import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Action, Module } from 'vuex-module-decorators';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Module({ namespaced: true, stateFactory: true })
export default class SessionCmsPageHeaderWidgetStore extends WidgetBaseStore {
  @Action
  setDataConfigs(): void {
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    const payload = JSON.parse(this.widget?.payload || '{}');
    const { entityCode } = payload;

    if (entityCode && String(entityCode).length) {
      const variables = {
        filter: {
          uid: entityCode,
        },
      };
      configs.push({
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: variables.filter,
            type: GqlEntityFilterType.SESSION_FILTER,
          },
        }),
        operation: 'session',
        fragmentName: 'sessionForCmsPageHeaderFragment',
        alias: this.widgetStoreName,
      });
      super.setDataConfigs(configs);
    }
  }
}
