import { Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';

@Module({ namespaced: true, stateFactory: true })
export default class InformationCardListWidgetStore extends WidgetBaseStore {
  @Mutation
  mapper(): void {
    const items = [];
    if (this.widget) {
      if (this.widget.subWidgets) {
        items.push(...this.widget.subWidgets
          .map((sub) => ({ cardType: 'information', ...sub })));
      }
    }
    this.mapping = {
      ...this.payload,
      items,
    };
  }
}
