enum ActionRule {
  CAN_VIEW = 'CAN_VIEW',
  CAN_MEET = 'CAN_MEET',
  CAN_BOOKMARK = 'CAN_BOOKMARK',
  CAN_CONNECT = 'CAN_CONNECT',
  CAN_VIEW_LIVE_VIDEO = 'CAN_VIEW_LIVE_VIDEO',
  CAN_CHAT = 'CAN_CHAT',
  CAN_ADD_TO_AGENDA = 'CAN_ADD_TO_AGENDA',
  CAN_REQUEST_MEETING = 'CAN_REQUEST_MEETING',
}

export default ActionRule;
