import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import CommunityUserCountryRepository from '@/repositories/CommunityUserCountryRepository';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import CommunityUser from '@/models/graphql/CommunityUser';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class CommunityUserCountryStore extends LoadableStore<LoadableState> {
    private readonly CommunityUserCountryRepository = new CommunityUserCountryRepository()

    private get repository(): CommunityUserCountryRepository {
      return this.CommunityUserCountryRepository;
    }

    @Action
    setCommunityUserCountry(payload: { countryUid: string; userUid: string }): Promise<CommunityUser | undefined> {
      return this.repository.update({
        definition: buildMutationDefinition([
          {
            fieldName: 'uid',
            type: GqlEntityInputType.ID,
            value: payload.userUid,
          },
          {
            fieldName: 'country_CountryUid',
            type: GqlEntityInputType.ID,
            value: payload.countryUid,
          },
        ]),
      }).then((response) => response);
    }
}
