var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isItForYou)?_c('div',{staticClass:"notification-item d-flex p-3 column-gap-4"},[_c('div',[([_vm.NotificationActionType.MEETING_ACCEPTED,
             _vm.NotificationActionType.MEETING_DECLINED].includes(_vm.notification.action))?_c('avatar-solo-widget',{staticClass:"user-avatar border-0 bg-transparent",attrs:{"firstname":_vm.names.firstName,"lastname":_vm.names.lastName,"src":_vm.image,"with-borders":true,"default-icon":"fa-regular fa-calendar-clock","icon-classes":"icon-16 text-neutral-n-6-label","size":"40"}}):_c('font-awesome-component',{attrs:{"container-classes":"bg-yellow-y-1-light-yellow p-12 rounded-circle","icon":"far fa-calendar-clock","icon-classes":"icon-16 text-neutral-n-8-dark-ink"}})],1),_c('div',{staticClass:"d-flex flex-column text-left"},[(_vm.notification.action === _vm.NotificationActionType.MEETING_INVITE)?_c('label',{staticClass:"label-2 text-neutral-n-8-dark-ink"},[_c('div',{staticClass:"d-inline-flex",on:{"click":_vm.onInteractedWith}},[_c('b-link',{staticClass:"m-0 text-decoration-none label label-2 medium text-neutral-n-8-dark-ink",attrs:{"to":_vm.navigateTo}},[_vm._v(" "+_vm._s(_vm.fullName)+" ")])],1),_vm._v(" "+_vm._s(_vm.$t('my-notification-list-component.meeting.meeting-invite'))+" "),_c('label',{staticClass:"m-0 label-2 medium text-neutral-n-8-dark-ink cursor-pointer",on:{"click":_vm.openMeetingInToolbox}},[_vm._v(" "+_vm._s(_vm.meetingName)+" ")])]):_c('label',{staticClass:"label-2 text-neutral-n-8-dark-ink"},[([_vm.NotificationActionType.MEETING_UPDATED,
               _vm.NotificationActionType.MEETING_CANCELLED].includes(_vm.notification.action))?_c('label',{staticClass:"m-0 label-2 medium text-neutral-n-8-dark-ink cursor-pointer",on:{"click":_vm.openMeetingInToolbox}},[_vm._v(" "+_vm._s(_vm.meetingName)+" ")]):_vm._e(),_c('div',{staticClass:"d-inline-flex",on:{"click":_vm.onInteractedWith}},[([_vm.NotificationActionType.MEETING_ACCEPTED,
                 _vm.NotificationActionType.MEETING_DECLINED].includes(_vm.notification.action))?_c('b-link',{staticClass:"m-0 text-decoration-none label label-2 medium text-neutral-n-8-dark-ink",attrs:{"to":_vm.navigateTo}},[_vm._v(" "+_vm._s(_vm.fullName)+" ")]):_vm._e()],1),_vm._v(" "+_vm._s(_vm.content)+" ")]),_c('label',{staticClass:"m-0 label-2 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.dateReceived)+" ")]),(!_vm.isCancelled
        && _vm.isActionVisible
        && _vm.NotificationActionType.MEETING_CANCELLED !== _vm.notification.action)?_c('div',{staticClass:"notification-item-buttons d-flex column-gap-4 mt-2"},[_c('button',{class:['paragraph paragraph-2 font-weight-bold  border-0 text-uppercase bg-transparent',
                 _vm.isAccepted ? 'text-blue-b-3-primary-blue' : 'text-neutral-n-6-label', 'p-0'],on:{"click":_vm.onAccept}},[_vm._v(" "+_vm._s(_vm.$tc('my-notification-list-component.meeting.accept', _vm.isAccepted ? 0 : 1))+" ")]),_c('button',{class:['paragraph paragraph-2 font-weight-bold border-0 text-uppercase bg-transparent',
                 _vm.isDeclined ? 'text-red-r-3-primary-red' : 'text-neutral-n-6-label', 'p-0'],on:{"click":_vm.onDecline}},[_vm._v(" "+_vm._s(_vm.$tc('my-notification-list-component.meeting.decline', _vm.isDeclined ? 0 : 1))+" ")])]):_vm._e()]),_c('div',{staticClass:"notification-item-icons ml-auto position-relative"},[(!_vm.notification.interactedWith)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-solid fa-scrubber","icon-classes":"icon-16 text-red-r-3-primary-red"}})],1):_vm._e(),_c('button-icon-component',{staticClass:"xmark-button position-absolute top-0 right-0",attrs:{"id":("remove-button-" + (_vm.notification.uid)),"boundry":"window","shape":"round","size":"md","variant":"tertiary"},on:{"on-click":_vm.onDelete},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-xmark","icon-classes":"neutral-n-8-dark-ink"}})]},proxy:true}],null,false,2243841175)}),_c('b-tooltip',{attrs:{"target":("remove-button-" + (_vm.notification.uid)),"boundary":"document","custom-class":"remove-button-tooltip","placement":"bottom","triggers":"hover"}},[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t('my-notification-list-component.tooltip-remove'))+" ")])])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }