import { FetchResult } from '@apollo/client';
import Speaker from '@/models/graphql/Speaker';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import UpdateInterface from '@/repositories/base/UpdateInterface';

export default class SpeakerRepository extends GraphQlRepository<Speaker>
  implements UpdateInterface<Speaker> {
  update(params: GqlPayloadParams): Promise<Speaker | undefined> {
    return buildMutationGql({
      operationName: 'UpdateSpeaker',
      operation: 'SpeakerUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'speakerBaseFragment',
    }).then((mutation) => this.mutate<{ SpeakerUpdate: Speaker }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ SpeakerUpdate: Speaker }>) => response.data?.SpeakerUpdate));
  }
}
