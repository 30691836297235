import {
  ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject,
} from '@apollo/client';
import GraphQlProvider, { GraphQlProviderOptions } from '@/plugins/apollo/GraphQlProvider';

// Cache implementation
const cache = new InMemoryCache({ addTypename: false });

export function createDefaultClient(options: object = {}): ApolloClient<NormalizedCacheObject> {
  const linkOptions = {
    uri: process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql',
    includeUnusedVariables: true,
  };

  // Create apollo client
  return new ApolloClient({
    cache,
    ssrMode: false,
    link: createHttpLink(linkOptions),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
    ...options,
  });
}

// Call this in the Vue app file
// eslint-disable-next-line max-len
export function createProvider(options?: GraphQlProviderOptions<NormalizedCacheObject>): GraphQlProvider<NormalizedCacheObject> {
  let defaultOptions = {
    defaultClient: createDefaultClient(options),
  };

  if (options) {
    defaultOptions = { ...defaultOptions, ...options };
  }

  // Create vue apollo provider
  return GraphQlProvider.init<NormalizedCacheObject>(defaultOptions);
}
