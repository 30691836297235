import { ApolloQueryResult } from '@apollo/client';
import ExtraPropertySection from '@/models/graphql/ExtraPropertySection';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';

/* eslint-disable max-len */
export default class ExtraPropertySectionRepository extends GraphQlRepository<ExtraPropertySection>
  implements FilterInterface<ExtraPropertySection> {
  filter(params: GqlPayloadParams): Promise<ExtraPropertySection[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterExtraPropertySection',
      operation: 'extraPropertySection',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'extraPropertySectionFullFragment',
      authUser: params.authUser,
      magicArgs: params.magicArgs,
    }).then((query) => this.query<{ extraPropertySection: Array<ExtraPropertySection> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ extraPropertySection: Array<ExtraPropertySection> }>) => response.data.extraPropertySection));
  }
}
