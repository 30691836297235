
































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import { Getter, namespace } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

const companyUserRoleStore = namespace('CompanyUserRoleStore');
const toastStore = namespace('ToastStore');

@Component({
  components: { ButtonComponent },
})
/* eslint-disable no-underscore-dangle */
export default class CompanyInvitationNotification extends Vue {
  @Getter
  protected readonly community!: Community;

  @Getter
  private authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({ required: true, default: '' })
  protected readonly entityUid!: string;

  @Prop({ required: true, default: '' })
  protected readonly entityType!: string;

  @companyUserRoleStore.Action('getAllCompanyUserRoles')
  private allCompanyUserRoles!: (payload: {
    schemaCode: string; userId: string;
  }) => Promise<void>;

  @companyUserRoleStore.Getter
  private fetchCompanyUserRoles!: CompanyUserRole[];

  @companyUserRoleStore.Action
  private updateCompanyUserRoleState!: (payload: {
    uid: string; state: CompanyUserRoleStatusType;
  }) => Promise<CompanyUserRole>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private companyInvitation: CompanyUserRole | null = null;

  private localInvitations: CompanyUserRole[] = [];

  private nextInvitation: CompanyUserRole | null = null;

  created(): void {
    this.onAuthUserReady();
    this.$root.$on('accept-company-invitation', (payload: {
      companyUserRole: CompanyUserRole;
    }) => {
      this.onAccept(payload.companyUserRole);
    });
    this.$root.$on('decline-company-invitation', (payload: {
      companyUserRole: CompanyUserRole;
    }) => {
      this.onDecline(payload.companyUserRole);
    });
  }

  @Watch('authUser')
  private onAuthUserReady(): void {
    if (this.authUser) {
      this.allCompanyUserRoles({
        userId: this.authUser.uid,
        schemaCode: this.community.code ? this.community.code : '',
      });
      this.setLocalInvitations();
    }
  }

  @Watch('companyInvitation')
  private updateDisplayConnectionNotification(): void {
    if (this.companyInvitation) {
      this.$emit('display-company-invite-notification', this.companyInvitation);
    } else {
      this.$emit('hide-company-invite-notification');
    }
  }

  @Watch('fetchCompanyUserRoles')
  private setLocalInvitations(): void {
    this.localInvitations = this.fetchCompanyUserRoles;
    if (this.localInvitations.length > 0) {
      if (this.$route.params.companyId) {
        const index = this.localInvitations
          .findIndex((item) => item.company?.uid === this.$route.params.companyId);
        this.companyInvitation = this.localInvitations[index !== -1 ? index : 0];
      } else {
        [this.companyInvitation] = this.localInvitations;
      }
    } else {
      this.companyInvitation = null;
    }
  }

  private onAccept(companyUserRole: CompanyUserRole | null): void {
    if (companyUserRole) {
      this.nextInvitation = companyUserRole;
      companyUserRole.state = CompanyUserRoleStatusType.ACCEPTED;
      this.removeFromList(companyUserRole);
      this.updateCompanyUserRoleState({
        uid: companyUserRole.uid,
        state: CompanyUserRoleStatusType.ACCEPTED,
      }).then(() => {
        this.addNewAction(
          { type: ToastActionType.USER_ACCEPT_INVITATION },
        );
      });
    }
  }

  private removeFromList(companyRole: CompanyUserRole): void {
    const foundIndex = this.fetchCompanyUserRoles.findIndex((c) => c.uid === companyRole.uid);
    if (foundIndex > -1) {
      this.fetchCompanyUserRoles[foundIndex].state = companyRole.state;
      this.fetchCompanyUserRoles.splice(foundIndex, 1);
    }
  }

  private onDecline(companyUserRole: CompanyUserRole | null): void {
    if (companyUserRole) {
      companyUserRole.state = CompanyUserRoleStatusType.DECLINED;
      this.removeFromList(companyUserRole);
      this.addNewAction(
        { type: ToastActionType.USER_DECLINE_INVITATION },
      );
      this.updateCompanyUserRoleState({
        uid: companyUserRole.uid,
        state: companyUserRole.state,
      });
    }
  }

  private isFeatureActivated(code: FeatureKeys): boolean {
    return this.featureByKey(code) && this.featureByKey(code).enabled;
  }
}
