/* eslint-disable max-len,@typescript-eslint/camelcase  */
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import Comment from '@/models/graphql/Comment';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import WriteInterface from '@/repositories/base/WriteInterface';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import CountInterface from '@/repositories/base/CountInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

export default class CommentRepository extends GraphQlRepository<Comment>
  implements WriteInterface<Comment>, FilterInterface<Comment>, CountInterface {
  filter(params: GqlPayloadParams): Promise<Comment[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterComments',
      operation: 'comment',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'commentBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ comment: Comment[] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ comment: Comment[] }>) => response.data.comment));
  }

  count(params: GqlPayloadParams): Promise<number | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetCommentCount',
      operation: 'comment',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'commentCountFragment',
      authUser: params.authUser,
    }).then((query) => this.use().query<{ comment: [{ count: number }] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ comment: [{ count: number }] }>) => response.data.comment[0].count));
  }

  create(params: GqlPayloadParams): Promise<Comment | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommentCreateForAuthorAndPost',
      operation: 'CommentCreateForAuthorAndPost',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'commentBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ CommentCreateForAuthorAndPost: Comment }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommentCreateForAuthorAndPost: Comment }>) => response.data?.CommentCreateForAuthorAndPost ?? undefined));
  }

  createFeedPostCommentWithParent(params: GqlPayloadParams): Promise<Comment | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommentCreateForAuthorAndParentCommentAndPost',
      operation: 'CommentCreateForAuthorAndParentCommentAndPost',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'commentBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ CommentCreateForAuthorAndParentCommentAndPost: Comment }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommentCreateForAuthorAndParentCommentAndPost: Comment }>) => response.data?.CommentCreateForAuthorAndParentCommentAndPost ?? undefined));
  }

  createMeetingComment(params: GqlPayloadParams): Promise<Comment | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommentCreateForAuthorAndParentCommentAndMeetingRequest',
      operation: 'CommentCreateForAuthorAndParentCommentAndMeetingRequest',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'commentBaseFragment',
      authUser: params.authUser,
      alias: 'CreateCommentForMeeting',
    }).then((mutation) => this.mutate<{ CreateCommentForMeeting: Comment }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CreateCommentForMeeting: Comment }>) => response.data?.CreateCommentForMeeting ?? undefined));
  }

  update(params: GqlPayloadParams): Promise<Comment | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommentUpdate',
      operation: 'CommentUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'commentBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ CommentUpdate: Comment }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommentUpdate: Comment }>) => response.data?.CommentUpdate ?? undefined));
  }

  delete(params: GqlPayloadParams): Promise<Comment | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommentDelete',
      operation: 'CommentDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'commentBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ CommentDelete: Comment }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommentDelete: Comment }>) => response.data?.CommentDelete ?? undefined));
  }

  likeComment(params: GqlPayloadParams): Promise<Comment | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommentAddLikeUser',
      operation: 'CommentAddLikeUser',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'commentBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ CommentAddLikeUser: Comment }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommentAddLikeUser: Comment }>) => response.data?.CommentAddLikeUser ?? undefined));
  }

  unlikeComment(params: GqlPayloadParams): Promise<Comment | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommentRemoveLikeUser',
      operation: 'CommentRemoveLikeUser',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'commentBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ CommentRemoveLikeUser: Comment }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommentRemoveLikeUser: Comment }>) => response.data?.CommentRemoveLikeUser ?? undefined));
  }
}
