import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { Data } from '@/utils/types/WidgetData';
import UiPage from '@/models/graphql/UiPage';
import CommunityUser from '@/models/graphql/CommunityUser';
import ProfilePrivacyType from '@/utils/enums/ProfilePrivacyType';
/* eslint-disable @typescript-eslint/camelcase */
@Module({ namespaced: true, stateFactory: true })
export default class TabMenuWidgetStore extends WidgetBaseStore {
  tabMenuConfigs: Record<string, CommunityUser[]> = {};

  pages: UiPage[] = [];

  @Action
  setDataConfigs(): void {
    this.context.commit('setPages', this.context.rootState.pages);
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    if (this.widget) {
      const {
        entityCode, isUserPrivate,
      } = JSON.parse(this.widget.payload || '{}');
      if (isUserPrivate) {
        configs.push({
          gqlDefinition: buildQueryDefinition({
            filter: {
              value: { uid: entityCode },
              type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
            },
          }),
          operation: 'communityUser',
          fragmentName: 'communityUserPrivacyFragment',
          alias: this.widgetStoreName,
        });
      }
      if (this.widget.subWidgets && this.widget.subWidgets.length > 0) {
        this.widget.subWidgets.forEach((sub) => {
          const payload = JSON.parse(sub.payload || '{}');
          if (payload.isUserPrivate) {
            configs.push({
              gqlDefinition: buildQueryDefinition({
                filter: {
                  value: { uid: payload.entityCode },
                  type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
                },
              }),
              operation: 'communityUser',
              fragmentName: 'communityUserPrivacyFragment',
              alias: `${this.widgetStoreName}_tabMenuItem${sub.id}`,
            });
          }
        });
      }
      this.context.commit('setGqlQueryConfig', configs);
      this.context.commit(
        'WidgetDispatcherStore/setGqlQueryConfigs',
        { key: this.widgetStoreName, values: configs },
        { root: true },
      );
    }
  }

  @Mutation
  mapper(): void {
    const tabConfigs: Data[] = [];
    const tabMenuRoutes: Data[] = [];
    if (this.widget && this.widget.page && this.pages.length > 0) {
      const parent = this.pages.find((p) => this.widget
        && this.widget.page
        && this.widget.page.id === p.id);
      if (parent && parent.path && parent.childPages.length > 0) {
        tabMenuRoutes.push(...parent.childPages.map((child) => ({
          id: child.id as number,
          code: child.code as string,
        })));
      }
    }
    const isPrivate = (widgetId: string): boolean => {
      const key = Object.keys(this.tabMenuConfigs).find((k) => k.includes(widgetId));
      if (key && this.tabMenuConfigs[key] && this.tabMenuConfigs[key].length > 0) {
        return this.tabMenuConfigs[key][0].profilePrivacyMode === ProfilePrivacyType.PRIVATE;
      }
      return false;
    };
    if (this.widget && this.widget.subWidgets && this.widget.subWidgets.length > 0) {
      tabConfigs.push(...this.widget.subWidgets.map((sub) => {
        const payload = JSON.parse(sub.payload || '{}');
        const tabCode = tabMenuRoutes.find((tab) => tab.id === payload.targetPage);
        return {
          ...payload,
          isPrivate: isPrivate(`${sub.id}`),
          route: tabCode ? tabCode.code : null,
        };
      }));
    }
    this.mapping = {
      ...this.payload,
      isPrivate: isPrivate('main'),
      tabConfigs: tabConfigs.filter((tab) => !!tab.targetPage),
    };
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    const keys = data.key.split('_');
    const key = keys[1] ? keys[1] : 'main';
    Object.assign(this.tabMenuConfigs, { [key]: data.values });
  }

  @Mutation
  private setPages(pages: UiPage[]): void {
    this.pages = pages;
  }
}
