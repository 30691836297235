import { ApolloClient } from '@apollo/client';
import { SubscriptionClient } from 'subscriptions-transport-ws';

export default class SubscriptionApolloClient<T> extends ApolloClient<T> {
  protected wsClient!: SubscriptionClient;

  protected static instance: object | null = null;

  onConnected(callback: () => void): void {
    this.wsClient.onConnected(callback);
  }

  onDisconnected(callback: () => void): void {
    this.wsClient.onDisconnected(callback);
  }

  onReconnected(callback: () => void): void {
    this.wsClient.onReconnected(callback);
  }
}
