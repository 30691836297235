import { Action, Module, Mutation } from 'vuex-module-decorators';
import Deal from '@/models/graphql/Deal';
import LoadableState from '@/store/states/LoadableState';
import DealRepository from '@/repositories/DealRepository';
import Category from '@/models/graphql/Category';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { Data } from '@/utils/types/WidgetData';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

/* eslint-disable @typescript-eslint/camelcase */
interface DealState extends LoadableState {
  promotionLinksLoading: boolean;
}

@Module({ namespaced: true })
export default class DealStore extends LoadableStore<DealState> {
  private readonly dealRepository = new DealRepository();

  promotionLinksLoading = false;

  protected get repository(): DealRepository {
    return this.dealRepository;
  }

  get getPromotionLinksLoading(): boolean {
    return this.promotionLinksLoading;
  }

  @Action
  editPromotion(payload: {
    exhibitorId: string;
    promotion: {
      uid: string;
      name: string;
      title: string;
      content: string;
      startTime: string;
      endTime: string;
      oldCategories: [];
      newCategories: [];
      newProductLink: string;
      oldProductLink: string;
    };
  }): Promise<Deal | undefined> {
    const finalPromotion = { ...payload.promotion };
    if ('oldCategories' in finalPromotion) {
      delete finalPromotion.oldCategories;
    }
    if ('newCategories' in finalPromotion) {
      delete finalPromotion.newCategories;
    }
    if ('newProductLink' in finalPromotion) {
      delete finalPromotion.newProductLink;
    }
    if ('oldProductLink' in finalPromotion) {
      delete finalPromotion.oldProductLink;
    }
    if (payload.promotion.uid) {
      return this.repository.update({
        definition: buildMutationDefinition([
          {
            fieldName: 'entity',
            type: GqlEntityInputType.DEAL_INPUT,
            value: finalPromotion,
          },
        ]),
      });
    }

    return this.repository.createDealForExhibitor({
      definition: buildMutationDefinition([
        {
          fieldName: 'exhibitor_ExhibitorUid',
          type: 'ID',
          value: payload.exhibitorId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.DEAL_INPUT,
          value: finalPromotion,
        },
      ]),
    });
  }

  @Action
  updatePromotionByLocale(payload: {
    promotion: {
      uid: string;
      translations: Record<string, string>;
    };
  }): Promise<void> {
    let translationsToUpdatePromise: Promise<Deal | undefined>[] = [];
    if (Object.keys(payload.promotion.translations).length > 0) {
      translationsToUpdatePromise = Object.keys(payload.promotion.translations).map((key) => {
        const infos = key.split('-');
        return this.repository.updateByLocale({
          definition: buildMutationDefinition([
            {
              fieldName: 'entity',
              type: GqlEntityInputType.DEAL_INPUT,
              value: {
                uid: payload.promotion.uid,
                [infos[0]]: payload.promotion.translations[key],
              },
            },
          ]),
        }, infos[1]);
      });
    }
    return Promise.all(translationsToUpdatePromise).then(() => Promise.resolve());
  }

  @Action
  updatePromotionCategories(payload: {
    promotion: {
      uid: string;
      oldCategories: Category[];
      newCategories: Category[];
    };
  }): Promise<void> {
    let categoriesToDelete: string[] = [];
    let categoriesToAdd: string[] = [];
    if (payload.promotion.newCategories && payload.promotion.oldCategories) {
      const newCategoriesUid = payload.promotion.newCategories.map((c) => c.uid);
      categoriesToDelete = payload.promotion.oldCategories
        .filter((old) => !newCategoriesUid.includes(old.uid)).map((c) => c.uid);

      const oldCategoriesUid = payload.promotion.oldCategories.map((c) => c.uid);
      categoriesToAdd = payload.promotion.newCategories
        .filter((newCat) => !oldCategoriesUid.includes(newCat.uid)).map((c) => c.uid);
    }

    let categoriesToAddPromise: Promise<Record<string, Deal>[]> | null = null;
    if (categoriesToAdd.length > 0) {
      categoriesToAddPromise = this.repository.addCategoriesToDeal({
        definitions: categoriesToAdd.map((category, index) => ({
          operation: 'DealAddCategory',
          gqlDefinition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: 'ID',
              value: payload.promotion.uid,
            },
            {
              fieldName: 'category_CategoryUid',
              type: 'ID',
              value: category,
            },
          ]),
          fragmentName: 'dealBaseFragment',
          alias: `add${index}`,
        })),
      });
    }

    let categoriesToDeletePromise: Promise<Record<string, Deal>[]> | null = null;
    if (categoriesToDelete.length > 0) {
      categoriesToDeletePromise = this.repository.removeCategoriesFromDeal({
        definitions: categoriesToDelete.map((category, index) => ({
          operation: 'DealRemoveCategory',
          gqlDefinition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: 'ID',
              value: payload.promotion.uid,
            },
            {
              fieldName: 'category_CategoryUid',
              type: 'ID',
              value: category,
            },
          ]),
          fragmentName: 'dealBaseFragment',
          alias: `delete${index}`,
        })),
      });
    }

    const allPromises = [];
    if (categoriesToAddPromise) {
      allPromises.push(categoriesToAddPromise);
    }
    if (categoriesToDeletePromise) {
      allPromises.push(categoriesToDeletePromise);
    }

    return Promise.all(allPromises)
      .then(() => Promise.resolve());
  }

  @Action
  updatePromotionLinksProductAndLargeProduct(payload: {
    promotion: {
      PromotionUid: string;
      ProductUid: string;
      isProduct: boolean;
    };
  }): Promise<Deal | undefined> {
    this.context.commit('setPromotionLinksLoading', true);
    return this.repository.updateProductAndLargeProductFromDeal({
      definition: buildMutationDefinition([
        {
          fieldName: 'dealUid',
          type: 'ID!',
          value: payload.promotion.PromotionUid,
        },
        {
          fieldName: 'productUid',
          type: 'ID',
          value: (payload.promotion.isProduct) ? payload.promotion.ProductUid : '',
        },
        {
          fieldName: 'largeProductUid',
          type: 'ID',
          value: (!payload.promotion.isProduct) ? payload.promotion.ProductUid : '',
        },
      ]),
    }).then((response) => {
      this.context.commit('setPromotionLinksLoading', false);
      return Promise.resolve(response);
    });
  }

  @Action
  publishDeal(payload: { uid: string; startTime: string }): Promise<Deal | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.DEAL_INPUT,
        value: { ...payload, endTime: null },
      }]),
    });
  }

  @Action
  unpublishDeal(payload: { uid: string; endTime: string }): Promise<Deal | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.DEAL_INPUT,
        value: payload,
      }]),
    });
  }

  @Action
  deleteDeal(uid: string): Promise<boolean> {
    return this.repository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    }).then((response) => !!response);
  }

  @Action
  loadDeals(filter: Data): Promise<Deal[]> {
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.DEAL_FILTER,
        },
      }),
    });
  }

  @Mutation
  setPromotionLinksLoading(promotionLinksLoading: boolean): void {
    this.promotionLinksLoading = promotionLinksLoading;
  }
}
