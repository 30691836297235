


























import CommunityUser from '@/models/graphql/CommunityUser';
import Component from 'vue-class-component';
import { namespace, State } from 'vuex-class';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import ListFilter from '@/utils/enums/agenda/ListFilter';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import AgendaFilterItem from '@/components/toolbox/agenda/filters/AgendaFilterItem.vue';
import { getUnixTime } from 'date-fns';
import { Vue } from 'vue-property-decorator';

/* eslint-disable @typescript-eslint/camelcase */

const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    AgendaFilterItem,
  },
})
export default class AgendaFilters extends Vue {
  @agendaStore.Action
  private loadAgendaData!: (payload: object) => void;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Mutation
  private setDayBeingViewed!: (date: string) => void;

  @agendaStore.Getter
  private fetchViewMode!: string

  @agendaStore.Mutation
  private setListFilter!: (string: ListFilter) => void;

  @agendaStore.Action
  private loadAgendaCounts!: (now: number) => void;

  @agendaStore.Getter
  private fetchPendingFilterCount!: number;

  @agendaStore.Getter
  private fetchMeetingFilterCount!: number;

  @agendaStore.Getter
  private fetchSessionFilterCount!: number;

  @agendaStore.Getter
  private fetchListFilter!: string;

  @State
  private authUser!: CommunityUser;

  @State
  private selectedTzName!: string;

  private selectedFilter = '';

  private ViewMode = ViewMode;

  private ListFilter = ListFilter;

  mounted(): void {
    this.loadAgendaCounts(
      getUnixTime(
        DateTimeHelper.zonedToUTCTimeDate(
          DateTimeHelper.getCurrentDateTime(),
          this.selectedTzName,
        ),
      ),
    );
  }

  private onFilterClick(filterName: ListFilter): void {
    if (this.selectedFilter === filterName) {
      this.selectedFilter = '';
      this.setViewMode(ViewMode.DAILY_VIEW);
    } else {
      this.selectedFilter = filterName;
      this.setListFilter(filterName);
      if (this.fetchViewMode !== ViewMode.LIST) {
        this.setViewMode(ViewMode.LIST);
      }
      const or = {
        startTimestamp_gte: getUnixTime(
          DateTimeHelper.zonedToUTCTimeDate(
            DateTimeHelper.getCurrentDateTime(),
            this.selectedTzName,
          ),
        ),
      };
      this.loadAgendaData({
        filter: {
          authUser: this.authUser.uid,
          orMeeting: or,
          orSession: or,
        },
      });
      this.setDayBeingViewed(AgendaStoreHelper.formatDictionaryKey(DateTimeHelper.getCurrentDateTime()));
      this.$emit('on-filter-click');
    }
  }
}
