





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextEditorComponent from '@/components/TextEditorComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import Variant from '@/utils/enums/Variant';
import LinkedEntityResourceHelper from '@/utils/link/LinkedEntityResourceHelper';
import CommunityUserNote from '@/models/graphql/CommunityUserNote';
import { format } from 'date-fns';
import CommunityUserLink from '@/models/graphql/CommunityUserLink';
import { State } from 'vuex-class';

@Component({
  components: {
    ButtonComponent,
    TextEditorComponent,
    PillWidget,
  },
})

/* eslint-disable max-len */
export default class NoteEditorComponent extends Vue {
  @State
  private dateLocale!: Locale;

  @Prop({ required: true })
  private fontSizes!: string[];

  @Prop({ required: true })
  private configs!: object;

  @Prop({ required: true })
  private note!: CommunityUserNote;

  @Prop({ default: '' })
  private readonly variant!: string;

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean;

  @Prop({ required: false, default: true })
  private readonly showToolbar!: boolean;

  @Prop({ required: false, default: false })
  private readonly displayFooter!: boolean;

  private variantEnum = Variant;

  private linkedEntityResourceHelper = LinkedEntityResourceHelper;

  private get activeNoteTimestamp(): string {
    if (this.note && this.note.updatedTime) {
      const dateUpdated = new Date(`${this.note.updatedTime}Z`);
      return format(dateUpdated,
        `${this.$t('app.date.defaultDateFormat')}
        ${this.$t('app.date.dateTimeAtSeparator')}
        ${this.$t('app.date.defaultTimeFormat')}`,
        { locale: this.dateLocale });
    }
    return '';
  }

  private onChange(data: { html: string; text: string }): void {
    this.$emit('on-change', data);
  }

  // eslint-disable-next-line class-methods-use-this
  private getLinkImagePath(link: CommunityUserLink): string {
    const linkedEntity = LinkedEntityResourceHelper.getLinkedEntity(link);
    return linkedEntity ? linkedEntity.mainPicture : '';
  }

  private goToLinkedPage(link: CommunityUserLink): void {
    const route = LinkedEntityResourceHelper.getLinkedEntityRoute(link);
    if (route) {
      this.$router.push(route).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }).finally(() => {
        this.$eventsBus.emit('close-toolbox');
      });
    }
  }

  private clearLink(data: { note: CommunityUserNote; linkTargetUid: string }): void {
    this.$emit('on-clear-link', data);
  }
}
