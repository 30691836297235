import { Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import MenuModel from '@/models/MenuModel';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';

interface CompanyProfileTabState extends LoadableState {
  tabsMenuList: MenuModel[];
}

@Module({ namespaced: true })
export default class CompanyProfileTabStore extends LoadableStore<CompanyProfileTabState> {
  private tabsMenuList: MenuModel[] = [
    {
      uid: '1',
      title: 'Profile',
      name: 'company-tab-profile',
      code: 'community_company_profile_tab_profile',
    },
    {
      uid: '2',
      title: 'Team',
      name: 'company-tab-team',
      code: 'community_company_profile_tab_team',
    },
    {
      uid: '3',
      title: 'Products',
      name: 'company-tab-products',
      code: 'community_company_profile_tab_product',
    },
    {
      uid: '4',
      title: 'Promotions',
      name: 'company-tab-promotions',
      code: 'community_company_profile_tab_deal',
    },
    {
      uid: '5',
      title: 'Documents',
      name: 'company-tab-documents',
      code: 'community_company_profile_tab_handout',
    },
  ];

  get featuredCompanyProfileTab(): MenuModel[] | null {
    return this.tabsMenuList.filter((menu) => this.context.rootGetters.featureByKey(menu.code as FeatureKeys)
      && this.context.rootGetters.featureByKey(menu.code as FeatureKeys).enabled);
  }
}
