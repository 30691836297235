import Axios from 'axios';
import BaseService from '@/services/BaseService';
import OgMetadata from '@/models/feeds/OgMetaData';

class OgService extends BaseService {
  private url = '';

  constructor() {
    super();
    this.url = process.env.VUE_APP_OG_NODE_SERVICE ? `${process.env.VUE_APP_OG_NODE_SERVICE}` : 'http://localhost:3111';
  }

  getUrlOgData(url: string): Promise<OgMetadata> {
    const encodedUrl = encodeURIComponent(url);
    return Axios.get(
      `${this.url}/url-meta/${encodedUrl}`,
    );
  }
}

export default OgService;
