import { ApolloQueryResult, FetchResult } from '@apollo/client';
import Country from '@/models/graphql/Country';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable max-len */
export default class CountryRepository extends GraphQlRepository<Country>
  implements FilterInterface<Country> {
  filter(params: GqlPayloadParams): Promise<Country[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterCountries',
      operation: 'country',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'countryBaseFragment',
    }).then((query) => this.query<{ country: Country[] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ country: Country[] }>) => response.data.country));
  }

  addToExhibitor(params: GqlPayloadParams): Promise<Country | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CountryAddExhibitor',
      operation: 'CountryAddExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'countryBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ CountryAddExhibitor: Country }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CountryAddExhibitor: Country }>) => response.data?.CountryAddExhibitor ?? undefined));
  }

  removeFromExhibitor(params: GqlPayloadParams): Promise<Country | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CountryRemoveExhibitor',
      operation: 'CountryRemoveExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'countryBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ CountryRemoveExhibitor: Country }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CountryRemoveExhibitor: Country }>) => response.data?.CountryRemoveExhibitor ?? undefined));
  }

  linkCountryToAddress(params: GqlPayloadParams): Promise<Country | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'LinkCountryToAddress',
      operation: 'AddressSetCountry',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'addressBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ AddressSetCountry: Country }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ AddressSetCountry: Country }>) => response.data?.AddressSetCountry));
  }
}
