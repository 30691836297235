import { ApolloQueryResult } from '@apollo/client';
import CategoryDomainContext from '@/models/graphql/CategoryDomainContext';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import FilterInterface from '@/repositories/base/FilterInterface';
import Slot from '@/models/graphql/Slot';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import Exhibitor from '@/models/graphql/Exhibitor';

/* eslint-disable max-len */
export default class SalesPackageLimitRepository extends GraphQlRepository<CategoryDomainContext>
  implements FilterInterface<Slot> {
  filter(params: GqlPayloadParams): Promise<Slot[]> {
    let filter = '';
    if (params.params) {
      if (params.params.code) {
        if (params.params.type) {
          filter = `{code: "${params.params.code}", type: "${params.params.type}"}`;
        } else {
          filter = `{code: "${params.params.code}"}`;
        }
      } else if (params.params.type) {
        filter = `{type: "${params.params.type}"}`;
      }
    }

    return buildQueryGql({
      operationName: params.operationName || 'FilterSlots',
      operation: 'exhibitor',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'slotBaseFragment',
      magicArgs: { filter: `(filter: ${filter})` },
    })
      .then((query) => this.query<{ exhibitor: Array<Exhibitor> }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{ exhibitor: Array<Exhibitor> }>) => response.data.exhibitor[0]?.slots));
  }
}
