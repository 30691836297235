import Vue from 'vue';
import VueI18n from 'vue-i18n';
import LocaleService from '@/services/LocaleService';

Vue.use(VueI18n);

const i18n = new VueI18n(
  {
    locale: LocaleService.defaultLocale,
    fallbackLocale: LocaleService.fallbackLocale,
    // Lazy load translations for current language only
    messages: {},
  },
);

export default i18n;
