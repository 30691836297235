import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import ProfilePrivacyType from '@/utils/enums/ProfilePrivacyType';
/* eslint-disable @typescript-eslint/camelcase */
@Module({ namespaced: true, stateFactory: true })
export default class UserPrivacyGuardWidgetStore extends WidgetBaseStore {
  @Action
  setDataConfigs(): void {
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    if (this.widget) {
      const {
        entityCode,
      } = JSON.parse(this.widget.payload || '{}');
      configs.push({
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: { uid: entityCode },
            type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
          },
        }),
        operation: 'communityUser',
        fragmentName: 'communityUserPrivacyFragment',
        alias: this.widgetStoreName,
      });
      this.context.commit('setGqlQueryConfig', configs);
      this.context.commit(
        'WidgetDispatcherStore/setGqlQueryConfigs',
        { key: this.widgetStoreName, values: configs },
        { root: true },
      );
    }
  }

  @Mutation
  mapper(): void {
    let isPrivate = false;
    if (this.data.length > 0 && this.data[0].profilePrivacyMode) {
      isPrivate = this.data[0].profilePrivacyMode === ProfilePrivacyType.PRIVATE;
    }
    this.mapping = {
      ...this.payload,
      isPrivate,
    };
  }
}
