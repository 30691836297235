import { ApolloQueryResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import FilterInterface from '@/repositories/base/FilterInterface';
import SurveyQuestion from '@/models/graphql/SurveyQuestion';

/* eslint-disable max-len */
export default class SurveyQuestionRepository extends GraphQlRepository<SurveyQuestion>
  implements FilterInterface<SurveyQuestion> {
  filter(params: GqlPayloadParams): Promise<SurveyQuestion[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterSurveyQuestions',
      operation: 'surveyQuestion',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'surveyQuestionFullWithoutSurveyFragment',
    }).then((query) => this.query<{ surveyQuestion: Array<SurveyQuestion> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ surveyQuestion: Array<SurveyQuestion> }>) => response.data.surveyQuestion));
  }
}
