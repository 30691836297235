import { ApolloQueryResult, FetchResult } from '@apollo/client';
import Address from '@/models/graphql/Address';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GetterInterface from '@/repositories/base/GetterInterface';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import WriteInterface from '@/repositories/base/WriteInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable max-len */
export default class AddressRepository extends GraphQlRepository<Address>
  implements GetterInterface<Address>, WriteInterface<Address> {
  get(params: GqlPayloadParams): Promise<Address | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetAddresses',
      operation: 'address',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'addressBaseFragment',
    }).then((query) => this.query<{ address: Address[] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ address: Address[] }>) => response.data?.address[0]));
  }

  create(params: GqlPayloadParams): Promise<Address | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'AddressCreate',
      operation: 'AddressCreate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'addressBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ AddressCreate: Address }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ AddressCreate: Address }>) => response.data?.AddressCreate ?? undefined));
  }

  update(params: GqlPayloadParams): Promise<Address | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'AddressUpdate',
      operation: 'AddressUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'addressBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ AddressUpdate: Address }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ AddressUpdate: Address }>) => response.data?.AddressUpdate));
  }

  delete(params: GqlPayloadParams): Promise<Address | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'AddressDelete',
      operation: 'AddressDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'addressBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ AddressDelete: Address }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ AddressDelete: Address }>) => response.data?.AddressDelete));
  }

  addressSetCountry(params: GqlPayloadParams): Promise<Address | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'AddressSetCountry',
      operation: 'AddressSetCountry',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'addressBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ AddressSetCountry: Address }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ AddressSetCountry: Address }>) => response.data?.AddressSetCountry));
  }
}
