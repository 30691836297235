import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Data } from '@/utils/types/WidgetData';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import EntityType from '@/utils/enums/EntityType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Module({ namespaced: true, stateFactory: true })
export default class CountdownWidgetStore extends WidgetBaseStore {
  private date: string | null = null;

  @Action
  setDataConfigs(): void {
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    const payload = JSON.parse(this.widget?.payload || '{}');
    const {
      entityType,
      useDate,
    } = payload;
    const { entityCode } = payload;
    if (useDate === 'false') {
      let operation: string;
      let fragmentName: string;
      const variables = { filter: {} };
      let filterType = '';

      switch (entityType.toLowerCase()) {
        case EntityType.SESSION.toLowerCase():
          fragmentName = 'sessionCountdownFragment';
          operation = 'session';
          filterType = GqlEntityFilterType.SESSION_FILTER;
          Object.assign(variables.filter, {
            uid: entityCode,
          });
          break;
        case EntityType.EVENT.toLowerCase():
          fragmentName = 'subEditionCountdownFragment';
          operation = 'subEdition';
          filterType = GqlEntityFilterType.SUB_EDITION_FILTER;
          Object.assign(variables.filter, {
            uid: entityCode,
          });
          break;
        case EntityType.EDITION.toLowerCase():
          fragmentName = 'editionCountdownFragment';
          operation = 'edition';
          filterType = GqlEntityFilterType.EDITION_FILTER;
          Object.assign(variables.filter, {
            uid: entityCode,
          });
          break;
        default:
          fragmentName = '';
          operation = '';
          break;
      }
      if (fragmentName.length && operation.length) {
        configs.push({
          gqlDefinition: buildQueryDefinition({
            cacheable: true,
            filter: {
              value: variables.filter,
              type: filterType,
            },
          }),
          operation,
          fragmentName,
          alias: this.widgetStoreName,
        });
        super.setDataConfigs(configs);
      }
    }
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    const payload = JSON.parse(this.widget?.payload || '{}');
    if (payload.useDate && payload.useDate === 'false' && data.values.length > 0) {
      this.date = String(data.values[0].startTime);
    }
  }

  @Mutation
  mapper(): void {
    let { date } = this;

    if (!date) {
      [date] = (this.payload as { date: string }).date.split('Z');
    }
    this.mapping = {
      ...this.payload,
      date,
    };
  }
}
