import { ApolloQueryResult, FetchResult } from '@apollo/client';
import Meeting from '@/models/graphql/Meeting';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import BaseModel from '@/models/BaseModel';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlComposeQueryPayloadParams from '@/utils/types/gql/GqlComposeQueryPayloadParams';
import {
  buildComposeMutationGql,
  buildComposeQueryGql,
  buildMutationGql,
} from '@/graphql/_Tools/GqlGeneric';
import CreateInterface from '@/repositories/base/CreateInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';
import UpdateInterface from '@/repositories/base/UpdateInterface';

/* eslint-disable max-len */
export default class AgendaRepository extends GraphQlRepository<BaseModel>
  implements CreateInterface<Meeting>, UpdateInterface<Meeting> {
  loadComposedQueries<T>(params: GqlComposeQueryPayloadParams): Promise<T> {
    return buildComposeQueryGql({
      operationName: params.operationName || 'LoadComposedQueries',
      definitions: params.definitions,
      authUser: params.authUser,
      magicArgs: params.magicArgs,
    }).then((query) => this.query<{ }, {}>(query, {}, false)
      .then((response: ApolloQueryResult<{ }>) => response.data as T));
  }

  create(params: GqlPayloadParams): Promise<Meeting | null | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateMeeting',
      operation: 'createMeeting',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'meetingFullFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ createMeeting: Meeting }>(mutation, params.definition.variables))
      .then((response: FetchResult<{ createMeeting: Meeting }>) => response.data?.createMeeting);
  }

  update(params: GqlPayloadParams): Promise<Meeting | null | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdateMeeting',
      operation: 'MeetingUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'meetingFullFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ MeetingUpdate: Meeting }>(mutation, params.definition.variables))
      .then((response: FetchResult<{ MeetingUpdate: Meeting }>) => response.data?.MeetingUpdate);
  }

  updateMeetingParticipantState(params: GqlPayloadParams): Promise<MeetingParticipant | null | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdateMeetingParticipantState',
      operation: 'MeetingParticipantUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'meetingParticipantBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ MeetingParticipantUpdate: Meeting }>(mutation, params.definition.variables))
      .then((response: FetchResult<{ MeetingParticipantUpdate: Meeting }>) => response.data?.MeetingParticipantUpdate);
  }

  toggleParticipants(params: GqlComposeMutationPayloadParams): Promise<Record<string, MeetingParticipant>[]> {
    return buildComposeMutationGql({
      operationName: params.operationName || 'ToggleMeetingParticipants',
      definitions: params.definitions,
      authUser: params.authUser,
    }).then((mutation) => this.mutate<Record<string, MeetingParticipant>[]>(mutation))
      .then((response: FetchResult<Record<string, MeetingParticipant>[]>) => response.data as Record<string, MeetingParticipant>[]);
  }
}
