




































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component
export default class DynamicTextComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  baseStoreName = 'DynamicTextWidgetStore';

  @Prop({ required: false, default: '' })
  private readonly text!: string;

  @Prop({ required: false, default: '' })
  private readonly emptyState!: string;

  @Prop({ required: false, default: false })
  private readonly seeMore!: boolean;

  @Prop({ required: false, default: 5 })
  private readonly nbRows!: number;

  @Prop({ required: false, default: false })
  private readonly smallParagraph!: boolean;

  private more = false;

  private showMore = false;

  private moreLessText = 'actions.more';

  private get isHtml(): boolean {
    return /<?[a-z][\s\S]*>.*<\/[a-z][\s\S]*>/i.test(this.text);
  }

  created(): void {
    this.setDataConfig();
  }

  mounted(): void {
    this.toggleShowMore();
  }

  @Watch('isReadyToDisplay')
  @Watch('windowWidth', { deep: true })
  private toggleShowMore(): void {
    this.$nextTick(() => {
      if (this.$refs.content) {
        this.showMore = ((this.$refs.content as HTMLDivElement).scrollHeight / 24) > this.nbRows;
      }
    });
  }

  private toggleMoreLess(): void {
    this.more = !this.more;
    if (!this.more) {
      (this.$el as HTMLElement).style.setProperty('--line-clamp', `${this.nbRows}`);
      this.moreLessText = 'actions.more';
    } else {
      (this.$el as HTMLElement).style.setProperty('--line-clamp', 'none');
      this.moreLessText = 'actions.less';
    }
  }
}
