/* eslint-disable import/no-cycle */
import BaseModel from '@/models/BaseModel';
import CommunityUser from '@/models/graphql/CommunityUser';
import CommunityUserLink from '@/models/graphql/CommunityUserLink';

export default class CommunityUserNote extends BaseModel {
  user?: CommunityUser;

  schemaCode?: string;

  title?: string;

  content?: string;

  contentPlain?: string;

  oldContent?: string;

  deleted?: boolean;

  createdTime?: string;

  updatedTime?: string;

  deletedTime?: string;

  links: Array<CommunityUserLink> = [];

  get isTheSame(): boolean {
    return this.content === this.oldContent;
  }

  static hydrate(item: object): CommunityUserNote {
    const note = Object.assign(new CommunityUserNote(), item);
    note.oldContent = note.content;
    return note;
  }
}
