import { FetchResult } from '@apollo/client';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import CreateInterface from '@/repositories/base/CreateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';
import UpdateInterface from '@/repositories/base/UpdateInterface';

/* eslint-disable max-len,class-methods-use-this,@typescript-eslint/no-unused-vars */
export default class MeetingRequestRepository extends GraphQlRepository<MeetingRequest>
  implements CreateInterface<MeetingRequest>, UpdateInterface<MeetingRequest>, DeleteInterface<MeetingRequest> {
  create(params: GqlPayloadParams): Promise<MeetingRequest | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateMeetingRequest',
      operation: 'createMeetingRequest',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'meetingRequestFullFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ createMeetingRequest: MeetingRequest }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ createMeetingRequest: MeetingRequest }>) => response.data?.createMeetingRequest ?? undefined));
  }

  meetingRequestSetMeeting(params: GqlPayloadParams): Promise<MeetingRequest | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'MeetingRequestSetMeeting',
      operation: 'MeetingRequestSetMeeting',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'meetingRequestBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ MeetingRequestSetMeeting: MeetingRequest }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ MeetingRequestSetMeeting: MeetingRequest }>) => response.data?.MeetingRequestSetMeeting ?? undefined));
  }

  update(params: GqlPayloadParams): Promise<MeetingRequest | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'MeetingRequestUpdate',
      operation: 'MeetingRequestUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'meetingRequestFullFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ MeetingRequestUpdate: MeetingRequest }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ MeetingRequestUpdate: MeetingRequest }>) => response.data?.MeetingRequestUpdate ?? undefined));
  }

  delete(params: GqlPayloadParams): Promise<MeetingRequest | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'MeetingRequestDelete',
      operation: 'MeetingRequestDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'meetingRequestBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ MeetingRequestDelete: MeetingRequest }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ MeetingRequestDelete: MeetingRequest }>) => response.data?.MeetingRequestDelete ?? undefined));
  }
}
