/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import ReadInterface from '@/repositories/base/ReadInterface';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import CreateInterface from '@/repositories/base/CreateInterface';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable max-len */
export default class CompanyUserRoleRepository extends GraphQlRepository<CompanyUserRole>
  implements ReadInterface<CompanyUserRole>,
    CreateInterface<CompanyUserRole>,
    UpdateInterface<CompanyUserRole> {
  filter(params: GqlPayloadParams): Promise<CompanyUserRole[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterCompanyUserRoles',
      operation: 'companyUserRole',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'companyUserRoleBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ companyUserRole: Array<CompanyUserRole> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ companyUserRole: Array<CompanyUserRole> }>) => response.data.companyUserRole));
  }

  get(params: GqlPayloadParams): Promise<CompanyUserRole | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetCompanyUserRole',
      operation: 'companyUserRole',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'companyUserRoleBaseFragment',
    }).then((query) => this.query<{ companyUserRole: CompanyUserRole }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ companyUserRole: CompanyUserRole }>) => response.data.companyUserRole));
  }

  create(params: GqlPayloadParams): Promise<CompanyUserRole | undefined> {
    return buildMutationGql({
      operationName: 'createCompanyUserRole',
      operation: 'CompanyUserRoleCreateForUserAndCompanyAndRole',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'companyUserRoleBaseFragment',
    }).then((mutation) => this.mutate<{ CompanyUserRoleCreateForUserAndCompanyAndRole: CompanyUserRole }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CompanyUserRoleCreateForUserAndCompanyAndRole: CompanyUserRole }>) => response.data?.CompanyUserRoleCreateForUserAndCompanyAndRole));
  }

  update(params: GqlPayloadParams): Promise<CompanyUserRole> {
    return buildMutationGql({
      operationName: params.operationName || 'updateCompanyUserRoleState',
      operation: 'CompanyUserRoleUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'companyUserRoleBaseFragment',
    }).then((mutation) => this.mutate<{ CompanyUserRoleUpdate: CompanyUserRole }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CompanyUserRoleUpdate: CompanyUserRole }>) => response.data?.CompanyUserRoleUpdate))
      .catch((err) => err);
  }

  updateCompanyUserRole(params: GqlPayloadParams): Promise<CompanyUserRole | undefined> {
    return buildMutationGql({
      operationName: 'updateCompanyUserRole',
      operation: 'CompanyUserRoleSetRole',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'companyUserRoleBaseFragment',
    }).then((mutation) => this.mutate<{ CompanyUserRoleSetRole: CompanyUserRole }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CompanyUserRoleSetRole: CompanyUserRole }>) => response.data?.CompanyUserRoleSetRole));
  }

  setDefaultCompany(params: GqlPayloadParams): Promise<CompanyUserRole> {
    return buildMutationGql({
      operationName: params.operationName || 'setDefaultCompany',
      operation: 'setDefaultCompanyUserRole',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'companyUserRoleBaseFragment',
    }).then((mutation) => this.mutate<{ setDefaultCompanyUserRole: CompanyUserRole }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ setDefaultCompanyUserRole: CompanyUserRole }>) => response.data?.setDefaultCompanyUserRole))
      .catch((err) => err);
  }
}
