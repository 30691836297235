import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import Session from '@/models/graphql/Session';
import { utcToZonedTime } from 'date-fns-tz';
import { format, startOfHour } from 'date-fns';
import RootState from '@/store/states/RootState';
import ScheduleEvent from '@/models/graphql/ScheduleEvent';
import { Data } from '@/utils/types/WidgetData';
import WidgetBaseRepository from '@/repositories/widget/WidgetBaseRepository';
import ScheduleOfEventType from '@/utils/enums/ScheduleOfEventType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import EntityType from '@/utils/enums/EntityType';
import GUUID from '@/utils/GUUID';
import { Route } from 'vue-router';

/* eslint-disable @typescript-eslint/camelcase */
@Module({ namespaced: true, stateFactory: true })
export default class ScheduleOfEventWidgetStore extends WidgetBaseStore {
  private scheduleEvents: Record<string, Record<string, ScheduleEvent[]>> = {};

  private startDates: string[] = [];

  private selectedDay = '';

  private readonly repository = new WidgetBaseRepository();

  @Action
  setDataConfigs(): void {
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    const payload = JSON.parse(this.widget?.payload || '{}');
    const { filter, type } = payload;
    let entityType = EntityType.SCHEDULE_EVENT;
    let variables = {};
    if (filter) {
      variables = JSON.parse(filter || '{}');
    }
    if (type) {
      entityType = type;
    }
    switch (entityType) {
      case EntityType.SCHEDULE_EVENT: configs.push({
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: { ...variables, distinct: true },
            type: GqlEntityFilterType.SCHEDULE_EVENT_FILTER,
          },
        }),
        operation: 'scheduleEvent',
        fragmentName: 'scheduleEventStartDateFragment',
        alias: this.widgetStoreName,
      });
        break;
      case EntityType.SESSION: configs.push({
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: { ...variables, distinct: true },
            type: GqlEntityFilterType.SESSION_FILTER,
          },
        }),
        operation: 'session',
        fragmentName: 'sessionStartDateFragment',
        alias: this.widgetStoreName,
      });
        break;
      default: break;
    }

    super.setDataConfigs(configs);
  }

  @Action
  loadSelectedTab(route: Route): Promise<void> {
    const { filter, type } = this.payload || {};
    const filterType = type === EntityType.SESSION
      ? GqlEntityFilterType.SESSION_FILTER
      : GqlEntityFilterType.SCHEDULE_EVENT_FILTER;
    const orderType = type === EntityType.SESSION
      ? GqlEntityOrderingType.SESSION_ORDERING
      : GqlEntityOrderingType.SCHEDULE_EVENT_ORDERING;
    const operationName = type === EntityType.SESSION
      ? 'LoadSessionsByDate'
      : 'LoadScheduleOfEventsByDate';
    const operation = type === EntityType.SESSION
      ? 'session'
      : 'scheduleEvent';
    const fragmentName = type === EntityType.SESSION
      ? 'sessionScheduleOfEventFragment'
      : 'scheduleEventFullFragment';

    const variables = JSON.parse(filter as string || '{}');

    const getMagicArgs = (): Data => {
      const magicArgs = {
        authUser: this.context.rootState.authUser?.uid || '',
      };
      if (route.query && Object.keys(route.query).length > 0) {
        Object.assign(magicArgs, { ...route.query });
      }
      if (Object.keys(route.params).length > 0) {
        Object.assign(magicArgs, { ...route.params });
      }

      return magicArgs;
    };

    return this.repository.load({
      params: {
        operationName,
        definitions: [{
          gqlDefinition: buildQueryDefinition({
            cacheable: true,
            filter: {
              value: {
                ...variables,
                _inStartDate: {
                  dates: [this.selectedDay],
                  timezone: this.context.rootGetters.communityTimeZone,
                },
              },
              type: filterType,
            },
            orderBy: {
              type: orderType,
              value: ['startTimestamp_asc'],
            },
          }),
          operation,
          fragmentName,
          alias: this.widgetStoreName,
        }],
        magicArgs: getMagicArgs(),
      },
      queries: '',
    }).then((response) => {
      Object.keys(response).forEach((k) => {
        let data = response[k];
        if (this.payload && this.payload.type && this.payload.type === EntityType.SESSION) {
          data = response[k].map((session) => ({
            uid: GUUID.uuidv4(),
            type: ScheduleOfEventType.SESSIONS,
            session,
          }));
        }
        this.context.commit(
          'setScheduleOfEvents',
          { key: this.selectedDay, values: data, rootState: this.context.rootState },
        );
      });
    });
  }

  @Mutation
  setScheduleOfEvents(data: { values: Array<ScheduleEvent>; key: string; rootState?: RootState }): void {
    if (data.values) {
      data.values
        .sort((a, b) => {
          const aStartTime = a.type === ScheduleOfEventType.SESSIONS
            ? (a.session as Session).startTimestamp
            : a.startTimestamp;
          const bStartTime = b.type === ScheduleOfEventType.SESSIONS
            ? (b.session as Session).startTimestamp
            : b.startTimestamp;
          return aStartTime && bStartTime && aStartTime > bStartTime ? 1 : -1;
        })
        .forEach((event) => {
          const scheduleEvent = ScheduleEvent.hydrate(event);
          const startDate = `${scheduleEvent.type === ScheduleOfEventType.SESSIONS
            ? (scheduleEvent.session as Session).startTime as string
            : scheduleEvent.startTime as string}Z`;
          const tzStartDateTime = utcToZonedTime(startDate, data.rootState?.community?.timeZone as string);
          const hour = format(
            startOfHour(tzStartDateTime),
            `${data.rootState?.i18n?.t('app.date.scheduleOfEventsHour')}`,
            { locale: data.rootState?.dateLocale },
          ).toLowerCase();

          if (!this.scheduleEvents[data.key]) {
            this.scheduleEvents[data.key] = {};
          }

          if (!this.scheduleEvents[data.key][hour]) {
            this.scheduleEvents[data.key][hour] = [];
          }
          this.scheduleEvents[data.key][hour].push(scheduleEvent);
        });
    }
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string; rootState?: RootState }): void {
    this.startDates = (data.values.map((date) => date.startDate) as unknown as Array<string>)
      .sort((d1, d2) => (d1 > d2 ? 1 : -1));
    this.startDates.forEach((d) => {
      if (!this.scheduleEvents[d]) {
        this.scheduleEvents[d] = {};
      }
    });
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      startDates: this.startDates,
      data: this.scheduleEvents,
    };
  }

  @Mutation
  private setSelectedDay(day: string): void {
    this.selectedDay = day;
  }
}
