import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Module, Mutation } from 'vuex-module-decorators';
import { Data } from '@/utils/types/WidgetData';
import { deepGet } from '@/utils/ObjectHelpers';

@Module({ namespaced: true, stateFactory: true })
export default class DynamicTitleWidgetStore extends WidgetBaseStore {
  private dataValues = {
    title: '',
    subtitle: '',
  };

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    const payload: Data = JSON.parse(this.widget?.payload || '{}');
    if (data.values.length > 0 && (payload.title || payload.subtitle)) {
      data.values.forEach((dataValue) => {
        if (payload.title) {
          const titleValue = deepGet(dataValue, String(payload.title));
          if (titleValue) this.dataValues.title = String(titleValue);
        }
        if (payload.subtitle) {
          const subtitleValue = deepGet(dataValue, String(payload.subtitle));
          if (subtitleValue) this.dataValues.subtitle = String(subtitleValue);
        }
      });
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      ...this.dataValues,
    };
  }
}
