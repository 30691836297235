




























import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';

/* eslint-disable max-len,no-underscore-dangle */
@Component({
  components: { ButtonComponent },
})
export default class RespondPopupComponent extends Vue {
  @Prop({ required: false, default: '' })
  private readonly message!: string;

  @Prop({ required: true, default: '' })
  private readonly declineText!: string;

  @Prop({ required: true, default: '' })
  private readonly okText!: string;

  @Prop({ required: false, default: false })
  private readonly isGlobal!: boolean;

  @Prop({ required: false, default: '' })
  private readonly uid!: string;

  @Prop({ required: false, default: false })
  private readonly isLoading!: boolean;

  private onAcceptClick(): void {
    this.$emit('accept-click');
  }

  private onDeclineConnection(): void {
    this.$emit('decline-click');
  }
}
