import UiMenu from '@/models/graphql/UiMenu';
import MenuModel from '@/models/MenuModel';
import UiMenuItemPayloadParams from '@/utils/types/UiMenuItemPayloadParams';

export default class MenuHelpers {
  static mapMenus(menu: UiMenu): MenuModel[] {
    if (menu && menu.items) {
      return menu.items.filter((item) => item.parentMenuItem === null)
        .map((m) => {
          const newMenu: MenuModel = { uid: '' };
          const payload = (JSON.parse(m.payload) as UiMenuItemPayloadParams);
          Object.assign(newMenu, {
            uid: m.uid,
            title: payload.label,
            name: payload.name,
            subtitle: payload.description,
            code: payload.code,
            type: m.type,
            menuItemType: payload.type,
            menuItemCount: 0,
            salesComponent: payload.salesComponent,
            socials: m.type === 'app_social_media' ? JSON.parse(m.payload || '{}') : null,
            action: payload.action,
            icon: payload.icon,
            targetPage: payload.targetPage,
            ...m.type === 'destination_external_link' ? { externalUrl: payload.url } : { internalUrl: payload.url },
          });
          if (m.subMenuItem && m.subMenuItem.length > 0) {
            const subMenu = m.subMenuItem.map((sub) => {
              const subPayload = JSON.parse(sub.payload) as UiMenuItemPayloadParams;
              return {
                uid: sub.uid,
                title: subPayload.label,
                subtitle: subPayload.description,
                name: subPayload.name,
                code: subPayload.code,
                type: sub.type,
                menuItemType: subPayload.type,
                menuItemCount: 0,
                salesComponent: subPayload.salesComponent,
                socials: sub.type === 'app_social_media' ? JSON.parse(sub.payload || '{}') : null,
                action: subPayload.action,
                icon: subPayload.icon,
                targetPage: subPayload.targetPage,
                ...sub.type === 'destination_external_link' ? { externalUrl: subPayload.url } : { internalUrl: subPayload.url },
              };
            });
            Object.assign(newMenu, { subMenu });
          } else {
            Object.assign(newMenu, { subMenu: [] });
          }
          return newMenu;
        });
    }
    return [];
  }

  static findMenuItemByType(menu: MenuModel[], type: string): MenuModel | undefined {
    return menu.find((item) => item.menuItemType === type)
      // eslint-disable-next-line max-len
      || menu.reduce<MenuModel | undefined>((acc, curr) => acc || MenuHelpers.findMenuItemByType(curr.subMenu || [], type), undefined);
  }
}
