import Message from '@/models/graphql/Message';
import GraphQlClients from '@/utils/enums/GraphQlClients';
import { ApolloQueryResult } from '@apollo/client';
import ChatBaseRepository from '@/repositories/chat/ChatBaseRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import CountInterface from '@/repositories/base/CountInterface';
import WriteInterface from '@/repositories/base/WriteInterface';

/* eslint-disable max-len */
export default class MessageRepository extends ChatBaseRepository<Message>
  implements WriteInterface<Message>, FilterInterface<Message>, CountInterface {
  delete(params: GqlPayloadParams): Promise<Message | undefined> {
    switch (params.operationName) {
      case 'DeleteMessage':
        return buildMutationGql({
          operationName: 'DeleteMessage',
          operation: 'deleteMessage',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        }).then((mutation) => this.use(GraphQlClients.CHAT_CLIENT).mutate(mutation, params.definition.variables))
          .then(() => new Message());
      default: return Promise.resolve(undefined);
    }
  }

  count(params: GqlPayloadParams): Promise<number | undefined> { // MessageFilter
    return buildQueryGql({
      operationName: 'MessagesCount',
      operation: 'message',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'messageCountFragment',
    }).then((query) => this.use().query<{ message: CountResponseParams[] }, {}>(query, params.definition.variables, false))
      .then((response: ApolloQueryResult<{ message: CountResponseParams[] }>) => response.data.message[0]._count); // eslint-disable-line no-underscore-dangle
  }

  filter(params: GqlPayloadParams): Promise<Message[]> {
    switch (params.operationName) {
      case 'MessagesAll':
        return buildQueryGql({
          operationName: 'MessagesAll',
          operation: 'message',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: params.fragmentName || 'messageAllFragment',
        }).then((query) => this.use().query<{ message: Message[] }, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{ message: Message[] }>) => response.data.message);

      case 'PaginatedMessages':
        return buildQueryGql({
          operationName: 'PaginatedMessages',
          operation: 'message',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'messagePaginatedFragment',
          authUser: params.authUser,
        }).then((query) => this.use().query<{ message: Message[] }, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{ message: Message[] }>) => response.data.message);

      default:
        return Promise.resolve([]);
    }
  }

  create(params: GqlPayloadParams): Promise<Message | undefined> {
    switch (params.operationName) {
      case 'CreateMessageReaction':
        return buildMutationGql({
          operationName: 'CreateMessageReaction',
          operation: 'createReaction',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        }).then((mutation) => this.use(GraphQlClients.CHAT_CLIENT).mutate(mutation, params.definition.variables))
          .then(() => new Message());

      default:
        return buildMutationGql({
          operationName: 'SendMessage',
          operation: 'sendMessage',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        }).then((mutation) => this.use(GraphQlClients.CHAT_CLIENT).mutate(mutation, params.definition.variables))
          .then(() => new Message());
    }
  }

  update(params: GqlPayloadParams): Promise<Message | undefined> {
    switch (params.operationName) {
      case 'RemoveMessageReaction':
        return buildMutationGql({
          operationName: 'RemoveMessageReaction',
          operation: 'removeReaction',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        }).then((mutation) => this.use(GraphQlClients.CHAT_CLIENT).mutate(mutation, params.definition.variables))
          .then(() => new Message());
      case 'BanUser':
        return buildMutationGql({
          operationName: 'BanUser',
          operation: 'banUser',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        }).then((mutation) => this.use(GraphQlClients.CHAT_CLIENT).mutate(mutation, params.definition.variables))
          .then(() => new Message());
      case 'UnBanUser':
        return buildMutationGql({
          operationName: 'UnBanUser',
          operation: 'unbanUser',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        }).then((mutation) => this.use(GraphQlClients.CHAT_CLIENT).mutate(mutation, params.definition.variables))
          .then(() => new Message());
      default:
        return buildMutationGql({
          operationName: 'UpdateChatState',
          operation: 'updateState',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        }).then((mutation) => this.use(GraphQlClients.CHAT_CLIENT).mutate(mutation, params.definition.variables))
          .then(() => new Message());
    }
  }
}
