







































































import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import VueContextLoading from '@/components/VueContextLoading';

@Component({
  components: {
    FontAwesomeComponent,
    ButtonComponent,
  },
})
export default class DeviceCameraComponent extends VueContextLoading {
  @Prop({ required: true, default: false })
  private readonly showCamera!: boolean;

  @Getter
  authUser!: CommunityUser;

  private photoTakenBase64Url = '';

  private currentStatus = 'default';

  private constraints = {
    video: true,
  };

  // eslint-disable-next-line class-methods-use-this
  private init(): void {
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia(this.constraints)
        .then((stream) => {
          const videoPlayer = document.querySelector('video');
          if (videoPlayer) {
            videoPlayer.srcObject = stream;
            videoPlayer.play();
          }
        })
        .catch(() => {
          this.$emit('error-photo', false);
        });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  pauseVideo(): void {
    const videoElem = document.querySelector('video');
    if (videoElem) {
      const stream = videoElem.srcObject as MediaStream;
      if (stream) {
        const tracks = stream.getTracks();

        tracks.forEach((track) => {
          track.stop();
        });

        videoElem.srcObject = null;
      }
    }
  }

  private async takePhoto(): Promise<void> {
    const photo = document.querySelector('canvas');
    const video = document.querySelector('video');

    const ctx = photo?.getContext('2d');

    if (photo && video) {
      photo.width = video.videoWidth;
      photo.height = video.videoHeight;
    }

    if (ctx && ctx.imageSmoothingEnabled) {
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      if (video && photo?.width && photo.height) {
        ctx.translate(video.videoWidth, 0);
        ctx.scale(-1, 1);
        ctx.drawImage(video, 0, 0, photo.width, photo.height);

        this.photoTakenBase64Url = photo.toDataURL('image/jpeg', 1);
      }
    }
  }

  private choosePhoto(): void {
    this.pauseVideo();
    this.$emit('take-photo', this.photoTakenBase64Url);
  }

  private reTakePhoto(): void {
    this.photoTakenBase64Url = '';
  }

  @Watch('photoTakenBase64Url')
  setStatus(): void {
    if (this.photoTakenBase64Url === '') {
      this.currentStatus = 'default';
    } else {
      this.currentStatus = 'photo-taken';
    }
  }

  mounted(): void {
    this.init();
  }
}
