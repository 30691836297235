












































































import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { addMinutes, format, startOfDay } from 'date-fns';
import { Component, Prop } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { State } from 'vuex-class';
import GUUID from '@/utils/GUUID';

@Component({
  components: { FontAwesomeComponent },
})
export default class PickerTimeComponent extends BreakpointWrapper {
  @State
  private dateLocale!: Locale;

  @Prop({ required: false, default: 0 })
  private readonly selectedTime!: number;

  @Prop({ required: false, default: 0 })
  private readonly diffSelectedTime!: number;

  @Prop({ required: false, default: false })
  private readonly showDiff!: boolean;

  private componentId = GUUID.uuidv4();

  private get timePickerList(): Array<Record<string, string | number | boolean>> {
    const result: Array<Record<string, string | number | boolean>> = [];
    let currentMinute = this.showDiff ? this.diffSelectedTime : 0;
    const modulo = currentMinute % 15;
    if (modulo > 0) {
      currentMinute += (15 - modulo);
    }
    for (let i = currentMinute; i <= 1425; i += 15) {
      const diff = (i - this.diffSelectedTime) / 60;
      if (diff > 0) {
        result.push({
          value: i,
          hide: i < currentMinute && false,
          selected: i === this.selectedTime,
          text: format(
            addMinutes(startOfDay(DateTimeHelper.getCurrentDateTime()), i),
            this.$t('app.date.defaultTimeFormat').toString(),
            { locale: this.dateLocale },
          ),
          diff: diff >= 0 ? `(${diff} hrs)` : '',
        });
      }
    }
    return result;
  }

  private scrollToDate(): void {
    if (this.selectedTime) {
      this.$nextTick(() => {
        const timesListEl = this.$el.querySelector('.times-list');
        if (timesListEl) {
          timesListEl.scrollTop = (((this.selectedTime - this.diffSelectedTime) / 15) * 40) - 40;
        }
      });
    }
  }
}
