

































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import { Getter, namespace } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';

const communityUserStore = namespace('CommunityUserStore');

@Component({
  data(): object {
    return {};
  },
  components: { AvatarSoloWidget },
})
export default class MemberInviteMultipleAccount extends Vue {
  @Getter
  private community!: Community;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: false, default: '' })
  private readonly exhibitorId!: string;

  @communityUserStore.Getter
  private fetchCommunityUsers!: CommunityUser[];

  private FileResourceHelper = FileResourceHelper;

  private selectedUser: CommunityUser | null = null;

  private get isMobile(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'sm' || this.currentBootstrapBreakpoint === 'xs');
  }

  private onUserChange(user: CommunityUser): void {
    this.selectedUser = user;
    this.$emit('user-change', user);
  }
}
