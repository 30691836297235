import { Action, Module } from 'vuex-module-decorators';
import SubEdition from '@/models/graphql/SubEdition';
import SubEditionRepository from '@/repositories/SubEditionRepository';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Module({ namespaced: true })
export default class SubEditionStore extends LoadableStore<SubEdition> {
  private readonly subEditionRepository = new SubEditionRepository();

  protected get repository(): SubEditionRepository {
    throw this.subEditionRepository;
  }

  @Action
  getFeedSubEdition(code: string): Promise<void> {
    this.context.commit('load', true);
    if (code) {
      return this.subEditionRepository.get({
        definition: buildQueryDefinition({
          filter: {
            value: { code },
            type: GqlEntityFilterType.SUB_EDITION_FILTER,
          },
        }),
      }).then((response) => {
        if (response) {
          this.context.commit('FeedItemWrapperStore/setFeedInitiator', response, { root: true });
          this.context.commit('load', false);
        }
      });
    }
    return Promise.resolve();
  }
}
