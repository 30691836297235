import { FetchResult } from '@apollo/client';
import CommunityUserProfileRole from '@/models/graphql/CommunityUserProfileRole';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import CreateInterface from '@/repositories/base/CreateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';

/* eslint-disable max-len */
export default class CommunityUserProfileRoleRepository extends GraphQlRepository<CommunityUserProfileRole>
  implements CreateInterface<CommunityUserProfileRole>, DeleteInterface<CommunityUserProfileRole> {
  create(params: GqlPayloadParams): Promise<CommunityUserProfileRole | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommunityUserProfileRoleCreateForUserAndProfileRole',
      operation: 'CommunityUserProfileRoleCreateForUserAndProfileRole',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserProfileRoleBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ CommunityUserProfileRoleCreateForUserAndProfileRole: CommunityUserProfileRole }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserProfileRoleCreateForUserAndProfileRole: CommunityUserProfileRole }>) => response.data?.CommunityUserProfileRoleCreateForUserAndProfileRole ?? undefined));
  }

  delete(params: GqlPayloadParams): Promise<CommunityUserProfileRole | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommunityUserProfileRoleDelete',
      operation: 'CommunityUserProfileRoleDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserProfileRoleBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ CommunityUserProfileRoleDelete: CommunityUserProfileRole }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserProfileRoleDelete: CommunityUserProfileRole }>) => response.data?.CommunityUserProfileRoleDelete ?? undefined));
  }
}
