import LoadableStore from '@/store/LoadableStore';
import LoadableState from '@/store/states/LoadableState';
import { Action, Mutation } from 'vuex-module-decorators';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { Data } from '@/utils/types/WidgetData';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import {
  DocumentNode, OperationDefinitionNode, parse, visit,
} from 'graphql';
import RootState from '@/store/states/RootState';
import { deepSearch } from '@/utils/ObjectHelpers';
import GraphqlQueryHelper from '@/utils/helpers/GraphqlQueryHelper';

export default abstract class WidgetBaseStore<T = Data> extends LoadableStore<LoadableState> {
  protected data: Array<T> = [];

  protected widget: UiPageWidget | null = null;

  protected payload: Data | null = null;

  protected query = '';

  protected mapping: object = {};

  protected widgetStoreName = '';

  protected gqlQueryConfig: Array<GqlComposeQueryDefinitionParams> = [];

  get props(): object {
    return this.mapping;
  }

  get count(): number | null {
    if (this.data
      && this.data.length > 0) {
      const data = this.data[0] as unknown as { count: number };
      return data.count === undefined ? null : data.count;
    }
    return null;
  }

  get one(): T | null {
    if (this.data && this.data.length > 0 && this.data[0]) {
      return this.data[0];
    }
    return null;
  }

  get all(): Array<T> {
    return this.data;
  }

  get childrenWidgets(): UiPageWidget[] {
    let widgets = [];
    if (this.widget && this.widget.subWidgets) {
      widgets = this.widget.subWidgets.map((sub) => ({
        uid: sub.uid,
        ...JSON.parse(sub.payload || '{}'),
      }));
    }
    return widgets;
  }

  @Action
  setDataConfigs(values?: Array<GqlComposeQueryDefinitionParams>): void {
    if (values) {
      this.context.commit('setGqlQueryConfig', values);
      this.context.commit(
        'WidgetDispatcherStore/setGqlQueryConfigs',
        { key: this.widgetStoreName, values },
        { root: true },
      );
    }
    if (this.query) {
      this.context.commit(
        'WidgetDispatcherStore/setGqlQuery',
        { key: this.widgetStoreName, value: this.query },
        { root: true },
      );
    }
  }

  @Mutation
  setWidgetStoreName(name: string): void {
    this.widgetStoreName = name;
  }

  @Mutation
  setData(data: {
    values: Array<T>;
    key: string;
    rootState?: RootState;
  }): void {
    this.data = data.values;
  }

  @Mutation
  setWidget(value: UiPageWidget): void {
    this.widget = value;
    if (this.widget
      && this.widgetStoreName
      && this.widget.payload) {
      this.payload = JSON.parse(this.widget.payload);
      if (this.payload
        && 'graphql' in this.payload
        && this.payload.graphql) {
        let graphQL = this.payload.graphql as string;
        const aliases: string[] = [];
        let ast: DocumentNode | null = null;
        if (graphQL.trim().startsWith('{')) {
          ast = parse(graphQL);
        } else {
          ast = parse(`{${graphQL.trim()}}`);
        }
        if (ast) {
          visit(ast, {
            OperationDefinition(node: OperationDefinitionNode) {
              if (node.selectionSet && node.selectionSet.selections) {
                node.selectionSet.selections.forEach((selection) => {
                  if (selection.kind === 'Field' && selection.alias && selection.alias.value) {
                    aliases.push(selection.alias.value);
                  }
                });
              }
            },
          });
        }

        if (aliases.length > 0) {
          graphQL = graphQL.replace(`${aliases[0]}:`, '');
        }

        if (!graphQL.includes('_cachable')) {
          graphQL = GraphqlQueryHelper.appendArgumentToQuery(
            graphQL,
            '_cachable',
            'BooleanValue',
            !deepSearch(GraphqlQueryHelper.extractFilterFromQuery(graphQL), '%authUser%'),
          );
        }
        this.query = `${this.widgetStoreName}: ${graphQL}`;
      }
    }
  }

  @Mutation
  setGqlQueryConfig(values: Array<GqlComposeQueryDefinitionParams>): void {
    this.gqlQueryConfig = values;
  }

  @Mutation
  mapper(): void {
    const data = this.data.length > 0 ? { data: this.data } : {};
    this.mapping = {
      ...this.payload,
      ...data,
    };
  }
}
