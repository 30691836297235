enum CompanyUserRoleStatusType {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  INVITED = 'INVITED',
  TO_BE_INVITED = 'TO_BE_INVITED',
  CANCELLED = 'CANCELLED',
  INVITE_CANCELLED = 'INVITE_CANCELLED',
}

export default CompanyUserRoleStatusType;
