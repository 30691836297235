import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CommunityUserVisited from '@/models/graphql/CommunityUserVisited';
import { CommunityUserVisitedFilter } from '@/graphql/_Filters/CommunityUserVisitedFilter';
import CommunityUserVisitedRepository from '@/repositories/CommunityUserVisitedRepository';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
/* eslint-disable max-len */
export default class CommunityUserVisitedStore extends LoadableStore<LoadableState> {
  private readonly communityUserVisitedRepository = new CommunityUserVisitedRepository();

  protected get repository(): CommunityUserVisitedRepository {
    return this.communityUserVisitedRepository;
  }

  @Action
  markAsVisited(payload: CommunityUserVisitedFilter): Promise<CommunityUserVisited | undefined> {
    this.context.commit('load', true);
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'user_CommunityUserUid',
          type: 'ID',
          value: payload.userId,
        },
        {
          fieldName: 'linkTarget_LinkableUid',
          type: 'ID',
          value: payload.linkedEntityId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMUNITY_USER_VISITED_INPUT,
          value: { targetType: payload.entityType },
        },
      ]),
    }).finally(() => {
      this.context.commit('load', false);
    });
  }

  @Action
  markUnvisited(payload: CommunityUserVisitedFilter): Promise<CommunityUserVisited | undefined> {
    this.context.commit('load', true);
    return this.repository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: payload.uid,
        },
      ]),
    }).finally(() => {
      this.context.commit('load', false);
    });
  }
}
