var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.chatErrors.haveErrors
    && (_vm.chatErrors.findInternetError
      || _vm.chatErrors.findServerError))?_c('div',{class:['message-box-container',
           { 'hide': _vm.isClosed },
           {'desktop': _vm.isDesktop},
           {'tablet': _vm.isTablet},
           {'mobile': _vm.isMobile}]},[_c('message-box-header',{key:_vm.renderHeader,attrs:{"force-status":4}}),(!_vm.isClosed)?_c('illustration-component',{attrs:{"illustration-type":_vm.chatErrors.findInternetError
      && _vm.chatErrors.findInternetError.type === _vm.ChatErrorType.INTERNET
      ? _vm.IllustrationType.NO_INTERNET : _vm.IllustrationType.NO_SERVER,"title":("" + (_vm.chatErrors.findInternetError
      && _vm.chatErrors.findInternetError.type === _vm.ChatErrorType.INTERNET
      ? _vm.$t('chat.content.list-view.no-internet.message')
      : _vm.$t('chat.content.list-view.no-internet.server')))}}):_vm._e()],1):(_vm.isDesktop)?_c('div',{class:['message-box-container desktop', { 'hide': _vm.isClosed }]},[_c('message-box-header',{key:_vm.renderHeader}),(!_vm.isClosed)?_c('message-box-content',{key:_vm.renderMessageBox}):_vm._e()],1):(_vm.isTablet)?_c('div',{class:['message-box-container tablet', { 'hide': _vm.isClosed }]},[(!_vm.isClosed)?_c('toolbox-message-container',{key:_vm.renderMessageBox,staticClass:"shadows-line-up"}):_vm._e()],1):(_vm.isMobile)?_c('div',{class:['message-box-container mobile', { 'hide': _vm.isClosed }]},[_c('message-box-header',{key:_vm.renderHeader}),(!_vm.isClosed)?_c('message-box-content',{key:_vm.renderMessageBox}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }