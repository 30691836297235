var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'item py-2 px-3 paragraph text-left text-nowrap w-100 d-flex align-items-center',
    _vm.disabled
      ? 'user-select-none bg-neutral-n-2-background text-neutral-n-6-label cursor-default'
      : _vm.isWarningColor ? 'text-red-r-3-primary-red' : 'text-neutral-n-8-dark-ink'
  ],on:{"click":[function($event){$event.preventDefault();return _vm.$emit('on-click')},function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('on-click-stop')}]}},[(_vm.icon)?_c('font-awesome-component',{attrs:{"icon":_vm.icon,"icon-classes":[
      'icon-16',
      _vm.isWarningColor ? 'text-red-r-3-primary-red' : 'text-neutral-n-6-label'
    ],"container-classes":"icon-24"}}):_vm._e(),_c('p',{staticClass:"m-0 text-left"},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }