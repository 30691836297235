var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.text || _vm.number || (_vm.icon && _vm.iconPosition))?_c('div',{class:['segmented-element d-flex align-items-center justify-content-center column-gap-2',
           'flex-nowrap', _vm.activeState ? 'bg-blue-b-1-light-blue' : 'bg-neutral-n-2-background',
           _vm.size === _vm.SizeDigit.S_40 ? 'py-12 px-3' : 'py-1 px-2', 'cursor-pointer'],attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.$emit('on-click');}}},[(_vm.icon && _vm.iconPosition === 'LEFT')?_c('font-awesome-component',{attrs:{"icon":_vm.icon,"icon-classes":[_vm.size === _vm.SizeDigit.S_40 ? 'icon-12' : 'icon-8',
                    _vm.iconColor
                      ? _vm.iconColor
                      : _vm.activeState ? 'text-blue-b-3-primary-blue' : 'text-neutral-n-6-label']}}):_vm._e(),(_vm.text)?_c('label',{class:['m-0 medium', _vm.activeState ? 'text-blue-b-3-primary-blue' : 'text-neutral-n-6-label',
             _vm.size === _vm.SizeDigit.S_40 ? 'label-1' : 'label-3', 'cursor-pointer']},[_vm._v(" "+_vm._s(_vm.$t(_vm.text))+" ")]):_vm._e(),(_vm.number !== null)?_c('label',{class:['m-0 medium text-white-white-100 bg-red-r-3-primary-red rounded-pill',
             _vm.size === _vm.SizeDigit.S_40 ? 'label-5 px-1 py-0' : 'number-sm', 'cursor-pointer']},[_vm._v(" "+_vm._s(_vm.number)+" ")]):_vm._e(),(_vm.icon && _vm.iconPosition === 'RIGHT')?_c('font-awesome-component',{attrs:{"icon":_vm.icon,"icon-classes":[_vm.size === _vm.SizeDigit.S_40 ? 'icon-12' : 'icon-8',
                    _vm.iconColor
                      ? _vm.iconColor
                      : _vm.activeState ? 'text-blue-b-3-primary-blue' : 'text-neutral-n-6-label']}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }