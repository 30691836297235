var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['toolbox-agenda-container-create-edit-view position-relative w-100', _vm.breakpoint.value]},[(!_vm.isMobile)?_c('h6',{staticClass:"m-0 text-left px-4 py-3"},[_vm._v(" "+_vm._s(_vm.$t(("toolbox.agenda.create-edit." + (_vm.fetchViewMode === _vm.ViewMode.CREATE ? 'title' : 'edit'))))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-column px-4 row-gap-4 create-edit-fields hide-scrollbar",class:{'mt-3': _vm.isMobile}},[_c('input-text',{attrs:{"default-value":_vm.title,"hint-text":_vm.fetchViewMode === _vm.ViewMode.CREATE
        ? ("" + (_vm.$t('toolbox.agenda.create-edit.input-title-hint')))
        : '',"label":("" + (_vm.$t('toolbox.agenda.create-edit.input-title'))),"required":true},on:{"update:defaultValue":function($event){_vm.title=$event},"update:default-value":function($event){_vm.title=$event}}}),_c('div',{staticClass:"date-time d-flex flex-column"},[_c('p',{staticClass:"text-left text-neutral-n-8-dark-ink mb-2"},[_vm._v(" "+_vm._s(_vm.$t('toolbox.agenda.create-edit.label-date-time'))+" ")]),_c('picker-date-component',{staticClass:"w-fit",attrs:{"date":_vm.pickerDateInput,"show-only-future-date":true,"type":"single-selection"},on:{"on-select":_vm.selectedDate}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"time d-flex align-items-center w-100 column-gap-2"},[_c('div',{staticClass:"d-flex flex-column row-gap-2 flex-1"},[_c('p',{staticClass:"text-left text-neutral-n-8-dark-ink m-0"},[_vm._v(" "+_vm._s(_vm.$t('toolbox.agenda.create-edit.label-date-from'))+" ")]),_c('picker-time-component',{attrs:{"selected-time":_vm.selectedPickerStartTime},on:{"on-select":_vm.selectedStartTime}})],1),_c('div',{staticClass:"d-flex flex-column row-gap-2 flex-1"},[_c('p',{staticClass:"text-left text-neutral-n-8-dark-ink m-0"},[_vm._v(" "+_vm._s(_vm.$t('toolbox.agenda.create-edit.label-date-to'))+" ")]),_c('picker-time-component',{attrs:{"diff-selected-time":_vm.selectedPickerStartTime,"selected-time":_vm.selectedPickerEndTime,"show-diff":true},on:{"on-select":_vm.selectedEndTime}})],1)])]),_c('user-selector-field',{attrs:{"meeting-request":_vm.fetchEventEdit.meetingRequest,"selected-moderator":_vm.fetchEventEdit.creator,"selected-users":_vm.selectedParticipants},on:{"select-change":_vm.onUserListChange,"on-creator-select":function (user) { return _vm.moderatorSelected = user; }}}),_c('input-text',{attrs:{"default-value":_vm.location,"hint-text":_vm.fetchViewMode === _vm.ViewMode.CREATE
        ? ("" + (_vm.$t('toolbox.agenda.create-edit.input-location-hint')))
        : '',"label":("" + (_vm.$t('toolbox.agenda.create-edit.input-location')))},on:{"update:defaultValue":function($event){_vm.location=$event},"update:default-value":function($event){_vm.location=$event}}}),_c('text-area-component',{staticClass:"mb-4",attrs:{"default-value":_vm.message,"hint-text":_vm.fetchViewMode === _vm.ViewMode.CREATE
        ? ("" + (_vm.$t('toolbox.agenda.create-edit.input-message-hint')))
        : '',"label":("" + (_vm.$t('toolbox.agenda.create-edit.input-message'))),"rows-height":3},on:{"update:defaultValue":function($event){_vm.message=$event},"update:default-value":function($event){_vm.message=$event}}})],1),_c('div',{staticClass:"d-flex align-items-center p-4 column-gap-2 bg-white save-cancel-buttons",class:_vm.isMobile
      ? ['bottom-0 left-0 right-0 px-3 pt-3',
         'm-0 bg-white']
      : []},[_c('button-component',{staticClass:"flex-1",class:{'flex-1': _vm.isMobile},attrs:{"text":("" + (_vm.$t('toolbox.agenda.create-edit.cancel'))),"size":"md","variant":"secondary"},on:{"on-click":_vm.onCancel}}),_c('button-component',{staticClass:"flex-1",class:{'flex-1': _vm.isMobile},attrs:{"disabled":!_vm.title,"loading":_vm.fetchMeetingSaveLoading,"text":("" + (_vm.$t('toolbox.agenda.create-edit.save'))),"size":"md","variant":"primary"},on:{"on-click":_vm.onSave}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }