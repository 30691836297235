export default class StringHelper {
  static capitalize(word: string): string {
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  static snakeCaseToPascalCase = (input: string): string => input
    .split('_')
    .reduce((res, word) => `${res}${word.charAt(0).toUpperCase()}${word.substr(1).toLowerCase()}`, '');

  static kebabToCamel = (str: string): string => (str.includes('-')
    ? str.replace(/-([a-z])/g, (match, group1) => group1.toUpperCase())
    : str);
}
