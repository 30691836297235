









































import { Component, Inject, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import ToastComponent from '@/components/ToastComponent.vue';
import ToastActionParams from '@/utils/types/ToastActionParams';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import NotificationListComponent from '@/components/notification/NotificationListComponent.vue';
import MessageBoxOptionsModal from '@/components/chat/MessageBoxOptionsModal.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import { EventCallback } from '@/utils/event-bus/EventsBus';
import MessageMenuItem from '@/utils/enums/chat/MessageMenuItem';
import { Md5 } from 'md5-typescript';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import SignInPromptModal from '@/components/SignInPromptComponent.vue';
import InformationRequestModal from '@/components/modals/InformationRequestModal.vue';

const authenticationStore = namespace('AuthenticationStore');
const toastStore = namespace('ToastStore');
const notificationStore = namespace('NotificationStore');

@Component({
  components: {
    InformationRequestModal,
    MessageBoxOptionsModal,
    ToastComponent,
    NotificationListComponent,
    SignInPromptModal,
  },
})
export default class BasePage extends BreakpointWrapper {
  @Inject({ from: 'windowWidth' })
  private readonly windowWidth!: { value: number };

  @Getter
  private readonly authUser!: CommunityUser;

  @authenticationStore.Getter
  private isAuthenticated!: boolean;

  @toastStore.Getter
  private actionsMade!: (ToastActionParams & { id: number })[];

  @notificationStore.Getter('isClosed')
  private isNotificationClosed!: boolean;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature | undefined;

  private informationRequestProps: EventCallback | null = null;

  private conversationOptionsModalData: {
    users: CommunityUser[];
    groupId: string;
    actionType: MessageMenuItem | null;
  } = {
    users: [],
    groupId: '',
    actionType: null,
  };

  private get urlChecksum(): string {
    if (this.$route.meta && this.$route.meta.routerKey) {
      const {
        query,
        params,
      } = this.$route;
      let q = '';
      if (query && Object.keys(query).length > 0) {
        q = JSON.stringify(query || {});
      }
      let p = '';
      if (params && Object.keys(params).length > 0) {
        p = JSON.stringify(params || {});
      }
      return Md5.init(`${this.$route.meta.routerKey}-${p}-${q}`);
    }
    return Md5.init(this.$route.fullPath);
  }

  mounted(): void {
    this.$eventsBus.on('request-information-modal',
      (event: Event | undefined, payload: EventCallback) => {
        this.informationRequestProps = payload;
      });
    this.$eventsBus.on('open-conversation-option-modal',
      (event: Event | undefined, payload: EventCallback) => {
        const props = payload as {
            users: CommunityUser[];
            groupId: string;
            actionType: MessageMenuItem | null;
          };
        this.conversationOptionsModalData = {
          users: [],
          groupId: '',
          actionType: null,
        };
        this.$bvModal.show('conversation-action-modal');
        if (props.actionType === MessageMenuItem.VIEW) {
          const users = props.users.filter((u) => u.uid !== this.authUser.uid);
          users.push(this.authUser);
          Object.assign(props, { users });
        }
        this.conversationOptionsModalData = props;
      });
    this.$eventsBus.on('close-conversation-option-modal', () => {
      this.closeMemberOptionModal();
    });
    this.updateToastPosition();
  }

  private closeMemberOptionModal(): void {
    this.$bvModal.hide('conversation-action-modal');
    this.conversationOptionsModalData = {
      groupId: '',
      users: [],
      actionType: null,
    };
  }

  @Watch('updateToastPosition')
  private updateToastPosition(): string {
    const toastContainer = document.getElementById('toast-container');
    if (toastContainer) {
      return `left: ${
        (this.windowWidth.value - toastContainer.getBoundingClientRect().width) / 2
      }px`;
    }
    return '';
  }

  @Watch('isNotificationClosed')
  private onNotificationOpen(): void {
    if (this.isNotificationClosed) {
      document.body.style.overflow = 'visible';
    } else if (!this.isDesktop) {
      document.body.style.overflow = 'hidden';
    }
  }
}
