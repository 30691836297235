import { ApolloQueryResult } from '@apollo/client';
import Survey from '@/models/graphql/Survey';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import CommunityUser from '@/models/graphql/CommunityUser';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import ReadInterface from '@/repositories/base/ReadInterface';

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/camelcase */
export default class SurveyRepository extends GraphQlRepository<CommunityUser>
  implements ReadInterface<Survey> {
  get(params: GqlPayloadParams): Promise<Survey | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetSurvey',
      operation: 'survey',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'surveyBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ survey: Array<Survey> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ survey: Array<Survey> }>) => response.data.survey[0]));
  }

  filter(params: GqlPayloadParams): Promise<Survey[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterSurvey',
      operation: 'survey',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'surveyBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ survey: Array<Survey> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ survey: Array<Survey> }>) => response.data.survey));
  }
}
