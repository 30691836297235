import BaseModel from '@/models/BaseModel';
import Community from '@/models/graphql/Community';
import Exhibitor from '@/models/graphql/Exhibitor';
import CommunityUser from '@/models/graphql/CommunityUser';
/* eslint-disable import/no-cycle */
import Meeting from '@/models/graphql/Meeting';

export default class MeetingRequest extends BaseModel {
  id?: number;

  community?: Community | null = null;

  message: string | null = null;

  timezoneName: string | null = null;

  availability: string | null = null;

  readState = false;

  trash = false;

  startTime!: string;

  endTime!: string;

  createdTime!: string;

  startTimestamp!: number;

  endTimestamp!: number;

  createdTimestamp!: number;

  deleted = false;

  exhibitor: Exhibitor | null = null;

  user: CommunityUser | null = null;

  companyRep: CommunityUser | null = null;

  guests: CommunityUser[] = [];

  meeting: Meeting | null = null;

  meetingRequestMeeting: Meeting | null = null;

  _commentCount = 0

  get availabilities(): string[] {
    return JSON.parse(this.availability || '{}');
  }

  static hydrate(item: object): MeetingRequest {
    const result = new MeetingRequest();
    Object.assign(result, item);

    return result;
  }
}
