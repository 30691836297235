import { ApolloQueryResult } from '@apollo/client';
import Category from '@/models/graphql/Category';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import ReadInterface from '@/repositories/base/ReadInterface';

/* eslint-disable max-len */
export default class CategoryRepository extends GraphQlRepository<Category>
  implements ReadInterface<Category> {
  filter(params: GqlPayloadParams): Promise<Category[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterCategories',
      operation: 'category',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'categoryBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ category: Array<Category> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ category: Array<Category> }>) => response.data.category));
  }

  get(params: GqlPayloadParams): Promise<Category | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetCategory',
      operation: 'category',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'categoryBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ category: Array<Category> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ category: Array<Category> }>) => response.data?.category[0]));
  }
}
