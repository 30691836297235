import BaseModel from '@/models/BaseModel';
/* eslint-disable import/no-cycle */
import Community from '@/models/graphql/Community';
import Edition from '@/models/graphql/Edition';
import Exhibitor from '@/models/graphql/Exhibitor';
import FileResource from '@/models/graphql/FileResource';
import CommunityUserLink from '@/models/graphql/CommunityUserLink';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';

class Article extends BaseModel {
  community?: Community;

  edition?: Edition;

  code?: string | null;

  name?: string;

  displaySequence?: number;

  subtitle?: string;

  authorName?: string;

  publicationTime?: Date;

  relatedArticles?: Array<Article>;

  keywords?: string;

  featured?: boolean;

  promoted?: boolean;

  summary?: string;

  content?: string;

  deleted?: boolean;

  exhibitor?: Exhibitor;

  images: Array<FileResource> = [];

  articleImages?: Array<FileResource>;

  createdTime?: Date;

  updatedTime?: Date;

  linked?: Array<CommunityUserLink>;

  _isBookmarked?: string | null;

  _isFollowed?: string | null;

  _isShared?: string | null;

  _isInAgenda?: string | null;

  _isConnected?: boolean;

  _isRecommendedForMe?: boolean;

  _myTags?: CommunityUserTag[];

  authorImg?: string;

  mainImage?: string;

  imageCaption?: string;

  tags?: CommunityUserTag[];

  channelName?: string;

  get mainPicture(): string {
    if (this.images && this.images[0]) {
      return FileResourceHelper.getFullPath(this.images[0], 'w40');
    }

    return '';
  }

  static hydrate(item: object): Article {
    const result = new Article();
    Object.assign(result, item);

    return result;
  }
}

export default Article;
