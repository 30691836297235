import { Component, Inject, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';
import WidgetBackground from '@/utils/widgets/WidgetBackground';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  inheritAttrs: false,
})
export default class VueBaseWidget extends BreakpointWrapper {
  @Inject({ from: 'windowWidth' })
  protected readonly windowWidth!: { value: number };

  @Getter
  protected readonly authUser!: CommunityUser;

  @Getter
  protected readonly community!: Community;

  @Prop({ required: false, default: null })
  protected readonly widget!: UiPageWidget;

  @Prop({ required: false, default: '' })
  protected readonly code!: string;

  @Prop({ required: false, default: '' })
  protected readonly classes!: string;

  @Prop({ required: false, default: 'None' })
  protected readonly background!: string;

  @Prop({ required: false, default: 'None' })
  protected readonly topGap!: string;

  @Prop({ required: false, default: 'None' })
  protected readonly bottomGap!: string;

  protected readonly WidgetBackground = WidgetBackground;

  protected WidgetTopGap: Record<string, string> = {
    NONE: '',
    QUARTER: 'pt-4',
    HALF: 'pt-40 pt-xl-5',
    FULL: 'pt-40 pt-md-80 pt-xl-6',
  };

  protected WidgetBottomGap: Record<string, string> = {
    NONE: '',
    QUARTER: 'pb-4',
    HALF: 'pb-40 pb-xl-5',
    FULL: 'pb-40 pb-md-80 pb-xl-6',
  };

  protected get classList(): string {
    const list: string[] = ['base-widget'];
    if (this.background) {
      list.push(this.WidgetBackground[this.background]);
    }
    if (this.topGap) {
      list.push(this.WidgetTopGap[this.topGap]);
    }
    if (this.bottomGap) {
      list.push(this.WidgetBottomGap[this.bottomGap]);
    }
    if (this.classes) {
      list.push(this.classes);
    }
    return list.join(' ').trim();
  }

  protected get getGaps(): string {
    const list: string[] = [''];
    if (this.topGap) {
      list.push(this.WidgetTopGap[this.topGap]);
    }
    if (this.bottomGap) {
      list.push(this.WidgetBottomGap[this.bottomGap]);
    }
    return list.join(' ').trim();
  }
}
