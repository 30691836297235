










































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class InputNumberComponent extends Vue {
  @Prop({ default: null })
  private defaultValue!: number | null;

  @Prop({ default: '' })
  readonly label!: string;

  @Prop({ default: '' })
  private max!: number;

  @Prop({ default: 0 })
  private min!: number;

  @Prop({ default: '' })
  readonly hintText!: string;

  private value = this.defaultValue;

  private focused = false;

  @Watch('defaultValue')
  onDefaultValueChange(): void {
    this.value = this.defaultValue;
  }

  @Watch('value')
  onValueChange(): void {
    this.$emit('on-change', this.value);
    this.$emit('update:defaultValue', this.value);
  }

  private add(): void {
    const input = this.$el.querySelector('input[type=number]') as HTMLInputElement;
    if (input) {
      input.stepUp();
      this.value = parseInt(input.value, 10);
    }
  }

  private minus(): void {
    const input = this.$el.querySelector('input[type=number]') as HTMLInputElement;
    if (input) {
      input.stepDown();
      this.value = parseInt(input.value, 10);
    }
  }
}
