enum EventEnum {
  CREATE_CONVERSATION = 'CREATE_CONVERSATION',
  ACCEPT_CONVERSATION = 'ACCEPT_CONVERSATION',
  DECLINE_CONVERSATION = 'DECLINE_CONVERSATION',
  DISCONNECT_CONVERSATION = 'DISCONNECT_CONVERSATION',
  ADD_PARTICIPANT = 'ADD_PARTICIPANT',
  DELETE_PARTICIPANT = 'DELETE_PARTICIPANT',
  UPDATE_CONVERSATION_STATE = 'UPDATE_CONVERSATION_STATE',
  SEND_MESSAGE = 'SEND_MESSAGE',
  UPDATE_MESSAGE = 'UPDATE_MESSAGE',
  DELETE_MESSAGE = 'DELETE_MESSAGE',
  DELETE_CONVERSATION = 'DELETE_CONVERSATION',
  BLOCK_USER = 'BLOCK_USER',
  BLOCKED_USER = 'BLOCKED_USER',
  UNBLOCK_USER = 'UNBLOCK_USER',
  UNBLOCKED_USER = 'UNBLOCKED_USER',
  SEND_MESSAGE_REACTION = 'SEND_MESSAGE_REACTION',
  REMOVE_MESSAGE_REACTION = 'REMOVE_MESSAGE_REACTION',
  CLOSE_CHAT = 'CLOSE_CHAT',
  OPEN_CHAT = 'OPEN_CHAT',
  BAN_USER = 'BAN_USER',
  UNBAN_USER = 'UNBAN_USER',
  CHAT_CLOSED = 'CHAT_CLOSED',
}

export default EventEnum;
