import { ApolloQueryResult, FetchResult } from '@apollo/client';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import WriteInterface from '@/repositories/base/WriteInterface';

/* eslint-disable max-len */
export default class CommunityUserTagRepository extends GraphQlRepository<CommunityUserTag>
  implements FilterInterface<CommunityUserTag>, WriteInterface<CommunityUserTag> {
  filter(params: GqlPayloadParams): Promise<CommunityUserTag[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterCommunityUserTags',
      operation: 'communityUserTag',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'communityUserTagBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ communityUserTag: Array<CommunityUserTag> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ communityUserTag: Array<CommunityUserTag> }>) => response.data.communityUserTag));
  }

  create(params: GqlPayloadParams): Promise<CommunityUserTag | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateCommunityUserTag',
      operation: 'CommunityUserTagCreateForUser',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserTagBaseFragment',
    }).then((mutation) => this.mutate<{ CommunityUserTagCreateForUser: CommunityUserTag }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserTagCreateForUser: CommunityUserTag }>) => response.data?.CommunityUserTagCreateForUser));
  }

  update(params: GqlPayloadParams): Promise<CommunityUserTag | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommunityUserTagUpdate',
      operation: 'CommunityUserTagUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserTagBaseFragment',
    }).then((mutation) => this.mutate<{ CommunityUserTagUpdate: CommunityUserTag }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserTagUpdate: CommunityUserTag }>) => response.data?.CommunityUserTagUpdate));
  }

  delete(params: GqlPayloadParams): Promise<CommunityUserTag | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeleteCommunityUserTag',
      operation: 'CommunityUserTagDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserTagBaseFragment',
    }).then((mutation) => this.mutate<{ CommunityUserTagDelete: CommunityUserTag }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserTagDelete: CommunityUserTag }>) => response.data?.CommunityUserTagDelete));
  }

  count(params: GqlPayloadParams): Promise<number | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetUserTagsCount',
      operation: 'communityUserTag',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'communityUserTagCountFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ communityUserTag: [{ count: number }] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ communityUserTag: [{ count: number }] }>) => response.data.communityUserTag[0].count));
  }

  removeFromEntity(params: GqlPayloadParams): Promise<CommunityUserTag | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommunityUserTagRemoveLinkTarget',
      operation: 'CommunityUserTagRemoveLinkTarget',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserTagBaseFragment',
    }).then((mutation) => this.mutate<{ CommunityUserTagRemoveLinkTarget: CommunityUserTag }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserTagRemoveLinkTarget: CommunityUserTag }>) => response.data?.CommunityUserTagRemoveLinkTarget));
  }

  setTagsForEntity(params: GqlPayloadParams): Promise<boolean | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'EntitySetCommunityUserTags',
      operation: 'setCommunityUserTagsForEntity',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: '',
    }).then((mutation) => this.mutate<{ setCommunityUserTagsForEntity: boolean }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ setCommunityUserTagsForEntity: boolean }>) => response.data?.setCommunityUserTagsForEntity));
  }
}
