
















































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import MessageBoxActions from '@/utils/enums/chat/MessageBoxActions';
import MessageBoxConversationSummary from '@/utils/types/chat/MessageBoxConversationSummary';
import { Getter, namespace } from 'vuex-class';
import ListViewItemParams from '@/utils/types/ListViewItemParams';
import EntityType from '@/utils/enums/EntityType';
import CommunityUser from '@/models/graphql/CommunityUser';
import MessageGroup from '@/models/graphql/MessageGroup';
import PillComponent from '@/components/pill/PillComponent.vue';
import MessageUserSuggestionComponent from '@/components/chat/MessageUserSuggestionComponent.vue';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import MessageBoxWriteComponent from '@/components/chat/MessageBoxWriteComponent.vue';
import MessageBoxMessages from '@/views/chat/MessageBoxMessages.vue';
import GUUID from '@/utils/GUUID';
import PillWidget from '@/components/pill/PillWidget.vue';
import GroupType from '@/utils/enums/chat/GroupType';
import MessageType from '@/utils/enums/chat/MessageType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import { runMathJax } from '@/utils/helpers/LatexHelper';

const communityUserConnectionStore = namespace('CommunityUserConnectionStore');
const chatStore = namespace('ChatDispatcherStore/ChatStore');

@Component({
  components: {
    IllustrationComponent,
    PillWidget,
    MessageBoxMessages,
    MessageBoxWriteComponent,
    MessageUserSuggestionComponent,
    PillComponent,
  },
})
export default class MessageBoxNewConversation extends Vue {
  @Getter
  private readonly authUser!: CommunityUser;

  @Prop({
    required: false,
    default: false,
  })
  private readonly isToolboxMode!: boolean;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly users!: CommunityUser[];

  @Prop({
    required: false,
    default: null,
  })
  private readonly targetId!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly targetType!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly targetData!: object;

  @chatStore.Getter
  private selectedGroup!: MessageGroup;

  @chatStore.State
  private status!: MessageBoxActions;

  @chatStore.Getter
  private summary!: MessageBoxConversationSummary;

  @chatStore.Mutation
  private setSelectedGroup!: (group: string | null) => void;

  @chatStore.Mutation
  private setNewConversation!: (group: object) => void;

  @chatStore.Mutation
  private changeStatus!: (status: MessageBoxActions) => void;

  @chatStore.Mutation
  private cleanupConversations!: () => void;

  @communityUserConnectionStore.Action
  private loadConnections!: () => Promise<CommunityUserConnection[]>;

  @communityUserConnectionStore.State
  private stateConnections!: CommunityUserConnection[];

  @chatStore.Getter
  private fetchPossibleGroups!: (users: string[]) => MessageGroup;

  @chatStore.Action
  private createConversation!: (model: {
    users: CommunityUser[];
    message: string | null;
    tempId: string;
    messageTempId: string;
    messageType: MessageType | null;
    targetId: string | null;
    targetType: string | null;
    targetMessageId: string | null;
  }) => Promise<boolean>;

  @chatStore.Action
  private sendMessage!: (model: {
    groupId: string;
    message: string;
    tempId: string;
    parentMessageId: string | null;
    targetMessageId: string | null;
    type: MessageType | null;
  }) => void;

  private selectedUsers: Array<CommunityUser> = [];

  private userConnections: Array<CommunityUser> = [];

  private editableElement!: HTMLElement;

  private isSearchActive = false;

  private toggleAddMembers = true;

  private renderMessageBoxWrite = 0;

  private tempGroupId = GUUID.uuidv4();

  private suggestedGroup: MessageGroup | undefined | null = null;

  private IllustrationType = IllustrationType;

  private get connections(): ListViewItemParams[] {
    if (this.userConnections.length === 0 && !this.isSearchActive) {
      this.extractUsersFromConnections();
    }
    return this.userConnections.filter((c) => c.uid !== this.authUser.uid)
      .map((u) => ({
        entityId: u.uid,
        entityType: EntityType.USER,
        src: u.profilePicture ? u.profilePicture : '',
        title: `${u.firstName} ${u.lastName}`,
        firstName: `${u.lastName}`,
        lastName: `${u.lastName}`,
        subtitle: u.jobTitle,
      })) || [];
  }

  created(): void {
    this.setSelectedGroup('');
    this.loadConnections();
    this.selectedUsers = this.users;
  }

  mounted(): void {
    this.$nextTick(() => {
      this.editableElement = document.querySelector('.new-message-input > .editable') as HTMLElement;
    });
  }

  @Watch('selectedUsers')
  updateSummary(): void {
    this.toggleAddMembers = false;
    this.suggestedGroup = this.fetchPossibleGroups(
      Array.from(new Set([...this.selectedUsers.map((u) => u.uid),
        this.authUser.uid,
      ])),
    );
    if (this.suggestedGroup && !this.targetId) {
      this.setSelectedGroup(this.suggestedGroup.uid);
    } else {
      this.setSelectedGroup('');
      const target = this.targetId && this.targetType
        ? {
          uid: this.targetId,
          __typename: this.targetType,
        } : null;
      this.setNewConversation(MessageGroup.hydrate({
        uid: this.tempGroupId,
        tempId: this.tempGroupId,
        users: this.selectedUsers,
        target: { ...(this.targetData || {}), ...target },
        selected: true,
        messages: [],
      }));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  updated(): void {
    runMathJax();
  }

  private extractUsersFromConnections(): void {
    this.userConnections = this.stateConnections.map((c) => {
      if (c.user.uid === this.authUser.uid) {
        return CommunityUser.hydrate(c.connectedUser as CommunityUser);
      }
      return CommunityUser.hydrate(c.user);
    }) || [];

    if (this.summary && this.summary.fullUsers) {
      this.userConnections = this.userConnections
        .filter(({ uid: id1 }) => !this.summary.fullUsers
          .some(({ uid: id2 }) => id2 === id1));

      this.userConnections = this.userConnections
        .filter(({ uid: id1 }) => !this.selectedUsers
          .some(({ uid: id2 }) => id2 === id1));
    }
  }

  private openToConnect(): void {
    this.$router.push({
      name: 'members_browser',
    })
      .catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      })
      .finally(() => {
        this.$eventsBus.emit('close-conversation-option-modal');
        this.$eventsBus.emit('close-toolbox');
      });
  }

  private onSearch(): void {
    const query = this.editableElement.textContent || '';
    this.extractUsersFromConnections();
    this.isSearchActive = query.length > 0;
    this.userConnections = this.userConnections
      .filter((u) => u.firstName?.toLowerCase()
                ?.includes(query.toLowerCase())
            || u.lastName?.toLowerCase()
                ?.includes(query.toLowerCase())
            || u.jobTitle?.toLowerCase()
                ?.includes(query.toLowerCase())
            || u.fullName?.toLowerCase()
                ?.includes(query.toLowerCase()));
  }

  private addMember(uid: string): void {
    const index = this.userConnections.findIndex((u) => u.uid === uid);
    if (index >= 0) {
      this.selectedUsers.push(this.userConnections[index]);
      this.userConnections.splice(index, 1);
      this.toggleAddMembers = false;
      this.isSearchActive = false;
      if (this.editableElement.textContent && this.editableElement.textContent.length > 0) {
        this.editableElement.textContent = '';
        this.userConnections = [];
      }
    }
  }

  private removeMember(index: number): void {
    this.userConnections.push(this.selectedUsers[index]);
    this.selectedUsers.splice(index, 1);
    this.toggleAddMembers = true;
  }

  private handleNewMessage(message: string): void {
    const tempMessageId = GUUID.uuidv4();
    if (this.selectedUsers.length > 0) {
      if (this.suggestedGroup && this.suggestedGroup?.uid === this.selectedGroup.uid) {
        if (this.selectedGroup.myState) {
          this.cleanupConversations();
          this.sendMessage({
            groupId: this.suggestedGroup.uid,
            message,
            tempId: tempMessageId,
            parentMessageId: null,
            targetMessageId: null,
            type: null,
          });
        }
      } else {
        const {
          uid,
          firstName,
          lastName,
          pictureFileResource,
          jobTitle,
        } = this.authUser;
        this.selectedUsers.push(CommunityUser.hydrate({
          uid,
          firstName,
          lastName,
          pictureFileResource,
          jobTitle,
        }));
        const group = {
          uid: this.tempGroupId,
          tempId: this.tempGroupId,
          users: this.selectedUsers,
          groupType: this.selectedUsers.length === 2 ? GroupType.DIRECT : GroupType.GROUP,
          messages: [{
            uid: tempMessageId,
            tempId: tempMessageId,
            content: message,
            type: MessageType.REGULAR,
            senttime: new Date().toISOString(),
            user: {
              uid,
              firstName,
              lastName,
              pictureFileResource,
              jobTitle,
            },
            group: this.selectedGroup,
          }],
          updated: true,
          selected: true,
        };
        this.setNewConversation(group);
        this.createConversation({
          users: (this.selectedGroup.users || []).filter((u) => u.uid !== uid),
          message,
          tempId: this.tempGroupId,
          messageTempId: tempMessageId,
          messageType: MessageType.REGULAR,
          targetMessageId: null,
          targetId: this.targetId,
          targetType: this.targetType,
        });
      }
      this.changeStatus(MessageBoxActions.MESSAGES);
    }
    this.renderMessageBoxWrite += 1;
  }
}
