














import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Variant from '@/utils/enums/Variant';
import PillWidget from '@/components/pill/PillWidget.vue';

@Component({
  components: { PillWidget },
})
export default class FilterItemComponent extends Vue {
  @Prop({ required: true })
  private readonly id!: string;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly selected!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly disabled!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly noPadding!: boolean;

  private checked = this.selected;

  private variantEnum = Variant;

  @Watch('selected')
  private updateSelected(): void {
    this.checked = this.selected;
  }
}
