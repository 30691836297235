import {
  VuexModule,
  Mutation,
} from 'vuex-module-decorators';
import RootState from '@/store/states/RootState';
import LoadPayloadParams from '@/utils/types/LoadPayloadParams';

export default abstract class LoadableStore<State> extends VuexModule<State, RootState> {
  private loading = false;

  private namespaces: Record<string, boolean> = {};

  @Mutation
  protected load(payload: LoadPayloadParams | boolean): void {
    if (typeof payload === 'object') {
      if (payload.namespace) {
        this.namespaces = { ...this.namespaces, [payload.namespace]: payload.loading };
      } else {
        this.loading = payload.loading;
      }
    } else {
      this.loading = payload;
    }
  }

  protected get rootLoading(): boolean {
    return this.context.rootGetters.isLoading;
  }

  get isLoading(): boolean {
    let loadingNamespaces = false;
    if (Object.keys(this.namespaces)) {
      loadingNamespaces = loadingNamespaces || Object.values(this.namespaces).some((el) => el);
    }

    return this.loading || this.rootLoading || loadingNamespaces;
  }
}
