import ErrorListFormatter from '@/utils/types/chat/ErrorListFormatter';
import { deepEqual } from '@/utils/ObjectHelpers';
import ChatError, { ChatErrorType, ChatErrorView } from '@/utils/types/chat/ChatError';

export default class ChatErrorList extends ErrorListFormatter<ChatError> {
  get findInternetError(): ChatError | undefined {
    const error: ChatError = {
      type: ChatErrorType.INTERNET,
      view: ChatErrorView.BOTH,
      group: 'ALL',
      request: null,
    };
    return this.findError(error);
  }

  get findServerError(): ChatError | undefined {
    const error: ChatError = {
      type: ChatErrorType.SERVER,
      view: ChatErrorView.BOTH,
      group: 'ALL',
      request: null,
    };
    return this.findError(error);
  }

  get findFetchConversationsError(): ChatError | undefined {
    return this.errors
      .find((er) => er.type === ChatErrorType.FETCH_CONVERSATIONS
      && er.view === ChatErrorView.CONVERSATIONS);
  }

  get checkForCreateConversationError(): boolean {
    return !!this.errors
      .find((er) => er.type === ChatErrorType.CREATE_CONVERSATION
      && er.view === ChatErrorView.MESSAGES);
  }

  get haveErrors(): boolean {
    return this.errors.length > 0;
  }

  findError(error: Partial<ChatError>): ChatError | undefined {
    return this.errors.find((er) => deepEqual(er, error));
  }

  findCreateConversationError(group: string): ChatError | undefined {
    return this.errors.find((er) => er.type === ChatErrorType.CREATE_CONVERSATION
      && er.view === ChatErrorView.MESSAGES
      && er.group === group);
  }

  removeCreateConversationError(group: string): void {
    this.errors = this.errors.filter((er) => !(er.type === ChatErrorType.CREATE_CONVERSATION
      && er.view === ChatErrorView.MESSAGES
      && er.group === group));
  }

  findFetchMessagesError(group: string): ChatError | undefined {
    return this.errors.find((er) => er.type === ChatErrorType.FETCH_MESSAGES
      && er.view === ChatErrorView.MESSAGES
      && er.group === group);
  }

  findErrorIndex(error: Partial<ChatError>): number {
    return this.errors.findIndex((er) => deepEqual(er, error));
  }

  findSendMessageErrors(group: string): ChatError[] {
    return this.errors
      .filter((err) => err.type === ChatErrorType.SEND_MESSAGE
        && err.view === ChatErrorView.MESSAGES
        && err.group === group);
  }

  removeSendMessagesErrors(group: string): void {
    this.errors = this.errors.filter((er) => !(er.type === ChatErrorType.SEND_MESSAGE
      && er.view === ChatErrorView.MESSAGES
      && er.group === group));
  }

  protected addError(error: ChatError): this {
    if (!this.findError(error)) {
      this.errors.push(error);
    }
    return this;
  }

  protected removeError(error: ChatError): this {
    const index = this.findErrorIndex(error);
    if (index > -1) {
      this.errors.splice(index, 1);
    }
    return this;
  }
}
