



















































































import {
  Component, Provide, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import Community from '@/models/graphql/Community';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CommunityUser from '@/models/graphql/CommunityUser';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import ToolboxComponent from '@/components/ToolboxComponent.vue';
import MessageBoxPanel from '@/views/chat/MessageBoxPanel.vue';
import NotFound from '@/views/error/NotFound.vue';
import ChatErrorList from '@/utils/types/chat/ChatErrorList';
import { ChatErrorType, ChatErrorView } from '@/utils/types/chat/ChatError';
import currentWindowSizeHelper from '@/utils/helpers/CurrentWindowSizeHelper';
import RespondPopupComponent from '@/components/RespondPopupComponent.vue';
import ToastActionParams from '@/utils/types/ToastActionParams';
import GdprFormComponent from '@/components/GdprFormComponent.vue';
import ChatModuleHelper from '@/components/chat/ChatModuleHelper.vue';
import jwtDecode from 'jwt-decode';
import JwtParams from '@/utils/types/JwtParams';
import CookieService from '@/services/CookieService';
import SessionToken from '@/utils/constants/SessionToken';
import { MetaInfo } from 'vue-meta';
import { RouteMeta } from 'vue-router';
import NotificationBox from '@/components/notificationBox/NotificationBox.vue';
import UiPage from '@/models/graphql/UiPage';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import { mixins } from 'vue-class-component';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import { loadMathJax, runMathJax } from '@/utils/helpers/LatexHelper';

const authenticationStore = namespace('AuthenticationStore');
const chatDispatcherStore = namespace('ChatDispatcherStore');
const notificationStore = namespace('NotificationStore');
const toastStore = namespace('ToastStore');
const pageStateManagementStore = namespace('PageStateManagementStore');

@Component({
  components: {
    NotificationBox,
    RespondPopupComponent,
    MessageBoxPanel,
    NotFound,
    ToolboxComponent,
    GdprFormComponent,
  },
  metaInfo(this: App): MetaInfo {
    return {
      meta: this.metaTags,
    } as MetaInfo;
  },
})
export default class App extends mixins(ChatModuleHelper, VueBaseNotify) {
  @Provide('windowWidth')
  private windowWidth = Vue.observable({ value: currentWindowSizeHelper(window).width });

  @Provide('windowHeight')
  private windowHeight = Vue.observable({ value: currentWindowSizeHelper(window).height });

  @Provide('breakpoint')
  private breakpoint = Vue.observable({ value: 'xl' });

  @Provide('isOnline')
  private isOnline = Vue.observable({ value: navigator.onLine });

  @authenticationStore.Getter
  private isAuthenticated!: boolean;

  @authenticationStore.State
  private authToken!: string;

  @notificationStore.Action('events')
  private notificationEvents!: () => Promise<{ unsubscribe: () => void }>;

  @notificationStore.Mutation('clearCache')
  private notificationClearCache!: () => void;

  @notificationStore.Action
  private getUnreadNotificationCount!: () => Promise<number | null>;

  @notificationStore.State
  private unreadNotifications!: number;

  @chatDispatcherStore.Action('events')
  private chatEvents!: () => Promise<{ unsubscribe: () => void }>;

  @chatDispatcherStore.Action('eventsTyping')
  private chatEventsTyping!: () => Promise<{ unsubscribe: () => void }>;

  @chatDispatcherStore.Action
  private onConnected!: () => void;

  @chatDispatcherStore.Action
  private onDisconnected!: () => void;

  @chatDispatcherStore.Action
  private onReconnected!: () => void;

  @chatDispatcherStore.Action('clearCache')
  private chatClearCache!: () => void;

  @pageStateManagementStore.State
  private pageReadyToDisplay!: number[];

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Getter
  private isLoading!: boolean;

  @Getter
  private currentPage!: UiPage;

  @Getter
  private authUser!: CommunityUser;

  @Getter
  private community!: Community;

  @Getter
  private readonly isUnifyExhibitorPortal!: boolean;

  @State
  private dateLocale!: Locale;

  @State
  private guestToken!: null | string;

  @State
  private pageClasses!: string;

  private FeatureKeys = FeatureKeys;

  private sound: HTMLAudioElement | null = null;

  private showCta = false;

  private showGdpr = false;

  private showFooter = false;

  private showHeader = false;

  private showToolbar = false;

  private showChatPanel = false;

  private metaSetup = false;

  private preloadWidget = false;

  get isReadyToDisplay(): boolean {
    if (this.pageReadyToDisplay.includes(this.currentPage?.id || 0)) {
      // call a custom js event
      document.dispatchEvent(new Event('app-loaded'));
      // call the event using the bridge
      const nomadBridge = (window as typeof window & { bridge?: { unifyPageLoaded?: Function } }).bridge;
      if (nomadBridge?.unifyPageLoaded) {
        nomadBridge.unifyPageLoaded();
      }
      return true;
    }

    return false;
  }

  get showTheRestOfThePage(): boolean {
    return this.isReadyToDisplay || (this.currentPage && this.currentPage.parentPage !== null);
  }

  private get metaTags(): Array<object> {
    const metaTags = [];
    if (this.community
        && this.community.organizer
        && this.community.organizer.editionITunesLink) {
      metaTags.push({
        name: 'apple-itunes-app',
        content: `app-id=${this.appleItunesAppId}`,
      });
    }
    return metaTags;
  }

  private get appleItunesAppId(): string | undefined {
    if (this.community
        && this.community.organizer
        && this.community.organizer.editionITunesLink
    ) {
      const itunesId = this.community.organizer.editionITunesLink.split('/id');
      return itunesId[1];
    }
    return undefined;
  }

  private get isChatPanelFeatureEnabled(): boolean {
    return this.hasChatFeature && this.hasChatHidePanelFeature;
  }

  private get chatErrors(): ChatErrorList {
    return this.$store.getters[`${this.storeContextPath}/fullChatErrors`];
  }

  private get playNewMessageSound(): boolean {
    return this.$store.state[`${this.storeContextPath}/playNewMessageSound`];
  }

  private get isMessageBoxClose(): boolean {
    return this.$store.getters[`${this.storeContextPath}/isClosed`];
  }

  private get unReadCount(): number {
    return this.$store.getters[`${this.storeContextPath}/unReadCount`];
  }

  private get isMobile(): boolean {
    return this.breakpoint && (this.breakpoint.value === 'sm' || this.breakpoint.value === 'xs');
  }

  private get hasChatFeature(): boolean {
    return this.isFeatureActivated(FeatureKeys.COMMUNITY_CHAT_FEATURE);
  }

  private get hasChatHidePanelFeature(): boolean {
    return this.isFeatureActivated(FeatureKeys.COMMUNITY_CHAT_PANEL);
  }

  private get entityPageUid(): string {
    if (this.currentPage && this.currentPage.paramName) {
      return this.$route.params[this.currentPage.paramName] || '';
    }

    return '';
  }

  private get entityPageType(): string {
    if (this.currentPage
        && this.currentPage.entityType
        && this.currentPage.entityType.code) {
      return this.currentPage.entityType.code || '';
    }

    return '';
  }

  private get showSideMenu(): boolean {
    return this.isUnifyExhibitorPortal || (!this.isUnifyExhibitorPortal && this.breakpoint.value !== 'xl');
  }

  beforeCreate(): void {
    this.storeContext = 'ChatStore';
  }

  // eslint-disable-next-line class-methods-use-this
  updated(): void {
    runMathJax();
  }

  created(): void {
    window.addEventListener('resize', () => {
      this.watchWindowWidth();
      this.watchWindowHeight();
    });
    window.addEventListener('online', () => {
      this.isOnline.value = true;
      if (this.hasChatFeature) {
        this.chatErrors.removeErrors({
          type: ChatErrorType.INTERNET,
          view: ChatErrorView.BOTH,
          group: 'ALL',
          request: null,
        });
      }
    });
    window.addEventListener('offline', () => {
      this.isOnline.value = false;
      if (this.hasChatFeature) {
        this.chatErrors.addErrors({
          type: ChatErrorType.INTERNET,
          view: ChatErrorView.BOTH,
          group: 'ALL',
          request: null,
        });
      }
    });
    document.addEventListener('app-loaded', this.preloadActions, { once: true });
    this.setBrowserTabTitle();
  }

  mounted(): void {
    if (this.community && this.community.landingCSSConfig) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.textContent = this.community.landingCSSConfig;
      document.head.appendChild(style);
    }
    this.getCurrentBreakpoint();
    ['unReadCount', 'unreadNotifications'].forEach((watch) => {
      this.$watch(watch, this.updateFavicon, { immediate: true });
    });
    window.addEventListener('resize', this.getCurrentBreakpoint);

    if (this.sound === null) {
      this.sound = new Audio('/sound/message-pop-sound.wav');
      this.sound.autoplay = false;
    }
    if (this.hasChatFeature) {
      const token = CookieService.getCookie(SessionToken) || '';
      if (token) {
        const decodedToken = jwtDecode(token) as JwtParams;
        if (decodedToken && decodedToken.r === 'GUEST') {
          this.$store.commit('AuthenticationStore/setAuthToken', token, { root: true });
        }
      }
    }
    if (this.community
        && this.community.organizer?.shortname
        && this.community.organizer?.itunesApplicationName
        && this.community.organizer.editionITunesLink && this.isFeatureActivated(FeatureKeys.COMMUNITY_ENABLE_SMART_APP_BANNER)) {
      let bannerTitle = this.community.organizer?.itunesApplicationName;
      if (bannerTitle.length > 25) {
        bannerTitle = `${bannerTitle.substring(0, 25)}...`;
      }
      this.createMetaTag('smartbanner:title', bannerTitle);
      this.createMetaTag('smartbanner:author', this.community.organizer.shortname);
      this.createMetaTag('smartbanner:price-suffix-apple', this.$t('smartbanner.price-suffix-apple') as string);
      this.createMetaTag('smartbanner:price', this.$t('smartbanner.price') as string);
      this.createMetaTag('smartbanner:button', this.$t('smartbanner.button') as string);
      this.createMetaTag('smartbanner:close-label', this.$t('smartbanner.close-label') as string);
      this.createMetaTag('smartbanner:button-url-apple', this.community.organizer.editionITunesLink);
      if (window.smartbanner?.parseMeta) {
        window.smartbanner.parseMeta();
        window.smartbanner.publish();
      }
    }
    if (this.featureByKey(FeatureKeys.COMMUNITY_LATEX_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_LATEX_FEATURE).enabled) {
      loadMathJax();
    }
  }

  destroyed(): void {
    window.removeEventListener('resize', this.getCurrentBreakpoint);
    window.removeEventListener('online', () => {
      delete this.isOnline;
    });
    window.removeEventListener('offline', () => {
      delete this.isOnline;
    });
  }

  private preloadActions(): void {
    this.$root.$emit('on-toolbox-count-load');
    this.getUnreadNotificationCount();
    this.preloadWidget = true;
  }

  private isFeatureActivated(code: FeatureKeys): boolean {
    return this.featureByKey(code) && this.featureByKey(code).enabled;
  }

  private setPlaySound(play: boolean): void {
    this.$store.commit(`${this.storeContextPath}/setPlaySound`, play);
  }

  private updateFavicon(): void {
    if (this.unReadCount < 1 && this.unreadNotifications < 1) {
      this.setFavicon();
      return;
    }

    const favicon = document.querySelectorAll('link[rel~="icon"]');
    if (favicon && this.community) {
      const communityLogo = FileResourceHelper.getFullPath(
        this.community.favIconFileResource,
        '',
        '/img/favicon.png',
      );

      const faviconSize = 16;
      const canvas = document.createElement('canvas');
      canvas.width = faviconSize;
      canvas.height = faviconSize;

      const context = canvas.getContext('2d');
      const img = document.createElement('img');
      img.src = communityLogo;
      img.setAttribute('crossOrigin', 'anonyomous');

      if (context) {
        img.onload = () => {
          context.drawImage(img, 0, 0, faviconSize, faviconSize);

          context.beginPath();
          context.arc(
            canvas.width - faviconSize / 4,
            faviconSize / 4,
            faviconSize / 4, 0, 2 * Math.PI,
          );
          context.fillStyle = '#dc3545';
          context.fill();

          const newFavicon = canvas.toDataURL('image/png');
          favicon.forEach((item) => item.setAttribute('href', newFavicon));
        };
      }
    }
  }

  private watchWindowWidth(): void {
    if (this.windowWidth) {
      this.windowWidth.value = currentWindowSizeHelper(window).width;
    }
  }

  private watchWindowHeight(): void {
    if (this.windowHeight) {
      this.windowHeight.value = currentWindowSizeHelper(window).height;
    }
  }

  @Watch('$route')
  private setComponentVisibility(): void {
    this.showGdpr = !(this.$route.meta && this.$route.meta.showGdpr === false);
    this.showCta = !(this.$route.meta && this.$route.meta.showCta === false);
    this.showFooter = !(this.$route.meta && this.$route.meta.footer === false);
    this.showHeader = !(this.$route.meta && this.$route.meta.header === false);
    this.showToolbar = !(this.$route.meta && this.$route.meta.toolbar === false);
    this.showChatPanel = !(this.$route.meta && this.$route.meta.chatPanel === false);
    this.setBrowserTabTitle();
    this.openAgendaDetailFromUrl();
  }

  /** Method to open a specific agenda detail from url */
  private openAgendaDetailFromUrl(): void {
    if (this.authUser && this.$route.query && this.$route.query.toolbarAgenda) {
      const {
        entityType,
        entityUid,
      } = this.$route.query.toolbarAgenda as unknown as Record<string, string>;
      if (entityType && entityUid) {
        this.$store.dispatch(
          'AgendaStore/openAgendaFromUrl',
          {
            entityType: entityType.toLowerCase(),
            entityUid,
          },
        )
          .then((success: boolean) => {
            if (success) {
              this.$eventsBus.emit('ontoolbox', {
                view: ToolbarMenuActions.TOOLBAR_AGENDA,
              });
            }
          });
      }
    }
  }

  private setBrowserTabTitle(): void {
    if (this.community) {
      if (this.$route.meta?.title) {
        document.title = this.getPageTitle();
        if (this.$route.meta.metaDescription || this.$route.meta.entityDescription) {
          this.setMetaTag(this.$route.meta);
        }
      } else {
        document.title = this.community?.shortName as string;
      }
    } else {
      document.title = 'Invalid URL';
    }
  }

  @Watch('isAuthenticated')
  private logoutUser(): void {
    const isLoggedOutPage = this.$route.name === 'find-account-email'
        || this.$route.name === 'register'
        || this.$route.name === 'reset-password'
        || this.$route.name === 'create-account'
        || this.$route.name === 'reset-password-email'
        || this.$route.name === 'enter-information'
        || this.$route.name === 'create-password'
        || this.$route.name === 'email-disambiguated';
    if (!this.isAuthenticated
        && !(this.$route.meta && this.$route.meta.guestOnly)
        && !isLoggedOutPage) {
      this.$router.push({ name: 'login' });
    }

    if (this.isAuthenticated) {
      this.afterLoginRedirection();
    }

    this.chatClearCache();
    this.notificationClearCache();
    if (this.isAuthenticated) {
      if (this.hasChatFeature) {
        this.onConnected();
        this.onDisconnected();
        this.onReconnected();
        this.chatEvents();
        this.chatEventsTyping();
      }

      this.notificationEvents();
      this.registerComponentForNotification();
    }
  }

  private afterLoginRedirection(): void {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('redirect')) {
      let query = {};
      if (searchParams.has('openTheaterMode')) {
        query = { query: { openTheaterMode: 'true' } };
      }
      this.$router.push({ path: `${decodeURIComponent(searchParams.get('redirect') || '')}`, ...query });
    } else if (this.$route.name === 'login') {
      this.$router.push({ path: '/' });
    }
  }

  @Watch('isLoading')
  private setFavicon(): void {
    if (this.community) {
      const favicon = document.querySelectorAll('link[rel~="icon"]');
      if (favicon) {
        const communityLogo = FileResourceHelper.getFullPath(
          this.community.favIconFileResource,
          '',
          '/img/favicon.png',
        );
        favicon.forEach((item) => item.setAttribute('href', communityLogo));
      }
    }
  }

  private getPageTitle(): string {
    let pageTitle = '';
    if (this.$route.meta) {
      if (this.$route.name && this.$route.name !== 'home') {
        if (this.$route.meta.metaTitle) {
          pageTitle = this.$route.meta.metaTitle as string;
        } else {
          pageTitle = this.$route.meta.title || (this.$t(`app.titles.${this.$route.name}`) as string);
        }
      }
      if (pageTitle !== '') {
        if (this.$route.meta.entityName) {
          return `${this.$route.meta.entityName.substring(0, 50)} | ${pageTitle} | ${this.community?.shortName}`;
        }
        return `${pageTitle.substring(0, 50)} | ${this.community?.shortName}`;
      }
      return this.community?.shortName as string;
    }
    return '';
  }

  private setMetaTag(meta: RouteMeta): void {
    if (meta) {
      let description = '';
      if (meta.entityDescription) {
        description = meta.entityDescription.substring(0, 150)
          .replace(/<\/?[^>]+(>|$)/g, '');
      } else {
        description = meta.metaDescription ? meta.metaDescription : '';
      }
      if (!this.metaSetup) {
        this.createMetaTag('description', description);
        this.metaSetup = true;
      } else {
        document.getElementsByName('description')[0].setAttribute('content', description);
      }
    }
  }

  private getCurrentBreakpoint(): void {
    const xs = window.matchMedia('(max-width: 575.98px)');
    const sm = window.matchMedia('(min-width: 576px) and (max-width: 767.98px)');
    const md = window.matchMedia('(min-width: 768px) and (max-width: 991.98px)');
    const lg = window.matchMedia('(min-width: 992px) and (max-width: 1199.98px');
    const xl = window.matchMedia('(min-width: 1200px)');
    if (xs.matches) {
      this.breakpoint.value = 'xs';
    }
    if (sm.matches) {
      this.breakpoint.value = 'sm';
    }
    if (md.matches) {
      this.breakpoint.value = 'md';
    }
    if (lg.matches) {
      this.breakpoint.value = 'lg';
    }
    if (xl.matches) {
      this.breakpoint.value = 'xl';
    }
  }

  @Watch('playNewMessageSound')
  private playMessageSound(): void {
    if (this.playNewMessageSound
        && this.sound) {
      this.sound.play();
    }
    this.setPlaySound(false);
  }

  @Watch('community', { immediate: true })
  private setCommunityLog(): void {
    if (this.community && this.community.matomoIdSite) {
      this.$logger.log(['setSiteId', this.community.matomoIdSite]);
    }
    if (this.community && this.community.code) {
      this.$logger.log(['setCustomDimension', 1, this.community.code]);
    } else {
      this.$logger.log(['setCustomDimension', 1, null]);
    }
  }

  @Watch('dateLocale')
  private setLocalLog(): void {
    if (this.dateLocale && this.dateLocale.code) {
      this.$logger.log(['setCustomDimension', 2, this.dateLocale.code]);
    } else {
      this.$logger.log(['setCustomDimension', 2, null]);
    }
  }

  @Watch('authUser')
  private setUserIdLog(): void {
    this.$logger.log(['setCustomDimension', 3, !!(this.authUser && this.authUser.uid)]);
    if (this.authUser && this.authUser.uid) {
      this.$logger.log(['setUserId', this.authUser.uid]);
    } else {
      this.$logger.log(['resetUserId']);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private createMetaTag(name: string, content: string): void {
    const meta = document.createElement('meta');
    meta.name = name;
    meta.content = content;
    document.head.append(meta);
  }
}
