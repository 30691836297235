enum IllustrationType {
  ERROR_OCCURRED = 'ERROR_OCCURRED',
  NO_INTERNET = 'NO_INTERNET',
  NO_SERVER = 'NO_SERVER',
  NO_TAGS = 'NO_TAGS',
  NO_RESULTS_FOUND = 'NO_RESULTS_FOUND',
  SUCCESS_ACTIVATED = 'SUCCESS_ACTIVATED',
  SUCCESS_PASSWORD = 'SUCCESS_PASSWORD',
  SUCCESS_WELCOME = 'SUCCESS_WELCOME',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  EXPIRED_LINK = 'EXPIRED_LINK',
  SEARCH_EMPTY = 'SEARCH_EMPTY',
  SEARCH_INVALID = 'SEARCH_INVALID',
  EMPTY_MESSAGES = 'EMPTY_MESSAGES',
  EMPTY_NOTIFICATIONS = 'EMPTY_NOTIFICATIONS',
  EMPTY_AGENDA = 'EMPTY_AGENDA',
  EMPTY_CONNECTIONS = 'EMPTY_CONNECTIONS',
  EMPTY_NOTEPAD = 'EMPTY_NOTEPAD',
  EMPTY_BOOKMARKS = 'EMPTY_BOOKMARKS',
  EMPTY_LIST = 'EMPTY_LIST',
  EMPTY_NOTHING = 'EMPTY_NOTHING',
  EMPTY_LIVE_CHAT = 'EMPTY_LIVE_CHAT',
  ERROR_404 = 'ERROR_404',
  ERROR_403 = 'ERROR_403',
  PHONE = 'PHONE',
  PHONE_WITHOUT_QR = 'PHONE_WITHOUT_QR'
}

export default IllustrationType;
