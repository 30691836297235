































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import Notification from '@/models/graphql/Notification';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
  format,
  fromUnixTime,
} from 'date-fns';
import Session from '@/models/graphql/Session';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Message from '@/models/graphql/Message';
import NotificationActionType from '@/utils/enums/notification/NotificationActionType';
import { runMathJax } from '@/utils/helpers/LatexHelper';

/* eslint-disable @typescript-eslint/camelcase */

const sessionRatingStore = namespace('SessionRatingStore');

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
    AvatarSoloWidget,
  },
})
export default class NotificationQnaComponent extends Vue {
  @sessionRatingStore.Action
  createReaction!: (payload: { rating: number; sessionId: string; userCommunityUid: string }) => void;

  @State
  private dateLocale!: Locale;

  @Prop({ required: true })
  private notification!: Notification;

  private NotificationActionType = NotificationActionType;

  private get message(): Message | null {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.MESSAGE) {
      return Message.hydrate(this.notification.triggered);
    }
    return null;
  }

  private get initiator(): CommunityUser | null {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator) {
      return CommunityUser.hydrate(this.notification.initiator);
    }
    return null;
  }

  private get title(): string {
    if (this.notification.action === NotificationActionType.QNA_QUESTION) {
      const session = this.message?.group?.target;
      if (session) {
        return Session.hydrate(session).name ?? '';
      }
      return '';
    }
    if (this.notification.initiator) {
      const user = CommunityUser.hydrate(this.notification.initiator);
      return user.fullName;
    }
    return '';
  }

  private get answer(): string {
    return this.message?.content ?? '';
  }

  private get dateReceived(): string {
    const today = DateTimeHelper.getCurrentDateTime();
    const createdTimeDate = fromUnixTime(this.notification.createdTimestamp as number);
    if (differenceInSeconds(today, createdTimeDate) < 60) {
      return `${this.$t('my-notification-list-component.time-ago.now')}`;
    }

    const diffInMinutes = differenceInMinutes(today, createdTimeDate);
    if (diffInMinutes < 60) {
      return `${this.$t('my-notification-list-component.time-ago.minutes', {
        number: diffInMinutes,
      })}`;
    }

    const diffInDays = differenceInDays(today, createdTimeDate);
    if (diffInDays === 1) {
      return ` ${this.$t('my-notification-list-component.time-ago.yesterday')}`;
    }
    if (diffInDays < 1) {
      return `${this.$t('my-notification-list-component.time-ago.hours', {
        number: differenceInHours(today, createdTimeDate),
      })}`;
    }
    if (diffInDays < 7) {
      return `${this.$t('my-notification-list-component.time-ago.days', { number: diffInDays })}`;
    }

    if (differenceInYears(today, createdTimeDate) === 0) {
      return format(createdTimeDate, `${this.$t('app.date.monthDayShort')}`, {
        locale: this.dateLocale,
      });
    }
    return format(createdTimeDate, `${this.$t('app.date.defaultDateFormat')}`, {
      locale: this.dateLocale,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  updated(): void {
    runMathJax();
  }

  private closeNotificationModal(): void {
    this.$emit('on-close-notification');
  }

  private onInteractedWith(close = true): void {
    if (close) {
      this.closeNotificationModal();
    }
    if (!this.notification.interactedWith) {
      this.$emit('on-interacted-with', this.notification.uid);
    }
  }

  private onDelete(): void {
    this.$emit('on-delete', this.notification.uid);
  }

  private openDetail(): void {
    this.onInteractedWith(true);
    const session = this.message?.group?.target;
    if (session) {
      this.$router.push({
        name: 'session-detail',
        params: { sessionId: session.uid },
      })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        });
    }
  }
}
