import ChatBaseRepository from '@/repositories/chat/ChatBaseRepository';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import MessageGroupState from '@/models/graphql/MessageGroupState';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';

export default class MessageGroupStateRepository extends ChatBaseRepository<MessageGroupState>
  implements UpdateInterface<MessageGroupState> {
  update(params: GqlPayloadParams): Promise<MessageGroupState | null> {
    return buildMutationGql({
      operationName: 'MessageGroupToggleMute',
      operation: 'MessageGroupStateUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'messageGroupStateUpdateFragment',
    }).then((mutation) => this.use().mutate<{ MessageGroupStateUpdate: MessageGroupState}>(mutation, params.definition.variables))
      .then((response) => response?.data?.MessageGroupStateUpdate || null);
  }
}
