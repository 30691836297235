export default abstract class ErrorListFormatter<ListType> {
  errors: Array<ListType> = [];

  abstract get haveErrors(): boolean;

  addErrors(errors: ListType[] | ListType): this {
    if (Array.isArray(errors)) {
      errors.forEach((error) => {
        this.addError(error);
      });
    } else {
      this.addError(errors);
    }

    return this;
  }

  removeErrors(errors: ListType[] | ListType): this {
    if (Array.isArray(errors)) {
      errors.forEach((error) => {
        this.removeError(error);
      });
    } else {
      this.removeError(errors);
    }

    return this;
  }

  abstract findError(error: ListType): ListType | undefined;

  abstract findErrorIndex(error: ListType): number;

  protected abstract addError(error: ListType): this;

  protected abstract removeError(error: ListType): this;
}
