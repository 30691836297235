var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authUser)?_c('div',{class:[
    'message-box-write d-flex flex-column row-gap-4',
    {'no-hover': _vm.disable},
    {'large shadows-line-up p-3': _vm.isForChat},
    _vm.isChatClosed
      ? 'bg-neutral-n-2-background'
      : _vm.darkMode ? 'bg-neutral-n-8-dark-ink' : 'bg-white-white-100' ]},[(_vm.isChatClosed)?_c('div',{staticClass:"d-flex align-items-center justify-content-center py-2"},[_c('span',{staticClass:"m-0 label label-2 medium text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('chat.content.messages.closed-chat'))+" ")])]):[(_vm.messageToReplyTo)?_c('div',{staticClass:"message-to-reply-to d-flex justify-content-between align-items-center gap-5 w-100"},[_c('div',{staticClass:"content d-flex flex-column w-75"},[_c('label',{staticClass:"label-3 medium m-0 text-left text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.$tc('chat.content.messages.replying', 0, { name: _vm.userMessageName }))+" ")]),_c('label',{staticClass:"label-3 m-0 text-left text-neutral-n-6-label ellipsis-1"},[_vm._v(" "+_vm._s(_vm.messageToReplyTo.content)+" ")])]),_c('button-icon-component',{attrs:{"rounded":true,"size":"sm","variant":"tertiary"},on:{"on-click":function($event){return _vm.$emit('on-remove-reply-to-message')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-16","icon":"far fa-xmark"}})]},proxy:true}],null,false,819952424)})],1):_vm._e(),_c('div',{class:['d-flex align-items-center border-1 px-3 py-2',
               {'bg-neutral-n-3-disable': _vm.disable && !_vm.darkMode},
               {'bg-neutral-n-7-light-ink': _vm.disable && _vm.darkMode},
               {'bg-neutral-n-6-label border-blue-b-3-primary-blue': _vm.darkMode && !_vm.onFocus}]},[_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.messageText),expression:"messageText"}],ref:"sendMessage",class:[
          'send-message paragraph paragraph-2 x-small w-100 border-0 paragraph p-0',
          {'dark': _vm.darkMode},
          {'disabled': _vm.disable}
        ],attrs:{"disabled":_vm.disable,"placeholder":_vm.placeholder && _vm.placeholder.length > 0 ?
          _vm.placeholder
          : _vm.$t('chat.content.messages.input-placeholder'),"data-autoresize":"","rows":"1"},domProps:{"value":(_vm.messageText)},on:{"blur":function($event){_vm.onFocus = false;},"focus":function($event){_vm.onFocus = true;},"input":[function($event){if($event.target.composing){ return; }_vm.messageText=$event.target.value},function($event){return _vm.typing()}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.emit.apply(null, arguments)}}},'textarea',_vm.useTestDataAttribute(_vm.dataCy),false)),_vm._v(" "),_c('b-button',{staticClass:"send-btn bg-transparent border-0 m-0",on:{"click":_vm.emit}},[_c('font-awesome-component',{attrs:{"icon-classes":['icon-16', _vm.messageText.length === 0
            ? 'text-neutral-n-5-placeholder'
            : 'text-blue-b-3-primary-blue'],"container-classes":"icon-24","icon":"far fa-paper-plane-top"}})],1)],1)]],2):_c('div',{staticClass:"px-3 py-4 d-flex justify-content-center align-items-center shadows-line-up"},[_c('p',{staticClass:"paragraph-1 m-0 text-neutral-n-6-label"},[_c('b-link',{staticClass:"text-blue-b-3-primary-blue",on:{"click":_vm.signIn}},[_vm._v(" "+_vm._s(_vm.$t('video-player.theater.chat.sign-in'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('video-player.theater.chat.join'))+" ")],1)])}
var staticRenderFns = []

export { render, staticRenderFns }