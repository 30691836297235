import ExhibitorContact from '@/models/graphql/ExhibitorContact';
import ExhibitorContactRepository from '@/repositories/ExhibitorContactRepository';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { ExhibitorContactFilter } from '@/graphql/_Filters/ExhibitorContactFilter';
import LoadableState from '@/store/states/LoadableState';

@Module({ namespaced: true })
export default class ExhibitorContactStore extends LoadableStore<LoadableState> {
  exhibitorContacts: ExhibitorContact[] = [];

  private readonly exhibitorContactRepository = new ExhibitorContactRepository();

  get fetchExhibitorContacts(): ExhibitorContact[] {
    return this.exhibitorContacts;
  }

  @Action
  filterExhibitorContact(filter: ExhibitorContactFilter): void {
    const oldExhibitorContacts = this.exhibitorContacts;
    this.exhibitorContactRepository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.EXHIBITOR_CONTACT_FILTER,
        },
      }),
    }).then((response) => {
      this.context.commit('setElements', response);
    }).catch(() => this.context.commit('setElements', oldExhibitorContacts));
  }

  @Action
  createExhibitorContact(payload: { uid: string; exhibitorContact: ExhibitorContact }): Promise<ExhibitorContact | undefined> {
    const oldExhibitorContacts = this.exhibitorContacts;
    return this.exhibitorContactRepository.create({
      definition: buildMutationDefinition([{
        fieldName: 'exhibitor_ExhibitorUid',
        type: GqlEntityInputType.ID,
        value: payload.uid,
      },
      {
        fieldName: 'entity',
        type: GqlEntityInputType.EXHIBITOR_CONTACT_INPUT,
        value: payload.exhibitorContact,
      }]),
    }).then((res: ExhibitorContact | undefined) => {
      this.context.commit('addElements', res);
      return res;
    }).catch(() => {
      this.context.commit('setElements', oldExhibitorContacts);
      return undefined;
    });
  }

  @Action
  deleteExhibitorContact(uid: string): void {
    const oldExhibitorContacts = this.exhibitorContacts;
    this.exhibitorContactRepository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: GqlEntityInputType.ID,
        value: uid,
      }]),
    }).catch(() => this.context.commit('setElements', oldExhibitorContacts));
  }

  @Action({ rawError: true })
  updateExhibitorContact(exhibitorContact: ExhibitorContact): Promise<ExhibitorContact | undefined> {
    const oldExhibitorContacts = this.exhibitorContacts;
    return this.exhibitorContactRepository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.EXHIBITOR_CONTACT_INPUT,
        value: exhibitorContact,
      }]),
    })
      .then((res) => res)
      .catch(() => {
        this.context.commit('setElements', oldExhibitorContacts);
        return undefined;
      });
  }

  @Mutation
  setElements(exhibitorContacts: ExhibitorContact[]): void {
    this.exhibitorContacts = exhibitorContacts;
  }

  @Mutation
  addElements(exhibitorContact: ExhibitorContact): void {
    this.exhibitorContacts.push(exhibitorContact);
  }
}
