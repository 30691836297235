import { Action, Module, Mutation } from 'vuex-module-decorators';
import jwtDecode from 'jwt-decode';
import LoadableStore from '@/store/LoadableStore';
import RootState from '@/store/states/RootState';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import AuthResponseParams from '@/utils/types/AuthResponseParams';
import JwtParams from '@/utils/types/JwtParams';
import Logger from '@/utils/logger/Logger';
import CommunityUser from '@/models/graphql/CommunityUser';
import CookieService from '@/services/CookieService';
import SignInStep from '@/utils/enums/SignInStep';
import AccountRepository from '@/repositories/AccountRepository';

@Module({ namespaced: true })
export default class AuthenticationStore extends LoadableStore<CommunityUser> {
  authErrors: string[] = [];

  justSignedOut = false;

  authToken = '';

  private accountRepository = new AccountRepository();

  get fetchJustSignedOut(): boolean {
    return this.justSignedOut;
  }

  get isAuthenticated(): boolean {
    return !!this.context.rootState.authUser && !!CookieService.getCookie(AUTH_TOKEN);
  }

  private get repository(): AccountRepository {
    return this.accountRepository;
  }

  @Action
  // eslint-disable-next-line max-len
  login(credentials: { username: string; password: string; remember: boolean } | null = null): Promise<CommunityUser | null> {
    this.context.commit('load', true);
    const { rootState, rootGetters } = this.context;

    if (credentials) {
      const { username, password, remember } = credentials;
      if (rootGetters.communityCode) {
        return this.repository.login(username, password, rootGetters.communityCode)
          .then((response): Promise<CommunityUser | null> => {
            this.context.commit('authenticate', { response, rootState, remember });
            const authResponse = response as unknown as AuthResponseParams;
            const repositoryResult = this.context.dispatch(
              'CommunityUserStore/getForCommunityUserAuth',
              { filter: { uid: authResponse.userUid } },
              { root: true },
            ).then((res) => {
              if (res) {
                rootState.authUser = CommunityUser.hydrate(res);
                this.context.commit('setAuthToken', response.token);
                Logger.prototype.log(['trackEvent', 'communityUser', 'signIn',
                  `${rootState.authUser.firstName} ${rootState.authUser.lastName}`, 0, {
                    ...Logger.prototype
                      .serialiseAuthUserDimensions(
                        rootState.authUser,
                        rootState.community?.code ?? '',
                        'communityUser',
                      ),
                    dimension2: this.context.rootState.i18n?.locale || '',
                  }]);
                return res;
              }

              return null;
            });
            this.context.commit('load', false);

            return repositoryResult;
          }).catch(() => {
            this.context.commit('unAuthenticate', rootState);
            this.context.commit(
              'authFailure',
              'You have entered an invalid username or password',
            );
            this.context.commit('load', false);
            Logger.prototype.log(
              [
                'trackEvent',
                'communityUser',
                'signInFailed',
                '',
                0,
                { dimension9: 'communityUser' },
              ],
            );
            return new Promise((resolve) => resolve(null));
          });
      }
    }

    const token = CookieService.getCookie(AUTH_TOKEN) as string;
    const decodedToken = jwtDecode(token) as JwtParams;
    return this.context.dispatch(
      'CommunityUserStore/getForCommunityUserAuth',
      { filter: { uid: decodedToken.u ?? '' } },
      { root: true },
    ).then(((res) => {
      if (res) {
        rootState.authUser = CommunityUser.hydrate(res);
        this.context.commit('setAuthToken', token);
        return rootState.authUser;
      }

      return new Promise((resolve) => resolve(null));
    }));
  }

  @Action
  setAuthStep(step: SignInStep | null): void {
    this.context.rootState.authStep = step;
  }

  @Action
  setAuthEmail(email: string | null): void {
    this.context.rootState.authEmail = email;
  }

  @Mutation
  mutateJustSignedOut(): void {
    this.justSignedOut = true;
  }

  @Mutation
  setAuthToken(token: string): void {
    this.authToken = token;
  }

  @Mutation
  mutateResetJustSignedOut(): void {
    this.justSignedOut = false;
  }

  @Action
  resetJustSignedOut(): void {
    this.context.commit('mutateResetJustSignedOut');
  }

  @Action
  logout(): Promise<void> {
    const { rootState } = this.context;
    this.context.commit('unAuthenticate', rootState);
    this.context.commit('mutateLoadingGuestToken', true, { root: true });
    // This method loads the domain info and sets the guest token to the authToken cookie
    return this.context
      .dispatch('loadCommunityCodeFromDomain', '', { root: true })
      .then((payload) => {
        this.context.commit('setAuthToken', payload.token);
        this.context.commit('load', false);
      });
  }

  @Action
  register(newUser: Partial<CommunityUser>): Promise<void | Response | string> {
    this.context.commit('load', true);
    const { rootGetters } = this.context;
    return this.context.dispatch(
      'CommunityUserStore/getForCommunityUserAuth',
      { filter: { email: newUser.email } },
      { root: true },
    ).then((response: CommunityUser | undefined) => {
      this.context.commit('load', false);
      if (response?.uid) {
        this.context.commit('authFailure', 'This email already exists.');
        return Promise.reject(new Error('This email already exists.'));
      }
      return this.repository.register(newUser, rootGetters.communityCode);
    }).catch((err) => {
      this.context.commit('authFailure', err);
      return err.message;
    });
  }

  @Action
  resetAuthErrors(): void {
    this.context.commit('resetAuthErrorsMutation');
  }

  @Mutation
  resetAuthErrorsMutation(): void {
    this.authErrors = [];
  }

  @Mutation
  authenticate(params: {
    response: AuthResponseParams;
    rootState: RootState;
    remember: boolean;
  }): void {
    this.authErrors = [];
    const { response } = params;
    if (response.token) {
      if (params.remember) {
        CookieService.setCookie(AUTH_TOKEN, response.token, 365);
      } else {
        CookieService.setCookie(AUTH_TOKEN, response.token);
      }
    }
  }

  @Mutation
  // eslint-disable-next-line class-methods-use-this
  unAuthenticate(rootState: RootState): void {
    // eslint-disable-next-line no-param-reassign
    rootState.authUser = null;
    CookieService.deleteCookie(AUTH_TOKEN);
  }

  @Mutation
  authFailure(errors: string): void {
    this.justSignedOut = false;
    if (this.authErrors.includes(errors)) {
      const index = this.authErrors.indexOf(errors);
      this.authErrors.splice(index, 1, errors);
    } else {
      this.authErrors = [...this.authErrors, errors];
    }
  }
}
