import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import ToastActionParams from '@/utils/types/ToastActionParams';
import LoadableStore from '@/store/LoadableStore';

interface ToastState extends LoadableState {
  toastActions: ToastActionParams & { id: number }[];

  toastId: number;
}

@Module({ namespaced: true })
export default class ToastStore extends LoadableStore<ToastState> {
  private toastActions: (ToastActionParams & { id: number })[] = [];

  private toastId = 0;

  get actionsMade(): (ToastActionParams & { id: number })[] | null {
    return this.toastActions;
  }

  @Mutation
  setActionsMade(toast: ToastActionParams & { id: number }): void {
    this.toastId += 1;
    this.toastActions = [...this.toastActions, toast];
  }

  @Mutation
  removeActionsMade(id: number): void {
    const foundIndex = this.toastActions
      .findIndex((toast) => toast.id === id);
    if (foundIndex >= 0) {
      this.toastActions.splice(foundIndex, 1);
    }
  }

  @Action
  addNewAction(payload: ToastActionParams): void {
    if (this.actionsMade) {
      const index = this.actionsMade.findIndex((
        toast,
      ) => toast.type === payload.type);
      if (index === -1) {
        const newPayload = Object.assign(payload, { id: this.toastId });
        this.context.commit('setActionsMade', newPayload);
      }
    } else {
      const newPayload = Object.assign(payload, { id: this.toastId });
      this.context.commit('setActionsMade', newPayload);
    }
  }
}
