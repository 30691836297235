




































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import InputText from '@/components/InputText.vue';

@Component({
  components: { InputText },
})
export default class CheckboxSwitchComponent extends Vue {
  @Prop({ required: false, default: '' })
  private readonly text!: string;

  @Prop({ required: false, default: false })
  private readonly value!: boolean;

  @Prop({ required: false, default: false })
  private readonly readonly!: boolean;

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean;

  @Prop({ required: false, default: false })
  private readonly isLoading!: boolean;

  @Prop({ required: false, default: true })
  private readonly isSwitcher!: boolean;

  @Prop({ required: false, default: true })
  private readonly textPosStart!: boolean;

  @Prop({ required: false, default: true })
  private readonly ellipsis!: boolean;

  @Prop({ required: false, default: false })
  private readonly editable!: boolean;

  @Prop({ required: false, default: false })
  private readonly isMandatory!: boolean;

  private checked = this.value;

  @Watch('value')
  updateValue(): void {
    this.checked = this.value;
  }

  private onTextAnswerBlur(text: string): void {
    this.$emit('on-text-blur', text);
  }

  private onTextAnswerChange(text: string): void {
    this.$emit('on-text-change', text);
  }

  private onFocusIn(): void {
    this.$emit('focus-in');
  }
}
