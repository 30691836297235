var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('input-text',{ref:"userSelectorInput",attrs:{"hint-text":("" + (_vm.$t('toolbox.agenda.create-edit.participants-hint'))),"label":("" + (_vm.$t('toolbox.agenda.create-edit.participants-label')))},on:{"on-focus-in":function($event){return _vm.onInputFocus()}}}),(_vm.selectedUserList.length > 0)?_c('div',{staticClass:"selected-user-list mt-3"},[(_vm.showCreator)?_c('div',{staticClass:"d-flex align-items-center justify-content-between selected-user-list-item"},[_c('div',{staticClass:"d-flex align-items-center justify-content-start\n        pl-2 pt-2 pb-2"},[_c('avatar-solo-widget',{staticClass:"mr-3",attrs:{"firstname":_vm.creator.firstName,"lastname":_vm.creator.lastName,"src":_vm.creator.pictureFileResource
            && _vm.creator.pictureFileResource.path
            ? _vm.FileResourceHelper.getFullPath(_vm.creator.pictureFileResource, 'w40')
            : '',"with-borders":!_vm.creator.pictureFileResource
            || (_vm.creator.pictureFileResource && !_vm.creator.pictureFileResource.path),"size":"40"}}),_c('div',{staticClass:"d-flex flex-column justify-content-center text-left"},[_c('label',{staticClass:"label-3 text-neutral-n-8-dark-ink mb-0"},[_vm._v(" "+_vm._s(_vm.creator.firstName)+" "+_vm._s(_vm.creator.lastName)+" ")]),_c('label',{staticClass:"label-4 text-neutral-n-6-label mb-0"},[_vm._v(" "+_vm._s(_vm.creator.jobTitle)+" "+_vm._s(_vm.defaultCompany(_vm.creator))+" ")])])],1),(_vm.meetingRequest)?_c('button-component',{staticClass:"mr-3 p-0",attrs:{"text":("" + (_vm.$t('toolbox.agenda.create-edit.edit'))),"size":"sm","variant":"white-blue"},on:{"on-click":function($event){return _vm.$bvModal.show('moderator-list-modal-1')}}}):_vm._e()],1):_vm._e(),_vm._l((_vm.selectedUserList),function(user){return _c('div',{key:user.uid,staticClass:"d-flex align-items-center justify-content-between selected-user-list-item"},[_c('div',{staticClass:"d-flex align-items-center justify-content-start\n        pl-2 pt-2 pb-2"},[_c('avatar-solo-widget',{staticClass:"mr-3",attrs:{"firstname":user.firstName,"lastname":user.lastName,"src":user.pictureFileResource
            && user.pictureFileResource.path
            ? _vm.FileResourceHelper.getFullPath(user.pictureFileResource, 'w40')
            : '',"with-borders":!user.pictureFileResource
            || (user.pictureFileResource && !user.pictureFileResource.path),"size":"40"}}),_c('div',{staticClass:"d-flex flex-column justify-content-center text-left"},[_c('label',{staticClass:"label-3 text-neutral-n-8-dark-ink mb-0"},[_vm._v(" "+_vm._s(user.firstName)+" "+_vm._s(user.lastName)+" ")]),_c('label',{staticClass:"label-4 text-neutral-n-6-label mb-0"},[_vm._v(" "+_vm._s(user.jobTitle)+" "+_vm._s(_vm.defaultCompany(user))+" ")])])],1),(!_vm.isCreator(user))?_c('font-awesome-component',{staticClass:"pr-3",attrs:{"container-classes":"icon-24","icon":"fa-regular fa-xmark","icon-classes":"text-neutral-n-6-label"},on:{"on-click":function($event){return _vm.onRemoveItemClick(user)}}}):_vm._e()],1)})],2):_vm._e(),_c('user-selector-modal',{key:_vm.modalId,attrs:{"current-bootstrap-breakpoint":_vm.breakpoint.value,"exclude-users":_vm.excludeUsers,"modal-id":_vm.modalId,"selected-users":_vm.selectedUserList},on:{"on-confirm":_vm.onModalConfirm}}),_c('company-request-moderator-list-modal',_vm._b({attrs:{"modal-id":"moderator-list-modal-1"},on:{"on-select":_vm.onSelectCreator}},'company-request-moderator-list-modal',_vm.meetingRequest,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }