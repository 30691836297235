import { ApolloQueryResult, FetchResult } from '@apollo/client';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import FilterInterface from '@/repositories/base/FilterInterface';
import createInterface from '@/repositories/base/CreateInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import CountInterface from '@/repositories/base/CountInterface';

/* eslint-disable max-len */
export default class CommunityUserConnectionRepository extends GraphQlRepository<CommunityUserConnection>
  implements FilterInterface<CommunityUserConnection>,
    CountInterface,
    createInterface<CommunityUserConnection>,
    UpdateInterface<CommunityUserConnection> {
  count(params: GqlPayloadParams): Promise<number | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetCommunityUserConnectionCount',
      operation: 'communityUserConnection',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'communityUserConnectionCountFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ communityUserConnection: [{ count: number }] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ communityUserConnection: [{ count: number }] }>) => response.data.communityUserConnection[0].count));
  }

  filter(params: GqlPayloadParams): Promise<CommunityUserConnection[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterCommunityUserConnections',
      operation: 'communityUserConnection',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'communityUserConnectionFullFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ communityUserConnection: Array<CommunityUserConnection> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ communityUserConnection: Array<CommunityUserConnection> }>) => response.data.communityUserConnection));
  }

  create(params: GqlPayloadParams): Promise<CommunityUserConnection | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateCommunityUserConnection',
      operation: 'CommunityUserConnectionCreateForUserAndConnectedUser',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserConnectionFullFragment',
      alias: 'CommunityUserConnectionCreate',
    }).then((mutation) => this.mutate<{ CommunityUserConnectionCreate: CommunityUserConnection }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserConnectionCreate: CommunityUserConnection }>) => response.data?.CommunityUserConnectionCreate));
  }

  update(params: GqlPayloadParams): Promise<CommunityUserConnection | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdateCommunityUserConnection',
      operation: 'CommunityUserConnectionUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserConnectionFullFragment',
    }).then((mutation) => this.mutate<{ CommunityUserConnectionUpdate: CommunityUserConnection }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserConnectionUpdate: CommunityUserConnection }>) => response.data?.CommunityUserConnectionUpdate));
  }
}
