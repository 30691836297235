import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import RootState from '@/store/states/RootState';
import { Data } from '@/utils/types/WidgetData';
import CommunityUserInterest from '@/models/graphql/CommunityUserInterest';

@Module({ namespaced: true, stateFactory: true })
export default class MyProfileInterestsWidgetStore extends WidgetBaseStore {
  @Action
  setDataConfigs(): void {
    if (this.payload) {
      super.setDataConfigs([{
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: { user: { uid: '%authUser%' } },
            type: GqlEntityFilterType.COMMUNITY_USER_INTEREST_FILTER,
          },
        }),
        fragmentName: 'communityUserInterestBaseFragment',
        operation: 'communityUserInterest',
        alias: this.widgetStoreName,
      }]);
    }
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string; rootState?: RootState}): void {
    this.data = data.values;
    if (data.values
      && data.values.length > 0
      && data.rootState
      && data.rootState.authUser
      && data.rootState.authUser.interests) {
      data.rootState.authUser.interests = data.values as unknown as CommunityUserInterest[];
    }
  }
}
