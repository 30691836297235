enum NotificationActionType {
  MEETING_STARTING_SOON = 'MEETING_STARTING_SOON',
  MEETING_STARTING_NOW = 'MEETING_STARTING_NOW',
  MEETING_UPDATED = 'MEETING_UPDATED',
  MEETING_INVITE = 'MEETING_INVITE_REQUEST',
  MEETING_CANCELLED = 'MEETING_CANCELLED',
  MEETING_ACCEPTED = 'MEETING_ACCEPTED',
  MEETING_DECLINED = 'MEETING_DECLINED',
  SESSION_STARTING_SOON = 'SESSION_STARTING_SOON',
  SESSION_STARTING_NOW = 'SESSION_STARTING_NOW',
  SESSION_END = 'SESSION_END',
  QNA_ANSWER = 'QNA_ANSWER',
  QNA_QUESTION = 'QNA_QUESTION',
  FEEDBACK_SURVEY = 'FEEDBACK_SURVEY',
  CUSTOM = 'CUSTOM_NOTIFICATION',
  COMMENT_ON_POST = 'COMMENT_ON_POST',
  POST_LIKED = 'POST_LIKED',
  COMMENT_REPLIED = 'COMMENT_REPLIED',
}

export default NotificationActionType;
