












































































































































































































































































































































































































































































































































































































import { Component } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import EntityType from '@/utils/enums/EntityType';
import PillWidget from '@/components/pill/PillWidget.vue';
import { Data } from '@/utils/types/WidgetData';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FileResource from '@/models/graphql/FileResource';
import ProductImage from '@/models/graphql/ProductImage';
import Variant from '@/utils/enums/Variant';
import { Getter, namespace } from 'vuex-class';
import { CommunityUserTagFilter } from '@/graphql/_Filters/CommunityUserTagFilter';
import CommunityUser from '@/models/graphql/CommunityUser';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import { StateChanger } from 'vue-infinite-loading';
import { TAG_LIST } from '@/utils/constants/PaginationOffsets';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CreateRenameTagModal from '@/components/toolbox/tags/CreateRenameTagModal.vue';
import RouteHelper from '@/utils/helpers/RouteHelper';
import SubEdition from '@/models/graphql/SubEdition';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ToastActionType from '@/utils/enums/ToastActionType';
import { TranslateResult } from 'vue-i18n';
import { Route } from 'vue-router';

const communityUserTagStore = namespace('CommunityUserTagStore');
const toastStore = namespace('ToastStore');

type EntityListType = {
  entityUid: string;
  entityType: string;
  linkUid: string;
  eventCode: string;
  name: string;
  image: string | null;
  icon: string | null;
};

@Component({
  components: {
    ConfirmModal,
    DropdownMenuItem,
    CreateRenameTagModal,
    ButtonComponent,
    IllustrationComponent,
    LoadingSpinnerComponent,
    AvatarSoloWidget,
    PillWidget,
    InputSearchComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
  },
})
export default class ToolboxTagsModal extends BreakpointWrapper {
  @Getter
  private authUser!: CommunityUser;

  @communityUserTagStore.Action
  private paginated!: (payload: { filter: CommunityUserTagFilter; offset: number }) => Promise<CommunityUserTag[]>;

  @communityUserTagStore.Action
  private removeFromEntity!: (payload: { tagId: string; entityId: string }) => Promise<CommunityUserTag | undefined>;

  @communityUserTagStore.Action
  private deleteCommunityUserTag!: (uid: string) => Promise<boolean>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private ViewMode = ViewMode;

  private EntityType = EntityType;

  private Variant = Variant;

  private IllustrationType = IllustrationType;

  private selectedViewMode: ViewMode = ViewMode.LIST;

  private selectedTag: CommunityUserTag | null = null;

  private tagList: CommunityUserTag[] = [];

  private searchQuery = '';

  private selectedEntityFilter = 'all';

  private entityList: Array<EntityListType> = [];

  private allEntityList: Array<EntityListType> = [];

  private isLoading = false;

  private isCreate = false;

  private showDetailHeaderMenu = false;

  private renameTagName = '';

  private renameTagUid = '';

  private deleteTagUid = '';

  private renderTagList = 0;

  private offset = 0;

  private openedMenu = null;

  private isMouseOver = false;

  private entityFilterConfig = [
    {
      name: 'toolbox.tags.entity-filter.all',
      itemEntityName: '',
      entityType: 'all',
    },
    {
      name: 'toolbox.tags.entity-filter.users',
      itemEntityName: 'entity-type.user',
      entityType: EntityType.USER,
    },
    {
      name: 'toolbox.tags.entity-filter.companies',
      itemEntityName: 'entity-type.company',
      entityType: EntityType.EXHIBITOR,
    },
    {
      name: 'toolbox.tags.entity-filter.events',
      itemEntityName: 'entity-type.event',
      entityType: EntityType.SUB_EDITION,
    },
    {
      name: 'toolbox.tags.entity-filter.sessions',
      itemEntityName: 'entity-type.session',
      entityType: EntityType.SESSION,
    },
    {
      name: 'toolbox.tags.entity-filter.products',
      itemEntityName: 'entity-type.product',
      entityType: EntityType.PRODUCT,
    },
    {
      name: 'toolbox.tags.entity-filter.large-products',
      itemEntityName: 'entity-type.large-product',
      entityType: EntityType.LARGE_PRODUCT,
    },
    {
      name: 'toolbox.tags.entity-filter.speakers',
      itemEntityName: 'entity-type.speaker',
      entityType: EntityType.SPEAKER,
    },
    {
      name: 'toolbox.tags.entity-filter.promotions',
      itemEntityName: 'entity-type.promotion',
      entityType: EntityType.DEAL,
    },
  ];

  private get tagNameInDetail(): string {
    return this.selectedTag ? this.selectedTag.name : '';
  }

  private get availableEntityFilterConfigs(): Array<{ name: string; entityType: string }> {
    return this.entityFilterConfig
      .filter((conf) => conf.entityType === 'all' || !!this.allEntityList.find((ent) => ent.entityType === conf.entityType));
  }

  private mouseOver(state: boolean): void {
    this.isMouseOver = state;
  }

  private getTranslatedEntityType(entityType: string): TranslateResult {
    const result = this.entityFilterConfig.find((element) => element.entityType === entityType);
    return result ? this.$t(result.itemEntityName) : '';
  }

  private setSelectedViewMode(mode: ViewMode): void {
    this.selectedViewMode = mode;
  }

  private setSelectedTag(tag: CommunityUserTag | null): void {
    this.selectedTag = tag;
    if (this.selectedTag) {
      this.allEntityList = this.selectedTag.links.map((link) => ({
        linkUid: link.uid,
        entityType: link.target?.__typename as string,
        entityUid: link.target?.uid as string,
        eventCode: (link.target as SubEdition).code || '',
        name: [EntityType.USER, EntityType.SPEAKER].includes(link.target?.__typename as EntityType)
          ? `${(link.target as CommunityUser).firstName} ${(link.target as CommunityUser).lastName}`
          : link.target?.name as string,
        image: this.mapEntityImage(link.target as unknown as Data),
        icon: this.mapEntityDefaultIcon(link.target?.__typename as string),
      }))
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    } else {
      this.allEntityList = [];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private mapEntityImage(entity: Data): string | null {
    // eslint-disable-next-line no-underscore-dangle
    switch (entity.__typename) {
      case EntityType.USER:
        return entity.pictureFileResource
          ? FileResourceHelper.getFullPath(entity.pictureFileResource as FileResource, 'w40')
          : null;
      case EntityType.SPEAKER:
        return entity.photoFileResource
          ? FileResourceHelper.getFullPath(entity.photoFileResource as FileResource, 'w40')
          : null;
      case EntityType.SESSION:
        return entity.imageFileResource
          ? FileResourceHelper.getFullPath(entity.imageFileResource as FileResource, 'w40')
          : null;
      case EntityType.DEAL:
        return entity.displayFileResource
          ? FileResourceHelper.getFullPath(entity.displayFileResource as FileResource, 'w40')
          : null;
      case EntityType.LARGE_PRODUCT:
      case EntityType.PRODUCT:
        return entity.images
        && (entity.images as ProductImage[]).length > 0
        && (entity.images as ProductImage[])[0].fullFileResource
          ? FileResourceHelper.getFullPath((entity.images as ProductImage[])[0].fullFileResource, 'w40')
          : null;
      case EntityType.SUB_EDITION:
      case EntityType.EXHIBITOR:
        return entity.logoFileResource
          ? FileResourceHelper.getFullPath(entity.logoFileResource as FileResource, 'w40')
          : null;
      default:
        return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private mapEntityDefaultIcon(entityType: string): string | null {
    switch (entityType) {
      case EntityType.USER:
      case EntityType.SPEAKER:
        return 'far fa-circle-user';
      case EntityType.SESSION:
        return 'far fa-presentation-screen';
      case EntityType.DEAL:
        return 'far fa-megaphone';
      case EntityType.LARGE_PRODUCT:
      case EntityType.PRODUCT:
        return 'far fa-briefcase';
      case EntityType.SUB_EDITION:
        return 'far fa-calendar-star';
      case EntityType.EXHIBITOR:
        return 'far fa-buildings';
      default:
        return null;
    }
  }

  private openEntityDetail(entityUid: string, entityType: string): void {
    (RouteHelper.internalDetailRouteNavigation(
      this.$router,
      entityUid,
      entityType,
    ) as Promise<Route | null>).then(() => {
      this.$bvModal.hide('toolbox-modal');
    });
  }

  private onFilter(filter: string): void {
    if (this.selectedTag) {
      this.selectedEntityFilter = this.selectedEntityFilter === filter
        ? 'all'
        : filter;
      this.entityList = this.selectedEntityFilter === 'all'
        ? this.allEntityList
        : this.allEntityList.filter((entity) => entity.entityType === this.selectedEntityFilter);
    } else {
      this.entityList = [];
    }
  }

  private clickBackToListMode(): void {
    if (this.isMobile) {
      this.showDetailHeaderMenu = false;
      this.setSelectedTag(null);
      this.onSearch({ query: this.searchQuery });
    }
    this.setSelectedViewMode(ViewMode.LIST);
  }

  private removeTag(tag: CommunityUserTag): void {
    this.deleteTagUid = tag.uid;
    this.$bvModal.show('delete-tag-modal-main');
  }

  private deleteTag(): void {
    const tagToDeleteIndex = this.tagList.findIndex((tag) => tag.uid === this.deleteTagUid);
    if (this.tagList.length > 1) {
      this.tagList = this.tagList.filter((tag) => tag.uid !== this.deleteTagUid);
    }
    this.deleteCommunityUserTag(this.deleteTagUid)
      .then(() => {
        this.showToast(ToastActionType.TAG_DELETED);
      })
      .finally(() => {
        if (this.tagList.length === 1) {
          this.tagList = this.tagList.filter((tag) => tag.uid !== this.deleteTagUid);
        }
        this.deleteTagUid = '';
      });
    if (this.tagList.length > 0) {
      this.onTagClick(tagToDeleteIndex === 0 ? this.tagList[0] : this.tagList[tagToDeleteIndex - 1]);
    } else {
      this.onTagClick(null);
    }
    this.clickBackToListMode();
  }

  private renameTag(tag: CommunityUserTag): void {
    this.renameTagName = tag.name;
    this.renameTagUid = tag.uid;
    this.isCreate = false;
    this.$bvModal.show('create-rename-tag-modal');
  }

  private setNewTagName(name: string): void {
    const tag = this.tagList.find((t) => t.uid === this.renameTagUid);
    if (tag) {
      tag.name = name;
    }
    this.orderTagList();
    this.renameTagName = '';
    this.renameTagUid = '';
    this.isCreate = true;
    this.showToast(ToastActionType.TAG_RENAMED);
  }

  private onSearch(payload: { query: string }): void {
    this.searchQuery = payload.query.trim();
    if (this.isMobile
        || (!this.isMobile && this.selectedViewMode !== ViewMode.SEARCH)
        || (!this.isMobile && this.selectedViewMode === ViewMode.SEARCH && this.searchQuery.length > 2)
    ) {
      this.isLoading = true;
      this.setSelectedTag(null);
      this.tagList = [];
      this.entityList = [];
      this.offset = 0;
      this.renderTagList += 1;
    }
  }

  private onTagClick(tag: CommunityUserTag | null): void {
    this.openedMenu = null;
    this.setSelectedTag(tag);
    this.onFilter('all');
    this.setSelectedViewMode(ViewMode.DETAIL);
  }

  private removeTagLink(entityUid: string): void {
    if (this.selectedTag) {
      this.entityList = this.entityList.filter((item) => item.entityUid !== entityUid);
      this.allEntityList = this.allEntityList.filter((item) => item.entityUid !== entityUid);
      // eslint-disable-next-line no-underscore-dangle
      this.selectedTag._linkCount -= 1;
      const selectedTagIndex = this.tagList.findIndex((tag) => (this.selectedTag ? tag.uid === this.selectedTag.uid : false));
      if (selectedTagIndex >= 0) {
        this.tagList[selectedTagIndex].links = this.tagList[selectedTagIndex].links
          .filter((link) => link.target?.uid !== entityUid);
      }
      this.removeFromEntity({
        tagId: this.selectedTag.uid,
        entityId: entityUid,
      });
    }
  }

  private createTag(): void {
    this.isCreate = true;
    this.renameTagName = this.searchQuery;
    this.$bvModal.show('create-rename-tag-modal');
  }

  private orderTagList(): void {
    this.tagList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  }

  private addNewTag(tag: CommunityUserTag): void {
    if (this.tagList.length > 0) {
      this.tagList.unshift(tag);
      this.orderTagList();
    } else {
      this.onSearch({ query: '' });
    }
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  private infiniteHandler(state: StateChanger): void {
    this.isLoading = true;
    // eslint-disable-next-line @typescript-eslint/camelcase
    const searchFilter = this.searchQuery.length > 0 ? { name_contains: this.searchQuery } : {};
    this.paginated({
      filter: {
        user: {
          uid: this.authUser.uid,
        },
        ...searchFilter,
      },
      offset: this.offset,
    })
      .then((tags) => {
        if (this.offset === 0) {
          this.tagList = tags;
        } else {
          this.tagList.push(...tags);
        }
        if (tags.length === TAG_LIST) {
          state.loaded();
          this.offset += TAG_LIST;
        } else {
          state.complete();
          this.offset = 0;
        }
      })
      .then(() => {
        if (this.tagList.length > 0 && !this.isMobile && this.selectedViewMode !== this.ViewMode.SEARCH) {
          this.setSelectedTag(this.tagList[0]);
          this.onFilter('all');
        }
      })
      .catch(() => state.complete())
      .finally(() => {
        this.isLoading = false;
      });
  }

  private onSearchFocus(): void {
    this.setSelectedTag(null);
    this.selectedViewMode = ViewMode.SEARCH;
  }

  private onLeaveSearchView(): void {
    this.selectedViewMode = ViewMode.LIST;
    this.searchQuery = '';
    this.onSearch({ query: '' });
    if (this.tagList.length && !this.isMobile) {
      this.setSelectedTag(this.tagList[0]);
    }
  }
}
