import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Data } from '@/utils/types/WidgetData';
import { BasicTypes } from '@/utils/types/BasicTypes';

@Module({ namespaced: true, stateFactory: true })
export default class BreadcrumbWidgetStore extends WidgetBaseStore {
  private paramsData: Record<string, Array<Data>> = {};

  @Action
  setDataConfigs(): void {
    if (this.widget && this.widget.subWidgets) {
      const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
      const paramsPayload = this.widget.subWidgets.map((sub) => ({
        id: sub.id,
        ...JSON.parse(sub.payload || '{}'),
      }));
      paramsPayload.forEach((param) => {
        if (param.parameter
          && param.field
          && args
          && Object.keys(args).includes(param.parameter)
          && args[param.parameter]) {
          const alias = `${this.widgetStoreName}_param${param.id}`;
          const paramQuery = `${alias}:
          ${param.type}(_cachable: true ${param.type === 'subEdition' ? 'code' : 'uid'}: "%${param.parameter}%")
          { uid ${param.field} }`;
          this.context.commit(
            'WidgetDispatcherStore/setGqlQuery',
            { key: `${this.widgetStoreName}_param${param.id}`, value: paramQuery },
            { root: true },
          );
          this.context.commit('setGqlQuery', paramQuery);
        }
      });
    }
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    const { values } = data;

    if (values?.length > 0 && 'uid' in values[0]) {
      const value = values[0];
      const key = value.uid as string;
      // eslint-disable-next-line no-underscore-dangle
      const keyValue = 'code' in value ? value.code : value.name ?? value._breadcrumbName;

      Object.assign(this.paramsData, {
        [key]: keyValue,
      });
    }
  }

  @Mutation
  setGqlQuery(query: string): void {
    this.query += ` \n ${query}`;
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      params: this.paramsData,
    };
  }
}
