import BaseModel from '@/models/BaseModel';
/* eslint-disable import/no-cycle */
import Category from '@/models/graphql/Category';
import Session from '@/models/graphql/Session';
import Handout from '@/models/graphql/Handout';
import Exhibitor from '@/models/graphql/Exhibitor';
import Edition from '@/models/graphql/Edition';
import Community from '@/models/graphql/Community';
import CommunityUserLink from '@/models/graphql/CommunityUserLink';
import Deal from '@/models/graphql/Deal';
import ProductImage from '@/models/graphql/ProductImage';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import EntityTranslationParams from '@/utils/types/EntityTranslationParams';

/* eslint-disable no-underscore-dangle */
class Product extends BaseModel {
  community?: Community;

  edition?: Edition;

  name?: string;

  description?: string;

  deleted?: boolean;

  featured?: boolean;

  firsttime?: boolean;

  shared?: [];

  productFilterType?: string;

  publicationTime?: string;

  endPublicationTime?: string;

  subEdition?: string;

  exhibitor?: Exhibitor;

  images?: Array<ProductImage>;

  deals?: Array<Deal>;

  categories?: Array<Category>;

  sessions?: Array<Session>;

  handouts?: Array<Handout>;

  linked?: Array<CommunityUserLink>;

  url?: string;

  videoUrl?: string;

  dimensions?: string;

  manufacturer?: string;

  model?: string;

  serie?: string;

  serialNumber?: string;

  price?: number;

  _isBookmarked?: string | null;

  _isFollowed?: string | null;

  _isShared?: string | null;

  _isInAgenda?: string | null;

  _isConnected?: boolean;

  _myTags?: CommunityUserTag[];

  _isRecommendedForMe?: boolean;

  _isExhibitorFeatured?: boolean;

  categoriesInContext?: Category[];

  _translations: Array<{
    locale: string;
    values: Array<{key: string; value: string | null}>;
  }> = [];

  get mainPicture(): string {
    if (this.images && this.images[0] && this.images[0].fullFileResource) {
      return FileResourceHelper.getFullPath(this.images[0].fullFileResource, 'w40');
    }

    return '';
  }

  get translation(): EntityTranslationParams[] {
    const result: EntityTranslationParams[] = [];
    if (this._translations.length > 0) {
      this._translations
        .forEach((trans) => {
          result.push(
            ...trans.values
              .map((val) => ({
                locale: trans.locale,
                value: val.value,
                field: val.key,
              })),
          );
        });
    }
    return result;
  }

  static hydrate(item: object): Product {
    const result = new Product();
    Object.assign(result, item);

    return result;
  }

  isMissingTranslation(locales: string[], fields: string[]): boolean {
    return this.translation.length === 0 || this.missingTranslation(locales, fields).length > 0;
  }

  missingTranslation(locales: string[], fields: string[]): EntityTranslationParams[] {
    return this.translation
      .filter((trans) => fields.findIndex((f) => f === trans.field) > -1
          && locales.findIndex((l) => l === trans.locale) > -1
          && !trans.value);
  }

  translationsByField(field: string): EntityTranslationParams[] {
    return this.translation.filter((trans) => trans.field === field);
  }
}

export default Product;
