

















































































































































































































































































































































































































































import {
  Component, Inject, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import MessageBoxBubbleComponent from '@/components/chat/MessageBoxBubbleComponent.vue';
import MessageBoxDateSeparatorComponent from '@/components/chat/MessageBoxDateSeparatorComponent.vue';
import MessageBoxWriteComponent from '@/components/chat/MessageBoxWriteComponent.vue';
import MessageBoxConversationSummary from '@/utils/types/chat/MessageBoxConversationSummary';
import SoloDoubleAvatarComponent from '@/components/chat/SoloDoubleAvatarComponent.vue';
import MessageBoxActions from '@/utils/enums/chat/MessageBoxActions';
import CommunityUser from '@/models/graphql/CommunityUser';
import PillComponent from '@/components/pill/PillComponent.vue';
import MessageGroup from '@/models/graphql/MessageGroup';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import GUUID from '@/utils/GUUID';
import Message from '@/models/graphql/Message';
import { addSeconds, format, getUnixTime } from 'date-fns';
import TypingEventEnum from '@/utils/enums/chat/TypingEventEnum';
import MessageBoxTypingComponent from '@/components/chat/MessageBoxTypingComponent.vue';
import MessageMenuItem from '@/utils/enums/chat/MessageMenuItem';
import GroupType from '@/utils/enums/chat/GroupType';
import MessageType from '@/utils/enums/chat/MessageType';
import ChatErrorList from '@/utils/types/chat/ChatErrorList';
import { ChatRequestType } from '@/utils/types/chat/ChatError';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import SendConnectionRequestModal from '@/components/modals/SendConnectionRequestModal.vue';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import PillWidget from '@/components/pill/PillWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import Session from '@/models/graphql/Session';
import { RawLocation } from 'vue-router';
import EntityType from '@/utils/enums/EntityType';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import MessageBoxMeetingBubbleComponent from '@/components/chat/MessageBoxMeetingBubbleComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Exhibitor from '@/models/graphql/Exhibitor';
import { CHAT_MESSAGES, CHAT_MESSAGES_TO_KEEP_IN_VIEW } from '@/utils/constants/PaginationOffsets';
import { StateChanger } from 'vue-infinite-loading';
import Meeting from '@/models/graphql/Meeting';
import MessageGroupPermission from '@/utils/enums/chat/MessageGroupPermission';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { runMathJax } from '@/utils/helpers/LatexHelper';

const communityUserConnectionStore = namespace('CommunityUserConnectionStore');
const chatDispatcherStore = namespace('ChatDispatcherStore');

@Component({
  methods: { useTestDataAttribute },
  components: {
    DropdownMenuItem,
    MessageBoxMeetingBubbleComponent,
    AvatarSoloWidget,
    LoadingSpinnerComponent,
    FontAwesomeComponent,
    PillWidget,
    IllustrationComponent,
    SendConnectionRequestModal,
    MessageBoxTypingComponent,
    ButtonComponent,
    ButtonIconComponent,
    PillComponent,
    SoloDoubleAvatarComponent,
    MessageBoxWriteComponent,
    MessageBoxDateSeparatorComponent,
    MessageBoxBubbleComponent,
  },
})
/* eslint-disable max-len,no-underscore-dangle,@typescript-eslint/camelcase */
export default class MessageBoxMessages extends Vue {
  @Prop({ default: 'ChatStore' })
  private readonly storeContext!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly hideMessageBoxWrite!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly isSession!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly isItInTheaterMode!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly fromInsideMeeting!: boolean;

  @Prop({ default: false })
  private readonly darkMode!: boolean;

  @Prop({ default: false })
  private readonly adminView!: boolean;

  @Prop({ default: false })
  private readonly noLoad!: boolean;

  @State
  private dateLocale!: Locale;

  @Getter
  private readonly authUser!: CommunityUser;

  @Inject({ from: 'windowHeight' })
  private readonly windowHeight!: { value: number };

  @communityUserConnectionStore.Action
  private acceptConnection!: (payload: {
    uid: string; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private declineConnection!: (payload: {
    uid: string; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private connect!: (connection: object) => Promise<CommunityUserConnection | undefined>;

  @chatDispatcherStore.Action('events')
  private chatEvents!: (sessionId?: string) => Promise<{ unsubscribe: () => void }>;

  @chatDispatcherStore.Mutation
  private sessionUnsubscribe!: () => void;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private goBackToLastMessage = false;

  private lastMessageBottomPosition = 0;

  private renderMessageBoxWrite = 0;

  private renderMessageBoxMessages = 0;

  private isNewMessage = false;

  private yourGroupState: string | null = null;

  private yourSelectedGroup: string | null = null;

  private yourSelectedGroupTarget: EntityType | null = null;

  private GroupType = GroupType;

  private IllustrationType = IllustrationType;

  private MessageGroupPermission = MessageGroupPermission;

  private ConnectionStatusType = ConnectionStatusType;

  private messagesElementId = 'messages';

  private messagesCount = 0;

  private offset = 0;

  private firstLoad = true;

  private isCloseMenuOpened = false;

  private messageToReply: Message | null = null;

  private requestInProgress = false;

  private infiniteId = +new Date();

  private get status(): MessageBoxActions {
    return this.$store.getters[`ChatDispatcherStore/${this.storeContext}/selectedView`];
  }

  private get tempGroupId(): string {
    return this.$store.getters[`ChatDispatcherStore/${this.storeContext}/getTempGroupId`];
  }

  private get chatErrors(): ChatErrorList {
    return this.$store.getters[`ChatDispatcherStore/${this.storeContext}/fullChatErrors`];
  }

  private get selectedGroup(): MessageGroup {
    return this.$store.getters[`ChatDispatcherStore/${this.storeContext}/selectedGroup`];
  }

  private get showErrorBanner(): boolean {
    return !this.requestInProgress && !!(this.chatErrors
        && this.chatErrors.haveErrors
        && (this.chatErrors.findCreateConversationError(this.selectedGroup.uid)
            || this.chatErrors.findSendMessageErrors(this.selectedGroup.uid).length > 0));
  }

  private get isMuted(): boolean {
    return !!(this.selectedGroup
        && this.selectedGroup.myState
        && this.selectedGroup.myState.muted);
  }

  private get images(): string[] {
    if (this.selectedGroup
        && this.targetExhibitor) {
      const exhibitor = this.targetExhibitor;
      if (exhibitor.logoFileResource) {
        return [FileResourceHelper.getFullPath(exhibitor.logoFileResource)];
      }
    }
    if (this.summary.isUserAlone && this.authUser) {
      return [this.authUser.profilePicture];
    }
    if (this.summary && this.summary.images && this.authUser) {
      return this.summary.images.filter((src) => !src.includes((this.authUser as unknown as {
        profilePicture: string;
      }).profilePicture)) || [''];
    }
    return [];
  }

  private get avatarNames(): Array<{ firstName: string; lastName: string }> {
    if (this.selectedGroup
        && ((this.selectedGroup.target && ![EntityType.EXHIBITOR]
          .includes(this.selectedGroup.target.__typename as EntityType)) || !this.selectedGroup.target)) {
      if (this.summary.isUserAlone && this.authUser) {
        return [{
          firstName: this.authUser.firstName as string,
          lastName: this.authUser.lastName as string,
        }];
      }
      return this.summary.fullUsers
        .map((u) => ({
          firstName: u.firstName as string,
          lastName: u.lastName as string,
        }));
    }

    return [];
  }

  private get targetMeeting(): Meeting | null {
    return this.selectedGroup
    && this.selectedGroup.target
    && this.selectedGroup.target.__typename === EntityType.MEETING
      ? this.selectedGroup.target as Meeting
      : null;
  }

  private get targetExhibitor(): Exhibitor | null {
    return this.selectedGroup
    && this.selectedGroup.target
    && this.selectedGroup.target.__typename === EntityType.EXHIBITOR
      ? this.selectedGroup.target as Exhibitor
      : null;
  }

  private get targetSession(): Session | null {
    return this.selectedGroup
    && this.selectedGroup.target
    && this.selectedGroup.target.__typename === EntityType.SESSION
      ? this.selectedGroup.target as Session
      : null;
  }

  private get isBlocked(): boolean {
    return this.authUser
        && this.selectedGroup
        && !this.selectedGroup.target
        && this.groupUsers.length > 0
        && this.selectedGroup.groupType !== GroupType.GROUP
        && !this.authUser.isBlockedByUser(this.groupUsers[0].uid)
        && this.authUser.haveBlockedUser(this.groupUsers[0].uid);
  }

  private get isBlockedByCurrentUser(): boolean {
    return this.selectedGroup
        && this.selectedGroup.groupType === GroupType.DIRECT
        && this.groupUsers.length > 0
        && (this.authUser
            && (this.authUser.isBlockedByUser(this.groupUsers[0].uid)
                || this.authUser.haveBlockedUser(this.groupUsers[0].uid)));
  }

  private get isChatOpen(): boolean {
    const autoOpenFeature = FeatureKeys.COMMUNITY_AUTO_OPEN_SESSION_CHAT;
    const hasAutoOpenFeature = this.featureByKey(autoOpenFeature) && this.featureByKey(autoOpenFeature).enabled;
    return !(this.selectedGroup
            && !!this.selectedGroup.target
            && this.selectedGroup.target.__typename === EntityType.SESSION
            && !this.selectedGroup.active)
        || !!(this.selectedGroup
            && this.selectedGroup.tempId
            && this.selectedGroup.tempId === this.selectedGroup.uid
            && hasAutoOpenFeature);
  }

  private get canSendMessage(): boolean {
    return (this.adminView && this.messageGroupPermission === MessageGroupPermission.ADMINISTRATOR) || this.isChatOpen;
  }

  private get fullNames(): string[] {
    if (this.selectedGroup
        && this.selectedGroup.target
        && (this.selectedGroup.target as Session | Exhibitor).name) {
      const { name } = this.selectedGroup.target as Session | Exhibitor;
      return [name as string];
    }
    if (this.summary && this.summary.fullUsers && this.authUser) {
      const withoutAuth = this.summary.fullUsers.filter((u) => u.uid !== this.authUser.uid);
      return withoutAuth.map((u) => (withoutAuth.length > 1 ? u.firstName : `${u.firstName} ${u.lastName}`) || '');
    }
    return [];
  }

  private get fullUsers(): CommunityUser[] {
    return this.summary && this.summary.fullUsers ? this.summary.fullUsers : [];
  }

  private get messageGroupPermission(): MessageGroupPermission {
    if (this.authUser
        && this.selectedGroup
        && this.selectedGroup.users
        && this.selectedGroup.users.filter((user) => user.uid === this.authUser.uid).length > 0
    ) {
      if (this.selectedGroup.isSessionModerator) {
        return MessageGroupPermission.ADMINISTRATOR;
      }
      return MessageGroupPermission.STANDARD;
    }
    return MessageGroupPermission.GUEST;
  }

  private get messages(): Message[] {
    this.renderMessageBoxMessages += 1;
    if (this.selectedGroup && this.selectedGroup.messages) {
      if (this.selectedGroup.target) {
        /** TODO: Remove the sort and replace order senttime by senttimestap
         once Yoann implement senttimestamp in backend */
        return this.selectedGroup.messages
          .filter((m) => (m.messageType === MessageType.REGULAR || m.messageType === MessageType.LINK))
          .sort((d1, d2) => (d1.senttime
                && d2.senttime
                && new Date(d1.senttime).getTime() >= new Date(d2.senttime).getTime()
            ? 1
            : -1
          ));
      }
      return this.selectedGroup
        .messages.sort((d1, d2) => (d1.senttime
              && d2.senttime
              && new Date(d1.senttime).getTime() >= new Date(d2.senttime).getTime()
          ? 1
          : -1
        ));
    }
    return [];
  }

  private get startTime(): string {
    if (this.selectedGroup
        && this.selectedGroup.target) {
      const entityTarget = this.selectedGroup.target as Session;
      if (entityTarget.startTime) {
        const dateLocal = DateTimeHelper.toLocal(
          new Date(entityTarget.startTime as unknown as string),
        );
        return format(dateLocal, 'PPPPp', { locale: this.dateLocale });
      }
    }
    return '';
  }

  private get yourAreBanned(): boolean {
    if (this.authUser
        && !!this.targetSession
        && this.selectedGroup
        && this.selectedGroup.users
        && this.selectedGroup.users.length > 0) {
      return !!this.selectedGroup.users.find((u) => u.uid === this.authUser.uid && u.banned);
    }
    return false;
  }

  private get summary(): MessageBoxConversationSummary {
    return this.$store.getters[`ChatDispatcherStore/${this.storeContext}/summary`];
  }

  private get youHaveBeenBlocked(): boolean {
    return this.authUser
        && this.selectedGroup
        && !this.selectedGroup.target
        && this.groupUsers.length > 0
        && this.selectedGroup.groupType !== GroupType.GROUP
        && this.authUser.isBlockedByUser(this.groupUsers[0].uid)
        && !this.authUser.haveBlockedUser(this.groupUsers[0].uid);
  }

  private get groupUsers(): CommunityUser[] {
    return (this.selectedGroup.users || []).filter((u) => u.uid !== this.authUser.uid);
  }

  // eslint-disable-next-line class-methods-use-this
  updated(): void {
    runMathJax();
  }

  subscribeToChatEvents(): void {
    const code = FeatureKeys.COMMUNITY_CHAT_FEATURE;
    const hasChatFeature = this.featureByKey(code) && this.featureByKey(code).enabled;
    if (this.isSession && hasChatFeature) {
      this.sessionUnsubscribe();
      const authUserInGroup = this.authUser
          && this.selectedGroup
          && this.selectedGroup.users?.find((u) => u.uid === this.authUser.uid);
      if (!this.authUser || !authUserInGroup) {
        this.chatEvents(this.targetSession?.uid);
      }
    }
  }

  created(): void {
    this.subscribeToChatEvents();
    if (this.isSession) {
      this.messagesElementId += `-session-${+new Date()}`;
    } else if (this.fromInsideMeeting) {
      this.messagesElementId += `-meeting-${+new Date()}`;
    }
    if (this.selectedGroup) {
      if (this.selectedGroup.uid !== this.selectedGroup.tempId
          && (this.status === MessageBoxActions.MESSAGES || this.hideMessageBoxWrite)) {
        if (this.selectedGroup.messagesCount === 0) {
          this.countMessagesByGroup({ group: { uid: this.selectedGroup.uid } })
            .then((count) => {
              if (count) {
                this.selectedGroup.messagesCount = count;
                this.messagesCount = count;
              }
            });
        } else {
          this.messagesCount = this.selectedGroup.messagesCount;
        }
      }
      this.yourSelectedGroup = this.selectedGroup.uid;
      this.yourSelectedGroupTarget = this.selectedGroup.target
      && this.selectedGroup.target.__typename
        ? this.selectedGroup.target.__typename as EntityType
        : null;
      if (this.authUser && this.selectedGroup.myState) {
        this.yourGroupState = this.selectedGroup.myState.uid;
      }
      this.offset = this.selectedGroup.messagesOffset;
    }
  }

  mounted(): void {
    if (this.selectedGroup && this.selectedGroup.myState && !this.targetSession) {
      this.selectedGroup.myState.lastReadTimestamp = getUnixTime(addSeconds(new Date(), 1));
      this.updateYourGroupState();
    }
    this.updateGroupMessageInView();
    setTimeout(() => {
      this.renderMessageBoxWrite += 1;
    }, 200);
  }

  beforeDestroy(): void {
    if (!this.isNewMessage
        && (!this.yourSelectedGroupTarget
            || ![EntityType.SESSION, EntityType.MEETING].includes(this.yourSelectedGroupTarget))) {
      this.updateYourGroupState();
    }
    if (this.yourSelectedGroup) {
      this.resetCurrentGroupMessages(this.yourSelectedGroup);
      this.setGroupMessageInView({
        uid: this.yourSelectedGroup,
        value: false,
      });
    }
  }

  updateGroupMessageInView(): void {
    if (this.selectedGroup) {
      this.setGroupMessageInView({
        uid: this.selectedGroup.uid,
        value: !this.goBackToLastMessage,
      });
    }
  }

  private setNewConversation(group: object): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeContext}/setNewConversation`, group);
  }

  private cleanupConversations(): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeContext}/cleanupConversations`);
  }

  private setGroupMessageInView(payload: { uid: string; value: boolean }): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeContext}/setGroupMessageInView`, payload);
  }

  private addMessages(payload: { uid: string; messages: Message[]; replace?: boolean; reverse?: boolean }): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeContext}/addMessages`, payload);
  }

  private changeStatus(status: MessageBoxActions): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeContext}/changeStatus`, status);
  }

  private toggleChat(close?: boolean): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeContext}/toggleChat`, close);
  }

  private countMessagesByGroup(filter: object): Promise<number> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/countMessagesByGroup`, filter);
  }

  private changeChatState(filter: object): Promise<void> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/changeChatState`, filter);
  }

  private typing(model: { event: TypingEventEnum; groupId: string }): Promise<void> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/typing`, model);
  }

  private createConversation(model: {
    users: CommunityUser[];
    message: string | null;
    tempId: string;
    messageTempId: string;
    messageType: MessageType | null;
    targetId: string | null;
    targetType: string | null;
    targetMessageId: string | null;
    active?: boolean | null;
  }): Promise<boolean> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/createConversation`, model);
  }

  private acceptConversation(model: { uid: string }): Promise<boolean> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/acceptConversation`, model);
  }

  private declineConversation(model: { uid: string }): Promise<boolean> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/declineConversation`, model);
  }

  private sendMessage(model: {
    groupId: string;
    message: string;
    tempId: string;
    type: MessageType | null;
    parentMessageId: string | null;
    targetMessageId: string | null;
  }): void {
    this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/sendMessage`, model);
  }

  private reloadLastRequest(request?: ChatRequestType): Promise<void> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/reloadLastRequest`, request);
  }

  private updateState(model: {
    lastReadTimestamp: number;
    hideMessageBeforeTimestamp?: number;
    uid: string;
  }): Promise<void> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/updateState`, model);
  }

  private resetCurrentGroupMessages(group: string): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeContext}/resetCurrentGroupMessages`, group);
  }

  private toggleMuteState(payload: { uid: string; muted: boolean }): Promise<string | null> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/toggleMuteState`, payload);
  }

  private paginatedMessages(payload: { filter?: object; offset: number }): Promise<number> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/paginatedMessages`, payload);
  }

  private infiniteHandler($state: StateChanger): void {
    if ((!this.selectedGroup && this.messagesCount === 0) || this.noLoad) {
      $state.complete();
      return;
    }
    if (this.selectedGroup && this.selectedGroup.uid) {
      if (this.messagesCount < this.offset) {
        $state.complete();
        if (this.firstLoad) {
          this.firstLoad = false;
          this.scrollToBottom();
        }
        return;
      }
      if (this.firstLoad && this.offset > 0) {
        this.firstLoad = false;
        $state.reset();
        this.scrollToBottom();
      } else {
        this.offset += CHAT_MESSAGES;
        this.selectedGroup.messagesOffset = this.offset;
        this.paginatedMessages({
          filter: {
            group: { uid: this.selectedGroup.uid },
          },
          offset: this.offset - CHAT_MESSAGES,
        })
          .then(() => {
            if (this.offset < this.messagesCount) {
              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .then(() => {
            if (this.messagesCount < CHAT_MESSAGES) {
              this.scrollToBottom();
            }
          })
          .catch(() => {
            $state.complete();
          });
      }
    }
  }

  private retry(): void {
    if (this.chatErrors.haveErrors) {
      const error = this.chatErrors.findFetchMessagesError(this.yourSelectedGroup || '');
      if (error && error.request) {
        this.reloadLastRequest(error.request);
        this.chatErrors.removeErrors(error);
      }
    }
  }

  private forceReload(): void {
    if (this.chatErrors.haveErrors) {
      this.requestInProgress = true;
      if (this.chatErrors.findSendMessageErrors(this.selectedGroup.uid).length > 0
          && this.selectedGroup) {
        Promise.all(this.chatErrors
          .findSendMessageErrors(this.selectedGroup.uid)
          .map((err) => this.reloadLastRequest(err.request)))
          .finally(() => {
            this.chatErrors.removeSendMessagesErrors(this.selectedGroup.uid);
            this.requestInProgress = false;
          });
      } else if (this.chatErrors.findCreateConversationError(this.selectedGroup.uid)) {
        this.reloadLastRequest(this.chatErrors.findCreateConversationError(this.selectedGroup.uid)?.request)
          .finally(() => {
            this.chatErrors.removeCreateConversationError(this.selectedGroup.uid);
            this.requestInProgress = false;
          });
      }
    }
  }

  private backToBottom(): void {
    this.goBackToLastMessage = false;
    this.isNewMessage = false;
    this.scrollToBottom();
    if (this.selectedGroup && this.selectedGroup.myState && !this.targetSession) {
      this.selectedGroup.myState.lastReadTimestamp = getUnixTime(addSeconds(new Date(), 1));
    }
  }

  private updateYourGroupState(): void {
    if (this.yourGroupState && !this.targetSession) {
      this.updateState({
        lastReadTimestamp: getUnixTime(addSeconds(new Date(), 1)),
        uid: this.yourGroupState,
      });
    }
  }

  private openViewMembers(): void {
    this.$eventsBus.emit('open-conversation-option-modal', {
      users: this.fullUsers,
      groupId: this.selectedGroup?.uid,
      actionType: MessageMenuItem.VIEW,
    });
  }

  private unBlock(): void {
    if (!this.selectedGroup.target && this.groupUsers.length > 0
    ) {
      const fullName = this.groupUsers[0].fullName
        ? this.groupUsers[0].fullName
        : `${this.groupUsers[0].firstName} ${this.groupUsers[0].lastName}`;
      this.$eventsBus.emit('open-conversation-option-modal', {
        users: [{
          uid: this.groupUsers[0].uid,
          fullName,
        }],
        groupId: this.groupUsers[0].uid,
        actionType: MessageMenuItem.UNBLOCK,
      });
    }
  }

  private openProfileDetail(uid?: string): void {
    let route: RawLocation | null = null;
    /* eslint-disable no-underscore-dangle */
    if (this.selectedGroup
        && this.selectedGroup.target
        && this.selectedGroup.target.__typename) {
      if (this.selectedGroup.target.__typename === EntityType.SESSION) {
        route = {
          name: 'session-detail',
          params: { sessionId: this.selectedGroup.target.uid },
        };
      }

      if (this.selectedGroup.target.__typename === EntityType.EXHIBITOR) {
        route = {
          name: 'company-detail',
          params: { companyId: this.selectedGroup.target.uid },
        };
      }
    } else if (uid) {
      route = {
        name: 'member-detail',
        params: {
          memberId: uid,
        },
      };
    }
    if (route) {
      this.$router.push(route)
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        })
        .finally(() => {
          this.toggleChat(true);
          this.$eventsBus.emit('close-conversation-option-modal');
          this.$eventsBus.emit('close-toolbox');
        });
    }
  }

  @Watch('selectedGroup.newMessageReceived')
  private messageReceived(): void {
    if (this.authUser) {
      if (this.selectedGroup) {
        this.yourSelectedGroup = this.selectedGroup.uid;
        if (this.selectedGroup.myState) {
          this.yourGroupState = this.selectedGroup.myState.uid;
        }
      }
    }
    if (this.goBackToLastMessage) {
      this.isNewMessage = true;
    } else {
      this.isNewMessage = false;
      this.scrollToBottom();
    }
    this.updateGroupMessageInView();
  }

  private handleScroll(): void {
    const messages = document.getElementById(this.messagesElementId);
    if (messages) {
      this.goBackToLastMessage = (messages.scrollHeight - messages.clientHeight) - 16 > messages.scrollTop
          && !!this.authUser
          && this.messages.length > 0;
      this.updateGroupMessageInView();
      if (!this.goBackToLastMessage) {
        this.keepCertainAmountOfMessagesInView();
        if (this.selectedGroup && this.isNewMessage && this.selectedGroup.myState && !this.targetSession) {
          this.selectedGroup.myState.lastReadTimestamp = getUnixTime(addSeconds(new Date(), 1));
        }
      }
      this.updateGoToLastMessageBtnPosition();
    }
  }

  private keepCertainAmountOfMessagesInView(): void {
    if (this.selectedGroup
        && this.selectedGroup.messages.length > CHAT_MESSAGES_TO_KEEP_IN_VIEW) {
      this.selectedGroup.messages = this.selectedGroup.messages.slice(-CHAT_MESSAGES_TO_KEEP_IN_VIEW);
      this.offset = CHAT_MESSAGES_TO_KEEP_IN_VIEW;
      this.selectedGroup.messagesOffset = this.offset;
      this.infiniteId += 1;
    }
  }

  @Watch('windowHeight.value')
  private updateGoToLastMessageBtnPosition(): void {
    this.$nextTick(() => {
      const messageBoxWrite = this.$el.querySelector('.message-box-write');
      if (messageBoxWrite) {
        this.lastMessageBottomPosition = messageBoxWrite.getBoundingClientRect().height + 16;
      } else {
        this.lastMessageBottomPosition = 16;
      }
    });
  }

  private scrollToBottom(): void {
    this.$nextTick(() => {
      const messages = document.getElementById(this.messagesElementId);
      if (messages) {
        messages.scrollTop = messages.scrollHeight;
        this.keepCertainAmountOfMessagesInView();
      }
    });
  }

  private showDateSeparator(index: number): boolean {
    const previousMessageDate = this.messages[index - 1] && this.messages[index - 1].senttime
      ? new Date(`${this.messages[index - 1].senttime}Z`)
      : new Date();
    const messageDate = this.messages[index] && this.messages[index].senttime
      ? new Date(`${this.messages[index].senttime}Z`)
      : new Date();
    return index === 0
        || (index >= 1
            && !DateTimeHelper.equalLocalizedDay(previousMessageDate, messageDate));
  }

  private handleTypingEvent(isTyping: boolean): void {
    if (this.authUser
        && !(this.selectedGroup.target && this.selectedGroup.target.__typename === 'Session')) {
      if (isTyping) {
        this.typing({
          event: TypingEventEnum.START_TYPING,
          groupId: this.selectedGroup.uid,
        });
      } else {
        this.typing({
          event: TypingEventEnum.END_TYPING,
          groupId: this.selectedGroup.uid,
        });
      }
    }
  }

  private handleNewMessage(message: string): void {
    if (this.selectedGroup) {
      const tempGroupId = this.tempGroupId || GUUID.uuidv4();
      const tempMessageId = GUUID.uuidv4();
      const {
        uid,
        firstName,
        lastName,
        pictureFileResource,
        jobTitle,
      } = this.authUser;
      if (((this.selectedGroup.users && this.selectedGroup.users.length > 0)
              || this.selectedGroup.target)
          && this.selectedGroup.uid === tempGroupId) {
        const users = [...this.selectedGroup.users || [], {
          uid,
          firstName,
          lastName,
          pictureFileResource,
          jobTitle,
        }];
        const group = {
          uid: tempGroupId,
          tempId: tempGroupId,
          groupType: users.length === 2 ? GroupType.DIRECT : GroupType.GROUP,
          users,
          target: this.selectedGroup.target,
          messages: [{
            uid: tempMessageId,
            tempId: tempMessageId,
            content: message,
            senttime: DateTimeHelper.toISO8601UTC(),
            type: MessageType.REGULAR,
            user: {
              uid,
              firstName,
              lastName,
              pictureFileResource,
              jobTitle,
            },
            group: this.selectedGroup,
          }],
          updated: true,
          selected: true,
        };
        this.setNewConversation(group);
        this.createConversation({
          users: (this.selectedGroup.users || []).filter((u) => u.uid !== uid),
          message,
          tempId: tempGroupId,
          messageTempId: tempMessageId,
          messageType: MessageType.REGULAR,
          targetMessageId: null,
          targetId: group.target
          && group.target.uid
            ? group.target.uid
            : null,
          targetType: group.target
          && group.target.__typename
            ? group.target.__typename
            : null,
        });
      } else {
        this.cleanupConversations();
        this.addMessages({
          uid: this.selectedGroup.uid,
          messages: [Message.hydrate({
            uid: tempMessageId,
            content: message,
            parentMessage: this.messageToReply,
            senttime: DateTimeHelper.toISO8601UTC(),
            user: {
              uid,
              firstName,
              lastName,
              pictureFileResource,
              jobTitle,
            } as CommunityUser,
            group: this.selectedGroup,
          })],
        });
        this.sendMessage({
          groupId: this.selectedGroup.uid,
          message,
          type: MessageType.REGULAR,
          tempId: tempMessageId,
          parentMessageId: this.messageToReply ? this.messageToReply.uid : null,
          targetMessageId: null,
        });
        this.messagesCount += 1;
        this.selectedGroup.messagesCount = this.messagesCount;
        this.scrollToBottom();
      }
      this.messageToReply = null;
      this.$emit('disable-message-box-for-toolbox-mode');
      this.changeStatus(MessageBoxActions.MESSAGES);
      this.renderMessageBoxWrite += 1;
    }
  }

  private sendConnectionRequest(message: string): void {
    const payload = {
      userId: this.authUser.uid,
      linkedUserId: this.fullUsers[0].uid,
      message,
    };
    this.connect(payload)
      .then(() => {
        this.createConversation({
          users: [...(this.selectedGroup.users || []).filter((u) => u.uid !== this.authUser.uid)],
          message,
          tempId: this.selectedGroup.uid,
          messageTempId: GUUID.uuidv4(),
          messageType: null,
          targetMessageId: null,
          targetId: null,
          targetType: null,
        });
      });
  }

  private onAcceptConnectionRequest(): void {
    if (this.selectedGroup && this.selectedGroup.uid) {
      const connection = this.selectedGroup.users?.find((u) => u.uid !== this.authUser.uid);
      if (connection && connection._ourConnection && connection._ourConnection.uid) {
        this.acceptConnection({
          uid: connection._ourConnection.uid,
          toastUserFullName: this.fullNames[0],
        })
          .then(() => {
            this.acceptConversation({ uid: this.selectedGroup.uid });
          });
      }
    }
  }

  private onDeclineConnectionRequest(): void {
    if (this.selectedGroup && this.selectedGroup.uid) {
      const connection = this.selectedGroup.users?.find((u) => u.uid !== this.authUser.uid);
      if (connection && connection._ourConnection && connection._ourConnection.uid) {
        this.declineConnection({
          uid: connection._ourConnection.uid,
          toastUserFullName: this.fullNames[0],
        })
          .then(() => {
            this.declineConversation({ uid: this.selectedGroup.uid });
          });
      }
    }
  }

  private unmute(): void {
    if (this.yourGroupState) {
      this.toggleMuteState({
        uid: this.yourGroupState,
        muted: false,
      });
    }
  }

  private toggleChatState(): void {
    this.isCloseMenuOpened = false;
    if (this.selectedGroup) {
      this.selectedGroup.active = !this.selectedGroup.active;
      if (this.selectedGroup.uid === this.selectedGroup.tempId) {
        this.createConversation({
          users: [this.authUser],
          message: null,
          tempId: this.selectedGroup.uid,
          messageTempId: GUUID.uuidv4(),
          messageType: null,
          targetMessageId: null,
          targetId: this.targetSession?.uid ?? null,
          targetType: EntityType.SESSION,
          active: true,
        });
      } else {
        this.changeChatState({
          uid: this.selectedGroup.uid,
          active: this.selectedGroup.active,
        });
      }
    }
  }
}
