import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import Product from '@/models/graphql/Product';
import ProductRepository from '@/repositories/ProductRepository';
import Category from '@/models/graphql/Category';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import EntityType from '@/utils/enums/EntityType';
import FileResourceParams from '@/utils/types/FileResourceParams';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { ProductFilter } from '@/graphql/_Filters/ProductFilter';

interface ProductState extends LoadableState {
  allPromisesLoading: boolean;
}

@Module({ namespaced: true })
export default class ProductStore extends LoadableStore<ProductState> {
  allPromisesLoading = false;

  private readonly productRepository = new ProductRepository();

  get getAllPromisesLoading(): boolean {
    return this.allPromisesLoading;
  }

  protected get repository(): ProductRepository {
    return this.productRepository;
  }

  @Action
  editProduct(payload: {
    exhibitorId: string;
    product: {
      uid: string;
      name: string;
      url: string;
      dimensions: string;
      manufacturer: string;
      model: string;
      serie: string;
      serialNumber: string;
      price: number;
      description: string;
      publicationTime: string;
      endPublicationTime: string;
      oldCategories: [];
      newCategories: [];
      newDealLink: string;
      oldDealLink: string;
    };
  }): Promise<Product | undefined> {
    this.context.commit('setAllPromisesLoading', true);
    const finalProduct = { ...payload.product };
    if ('oldCategories' in finalProduct) {
      delete finalProduct.oldCategories;
    }
    if ('newCategories' in finalProduct) {
      delete finalProduct.newCategories;
    }
    if ('newDealLink' in finalProduct) {
      delete finalProduct.newDealLink;
    }
    if ('oldDealLink' in finalProduct) {
      delete finalProduct.oldDealLink;
    }
    if (payload.product.uid) {
      return this.repository.update({
        definition: buildMutationDefinition([
          {
            fieldName: 'entity',
            type: GqlEntityInputType.PRODUCT_INPUT,
            value: finalProduct,
          },
        ]),
      });
    }
    return this.repository.createProductForExhibitor({
      definition: buildMutationDefinition([
        {
          fieldName: 'exhibitor_ExhibitorUid',
          type: 'ID',
          value: payload.exhibitorId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.PRODUCT_INPUT,
          value: finalProduct,
        },
      ]),
    });
  }

  @Action
  updateProductLinkedEntities(payload: {
    communityCode: string;
    product: {
      uid: string;
      oldCategories: [];
      newCategories: [];
      imagesToDelete: [];
      imagesToAdd: [];
      handoutsToDelete: [];
      handoutsToAdd: [];
      translations: Record<string, string>;
    };
  }): Promise<void> {
    let translationsToUpdatePromise: Promise<Product | undefined>[] = [];
    if (Object.keys(payload.product.translations).length > 0) {
      translationsToUpdatePromise = Object.keys(payload.product.translations).map((key) => {
        const infos = key.split('-');
        return this.repository.updateByLocale({
          definition: buildMutationDefinition([
            {
              fieldName: 'entity',
              type: GqlEntityInputType.PRODUCT_INPUT,
              value: {
                uid: payload.product.uid,
                [infos[0]]: payload.product.translations[key],
              },
            },
          ]),
        }, infos[1]);
      });
    }
    const categoriesToDelete: string[] = [];
    if (payload.product.oldCategories) {
      payload.product.oldCategories.forEach((category: Category) => {
        const toDelete = payload.product.newCategories
          .find((newCategory: Category) => newCategory.uid === category.uid);
        if (toDelete === undefined) {
          const alreadyInArray = categoriesToDelete.includes(category.uid);
          if (!alreadyInArray) {
            categoriesToDelete.push(category.uid);
          }
        }
      });
    }

    const categoriesToAdd: string[] = [];
    if (payload.product.newCategories) {
      payload.product.newCategories.forEach((category: Category) => {
        const toAdd = payload.product.oldCategories
          .find((newCategory: Category) => newCategory.uid === category.uid);
        if (toAdd === undefined) {
          const alreadyInArray = categoriesToAdd.includes(category.uid);
          if (!alreadyInArray) {
            categoriesToAdd.push(category.uid);
          }
        }
      });
    }

    let categoriesToAddPromise: Promise<Record<string, Product>[]> | null = null;
    if (categoriesToAdd.length > 0) {
      categoriesToAddPromise = this.repository.addCategoriesToProduct({
        definitions: categoriesToAdd.map((category, index) => ({
          operation: 'ProductAddCategory',
          gqlDefinition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: 'ID',
              value: payload.product.uid,
            },
            {
              fieldName: 'category_CategoryUid',
              type: 'ID',
              value: category,
            },
          ]),
          fragmentName: 'productBaseFragment',
          alias: `add${index}`,
        })),
      });
    }

    let categoriesToDeletePromise: Promise<Record<string, Product>[]> | null = null;
    if (categoriesToDelete.length > 0) {
      categoriesToDeletePromise = this.repository.removeCategoriesFromProduct({
        definitions: categoriesToDelete.map((category, index) => ({
          operation: 'ProductRemoveCategory',
          gqlDefinition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: 'ID',
              value: payload.product.uid,
            },
            {
              fieldName: 'category_CategoryUid',
              type: 'ID',
              value: category,
            },
          ]),
          fragmentName: 'productBaseFragment',
          alias: `delete${index}`,
        })),
      });
    }

    const imagesToAddPromise = this.context.dispatch(
      'ProductImageStore/createProductImagesForProduct',
      { productUid: payload.product.uid, images: payload.product.imagesToAdd },
      { root: true },
    );

    const imagesToDeletePromise = this.context.dispatch(
      'ProductImageStore/deleteProductImagesForProduct',
      payload.product.imagesToDelete,
      { root: true },
    );

    const handoutsToAddPromise = this.context.dispatch(
      'HandoutStore/createHandoutsForEntity', {
        entityType: EntityType.PRODUCT,
        entityId: payload.product.uid,
        handouts: payload.product.handoutsToAdd.map((h: FileResourceParams) => ({
          name: h.name,
          type: EntityType.PRODUCT,
          schemaCode: payload.communityCode,
          handoutFileResourceBase64: h.base64Path,
          handoutFileResourceFilename: h.fileName,
        })),
      },
      { root: true },
    );

    const handoutsToDeletePromise = this.context.dispatch(
      'HandoutStore/deleteHandouts',
      payload.product.handoutsToDelete,
      { root: true },
    );

    const allPromises = [
      imagesToDeletePromise,
      imagesToAddPromise,
      handoutsToAddPromise,
      handoutsToDeletePromise,
      ...translationsToUpdatePromise,
    ];
    if (categoriesToAddPromise) {
      allPromises.push(categoriesToAddPromise);
    }
    if (categoriesToDeletePromise) {
      allPromises.push(categoriesToDeletePromise);
    }

    return Promise.all(allPromises).then(() => {
      this.context.commit('setAllPromisesLoading', false);
      return Promise.resolve();
    });
  }

  @Action
  publishProduct(payload: { uid: string; publicationTime: string }): Promise<Product | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.PRODUCT_INPUT,
        value: { ...payload, endPublicationTime: null },
      }]),
    });
  }

  @Action
  unpublishProduct(payload: { uid: string; endPublicationTime: string }): Promise<Product | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.PRODUCT_INPUT,
        value: payload,
      }]),
    });
  }

  @Action
  deleteProduct(uid: string): Promise<boolean> {
    return this.repository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    }).then((response) => !!response);
  }

  @Mutation
  setAllPromisesLoading(allPromisesLoading: boolean): void {
    this.allPromisesLoading = allPromisesLoading;
  }

  @Action
  loadProducts(filter: ProductFilter): Promise<Product[]> {
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.PRODUCT_FILTER,
        },
      }),
    });
  }
}
