export function runMathJax(): void {
  if (window.MathJax) {
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
  }
}

function setConfigMathJax(): void {
  if (window.MathJax) {
    window.MathJax.Hub.Config({
      extensions: ['tex2jax.js'],
      jax: ['input/TeX', 'output/HTML-CSS'],
      tex2jax: {
        inlineMath: [['$', '$'], ['{', '}']],
        displayMath: [['$$', '$$'], ['\\[', '\\]']],
        processEscapes: true,
      },
      CommonHTML: { scale: 100 },
      'HTML-CSS': { scale: 100 },
      SVG: { scale: 100 },
    });
  }
}

/* importing MathJax using CDN because npm package having issue with ES6 import */
export function loadMathJax(): void {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.onload = setConfigMathJax;
  script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML';
  document.head.appendChild(script);
}
