/* eslint-disable max-len */
import { Module, Action } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CommunityUserInterest from '@/models/graphql/CommunityUserInterest';
import CommunityUserInterestRepository from '@/repositories/CommunityUserInterestRepository';
import CommunityUser from '@/models/graphql/CommunityUser';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class CommunityUserInterestStore extends LoadableStore<LoadableState> {
  private readonly communityUserInterestRepository = new CommunityUserInterestRepository();

  protected get repository(): CommunityUserInterestRepository {
    return this.communityUserInterestRepository;
  }

  @Action
  deleteUserInterest(uid: string): Promise<CommunityUserInterest | undefined> {
    return this.communityUserInterestRepository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: uid,
        },
      ]),
    });
  }

  @Action
  updateUserInterest(payload: {
    categoriesToAdd: string[];
    interestsToDelete: string[];
    authUser: string;
  }): Promise<void> {
    this.context.commit('load', true);
    const deletedInterestsDefinitions = payload.interestsToDelete ? payload.interestsToDelete
      .map((uid: string, index: number) => ({
        operation: 'CommunityUserInterestDelete',
        fragmentName: 'communityUserInterestBaseFragment',
        alias: `delete${index}`,
        gqlDefinition: buildMutationDefinition([
          {
            fieldName: 'uid',
            type: GqlEntityInputType.ID,
            value: uid,
          },
        ]),
      })) : [];
    const createdInterestsDefinitions = payload.categoriesToAdd ? payload.categoriesToAdd
      .map((uid: string, index: number) => ({
        operation: 'CommunityUserInterestCreateForUserAndInterest',
        fragmentName: 'communityUserInterestBaseFragment',
        alias: `create${index}`,
        gqlDefinition: buildMutationDefinition([
          {
            fieldName: 'user_CommunityUserUid',
            type: GqlEntityInputType.ID,
            value: payload.authUser,
          },
          {
            fieldName: 'interest_CategoryUid',
            type: GqlEntityInputType.ID,
            value: uid,
          },
          {
            fieldName: 'entity',
            type: GqlEntityInputType.COMMUNITY_USER_INTEREST_INPUT,
            value: { hold: false },
          },
        ]),
      })) : [];

    return this.repository.manageCommunityUserInterest({
      definitions: [...deletedInterestsDefinitions, ...createdInterestsDefinitions],
    }).then(() => {
      this.context.dispatch('CommunityUserStore/get', {
        filter: { uid: payload.authUser },
      }, { root: true })
        .then((user) => {
          const fecthedCommunityUser = user as unknown as CommunityUser;
          if (fecthedCommunityUser && fecthedCommunityUser.interests && this.context.rootState.authUser) {
            this.context.rootState.authUser.interests = fecthedCommunityUser.interests;
          }
          this.context.commit('load', false);
        });
    });
  }
}
