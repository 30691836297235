
















































































































































































































































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { clipperFixed, clipperRange, clipperUpload } from 'vuejs-clipper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Clipper from '@/utils/types/Clipper';
import ClipperUpload from '@/utils/types/ClipperUpload';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import DeviceCameraComponent from '@/components/DeviceCameraComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    ButtonComponent,
    clipperFixed,
    clipperUpload,
    clipperRange,
    DeviceCameraComponent,
  },
  data(): object {
    return {
      src: '',
      coordinates: {
        left: 0,
        top: 0,
      },
      isCameraOpen: false,
      rotate: 0,
      zoom: 1,
    };
  },
})
export default class EditFileResourceModal extends Vue {
  @Prop({ required: true })
  type!: string;

  @Prop({ default: '' })
  image!: string;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  okText!: string;

  @Prop({ required: true })
  cameraText!: string;

  @Prop({ required: true })
  cancelText!: string;

  @Prop({ required: true })
  deleteText!: string;

  @Prop({ default: true })
  isRounded!: boolean;

  @Prop({ default: false })
  isPortrait!: boolean;

  @Prop({ default: false })
  isSquare!: boolean;

  @Prop({ default: false })
  uploading!: boolean;

  @Prop({ default: false })
  payloadTooLarge!: boolean;

  @Prop()
  zoomValue!: number;

  @Prop({ required: false, default: '' })
  optimalDimensions!: string;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  private visible = false;

  private firstTimeClipper = true;

  private rotationStep = 1;

  private zoomStep = 0.1;

  private toDelete = false;

  private toAdd = false;

  private formatNotSupported = false;

  private fileTooLarge = false;

  private get isLargeDevice(): boolean {
    switch (this.currentBootstrapBreakpoint) {
      case 'lg':
      case 'xl':
        return true;
      default:
        return false;
    }
  }

  private get isMediumDevice(): boolean {
    switch (this.currentBootstrapBreakpoint) {
      case 'md':
        return true;
      default:
        return false;
    }
  }

  private get isSmallDevice(): boolean {
    switch (this.currentBootstrapBreakpoint) {
      case 'sm':
      case 'xs':
        return true;
      default:
        return false;
    }
  }

  private get avatarAreaValue(): number {
    switch (this.currentBootstrapBreakpoint) {
      case 'md':
        return 54.75;
      case 'sm':
      case 'xs':
        return 50;
      default:
        return 50;
    }
  }

  mounted(): void {
    this.setImage();
    if (this.isRounded) {
      this.$data.zoom = 0.4;
    }
    if (this.isPortrait) {
      this.$data.zoom = 0.5;
    }
  }

  public showImage(): string {
    if (this.$data.src === '') {
      return '';
    }
    const canvas = (this.$refs.clipper as unknown as Clipper).clip();
    return canvas.toDataURL('image/jpeg', 1);
  }

  public showCamera(): void {
    this.$data.src = '';
    this.$data.isCameraOpen = true;
  }

  @Watch('image')
  private setImage(): void {
    this.formatNotSupported = false;
    if (!this.uploading && !this.fileTooLarge) {
      this.$data.src = this.image;
    }
  }

  @Watch('payloadTooLarge')
  private setPayloadTooLarge(): void {
    this.fileTooLarge = this.payloadTooLarge;
  }

  private onSave(): void {
    if (this.toAdd && this.$data.src) {
      this.toAdd = false;
      this.onAdd();
      if (this.toDelete) {
        this.toDelete = false;
      }
    } else if (this.toDelete || !!this.image) {
      this.toDelete = false;
      this.onRemove();
    }
    this.closeCamera();
  }

  private onCancel(): void {
    this.$data.src = this.image;
    this.$bvModal.hide(this.type);
    this.closeCamera();
  }

  private closeCamera(): void {
    const deviceCameraComponent = (this.$refs.deviceCameraComponent as DeviceCameraComponent);
    if (deviceCameraComponent) {
      deviceCameraComponent.pauseVideo();
    }
    if (this.$data.isCameraOpen) {
      this.$data.isCameraOpen = false;
    }
  }

  private onAdd(): void {
    this.$emit('on-add', {
      deleteBefore: this.toDelete || !!this.image,
    });
  }

  private onRemove(): void {
    this.$emit('on-remove');
    if (!this.uploading) {
      this.$data.src = '';
      this.toDelete = false;
    }
  }

  @Watch('$data.rotate')
  private getRotationValue(): number {
    return this.$data.rotate;
  }

  @Watch('currentBootstrapBreakpoint')
  private closeModal(): void {
    return this.$bvModal.hide(this.type);
  }

  @Watch('$data.zoom')
  private getZoomValue(): void {
    if (typeof this.$data.zoom === 'string') {
      this.$data.zoom = parseFloat(this.$data.zoom);
    }
    if ((this.$refs.clipper as unknown as Clipper)) {
      (this.$refs.clipper as unknown as Clipper).setWH$.next(this.$data.zoom);
    }
  }

  private resetCoordinatesAndZoom(): void {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$refs.clipper) {
          (this.$refs.clipper as unknown as Clipper).setTL$
            .next({ top: 0, left: 0 });
          if (this.isRounded) {
            if (this.isSmallDevice) {
              (this.$refs.clipper as unknown as Clipper).setWH$
                .next(0.5);
              this.$data.zoom = 0.5;
            } else {
              (this.$refs.clipper as unknown as Clipper).setWH$
                .next(0.4);
              this.$data.zoom = 0.4;
            }
          } else if (this.isPortrait) {
            if (this.isSmallDevice) {
              (this.$refs.clipper as unknown as Clipper).setWH$
                .next(0.6);
              this.$data.zoom = 0.6;
            } else {
              (this.$refs.clipper as unknown as Clipper).setWH$
                .next(0.4);
              this.$data.zoom = 0.4;
            }
          } else {
            (this.$refs.clipper as unknown as Clipper).setWH$
              .next(1);
            this.$data.zoom = 1;
          }
        }
        this.$data.rotate = 0;
      }, 80);
    });
  }

  private handleZoom = (scale: number): void => {
    if (this.$refs.clipper && this.firstTimeClipper) {
      this.firstTimeClipper = false;
    }
    if (this.$data && scale) {
      this.$data.zoom = scale;
    }
  }

  private onRotateLeft(): void {
    this.$data.rotate -= this.rotationStep;
  }

  private onRotateRight(): void {
    this.$data.rotate += this.rotationStep;
  }

  private onZoomOut(): void {
    if (this.$data.zoom - this.zoomStep > 0) {
      this.$data.zoom = Number.parseFloat((this.$data.zoom - this.zoomStep).toFixed(1));
    }
  }

  private onZoomIn(): void {
    if (this.$data.zoom + this.zoomStep <= 3) {
      this.$data.zoom = Number.parseFloat((this.$data.zoom + this.zoomStep).toFixed(1));
    }
  }

  private onDrop(e: DragEvent): void {
    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer !== null ? e.dataTransfer.files[0] : null;
    if (file && (file?.type === 'image/png'
      || file?.type === 'image/jpg'
      || file?.type === 'image/jpeg'
      || file?.type === 'image/webp')) {
      this.formatNotSupported = false;
      const reader = new FileReader();
      reader.onload = () => {
        this.$data.src = reader.result;
      };
      this.resetCoordinatesAndZoom();
      reader.readAsDataURL(file);
    } else {
      this.$data.src = '';
      // eslint-disable-next-line no-alert
      this.formatNotSupported = true;
    }
  }

  private allowDrop = (e: DragEvent): void => {
    e.stopPropagation();
    e.preventDefault();
    const dropTarget = document.getElementsByClassName('target-drop');
    const placeholder = document.getElementsByClassName('dropdown-placeholder');
    if (!placeholder[0]) {
      dropTarget[0].classList.add('dropdown-placeholder');
    }
  }

  private allowDropEnd = (e: DragEvent): void => {
    e.stopPropagation();
    e.preventDefault();
    const dropTarget = document.getElementsByClassName('target-drop');
    dropTarget[0].classList.remove('dropdown-placeholder');
  }

  private onTakePhoto(newPhoto: string): void {
    this.$data.isCameraOpen = false;
    this.formatNotSupported = false;
    this.fileTooLarge = false;
    this.$data.src = newPhoto;
  }

  private onErrorPhoto(): void {
    this.$data.isCameraOpen = false;
    // eslint-disable-next-line no-alert
    alert(this.$t('app.my-profile.edit.modal.alert.camera-not-available'));
  }

  private onUpload(): void {
    this.fileTooLarge = false;
    this.resetCoordinatesAndZoom();
    const uploadFile = (this.$refs['clipper-upload'] as ClipperUpload).file;
    if (uploadFile?.type === 'image/png' || uploadFile?.type === 'image/jpg'
      || uploadFile?.type === 'image/jpeg'
      || uploadFile?.type === 'image/webp') {
      this.formatNotSupported = false;
      this.$data.rotate = 0;
      if (this.isRounded) {
        this.$data.zoom = 0.4;
      } else if (this.isPortrait) {
        this.$data.zoom = 0.5;
      } else {
        this.$data.zoom = 1;
      }
    } else {
      this.$data.src = '';
      // eslint-disable-next-line no-alert
      this.formatNotSupported = true;
    }
  }

  private onHide(): void {
    if (!this.uploading) {
      this.$data.src = this.image;
    }
    this.formatNotSupported = false;
    this.fileTooLarge = false;
    this.closeCamera();
  }

  private getDeviceType(): string {
    switch (this.currentBootstrapBreakpoint) {
      case 'lg':
      case 'xl':
        return 'lg';
      case 'md':
        return 'md';
      case 'sm':
        return 'sm small';
      case 'xs':
        return 'xs small';
      default:
        return '';
    }
  }

  private toggleUploadFiles(): void {
    const deviceCameraComponent = (this.$refs.deviceCameraComponent as DeviceCameraComponent);
    deviceCameraComponent.pauseVideo();
    this.$nextTick(() => {
      this.$data.isCameraOpen = false;
    });
  }
}
