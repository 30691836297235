import { Action, Module } from 'vuex-module-decorators';
import LoadableStore from '@/store/LoadableStore';
import BaseModel from '@/models/BaseModel';
import FeedPostRepository from '@/repositories/FeedPostRepository';
import FeedPost from '@/models/graphql/FeedPost';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Module({ namespaced: true })
export default class FeedPostStore extends LoadableStore<BaseModel> {
  private readonly FeedPostRepository = new FeedPostRepository();

  protected get repository(): FeedPostRepository {
    return this.FeedPostRepository;
  }

  @Action
  getCommentFromFeedPost(payload: { feedPostUid: string }): Promise<FeedPost | undefined> {
    return this.repository.get(
      {
        operationName: 'GetFeedPostComments',
        definition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.FEED_POST_FILTER,
            value: {
              uid: payload.feedPostUid,
            },
          },
        }),
        authUser: this.context.rootState.authUser?.uid,
      },
    );
  }
}
