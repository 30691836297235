var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"b-hover",rawName:"v-b-hover",value:(_vm.handleHover),expression:"handleHover"}],class:[
    'message-box-bubble position-relative d-flex column-gap-4 align-items-start cursor-pointer', {'dark': _vm.darkMode},
    {'p-3': !_vm.noPadding},
    {'user-select-none': _vm.error} ],on:{"click":_vm.retry}},[(_vm.message && _vm.message.messageType === _vm.MessageType.USER_JOIN)?_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100"},[(_vm.message.user)?_c('pill-widget',{attrs:{"label":("" + (_vm.isYou ?
        _vm.$t('chat.content.messages.you-join') :
        _vm.$t('chat.content.messages.user-join', { username: _vm.message.user.firstName }))),"variant":_vm.darkMode ? 'dark-gray' : 'gray',"size":"sm"}}):_vm._e()],1):(_vm.message && _vm.message.messageType === _vm.MessageType.USER_LEAVE)?_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100"},[(_vm.message.user)?_c('pill-widget',{attrs:{"label":("" + (_vm.isYou ?
        _vm.$t('chat.content.messages.you-left') :
        _vm.$t('chat.content.messages.user-left', { username: _vm.message.user.firstName }))),"variant":_vm.darkMode ? 'dark-gray' : 'gray',"size":"sm"}}):_vm._e()],1):[(_vm.message && _vm.message.user)?_c('b-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'member-detail', params: { memberId: _vm.message.user.uid } }}},[_c('avatar-solo-widget',{attrs:{"firstname":!_vm.message.userDeleted ? _vm.message.user.firstName : '',"icon-main-container-classes":_vm.message.userDeleted ? 'bg-neutral-n-3-disable' : '',"lastname":!_vm.message.userDeleted ? _vm.message.user.lastName : '',"src":!_vm.message.userDeleted ? _vm.message.user.profilePicture: '',"with-borders":!_vm.message.user.profilePicture,"default-icon":"fa-solid fa-trash-can","icon-classes":"icon-16 text-neutral-n-5-placeholder","size":"40"}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column overflow-hidden w-100"},[(!_vm.message.userDeleted)?_c('div',{staticClass:"d-flex column-gap-2 align-items-center"},[_c('b-link',{class:['text-left paragraph m-0 font-weight-bold text-decoration-none text-nowrap',
                   _vm.darkMode ? 'text-neutral-n-3-disable' : 'text-neutral-n-8-dark-ink'],attrs:{"to":{ name: 'member-detail', params: { memberId: _vm.message.user.uid } }}},[_vm._v(" "+_vm._s(_vm.messageUserName)+" ")]),(_vm.isSessionModerator)?_c('font-awesome-component',{attrs:{"container-classes":"icon-16","icon":"fa-solid fa-shield-check","icon-classes":"icon-12 text-orange"}}):_vm._e(),_c('span',{class:['text-left label label-4 text-nowrap',
                   _vm.darkMode ? 'text-neutral-n-5-placeholder' : 'text-neutral-n-6-label']},[_vm._v(" "+_vm._s(_vm.formatTime)+" ")])],1):_c('label',{staticClass:"text-left m-0 label-3 text-neutral-n-6-label my-12"},[_vm._v(" "+_vm._s(_vm.$t('chat.content.messages.delete-message-text'))+" ")]),_c('div',{staticClass:"d-flex flex-column align-items-start row-gap-2 w-100 ws-pre-wrap message-content"},[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"d-flex w-100"},[(!_vm.message.userDeleted && _vm.content)?_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.content),expression:"content"}],class:['text-left label label-2 w-100',
                       _vm.darkMode ? 'text-neutral-n-3-disable' : 'text-neutral-n-8-dark-ink']}):_vm._e()])]),_vm._l((!_vm.message.userDeleted ? _vm.ogLinksData : []),function(data,index){return _c('og-link-card',_vm._b({key:index,attrs:{"is-loading":_vm.isLoading}},'og-link-card',data,false))}),(!_vm.message.userDeleted && !_vm.youAreBanned && _vm.message.nbReactions > 0)?_c('div',{class:['like-reaction px-2 py-1 border-1 d-flex align-items-center column-gap-1',
                   {'my-reaction': _vm.message.myReactions.length > 0}, 'cursor-pointer rounded-pill'],on:{"click":_vm.toggleReaction}},[_c('font-awesome-component',{attrs:{"container-classes":"icon-16","icon":"fa-solid fa-heart","icon-classes":"icon-12 text-red-r-3-primary-red"}}),_c('label',{class:['label-3 medium m-0 text-left cursor-pointer',
                     _vm.message.myReactions.length > 0 ? 'text-blue-b-3-primary-blue' : 'text-neutral-n-8-dark-ink']},[_vm._v(" "+_vm._s(_vm.message.nbReactions)+" ")])],1):_vm._e(),(_vm.parentMessage)?_c('div',{staticClass:"reply-message mt-2 d-flex flex-column row-gap-2 border-left-4 border-neutral-n-4-line pl-3"},[_c('div',{staticClass:"d-flex align-items-center column-gap-2"},[_c('font-awesome-component',{attrs:{"stop-propagation":true,"container-classes":"icon-16","icon":"far fa-reply","icon-classes":"icon-12 text-neutral-n-6-label"}}),(_vm.parentMessage
                && !_vm.parentMessage.userDeleted
                && _vm.parentMessage.user)?_c('b-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'member-detail', params: { memberId: _vm.parentMessage.user.uid } }}},[_c('avatar-solo-widget',{attrs:{"firstname":_vm.parentMessage.user.firstName,"lastname":_vm.parentMessage.user.lastName,"src":_vm.parentMessage.user.profilePicture,"with-borders":!_vm.parentMessage.user.profilePicture,"size":"16"}})],1):_vm._e(),(!_vm.parentMessage.userDeleted)?_c('b-link',{staticClass:"label text-left m-0 medium text-neutral-n-6-label text-decoration-none text-nowrap",attrs:{"to":{ name: 'member-detail', params: { memberId: _vm.parentMessage.user.uid } }}},[_vm._v(" "+_vm._s(_vm.parentMessageUserName)+" ")]):_c('label',{staticClass:"text-left m-0 label-3 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('chat.content.messages.delete-message-text'))+" ")])],1),(!_vm.parentMessage.userDeleted)?_c('label',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.parentMessage.content),expression:"parentMessage.content"}],staticClass:"label-3 m-0 text-left text-neutral-n-6-label message-content"}):_vm._e()]):_vm._e(),(_vm.error)?_c('p',{staticClass:"m-0 paragraph-3 text-red-r-3-primary-red text-left"},[_vm._v(" "+_vm._s(_vm.$t('chat.content.list-view.no-internet.send'))+" ")]):_vm._e()],2)]),(_vm.isHovered && !_vm.message.userDeleted && !_vm.youAreBanned && (_vm.showAction || _vm.canModerate)
        && (!_vm.isChatClosed || _vm.canModerate))?_c('div',{staticClass:"reaction-menu position-absolute d-flex align-items-center bg-white-white-100\n      border-1 border-neutral-n-4-line rounded p-2 gap-2 shadows-sm"},[(_vm.canLike && (!_vm.isChatClosed || _vm.canModerate))?_c('font-awesome-component',_vm._b({attrs:{"icon":((_vm.message.myReactions.length > 0 ? 'fa-solid' : 'far') + " fa-heart"),"icon-classes":['icon-16',
                        _vm.message.myReactions.length > 0 ? 'text-red-r-3-primary-red' : 'text-neutral-n-6-label'],"container-classes":"icon-24"},on:{"on-click":_vm.toggleReaction}},'font-awesome-component',_vm.useTestDataAttribute('message-box-bubble-toggle-reaction'),false)):_vm._e(),(!_vm.isChatClosed || _vm.canModerate)?_c('font-awesome-component',_vm._b({attrs:{"container-classes":"icon-24","icon":"far fa-reply","icon-classes":"icon-16 text-neutral-n-6-label"},on:{"on-click":_vm.onReplyToMessage}},'font-awesome-component',_vm.useTestDataAttribute('message-box-bubble-reply'),false)):_vm._e(),(_vm.canModerate)?_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-ellipsis","icon-classes":"icon-16 text-neutral-n-6-label"},on:{"on-click":function($event){_vm.openedMenu = !_vm.openedMenu}}}):_vm._e(),(_vm.canModerate)?_c('div',{staticClass:"position-relative"},[(_vm.openedMenu)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.openedMenu = false; }),expression:"() => openedMenu = false"}],staticClass:"message-menu bg-white-white-100 position-absolute rounded py-2 shadows-md"},[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.getLoadingState},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-skeleton',{staticClass:"w-100",attrs:{"type":"button"}})]},proxy:true},{key:"default",fn:function(){return [(_vm.message.user
                  && _vm.authUser
                  && _vm.message.user.uid !== _vm.authUser.uid
                  && !_vm.getLoadingState)?_c('dropdown-menu-item',{staticClass:"cursor-pointer font-weight-normal",attrs:{"text":("" + (_vm.$t(!_vm.banned ? 'chat.content.messages.ban-user' : 'chat.content.messages.unban-user'))),"icon":"fa-regular fa-ban"},on:{"on-click":_vm.toggleBanUser}}):_vm._e()]},proxy:true}],null,false,3527178722)}),_c('dropdown-menu-item',{staticClass:"cursor-pointer font-weight-normal",attrs:{"text":("" + (_vm.$t('chat.content.messages.delete-message'))),"icon":"fa-regular fa-trash-can"},on:{"on-click":_vm.deleteMessage}})],1):_vm._e()]):_vm._e()],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }