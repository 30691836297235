import { ApolloQueryResult, FetchResult } from '@apollo/client';
import CommunityUserNote from '@/models/graphql/CommunityUserNote';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import CountInterface from '@/repositories/base/CountInterface';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import WriteInterface from '@/repositories/base/WriteInterface';

export default class CommunityUserNoteRepository extends GraphQlRepository<CommunityUserNote>
  implements WriteInterface<CommunityUserNote>,
    FilterInterface<CommunityUserNote>,
    CountInterface {
  update(params: GqlPayloadParams): Promise<CommunityUserNote | null | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdateCommunityUserNote',
      operation: 'CommunityUserNoteUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserNoteFullFragment',
    })
      .then((mutation) => this.mutate<{
        CommunityUserNoteUpdate: CommunityUserNote;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          CommunityUserNoteUpdate: CommunityUserNote;
        }>) => response.data?.CommunityUserNoteUpdate));
  }

  get(payload: { params: GqlPayloadParams; operation: string }): Promise<{
    __typename: string;
  } & object> {
    return buildQueryGql({
      operationName: 'LoadNoteLinkedTarget',
      operation: payload.operation,
      gqlDefinition: payload.params.definition as GqlQueryDefinition,
      fragmentName: payload.params.fragmentName || '',
    })
      .then((query) => this.query<
        Record<string, Array<{
          __typename: string;
        } & object>>, {}>(query, payload.params.definition.variables, false)
        .then((response: ApolloQueryResult<Record<string, Array<{
          __typename: string;
        } & object>>>) => response.data[payload.operation][0]));
  }

  filter(params: GqlPayloadParams): Promise<CommunityUserNote[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterCommunityUserNotes',
      operation: 'communityUserNote',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'communityUserNoteFullFragment',
      authUser: params.authUser,
    })
      .then((query) => this.query<{
        communityUserNote: Array<CommunityUserNote>;
      }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{
          communityUserNote: Array<CommunityUserNote>;
        }>) => response.data.communityUserNote));
  }

  count(params: GqlPayloadParams): Promise<number | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetCommunityUserNoteCount',
      operation: 'communityUserNote',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'communityUserNoteCountFragment',
      authUser: params.authUser,
    })
      .then((query) => this.use()
        .query<{
          communityUserNote: [{ count: number }];
        }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{
          communityUserNote: [{ count: number }];
        }>) => response.data.communityUserNote[0].count));
  }

  delete(params: GqlPayloadParams): Promise<CommunityUserNote | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeleteCommunityUserNote',
      operation: 'CommunityUserNoteDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserNoteBaseFragment',
    })
      .then((mutation) => this.mutate<{
        CommunityUserNoteDelete: CommunityUserNote;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          CommunityUserNoteDelete: CommunityUserNote;
        }>) => response.data?.CommunityUserNoteDelete));
  }

  create(params: GqlPayloadParams): Promise<CommunityUserNote | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateCommunityUserNote',
      operation: 'addCommunityUserNote',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserNoteFullFragment',
    })
      .then((mutation) => this.mutate<{
        addCommunityUserNote: CommunityUserNote;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          addCommunityUserNote: CommunityUserNote;
        }>) => response.data?.addCommunityUserNote));
  }

  removeLinkTarget(params: GqlPayloadParams): Promise<CommunityUserNote | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'RemoveCommunityUserNoteLinkTarget',
      operation: 'CommunityUserNoteRemoveLinkTarget',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserNoteBaseFragment',
    })
      .then((mutation) => this.mutate<{
        CommunityUserNoteRemoveLinkTarget: CommunityUserNote;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          CommunityUserNoteRemoveLinkTarget: CommunityUserNote;
        }>) => response.data?.CommunityUserNoteRemoveLinkTarget));
  }
}
