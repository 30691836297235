










































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import {
  addHours, compareAsc, format, getUnixTime, isSameYear, subWeeks,
} from 'date-fns';
import SoloDoubleAvatarComponent from '@/components/chat/SoloDoubleAvatarComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import MessageGroup from '@/models/graphql/MessageGroup';
import MessageBoxMenuComponent from '@/components/chat/MessageBoxMenuComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import MessageMenuItem from '@/utils/enums/chat/MessageMenuItem';
import GroupType from '@/utils/enums/chat/GroupType';
import { Getter, namespace, State } from 'vuex-class';
import EntityType from '@/utils/enums/EntityType';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Session from '@/models/graphql/Session';
import Exhibitor from '@/models/graphql/Exhibitor';
import CommunityUser from '@/models/graphql/CommunityUser';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import Meeting from '@/models/graphql/Meeting';
import Event from '@/utils/types/Event';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    FontAwesomeComponent,
    MessageBoxMenuComponent,
    ConfirmModal,
    ButtonIconComponent,
    SoloDoubleAvatarComponent,
  },
})
export default class MessageBoxListItemComponent extends Vue {
  @Getter
  private readonly authUser!: CommunityUser;

  @Prop({ required: true })
  private readonly data!: MessageGroup;

  @Prop({ required: true, default: false })
  private readonly isSelectable!: boolean;

  @Prop({ required: false, default: false })
  private readonly isActive!: boolean;

  @Prop({ required: false, default: () => [] })
  private readonly users!: CommunityUser[];

  @Prop({ required: false, default: true })
  private readonly showActions!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly isBasicLayout!: boolean;

  @agendaStore.Mutation
  private setEventEdited!: (e: Partial<Event>) => void;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private selected = false;

  private isHovered = false;

  private flipMenuOptions = false;

  private GroupType = GroupType;

  private EntityType = EntityType;

  private get images(): string[] {
    if (this.data && this.data.target) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.data.target.__typename === EntityType.SESSION) {
        return [FileResourceHelper.getFullPath((this.data.target as Session).imageFileResource, 'w40')];
      }
      // eslint-disable-next-line no-underscore-dangle
      if (this.data.target.__typename === EntityType.EXHIBITOR) {
        return [FileResourceHelper.getFullPath((this.data.target as Exhibitor).logoFileResource, 'w40')];
      }
    }
    if (this.data.activeMembersData.isUserAlone) {
      return [this.authUser.profilePicture];
    }
    return this.data.activeMembersData.images;
  }

  private get isAgendaFeatureActivated(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_AGENDA_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_AGENDA_FEATURE).enabled;
  }

  private get avatarNames(): Array<{ firstName: string; lastName: string }> {
    if (this.data
      && ((this.data.target && ![EntityType.SESSION, EntityType.EXHIBITOR]
        // eslint-disable-next-line no-underscore-dangle
        .includes(this.data.target.__typename as EntityType)) || !this.data.target)) {
      if (this.data.activeMembersData.isUserAlone) {
        return [{
          firstName: this.authUser.firstName as string,
          lastName: this.authUser.lastName as string,
        }];
      }
      return this.data.activeMembersData.fullUsers
        .map((u) => ({ firstName: u.firstName as string, lastName: u.lastName as string }));
    }

    return [];
  }

  private get title(): string {
    if (this.data && this.data.target) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.data.target.__typename === EntityType.SESSION) {
        return (this.data.target as Session).name as string;
      }
      // eslint-disable-next-line no-underscore-dangle
      if (this.data.target.__typename === EntityType.EXHIBITOR) {
        return (this.data.target as Exhibitor).name as string;
      }
      // eslint-disable-next-line no-underscore-dangle
      if (this.data.target.__typename === EntityType.MEETING) {
        return (this.data.target as Meeting).subject as string;
      }
    }
    if (this.data.activeMembersData.isUserAlone) {
      return this.$t('chat.content.messages.left-alone') as string;
    }
    return `${this.data.activeMembersData.names.slice(0, 2).join(', ')} ${this.data.activeMembersData.nbMembers > 2
      ? `+ ${(this.data.activeMembersData.nbMembers - 2)} ${this.$t('chat.header.others')}` : ''}`;
  }

  private get subtitle(): string {
    if (this.data && this.data.target) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.data.target.__typename === EntityType.SESSION) {
        return this.startTime;
      }
    }
    // eslint-disable-next-line no-nested-ternary
    return `${this.data.lastMessage && this.data.groupType === GroupType.CONNECTION
      ? this.$t('chat.content.list-view.item.connection-message')
      : this.data.lastMessage && this.data.lastMessage.content ? this.data.lastMessage.content : ''}`;
  }

  private get startTime(): string {
    if (this.data
      && this.data.target) {
      const entityTarget = this.data.target as Session;
      if (entityTarget.startTime) {
        const dateLocal = DateTimeHelper.toLocal(
          new Date(`${entityTarget.startTime}Z`),
        );
        return format(dateLocal,
          `${this.$t('app.date.defaultDateFormat')}
          ${this.$t('app.date.dateTimeAtSeparator')}
          ${this.$t('app.date.defaultTimeFormat')}`,
          { locale: this.dateLocale });
      }
    }
    return '';
  }

  private get menuConfig(): MessageMenuItem[] {
    const configs: MessageMenuItem[] = [];
    if (this.data
      && this.data.target
      // eslint-disable-next-line no-underscore-dangle
      && this.data.target.__typename === EntityType.SESSION) {
      configs.push(...[
        MessageMenuItem.SESSION_PREVIEW,
        MessageMenuItem.VIEW,
        MessageMenuItem.LEAVE,
      ]);
      configs.push(MessageMenuItem.SESSION_PREVIEW);
    } else if (this.data.target
      // eslint-disable-next-line no-underscore-dangle
      && this.data.target.__typename === EntityType.EXHIBITOR) {
      configs.push(...[
        MessageMenuItem.UNREAD,
        MessageMenuItem.MUTE,
        MessageMenuItem.VIEW,
        MessageMenuItem.LEAVE,
      ]);
    } else if (this.data.target
      // eslint-disable-next-line no-underscore-dangle
      && this.data.target.__typename === EntityType.MEETING) {
      configs.push(...[
        MessageMenuItem.UNREAD,
        MessageMenuItem.MUTE,
        MessageMenuItem.VIEW,
      ]);
    } else {
      if (!this.data.unreadState) {
        configs.push(MessageMenuItem.UNREAD);
      }
      configs.push(MessageMenuItem.MUTE);
      if (this.data.groupType === GroupType.CONNECTION) {
        if (this.data.users && this.data.users.length > 1) {
          configs.push(MessageMenuItem.PROFILE);
        }
        configs.push(...[
          MessageMenuItem.ACCEPT,
          MessageMenuItem.DECLINE,
        ]);
      }
      if (this.data.groupType === GroupType.DIRECT) {
        if (this.data.users && this.data.users.length > 1) {
          configs.push(MessageMenuItem.PROFILE);
        }
        configs.push(...[MessageMenuItem.ADD, MessageMenuItem.BLOCK, MessageMenuItem.DELETE]);
      }
      if (this.data.groupType === GroupType.GROUP) {
        configs.push(...[MessageMenuItem.VIEW, MessageMenuItem.ADD, MessageMenuItem.LEAVE]);
      }
    }

    if (this.data
      && !this.data.target
      && (this.data.groupType === GroupType.GROUP
        || this.data.groupType === GroupType.DIRECT)
      && this.isAgendaFeatureActivated
      && (this.data.users || []).length > 1) {
      configs.push(MessageMenuItem.MEET);
    }
    return configs;
  }

  private get receiveDateTime(): string {
    if (this.data.lastMessage
      && this.data.lastMessage.senttime) {
      const dateLocal = new Date(`${this.data.lastMessage.senttime}Z`);
      const today = format(new Date(), this.$t('app.date.defaultDateFormat') as string);
      const sentDate = format(dateLocal, this.$t('app.date.defaultDateFormat') as string);
      if (today === sentDate) {
        return format(dateLocal, this.$t('app.date.defaultTimeFormat') as string, { locale: this.dateLocale });
      }
      if (compareAsc(subWeeks(new Date(), 1), dateLocal) !== -1
        && isSameYear(new Date(), dateLocal)) {
        return format(dateLocal, this.$t('app.date.monthDayShort') as string, { locale: this.dateLocale });
      }
      if (compareAsc(subWeeks(new Date(), 1), dateLocal) === -1) {
        return format(dateLocal, this.$t('app.date.dayOfWeek') as string, { locale: this.dateLocale });
      }
      return format(dateLocal, this.$t('app.date.monthYearFull') as string, { locale: this.dateLocale });
    }
    return '';
  }

  mounted(): void {
    this.flipOptionsMenuWhenIsNeeded();
  }

  updated(): void {
    this.flipOptionsMenuWhenIsNeeded();
  }

  created(): void {
    if (this.data && 'selected' in this.data) {
      this.selected = this.data.selected;
    }
  }

  private manageClick(): void {
    if (this.isSelectable) {
      this.selected = !this.selected;
      this.$emit('toggle-selection', this.selected);
    } else {
      this.$emit('open-messages');
    }
  }

  @Watch('isHovered')
  private flipOptionsMenuWhenIsNeeded(): void {
    this.$nextTick(() => {
      const optionsMenu = this.$el as HTMLElement;
      if (optionsMenu
        && optionsMenu.parentElement
        && optionsMenu.getBoundingClientRect) {
        this.flipMenuOptions = optionsMenu.parentElement.getBoundingClientRect().bottom
          < optionsMenu.getBoundingClientRect().bottom + (this.menuConfig.length * 40) + 16;
      }
    });
  }

  private onCreateMeeting(): void {
    const participants: MeetingParticipant[] = [];
    this.users
      .filter((u) => u.uid !== this.authUser.uid)
      .forEach((user: CommunityUser) => {
        participants.push({ user } as MeetingParticipant);
      });
    const creator = CommunityUser
      .hydrate(Object.fromEntries(Object.entries(this.authUser)
        .filter(([k]) => [
          'uid',
          'schemaCode',
          'firstName',
          'lastName',
          'name',
          'jobTitle',
          'employerName',
          'pictureFileResource'].includes(k))));
    const now = DateTimeHelper.roundToNearest15(DateTimeHelper.getCurrentDateTime());
    const startTime = DateTimeHelper.toISO8601(now);
    const endTime = DateTimeHelper.toISO8601(addHours(now, 1));
    const startTimestamp = getUnixTime(now);
    const endTimestamp = getUnixTime(addHours(now, 1));
    const meeting = {
      uid: 'new-meeting',
      creator,
      isCancelled: false,
      participants,
      startTime,
      endTime,
      startTimestamp,
      endTimestamp,
    } as unknown as Meeting;
    const event = AgendaStoreHelper.convertMeetingToEvent(meeting, this.selectedTzName);
    this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
    this.setViewMode(ViewMode.CREATE);
    this.setEventEdited(event as Partial<Event>);
  }
}
