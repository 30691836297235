var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.window",value:(_vm.tooltipText),expression:"tooltipText",modifiers:{"hover":true,"bottom":true,"window":true}}],class:[
    'btn-icon-wrapper',
    _vm.getSizeClass(),
    {'background-secondary': _vm.backgroundSecondary},
    _vm.variant,
    {'radius' : _vm.rounded},
    { disabled: _vm.disabled },
    { 'rounded-circle': _vm.shape === 'round'}
  ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('on-click')}}},[_vm._t("icon"),(_vm.text)?_c('span',{staticClass:"ml-2 button"},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }