import BaseModel from '@/models/BaseModel';

export default class Dashboard extends BaseModel {
  sessions = {
    type: 'Session',
    total: 0,
    upcoming: { value: 0, variant: 'dark-gray' },
    live: { value: 0, variant: 'blue' },
  };

  meetings = { type: 'Meeting', total: 0, upcoming: { value: 0, variant: 'blue' } };

  connections = {
    type: 'CommunityUserConnection',
    total: 0,
    pending: { value: 0, variant: 'dark-gray' },
  };

  messages = { type: 'MessageGroup', total: 0 };

  static hydrate(item: object): Dashboard {
    const result = new Dashboard();
    Object.assign(result, item);

    return result;
  }
}
