import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import CommunityUserAddressRepository from '@/repositories/CommunityUserAddressRepository';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class CommunityUserAddressStore extends LoadableStore<LoadableState> {
    private readonly communityUserAddressRepository = new CommunityUserAddressRepository()

    private get repository(): CommunityUserAddressRepository {
      return this.communityUserAddressRepository;
    }

    @Action
    setCommunityAddress(payload: {addressId: string; uid: string}): void {
      this.repository.update({
        definition: buildMutationDefinition([
          {
            fieldName: 'uid',
            type: GqlEntityInputType.ID,
            value: payload.uid,
          },
          {
            fieldName: 'addres_AddressUid',
            type: GqlEntityInputType.ID,
            value: payload.addressId,
          },
        ]),
      });
    }
}
