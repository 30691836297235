var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.event.isCancelled)?_c('div',{directives:[{name:"b-hover",rawName:"v-b-hover",value:(function (value) {_vm.isHovered = value;}),expression:"(value) => {isHovered = value;}"}],ref:"agenda-list-item",class:[
    'agenda-list-item d-flex justify-content-between align-items-start rounded',
    {'session': _vm.type === _vm.EntityType.SESSION || _vm.type === _vm.EntityType.SCHEDULE_EVENT },
    {'meeting-accepted': _vm.type === _vm.EntityType.MEETING
      && (_vm.participantState === _vm.MeetingParticipantState.ACCEPTED || _vm.isCreator)},
    {'meeting-declined': _vm.type === _vm.EntityType.MEETING
      && _vm.participantState === _vm.MeetingParticipantState.DECLINED},
    {'meeting-invited': _vm.type === _vm.EntityType.MEETING
      && _vm.participantState === _vm.MeetingParticipantState.INVITED},
    {'new-meeting bg-white-white-100': _vm.isCreateEdit},
    _vm.isSmall ? 'p-1' : 'px-3 py-2' ],on:{"click":function($event){$event.stopPropagation();!_vm.isCreateEdit ? _vm.onEventClick() : ''}}},[_c('div',{staticClass:"d-flex row-gap-0 column-gap-2 align-items-start flex-wrap"},[(_vm.startTime)?_c('div',{staticClass:"text-left d-flex align-items-start"},[_c('label',{class:['medium text-left text-neutral-n-8-dark-ink m-0 label-3 cal-data-time-label',
                 {'strikethrough': _vm.participantState === _vm.MeetingParticipantState.DECLINED}
        ]},[_vm._v(" "+_vm._s(_vm.startTime)+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('label',{class:['medium text-left m-0 detail label-3 cal-data-title-label ellipsis-2 line-break-auto',
                 {'strikethrough': _vm.participantState === _vm.MeetingParticipantState.DECLINED},
                 {'pending text-neutral-n-6-label': _vm.participantState === _vm.MeetingParticipantState.INVITED},
                 {'text-neutral-n-5-placeholder': _vm.isCreateEdit}
        ]},[_vm._v(" "+_vm._s(_vm.title)+" ")])])]),(!_vm.isCreateEdit && !_vm.isSmall && (_vm.isHovered || _vm.size === 'sm' || _vm.size === 'md') && _vm.isCreator)?_c('div',{staticClass:"d-flex align-items-center column-gap-1"},[_c('button-icon-component',{attrs:{"rounded":true,"size":"sm","variant":"yellow"},on:{"on-click":_vm.onEditClick},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-16","icon":"far fa-pen","icon-classes":"icon-12 text-neutral-n-8-dark-ink"}})]},proxy:true}],null,false,1024236144)}),_c('button-icon-component',{attrs:{"rounded":true,"size":"sm","variant":"yellow"},on:{"on-click":_vm.cancelMeeting},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-16","icon":"far fa-trash-can","icon-classes":"icon-12 text-neutral-n-8-dark-ink"}})]},proxy:true}],null,false,3145300342)})],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }