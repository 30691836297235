/* eslint-disable import/no-cycle */
import _Vue from 'vue';
import EventsBusInterface from '@/utils/event-bus/EventsBusInterface';

export type EventCallback = string | number | boolean | object | [] | undefined | null;

export default class EventBus implements EventsBusInterface {
  private buffer: { name: string; payload: Event }[] = [];

  private element = document.body;

  emit = (name: string, payload?: EventCallback, bubbles?: boolean, cancelable?: boolean): void => {
    if (this.element) {
      const customEvent = new CustomEvent(name,
        {
          detail: payload,
          bubbles: bubbles || true,
          cancelable: cancelable || true,
        });
      this.element.dispatchEvent(customEvent);
    }
  }

  // register method will add a new event listener for with your event name and
  // it will add your parameters to the buffer so that you can call it later on the event listener
  // This method need to be called before the dispatch event (emit method)
  register = (name: string): void => {
    this.element.addEventListener(name, (payload) => {
      this.buffer.push({ name, payload });
    });
  }

  on = (name: string, callback: (event?: Event, payload?: EventCallback) => void): void => {
    // This foreach is used to call all the callbacks stored in the buffer for a specific event
    // when we still didn't implement the event listener yet
    this.buffer.forEach((e) => {
      callback(e.payload, (e.payload as CustomEvent).detail);
    });

    this.buffer = [];

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.element.removeEventListener(name, () => {
    });

    // This event listener will be called each time you navigate through your app
    // or your listener gets called (dispatch: emit method)
    if (this.element) {
      this.element.addEventListener(name, (evt): void => {
        callback(evt, (evt as CustomEvent).detail);
      });
    }
  }

  off = (name: string, callback: (event?: Event, payload?: EventCallback) => void): void => {
    if (this.element) {
      this.element.removeEventListener(name, callback);
    }
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function EventsBus(Vue: typeof _Vue): void {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$eventsBus = new EventBus();
}
