import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import DashboardRepository from '@/repositories/DashboardRepository';
import Dashboard from '@/models/Dashboard';
import LoadableStore from '@/store/LoadableStore';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';

interface DashboardState extends LoadableState {
  stats: Dashboard;

}

@Module({ namespaced: true })
export default class DashboardStore extends LoadableStore<DashboardState> {
  stats: Dashboard | null = null;

  private readonly dashboardRepository = new DashboardRepository();

  private retrievedData = [];

  protected get repository(): DashboardRepository {
    return this.dashboardRepository;
  }

  @Action
  loadDashboardStats(filter: { authUser: string; currentTime: number }): Promise<void> {
    this.context.commit('load', true);
    const queryCompose = [
      { alias: 'sessions', fragment: 'sessionForDashboardFragment', query: 'session' },
      { alias: 'upcomingSessions', fragment: 'sessionForDashboardFragment', query: 'session' },
      { alias: 'liveSessions', fragment: 'sessionForDashboardFragment', query: 'session' },
      { alias: 'meetings', fragment: 'meetingForDashboardFragment', query: 'meeting' },
      { alias: 'upcomingMeetings', fragment: 'meetingForDashboardFragment', query: 'meeting' },
      { alias: 'connections', fragment: 'communityUserConnectionForDashboardFragment', query: 'communityUserConnection' },
      { alias: 'pendingConnections', fragment: 'communityUserConnectionForDashboardFragment', query: 'communityUserConnection' },
    ];

    const filterToUse = new Map();
    filterToUse.set('sessions', {
      type: GqlEntityFilterType.SESSION_FILTER,
      value: { linked: { agendaEntry: { user: { uid: filter.authUser } } } },
    });
    filterToUse.set('upcomingSessions', {
      type: GqlEntityFilterType.SESSION_FILTER,
      // eslint-disable-next-line @typescript-eslint/camelcase
      value: { startTimestamp_gt: filter.currentTime, linked: { agendaEntry: { user: { uid: filter.authUser } } } },
    });
    filterToUse.set('liveSessions', {
      type: GqlEntityFilterType.SESSION_FILTER,
      value: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        startTimestamp_gte: filter.currentTime,
        // eslint-disable-next-line @typescript-eslint/camelcase
        startTimestamp_gt: filter.currentTime,
        linked:
        { agendaEntry: { user: { uid: filter.authUser } } },
      },
    });
    filterToUse.set('meetings', {
      type: GqlEntityFilterType.MEETING_FILTER,
      // eslint-disable-next-line @typescript-eslint/camelcase
      value: { isCancelled: false, creatorUser: { uid: filter.authUser } },
    });
    filterToUse.set('upcomingMeetings', {
      type: GqlEntityFilterType.MEETING_FILTER,
      // eslint-disable-next-line @typescript-eslint/camelcase
      value: { isCancelled: false, creatorUser: { uid: filter.authUser }, startTimestamp_gt: filter.currentTime },
    });
    filterToUse.set('connections', {
      type: GqlEntityFilterType.COMMUNITY_USER_CONNECTION_FILTER,
      // eslint-disable-next-line @typescript-eslint/camelcase
      value: {
        linkState: 'ACCEPTED',
        deleted: false,
        OR: [
          {
            user: { uid: filter.authUser },
          },
          {
            connectedUser: { uid: filter.authUser },
          },
        ],
      },
    });
    filterToUse.set('pendingConnections', {
      type: GqlEntityFilterType.COMMUNITY_USER_CONNECTION_FILTER,
      // eslint-disable-next-line @typescript-eslint/camelcase
      value: {
        linkState: 'INVITED',
        deleted: false,
        OR: [
          {
            user: { uid: filter.authUser },
          },
          {
            connectedUser: { uid: filter.authUser },
          },
        ],
      },
    });

    return this.repository.filter({
      definitions: queryCompose.map((queryData) => ({
        operation: queryData.query,
        gqlDefinition: buildQueryDefinition({
          filter: filterToUse.get(queryData.alias),
        }),
        fragmentName: queryData.fragment,
        alias: queryData.alias,
      })),
    }).then((response: Record<string, Array<{ _count: number }>>) => {
      this.context.commit('proceedDataAndSetStats', response);
      this.context.commit('load', false);
    });
  }

  @Mutation
  proceedDataAndSetStats(data: Record<string, Array<{ _count: number }>>): void {
    this.retrievedData = []; // Used just to call this function in this store.
    if (data) {
      const stats = data as unknown as Record<string, Array<{ _count: number }>>;
      const obj = new Dashboard();
      Object.keys(stats).forEach((k) => {
        if (stats[k].length > 0) {
          /* eslint-disable no-underscore-dangle */
          switch (k) {
            case 'sessions':
              Object.assign(obj.sessions, { total: stats[k][0]._count });
              break;
            case 'upcomingSessions':
              Object.assign(obj.sessions.upcoming, { value: stats[k][0]._count });
              break;
            case 'liveSessions':
              Object.assign(obj.sessions.live, { value: stats[k][0]._count });
              break;
            case 'meetings':
              Object.assign(obj.meetings, { total: stats[k][0]._count });
              break;
            case 'upcomingMeetings':
              Object.assign(obj.meetings.upcoming, { value: stats[k][0]._count });
              break;
            case 'connections':
              Object.assign(obj.connections, { total: stats[k][0]._count });
              break;
            case 'pendingConnections':
              Object.assign(obj.connections.pending, { value: stats[k][0]._count });
              break;
            default:
              break;
          }
        }
      });
      this.stats = Dashboard.hydrate(obj) as unknown as Dashboard;
    }
  }
}
