import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CommunityUser from '@/models/graphql/CommunityUser';
import FeedPost from '@/models/graphql/FeedPost';
import Comment from '@/models/graphql/Comment';
import ToastActionType from '@/utils/enums/ToastActionType';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import CommentRepository from '@/repositories/CommentRepository';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { CommentFilter } from '@/graphql/_Filters/CommentFilter';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';

interface CommentState extends LoadableState {
  comments: Comment[];
}

@Module({ namespaced: true })
export default class CommentStore extends LoadableStore<CommentState> {
  comments: Comment[] = [];

  private readonly commentRepository = new CommentRepository();

  protected get repository(): CommentRepository {
    return this.commentRepository;
  }

  @Action
  getPaginatedComments(payload: {
    filter: CommentFilter;
    authUser: string;
    first: number;
    offset: number;
  }): Promise<Comment[] | null> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter as object | null | undefined,
          type: GqlEntityFilterType.COMMENT_FILTER,
        },
        orderBy: {
          value: ['createdTimestamp_asc'],
          type: GqlEntityOrderingType.COMMENT_ORDERING,
        },
        first: payload.first,
        offset: payload.offset,
      }),
      authUser: payload.authUser as string,
    }).then((response) => {
      this.context.commit('load', false);
      return response;
    }).catch(() => new Promise((resolve) => resolve(null)));
  }

  @Action
  getAllComments(payload: {
    filter: CommentFilter;
    authUser: string;
  }): Promise<Comment[] | null> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter as object | null | undefined,
          type: GqlEntityFilterType.COMMENT_FILTER,
        },
        orderBy: {
          value: ['createdTimestamp_asc'],
          type: GqlEntityOrderingType.COMMENT_ORDERING,
        },
      }),
      authUser: payload.authUser as string,
    }).then((response) => {
      this.context.commit('load', false);
      return response;
    }).catch(() => new Promise((resolve) => resolve(null)));
  }

  @Action
  count(payload: { filter: CommentFilter }): Promise<void | number | undefined> {
    this.context.commit('load', true);
    return this.repository.count({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.COMMENT_FILTER,
        },
      }),
    }).then((response) => {
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  createFeedPostComment({
    feedPost, authUser, parentComment, entity,
  }: {
    feedPost: FeedPost;
    authUser: CommunityUser;
    parentComment: Comment | null;
    entity: Partial<Comment>;
  }): Promise<Comment | undefined> {
    let parentUid: string | null = null;
    if (parentComment) {
      if (parentComment.parentComment && parentComment.parentComment.parentComment) {
        parentUid = parentComment.parentComment.uid;
      } else {
        parentUid = parentComment.uid;
      }
    }
    if (parentUid) {
      return this.repository.createFeedPostCommentWithParent({
        definition: buildMutationDefinition([
          {
            fieldName: 'author_CommunityUserUid',
            type: GqlEntityInputType.ID,
            value: authUser.uid,
          },
          {
            fieldName: 'parentComment_CommentUid',
            type: GqlEntityInputType.ID,
            value: parentUid,
          },
          {
            fieldName: 'post_FeedPostUid',
            type: GqlEntityInputType.ID,
            value: feedPost.uid,
          },
          {
            fieldName: 'entity',
            type: GqlEntityInputType.COMMENT_INPUT,
            value: entity,
          },
        ]),
      });
    }
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'author_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: authUser.uid,
        },
        {
          fieldName: 'post_FeedPostUid',
          type: GqlEntityInputType.ID,
          value: feedPost.uid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMENT_INPUT,
          value: entity,
        },
      ]),
    });
  }

  @Action
  createMeetingComment({
    meetingUid, authUser, entity,
  }: {
    meetingUid: string;
    authUser: CommunityUser;
    entity: Partial<Comment>;
  }): Promise<Comment | undefined> {
    return this.repository.createMeetingComment({
      definition: buildMutationDefinition([
        {
          fieldName: 'author_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: authUser.uid,
        },
        {
          fieldName: 'meetingRequest_MeetingRequestUid',
          type: GqlEntityInputType.ID,
          value: meetingUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMENT_INPUT,
          value: entity,
        },
      ]),
    });
  }

  @Action
  updateComment(entity: Partial<Comment>): Promise<Comment | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMENT_INPUT,
          value: entity,
        },
      ]),
    });
  }

  @Action
  deleteComment(uid: string): Promise<boolean> {
    return this.repository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: uid,
        },
      ]),
    }).then((response: Comment | undefined) => {
      if (response) {
        this.context.dispatch('ToastStore/addNewAction', {
          type: ToastActionType.COMMENT_DELETE_SUCCESS,
          delay: 5000,
        }, { root: true });
      } else {
        this.context.dispatch('ToastStore/addNewAction', {
          type: ToastActionType.COMMENT_DELETE_FAIL,
          delay: 5000,
        }, { root: true });
      }
      return response;
    }).catch((error) => {
      this.context.dispatch('ToastStore/addNewAction', {
        type: ToastActionType.COMMENT_DELETE_FAIL,
        delay: 5000,
      }, { root: true });
      return error;
    });
  }

  @Action
  likeComment({
    commentUid,
    authUserId,
  }: {
    commentUid: string;
    authUserId: string;
  }): Promise<boolean> {
    return this.repository.likeComment({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: commentUid,
        },
        {
          fieldName: 'likeUser_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: authUserId,
        },
      ]),
    }).then(() => Promise.resolve(true));
  }

  @Action
  unlikeComment({
    commentUid,
    authUserId,
  }: {
    commentUid: string;
    authUserId: string;
  }): Promise<boolean> {
    return this.repository.unlikeComment({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: commentUid,
        },
        {
          fieldName: 'likeUser_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: authUserId,
        },
      ]),
    }).then(() => Promise.resolve(true))
      .catch(() => Promise.resolve(false));
  }
}
