import { ApolloQueryResult, FetchResult } from '@apollo/client';
import LargeProduct from '@/models/graphql/LargeProduct';
import LargeProductCopyResponseParams from '@/utils/types/LargeProductCopyResponseParams';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import {
  buildComposeMutationGql,
  buildMutationGql,
  buildQueryGql,
} from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import ReadInterface from '@/repositories/base/ReadInterface';
import RestRepository from '@/repositories/base/RestRepository';

/* eslint-disable max-len */
export default class LargeProductRepository extends GraphQlRepository<LargeProduct>
  implements ReadInterface<LargeProduct>, UpdateInterface<LargeProduct>, DeleteInterface<LargeProduct> {
  get(params: GqlPayloadParams): Promise<LargeProduct | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetLargeProduct',
      operation: 'largeProduct',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'largeProductBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ largeProduct: Array<LargeProduct> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ largeProduct: Array<LargeProduct> }>) => response.data.largeProduct[0]));
  }

  filter(params: GqlPayloadParams): Promise<LargeProduct[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterLargeProducts',
      operation: 'largeProduct',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'largeProductBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ largeProduct: Array<LargeProduct> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ largeProduct: Array<LargeProduct> }>) => response.data.largeProduct));
  }

  createLargeProductForExhibitor(params: GqlPayloadParams): Promise<LargeProduct | undefined> {
    return buildMutationGql({
      operationName: 'CreateLargeProductForExhibitor',
      operation: 'LargeProductCreateForExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'largeProductBaseFragment',
    }).then((mutation) => this.mutate<{ LargeProductCreateForExhibitor: LargeProduct }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ LargeProductCreateForExhibitor: LargeProduct }>) => response.data?.LargeProductCreateForExhibitor));
  }

  update(params: GqlPayloadParams): Promise<LargeProduct | undefined> {
    return buildMutationGql({
      operationName: 'UpdateLargeProduct',
      operation: 'LargeProductUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'largeProductBaseFragment',
    }).then((mutation) => this.mutate<{ LargeProductUpdate: LargeProduct }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ LargeProductUpdate: LargeProduct }>) => response.data?.LargeProductUpdate));
  }

  updateByLocale(params: GqlPayloadParams, locale: string): Promise<LargeProduct | undefined> {
    return buildMutationGql({
      operationName: 'LargeProductUpdateByLocale',
      operation: 'LargeProductUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'largeProductBaseFragment',
    }).then((mutation) => this.mutate<{ LargeProductUpdate: LargeProduct }, {}>(mutation, {
      ...params.definition.variables,
      _locale: locale,
    }).then((response: FetchResult<{ LargeProductUpdate: LargeProduct }>) => response.data?.LargeProductUpdate));
  }

  delete(params: GqlPayloadParams): Promise<LargeProduct | undefined> {
    return buildMutationGql({
      operationName: 'DeleteLargeProduct',
      operation: 'LargeProductDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'largeProductBaseFragment',
    }).then((mutation) => this.mutate<{ LargeProductDelete: LargeProduct }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ LargeProductDelete: LargeProduct }>) => response.data?.LargeProductDelete));
  }

  setLargeProductBooth(params: GqlPayloadParams): Promise<boolean | undefined> {
    return buildMutationGql({
      operationName: 'SetLargeProductBooth',
      operation: 'setLargeProductBooth',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: '',
    }).then((mutation) => this.mutate<{ setLargeProductBooth: boolean }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ setLargeProductBooth: boolean }>) => response.data?.setLargeProductBooth));
  }

  unsetLargeProductBooth(params: GqlPayloadParams): Promise<boolean | undefined> {
    return buildMutationGql({
      operationName: 'UnsetLargeProductBooth',
      operation: 'unsetLargeProductBooth',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: '',
    }).then((mutation) => this.mutate<{ unsetLargeProductBooth: boolean }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ unsetLargeProductBooth: boolean }>) => response.data?.unsetLargeProductBooth));
  }

  addCategoriesToLargeProduct(params: GqlComposeMutationPayloadParams): Promise<Record<string, LargeProduct>[]> {
    return buildComposeMutationGql({
      operationName: 'AddCategoriesToLargeProduct',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, LargeProduct>[], {}>(mutations))
      .then((response: FetchResult<Record<string, LargeProduct>[]>) => response.data as Record<string, LargeProduct>[]);
  }

  removeCategoriesFromLargeProduct(params: GqlComposeMutationPayloadParams): Promise<Record<string, LargeProduct>[]> {
    return buildComposeMutationGql({
      operationName: 'removeCategoriesFromLargeProduct',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, LargeProduct>[], {}>(mutations))
      .then((response: FetchResult<Record<string, LargeProduct>[]>) => response.data as Record<string, LargeProduct>[]);
  }

  copyLargeProduct(params: {
    largeProductUid: string;
    exhibitorUid: string;
    communityCode: string;
  }): Promise<LargeProductCopyResponseParams> {
    const restRepository = new RestRepository(
      `${process.env.VUE_APP_GRAPHQL_LARGE_PRODUCT_COPY}`,
      {
        'Content-type': 'application/x-www-form-urlencoded',
        'jwt-token': this.authToken,
      },
    );

    const formData = new URLSearchParams();
    formData.append('community', params.communityCode);
    formData.append('exhibitorUid', params.exhibitorUid);
    formData.append('largeProductUid', params.largeProductUid);

    return restRepository.post({
      data: formData.toString(),
    });
  }
}
