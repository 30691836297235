import { ApolloQueryResult, FetchResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import Poll from '@/models/graphql/Poll';
import WriteInterface from '@/repositories/base/WriteInterface';
import CountInterface from '@/repositories/base/CountInterface';
import ReadInterface from '@/repositories/base/ReadInterface';

/* eslint-disable @typescript-eslint/camelcase,max-len */
export default class PollRepository extends GraphQlRepository<Poll>
  implements ReadInterface<Poll>, WriteInterface<Poll>, CountInterface {
  filter(params: GqlPayloadParams): Promise<Poll[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterPolls',
      operation: 'poll',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'pollFullFragment',
      authUser: params.authUser,
    })
      .then((query) => this.use()
        .query<{ poll: Array<Poll> }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{ poll: Array<Poll> }>) => response.data.poll));
  }

  get(params: GqlPayloadParams): Promise<Poll | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetPoll',
      operation: 'poll',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'pollBaseFragment',
    })
      .then((query) => this.query<{ poll: Array<Poll> }, {}>(query, params.definition.variables)
        .then((response: ApolloQueryResult<{ poll: Array<Poll> }>) => response.data.poll[0]));
  }

  create(params: GqlPayloadParams): Promise<Poll | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreatePoll',
      operation: 'PollCreateForSession',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'pollBaseFragment',
    })
      .then((mutation) => this.mutate<{
        PollCreateForSession: Poll;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          PollCreateForSession: Poll;
        }>) => response.data?.PollCreateForSession));
  }

  createPollWithPost(params: GqlPayloadParams): Promise<Poll | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreatePoll',
      operation: 'PollCreateForPost',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'pollBaseFragment',
    })
      .then((mutation) => this.mutate<{
        PollCreateForPost: Poll;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          PollCreateForPost: Poll;
        }>) => response.data?.PollCreateForPost));
  }

  update(params: GqlPayloadParams): Promise<Poll | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdatePoll',
      operation: 'PollUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'pollBaseFragment',
    })
      .then((mutation) => this.mutate<{
        PollUpdate: Poll;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{ PollUpdate: Poll }>) => response.data?.PollUpdate));
  }

  delete(params: GqlPayloadParams): Promise<Poll | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeletePoll',
      operation: 'PollDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'pollBaseFragment',
    })
      .then((mutation) => this.mutate<{
        PollDelete: Poll;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{ PollDelete: Poll }>) => response.data?.PollDelete));
  }

  count(params: GqlPayloadParams): Promise<number | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetPollCount',
      operation: 'poll',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'pollCountFragment',
      authUser: params.authUser,
    })
      .then((query) => this.use()
        .query<{ poll: [{ count: number }] }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{
          poll: [{ count: number }];
        }>) => response.data.poll[0].count));
  }
}
