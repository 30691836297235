import GqlFieldType from '@/graphql/_Tools/GqlFieldType';
import GqlFieldInterface from '@/graphql/_Tools/type/GqlFieldInterface';

export default class GqlOrderByType extends GqlFieldType<string[]> {
  get declaration(): string {
    return `$${this.fieldName}: [${this.type}!]`;
  }

  static init<TYPE = string[]>(
    seed: GqlFieldInterface<TYPE> | null | undefined,
  ): GqlFieldType<TYPE> | null {
    if (!seed) {
      return null;
    }

    const result = new GqlOrderByType(seed as unknown as GqlFieldInterface<string[]>, 'orderBy');
    return result.isValid ? result as unknown as GqlFieldType<TYPE> : null;
  }
}
