












import { Component, Prop, Vue } from 'vue-property-decorator';
import SegmentedElementComponent from '@/components/SegmentedElementComponent.vue';
import SegmentedElementParams from '@/utils/types/SegmentedElementParams';

@Component({
  components: { SegmentedElementComponent },
})
export default class SegmentedControlsComponent extends Vue {
  @Prop({ required: false, default: () => [] })
  private readonly elements!: SegmentedElementParams[];

  @Prop({ required: false, default: false })
  private readonly isFullWidth!: boolean;
}
