import { ApolloQueryResult, FetchResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import MessageGroup from '@/models/graphql/MessageGroup';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GetterInterface from '@/repositories/base/GetterInterface';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import CreateInterface from '@/repositories/base/CreateInterface';

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/camelcase */
export default class MessageGroupRepository extends GraphQlRepository<MessageGroup>
  implements GetterInterface<MessageGroup>, UpdateInterface<MessageGroup>, CreateInterface<MessageGroup> {
  addUser(params: GqlPayloadParams): Promise<boolean | undefined> {
    return buildMutationGql({
      operationName: 'MessageGroupAddUser',
      operation: 'MessageGroupAddUser',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'messageGroupBaseFragment',
    }).then((mutation) => this.mutate<{ MessageGroupAddUser: boolean }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ MessageGroupAddUser: boolean }>) => response.data?.MessageGroupAddUser));
  }

  create(params: GqlPayloadParams): Promise<MessageGroup | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateMessageGroup',
      operation: 'MessageGroupCreateForTarget',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'messageGroupBaseFragment',
    }).then((mutation) => this.mutate<{ MessageGroupCreateForTarget: MessageGroup }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ MessageGroupCreateForTarget: MessageGroup }>) => response.data?.MessageGroupCreateForTarget));
  }

  update(params: GqlPayloadParams): Promise<MessageGroup | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdateMessageGroup',
      operation: 'MessageGroupUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'messageGroupBaseFragment',
    }).then((mutation) => this.mutate<{ MessageGroupUpdate: MessageGroup }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ MessageGroupUpdate: MessageGroup }>) => response.data?.MessageGroupUpdate));
  }

  get(params: GqlPayloadParams): Promise<MessageGroup | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetMessageGroup',
      operation: 'messageGroup',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'messageGroupBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ messageGroup: Array<MessageGroup> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ messageGroup: Array<MessageGroup> }>) => response.data.messageGroup[0]));
  }
}
