import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

/**
 * Any fragmentName used in your buildGql method in the repositories
 * should be added it here with the corresponded import
 * should respect the alphabetic order
 */
export const fragments: Record<string, string> = {
  addressBaseFragment: 'Address/Base',
  appTranslationBaseFragment: 'AppTranslation/Base',
  articleActionButtonAddnoteFragment: 'Article/ActionButtons/AddNote',
  articleActionButtonBookmarkFragment: 'Article/ActionButtons/Bookmark',
  articleActionButtonFollowFragment: 'Article/ActionButtons/Follow',
  articleBaseFragment: 'Article/Base',
  articleCardFragment: 'Article/Card',
  articleCountFragment: 'Article/Count',
  articleEntityListFragment: 'Article/EntityList',
  articleGenericPageHeaderFragment: 'Article/GenericPageHeader',
  articleMagazineFragment: 'Article/MagazineArticle',
  articleWithTagsFragment: 'Article/WithTags',
  authProviderBaseFragment: 'AuthProvider/Base',
  businessCardBaseFragment: 'BusinessCard/Base',
  categoryBaseFragment: 'Category/Base',
  categoryDomainContextBaseFragment: 'CategoryDomainContext/Base',
  categoryWithContextFragment: 'Category/WithContext',
  channelActionButtonAddtoagendaFragment: 'Channel/ActionButtons/AddToAgenda',
  channelActionButtonBookmarkFragment: 'Channel/ActionButtons/Bookmark',
  channelActionButtonFollowFragment: 'Channel/ActionButtons/Follow',
  channelActionButtonVisitFragment: 'Channel/ActionButtons/Visit',
  channelBaseFragment: 'Channel/Base',
  channelGenericPageHeaderFragment: 'Channel/GenericPageHeader',
  commentBaseFragment: 'Comment/Base',
  commentCountFragment: 'Comment/Count',
  commentFullFragment: 'Comment/Full',
  communityCrmUserCardFragment: 'CommunityUser/CrmUserCard',
  communityFeatureFullFragment: 'CommunityFeature/Full',
  communityFullFragment: 'Community/Full',
  communityUserActionButtonAddnoteFragment: 'CommunityUser/ActionButtons/AddNote',
  communityUserActionButtonBlockFragment: 'CommunityUser/ActionButtons/Block',
  communityUserActionButtonConnectFragment: 'CommunityUser/ActionButtons/Connect',
  communityUserActionButtonCreatemeetingFragment: 'CommunityUser/ActionButtons/CreateMeeting',
  communityUserActionButtonFollowFragment: 'CommunityUser/ActionButtons/Follow',
  communityUserActionButtonLinkuserspeakerFragment: 'CommunityUser/ActionButtons/LinkUserSpeaker',
  communityUserActionButtonMessageFragment: 'CommunityUser/ActionButtons/Message',
  communityUserAgendaEntryFullFragment: 'CommunityUserAgendaEntry/Full',
  communityUserAuthUserFragment: 'CommunityUser/AuthUser',
  communityUserAccountStateFragment: 'CommunityUser/AccountState',
  communityUserCreateFragment: 'CommunityUser/Create',
  communityUserBaseFragment: 'CommunityUser/Base',
  communityUserBookmarkBaseFragment: 'CommunityUserBookmark/Base',
  communityUserCardFragment: 'CommunityUser/Card',
  communityUserCompanyUserRoleFragment: 'CommunityUser/CompanyUserRole',
  communityUserConnectionCountFragment: 'CommunityUserConnection/Count',
  communityUserConnectionForDashboardFragment: 'CommunityUserConnection/ForDashboard',
  communityUserConnectionFullFragment: 'CommunityUserConnection/Full',
  communityUserConnectionLookupFragment: 'CommunityUser/ConnectionLookup',
  communityUserCountFragment: 'CommunityUser/Count',
  communityUserEntityListFragment: 'CommunityUser/EntityList',
  communityUserFollowBaseFragment: 'CommunityUserFollow/Base',
  communityUserForAccountFragment: 'CommunityUser/ForAccount',
  communityUserForBanChatStatusFragment: 'CommunityUser/ForBanChatStatus',
  communityUserFullFragment: 'CommunityUser/Full',
  communityUserFeedStateFragment: 'CommunityUser/FeedState',
  communityUserGenericPageHeaderFragment: 'CommunityUser/GenericPageHeader',
  communityUserInterestBaseFragment: 'CommunityUserInterest/Base',
  communityUserMyProfileAboutFormFragment: 'CommunityUser/MyProfileAboutForm',
  communityUserNoteBaseFragment: 'CommunityUserNote/Base',
  communityUserNoteCountFragment: 'CommunityUserNote/Count',
  communityUserNoteFullFragment: 'CommunityUserNote/Full',
  communityUserPaginateFragment: 'CommunityUser/Paginate',
  communityUserPreferencesBaseFragment: 'CommunityUserPreferences/Base',
  communityUserPreferencesFragment: 'CommunityUser/Preferences',
  communityUserPrivacyFragment: 'CommunityUser/Privacy',
  communityUserEpUserDetailFragment: 'CommunityUser/EpUserDetail',
  communityUserEpUserHeaderFragment: 'CommunityUser/EpUserHeader',
  communityUserProfileRoleBaseFragment: 'CommunityUserProfileRole/Base',
  communityUserTagBaseFragment: 'CommunityUserTag/Base',
  communityUserTagCountFragment: 'CommunityUserTag/Count',
  communityUserTagWithoutUserFragment: 'CommunityUserTag/WithoutUser',
  communityUserTagToolboxFragment: 'CommunityUserTag/Toolbox',
  communityUserTagDuplicatedNameFragment: 'CommunityUserTag/DuplicatedName',
  communityUserUidFragment: 'CommunityUser/Uid',
  communityUserUrlBaseFragment: 'CommunityUserUrl/Base',
  communityUserVisitedBaseFragment: 'CommunityUserVisited/Base',
  communityUserWithTagsFragment: 'CommunityUser/WithTags',
  communityUserActionRuleForMeetingRequestFragment: 'CommunityUser/ActionRuleForMeetingRequest',
  companyUserRoleBaseFragment: 'CompanyUserRole/Base',
  companyUserRoleFullFragment: 'CompanyUserRole/Full',
  companyUserRoleForCalendarFragment: 'CompanyUserRole/ForCalendar',
  countryBaseFragment: 'Country/Base',
  dealActionButtonAddnoteFragment: 'Deal/ActionButtons/AddNote',
  dealActionButtonBookmarkFragment: 'Deal/ActionButtons/Bookmark',
  dealActionButtonMessageFragment: 'Deal/ActionButtons/Message',
  dealActionButtonViewonmapFragment: 'Deal/ActionButtons/ViewOnMap',
  dealBaseFragment: 'Deal/Base',
  dealCardFragment: 'Deal/Card',
  dealCountFragment: 'Deal/Count',
  dealEntityInfoFragment: 'Deal/EntityInfo',
  dealEntityInfoLinksFragment: 'Deal/EntityInfoLinks',
  dealEntityListFragment: 'Deal/EntityList',
  dealGenericPageHeaderFragment: 'Deal/GenericPageHeader',
  dealWithTagsFragment: 'Deal/WithTags',
  editionCountdownFragment: 'Edition/Countdown',
  exhibitHallBaseFragment: 'ExhibitHall/Base',
  exhibitorActionButtonAddnoteFragment: 'Exhibitor/ActionButtons/AddNote',
  exhibitorActionButtonBookmarkFragment: 'Exhibitor/ActionButtons/Bookmark',
  exhibitorActionButtonCreatemeetingFragment: 'Exhibitor/ActionButtons/CreateMeeting',
  exhibitorActionButtonFeedbackFragment: 'Exhibitor/ActionButtons/Feedback',
  exhibitorActionButtonFollowFragment: 'Exhibitor/ActionButtons/Follow',
  exhibitorActionButtonMessageFragment: 'Exhibitor/ActionButtons/Message',
  exhibitorActionButtonViewonmapFragment: 'Exhibitor/ActionButtons/ViewOnMap',
  exhibitorActionButtonVisitFragment: 'Exhibitor/ActionButtons/Visit',
  exhibitorBaseFragment: 'Exhibitor/Base',
  exhibitorCardFragment: 'Exhibitor/Card',
  exhibitorCardWithEditionFragment: 'Exhibitor/CardWithEdition',
  exhibitorCompanyEventsFragment: 'Exhibitor/CompanyEvents',
  exhibitorContactBaseFragment: 'ExhibitorContact/Base',
  exhibitorContactFragment: 'Exhibitor/Contact',
  exhibitorContactFullFragment: 'ExhibitorContact/Full',
  exhibitorCountFragment: 'Exhibitor/Count',
  exhibitorEntityListFragment: 'Exhibitor/EntityList',
  exhibitorFullFragment: 'Exhibitor/Full',
  exhibitorGenericPageHeaderFragment: 'Exhibitor/GenericPageHeader',
  exhibitorLogoFragment: 'Exhibitor/Logo',
  exhibitorMapFragment: 'Exhibitor/Map',
  exhibitorUrlBaseFragment: 'ExhibitorUrl/Base',
  exhibitorVideoBaseFragment: 'ExhibitorVideo/Base',
  exhibitorVideoCountFragment: 'ExhibitorVideo/Count',
  exhibitorWithPackageStockFragment: 'Exhibitor/WithPackageStocks',
  exhibitorWithTagsFragment: 'Exhibitor/WithTags',
  exhibitorTagBaseFragment: 'ExhibitorTag/Base',
  extraPropertyBaseFragment: 'ExtraProperty/Base',
  extraPropertyFullFragment: 'ExtraProperty/Full',
  extraPropertySectionFullFragment: 'ExtraPropertySection/Full',
  extraPropertySectionWithoutNmmaFragment: 'ExtraPropertySection/WithoutNmma',
  feedItemWrapperChannelFragment: 'FeedItemWrapper/FeedsOfChannel',
  feedItemWrapperEditionFragment: 'FeedItemWrapper/EditionWall',
  feedItemWrapperEntityFragment: 'FeedItemWrapper/FeedsOfEntity',
  feedItemWrapperFullFragment: 'FeedItemWrapper/Full',
  feedItemWrapperWithReactionsFragment: 'FeedItemWrapper/WithReactions',
  feedItemWrapperTopicFragment: 'FeedItemWrapper/FeedsOfTopic',
  latestFeedItemWrapperChannelFragment: 'FeedItemWrapper/LatestFeedsOfChannel',
  latestFeedItemWrapperEditionFragment: 'FeedItemWrapper/LatestEditionWall',
  latestFeedItemWrapperEntityFragment: 'FeedItemWrapper/LatestFeedsOfEntity',
  latestFeedItemWrapperTopicFragment: 'FeedItemWrapper/LatestFeedsOfTopic',
  latestFeedItemWrapperWallFragment: 'FeedItemWrapper/LatestWall',
  feedItemWrapperUidFragment: 'FeedItemWrapper/Uid',
  feedItemWrapperWallFragment: 'FeedItemWrapper/Wall',
  feedPostBaseFragment: 'FeedPost/Base',
  feedPostCreateFragment: 'FeedPost/Create',
  feedPostFullFragment: 'FeedPost/Full',
  feedPostLinkBaseFragment: 'FeedPostLink/Base',
  feedPostLinkUidFragment: 'FeedPostLink/Uid',
  feedPostMediaBaseFragment: 'FeedPostMedia/Base',
  feedPostMediaUidFragment: 'FeedPostMedia/Uid',
  feedPostReportFragment: 'FeedPostReport/Base',
  feedPostUidFragment: 'FeedPost/Uid',
  feedPostUpdateFragment: 'FeedPost/Update',
  feedStateBaseFragment: 'FeedState/Base',
  fileResourceBaseFragment: 'FileResource/Base',
  geozoneWithExhibitHallFragment: 'Geozone/WithExhibitHall',
  handoutBaseFragment: 'Handout/Base',
  handoutEntityListFragment: 'Handout/EntityList',
  largeProductActionButtonAddnoteFragment: 'LargeProduct/ActionButtons/AddNote',
  largeProductActionButtonBookmarkFragment: 'LargeProduct/ActionButtons/Bookmark',
  largeProductActionButtonMessageFragment: 'LargeProduct/ActionButtons/Message',
  largeProductActionButtonViewonmapFragment: 'LargeProduct/ActionButtons/ViewOnMap',
  largeProductBaseFragment: 'LargeProduct/Base',
  largeProductBoatCardFragment: 'LargeProduct/BoatCard',
  largeProductBoatCardWithExtraPropertiesFragment: 'LargeProduct/BoatCardWithExtraProperties',
  largeProductCardFragment: 'LargeProduct/Card',
  largeProductCountFragment: 'LargeProduct/Count',
  largeProductEntityInfoFragment: 'LargeProduct/EntityInfo',
  largeProductEntityInfoLinksFragment: 'LargeProduct/EntityInfoLinks',
  largeProductEntityInfoExhibitorFragment: 'LargeProduct/EntityInfoExhibitor',
  largeProductEntityInfoLocationFragment: 'LargeProduct/EntityInfoLocation',
  largeProductEntityInfoPriceFragment: 'LargeProduct/EntityInfoPrice',
  largeProductEntityListFragment: 'LargeProduct/EntityList',
  largeProductGenericPageHeaderFragment: 'LargeProduct/GenericPageHeader',
  largeProductImageBaseFragment: 'LargeProductImage/Base',
  largeProductVideoUrlFragment: 'LargeProduct/VideoUrl',
  largeProductWithTagsFragment: 'LargeProduct/WithTags',
  localeFullFragment: 'Locale/Full',
  loginProviderConfigBaseFragment: 'LoginProviderConfig/Base',
  masterDomainBaseFragment: 'MasterDomain/Base',
  meetingCountFragment: 'Meeting/Count',
  meetingForAgendaFragment: 'Meeting/ForAgenda',
  meetingForDashboardFragment: 'Meeting/ForDashboard',
  meetingFullFragment: 'Meeting/Full',
  meetingParticipantBaseFragment: 'MeetingParticipant/Base',
  meetingParticipantCountFragment: 'MeetingParticipant/Count',
  meetingParticipantDeleteFragment: 'MeetingParticipant/Delete',
  meetingRequestBaseFragment: 'MeetingRequest/Base',
  meetingRequestFullFragment: 'MeetingRequest/Full',
  meetingRequestCountFragment: 'MeetingRequest/Count',
  meetingRequestDashboardFragment: 'MeetingRequest/Dashboard',
  messageAllFragment: 'Message/All',
  messageBaseFragment: 'Message/Base',
  messageCountFragment: 'Message/Count',
  messageFullFragment: 'Message/Full',
  messageGroupBaseFragment: 'MessageGroup/Base',
  messageGroupForTargetFragment: 'MessageGroup/ForTarget',
  messageGroupFullFragment: 'MessageGroup/Full',
  messageGroupLastMessageByGroupFragment: 'MessageGroup/LastMessageByGroup',
  messageGroupCountFragment: 'MessageGroup/Count',
  messageGroupStateUpdateFragment: 'MessageGroupState/Update',
  messagePaginatedFragment: 'Message/Paginated',
  messageUidFragment: 'Message/Uid',
  notificationDeleteFragment: 'Notification/Delete',
  notificationFullFragment: 'Notification/Full',
  notificationCountFragment: 'Notification/Count',
  notificationUpdateFragment: 'Notification/Update',
  orderBaseFragment: 'Order/Base',
  orderCountFragment: 'Order/Count',
  orderForSalesComponents: 'Order/ForSalesComponents',
  orderFullFragment: 'Order/Full',
  orderPackageBaseFragment: 'OrderPackage/Base',
  pollAnswerBaseFragment: 'PollAnswer/Base',
  pollBaseFragment: 'Poll/Base',
  pollCountFragment: 'Poll/Count',
  pollFullFragment: 'Poll/Full',
  pollUserAnswerBaseFragment: 'PollUserAnswer/Base',
  productActionButtonAddnoteFragment: 'Product/ActionButtons/AddNote',
  productActionButtonBookmarkFragment: 'Product/ActionButtons/Bookmark',
  productActionButtonFeedbackFragment: 'Product/ActionButtons/Feedback',
  productActionButtonMessageFragment: 'Product/ActionButtons/Message',
  productActionButtonViewonmapFragment: 'Product/ActionButtons/ViewOnMap',
  productBaseFragment: 'Product/Base',
  productCardFragment: 'Product/Card',
  productCountFragment: 'Product/Count',
  productEntityInfoFragment: 'Product/EntityInfo',
  productEntityInfoLinksFragment: 'Product/EntityInfoLinks',
  productEntityListFragment: 'Product/EntityList',
  productGenericPageHeaderFragment: 'Product/GenericPageHeader',
  productImageBaseFragment: 'ProductImage/Base',
  productVideoUrlFragment: 'Product/VideoUrl',
  productWithTagsFragment: 'Product/WithTags',
  profileRoleFullFragment: 'ProfileRole/Full',
  roleFullFragment: 'Role/Full',
  salesComponentBaseFragment: 'SalesComponent/Base',
  salesPackageBaseFragment: 'SalesPackage/Base',
  salesPackageBaseWithComponentFragment: 'SalesPackage/BaseWithComponent',
  salesPackageCardFragment: 'SalesPackage/Card',
  salesPackageComponentConfigBaseFragment: 'SalesPackageComponentConfig/Base',
  salesPackageComponentWithConfigFragment: 'SalesPackageComponent/WithConfig',
  salesPackageCountFragment: 'SalesPackage/Count',
  salesPackageSearchResultFragment: 'SalesPackage/SearchResult',
  scheduleEventFullFragment: 'ScheduleEvent/Full',
  scheduleEventStartDateFragment: 'ScheduleEvent/StartDate',
  sessionActionButtonAddnoteFragment: 'Session/ActionButtons/AddNote',
  sessionActionButtonAddtoagendaFragment: 'Session/ActionButtons/AddToAgenda',
  sessionActionButtonBookmarkFragment: 'Session/ActionButtons/Bookmark',
  sessionActionButtonFeedbackFragment: 'Session/ActionButtons/Feedback',
  sessionActionButtonMessageFragment: 'Session/ActionButtons/Message',
  sessionActionButtonViewonmapFragment: 'Session/ActionButtons/ViewOnMap',
  sessionActionButtonMessageGroupFragment: 'Session/ActionButtons/MessageGroup',
  sessionBannerFileResourceFragment: 'Session/BannerFileResource',
  sessionBaseFragment: 'Session/Base',
  sessionCardFragment: 'Session/Card',
  sessionMapFragment: 'Session/Map',
  sessionScheduleEventFragment: 'Session/ScheduleEvent',
  sessionChatFragment: 'Session/Chat',
  sessionCountFragment: 'Session/Count',
  sessionStartDateFragment: 'Session/StartDate',
  sessionCountdownFragment: 'Session/Countdown',
  sessionDescriptionFragment: 'Session/Description',
  sessionEntityInfoFragment: 'Session/EntityInfo',
  sessionEntityListFragment: 'Session/EntityList',
  sessionForAgendaFragment: 'Session/ForAgenda',
  sessionForCmsPageHeaderFragment: 'Session/ForCmsPageHeader',
  sessionForDashboardFragment: 'Session/ForDashboard',
  sessionForLiveVideoCardFragment: 'Session/ForLiveVideoCard',
  sessionForLiveVideoFragment: 'Session/ForLiveVideo',
  sessionForPollAndQnAFragment: 'Session/ForPollAndQnA',
  sessionFullFragment: 'Session/Full',
  sessionScheduleOfEventFragment: 'Session/ScheduleOfEvent',
  sessionGenericPageHeaderFragment: 'Session/GenericPageHeader',
  sessionHandoutsFileResourceFragment: 'Session/HandoutsFileResource',
  sessionLiveStreamConfigFragment: 'Session/LiveStreamConfig',
  sessionRatingFullFragment: 'SessionRating/Full',
  sessionSpeakersNameFragment: 'Session/SpeakersName',
  sessionTimelineFragment: 'Session/Timeline',
  sessionUserRoleBaseFragment: 'SessionUserRole/Base',
  sessionWithTagsFragment: 'Session/WithTags',
  slotBaseFragment: 'Slot/Base',
  slotProductMessageFragment: 'Slot/ActionButtonExhibitorSlots/ProductMessage',
  slotLargeProductMessageFragment: 'Slot/ActionButtonExhibitorSlots/LargeProductMessage',
  slotDealMessageFragment: 'Slot/ActionButtonExhibitorSlots/DealMessage',
  slotExhibitorMessageFragment: 'Slot/ActionButtonExhibitorSlots/ExhibitorMessage',
  slotExhibitorShareContactFragment: 'Slot/ActionButtonExhibitorSlots/ExhibitorShareContact',
  slotExhibitorCreateMeetingFragment: 'Slot/ActionButtonExhibitorSlots/ExhibitorMeet',
  speakerActionButtonAddnoteFragment: 'Speaker/ActionButtons/AddNote',
  speakerActionButtonBookmarkFragment: 'Speaker/ActionButtons/Bookmark',
  speakerActionButtonFeedbackFragment: 'Speaker/ActionButtons/Feedback',
  speakerActionButtonLinkuserspeakerFragment: 'Speaker/ActionButtons/LinkUserSpeaker',
  speakerActionButtonMessageFragment: 'Speaker/ActionButtons/Message',
  speakerBaseFragment: 'Speaker/Base',
  speakerCardFragment: 'Speaker/Card',
  speakerCountFragment: 'Speaker/Count',
  speakerEntityListFragment: 'Speaker/EntityList',
  speakerFullFragment: 'Speaker/Full',
  speakerGenericPageHeaderFragment: 'Speaker/GenericPageHeader',
  speakerSocialLinksFragment: 'Speaker/SocialLinks',
  speakerHandoutsFragment: 'Speaker/Handouts',
  speakerAuthUserFragment: 'Speaker/AuthUser',
  speakerBasicInfoFragment: 'Speaker/BasicInfo',
  sponsorEntityListFragment: 'Sponsor/EntityList',
  sponsorRoleEntityListFragment: 'SponsorRole/EntityList',
  subEditionActionButtonBookmarkFragment: 'SubEdition/ActionButtons/Bookmark',
  subEditionActionButtonFollowFragment: 'SubEdition/ActionButtons/Follow',
  subEditionAuthUserFragment: 'SubEdition/AuthUser',
  subEditionBaseFragment: 'SubEdition/Base',
  subEditionCardFragment: 'SubEdition/Card',
  subEditionCountFragment: 'SubEdition/Count',
  subEditionCountdownFragment: 'SubEdition/Countdown',
  subEditionEntityListFragment: 'SubEdition/EntityList',
  subEditionGenericPageHeaderFragment: 'SubEdition/GenericPageHeader',
  subEditionWithTagsFragment: 'SubEdition/WithTags',
  subscriptionChatEventsFragment: 'Subscription/Chat/Events',
  subscriptionChatEventsTypingFragment: 'Subscription/Chat/EventsTyping',
  subscriptionNotificationEventsFragment: 'Subscription/Notification/Events',
  subscriptionNotificationGenericEventsFragment: 'Subscription/Notification/GenericEvents',
  surveyBaseFragment: 'Survey/Base',
  surveyQuestionBaseFragment: 'SurveyQuestion/Base',
  surveyQuestionChoiceBaseFragment: 'SurveyQuestionChoice/Base',
  surveyQuestionFullWithoutSurveyFragment: 'SurveyQuestion/FullWithoutSurvey',
  surveyUserAnswerBaseFragment: 'SurveyUserAnswer/Base',
  surveyUserAnswerWithSurveyFragment: 'SurveyUserAnswer/WithSurvey',
  surveyWithQuestionsFragment: 'Survey/WithQuestions',
  surveyWithSessionFragment: 'Survey/WithSession',
  topicBaseFragment: 'Topic/Base',
  topicCardFragment: 'Topic/Card',
  topicCountFragment: 'Topic/Count',
  topicEntityListFragment: 'Topic/EntityList',
  channelEntityListFragment: 'Channel/EntityList',
  topicGenericPageHeaderFragment: 'Topic/GenericPageHeader',
  uiMenuBaseFragment: 'UiMenu/Base',
  uiOnboardingBaseFragment: 'UiOnboarding/Base',
  uiOnboardingFullFragment: 'UiOnboarding/Full',
  uiOnboardingWidgetBaseFragment: 'UiOnboardingWidget/Base',
  uiOnboardingWidgetFullFragment: 'UiOnboardingWidget/Full',
  uiPageFullFragment: 'UiPage/Full',
  uiPageWidgetBaseFragment: 'UiPageWidget/Base',
};

/**
 * importFragment gets the fragmentName as argument and return a promise string
 * after mapping the name with right fragment file
 * @param fragmentName
 */
export const importFragment = (fragmentName: string): Promise<string> => {
  const moduleName = fragmentName
    .replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`)
    .toUpperCase();
  return import(`@/graphql/_Fragments/${fragments[fragmentName]}`)
    .then((modules) => {
      if (!(moduleName in modules)) {
        // eslint-disable-next-line no-console
        console.error(
          `Failed to load fragment "${fragmentName}" \n`,
          `Wrong module name: The module name should be "${moduleName}".`,
        );
        return '';
      }
      return modules[moduleName];
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(`Missing fragment "${fragmentName}"`);
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Error);
        Sentry.captureException(err);
      });
      return '';
    });
};
