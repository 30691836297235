

























import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Prop } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class DropdownMenuItem extends VueBaseWidget {
  @Prop({ required: false })
  private icon!: string;

  @Prop({ required: true })
  private text!: string;

  @Prop({ required: false, default: false })
  private isWarningColor!: boolean;

  @Prop({ required: false, default: false })
  private disabled!: boolean;
}
