import AccountStore from '@/store/AccountStore';
import AddressStore from '@/store/AddressStore';
import AgendaStore from '@/store/AgendaStore';
import AuthenticationStore from '@/store/AuthenticationStore';
import BusinessCardStore from '@/store/BusinessCardStore';
import AuthProviderStore from '@/store/AuthProviderStore';
import CategoryDomainContextStore from '@/store/CategoryDomainContextStore';
import CategoryStore from '@/store/CategoryStore';
import ChatDispatcherStore from '@/store/chat/ChatDispatcherStore';
import CommentStore from '@/store/CommentStore';
import CommunityUserAgendaEntryStore from '@/store/CommunityUserAgendaEntryStore';
import CommunityUserBookmarkStore from '@/store/CommunityUserBookmarkStore';
import CommunityUserConnectionStore from '@/store/CommunityUserConnectionStore';
import CommunityUserFollowStore from '@/store/CommunityUserFollowStore';
import CommunityUserInterestStore from '@/store/CommunityUserInterestStore';
import CommunityUserPreferencesStore from '@/store/CommunityUserPreferencesStore';
import CommunityUserProfileRoleStore from '@/store/CommunityUserProfileRoleStore';
import CommunityUserStore from '@/store/CommunityUserStore';
import CommunityUserAddressStore from '@/store/CommunityUserAddressStore';
import CommunityUserChatStore from '@/store/CommunityUserChatStore';
import CommunityUserCountryStore from '@/store/CommunityUserCountryStore';
import CommunityUserTagStore from '@/store/CommunityUserTagStore';
import CommunityUserUrlStore from '@/store/CommunityUserUrlStore';
import CommunityUserVisitedStore from '@/store/CommunityUserVisitedStore';
import CompanyCalendarStore from '@/store/CompanyCalendarStore';
import CompanyProfileTabStore from '@/store/CompanyProfileTabStore';
import CompanyUserRoleStore from '@/store/CompanyUserRoleStore';
import ConfigurationStore from '@/store/ConfigurationStore';
import CountryStore from '@/store/CountryStore';
import DashboardStore from '@/store/DashboardStore';
import DealStore from '@/store/DealStore';
import EntitySearchStore from '@/store/EntitySearchStore';
import ExhibitorContactStore from '@/store/ExhibitorContactStore';
import ExhibitorStore from '@/store/ExhibitorStore';
import ExhibitorUrlStore from '@/store/ExhibitorUrlStore';
import ExtraPropertySectionStore from '@/store/ExtraPropertySectionStore';
import ExtraPropertyStore from '@/store/ExtraPropertyStore';
import FeedItemWrapperStore from '@/store/FeedItemWrapperStore';
import FileResourceStore from '@/store/FileResourceStore';
import HandoutStore from '@/store/HandoutStore';
import LargeProductImageStore from '@/store/LargeProductImageStore';
import LargeProductStore from '@/store/LargeProductStore';
import LocalStorageCartStore from '@/store/LocalStorageCartStore';
import LoginProviderConfigStore from '@/store/LoginProviderConfigStore';
import MessageStore from '@/store/MessageStore';
import MessageGroupStore from '@/store/MessageGroupStore';
import OrderStore from '@/store/OrderStore';
import CommunityUserNoteStore from '@/store/CommunityUserNoteStore';
import NotificationStore from '@/store/notification/NotificationStore';
import PollAnswerStore from '@/store/PollAnswerStore';
import PollUserAnswerStore from '@/store/PollUserAnswerStore';
import PollStore from '@/store/PollStore';
import ProductImageStore from '@/store/ProductImageStore';
import ProductStore from '@/store/ProductStore';
import RootState from '@/store/states/RootState';
import SalesPackageStore from '@/store/SalesPackageStore';
import SessionBrowserCalendarStore from '@/store/session/SessionBrowserCalendarStore';
import SessionRatingStore from '@/store/SessionRatingStore';
import SessionStore from '@/store/session/SessionStore';
import SignInStore from '@/store/SignInStore';
import SpeakerStore from '@/store/SpeakerStore';
import SubEditionStore from '@/store/SubEditionStore';
import SurveyQuestionStore from '@/store/SurveyQuestionStore';
import SurveyStore from '@/store/SurveyStore';
import SurveyUserAnswerStore from '@/store/SurveyUserAnswerStore';
import ToastStore from '@/store/ToastStore';
import UiOnboardingStore from '@/store/UiOnboardingStore';
import WidgetPaginatedStore from '@/store/WidgetPaginatedStore';
import Vue from 'vue';
import Vuex from 'vuex';
import vuexRootOptions from '@/store/options/RootStoreOptions';
import FeedPostStore from '@/store/FeedPostStore';
import FeedStateStore from '@/store/FeedStateStore';
import WidgetDispatcherStore from '@/store/widget/WidgetDispatcherStore';
import PageStateManagementStore from '@/store/widget/PageStateManagementStore';
import MeetingRequestStore from '@/store/MeetingRequestStore';
import ExhibitorVideoStore from '@/store/ExhibitorVideoStore';
import SalesPackageLimitStore from '@/store/SalesPackageLimitStore';
import LocalStorageNotificationBoxStore from '@/store/LocalStorageNotificationBoxStore';
import NodeStore from '@/store/NodeStore';
import PermissionManagerStore from '@/store/PermissionManagerStore';
import FullCalendarStore from '@/store/FullCalendarStore';
import ExhibitorTagStore from '@/store/ExhibitorTagStore';
import OrderPackageStore from '@/store/OrderPackageStore';

Vue.use(Vuex);

vuexRootOptions.modules = {
  AccountStore,
  AddressStore,
  AgendaStore,
  AuthProviderStore,
  AuthenticationStore,
  BusinessCardStore,
  CategoryDomainContextStore,
  CategoryStore,
  ChatDispatcherStore,
  CommentStore,
  CommunityUserAddressStore,
  CommunityUserAgendaEntryStore,
  CommunityUserBookmarkStore,
  CommunityUserChatStore,
  CommunityUserConnectionStore,
  CommunityUserCountryStore,
  CommunityUserFollowStore,
  CommunityUserInterestStore,
  CommunityUserNoteStore,
  CommunityUserPreferencesStore,
  CommunityUserProfileRoleStore,
  CommunityUserStore,
  CommunityUserTagStore,
  CommunityUserUrlStore,
  CommunityUserVisitedStore,
  CompanyCalendarStore,
  CompanyProfileTabStore,
  CompanyUserRoleStore,
  CountryStore,
  DashboardStore,
  DealStore,
  EntitySearchStore,
  ExhibitorContactStore,
  ExhibitorStore,
  ExhibitorUrlStore,
  ExhibitorVideoStore,
  ExtraPropertySectionStore,
  ExtraPropertyStore,
  FeedItemWrapperStore,
  FeedPostStore,
  FeedStateStore,
  FileResourceStore,
  HandoutStore,
  LargeProductImageStore,
  LargeProductStore,
  LocalStorageCartStore,
  LocalStorageNotificationBoxStore,
  LoginProviderConfigStore,
  MeetingRequestStore,
  MessageGroupStore,
  MessageStore,
  NodeStore,
  NotificationStore,
  OrderStore,
  OrderPackageStore,
  PageStateManagementStore,
  PollAnswerStore,
  PollStore,
  PollUserAnswerStore,
  ProductImageStore,
  ProductStore,
  SalesPackageLimitStore,
  SalesPackageStore,
  SessionBrowserCalendarStore,
  SessionRatingStore,
  SessionStore,
  SignInStore,
  SpeakerStore,
  SubEditionStore,
  SurveyQuestionStore,
  SurveyStore,
  SurveyUserAnswerStore,
  ToastStore,
  UiOnboardingStore,
  WidgetDispatcherStore,
  WidgetPaginatedStore,
  PermissionManagerStore,
  configuration: ConfigurationStore,
  FullCalendarStore,
  ExhibitorTagStore,
};

export default new Vuex.Store<RootState>(vuexRootOptions);
