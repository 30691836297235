import {
  add,
  addMonths,
  endOfDay,
  endOfMonth,
  getUnixTime,
  nextSunday,
  startOfDay,
  startOfMonth,
  sub,
} from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { Data } from '@/utils/types/WidgetData';
import EntityFilterList from '@/utils/types/entity-search/EntityFilterList';
import EntityFilterToggle from '@/utils/types/entity-search/EntityFilterToggle';
import { EntitySearchFilterType } from '@/utils/enums/EntitySearchFilterType';
import EntityFilterRange from '@/utils/types/entity-search/EntityFilterRange';
/* eslint-disable @typescript-eslint/camelcase */
export default class EntitySearchHelper {
  static constructListFilters(
    listConfigs: EntityFilterList[],
    filters: Data,
    args: Data,
  ): object[] {
    const and: Record<string, string | object>[] = [];
    Object.keys(filters).forEach((k) => {
      const conf = listConfigs.find((lc) => lc.code === k);
      if (conf && 'filterQuery' in conf && conf.filterQuery) {
        const obj = conf.filterQuery
          .replaceAll(/"*%authUser%"*/g, `"${args.authUser || ''}"`)
          .replaceAll(/"*%community%"*/g, `"${args.schemaCode || ''}"`)
          .replaceAll(/"*%locale%"*/g, `"${args.locale || ''}"`)
          .replaceAll(/"*%now%"*/g, `${DateTimeHelper.currentTimestamp}`)
          .replaceAll(new RegExp(`"*%${k}%"*`, 'g'), JSON.stringify((filters as Record<string, []>)[k]));
        and.push(JSON.parse(obj || '{}'));
      }
    });
    return and;
  }

  static constructToggleFilters(
    toggleConfigs: EntityFilterToggle[],
    toggles: Data,
    args: Data,
  ): object[] {
    const and: Record<string, string | object>[] = [];
    Object.keys(toggles).forEach((k) => {
      const conf = toggleConfigs.find((tc) => tc.code === k);
      if (conf && 'filterQuery' in conf && conf.filterQuery) {
        const obj = conf.filterQuery
          .replaceAll(/"*%authUser%"*/g, `"${args.authUser || ''}"`)
          .replaceAll(/"*%community%"*/g, `"${args.schemaCode || ''}"`)
          .replaceAll(/"*%locale%"*/g, `"${args.locale || ''}"`)
          .replaceAll(/"*%now%"*/g, `${DateTimeHelper.currentTimestamp}`)
          .replaceAll(new RegExp(`"*%${k}%"*`, 'g'), JSON.stringify((toggles as Record<string, []>)[k]));
        and.push(JSON.parse(obj || '{}'));
      }
    });
    return and;
  }

  static constructRangeFilters(
    rangeConfigs: EntityFilterRange[],
    ranges: Data,
  ): object[] {
    const and: Record<string, number>[] = [];
    Object.keys(ranges).forEach((k) => {
      const conf = rangeConfigs.find((rc) => rc.key === k);
      if (conf) {
        const fieldName = conf.field;
        const parsedRanges = ranges as Record<string, [number, number]>;
        const rangeMin = Number.parseFloat(String(parsedRanges[k][0]));
        const rangeMax = Number.parseFloat(String(parsedRanges[k][1]));
        and.push({
          ...(!Number.isNaN(rangeMax) ? { [`${fieldName}_lte`]: rangeMax } : {}),
          ...(!Number.isNaN(rangeMin) ? { [`${fieldName}_gte`]: rangeMin } : {}),
        });
      }
    });
    return and;
  }

  static constructSessionDateFilter(listConfigs: EntityFilterList[], filters: Data): object[] {
    const results: object[] = [];
    const confSessionDate = listConfigs
      .find((lc) => !!Object.keys(filters).find((k) => lc.code === k)
        && lc.listType === EntitySearchFilterType.SESSION_DATE);
    if (confSessionDate) {
      const dates = (filters as Record<string, []>)[confSessionDate.code] as unknown as string[];
      dates.forEach((date: string) => {
        const startTimestamp = getUnixTime(startOfDay(DateTimeHelper.toUTC(new Date(date))));
        const endTimestamp = getUnixTime(endOfDay(DateTimeHelper.toUTC(new Date(date))));
        const and = {
          AND: [{
            startTimestamp_gte: startTimestamp as number,
            startTimestamp_lte: endTimestamp as number,
          },
          {
            endTimestamp_gte: startTimestamp as number,
            endTimestamp_lte: endTimestamp as number,
          },
          ],
        };
        results.push(and);
      });
    }
    return results;
  }

  static constructDateFilter(listConfigs: EntityFilterList[], filters: Data): object[] {
    const confDate = listConfigs.find((lc) => !!Object.keys(filters).find((k) => lc.code === k)
      && lc.listType === EntitySearchFilterType.DATE);
    if (confDate) {
      const dates = (filters as Record<string, []>)[confDate.code] as unknown as string[];
      let startTimestamp = null;
      let endTimestamp = null;
      if (dates.length > 0) {
        switch (dates[0]) {
          case 'past-week':
            startTimestamp = getUnixTime(startOfDay(sub(new Date(), { weeks: 1 })));
            endTimestamp = getUnixTime(endOfDay(new Date()));
            break;
          case 'past-month':
            startTimestamp = getUnixTime(startOfDay(sub(new Date(), { months: 1 })));
            endTimestamp = getUnixTime(endOfDay(new Date()));
            break;
          case 'today':
            startTimestamp = getUnixTime(startOfDay(new Date()));
            endTimestamp = getUnixTime(endOfDay(new Date()));
            break;
          case 'this-week':
            startTimestamp = getUnixTime(startOfDay(new Date()));
            endTimestamp = getUnixTime(endOfDay(add(new Date(), { weeks: 1 })));
            break;
          case 'next-week':
            startTimestamp = getUnixTime(startOfDay(nextSunday(new Date())));
            endTimestamp = getUnixTime(endOfDay(add(nextSunday(new Date()),
              { weeks: 1 })));
            break;
          case 'this-month':
            startTimestamp = getUnixTime(startOfDay(new Date()));
            endTimestamp = getUnixTime(endOfDay(add(new Date(), { months: 1 })));
            break;
          case 'next-month':
            startTimestamp = getUnixTime(startOfMonth(addMonths(new Date(), 1)));
            endTimestamp = getUnixTime(endOfMonth(addMonths(new Date(), 1)));
            break;
          default:
            break;
        }

        return [
          {
            startTimestamp_gte: startTimestamp as number,
            startTimestamp_lte: endTimestamp as number,
          },
          {
            endTimestamp_gte: startTimestamp as number,
            endTimestamp_lte: endTimestamp as number,
          },
        ];
      }
      return [];
    }
    return [];
  }
}
