












import { Component, Prop } from 'vue-property-decorator';
import VueContextLoading from '@/components/VueContextLoading';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FontAwesomeComponent,
  },
})
export default class SliderArrowComponent extends VueContextLoading {
  @Prop({
    required: true,
    default: 'md',
  })
  private readonly size!: string;

  @Prop({
    required: true,
    default: '',
  })
  private readonly direction!: string;
}
