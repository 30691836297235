import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Data } from '@/utils/types/WidgetData';
import { Module, Mutation } from 'vuex-module-decorators';
import { deepGet } from '@/utils/ObjectHelpers';

@Module({ namespaced: true, stateFactory: true })
export default class DynamicTextWidgetStore extends WidgetBaseStore {
  private fieldData = '';

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    const payload: Data = JSON.parse(this.widget?.payload || '{}');
    if (data.values.length > 0 && payload.field) {
      data.values.forEach((dataValue) => {
        const fieldValue = deepGet(dataValue, String(payload.field));
        if (fieldValue) this.fieldData = String(fieldValue);
      });
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      text: this.fieldData,
    };
  }
}
