import { render, staticRenderFns } from "./ConnectionRequestNotification.vue?vue&type=template&id=0d94e975&scoped=true&"
import script from "./ConnectionRequestNotification.vue?vue&type=script&lang=ts&"
export * from "./ConnectionRequestNotification.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/FeedbackNotificationBox.scss?vue&type=style&index=0&id=0d94e975&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d94e975",
  null
  
)

export default component.exports