import {
  ApolloCache, HttpLink, split,
} from '@apollo/client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import SubscriptionApolloClient from '@/plugins/apollo/SubscriptionApolloClient';

export default class ChatApolloClient<T> extends SubscriptionApolloClient<T> {
  protected constructor(cache: ApolloCache<T>) {
    const secure = process.env.VUE_APP_SECURE_PROTOCOL === 'false' ? '' : 's';
    const web = `http${secure}`;
    const ws = `ws${secure}`;

    const linkOptions = {
      uri: process.env.VUE_APP_CHAT_GRAPHQL ? `${web}://${process.env.VUE_APP_CHAT_GRAPHQL}` : 'http://localhost:4000/graphql',
      includeUnusedVariables: true,
    };

    const httpLink = new HttpLink(linkOptions);
    const wsClient = new SubscriptionClient(
      process.env.VUE_APP_CHAT_GRAPHQL ? `${ws}://${process.env.VUE_APP_CHAT_GRAPHQL}` : 'ws://localhost:4000/graphql',
      {
        reconnect: true,
      },
    );
    const wsLink = new WebSocketLink(wsClient);

    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );

    super({
      link: splitLink,
      cache,
      ssrMode: false,
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
        },
        mutate: {
          fetchPolicy: 'no-cache',
        },
      },
    });

    this.wsClient = wsClient;
  }

  static init<T>(cache: ApolloCache<T>): SubscriptionApolloClient<T> {
    if (!ChatApolloClient.instance) {
      ChatApolloClient.instance = new ChatApolloClient(cache);
    }

    return ChatApolloClient.instance as unknown as SubscriptionApolloClient<T>;
  }
}
