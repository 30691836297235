enum ActionType {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  GET_ONE = 'get',
  GET_ALL = 'all',
  FILTER = 'filter',
  GET_SOME = 'some',
  GET_COUNT = 'count',
  GET_PAGINATED = 'paginated',
  BOOKMARK = 'bookmark',
  UNBOOKMARK = 'unbookmark',
  FOLLOW = 'follow',
  UNFOLLOW = 'unfollow',
  SHARE = 'share',
  UNSHARE = 'unshare',
  CONNECT_USER = 'connectUser',
  DELETE_USER_CONNECTION = 'deleteUserConnection',
  UPDATE_USER_CONNECTION = 'updateUserConnection',
  UPLOAD_FILE = 'upload-file',
  ADD_TAG_TO_ENTITY='add-tag-to-entity',
  LIKE='like',
  UNLIKE='unlike',
  ACTIVATE_ACCOUNT = 'activate-account',
  CHANGE_EMAIL = 'change-email',
}

export default ActionType;
