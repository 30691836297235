import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import FeedPostLink from '@/models/graphql/FeedPostLink';
import { FetchResult } from '@apollo/client';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import CreateInterface from '@/repositories/base/CreateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';

export default class FeedPostLinkRepository extends GraphQlRepository<FeedPostLink>
  implements CreateInterface<FeedPostLink>, DeleteInterface<FeedPostLink> {
  create(params: GqlPayloadParams): Promise<FeedPostLink | undefined> {
    return buildMutationGql({
      operationName: 'FeedPostLinkCreateForPost',
      operation: 'FeedPostLinkCreateForPost',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'feedPostLinkBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ FeedPostLinkCreateForPost: FeedPostLink }, {}>(mutation, params.definition.variables))
      .then((response: FetchResult<{ FeedPostLinkCreateForPost: FeedPostLink }>) => response.data?.FeedPostLinkCreateForPost);
  }

  delete(params: GqlPayloadParams): Promise<FeedPostLink | undefined> {
    return buildMutationGql({
      operationName: 'FeedPostLinkDelete',
      operation: 'FeedPostLinkDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'feedPostLinkUidFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ FeedPostLinkDelete: FeedPostLink }, {}>(mutation, params.definition.variables))
      .then((response: FetchResult<{ FeedPostLinkDelete: FeedPostLink }>) => response.data?.FeedPostLinkDelete);
  }
}
