

import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BasicTypes } from '@/utils/types/BasicTypes';

const chatDispatcherStore = namespace('ChatDispatcherStore');

@Component({
  inheritAttrs: false,
})
export default class ChatModuleHelper extends Vue {
  protected storeContext = 'ChatStore';

  protected removeStoreOnDestroy = true;

  protected chatStoreCreated = false;

  @chatDispatcherStore.Action
  protected hasRegisteredModule!: (name: string) => boolean;

  @chatDispatcherStore.Action
  private registerModule!: (payload: {
    baseStoreName: string; name: string; store: object;
  }) => void;

  @chatDispatcherStore.Action
  private unRegisterModule!: (payload: { name: string; store: object }) => void;

  protected get storeContextPath(): string {
    if (this.storeContext) {
      return `ChatDispatcherStore/${this.storeContext}`;
    }
    return '';
  }

  @Watch('storeContext', { immediate: true })
  createStore(): void {
    if (this.storeContext) {
      this.registerModule({
        baseStoreName: 'ChatStore',
        name: this.storeContext,
        store: this.$store,
      });
      setTimeout(() => {
        this.chatStoreCreated = true;
      }, 100);
    }
  }

  destroyed(): void {
    if (this.storeContext !== 'ChatStore' && this.removeStoreOnDestroy) {
      this.unRegisterModule({
        name: this.storeContext,
        store: this.$store,
      });
    }
  }

  protected commit(name: string, payload?: BasicTypes): void {
    this.$store.commit(`${this.storeContextPath}/${name}`, payload);
  }

  protected getter<T>(name: string): T {
    return this.$store.getters[`${this.storeContextPath}/${name}`];
  }

  protected state<T>(name: string): T {
    return this.$store.state.ChatDispatcherStore[this.storeContext][name];
  }

  protected dispatch<T>(name: string, payload?: BasicTypes): Promise<T> {
    return this.$store.dispatch(`${this.storeContextPath}/${name}`, payload);
  }
}
