enum GqlEntityFilterType {
  ACTION_RULE_FILTER = '_ActionRuleFilter',
  ACTION_RULE_GROUP_FILTER = '_ActionRuleGroupFilter',
  ADDRESS_FILTER = '_AddressFilter',
  AD_FILTER = '_AdFilter',
  AD_IMAGE_FILTER = '_AdImageFilter',
  APP_TRANSLATION_FILTER = '_AppTranslationFilter',
  ARTICLE_FILTER = '_ArticleFilter',
  AUTH_PROVIDER_FILTER = '_LoginProviderConfigFilter',
  BOOTH_FILTER = '_BoothFilter',
  BUSINESS_CARD_FILTER = '_BusinessCardFilter',
  CATEGORIZABLE_FILTER = '_CategorizableFilter',
  CATEGORY_DOMAIN_CONTEXT_FILTER = '_CategoryDomainContextFilter',
  CATEGORY_FILTER = '_CategoryFilter',
  CHANGE_LOG_FILTER = '_ChangeLogFilter',
  CHANNEL_FILTER = '_ChannelFilter',
  COMMENT_FILTER = '_CommentFilter',
  COMMUNITY_CONTENT_FILTER = '_CommunityContentFilter',
  COMMUNITY_FEATURE_FILTER = '_CommunityFeatureFilter',
  COMMUNITY_FILTER = '_CommunityFilter',
  COMMUNITY_USER_AGENDA_ENTRY_FILTER = '_CommunityUserAgendaEntryFilter',
  COMMUNITY_USER_BOOKMARK_FILTER = '_CommunityUserBookmarkFilter',
  COMMUNITY_USER_CATEGORY_FILTER = '_CommunityUserCategoryFilter',
  COMMUNITY_USER_CONNECTION_FILTER = '_CommunityUserConnectionFilter',
  COMMUNITY_USER_FILTER = '_CommunityUserFilter',
  COMMUNITY_USER_FOLLOW_FILTER = '_CommunityUserFollowFilter',
  COMMUNITY_USER_INTEREST_FILTER = '_CommunityUserInterestFilter',
  COMMUNITY_USER_LINK_FILTER = '_CommunityUserLinkFilter',
  COMMUNITY_USER_NOTE_FILTER = '_CommunityUserNoteFilter',
  COMMUNITY_USER_PREFERENCES_FILTER = '_CommunityUserPreferencesFilter',
  COMMUNITY_USER_PROFILE_ROLE_FILTER = '_CommunityUserProfileRoleFilter',
  COMMUNITY_USER_PROJECT_FILTER = '_CommunityUserProjectFilter',
  COMMUNITY_USER_PUSH_NOTIFICATION_TOKEN_FILTER = '_CommunityUserPushNotificationTokenFilter',
  COMMUNITY_USER_SHARE_FILTER = '_CommunityUserShareFilter',
  COMMUNITY_USER_TAG_FILTER = '_CommunityUserTagFilter',
  COMMUNITY_USER_TASK_FILTER = '_CommunityUserTaskFilter',
  COMMUNITY_USER_URL_FILTER = '_CommunityUserUrlFilter',
  COMMUNITY_USER_VISITED_FILTER = '_CommunityUserVisitedFilter',
  COMPANY_USER_ROLE_FILTER = '_CompanyUserRoleFilter',
  CONTAINER_EDITION_FILTER = '_ContainerEditionFilter',
  CONTAINER_FILTER = '_ContainerFilter',
  COUNTRY_FILTER = '_CountryFilter',
  DATA_TRANSLATION_FILTER = '_DataTranslationFilter',
  DEAL_FILTER = '_DealFilter',
  DELETABLE_CONTENT_FILTER = '_DeletableContentFilter',
  EDITION_ADDRESS_MAPPING_FILTER = '_EditionAddressMappingFilter',
  EDITION_BUSINESS_CARD_FILTER = '_EditionBusinessCardFilter',
  EDITION_BUSINESS_CARD_MAPPING_FILTER = '_EditionBusinessCardMappingFilter',
  EDITION_CATEGORY_MAPPING_FILTER = '_EditionCategoryMappingFilter',
  EDITION_CONTENT_FILTER = '_EditionContentFilter',
  EDITION_COUNTRY_MAPPING_FILTER = '_EditionCountryMappingFilter',
  EDITION_DATA_TRANSLATION_MAPPING_FILTER = '_EditionDataTranslationMappingFilter',
  EDITION_DEAL_MAPPING_FILTER = '_EditionDealMappingFilter',
  EDITION_DEMOGRAPHIC_ANSWER_FILTER = '_EditionDemographicAnswerFilter',
  EDITION_DEMOGRAPHIC_QUESTION_FILTER = '_EditionDemographicQuestionFilter',
  EDITION_EXHIBITOR_CONTACT_MAPPING_FILTER = '_EditionExhibitorContactMappingFilter',
  EDITION_EXHIBITOR_MAPPING_FILTER = '_EditionExhibitorMappingFilter',
  EDITION_EXHIBITOR_URL_MAPPING_FILTER = '_EditionExhibitorUrlMappingFilter',
  EDITION_EXTRA_PROPERTY_CONFIGURATION_MAPPING_FILTER = '_EditionExtraPropertyConfigurationMappingFilter',
  EDITION_EXTRA_PROPERTY_MAPPING_FILTER = '_EditionExtraPropertyMappingFilter',
  EDITION_EXTRA_PROPERTY_SECTION_MAPPING_FILTER = '_EditionExtraPropertySectionMappingFilter',
  EDITION_FEATURE_FILTER = '_EditionFeatureFilter',
  EDITION_FILE_RESOURCE_MAPPING_FILTER = '_EditionFileResourceMappingFilter',
  EDITION_FILTER = '_EditionFilter',
  EDITION_HANDOUT_MAPPING_FILTER = '_EditionHandoutMappingFilter',
  EDITION_LARGE_PRODUCT_IMAGE_MAPPING_FILTER = '_EditionLargeProductImageMappingFilter',
  EDITION_LARGE_PRODUCT_MAPPING_FILTER = '_EditionLargeProductMappingFilter',
  EDITION_PRODUCT_IMAGE_MAPPING_FILTER = '_EditionProductImageMappingFilter',
  EDITION_PRODUCT_MAPPING_FILTER = '_EditionProductMappingFilter',
  EDITION_PROFILE_ROLE_FILTER = '_EditionProfileRoleFilter',
  EDITION_PROFILE_ROLE_MAPPING_FILTER = '_EditionProfileRoleMappingFilter',
  EDITION_REGISTRATION_CATEGORY_FILTER = '_EditionRegistrationCategoryFilter',
  EDITION_REGISTRATION_CATEGORY_MAPPING_FILTER = '_EditionRegistrationCategoryMappingFilter',
  EDITION_REGISTRATION_FILTER = '_EditionRegistrationFilter',
  EDITION_REGISTRATION_MAPPING_FILTER = '_EditionRegistrationMappingFilter',
  EDITION_SESSION_MAPPING_FILTER = '_EditionSessionMappingFilter',
  EDITION_SPEAKER_MAPPING_FILTER = '_EditionSpeakerMappingFilter',
  EDITION_SPEAKER_ROLE_MAPPING_FILTER = '_EditionSpeakerRoleMappingFilter',
  EDITION_SUB_EDITION_MAPPING_FILTER = '_EditionSubEditionMappingFilter',
  EDITION_SURVEY_FILTER = '_EditionSurveyFilter',
  EDITION_SURVEY_MAPPING_FILTER = '_EditionSurveyMappingFilter',
  EDITION_SURVEY_QUESTION_CHOICE_FILTER = '_EditionSurveyQuestionChoiceFilter',
  EDITION_SURVEY_QUESTION_CHOICE_MAPPING_FILTER = '_EditionSurveyQuestionChoiceMappingFilter',
  EDITION_SURVEY_QUESTION_FILTER = '_EditionSurveyQuestionFilter',
  EDITION_SURVEY_QUESTION_MAPPING_FILTER = '_EditionSurveyQuestionMappingFilter',
  EDITION_SURVEY_USER_ANSWER_FILTER = '_EditionSurveyUserAnswerFilter',
  EDITION_SURVEY_USER_ANSWER_MAPPING_FILTER = '_EditionSurveyUserAnswerMappingFilter',
  EDITION_USER_FILTER = '_EditionUserFilter',
  EDITION_USER_INTEREST_FILTER = '_EditionUserInterestFilter',
  EDITION_USER_INTEREST_MAPPING_FILTER = '_EditionUserInterestMappingFilter',
  EDITION_USER_MAPPING_FILTER = '_EditionUserMappingFilter',
  EDITION_USER_PROFILE_ROLE_FILTER = '_EditionUserProfileRoleFilter',
  EDITION_USER_PROFILE_ROLE_MAPPING_FILTER = '_EditionUserProfileRoleMappingFilter',
  EMAIL_LOG_FILTER = '_EmailLogFilter',
  EMAIL_SCHEMA_FILTER = '_EmailSchemaFilter',
  EMAIL_UNSUBSCRIBE_FILTER = '_EmailUnsubscribeFilter',
  EMAIL_WIDGET_FILTER = '_EmailWidgetFilter',
  ENTITY_TYPE_FILTER = '_EntityTypeFilter',
  EXHIBITOR_CONTACT_FILTER = '_ExhibitorContactFilter',
  EXHIBITOR_FILTER = '_ExhibitorFilter',
  EXHIBITOR_PACKAGE_FILTER = '_ExhibitorPackageFilter',
  EXHIBITOR_TAG_FILTER = '_ExhibitorTagFilter',
  EXHIBITOR_URL_FILTER = '_ExhibitorUrlFilter',
  EXHIBITOR_VIDEO_FILTER = '_ExhibitorVideoFilter',
  EXHIBIT_HALL_FILTER = '_ExhibitHallFilter',
  EXHIBIT_TAG_FILTER = '_ExhibitTagFilter',
  EXTRA_PROPERTY_CONFIGURATION_FILTER = '_ExtraPropertyConfigurationFilter',
  EXTRA_PROPERTY_FILTER = '_ExtraPropertyFilter',
  EXTRA_PROPERTY_SECTION_FILTER = '_ExtraPropertySectionFilter',
  FEATURE_FILTER = '_FeatureFilter',
  FEED_ITEM_FILTER = '_FeedItemFilter',
  FEED_ITEM_WRAPPER_FILTER = '_FeedItemWrapperFilter',
  FEED_POST_FILTER = '_FeedPostFilter',
  FEED_POST_LINK_FILTER = '_FeedPostLinkFilter',
  FEED_POST_MEDIA_FILTER = '_FeedPostMediaFilter',
  FEED_POST_REPORT_FILTER = '_FeedPostReportFilter',
  FILE_RESOURCE_FILTER = '_FileResourceFilter',
  GEOZONE_FILTER = '_GeozoneFilter',
  HANDOUT_FILTER = '_HandoutFilter',
  INITIATOR_FILTER = '_InitiatorFilter',
  KEY_VALUE_FILTER = '_KeyValueFilter',
  LARGE_PRODUCT_FILTER = '_LargeProductFilter',
  LARGE_PRODUCT_IMAGE_FILTER = '_LargeProductImageFilter',
  LINKABLE_FILTER = '_LinkableFilter',
  LOCALE_FILTER = '_LocaleFilter',
  MASTER_COMMUNITY_FILTER = '_MasterCommunityFilter',
  MASTER_CONTENT_FILTER = '_MasterContentFilter',
  MASTER_DOMAIN_FILTER = '_MasterDomainFilter',
  MASTER_EDITION_FILTER = '_MasterEditionFilter',
  MEETING_FILTER = '_MeetingFilter',
  MEETING_PARTICIPANT_FILTER = '_MeetingParticipantFilter',
  MEETING_REQUEST_FILTER = '_MeetingRequestFilter',
  MESSAGE_FILTER = '_MessageFilter',
  MESSAGE_GROUP_FILTER = '_MessageGroupFilter',
  MESSAGE_GROUP_GUEST_TOKEN_FILTER = '_MessageGroupGuestTokenFilter',
  MESSAGE_GROUP_STATE_FILTER = '_MessageGroupStateFilter',
  MOBILE_SHOWROOM_CONFIG_FILTER = '_MobileShowroomConfigFilter',
  NODE_FILTER = '_NodeFilter',
  NOTIFICATION_FILTER = '_NotificationFilter',
  LOGIN_PROVIDER_CONFIG_FILTER = '_LoginProviderConfigFilter',
  ORDER_FILTER = '_OrderFilter',
  ORDER_PACKAGE_FILTER = '_OrderPackageFilter',
  ORGANIZER_FILTER = '_OrganizerFilter',
  POLL_ANSWER_FILTER = '_PollAnswerFilter',
  POLL_FILTER = '_PollFilter',
  POLL_USER_ANSWER_FILTER = '_PollUserAnswerFilter',
  PRODUCT_FILTER = '_ProductFilter',
  PRODUCT_IMAGE_FILTER = '_ProductImageFilter',
  PROFILE_ROLE_FILTER = '_ProfileRoleFilter',
  PROMO_SLOT_FILTER = '_PromoSlotFilter',
  RECOMMENDED_FILTER = '_RecommendedFilter',
  ROLE_FILTER = '_RoleFilter',
  SALES_COMPONENT_FILTER = '_SalesComponentFilter',
  SALES_PACKAGE_FILTER = '_SalesPackageFilter',
  SALES_PACKAGE_COMPONENT_FILTER = '_SalesPackageComponentFilter',
  SEARCH_RESULT_FILTER = '_SearchResultFilter',
  SECURITY_PRINCIPAL_FILTER = '_SecurityPrincipalFilter',
  SESSION_FILTER = '_SessionFilter',
  SESSION_RATING_FILTER = '_SessionRatingFilter',
  SLOT_FILTER = '_SlotFilter',
  SPEAKER_FILTER = '_SpeakerFilter',
  SPEAKER_ROLE_FILTER = '_SpeakerRoleFilter',
  SPONSOR_FILTER = '_SponsorFilter',
  SPONSOR_ROLE_FILTER = '_SponsorRoleFilter',
  STRIPE_FILTER = '_StripeFilter',
  SUB_EDITION_FILTER = '_SubEditionFilter',
  SUB_EDITION_USER_ROLE_FILTER = '_SubEditionUserRoleFilter',
  SURVEY_FILTER = '_SurveyFilter',
  SURVEY_QUESTION_CHOICE_FILTER = '_SurveyQuestionChoiceFilter',
  SURVEY_QUESTION_FILTER = '_SurveyQuestionFilter',
  SURVEY_USER_ANSWER_FILTER = '_SurveyUserAnswerFilter',
  TOPIC_FILTER = '_TopicFilter',
  UI_MENU_FILTER = '_UiMenuFilter',
  UI_MENU_ITEM_FILTER = '_UiMenuItemFilter',
  UI_ONBOARDING_FILTER = '_UiOnboardingFilter',
  UI_ONBOARDING_WIDGET_FILTER = '_UiOnboardingWidgetFilter',
  UI_PAGE_FILTER = '_UiPageFilter',
  UI_PAGE_WIDGET_FILTER = '_UiPageWidgetFilter',
  VENUE_FILTER = '_VenueFilter',
  SCHEDULE_EVENT_FILTER = '_ScheduleEventFilter',
}

export default GqlEntityFilterType;
