import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { Data } from '@/utils/types/WidgetData';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import Exhibitor from '@/models/graphql/Exhibitor';

@Module({ namespaced: true, stateFactory: true })
export default class CompanyEventsWidgetStore extends WidgetBaseStore {
  private exhibitorDataValue = {};

  @Action
  setDataConfigs(): void {
    const payload = JSON.parse(this.widget?.payload || '{}');
    const {
      entityCode,
    } = payload;
    const fragmentName = 'exhibitorCompanyEventsFragment';
    const operation = 'exhibitor';
    const filterType = GqlEntityFilterType.EXHIBITOR_FILTER;
    const variables = {
      filter: {
        uid: entityCode,
      },
    };
    const dataConfig: GqlComposeQueryDefinitionParams = {
      gqlDefinition: buildQueryDefinition({
        cacheable: true,
        filter: {
          value: variables.filter,
          type: filterType,
        },
      }),
      operation,
      fragmentName,
      alias: this.widgetStoreName,
    };
    super.setDataConfigs([dataConfig]);
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    if (data.values.length) {
      this.exhibitorDataValue = Exhibitor.hydrate(data.values[0]);
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      exhibitor: this.exhibitorDataValue,
    };
  }
}
