export default class ColorHelper {
  static generatedColor(str: string): string {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      // eslint-disable-next-line no-bitwise
      const value = (hash >> (i * 8)) & 0xFF;
      colour += (`00${value.toString(16)}`).substr(-2);
    }
    return colour;
  }

  static colorContrast(color: string): 'BLACK' | 'WHITE' {
    let hexColor = color;
    // If a leading # is provided, remove it
    if (hexColor.slice(0, 1) === '#') {
      hexColor = hexColor.slice(1);
    }

    // Convert to RGB value
    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);

    // Get YIQ ratio
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    // Check contrast
    return (yiq >= 128) ? 'BLACK' : 'WHITE';
  }

  static color(str: string, amt: number): string {
    let col = this.generatedColor(str);
    let usePound = false;

    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(col, 16);
    // eslint-disable-next-line no-bitwise
    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    // eslint-disable-next-line no-bitwise
    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    // eslint-disable-next-line no-bitwise
    let g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    // eslint-disable-next-line no-bitwise
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }
}
