import {
  ApolloQueryResult, DocumentNode, FetchResult, gql,
} from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import {
  buildComposeQueryGql,
  buildMutationGql,
  buildQueryGql,
} from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import FilterInterface from '@/repositories/base/FilterInterface';
import Message from '@/models/graphql/Message';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import GqlComposeQueryPayloadParams from '@/utils/types/gql/GqlComposeQueryPayloadParams';
import { BasicTypes } from '@/utils/types/BasicTypes';
import { print } from 'graphql/language/printer';

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/camelcase */
export default class MessageQnaRepository extends GraphQlRepository<Message>
  implements FilterInterface<Message>, UpdateInterface<Message> {
  filter(params: GqlPayloadParams): Promise<Message[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterMessage',
      operation: 'message',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'messageBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ message: Array<Message> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ message: Array<Message> }>) => response.data.message));
  }

  filterWithCount(payload: {
    params: GqlComposeQueryPayloadParams;
    queries: string;
  }): Promise<Record<string, BasicTypes[]>> {
    let promise = Promise.resolve({} as DocumentNode);
    if (payload.params && payload.params.definitions.length > 0) {
      promise = buildComposeQueryGql({
        operationName: payload.params.operationName as string,
        definitions: payload.params.definitions,
      });
    }
    return promise.then((query) => {
      let gqlQueryString = '';
      if (Object.keys(query).length > 0) {
        gqlQueryString = print(query);
      }
      return this.query<Record<string, BasicTypes[]>, {}>(gql(gqlQueryString), {}, false);
    }).then((response: ApolloQueryResult<Record<string, BasicTypes[]>>) => response.data);
  }

  createAnswer(params: GqlPayloadParams): Promise<Message | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateMessage',
      operation: 'MessageCreateForUserAndGroupAndParentMessage',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'messageBaseFragment',
    }).then((mutation) => this.mutate<{ MessageCreateForUserAndGroupAndParentMessage: Message }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ MessageCreateForUserAndGroupAndParentMessage: Message }>) => response.data?.MessageCreateForUserAndGroupAndParentMessage));
  }

  createQuestion(params: GqlPayloadParams): Promise<Message | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateMessage',
      operation: 'MessageCreateForUserAndGroup',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'messageBaseFragment',
    }).then((mutation) => this.mutate<{ MessageCreateForUserAndGroup: Message }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ MessageCreateForUserAndGroup: Message }>) => response.data?.MessageCreateForUserAndGroup));
  }

  delete(params: GqlPayloadParams): Promise<Message | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeleteMessage',
      operation: 'MessageDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'messageBaseFragment',
    }).then((mutation) => this.mutate<{ MessageDelete: Message }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ MessageDelete: Message }>) => response.data?.MessageDelete));
  }

  update(params: GqlPayloadParams): Promise<Message | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdateMessage',
      operation: 'MessageUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'messageBaseFragment',
    }).then((mutation) => this.mutate<{ MessageUpdate: Message }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ MessageUpdate: Message }>) => response.data?.MessageUpdate));
  }
}
