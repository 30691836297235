import { Quill } from 'vue-quill-editor';

const parchment = Quill.import('parchment');

// eslint-disable-next-line @typescript-eslint/naming-convention
const SizeClass = new parchment.Attributor.Class('size', 'ql-size', {
  scope: parchment.Scope.INLINE,
  whitelist: ['caption', false, 'medium', 'large', 'huge'],
});

export { SizeClass };
