import LoadableState from '@/store/states/LoadableState';
import Meeting from '@/models/graphql/Meeting';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import { Action, Module } from 'vuex-module-decorators';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import LoadableStore from '@/store/LoadableStore';
import MeetingRequestRepository from '@/repositories/MeetingRequestRepository';

@Module({ namespaced: true })
export default class MeetingRequestStore extends LoadableStore<LoadableState> {
    meetingRequestRepository = new MeetingRequestRepository();

    protected get repository(): MeetingRequestRepository {
      return this.meetingRequestRepository;
    }

  @Action
    createMeetingRequest(payload: {
    creatorUid: string;
    exhibitorUid: string;
    companyRepUid: string | null;
    guests: string[];
    entity: Partial<MeetingRequest>;
  }): Promise<MeetingRequest | null | undefined> {
      return this.repository.create({
        definition: buildMutationDefinition([
          {
            type: 'ID!',
            fieldName: 'creatorUid',
            value: payload.creatorUid,
          },
          {
            type: 'ID!',
            fieldName: 'exhibitorUid',
            value: payload.exhibitorUid,
          },
          {
            type: 'ID',
            fieldName: 'companyRepUid',
            value: payload.companyRepUid,
          },
          {
            type: GqlEntityInputType.ID_ARRAY,
            fieldName: 'guests',
            value: payload.guests,
          },
          {
            type: 'MeetingRequestInput!',
            fieldName: 'entity',
            value: payload.entity,
          },
        ]),
      });
    }

  @Action
  meetingRequestSetMeeting({ uid, meeting }: { uid: string; meeting: Meeting }): Promise<MeetingRequest | undefined> {
    return this.repository.meetingRequestSetMeeting({
      definition: buildMutationDefinition([
        {
          type: GqlEntityInputType.ID,
          fieldName: 'uid',
          value: uid,
        },
        {
          type: GqlEntityInputType.ID,
          fieldName: 'meeting_MeetingUid',
          value: meeting.uid,
        },
      ]),
    });
  }

  @Action
  deleteMeetingRequest(uid: string): void {
    this.repository.delete({
      definition: buildMutationDefinition([
        {
          type: GqlEntityInputType.ID,
          fieldName: 'uid',
          value: uid,
        },
      ]),
    });
  }

  @Action
  updateMeetingRequest(payload: {
    entity: Partial<MeetingRequest>;
  }): Promise<MeetingRequest | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([
        {
          type: GqlEntityInputType.MEETING_REQUEST_INPUT,
          fieldName: 'entity',
          value: payload.entity,
        },
      ]),
    });
  }
}
