import { Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';

@Module({ namespaced: true, stateFactory: true })
export default class BigNumberWidgetStore extends WidgetBaseStore {
  @Mutation
  mapper(): void {
    let value = 0;
    if (this.data.length > 0 && this.data[0].count) {
      value = parseInt(this.data[0].count as string, 10);
    } else if (this.payload && this.payload.staticValue && this.payload.value) {
      value = this.payload.value as number;
    }
    this.mapping = {
      ...this.payload,
      value,
    };
  }
}
