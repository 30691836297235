enum FileType {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpeg',
  GIF = 'image/gif',
  WEBP = 'image/webp',
  PDF = 'application/pdf',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ZIP = 'application/zip',
  GZ = 'application/gzip',
  RAR = 'application/vnd.rar',
  TAR = 'application/x-tar',
  CSV = 'text/csv',
  TXT = 'text/plain',
  MP4 = 'video/mp4',
  WEBM = 'video/webm',
  OGG = 'video/ogg',
  AVI = 'video/avi',
  MOV = 'video/mov',
  MPG = 'video/mpg',
  WMV = 'video/wmv',
  FLV = 'video/flv',
  MKV = 'video/mkv',
}

export default FileType;
