/* eslint-disable max-len */
import { Action, Module } from 'vuex-module-decorators';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import LoadableStore from '@/store/LoadableStore';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import LoadableState from '@/store/states/LoadableState';
import ExhibitorVideo from '@/models/graphql/ExhibitorVideo';
import ExhibitorVideoRepository from '@/repositories/ExhibitorVideoRepository';

@Module({ namespaced: true })
export default class ExhibitorVideoStore extends LoadableStore<LoadableState> {
  private readonly exhibitorVideoRepository = new ExhibitorVideoRepository();

  private get repository(): ExhibitorVideoRepository {
    return this.exhibitorVideoRepository;
  }

  @Action
  create(payload: {
    exhibitorUid: string;
    entity: Partial<ExhibitorVideo>;
  }): Promise<ExhibitorVideo | undefined> {
    return this.repository.create({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.EXHIBITOR_VIDEO_INPUT,
        value: payload.entity,
      },
      {
        fieldName: 'exhibitor_ExhibitorUid',
        type: 'ID',
        value: payload.exhibitorUid,
      },
      ]),
    });
  }

  @Action
  delete(uid: string): Promise<boolean> {
    return this.repository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: uid,
        },
      ]),
    }).then((response) => !!response);
  }

  @Action
  update(exhibitorVideo: Partial<ExhibitorVideo>): Promise<ExhibitorVideo | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.EXHIBITOR_VIDEO_INPUT,
          value: exhibitorVideo,
        },
      ]),
    });
  }
}
