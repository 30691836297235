var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-skeleton-wrapper',{attrs:{"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"pill-container"},[_c('b-skeleton',{staticClass:"rounded-pill",attrs:{"height":_vm.size === 'sm' ? '1.5rem' : '2.5rem',"width":_vm.size === 'sm' ? '3rem' : '6rem',"type":"button"}})],1)]},proxy:true},{key:"default",fn:function(){return [_c('div',{class:[
        'pill-container',
        _vm.fluid ? 'w-100 justify-content-center': '',
        (_vm.variant + " " + _vm.size),
        (_vm.DecorationEnum.IMAGE === _vm.decoration
          || _vm.DecorationEnum.ICON === _vm.decoration) && _vm.clearable
          ? 'clearable-icon-image-padding'
          : (_vm.DecorationEnum.IMAGE === _vm.decoration
            || _vm.DecorationEnum.ICON === _vm.decoration && _vm.text) && !_vm.clearable
            ? 'icon-image-padding'
            : (_vm.DecorationEnum.IMAGE === _vm.decoration
              || _vm.DecorationEnum.ICON === _vm.decoration && !_vm.text) && !_vm.clearable
              ? 'icon-image-no-text-padding'
              : _vm.DecorationEnum.IMAGE !== _vm.decoration
                && _vm.DecorationEnum.ICON !== _vm.decoration
                && _vm.clearable
                ? 'clearable-padding'
                : _vm.DecorationEnum.NUMBER === _vm.decoration
                  ? 'number-padding'
                  : '',
        _vm.clickable ? 'clickable' : ''
      ],on:{"click":function($event){$event.stopPropagation();_vm.disabledParam ? function() {} : _vm.$emit('on-click')}}},[(_vm.DecorationEnum.IMAGE === _vm.decoration && _vm.img)?_c('avatar-solo-widget',{attrs:{"size":_vm.size,"src":_vm.img}}):_vm._e(),(_vm.DecorationEnum.ICON === _vm.decoration && _vm.iconClass)?_c('font-awesome-component',{attrs:{"container-classes":[_vm.sizeEnum.SMALL === _vm.size ? 'icon-16' : 'icon-24'],"icon":_vm.iconClass,"icon-classes":{ 'icon-12': _vm.sizeEnum.SMALL === _vm.size }}}):_vm._e(),(_vm.DecorationEnum.NUMBER === _vm.decoration)?_c('span',{staticClass:"number label label-5"},[_vm._v(" "+_vm._s(_vm.number)+" ")]):_vm._e(),(_vm.text)?_c('p',{class:[
          'p-0 m-0 text-center text content-text',
          ("" + _vm.textVariant),
          _vm.size === _vm.sizeEnum.SMALL
            ? 'label label-2'
            : _vm.size === _vm.sizeEnum.MICRO
              ? 'label label-4'
              : '',
          _vm.nowrap ? 'no-wrap' : ''
        ],attrs:{"title":_vm.nowrap ? _vm.text : ''}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),(_vm.clearable)?_c('font-awesome-component',{attrs:{"container-class":[
          'clear',
          { 'icon-24': _vm.sizeEnum.MEDIUM === _vm.size},
          { 'icon-16': _vm.sizeEnum.SMALL === _vm.size }
        ],"icon-classes":{ 'icon-12': _vm.sizeEnum.SMALL === _vm.size },"icon":"far fa-xmark"},on:{"on-click":function($event){return _vm.$emit('on-clear')}}}):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }