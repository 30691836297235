






































import { Component, Prop } from 'vue-property-decorator';
import { BButton, BSkeletonWrapper, BSpinner } from 'bootstrap-vue';
import VueContextLoading from '@/components/VueContextLoading';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    BSpinner,
    BButton,
    BSkeletonWrapper,
    FontAwesomeComponent,
  },
})
export default class ButtonComponent extends VueContextLoading {
  @Prop({ default: '' })
  private readonly variant!: string;

  @Prop({ default: '' })
  private readonly size!: string;

  @Prop({ default: '' })
  private readonly text!: string;

  @Prop({ default: false })
  private readonly disabled!: boolean;

  @Prop({ default: 'box' })
  private readonly type!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly rounded!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly loading!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly loadingWithText!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly noBorder!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly bold!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly isHtml!: boolean;

  @Prop({
    required: false,
    default: '',
  })
  private readonly textClasses!: string;

  private get sizeClass(): string {
    if (this.size === 'lg') {
      return 'lg-btn rounded';
    }
    if (this.size === 'md') {
      return 'md-btn rounded-sm';
    }
    if (this.size === 'medium-to-small') {
      return 'medium-to-small rounded-sm';
    }
    return 'sm-btn rounded-sm';
  }

  focusOnButton(): void {
    setTimeout(() => {
      (this.$refs['button-wrapper'] as HTMLButtonElement).focus();
    }, 50);
  }
}
