import { Component, Vue } from 'vue-property-decorator';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import { namespace } from 'vuex-class';
import GenericEvent from '@/utils/types/GenericEvent';
import GUUID from '@/utils/GUUID';

const notificationStore = namespace('NotificationStore');

@Component
export default class VueBaseNotify extends Vue {
  protected notifyEvents: NotificationEventType[] = [];

  protected componentId = '';

  @notificationStore.Mutation
  private registerComponent!: (payload: {
    type: NotificationEventType;
    componentId: string;
    callback: (event: SubscriptionEvent | GenericEvent) => void;
  }) => void;

  @notificationStore.Mutation
  private unregisterComponent!: (payload: {
    type: NotificationEventType;
    componentId: string;
  }) => void;

  protected get events(): NotificationEventType[] {
    return this.notifyEvents;
  }

  mounted(): void {
    this.register();
  }

  created(): void {
    this.componentId = this.componentId ? this.componentId : `component-${GUUID.uuidv4()}`;
  }

  beforeDestroy(): void {
    this.events.forEach((type) => {
      this.unregisterComponent({ type, componentId: this.componentId });
    });
  }

  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-unused-vars
  protected notificationCallback(event: SubscriptionEvent | GenericEvent, componentId?: string): void {
    throw new Error('not implemented');
  }

  protected registerComponentForNotification(): void {
    this.register();
  }

  private register(): void {
    this.events.forEach((type) => {
      this.registerComponent({ type, componentId: this.componentId, callback: this.notificationCallback.bind(this) });
    });
  }
}
