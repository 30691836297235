enum CategoryDomainContext {
  COMPANY_DETAIL = 'company_detail',
  COMPANY_CMS = 'company_cms',
  EXHIBITOR_DETAIL = 'exhibitor_detail',
  INTEREST_SESSION = 'interest_session',
  INTEREST_EXHIBITOR = 'interest_exhibitor',
  PRODUCT_DETAIL = 'product_detail',
  PRODUCT_CMS = 'product_cms',
  LARGE_PRODUCT_DETAIL = 'large_product_detail',
  LARGE_PRODUCT_CMS = 'large_product_cms',
  DEAL_DETAIL = 'deal_detail',
  SESSION_DETAIL = 'session_detail',
  SESSION_EXHIBITOR = 'session_exhibitor',
  USER_PROFILE_INTEREST = 'user_profile_interest',
  USER_PROFILE_SESSION_TOPICS_INTEREST = 'user_profile_session_topics_interest'
}

export default CategoryDomainContext;
