

























import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import Survey from '@/models/graphql/Survey';

@Component({
  components: {
    ButtonIconComponent,
    FontAwesomeComponent,
    ButtonComponent,
  },
})
/* eslint-disable no-underscore-dangle */
export default class SurveyGlobalNotification extends Vue {
  @Prop({ required: true, default: null })
  protected readonly survey!: Survey | null;

  private onTakeSurveyClick(): void {
    if (this.survey && this.survey.uid) {
      this.$router.push(`/survey/${this.survey.uid}`);
    }
  }
}
