enum ViewMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  LIST = 'LIST',
  DAILY_VIEW = 'DAILY_VIEW',
  SEARCH = 'SEARCH',
  DETAIL = 'DETAIL',
}

export default ViewMode;
