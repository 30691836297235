import { FetchResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import PollAnswer from '@/models/graphql/PollAnswer';
import WriteInterface from '@/repositories/base/WriteInterface';

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/camelcase */
export default class PollAnswerRepository extends GraphQlRepository<PollAnswer>
  implements WriteInterface<PollAnswer> {
  create(params: GqlPayloadParams): Promise<PollAnswer | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreatePollAnswer',
      operation: 'PollAnswerCreateForPoll',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'pollAnswerBaseFragment',
    }).then((mutation) => this.mutate<{ PollAnswerCreateForPoll: PollAnswer }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ PollAnswerCreateForPoll: PollAnswer }>) => response.data?.PollAnswerCreateForPoll));
  }

  update(params: GqlPayloadParams): Promise<PollAnswer | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdatePollAnswer',
      operation: 'PollAnswerUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'pollAnswerBaseFragment',
    }).then((mutation) => this.mutate<{ PollAnswerUpdate: PollAnswer }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ PollAnswerUpdate: PollAnswer }>) => response.data?.PollAnswerUpdate));
  }

  delete(params: GqlPayloadParams): Promise<PollAnswer | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeletePollAnswer',
      operation: 'PollAnswerDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'pollAnswerBaseFragment',
    }).then((mutation) => this.mutate<{ PollAnswerDelete: PollAnswer }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ PollAnswerDelete: PollAnswer }>) => response.data?.PollAnswerDelete));
  }
}
