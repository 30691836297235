

































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import InputText from '@/components/InputText.vue';
import UploadAssetComponent from '@/components/UploadAssetComponent.vue';
import MemberInviteAccountFound
  from '@/components/modals/member-invite/MemberInviteAccountFound.vue';
import MemberInvitePermissions from '@/components/modals/member-invite/MemberInvitePermissions.vue';
import MemberInviteMultipleAccount
  from '@/components/modals/member-invite/MemberInviteMultipleAccount.vue';
import MemberInviteNoAccountFound
  from '@/components/modals/member-invite/MemberInviteNoAccountFound.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CommunityUser from '@/models/graphql/CommunityUser';
import { Getter, namespace } from 'vuex-class';
import IllustrationType from '@/utils/enums/IllustrationType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ActionRule from '@/utils/enums/ActionRule';
import { BasicTypes } from '@/utils/types/BasicTypes';
import { StateChanger } from 'vue-infinite-loading';

const communityUserStore = namespace('CommunityUserStore');

@Component({
  components: {
    FontAwesomeComponent,
    LoadingSpinnerComponent,
    IllustrationComponent,
    AvatarSoloWidget,
    MemberInviteNoAccountFound,
    MemberInviteMultipleAccount,
    MemberInvitePermissions,
    MemberInviteAccountFound,
    UploadAssetComponent,
    ButtonComponent,
    InputText,
    ButtonIconComponent,
    StandardModal,
  },
})
export default class UserSelectorModal extends Vue {
  @Getter
  authUser!: CommunityUser;

  @Prop({ default: '' })
  modalId!: string;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: false, default: () => [] })
  private readonly selectedUsers!: CommunityUser[];

  @Prop({ required: false, default: () => [] })
  private readonly excludeUsers!: string[];

  @communityUserStore.Action
  private loadPaginatedUsers!: (payload: Record<string, BasicTypes>) => Promise<number | null>;

  @communityUserStore.Action
  private resetPage!: () => void;

  @communityUserStore.Getter
  private fetchCommunityUsers!: CommunityUser[];

  @communityUserStore.Getter
  private isLoading!: boolean;

  @communityUserStore.State
  private itemsPerPage!: number;

  private searchQuery = '';

  private FileResourceHelper = FileResourceHelper;

  private selectedUserList: CommunityUser[] = [];

  private IllustrationType = IllustrationType;

  private reset!: boolean;

  private timer: NodeJS.Timeout | null = null;

  private debounceTime = 500;

  private get isMobile(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'sm' || this.currentBootstrapBreakpoint === 'xs');
  }

  private get isTablet(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'md');
  }

  private mounted(): void {
    this.selectedUserList = [...this.selectedUsers];
  }

  private isSelected(user: CommunityUser): boolean {
    const isSelected = this.selectedUserList
      .findIndex((selectedUser: CommunityUser) => user.uid === selectedUser.uid);
    return isSelected !== -1;
  }

  private onSelectUser(user: CommunityUser): void {
    const isSelected = this.selectedUserList
      .findIndex((selectedUser: CommunityUser) => user.uid === selectedUser.uid);
    if (isSelected === -1) {
      this.selectedUserList.push(user);
    } else if (this.selectedUserList.length === 1) {
      this.selectedUserList = [];
    } else {
      this.selectedUserList.splice(isSelected, 1);
    }
  }

  private defaultCompany(user: CommunityUser): string {
    const defaultUser = CommunityUser.hydrate(user);
    if (!defaultUser.defaultCompany && !defaultUser.employerName) {
      return '';
    }
    return defaultUser.defaultCompany
      ? this.$tc('actions.at', 0, { employer: defaultUser.defaultCompany.name })
      : this.$tc('actions.at', 0, { employer: defaultUser.employerName });
  }

  private onConfirm(): void {
    this.$emit('on-confirm', this.selectedUserList);
  }

  @Watch('searchQuery')
  private onSearch(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.reset = true;
      this.resetPage();
      (this.$refs.infiniteLoading as unknown as Vue).$emit('$InfiniteLoading:reset');
    }, this.debounceTime);
  }

  private infiniteHandler(state: StateChanger): void {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const excludeFilter = this.excludeUsers.length > 0 ? { uid_not_in: this.excludeUsers } : {};
    const entityFts = this.searchQuery.length > 0 ? { entityFts: this.searchQuery } : {};
    this.loadPaginatedUsers({
      filter: {
        _connectedTo: this.authUser.uid,
        filterActions: [ActionRule.CAN_MEET],
        ...entityFts,
        ...excludeFilter,
      },
      authUser: this.authUser.uid,
    }).then((userCount) => {
      if (userCount === this.itemsPerPage) {
        state.loaded();
      } else {
        state.complete();
      }
    }).catch(() => state.complete());
  }

  private onShow(): void {
    this.selectedUserList = [...this.selectedUsers];
    this.onSearch();
  }

  private onHide(): void {
    this.$bvModal.hide(this.modalId);
    this.resetPage();
  }
}
