import MyCart from '@/utils/constants/MyCart';
import ClientStorage from '@/utils/ClientStore';
import Cart from '@/models/LocalStorage/Cart';

export default class LocalStorageCartRepository {
    myCartConstant = MyCart;

    setCart(cartUid: string): Cart {
      const jsonData = ClientStorage.getItem(this.myCartConstant);
      const cartStorage = jsonData ? JSON.parse(jsonData) as Cart[] : [];
      const currentCartElement = cartStorage.find((e: Cart) => e.uid === cartUid);
      return currentCartElement ?? {
        uid: cartUid,
        packagesInformation: [],
      };
    }

    saveCart(payload: {cartUid: string; cart: Cart}): void {
      const jsonData = ClientStorage.getItem(this.myCartConstant);
      const cartStorage: Cart[] = jsonData ? JSON.parse(jsonData) as Cart[] : [];
      const index = cartStorage.findIndex((e: Cart) => e.uid === payload.cartUid);
      if (index !== -1) {
        cartStorage[index].packagesInformation = payload.cart?.packagesInformation;
      } else {
        cartStorage.push(payload.cart);
      }
      ClientStorage.setItem(this.myCartConstant, JSON.stringify(cartStorage));
    }
}
