var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({class:[
    'avatar-solo-widget',
    'z-index-0 d-flex align-items-center justify-content-center',
    {'rounded-circle': !_vm.square},
    {'white-line-border': _vm.withBorders},
    ("s-" + _vm.size),
    'position-relative'
  ],on:{"click":function($event){return _vm.$emit('on-click')}}},'div',_vm.useTestDataAttribute(_vm.dataCy || ''),false),[(_vm.recommendedForYou)?_c('div',{staticClass:"w-100 position-absolute\n      d-flex justify-content-center align-items-end for-you-badge"},[_c('recommendation-badge-component',{attrs:{"label":("" + (_vm.$t('actions.for-you'))),"size":_vm.size.toString() === '136'
        ? 'lg'
        : _vm.size.toString() === '96' || _vm.size.toString() === '72' ? 'md' : 'sm'}})],1):_vm._e(),(_vm.isOnline)?_c('div',{staticClass:"position-absolute\n      d-flex justify-content-center align-items-end online-badge-border"},[_c('div',{staticClass:"online-badge"})]):_vm._e(),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],class:[
      'avatar-solo d-flex align-items-center justify-content-center bg-white',
      ("s-" + _vm.size), _vm.square ? 'company' : 'default', {'rounded-circle': !_vm.square},
      {'line-border': !(_vm.source && !_vm.square) && !_vm.defaultIcon && !_vm.recommendedForYou},
      {'line-border-lg': !_vm.square && _vm.withBorders && !_vm.recommendedForYou},
      {'border-neutral-n-4-line': !_vm.recommendedForYou && !_vm.borderColorClass && !_vm.borderColor},
      _vm.borderColorClass && !_vm.borderColor ? _vm.borderColorClass : '',
      {'border-orange-gradient': _vm.recommendedForYou && !_vm.borderColorClass && !_vm.borderColor},
      {'no-border': !_vm.withBorders},
      {opaque: _vm.opaque}
    ],style:(!_vm.borderColorClass && _vm.borderColor ? { borderColor: _vm.borderColor }: ''),attrs:{"title":_vm.tooltipText}},[_c('div',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-center",class:[
        {'base-content': !_vm.square},
        !_vm.source && _vm.defaultIcon ? _vm.iconMainContainerClasses : ''
      ]},[(_vm.square)?[(!_vm.source && _vm.defaultIcon)?_c('font-awesome-component',{attrs:{"container-classes":_vm.iconContainerClasses,"icon":_vm.defaultIcon,"icon-classes":_vm.iconClasses}}):_c('img',{class:['image', {'cover': _vm.cover}],attrs:{"src":_vm.source,"alt":"company logo"},on:{"error":function (e) { _vm.source = null }}})]:[(_vm.source)?_c('span',{class:_vm.cover ? 'bg-image-cover' : 'bg-image',style:(("background-image: url(" + _vm.source + ")")),on:{"error":function (e) { _vm.source = null }}}):(_vm.lastname && _vm.firstname)?_c('span',{staticClass:"initials"},[_vm._v(" "+_vm._s(_vm.firstname[0].toUpperCase())+_vm._s(_vm.lastname[0].toUpperCase())+" ")]):(!_vm.source && _vm.defaultIcon)?_c('font-awesome-component',{attrs:{"container-classes":_vm.iconContainerClasses,"icon":_vm.defaultIcon,"icon-classes":_vm.iconClasses}}):_vm._e(),(_vm.showEditButton)?_c('button-icon-component',{staticClass:"border-0 position-absolute button-right",attrs:{"shape":"round","size":"md","variant":"admin"},on:{"on-click":function($event){return _vm.$emit('button-click')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-pen"}})]},proxy:true}],null,false,766880639)}):_vm._e()]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }