/* eslint-disable import/no-cycle */
import MessageGroupInterface from '@/models/graphql/MessageGroupInterface';
import MessageBoxConversationSummary from '@/utils/types/chat/MessageBoxConversationSummary';
import MessageGroupState from '@/models/graphql/MessageGroupState';
import MessageType from '@/utils/enums/chat/MessageType';
import GroupType from '@/utils/enums/chat/GroupType';
import Session from '@/models/graphql/Session';
import Exhibitor from '@/models/graphql/Exhibitor';
import Product from '@/models/graphql/Product';
import Deal from '@/models/graphql/Deal';
import Community from '@/models/graphql/Community';
import BaseModel from '@/models/BaseModel';
import CommunityUser from '@/models/graphql/CommunityUser';
import Message from '@/models/graphql/Message';
import MessageGroupGuestToken from '@/models/graphql/MessageGroupGuestToken';
import Meeting from '@/models/graphql/Meeting';
import LargeProduct from '@/models/graphql/LargeProduct';

class MessageGroup extends BaseModel implements MessageGroupInterface {
  community?: Community;

  schemaCode?: string;

  tempId?: string;

  groupType?: GroupType;

  createdTime?: Date;

  users?: Array<CommunityUser>;

  guests?: Array<MessageGroupGuestToken> = [];

  inactiveUsers?: Array<CommunityUser>;

  whoTyping: Array<CommunityUser> = [];

  messageGroupStates?: Array<MessageGroupState>;

  myState?: MessageGroupState;

  lastMessageNotYours?: Message;

  target?: Session | Exhibitor | Product | LargeProduct | Deal | Meeting | null = null;

  messages: Array<Message> = [];

  selected = false;

  updated = false;

  unreadState = false;

  messagesInView = false;

  newMessageReceived = 0;

  messagesCount = 0;

  messagesOffset = 0;

  isMuted: string | null = null;

  active: boolean | null = false;

  isSessionModerator?: boolean;

  moderators: CommunityUser[] = [];

  set addUsers(users: CommunityUser[]) {
    this.users = users;
  }

  get lastMessage(): Message | null {
    return this.messages?.filter((m) => m.type === MessageType.REGULAR
      || m.type === MessageType.LINK)
      .pop() || null;
  }

  get membersData(): MessageBoxConversationSummary {
    return new MessageBoxConversationSummary(
      [...(this.users || []), ...(this.inactiveUsers || [])],
    );
  }

  get activeMembersData(): MessageBoxConversationSummary {
    return new MessageBoxConversationSummary(
      [...(this.users || [])],
    );
  }

  static hydrate(item: object): MessageGroup {
    const result = new MessageGroup();
    Object.assign(result, { ...item });

    if (result.messages) {
      result.messages = result.messages.map((el) => Message.hydrate(el));
    } else {
      result.messages = [];
    }

    if (result.users) {
      result.users = result.users.map((el) => CommunityUser.hydrate(el));
    }

    if (result.inactiveUsers) {
      result.inactiveUsers = result.inactiveUsers.map((el) => CommunityUser.hydrate(el));
    }

    if (result.guests) {
      result.guests = result.guests.map((el) => MessageGroupGuestToken.hydrate(el));
    }

    return result;
  }
}

export default MessageGroup;
