import { ApolloQueryResult } from '@apollo/client';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildComposeQueryGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import BaseModel from '@/models/BaseModel';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import Meeting from '@/models/graphql/Meeting';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import GqlComposeQueryPayloadParams from '@/utils/types/gql/GqlComposeQueryPayloadParams';

/* eslint-disable max-len */
export default class CompanyCalendarRepository extends GraphQlRepository<BaseModel> {
  loadComposedQueries<T>(params: GqlComposeQueryPayloadParams): Promise<T> {
    return buildComposeQueryGql({
      operationName: params.operationName || 'LoadComposedQueries',
      definitions: params.definitions,
      authUser: params.authUser,
      magicArgs: params.magicArgs,
    }).then((query) => this.query<{ }, {}>(query, {}, false)
      .then((response: ApolloQueryResult<{ }>) => response.data as T));
  }

  loadMeetings(params: GqlPayloadParams): Promise<Meeting[]> {
    return buildQueryGql({
      operationName: params.operationName || 'LoadMeetings',
      operation: 'meeting',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'meetingFullFragment',
    }).then((query) => this
      .query<{ meeting: Meeting[] }, {}>(
        query,
        params.definition.variables,
        false,
      ).then((response: ApolloQueryResult<{ meeting: Meeting[] }>) => response.data.meeting));
  }

  count(params: GqlPayloadParams): Promise<number | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetUnreadMeetingRequestCount',
      operation: 'meetingRequest',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'meetingRequestCountFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ meetingRequest: [{ count: number }] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ meetingRequest: [{ count: number }] }>) => response.data.meetingRequest[0].count));
  }

  loadMeetingRequest(params: GqlPayloadParams): Promise<MeetingRequest[]> {
    return buildQueryGql({
      operationName: params.operationName || 'LoadMeetingRequests',
      operation: 'meetingRequest',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'meetingRequestFullFragment',
      authUser: params.authUser,
      magicArgs: params.magicArgs,
    }).then((query) => this
      .query<{ meetingRequest: MeetingRequest[] }, {}>(
        query,
        params.definition.variables,
        false,
      ).then((response: ApolloQueryResult<{ meetingRequest: MeetingRequest[] }>) => response.data.meetingRequest));
  }

  loadCompanyReps(params: GqlPayloadParams): Promise<CompanyUserRole[]> {
    return buildQueryGql({
      operationName: params.operationName || 'LoadCompanyReps',
      operation: 'companyUserRole',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'companyUserRoleBaseFragment',
    }).then((query) => this
      .query<{ companyUserRole: CompanyUserRole[] }, {}>(
        query,
        params.definition.variables,
        false,
      ).then((response: ApolloQueryResult<{ companyUserRole: CompanyUserRole[] }>) => response.data.companyUserRole));
  }
}
