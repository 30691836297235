import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import NodeEntity from '@/models/graphql/NodeEntity';

@Module({ namespaced: true })
export default class NodeStore extends LoadableStore<LoadableState> {
  @Action
  get(uid: string): Promise<NodeEntity | undefined> {
    return this.context.dispatch('WidgetDispatcherStore/runGqlQuery', {
      operationName: 'GetNodeEntity',
      query: `node(uid: "${uid}") {
                uid
                __typename
              }`,
    }, { root: true }).then((response) => {
      if (response && response.node && response.node.length) {
        return response.node[0];
      }
      return undefined;
    });
  }
}
