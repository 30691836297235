import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CommunityUserPreferences from '@/models/graphql/CommunityUserPreferences';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import LoadableStore from '@/store/LoadableStore';
import CommunityUserPreferencesRepository from '@/repositories/CommunityUserPreferencesRepository';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class CommunityUserPreferencesStore extends LoadableStore<LoadableState> {
  private readonly communityUserPreferencesRepository = new CommunityUserPreferencesRepository()

  private get repository(): CommunityUserPreferencesRepository {
    return this.communityUserPreferencesRepository;
  }

  @Action
  loadMyPreferences(): Promise<CommunityUserPreferences | undefined> {
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          value: { uid: this.context.rootState.authUser?.uid },
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
    });
  }

  @Action
  changePreferences(preferences: Partial<CommunityUserPreferences>): Promise<CommunityUserPreferences | undefined> {
    return this.repository.changePreferences({
      definition: buildMutationDefinition([{
        fieldName: 'userUid',
        type: GqlEntityInputType.REQUIRED_ID,
        value: this.context.rootState.authUser?.uid,
      },
      {
        fieldName: 'preferences',
        type: GqlEntityInputType.COMMUNITY_USER_PREFERENCES_INPUT,
        value: preferences,
      },
      ]),
    }).then((response) => {
      if (this.context.rootState.authUser) {
        this.context.rootState.authUser.preferences = response;
      }
      return response;
    });
  }
}
