import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import SalesPackage from '@/models/graphql/SalesPackage';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import SalesPackageRepository from '@/repositories/SalesPackageRepository';
import PackageInformation from '@/models/LocalStorage/PackageInformation';
import { SalesPackageFilter } from '@/graphql/_Filters/SalesPackageFilter';

interface SalesPackageState extends LoadableState {
  allPromisesLoading: boolean;
}

@Module({ namespaced: true })
export default class SalesPackageStore extends LoadableStore<SalesPackageState> {
  allPromisesLoading = false;

  private salePackagesGroup: SalesPackage[] = [] as SalesPackage[];

  private readonly salesPackageRepository = new SalesPackageRepository();

  get salesPackages(): SalesPackage[] {
    return this.salePackagesGroup;
  }

  protected get repository(): SalesPackageRepository {
    return this.salesPackageRepository;
  }

  @Mutation
  setPackages(elements: SalesPackage[]): void {
    this.salePackagesGroup = elements;
  }

  @Mutation
  deletePackage(uidToDelete: string): void {
    this.salePackagesGroup = this.salePackagesGroup.filter((e: SalesPackage) => e.uid !== uidToDelete);
  }

  @Action
  getWithStocks(payload: { filter: SalesPackageFilter; exhibitorId: string }): Promise<SalesPackage | undefined> {
    this.context.commit('load', true);
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.SALES_PACKAGE_FILTER,
          value: payload.filter,
        },
        magicArgs: { filter: `(exhibitorUid: "${payload.exhibitorId}")` },
      }),
      fragmentName: 'salesPackageBaseFragment',
    }).then((response) => {
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  getPackages(payload: { filter: SalesPackageFilter; exhibitorId: string }): Promise<SalesPackage[] | undefined> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.SALES_PACKAGE_FILTER,
          value: payload.filter,
        },
        magicArgs: {
          filter: `(exhibitorUid: "${payload.exhibitorId}")`,
          exhibitorUid: payload.exhibitorId,
        },
      }),
      fragmentName: 'salesPackageBaseWithComponentFragment',
    }).then((response) => {
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  getCartInformation(payload: { localStoragePackage: Array<PackageInformation>; companyUid: string}): Promise<boolean> {
    this.context.commit('load', payload);
    const uidArray: string[] = payload.localStoragePackage.map((packageInfo) => packageInfo.uid);
    return this.repository.filter({
      operationName: 'GetSalesPackages',
      definition: buildQueryDefinition({
        filter: {
          value: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            uid_in: uidArray,
          },
          type: GqlEntityFilterType.SALES_PACKAGE_FILTER,
        },
        magicArgs: { filter: `(exhibitorUid: "${payload.companyUid}")` },
      }),
      fragmentName: 'salesPackageBaseFragment',
    }).then((response: SalesPackage[]): boolean => {
      const newResponse = response.map((el) => {
        const sourceObj = payload.localStoragePackage.find((localPack) => localPack.uid === el.uid);
        if (sourceObj) {
          return {
            ...el,
            localyOrderQuantity: sourceObj.quantity,
            // eslint-disable-next-line no-underscore-dangle
            isOverStock: sourceObj.quantity <= el._stock.quantity || el._stock.quantity === -1,
          };
        }
        return el;
      });
      this.context.commit('setPackages', newResponse);
      this.context.commit('load', false);
      return newResponse.some((item: SalesPackage) => item.isOverStock);
    });
  }
}
