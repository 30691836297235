enum GqlEntityInputType {
  ACTION_RULE_GROUP_INPUT = '_ActionRuleGroupInput',
  ACTION_RULE_INPUT = '_ActionRuleInput',
  AD_IMAGE_INPUT = '_AdImageInput',
  AD_INPUT = '_AdInput',
  ADDRESS_INPUT = '_AddressInput',
  APP_TRANSLATION_INPUT = '_AppTranslationInput',
  ARTICLE_INPUT = '_ArticleInput',
  BOOLEAN = 'Boolean',
  BUSINESS_CARD_INPUT = '_BusinessCardInput',
  CATEGORY_DOMAIN_CONTEXT_INPUT = '_CategoryDomainContextInput',
  CATEGORY_INPUT = '_CategoryInput',
  CHANGE_LOG_INPUT = '_ChangeLogInput',
  CHANNEL_INPUT = '_ChannelInput',
  COMMENT_INPUT = '_CommentInput',
  COMMUNITY_INPUT = '_CommunityInput',
  COMMUNITY_USER_AGENDA_ENTRY_INPUT = '_CommunityUserAgendaEntryInput',
  COMMUNITY_USER_BOOKMARK_INPUT = '_CommunityUserBookmarkInput',
  COMMUNITY_USER_CATEGORY_INPUT = '_CommunityUserCategoryInput',
  COMMUNITY_USER_CONNECTION_INPUT = '_CommunityUserConnectionInput',
  COMMUNITY_USER_FOLLOW_INPUT = '_CommunityUserFollowInput',
  COMMUNITY_USER_INPUT = '_CommunityUserInput',
  COMMUNITY_USER_INTEREST_INPUT = '_CommunityUserInterestInput',
  COMMUNITY_USER_LINK_INPUT = '_CommunityUserLinkInput',
  COMMUNITY_USER_NOTE_INPUT = '_CommunityUserNoteInput',
  COMMUNITY_USER_PREFERENCES_INPUT = 'CommunityUserPreferencesInput!',
  COMMUNITY_USER_PROFILE_ROLE_INPUT = '_CommunityUserProfileRoleInput',
  COMMUNITY_USER_PROJECT_INPUT = '_CommunityUserProjectInput',
  COMMUNITY_USER_PUSH_NOTIFICATION_TOKEN_INPUT = '_CommunityUserPushNotificationTokenInput',
  COMMUNITY_USER_SHARE_INPUT = '_CommunityUserShareInput',
  COMMUNITY_USER_TAG_INPUT = '_CommunityUserTagInput',
  COMMUNITY_USER_TASK_INPUT = '_CommunityUserTaskInput',
  COMMUNITY_USER_URL_INPUT = '_CommunityUserUrlInput',
  COMMUNITY_USER_VISITED_INPUT = '_CommunityUserVisitedInput',
  COMPANY_USER_ROLE_INPUT = '_CompanyUserRoleInput',
  COUNTRY_INPUT = '_CountryInput',
  DATA_TRANSLATION_INPUT = '_DataTranslationInput',
  DEAL_INPUT = '_DealInput',
  EDITION_ADDRESS_MAPPING_INPUT = '_EditionAddressMappingInput',
  EDITION_BUSINESS_CARD_MAPPING_INPUT = '_EditionBusinessCardMappingInput',
  EDITION_CATEGORY_MAPPING_INPUT = '_EditionCategoryMappingInput',
  EDITION_COUNTRY_MAPPING_INPUT = '_EditionCountryMappingInput',
  EDITION_DATA_TRANSLATION_MAPPING_INPUT = '_EditionDataTranslationMappingInput',
  EDITION_DEAL_MAPPING_INPUT = '_EditionDealMappingInput',
  EDITION_EXHIBITOR_CONTACT_MAPPING_INPUT = '_EditionExhibitorContactMappingInput',
  EDITION_EXHIBITOR_MAPPING_INPUT = '_EditionExhibitorMappingInput',
  EDITION_EXHIBITOR_URL_MAPPING_INPUT = '_EditionExhibitorUrlMappingInput',
  EDITION_EXTRA_PROPERTY_CONFIGURATION_MAPPING_INPUT = '_EditionExtraPropertyConfigurationMappingInput',
  EDITION_EXTRA_PROPERTY_MAPPING_INPUT = '_EditionExtraPropertyMappingInput',
  EDITION_EXTRA_PROPERTY_SECTION_MAPPING_INPUT = '_EditionExtraPropertySectionMappingInput',
  EDITION_FILE_RESOURCE_MAPPING_INPUT = '_EditionFileResourceMappingInput',
  EDITION_HANDOUT_MAPPING_INPUT = '_EditionHandoutMappingInput',
  EDITION_LARGE_PRODUCT_IMAGE_MAPPING_INPUT = '_EditionLargeProductImageMappingInput',
  EDITION_LARGE_PRODUCT_MAPPING_INPUT = '_EditionLargeProductMappingInput',
  EDITION_PRODUCT_IMAGE_MAPPING_INPUT = '_EditionProductImageMappingInput',
  EDITION_PRODUCT_MAPPING_INPUT = '_EditionProductMappingInput',
  EDITION_PROFILE_ROLE_MAPPING_INPUT = '_EditionProfileRoleMappingInput',
  EDITION_REGISTRATION_CATEGORY_MAPPING_INPUT = '_EditionRegistrationCategoryMappingInput',
  EDITION_REGISTRATION_MAPPING_INPUT = '_EditionRegistrationMappingInput',
  EDITION_SESSION_MAPPING_INPUT = '_EditionSessionMappingInput',
  EDITION_SPEAKER_MAPPING_INPUT = '_EditionSpeakerMappingInput',
  EDITION_SPEAKER_ROLE_MAPPING_INPUT = '_EditionSpeakerRoleMappingInput',
  EDITION_SUB_EDITION_MAPPING_INPUT = '_EditionSubEditionMappingInput',
  EDITION_SURVEY_MAPPING_INPUT = '_EditionSurveyMappingInput',
  EDITION_SURVEY_QUESTION_CHOICE_MAPPING_INPUT = '_EditionSurveyQuestionChoiceMappingInput',
  EDITION_SURVEY_QUESTION_MAPPING_INPUT = '_EditionSurveyQuestionMappingInput',
  EDITION_SURVEY_USER_ANSWER_MAPPING_INPUT = '_EditionSurveyUserAnswerMappingInput',
  EDITION_USER_INTEREST_MAPPING_INPUT = '_EditionUserInterestMappingInput',
  EDITION_USER_MAPPING_INPUT = '_EditionUserMappingInput',
  EDITION_USER_PROFILE_ROLE_MAPPING_INPUT = '_EditionUserProfileRoleMappingInput',
  EMAIL_LOG_INPUT = '_EmailLogInput',
  EMAIL_SCHEMA_INPUT = '_EmailSchemaInput',
  EMAIL_UNSUBSCRIBE_INPUT = '_EmailUnsubscribeInput',
  EMAIL_WIDGET_INPUT = '_EmailWidgetInput',
  ENTITY_TYPE_INPUT = '_EntityTypeInput',
  EXHIBITOR_CONTACT_INPUT = '_ExhibitorContactInput',
  EXHIBITOR_INPUT = '_ExhibitorInput',
  EXHIBITOR_TAG_INPUT = '_ExhibitorTagInput',
  EXHIBITOR_URL_INPUT = '_ExhibitorUrlInput',
  EXTRA_PROPERTY_CONFIGURATION_INPUT = '_ExtraPropertyConfigurationInput',
  EXTRA_PROPERTY_INPUT = '_ExtraPropertyInput',
  EXTRA_PROPERTY_SECTION_INPUT = '_ExtraPropertySectionInput',
  FEED_ITEM_INPUT = '_FeedItemInput',
  FEED_ITEM_WRAPPER_INPUT = '_FeedItemWrapperInput',
  FEED_POST_INPUT = '_FeedPostInput',
  FEED_POST_LINK_INPUT = '_FeedPostLinkInput',
  FEED_POST_MEDIA_INPUT = '_FeedPostMediaInput',
  FEED_POST_REPORT_INPUT = '_FeedPostReportInput',
  FILE_RESOURCE_INPUT = '_FileResourceInput',
  FEED_STATE_INPUT = '_FeedStateInput',
  GEOZONE_INPUT = '_GeozoneInput',
  HANDOUT_INPUT = '_HandoutInput',
  ID = 'ID',
  ID_ARRAY = '[ID]',
  LARGE_PRODUCT_IMAGE_INPUT = '_LargeProductImageInput',
  LARGE_PRODUCT_INPUT = '_LargeProductInput',
  LOCALE_INPUT = '_LocaleInput',
  MEETING_INPUT = '_MeetingInput',
  MEETING_PARTICIPANT_INPUT = '_MeetingParticipantInput',
  MEETING_REQUEST_INPUT = '_MeetingRequestInput',
  MESSAGE_GROUP_GUEST_TOKEN_INPUT = '_MessageGroupGuestTokenInput',
  MESSAGE_GROUP_INPUT = '_MessageGroupInput',
  MESSAGE_GROUP_STATE_INPUT = '_MessageGroupStateInput',
  MESSAGE_INPUT = '_MessageInput',
  MOBILE_SHOWROOM_CONFIG_INPUT = '_MobileShowroomConfigInput',
  NOTIFICATION_INPUT = '_NotificationInput',
  ORDER_INPUT = '_OrderInput',
  ORGANIZER_INPUT = '_OrganizerInput',
  POLL_ANSWER_INPUT = '_PollAnswerInput',
  POLL_INPUT = '_PollInput',
  POLL_USER_ANSWER_INPUT = '_PollUserAnswerInput',
  PRODUCT_IMAGE_INPUT = '_ProductImageInput',
  PRODUCT_INPUT = '_ProductInput',
  PROFILE_ROLE_INPUT = '_ProfileRoleInput',
  PROMO_SLOT_INPUT = '_PromoSlotInput',
  REQUIRED_ID = 'ID!',
  ROLE_INPUT = '_RoleInput',
  STOCK_INPUT = '[StockInput]',
  SESSION_INPUT = '_SessionInput',
  SPEAKER_INPUT = '_SpeakerInput',
  SPEAKER_ROLE_INPUT = '_SpeakerRoleInput',
  SPONSOR_INPUT = '_SponsorInput',
  SPONSOR_ROLE_INPUT = '_SponsorRoleInput',
  SUB_EDITION_INPUT = '_SubEditionInput',
  SUB_EDITION_USER_ROLE_INPUT = '_SubEditionUserRoleInput',
  SURVEY_INPUT = '_SurveyInput',
  SURVEY_QUESTION_CHOICE_INPUT = '_SurveyQuestionChoiceInput',
  SURVEY_QUESTION_INPUT = '_SurveyQuestionInput',
  SURVEY_USER_ANSWER_INPUT = '_SurveyUserAnswerInput',
  STRING = 'String!',
  TOPIC_INPUT = '_TopicInput',
  UI_MENU_INPUT = '_UiMenuInput',
  UI_MENU_ITEM_INPUT = '_UiMenuItemInput',
  UI_ONBOARDING_INPUT = '_UiOnboardingInput',
  UI_ONBOARDING_WIDGET_INPUT = '_UiOnboardingWidgetInput',
  UI_PAGE_INPUT = '_UiPageInput',
  UI_PAGE_WIDGET_INPUT = '_UiPageWidgetInput',
  VENUE_INPUT = '_VenueInput',
  SESSION_RATING_INPUT = '_SessionRatingInput',
  EXHIBITOR_VIDEO_INPUT = '_ExhibitorVideoInput',
}

export default GqlEntityInputType;
