enum Variant {
  WHITE = 'white',
  GRAY = 'gray',
  LIGHT_GRAY = 'light-gray',
  LIGHTBLUE = 'lightblue',
  BLUE = 'blue',
  LIGHTRED = 'lightred',
  RED = 'red',
  GREEN = 'green',
  BLACK = 'black',
  ORANGE_GRADIENT = 'orange-gradient',
  YELLOW='yellow'
}

export default Variant;
