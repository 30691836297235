


































































































































































































































import CommunityUser from '@/models/graphql/CommunityUser';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { endOfMonth, getUnixTime, startOfMonth } from 'date-fns';
import Component from 'vue-class-component';
import { Getter, namespace, State } from 'vuex-class';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import PickerCalendarComponent from '@/components/calendar/PickerCalendarComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CalendarView from '@/components/toolbox/agenda/CalendarView.vue';
import BadgeComponent from '@/components/BadgeComponent.vue';
import AgendaFilters from '@/components/toolbox/agenda/filters/AgendaFilters.vue';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import ListView from '@/components/toolbox/agenda/ListView.vue';
import DetailView from '@/components/toolbox/agenda/DetailView.vue';
import CreateEditView from '@/components/toolbox/agenda/CreateEditView.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import CookieService from '@/services/CookieService';
import SessionToken from '@/utils/constants/SessionToken';
import downloadAgendaPDFHandler from '@/utils/helpers/DownloadAgendaPDFHandler';
import ClientStorage from '@/utils/ClientStore';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import Community from '@/models/graphql/Community';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ToastActionType from '@/utils/enums/ToastActionType';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import parse from 'date-fns/parse';
import { runMathJax } from '@/utils/helpers/LatexHelper';

const agendaStore = namespace('AgendaStore');
const toastStore = namespace('ToastStore');

@Component({
  name: 'Agenda',
  methods: { useTestDataAttribute },
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    InputSearchComponent,
    ButtonComponent,
    PickerCalendarComponent,
    ListView,
    CalendarView,
    DetailView,
    CreateEditView,
    BadgeComponent,
    AgendaFilters,
  },
})
/* eslint-disable @typescript-eslint/camelcase */
export default class Agenda extends BreakpointWrapper {
  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @agendaStore.Action
  private loadAgendaData!: (payload: object) => Promise<void>;

  @agendaStore.Action
  private searchInAgenda!: (searchQuery: string) => Promise<void>;

  @agendaStore.Getter
  private fetchViewMode!: ViewMode;

  @agendaStore.Getter
  private fetchPrevViewMode!: ViewMode;

  @agendaStore.Getter
  private fetchDayBeingViewed!: string;

  @agendaStore.Getter
  private fetchEventsDays!: string[];

  @agendaStore.Mutation
  private setDayBeingViewed!: (date: string) => void;

  @State
  private selectedTzName!: string;

  @State
  private authUser!: CommunityUser;

  @Getter
  private community!: Community;

  private filterCalendarToggle = false;

  private FeatureKeys = FeatureKeys;

  private ViewMode = ViewMode;

  private searchQuery = '';

  private prevMode: ViewMode = ViewMode.LIST;

  private calendarFilterMobileViewKey = +new Date();

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Mutation
  private initAgenda!: () => void;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @State
  private dateLocale!: Locale;

  private get selectedMonth(): number {
    return getUnixTime(
      startOfMonth(
        parse(
          this.fetchDayBeingViewed,
          'yyyy-MM-dd',
          new Date(),
        ),
      ),
    );
  }

  created(): void {
    let month = DateTimeHelper.getCurrentDateTime();
    if (this.fetchDayBeingViewed) {
      month = parse(
        this.fetchDayBeingViewed,
        'yyyy-MM-dd',
        new Date(),
      );
    }
    this.load({
      start: getUnixTime(startOfMonth(month)),
      end: getUnixTime(endOfMonth(month)),
    });
  }

  beforeDestroy(): void {
    this.initAgenda();
  }

  private load(payload: { start: number; end: number }): void {
    const or = {
      startTimestamp_gte: payload.start,
      startTimestamp_lte: payload.end,
    };
    this.loadAgendaData({
      filter: {
        authUser: this.authUser.uid,
        orMeeting: or,
        orSession: or,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private updated(): void {
    runMathJax();
  }

  private onSelectedDate(dates: Array<string>, save: boolean): void {
    if (save === undefined) {
      ClientStorage.setItem('dayBeingViewed', JSON.stringify(dates));
    }
    this.setViewMode(ViewMode.DAILY_VIEW);
    this.setDayBeingViewed(dates[0]);
  }

  private onSearch(payload: { query: string }): void {
    if (payload.query.length > 0) {
      if (this.fetchViewMode !== ViewMode.SEARCH) {
        this.setViewMode(ViewMode.SEARCH);
      }
    } else {
      this.setViewMode(this.fetchPrevViewMode);
    }

    if (this.prevMode === this.fetchViewMode && this.fetchViewMode === ViewMode.SEARCH) {
      this.setViewMode(this.fetchPrevViewMode);
    }

    this.searchQuery = payload.query;
    this.searchInAgenda(this.searchQuery);
  }

  private clearSearch(): void {
    this.searchQuery = '';
    this.setViewMode(ViewMode.DAILY_VIEW);
  }

  private backToToday(): void {
    this.setViewMode(ViewMode.DAILY_VIEW);
    this.setDayBeingViewed(AgendaStoreHelper.formatDictionaryKey(new Date()));
  }

  private downloadPDF(): void {
    downloadAgendaPDFHandler({
      timezone: this.selectedTzName,
      token: CookieService.getCookie(SessionToken) || '',
      uid: this.authUser.uid,
      locale: ClientStorage.getItem('locale')
          ?.toString()
          .replace('_', '-') || 'en_US',
      fileName: this.community.shortName
        ? `${this.community.longName} - ${this.$t('toolbox.agenda.view.my-schedule')}`
        : `${this.$t('toolbox.agenda.view.my-schedule')}`,
    })
      .then((success) => {
        if (!success) {
          this.addNewAction({ type: ToastActionType.FAILED_TO_EXPORT_AGENDA });
        }
      });
  }
}
