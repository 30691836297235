import Article from '@/models/graphql/Article';
import CommunityUser from '@/models/graphql/CommunityUser';
import CommunityUserLink from '@/models/graphql/CommunityUserLink';
import Deal from '@/models/graphql/Deal';
import Exhibitor from '@/models/graphql/Exhibitor';
import LargeProduct from '@/models/graphql/LargeProduct';
import Product from '@/models/graphql/Product';
import Session from '@/models/graphql/Session';
import LinkedEntityTypes from '@/utils/enums/note/LinkedEntityTypes';
import Speaker from '@/models/graphql/Speaker';

export default class LinkedEntityResourceHelper {
  // eslint-disable-next-line class-methods-use-this
  public static getLinkedEntity(link: CommunityUserLink):
    CommunityUser | Deal | Product | LargeProduct | Session | Article | Exhibitor | Speaker | null {
    const type = this.getLinkedType(link);
    switch (type) {
      case LinkedEntityTypes.USER:
        return CommunityUser.hydrate(link.target as CommunityUser);
      case LinkedEntityTypes.DEAL:
        return Deal.hydrate(link.target as Deal);
      case LinkedEntityTypes.PRODUCT:
        return Product.hydrate(link.target as Product);
      case LinkedEntityTypes.LARGE_PRODUCT:
        return Product.hydrate(link.target as LargeProduct);
      case LinkedEntityTypes.SESSION:
        return Session.hydrate(link.target as Session);
      case LinkedEntityTypes.ARTICLE:
        return Article.hydrate(link.target as Article);
      case LinkedEntityTypes.EXHIBITOR:
        return Exhibitor.hydrate(link.target as Exhibitor);
      case LinkedEntityTypes.SPEAKER:
        return Speaker.hydrate(link.target as Speaker);
      default:
        return null;
    }
  }

  public static findEntityTypeNameByPageEntityTypeCode(code: string): string {
    switch (code) {
      case 'communityUser':
        return 'CommunityUser';
      case 'deal':
        return 'Deal';
      case 'product':
        return 'Product';
      case 'largeProduct':
        return 'LargeProduct';
      case 'session':
        return 'Session';
      case 'article':
        return 'Article';
      case 'exhibitor':
        return 'Exhibitor';
      case 'speaker':
        return 'Speaker';
      default:
        return '';
    }
  }

  public static getLinkedEntityRoute(link: CommunityUserLink): object | null {
    const type = this.getLinkedType(link);
    const linkedEntity = LinkedEntityResourceHelper.getLinkedEntity(link);
    if (linkedEntity) {
      switch (type) {
        case LinkedEntityTypes.USER:
          return { name: 'member-detail', params: { memberId: linkedEntity.uid } };
        case LinkedEntityTypes.DEAL:
          return { name: 'deal-detail', params: { dealId: linkedEntity.uid } };
        case LinkedEntityTypes.PRODUCT:
          return { name: 'product-detail', params: { productId: linkedEntity.uid } };
        case LinkedEntityTypes.LARGE_PRODUCT:
          return { name: 'large-product-detail', params: { largeProductId: linkedEntity.uid } };
        case LinkedEntityTypes.SESSION:
          return { name: 'session-detail', params: { sessionId: linkedEntity.uid } };
        case LinkedEntityTypes.ARTICLE:
          return { name: 'article-detail', params: { articleId: linkedEntity.uid } };
        case LinkedEntityTypes.EXHIBITOR:
          return { name: 'company-detail', params: { companyId: linkedEntity.uid } };
        case LinkedEntityTypes.SPEAKER:
          return { name: 'speaker-detail', params: { speakerId: linkedEntity.uid } };
        default:
          return null;
      }
    }
    return null;
  }

  public static getLinkedType(link: CommunityUserLink): string | undefined {
    let type = link.targetType;
    if (type === null && link.target && link.target?.__typename) {
      type = link.target?.__typename;
    }
    return type;
  }

  public static getLinkTargetName(link: CommunityUserLink): string | undefined {
    const linkedEntity = this.getLinkedEntity(link);
    let name;
    const type = this.getLinkedType(link);
    if (linkedEntity) {
      name = type === LinkedEntityTypes.USER
        ? (linkedEntity as CommunityUser).fullName : linkedEntity.name;
    }
    return name;
  }
}
