import { Action, Module } from 'vuex-module-decorators';
import LoadableStore from '@/store/LoadableStore';
import BaseModel from '@/models/BaseModel';
import ExtraPropertyRepository from '@/repositories/ExtraPropertyRepository';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class ExtraPropertyStore extends LoadableStore<BaseModel> {
  private readonly extraPropertyRepository = new ExtraPropertyRepository();

  protected get repository(): ExtraPropertyRepository {
    return this.extraPropertyRepository;
  }

  @Action
  createExtraPropertiesForConfigurationsAndLargeProduct(payload: {
    largeProductUid: string;
    extraProperties: Array<Record<string, string>>;
  }): Promise<void> {
    if (payload.extraProperties.length === 0) {
      return Promise.resolve();
    }
    return this.repository.createExtraPropertiesForConfigsAndLargeProduct({
      definitions: payload.extraProperties.map((extra, index) => ({
        operation: 'ExtraPropertyCreateForConfigAndLargeProduct',
        gqlDefinition: buildMutationDefinition([
          {
            fieldName: 'largeProduct_LargeProductUid',
            type: 'ID',
            value: payload.largeProductUid,
          },
          {
            fieldName: 'config_ExtraPropertyConfigurationUid',
            type: 'ID',
            value: extra.configUid,
          },
          {
            fieldName: 'entity',
            type: GqlEntityInputType.EXTRA_PROPERTY_INPUT,
            value: { value: extra.value, key: extra.key },
          },
        ]),
        fragmentName: 'extraPropertyFullFragment',
        alias: `create${index}`,
      })),
    }).then(() => Promise.resolve());
  }

  @Action
  updateExtraProperties(extraProperties: Array<Record<string, string>>): Promise<void> {
    if (extraProperties.length === 0) {
      return Promise.resolve();
    }
    return this.repository.updateExtraProperties({
      definitions: extraProperties.map((extra, index) => ({
        operation: 'ExtraPropertyUpdate',
        gqlDefinition: buildMutationDefinition([
          {
            fieldName: 'entity',
            type: GqlEntityInputType.EXTRA_PROPERTY_INPUT,
            value: { uid: extra.propertyUid, value: extra.value, key: extra.key },
          },
        ]),
        fragmentName: 'extraPropertyFullFragment',
        alias: `update${index}`,
      })),
    }).then(() => Promise.resolve());
  }

  @Action
  deleteExtraProperties(extraProperties: Array<string>): Promise<void> {
    if (extraProperties.length === 0) {
      return Promise.resolve();
    }
    return this.repository.deleteExtraProperties({
      definitions: extraProperties.map((extra, index) => ({
        operation: 'ExtraPropertyDelete',
        gqlDefinition: buildMutationDefinition([
          {
            fieldName: 'uid',
            type: 'ID',
            value: extra,
          },
        ]),
        fragmentName: 'extraPropertyBaseFragment',
        alias: `delete${index}`,
      })),
    }).then(() => Promise.resolve());
  }
}
