import GqlFieldType from '@/graphql/_Tools/GqlFieldType';
import GqlFieldMutationInterface from '@/graphql/_Tools/type/GqlFieldMutationInterface';
import GqlDefinition from '@/graphql/_Tools/GqlDefinition';
import { BasicTypes } from '@/utils/types/BasicTypes';
import GqlFieldSubscriptionInterface from '@/graphql/_Tools/type/GqlFieldSubscriptionInterface';

export default class GqlSubscriptionDefinition
  extends GqlDefinition<Record<string, BasicTypes>> {
  params!: GqlFieldType<BasicTypes>[];

  constructor(
    params: GqlFieldSubscriptionInterface<BasicTypes>[],
  ) {
    super();
    this.params = (params || [])
      .map((param) => GqlFieldType.hydrate(param, param.fieldName as string));
  }

  get declaration(): string {
    return this.params
      .reduce((accumulation, item) => `${accumulation} ${item.declaration}`, '');
  }

  get parameter(): string {
    return this.params
      .reduce((accumulation, item) => `${accumulation} ${item.parameter}`, '');
  }

  get variables(): Record<string, BasicTypes> {
    return this.params
      .reduce((accumulation, item) => ({ ...accumulation, ...item.variables }), {});
  }

  // eslint-disable-next-line class-methods-use-this
  get magicArgs(): Record<string, string> | undefined {
    return undefined;
  }
}

export function buildSubscriptionDefinition(
  params: GqlFieldMutationInterface<BasicTypes>[],
): GqlSubscriptionDefinition {
  return new GqlSubscriptionDefinition(params);
}

// Usage

buildSubscriptionDefinition([
  {
    fieldName: '$token',
    type: 'String!',
    value: '',
  },
]);
