const mergeDeep = function (...objects) {
  const isObject = (obj) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
};

const unflatten = function (flattend) {
  if (typeof flattend !== 'object') {
    throw new Error(`Expected an object but got a '${typeof flattend}'.`);
  }

  let result = {};
  let cur;
  let path;
  let keys;
  let
    idx;
  for (const p in flattend) {
    cur = result;
    path = '';
    if (!p.includes('.')) {
      if (!result['']) {
        result = {'': {}};
      }
      Object.assign(result[''], {[p]: flattend[p]});
    } else {
      keys = p.split('.');
      for (let i = 0; i < keys.length; i++) {
        idx = !isNaN(parseInt(keys[i], 10));
        cur = cur[path] || (cur[path] = (idx ? [] : {}));
        path = keys[i];
      }
      cur[path] = flattend[p];
    }
  }
  return result[''];
};

const pathToArray = path =>
  Array.from(path.matchAll(/\[(.*?)\]|[^.[]+/g) || [],
    ([prop, inbrackets]) => inbrackets || prop);

function deepGet(obj, path, defaultVal = null) {
  for (let prop of pathToArray(path)) {
    if (!obj) return null;

    if (!(prop in obj)) return defaultVal;
    obj = obj[prop];
  }
  return obj;
}

const deepEqual = function (x, y) {
  if (x === y) {
    return true;
  } else if (isObject(x) && isObject(y)) {
    if (Object.keys(x).length !== Object.keys(y).length)
      return false;

    for (let prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop]))
          return false;
      } else
        return false;
    }

    return true;
  } else
    return false;
}

const deepSearch = function (obj, targetValue) {
  if (obj === targetValue) {
    return true;
  }

  if (Array.isArray(obj)) {
    return obj.some((element) => deepSearch(element, targetValue));
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj).some((value) => deepSearch(value, targetValue));
  }

  return false;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

export {mergeDeep, unflatten, deepGet, isObject, deepEqual, deepSearch};
