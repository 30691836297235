






































import { Component, Prop } from 'vue-property-decorator';
import Size from '@/utils/enums/Size';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import Variant from '@/utils/enums/Variant';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  components: { FontAwesomeComponent },
  inheritAttrs: false,
})
export default class RecommendationBadgeComponent extends BreakpointWrapper {
  @Prop({ required: false, default: '' })
  private readonly label!: string;

  @Prop({ required: false, default: 'md' })
  private readonly size!: string;

  @Prop({ required: false, default: 'orange-gradient' })
  private readonly variant!: Variant;

  @Prop({
    required: false,
    default: null,
    validator: (obj) => 'iconDef' in obj && 'iconClass' in obj,
  })
  private readonly icon!: Record<string, string>

  private sizeEnum = Size;
}
