import { FetchResult } from '@apollo/client';
import FileResource from '@/models/graphql/FileResource';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import DeleteInterface from '@/repositories/base/DeleteInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';

/* eslint-disable max-len */
export default class FileResourceRepository extends GraphQlRepository<FileResource>
  implements DeleteInterface<FileResource> {
  delete(params: GqlPayloadParams): Promise<FileResource | undefined> {
    return buildMutationGql({
      operationName: 'DeleteFileResource',
      operation: 'FileResourceDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'fileResourceBaseFragment',
    }).then((mutation) => this.mutate<{ FileResourceDelete: FileResource }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FileResourceDelete: FileResource }>) => response.data?.FileResourceDelete));
  }

  createFileResourceForHandout(params: GqlPayloadParams): Promise<FileResource | undefined> {
    return buildMutationGql({
      operationName: 'CreateFileResourceForHandout',
      operation: 'FileResourceCreateForHandoutHandout',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'fileResourceBaseFragment',
    }).then((mutation) => this.mutate<{ FileResourceCreateForHandoutHandout: FileResource }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FileResourceCreateForHandoutHandout: FileResource }>) => response.data?.FileResourceCreateForHandoutHandout));
  }

  createProfileFileResourceForCommunityUser(params: GqlPayloadParams): Promise<FileResource | undefined> {
    return buildMutationGql({
      operationName: 'CreateProfileFileResourceForCommunityUser',
      operation: 'FileResourceCreateForUserPicture',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'fileResourceBaseFragment',
    }).then((mutation) => this.mutate<{ FileResourceCreateForUserPicture: FileResource }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FileResourceCreateForUserPicture: FileResource }>) => response.data?.FileResourceCreateForUserPicture));
  }

  createBannerFileResourceForCommunityUser(params: GqlPayloadParams): Promise<FileResource | undefined> {
    return buildMutationGql({
      operationName: 'CreateBannerFileResourceForCommunityUser',
      operation: 'FileResourceCreateForUserBanner',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'fileResourceBaseFragment',
    }).then((mutation) => this.mutate<{ FileResourceCreateForUserBanner: FileResource }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FileResourceCreateForUserBanner: FileResource }>) => response.data?.FileResourceCreateForUserBanner));
  }

  createLogoFileResourceForExhibitor(params: GqlPayloadParams): Promise<FileResource | undefined> {
    return buildMutationGql({
      operationName: 'CreateLogoFileResourceForExhibitor',
      operation: 'FileResourceCreateForExhibitorLogo',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'fileResourceBaseFragment',
    }).then((mutation) => this.mutate<{ FileResourceCreateForExhibitorLogo: FileResource }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FileResourceCreateForExhibitorLogo: FileResource }>) => response.data?.FileResourceCreateForExhibitorLogo));
  }

  createBannerFileResourceForExhibitor(params: GqlPayloadParams): Promise<FileResource | undefined> {
    return buildMutationGql({
      operationName: 'CreateBannerFileResourceForExhibitor',
      operation: 'FileResourceCreateForExhibitorBanner',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'fileResourceBaseFragment',
    }).then((mutation) => this.mutate<{ FileResourceCreateForExhibitorBanner: FileResource }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FileResourceCreateForExhibitorBanner: FileResource }>) => response.data?.FileResourceCreateForExhibitorBanner));
  }

  createPhotoFileResourceForSpeaker(params: GqlPayloadParams): Promise<FileResource | undefined> {
    return buildMutationGql({
      operationName: 'CreatePhotoFileResourceForSpeaker',
      operation: 'FileResourceCreateForSpeakerPhoto',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'fileResourceBaseFragment',
    }).then((mutation) => this.mutate<{ FileResourceCreateForSpeakerPhoto: FileResource }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FileResourceCreateForSpeakerPhoto: FileResource }>) => response.data?.FileResourceCreateForSpeakerPhoto));
  }

  createBannerFileResourceForSession(params: GqlPayloadParams): Promise<FileResource | undefined> {
    return buildMutationGql({
      operationName: 'CreateBannerFileResourceForSession',
      operation: 'FileResourceCreateForSessionBanner',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'fileResourceBaseFragment',
    }).then((mutation) => this.mutate<{ FileResourceCreateForSessionBanner: FileResource }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FileResourceCreateForSessionBanner: FileResource }>) => response.data?.FileResourceCreateForSessionBanner));
  }

  createDisplayLogoFileResourceForDeal(params: GqlPayloadParams): Promise<FileResource | undefined> {
    return buildMutationGql({
      operationName: 'CreateDisplayLogoFileResourceForDeal',
      operation: 'FileResourceCreateForProductDisplay',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'fileResourceBaseFragment',
    }).then((mutation) => this.mutate<{ FileResourceCreateForProductDisplay: FileResource }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FileResourceCreateForProductDisplay: FileResource }>) => response.data?.FileResourceCreateForProductDisplay));
  }

  createThumbnailFileResourceForExhibitorVideo(params: GqlPayloadParams): Promise<FileResource | undefined> {
    return buildMutationGql({
      operationName: 'createThumbnailFileResourceForExhibitorVideo',
      operation: 'FileResourceCreateForExhibitorVideoThumbnailFileResource',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'fileResourceBaseFragment',
    }).then((mutation) => this.mutate<{ FileResourceCreateForExhibitorVideoThumbnailFileResource: FileResource }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FileResourceCreateForExhibitorVideoThumbnailFileResource: FileResource }>) => response.data?.FileResourceCreateForExhibitorVideoThumbnailFileResource));
  }
}
