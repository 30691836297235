var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({class:[
    'message-box-header p-3 shadows-line-down',
    {'desktop': _vm.isDesktop},
    {'bg-blue-b-3-primary-blue': _vm.unReadCount > 0 && _vm.isClosed && _vm.forceStatus === -1}
  ],on:{"click":function($event){_vm.isDesktop && !_vm.disableAction ? _vm.toggleChat() : ''}}},'div',_vm.useTestDataAttribute('message-box-header'),false),[(!(_vm.isClosed && _vm.forceStatus === -1)
      && ((_vm.localStatus === _vm.MessageBoxActions.NEW_CONVERSATION
        || _vm.localStatus === _vm.MessageBoxActions.MESSAGES)
        || (_vm.localStatus === _vm.MessageBoxActions.MESSAGES && _vm.conversationSummary)))?_c('div',{staticClass:"left d-flex justify-content-start align-items-center"},[(_vm.localStatus === _vm.MessageBoxActions.NEW_CONVERSATION
        || (_vm.localStatus === _vm.MessageBoxActions.MESSAGES && (_vm.localStatus !== _vm.forceStatus || _vm.forceBackArrow)))?_c('button-icon-component',{attrs:{"shape":"round","size":"md","variant":"alt-3"},on:{"on-click":function($event){return _vm.changeStatus(_vm.MessageBoxActions.CONVERSATIONS)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-chevron-left"}})]},proxy:true}],null,false,1930335737)}):_vm._e(),(_vm.forceStatus === _vm.localStatus
        && _vm.localStatus === _vm.MessageBoxActions.MESSAGES
        && _vm.conversationSummary
        && !_vm.isMobile
        && !(_vm.forceTitle && _vm.selectedGroup && _vm.selectedGroup.target
          && _vm.selectedGroup.target.__typename === 'Meeting'))?_c('solo-double-avatar-component',{attrs:{"images":_vm.images,"names":_vm.avatarNames,"type":_vm.selectedGroup
        && _vm.selectedGroup.target
        && _vm.selectedGroup.target.__typename
        ? _vm.selectedGroup.target.__typename
        : _vm.EntityType.USER}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"center d-flex justify-content-start align-items-center w-100"},[((_vm.isClosed && _vm.forceStatus === -1) || _vm.localStatus === _vm.MessageBoxActions.CONVERSATIONS)?_c('h6',{class:['m-0 text-left',
               {'text-white-white-100': _vm.unReadCount > 0 && _vm.isClosed && _vm.forceStatus === -1}]},[_vm._v(" "+_vm._s(_vm.conversationListTitle)+" ")]):_vm._e(),(_vm.title
        && !(_vm.isClosed && _vm.forceStatus === -1)
        && _vm.localStatus === _vm.MessageBoxActions.MESSAGES && _vm.conversationSummary)?_c('h6',{staticClass:"m-0 text-left font-weight-normal ellipsis-1"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(!(_vm.isClosed && _vm.forceStatus === -1)
        && _vm.localStatus === _vm.MessageBoxActions.NEW_CONVERSATION)?_c('h6',{staticClass:"m-0 text-left font-weight-normal ellipsis-1"},[_vm._v(" "+_vm._s(_vm.$t('chat.header.new-conversation'))+" ")]):_vm._e(),(_vm.isClosed && _vm.forceStatus === -1 && _vm.unReadCount > 0)?_c('span',{staticClass:"unread-pill ml-2 label medium label-5 bg-red-r-3-primary-red text-white rounded-pill"},[_vm._v(" "+_vm._s(_vm.unReadCount > 99 ? '+99' : ("" + _vm.unReadCount))+" ")]):_vm._e()]),(!(_vm.isClosed && _vm.forceStatus === -1))?_c('div',{staticClass:"right d-flex justify-content-end align-items-center w-fit column-gap-2"},[([_vm.MessageBoxActions.CONVERSATIONS, _vm.MessageBoxActions.MESSAGES].includes(_vm.localStatus)
        && !_vm.isClosed && _vm.forceStatus === -1
        && !_vm.isMobile)?_c('button-icon-component',{attrs:{"variant":_vm.localStatus === _vm.MessageBoxActions.MESSAGES ? 'alt-3' : 'alt-1',"shape":"round","size":"md"},on:{"on-click":_vm.expandChat},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-arrow-up-right-and-arrow-down-left-from-center"}})]},proxy:true}],null,false,3019035966)}):_vm._e(),(!_vm.disableGroupCreation
        && _vm.localStatus === _vm.MessageBoxActions.CONVERSATIONS
        && !(_vm.chatErrors
          && (_vm.chatErrors.findInternetError
            || _vm.chatErrors.findServerError
            || _vm.chatErrors.checkForCreateConversationError)))?_c('button-icon-component',{attrs:{"shape":"round","size":"md","variant":"alt-1"},on:{"on-click":function($event){return _vm.changeStatus(_vm.MessageBoxActions.NEW_CONVERSATION)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-pen-to-square"}})]},proxy:true}],null,false,3385494181)}):_vm._e(),(_vm.localStatus === _vm.MessageBoxActions.MESSAGES)?_c('message-box-menu-component',{attrs:{"group":_vm.selectedGroup,"menu-config":_vm.menuConfig,"title":_vm.title},on:{"create-meeting":_vm.onCreateMeeting}}):_vm._e(),(!_vm.hideCloseButton
        && (_vm.forceStatus === _vm.localStatus && _vm.localStatus === _vm.MessageBoxActions.MESSAGES))?_c('button-icon-component',{attrs:{"shape":"round","size":"md","variant":"alt-3"},on:{"on-click":function($event){_vm.$bvModal.hide('toolbox-modal'); _vm.toggleChat(true);}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-xmark"}})]},proxy:true}],null,false,353229929)}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }