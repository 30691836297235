import { ApolloQueryResult, FetchResult } from '@apollo/client';
import SurveyUserAnswer from '@/models/graphql/SurveyUserAnswer';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import CreateInterface from '@/repositories/base/CreateInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import ReadInterface from '@/repositories/base/ReadInterface';

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/camelcase */
export default class SurveyUserAnswerRepository extends GraphQlRepository<SurveyUserAnswer>
  implements ReadInterface<SurveyUserAnswer>, CreateInterface<SurveyUserAnswer> {
  get(params: GqlPayloadParams): Promise<SurveyUserAnswer | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetSurveyUserAnswer',
      operation: 'surveyUserAnswer',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'surveyUserAnswerBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ surveyUserAnswer: Array<SurveyUserAnswer> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ surveyUserAnswer: Array<SurveyUserAnswer> }>) => response.data.surveyUserAnswer[0]));
  }

  filter(params: GqlPayloadParams): Promise<SurveyUserAnswer[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterSurveyUserAnswer',
      operation: 'surveyUserAnswer',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'surveyUserAnswerBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ surveyUserAnswer: Array<SurveyUserAnswer> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ surveyUserAnswer: Array<SurveyUserAnswer> }>) => response.data.surveyUserAnswer));
  }

  create(params: GqlPayloadParams): Promise<SurveyUserAnswer | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateSurveyUserAnswer',
      operation: 'SurveyUserAnswerCreateForUserAndSurveyAndChoice',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'surveyUserAnswerBaseFragment',
    }).then((mutation) => this.mutate<{ SurveyUserAnswerCreateForUserAndSurveyAndChoice: SurveyUserAnswer }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ SurveyUserAnswerCreateForUserAndSurveyAndChoice: SurveyUserAnswer }>) => response.data?.SurveyUserAnswerCreateForUserAndSurveyAndChoice));
  }

  surveyUserAnswerSetEntity(params: GqlPayloadParams, operation: string): Promise<SurveyUserAnswer | undefined> {
    return buildMutationGql({
      operationName: operation,
      operation,
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'surveyUserAnswerBaseFragment',
      alias: 'surveyUserAnswer',
    }).then((mutation) => this.mutate<{ surveyUserAnswer: SurveyUserAnswer }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ surveyUserAnswer: SurveyUserAnswer }>) => response.data?.surveyUserAnswer));
  }
}
