
















































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ChoiceParams from '@/utils/types/ChoiceParams';
import InputText from '@/components/InputText.vue';

@Component({
  components: { InputText },
})
export default class SingleSelectComponent extends Vue {
  @Prop({ required: true })
  private readonly name!: string;

  @Prop({ required: true })
  private readonly choices!: ChoiceParams[];

  @Prop({ required: false, default: null })
  private readonly selectedChoice!: ChoiceParams;

  private localSelectedChoice = {
    value: '',
    text: '',
    isOther: false,
  } as unknown as ChoiceParams;

  private textFieldFocused = false;

  mounted(): void {
    if (this.selectedChoice) {
      this.localSelectedChoice = this.selectedChoice;
    }
  }

  @Watch('selectedChoice', { deep: true })
  private selectedChoiceChange(): void {
    if (this.selectedChoice) {
      this.localSelectedChoice = this.selectedChoice;
    }
  }

  private onChange(choice: ChoiceParams): void {
    if (this.localSelectedChoice.value !== choice.value) {
      const finalChoice = choice;
      if (choice.isOther) {
        finalChoice.text = '';
      }
      this.localSelectedChoice = finalChoice;
      this.$emit('on-select', finalChoice);
    }
  }

  private onTextAnswerChange(text: string): void {
    const choice = { ...this.localSelectedChoice };
    choice.text = text;
    this.$emit('on-select', choice);
  }

  private onTextAnswerBlur(text: string): void {
    this.textFieldFocused = false;
    const choice = { ...this.localSelectedChoice };
    choice.text = text;
    this.$emit('on-select', choice);
  }

  private onTextFocus(): void {
    this.textFieldFocused = true;
  }

  private onRadioContainerClick(choice: ChoiceParams): void {
    if (this.localSelectedChoice && this.localSelectedChoice.value === choice.value) {
      if (!this.textFieldFocused) {
        this.localSelectedChoice = {
          value: '',
          text: '',
          isOther: false,
        } as unknown as ChoiceParams;
        this.$emit('on-select', null);
      }
    } else {
      this.onChange(choice);
    }
  }
}
