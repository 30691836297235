import { ApolloQueryResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import AuthProvider from '@/models/graphql/AuthProvider';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import FilterInterface from '@/repositories/base/FilterInterface';

export default class AuthProviderRepository extends GraphQlRepository<AuthProvider>
  implements FilterInterface<AuthProvider> {
  filter(params: GqlPayloadParams): Promise<AuthProvider[]> {
    return buildQueryGql({
      operationName: params.operationName || 'LoadAuthProviders',
      operation: 'loginProviderConfig',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'authProviderBaseFragment',
    }).then((query) => this.query<{ loginProviderConfig: Array<AuthProvider> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ loginProviderConfig: Array<AuthProvider> }>) => response.data.loginProviderConfig));
  }
}
