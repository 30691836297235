import { FetchResult } from '@apollo/client';
import Handout from '@/models/graphql/Handout';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildComposeMutationGql, buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';

export default class HandoutRepository extends GraphQlRepository<Handout>
  implements UpdateInterface<Handout>, DeleteInterface<Handout> {
  update(params: GqlPayloadParams): Promise<Handout | undefined> {
    return buildMutationGql({
      operationName: 'UpdateHandout',
      operation: 'HandoutUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'handoutBaseFragment',
    }).then((mutation) => this.mutate<{ HandoutUpdate: Handout }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ HandoutUpdate: Handout }>) => response.data?.HandoutUpdate));
  }

  createHandoutForExhibitor(params: GqlPayloadParams): Promise<Handout | undefined> {
    return buildMutationGql({
      operationName: 'CreateHandoutForExhibitor',
      operation: 'HandoutCreateForExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'handoutBaseFragment',
    }).then((mutation) => this.mutate<{ HandoutCreateForExhibitor: Handout }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ HandoutCreateForExhibitor: Handout }>) => response.data?.HandoutCreateForExhibitor));
  }

  createHandoutForProduct(params: GqlPayloadParams): Promise<Handout | undefined> {
    return buildMutationGql({
      operationName: 'CreateHandoutForProduct',
      operation: 'HandoutCreateForProduct',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'handoutBaseFragment',
    }).then((mutation) => this.mutate<{ HandoutCreateForProduct: Handout }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ HandoutCreateForProduct: Handout }>) => response.data?.HandoutCreateForProduct));
  }

  createHandoutForLargeProduct(params: GqlPayloadParams): Promise<Handout | undefined> {
    return buildMutationGql({
      operationName: 'CreateHandoutForLargeProduct',
      operation: 'HandoutCreateForLargeProduct',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'handoutBaseFragment',
    }).then((mutation) => this.mutate<{ HandoutCreateForLargeProduct: Handout }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ HandoutCreateForLargeProduct: Handout }>) => response.data?.HandoutCreateForLargeProduct));
  }

  createHandoutForSession(params: GqlPayloadParams): Promise<Handout | undefined> {
    return buildMutationGql({
      operationName: 'CreateHandoutForSession',
      operation: 'HandoutCreateForSession',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'handoutBaseFragment',
    }).then((mutation) => this.mutate<{ HandoutCreateForSession: Handout }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ HandoutCreateForSession: Handout }>) => response.data?.HandoutCreateForSession));
  }

  createHandoutForSpeaker(params: GqlPayloadParams): Promise<Handout | undefined> {
    return buildMutationGql({
      operationName: 'CreateHandoutForSpeaker',
      operation: 'HandoutCreateForSpeaker',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'handoutBaseFragment',
    }).then((mutation) => this.mutate<{ HandoutCreateForSpeaker: Handout }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ HandoutCreateForSpeaker: Handout }>) => response.data?.HandoutCreateForSpeaker));
  }

  delete(params: GqlPayloadParams): Promise<Handout | undefined> {
    return buildMutationGql({
      operationName: 'DeleteHandout',
      operation: 'HandoutDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'handoutBaseFragment',
    }).then((mutation) => this.mutate<{ HandoutDelete: Handout }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ HandoutDelete: Handout }>) => response.data?.HandoutDelete));
  }

  createHandoutsForEntity(params: GqlComposeMutationPayloadParams):
    Promise<Record<string, Handout>[]> {
    return buildComposeMutationGql({
      operationName: 'CreateHandoutsForEntities',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, Handout>[], {}>(mutations))
      .then((response: FetchResult<Record<string, Handout>[]>) => response.data as Record<string, Handout>[]);
  }

  deleteHandouts(params: GqlComposeMutationPayloadParams):
    Promise<Record<string, Handout>> {
    return buildComposeMutationGql({
      operationName: 'DeleteHandouts',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, Handout>, {}>(mutations))
      .then((response: FetchResult<Record<string, Handout>>) => response.data as Record<string, Handout>);
  }
}
