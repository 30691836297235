




























































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import Notification from '@/models/graphql/Notification';
import CommunityUser from '@/models/graphql/CommunityUser';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import NotificationListConnectionItemComponent
  from '@/components/notification/NotificationListConnectionItemComponent.vue';
import NotificationListCompanyItemComponent from '@/components/notification/NotificationListCompanyItemComponent.vue';
import NotificationListMeetingItemComponent from '@/components/notification/NotificationListMeetingItemComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import Meeting from '@/models/graphql/Meeting';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import NotificationListAgendaItemComponent from '@/components/notification/NotificationListAgendaItemComponent.vue';
import NotificationListMeetingRequestItemComponent
  from '@/components/notification/NotificationListMeetingRequestItemComponent.vue';
import NotificationListSessionItemComponent from '@/components/notification/NotificationListSessionItemComponent.vue';
import NotificationCustomItemComponent from '@/components/notification/NotificationCustomItemComponent.vue';
import NotificationFeedbackItemComponent from '@/components/notification/NotificationFeedbackItemComponent.vue';
import NotificationQnaComponent from '@/components/notification/NotificationQnaComponent.vue';
import NotificationCommentPostComponent from '@/components/notification/NotificationCommentPostComponent.vue';
import NotificationActionType from '@/utils/enums/notification/NotificationActionType';
import NotificationLikedPostComponent from '@/components/notification/NotificationLikedPostComponent.vue';
import NotificationCommentRepliedComponent from '@/components/notification/NotificationCommentRepliedComponent.vue';
import { NOTIFICATIONS } from '@/utils/constants/PaginationOffsets';

const notificationStore = namespace('NotificationStore');

@Component({
  components: {
    NotificationLikedPostComponent,
    NotificationCommentPostComponent,
    NotificationCustomItemComponent,
    NotificationFeedbackItemComponent,
    NotificationListSessionItemComponent,
    NotificationListMeetingRequestItemComponent,
    NotificationListAgendaItemComponent,
    NotificationListMeetingItemComponent,
    NotificationListCompanyItemComponent,
    NotificationListConnectionItemComponent,
    NotificationCommentRepliedComponent,
    NotificationQnaComponent,
    LoadingSpinnerComponent,
    IllustrationComponent,
  },
})
export default class NotificationListComponent extends BreakpointWrapper {
  @notificationStore.Action
  private clearAll!: (uid: string) => void;

  @notificationStore.Action
  private paginatedNotifications!: () => Promise<number | null>;

  @notificationStore.Action
  private closeNotificationPanel!: (close?: boolean) => void;

  @notificationStore.Getter('fetchNotifications')
  private notifications!: Notification[];

  @notificationStore.Action('delete')
  private deleteNotification!: (uid: string) => Promise<void>;

  @notificationStore.Action
  private setInteractedUser!: (uid: string) => void;

  @notificationStore.State
  private noMoreDataToLoad!: boolean;

  @Getter
  private authUser!: CommunityUser;

  private IllustrationType = IllustrationType;

  private EntityType = EntityType;

  private NotificationActionType = NotificationActionType;

  private isLoading = true;

  private get lastMeetingNotification(): Record<string, string> {
    const result: Record<string, string> = {};
    this.notifications
      .filter((n) => n.action
            && [NotificationActionType.MEETING_UPDATED, NotificationActionType.MEETING_INVITE]
              .includes(n.action as NotificationActionType))
      .forEach((n) => {
        if (n.triggered
              // eslint-disable-next-line no-underscore-dangle
              && n.triggered.__typename === EntityType.MEETING
              && !(n.triggered.uid in result)) {
          Object.assign(result, { [n.triggered.uid]: n.uid });
        }
        if (n.triggered
              // eslint-disable-next-line no-underscore-dangle
              && n.triggered.__typename === EntityType.MEETING_PARTICIPANT
              && (n.triggered as MeetingParticipant).meeting
              && !(((n.triggered as MeetingParticipant).meeting as Meeting).uid in result)) {
          Object.assign(result, { [((n.triggered as MeetingParticipant).meeting as Meeting).uid]: n.uid });
        }
      });
    this.notifications
      .filter((n) => n.action && n.action === NotificationActionType.MEETING_CANCELLED)
      .forEach((n) => {
        if (n.triggered
              // eslint-disable-next-line no-underscore-dangle
              && n.triggered.__typename === EntityType.MEETING
              && n.triggered.uid in result) {
          delete result[n.triggered.uid];
        }
        if (n.triggered
              // eslint-disable-next-line no-underscore-dangle
              && n.triggered.__typename === EntityType.MEETING_PARTICIPANT
              && (n.triggered as MeetingParticipant).meeting
              && !(((n.triggered as MeetingParticipant).meeting as Meeting).uid in result)) {
          delete result[((n.triggered as MeetingParticipant).meeting as Meeting).uid];
        }
      });
    return result;
  }

  private onInteractedWith(uid: string): void {
    this.setInteractedUser(uid);
  }

  private onDeleteNotification(uid: string): void {
    this.deleteNotification(uid);
  }

  private closeNotification(): void {
    this.closeNotificationPanel(true);
  }

  @Watch('breakpoint.value')
  private preventScroll(): void {
    if (!this.isDesktop) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }

  private infiniteHandler(): void {
    if (this.noMoreDataToLoad) {
      (this.$refs.infiniteLoading as unknown as Vue).$emit('$InfiniteLoading:complete');
      return;
    }
    this.paginatedNotifications()
      .then((newNotificationCount) => {
        if (newNotificationCount) {
          if (newNotificationCount < NOTIFICATIONS) {
            (this.$refs.infiniteLoading as unknown as Vue).$emit('$InfiniteLoading:complete');
            return;
          }
          (this.$refs.infiniteLoading as unknown as Vue).$emit('$InfiniteLoading:loaded');
        } else {
          (this.$refs.infiniteLoading as unknown as Vue).$emit('$InfiniteLoading:complete');
        }
      })
      .catch(() => (this.$refs.infiniteLoading as unknown as Vue).$emit('$InfiniteLoading:complete'))
      .finally(() => {
        this.isLoading = false;
      });
  }
}
