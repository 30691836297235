import { Action, Module, Mutation } from 'vuex-module-decorators';
import Country from '@/models/graphql/Country';
import CountryRepository from '@/repositories/CountryRepository';
import LoadableState from '@/store/states/LoadableState';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import LoadableStore from '@/store/LoadableStore';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';

interface CountryState extends LoadableState {
  countries: Country[];
}

@Module({ namespaced: true })
export default class CountryStore extends LoadableStore<CountryState> {
  countries: Country[] = [] as Country[];

  private readonly countryRepository = new CountryRepository();

  get fetchCountries(): Country[] {
    return this.countries;
  }

  private get repository(): CountryRepository {
    return this.countryRepository;
  }

  @Mutation
  setElements(list: Country[]): void {
    this.countries = list;
  }

  @Action
  loadCountries(filter?: object): Promise<Country[]> {
    let variable = { schemaCode: this.context.rootGetters.communityCode };
    if (filter) {
      variable = {
        ...variable,
        ...filter,
      };
    }
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.COUNTRY_FILTER,
          value: variable,
        },
        orderBy: {
          type: GqlEntityOrderingType.COUNTRY_ORDERING,
          value: ['name_asc'],
        },
      }),
    })
      .then((response) => {
        this.context.commit('setElements', response);
        return response;
      });
  }

  @Action
  addCountryToExhibitor(payload: {
    countryUid: string;
    exhibitorUid: string;
  }): Promise<Country | undefined> {
    return this.repository.addToExhibitor({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.countryUid,
        },
        {
          fieldName: 'exhibitor_ExhibitorUid',
          type: GqlEntityInputType.ID,
          value: payload.exhibitorUid,
        },
      ]),
    });
  }

  @Action
  removeFromExhibitor(payload: {
    countryUid: string;
    exhibitorUid: string;
  }): Promise<Country | undefined> {
    return this.repository.removeFromExhibitor({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.countryUid,
        },
        {
          fieldName: 'exhibitor_ExhibitorUid',
          type: GqlEntityInputType.ID,
          value: payload.exhibitorUid,
        },
      ]),
    });
  }

  @Action
  countryUpdateExhibitor(payload: {
    newCountryId: string;
    addressId: string;
  }): Promise<Country | undefined> {
    return this.repository.linkCountryToAddress({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.addressId,
        },
        {
          fieldName: 'country_CountryUid',
          type: GqlEntityInputType.ID,
          value: payload.newCountryId,
        },
      ]),
    });
  }
}
