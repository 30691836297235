import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';

@Component({
  inheritAttrs: false,
})
export default class BreakpointWrapper extends Vue {
  @Inject({ from: 'breakpoint' })
  protected readonly breakpoint!: { value: BootstrapBreakpointsLabels };

  protected get isLargeDevice(): boolean {
    return this.breakpoint.value === 'lg'
      || this.breakpoint.value === 'xl';
  }

  protected get isSmallDevice(): boolean {
    return this.breakpoint && (this.breakpoint.value === 'sm'
      || this.breakpoint.value === 'xs' || this.breakpoint.value === 'md'
    );
  }

  protected get isDesktop(): boolean {
    return this.breakpoint && this.breakpoint.value === 'xl';
  }

  protected get isTablet(): boolean {
    return this.breakpoint && (this.breakpoint.value === 'lg' || this.breakpoint.value === 'md');
  }

  protected get isMobile(): boolean {
    return this.breakpoint && (this.breakpoint.value === 'sm' || this.breakpoint.value === 'xs');
  }
}
