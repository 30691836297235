


































import { Component, Prop, Vue } from 'vue-property-decorator';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  format, startOfDay, startOfISOWeek, startOfMinute, startOfYear,
} from 'date-fns';
import { State } from 'vuex-class';
import CommunityUserLink from '@/models/graphql/CommunityUserLink';
import PillWidget from '@/components/pill/PillWidget.vue';
import Variant from '@/utils/enums/Variant';
import LinkedEntityResourceHelper from '@/utils/link/LinkedEntityResourceHelper';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Decoration from '@/utils/enums/Decoration';

@Component({
  inheritAttrs: false,
  components: {
    PillWidget,
  },
})
/* eslint-disable max-len */
export default class NotesListItemComponent extends Vue {
  private FileResourceHelper = FileResourceHelper;

  private DecorationEnum = Decoration;

  @State
  private dateLocale!: Locale;

  @Prop({ required: true })
  private readonly uid!: string;

  @Prop({ required: true })
  private readonly content!: string;

  @Prop({ required: true })
  private readonly contentPlain!: string;

  @Prop({ required: true })
  private readonly updatedTime!: string | null;

  @Prop({ required: true })
  private readonly isSelected!: boolean;

  @Prop({ required: false })
  private readonly links!: Array<CommunityUserLink>;

  private variantEnum = Variant;

  private linkedEntityResourceHelper = LinkedEntityResourceHelper;

  private get headline(): string {
    if (this.contentPlain && this.contentPlain.trim().length > 0) {
      return this.contentPlain;
    }
    return `${this.$t('toolbox.notes.notes-container.empty-note')}`;
  }

  private get getDateUpdatedString(): string {
    if (this.updatedTime) {
      const now = DateTimeHelper.getCurrentDateTime();
      const startOfTheMinute = startOfMinute(now);
      const today = startOfDay(now);
      const startOfTheWeek = startOfISOWeek(now);
      const startOfTheYear = startOfYear(now);
      const dateUpdated = new Date(`${this.updatedTime}Z`);

      if (dateUpdated > startOfTheMinute) {
        return `${this.$t('toolbox.notes.notes-container.notes-list.item.time.just-now')}`;
      }

      if (dateUpdated > today) {
        return format(dateUpdated, `${this.$t('app.date.defaultTimeFormat')}`);
      }

      if (dateUpdated > startOfTheWeek) {
        return format(dateUpdated, `${this.$t('app.date.dayOfWeekShort')}` as string, {
          locale: this.dateLocale,
        });
      }

      if (dateUpdated > startOfTheYear) {
        return format(dateUpdated, `${this.$t('app.date.monthDayShort')}` as string, {
          locale: this.dateLocale,
        });
      }

      return format(dateUpdated, `${this.$t('app.date.defaultDateFormat')}` as string, {
        locale: this.dateLocale,
      });
    }
    return '';
  }

  private manageClick(): void {
    this.$emit('open-note');
  }

  // eslint-disable-next-line class-methods-use-this
  private pillImg(link: CommunityUserLink): string {
    const linkedEntity = LinkedEntityResourceHelper.getLinkedEntity(link);
    return linkedEntity ? linkedEntity.mainPicture : '';
  }
}
