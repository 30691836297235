import { ApolloQueryResult, FetchResult } from '@apollo/client';
import Deal from '@/models/graphql/Deal';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';
import {
  buildComposeMutationGql,
  buildMutationGql,
  buildQueryGql,
} from '@/graphql/_Tools/GqlGeneric';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';

/* eslint-disable max-len */
export default class DealRepository extends GraphQlRepository<Deal>
  implements FilterInterface<Deal>, UpdateInterface<Deal>, DeleteInterface<Deal> {
  createDealForExhibitor(params: GqlPayloadParams): Promise<Deal | undefined> {
    return buildMutationGql({
      operationName: 'CreateDealForExhibitor',
      operation: 'DealCreateForExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'dealBaseFragment',
    }).then((mutation) => this.mutate<{ DealCreateForExhibitor: Deal }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ DealCreateForExhibitor: Deal }>) => response.data?.DealCreateForExhibitor));
  }

  update(params: GqlPayloadParams): Promise<Deal | undefined> {
    return buildMutationGql({
      operationName: 'DealUpdate',
      operation: 'DealUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'dealBaseFragment',
    }).then((mutation) => this.mutate<{ DealUpdate: Deal }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ DealUpdate: Deal }>) => response.data?.DealUpdate));
  }

  updateByLocale(params: GqlPayloadParams, locale: string): Promise<Deal | undefined> {
    return buildMutationGql({
      operationName: 'DealUpdateByLocale',
      operation: 'DealUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'dealBaseFragment',
    }).then((mutation) => this.mutate<{ DealUpdate: Deal }, {}>(mutation, {
      ...params.definition.variables,
      _locale: locale,
    })
      .then((response: FetchResult<{ DealUpdate: Deal }>) => response.data?.DealUpdate));
  }

  delete(params: GqlPayloadParams): Promise<Deal | undefined> {
    return buildMutationGql({
      operationName: 'DeleteDeal',
      operation: 'DealDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'dealBaseFragment',
    }).then((mutation) => this.mutate<{ DealDelete: Deal }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ DealDelete: Deal }>) => response.data?.DealDelete));
  }

  addCategoriesToDeal(params: GqlComposeMutationPayloadParams): Promise<Record<string, Deal>[]> {
    return buildComposeMutationGql({
      operationName: 'AddCategoriesToDeal',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, Deal>[], {}>(mutations))
      .then((response: FetchResult<Record<string, Deal>[]>) => response.data as Record<string, Deal>[]);
  }

  dealSetProduct(params: GqlPayloadParams): Promise<Deal | undefined> {
    return buildMutationGql({
      operationName: 'SetDealProduct',
      operation: 'DealSetProduct',
      fragmentName: 'dealBaseFragment',
      gqlDefinition: params.definition as GqlMutationDefinition,
    }).then((mutation) => this.mutate<{ DealSetProduct: Deal }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ DealSetProduct: Deal }>) => response.data?.DealSetProduct));
  }

  dealSetLargeProduct(params: GqlPayloadParams): Promise<Deal | undefined> {
    return buildMutationGql({
      operationName: 'dealSetLargeProduct',
      operation: 'DealSetLargeProduct',
      fragmentName: 'dealBaseFragment',
      gqlDefinition: params.definition as GqlMutationDefinition,
    }).then((mutation) => this.mutate<{ DealSetLargeProduct: Deal }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ DealSetLargeProduct: Deal }>) => response.data?.DealSetLargeProduct));
  }

  removeCategoriesFromDeal(params: GqlComposeMutationPayloadParams): Promise<Record<string, Deal>[]> {
    return buildComposeMutationGql({
      operationName: 'removeCategoriesFromDeal',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, Deal>[], {}>(mutations))
      .then((response: FetchResult<Record<string, Deal>[]>) => response.data as Record<string, Deal>[]);
  }

  updateProductAndLargeProductFromDeal(params: GqlPayloadParams): Promise<Deal | undefined> {
    return buildMutationGql({
      operationName: 'DealRelationSet',
      operation: 'setDealRelation',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'dealBaseFragment',
    }).then((mutation) => this.mutate<{ setDealRelation: Deal }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ setDealRelation: Deal }>) => response.data?.setDealRelation));
  }

  filter(params: GqlPayloadParams): Promise<Deal[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterDeals',
      operation: 'deal',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'dealBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ deal: Array<Deal> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ deal: Array<Deal> }>) => response.data.deal));
  }
}
