import { Action, Module } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import ProfilePrivacyType from '@/utils/enums/ProfilePrivacyType';
import { deepSearch } from '@/utils/ObjectHelpers';
/* eslint-disable @typescript-eslint/camelcase */

@Module({ namespaced: true, stateFactory: true })
export default class MembersWidgetStore extends WidgetBaseStore {
  @Action
  setDataConfigs(): void {
    if (this.widget) {
      const {
        filter, orderBy, type,
      } = JSON.parse(this.widget.payload || '{}');
      let variables = { filter: {} };
      if (filter) {
        variables = { ...JSON.parse(filter || '{}') };
      }
      let filterType = '';
      let orderByType = '';
      let operation = '';
      let fragmentName = '';
      const alias = this.widgetStoreName;
      switch (type) {
        case 'CommunityUser':
          filterType = GqlEntityFilterType.COMMUNITY_USER_FILTER;
          orderByType = GqlEntityOrderingType.COMMUNITY_USER_ORDERING;
          fragmentName = 'communityUserBaseFragment';
          operation = 'communityUser';
          Object.assign(variables.filter, {
            _isNotBlocked: '%authUser%',
            profilePrivacyMode: ProfilePrivacyType.PUBLIC,
          });
          break;
        case 'Channel':
          filterType = GqlEntityFilterType.CHANNEL_FILTER;
          orderByType = GqlEntityOrderingType.CHANNEL_ORDERING;
          fragmentName = 'channelBaseFragment';
          operation = 'channel';
          break;
        default:
          break;
      }
      let order = {};
      if (orderBy
        && orderBy.length > 0
        && !orderBy.includes('default')) {
        order = { orderBy: { value: orderBy.split(','), type: orderByType } };
      }
      const configs = [{
        gqlDefinition: buildQueryDefinition({
          cacheable: !this.context.rootGetters.authUser
            || (!deepSearch(variables.filter, this.context.rootGetters.authUser.uid)
            && !deepSearch(variables.filter, '%authUser%')),
          filter: {
            value: variables.filter,
            type: filterType,
          },
          first: 8,
          ...order,
        }),
        operation,
        fragmentName,
        alias,
      }];

      this.context.commit('setGqlQueryConfig', configs);
      this.context.commit(
        'WidgetDispatcherStore/setGqlQueryConfigs',
        { key: this.widgetStoreName, values: configs },
        { root: true },
      );
    }
  }
}
