import { render, staticRenderFns } from "./NotificationCustomItemComponent.vue?vue&type=template&id=febbdae4&scoped=true&"
import script from "./NotificationCustomItemComponent.vue?vue&type=script&lang=ts&"
export * from "./NotificationCustomItemComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/notification/NotificationListItemComponent.scss?vue&type=style&index=0&id=febbdae4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "febbdae4",
  null
  
)

export default component.exports