import { Action, Module } from 'vuex-module-decorators';
import LoadableStore from '@/store/LoadableStore';
import BaseModel from '@/models/BaseModel';
import ExtraPropertySection from '@/models/graphql/ExtraPropertySection';
import ExtraPropertySectionRepository from '@/repositories/ExtraPropertySectionRepository';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { BasicTypes } from '@/utils/types/BasicTypes';

@Module({ namespaced: true })
export default class ExtraPropertySectionStore extends LoadableStore<BaseModel> {
  private readonly extraPropertySectionRepository = new ExtraPropertySectionRepository();

  protected get repository(): ExtraPropertySectionRepository {
    return this.extraPropertySectionRepository;
  }

  @Action
  loadExtraPropertySections(payload: {filter: object; params: Record<string, BasicTypes>}): Promise<ExtraPropertySection[]> {
    const filter = payload.filter ? {
      filter: {
        type: GqlEntityFilterType.EXTRA_PROPERTY_SECTION_FILTER,
        value: payload.filter,
      },
    } : {};
    return this.repository.filter({
      definition: buildQueryDefinition({
        ...filter,
        orderBy: {
          value: ['displaySequence_asc'],
          type: GqlEntityOrderingType.EXTRA_PROPERTY_SECTION_ORDERING,
        },
      }),
      operationName: 'loadExtraPropertySections',
      fragmentName: 'extraPropertySectionWithoutNmmaFragment',
      magicArgs: payload.params,
    });
  }
}
