

































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import Notification from '@/models/graphql/Notification';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import { RawLocation } from 'vue-router';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
  format,
  fromUnixTime,
} from 'date-fns';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import Exhibitor from '@/models/graphql/Exhibitor';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import NotificationCompany from '@/utils/enums/notification/NotificationCompany';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

const companyUserRoleStore = namespace('CompanyUserRoleStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    AvatarSoloWidget,
  },
})
export default class NotificationListCompanyItemComponent extends Vue {
  @Getter
  private readonly community!: Community;

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @State
  private dateLocale!: Locale;

  @Prop({ required: true })
  private notification!: Notification;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private FeatureKeys = FeatureKeys;

  @companyUserRoleStore.Action
  private updateCompanyUserRoleState!: (payload: {
    uid: string; state: CompanyUserRoleStatusType;
  }) => Promise<CompanyUserRole>;

  private get receiverIsInvited(): boolean {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.COMPANY_USER_ROLE) {
      const companyUserRole = this.notification.triggered as CompanyUserRole;
      return !!(companyUserRole
          && companyUserRole.state
          && companyUserRole.state === CompanyUserRoleStatusType.INVITED);
    }
    return false;
  }

  private get receiverHasAcceptedInvitation(): boolean {
    if (this.notification
        && this.notification.action === NotificationCompany.COMPANY_USERROLE_INVITATION_ACCEPTED
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.COMPANY_USER_ROLE) {
      const companyUserRole = this.notification.triggered as CompanyUserRole;
      return !!(companyUserRole
          && companyUserRole.state
          && companyUserRole.state === CompanyUserRoleStatusType.ACCEPTED);
    }
    return false;
  }

  private get receiverHasDeclinedInvitation(): boolean {
    if (this.notification
        && this.notification.action === NotificationCompany.COMPANY_USERROLE_INVITATION_DECLINED
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.COMPANY_USER_ROLE) {
      const companyUserRole = this.notification.triggered as CompanyUserRole;
      return !!(companyUserRole
          && companyUserRole.state
          && companyUserRole.state === CompanyUserRoleStatusType.DECLINED);
    }
    return false;
  }

  private get receiverIsAccepted(): boolean {
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.EXHIBITOR
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.COMPANY_USER_ROLE) {
      const companyUserRole = this.notification.triggered as CompanyUserRole;
      return !!(companyUserRole
          && companyUserRole.state
          && companyUserRole.state === CompanyUserRoleStatusType.ACCEPTED);
    }
    return false;
  }

  private get receiverIsDeclined(): boolean {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.COMPANY_USER_ROLE) {
      const companyUserRole = this.notification.triggered as CompanyUserRole;
      return !!(companyUserRole
          && companyUserRole.state
          && companyUserRole.state === CompanyUserRoleStatusType.DECLINED);
    }
    return false;
  }

  private get receiverHasBeenCancelledInvitation(): boolean {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.COMPANY_USER_ROLE) {
      const companyUserRole = this.notification.triggered as CompanyUserRole;
      return !!(companyUserRole
          && companyUserRole.user?.uid !== this.authUser.uid
          && companyUserRole.state
          && companyUserRole.state === CompanyUserRoleStatusType.CANCELLED);
    }
    return false;
  }

  private get receiverIsCancelled(): boolean {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.COMPANY_USER_ROLE) {
      const companyUserRole = this.notification.triggered as CompanyUserRole;
      return !!(companyUserRole
          && companyUserRole.state
          && companyUserRole.state === CompanyUserRoleStatusType.CANCELLED);
    }
    return false;
  }

  private get isItForYou(): boolean {
    return !!(this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.COMPANY_USER_ROLE
        && this.notification.notifiedUsers.findIndex((n) => n.uid === this.authUser.uid) > -1);
  }

  private get image(): string | null {
    if (this.notification && this.notification.initiator) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.initiator.__typename === EntityType.EXHIBITOR) {
        const hydrated = Exhibitor.hydrate(this.notification.initiator);
        if (hydrated) {
          return hydrated.mainPicture;
        }
        // eslint-disable-next-line no-underscore-dangle
      } else if (this.notification.initiator.__typename === EntityType.USER) {
        const hydrated = CommunityUser.hydrate(this.notification.initiator);
        if (hydrated) {
          return hydrated.mainPicture;
        }
      }
    }
    return null;
  }

  private get fullName(): string {
    if (this.notification && this.notification.initiator) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.initiator.__typename === EntityType.EXHIBITOR) {
        const hydrated = Exhibitor.hydrate(this.notification.initiator);
        if (hydrated && hydrated.name) {
          return hydrated.name;
        }
        // eslint-disable-next-line no-underscore-dangle
      } else if (this.notification.initiator.__typename === EntityType.USER) {
        const hydrated = CommunityUser.hydrate(this.notification.initiator);
        if (hydrated) {
          return hydrated.fullName;
        }
      }
    }
    return '';
  }

  private get navigateTo(): RawLocation {
    if (this.notification && this.notification.initiator) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.initiator.__typename === EntityType.EXHIBITOR) {
        const hydrated = Exhibitor.hydrate(this.notification.initiator);
        if (hydrated) {
          return {
            name: 'company-detail',
            params: { companyId: hydrated.uid },
          };
        }
        // eslint-disable-next-line no-underscore-dangle
      } else if (this.notification.initiator.__typename === EntityType.USER) {
        const hydrated = CommunityUser.hydrate(this.notification.initiator);
        if (hydrated) {
          return {
            name: 'member-detail',
            params: { memberId: hydrated.uid },
          };
        }
      }
    }
    return '';
  }

  private get content(): string {
    if (this.notification && this.notification.initiator) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.initiator.__typename === EntityType.EXHIBITOR) {
        if (this.receiverIsAccepted) {
          return `${this.$t('my-notification-list-component.company.request-accepted')}`;
        }
        if (this.receiverIsCancelled) {
          return `${this.$t('my-notification-list-component.company.request-cancelled')}`;
        }
        return `${this.$t('my-notification-list-component.company.request-invited')}`;
      }

      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.initiator.__typename === EntityType.USER
          && this.receiverHasAcceptedInvitation
      ) {
        return `${this.$t('my-notification-list-component.company.request-invited-accepted')}`;
      }

      if (this.notification.notifiedUsers
        .findIndex((n) => this.notification.initiator && n.uid === this.notification.initiator.uid) === -1
          && this.receiverHasDeclinedInvitation) {
        return `${this.$t('my-notification-list-component.company.request-invited-declined')}`;
      }

      if (this.notification.notifiedUsers
        .findIndex((n) => this.notification.initiator && n.uid === this.notification.initiator.uid) === -1
          && this.receiverHasBeenCancelledInvitation) {
        return `${this.$t('my-notification-list-component.company.request-invited-canceled')}`;
      }
    }
    return '';
  }

  private get dateReceived(): string {
    const today = DateTimeHelper.getCurrentDateTime();
    const createdTimeDate = fromUnixTime(this.notification.createdTimestamp as number);
    if (differenceInSeconds(today, createdTimeDate) < 60) {
      return `${this.$t('my-notification-list-component.time-ago.now')}`;
    }

    const diffInMinutes = differenceInMinutes(today, createdTimeDate);
    if (diffInMinutes < 60) {
      return `${this.$t('my-notification-list-component.time-ago.minutes', {
        number: diffInMinutes,
      })}`;
    }

    const diffInDays = differenceInDays(today, createdTimeDate);
    if (diffInDays === 1) {
      return ` ${this.$t('my-notification-list-component.time-ago.yesterday')}`;
    }
    if (diffInDays < 1) {
      return `${this.$t('my-notification-list-component.time-ago.hours', {
        number: differenceInHours(today, createdTimeDate),
      })}`;
    }
    if (diffInDays < 7) {
      return `${this.$t('my-notification-list-component.time-ago.days', { number: diffInDays })}`;
    }

    if (differenceInYears(today, createdTimeDate) === 0) {
      return format(createdTimeDate, `${this.$t('app.date.monthDayShort')}`, {
        locale: this.dateLocale,
      });
    }
    return format(createdTimeDate, `${this.$t('app.date.defaultDateFormat')}`, {
      locale: this.dateLocale,
    });
  }

  private closeNotificationModal(): void {
    this.$emit('on-close-notification');
  }

  private onInteractedWith(close = true): void {
    if (close) {
      this.closeNotificationModal();
    }
    if (!this.notification.interactedWith) {
      this.$emit('on-interacted-with', this.notification.uid);
    }
  }

  private onDelete(): void {
    this.$emit('on-delete', this.notification.uid);
  }

  private onAccept(): void {
    this.onInteractedWith(false);
    this.$set(this.notification.triggered as CompanyUserRole, 'state', CompanyUserRoleStatusType.ACCEPTED);
    this.updateCompanyUserRoleState({
      uid: this.notification.triggered?.uid as string,
      state: CompanyUserRoleStatusType.ACCEPTED,
    })
      .then((companyRole) => {
        if (companyRole) {
          const exists = this.authUser.companyRoles?.filter((c) => c.uid === companyRole.uid);
          if (this.authUser && this.authUser.companyRoles && !exists) {
            this.authUser.companyRoles.push(companyRole);
          }
        }

        this.addNewAction(
          { type: ToastActionType.USER_ACCEPT_INVITATION },
        );
      })
      .catch(() => {
        this.$set(this.notification.triggered as CompanyUserRole, 'state', CompanyUserRoleStatusType.INVITED);
      });
  }

  private onDecline(): void {
    this.onInteractedWith(false);
    // eslint-disable-next-line no-underscore-dangle
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.EXHIBITOR
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.COMPANY_USER_ROLE) {
      this.$set(this.notification.triggered as CompanyUserRole, 'state', CompanyUserRoleStatusType.DECLINED);
      this.updateCompanyUserRoleState({
        uid: this.notification.triggered.uid,
        state: CompanyUserRoleStatusType.DECLINED,
      })
        .catch(() => {
          this.$set(this.notification.triggered as CompanyUserRole, 'state', CompanyUserRoleStatusType.INVITED);
        });
    }
  }
}
