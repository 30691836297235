enum DomainContextParams {
  exhibitor = 'exhibitor_detail',
  company = 'company_detail',
  companyCard = 'company_card',
  product = 'product_detail',
  largeProduct = 'large_product_detail',
  session = 'session_detail',
  deal = 'deal_detail',
  speaker = 'speaker_detail',
  communityUser = 'user_detail',
  subEdition = 'event_detail',
  interest = 'interest_exhibitor',
  sessionInterest = 'interest_session',
}

export default DomainContextParams;
