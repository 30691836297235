import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import UiOnboarding from '@/models/graphql/UiOnboarding';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';

/* eslint-disable max-len */
export default class UiOnboardingRepository extends GraphQlRepository<UiOnboarding> {
  filter(params: GqlPayloadParams): Promise<UiOnboarding[]> {
    return buildQueryGql({
      operationName: 'FilterUiOnboarding',
      operation: 'uiOnboarding',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'uiOnboardingFullFragment',
    }).then((query) => this.query<{ uiOnboarding: Array<UiOnboarding> }, {}>(query, params.definition.variables, false))
      .then((response: ApolloQueryResult<{ uiOnboarding: Array<UiOnboarding> }>) => response.data.uiOnboarding);
  }

  addSeenOnBoarding(params: GqlPayloadParams): Promise<UiOnboarding | undefined> {
    return buildMutationGql({
      operationName: 'UiOnboardingAddUsersHaveSeen',
      operation: 'UiOnboardingAddUsersHaveSeen',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'uiOnboardingBaseFragment',
    }).then((mutation) => this.mutate<{ UiOnboardingAddUsersHaveSeen: UiOnboarding }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ UiOnboardingAddUsersHaveSeen: UiOnboarding }>) => response.data?.UiOnboardingAddUsersHaveSeen));
  }
}
