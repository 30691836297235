import { FetchResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import PollAnswer from '@/models/graphql/PollAnswer';
import CreateInterface from '@/repositories/base/CreateInterface';
import PollUserAnswer from '@/models/graphql/PollUserAnswer';
import DeleteInterface from '@/repositories/base/DeleteInterface';

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/camelcase */
export default class PollUserAnswerRepository extends GraphQlRepository<PollUserAnswer>
  implements CreateInterface<PollAnswer>, DeleteInterface<PollUserAnswer> {
  create(params: GqlPayloadParams): Promise<PollUserAnswer | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreatePollUserAnswer',
      operation: 'PollUserAnswerCreateForPollAnswerAndUser',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'pollUserAnswerBaseFragment',
    }).then((mutation) => this.mutate<{ PollUserAnswerCreateForPollAnswerAndUser: PollUserAnswer }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ PollUserAnswerCreateForPollAnswerAndUser: PollUserAnswer }>) => response.data?.PollUserAnswerCreateForPollAnswerAndUser));
  }

  delete(params: GqlPayloadParams): Promise<PollUserAnswer | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeletePollUserAnswer',
      operation: 'PollUserAnswerDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'pollUserAnswerBaseFragment',
    }).then((mutation) => this.mutate<{ PollUserAnswerDelete: PollUserAnswer }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ PollUserAnswerDelete: PollUserAnswer }>) => response.data?.PollUserAnswerDelete));
  }
}
