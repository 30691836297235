import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import { FetchResult } from '@apollo/client';
import FeedPostMedia from '@/models/graphql/FeedPostMedia';
import CreateInterface from '@/repositories/base/CreateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';

export default class FeedPostMediaRepository extends GraphQlRepository<FeedPostMedia>
  implements CreateInterface<FeedPostMedia>, DeleteInterface<FeedPostMedia> {
  create(params: GqlPayloadParams): Promise<FeedPostMedia | undefined> {
    return buildMutationGql({
      operationName: 'FeedPostMediaCreateForPost',
      operation: 'FeedPostMediaCreateForPost',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'feedPostMediaBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ FeedPostMediaCreateForPost: FeedPostMedia }, {}>(mutation, params.definition.variables))
      .then((response: FetchResult<{ FeedPostMediaCreateForPost: FeedPostMedia }>) => response.data?.FeedPostMediaCreateForPost);
  }

  delete(params: GqlPayloadParams): Promise<FeedPostMedia | undefined> {
    return buildMutationGql({
      operationName: 'FeedPostMediaDelete',
      operation: 'FeedPostMediaDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'feedPostMediaUidFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ FeedPostMediaDelete: FeedPostMedia }, {}>(mutation, params.definition.variables))
      .then((response: FetchResult<{ FeedPostMediaDelete: FeedPostMedia }>) => response.data?.FeedPostMediaDelete);
  }
}
