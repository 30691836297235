import BaseModel from '@/models/BaseModel';
import Session from '@/models/graphql/Session';
import ScheduleOfEventType from '@/utils/enums/ScheduleOfEventType';
import Category from '@/models/graphql/Category';

export default class ScheduleEvent extends BaseModel {
  type!: ScheduleOfEventType;

  name: string | null = null;

  description: string| null = null;

  childSessionsOrderBy: string| null = null;

  childSessionsFilter: string| null = null;

  url: string| null = null;

  urlLabel: string| null = null;

  startTime: string| null = null;

  endTime: string| null = null;

  startTimestamp: number| null = null;

  endTimestamp: number| null = null;

  session: Session | null = null;

  childSessions: Session[] = [];

  categories: Category[] = [];

  _isInAgenda: string | null = null;

  static hydrate(item: object): ScheduleEvent {
    const result = new ScheduleEvent();
    Object.assign(result, item);

    return result;
  }
}
