enum GroupType {
  DIRECT = 'DIRECT',
  GROUP = 'GROUP',
  CONNECTION = 'CONNECTION',
  RECONNECTION = 'RECONNECTION',
  DISCONNECTED = 'DISCONNECTED',
  QNA = 'QNA',
}

export default GroupType;
