import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import { Data } from '@/utils/types/WidgetData';
import EntityListHelper from '@/utils/helpers/widgets/EntityListHelper';
import { BasicTypes } from '@/utils/types/BasicTypes';
import WidgetBaseRepository from '@/repositories/widget/WidgetBaseRepository';
import { deepSearch } from '@/utils/ObjectHelpers';

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class EntityListWidgetStore extends WidgetBaseStore {
  private repository = new WidgetBaseRepository();

  @Action
  loadEntityListSearch(entityFts = ''): Promise<Data[]> {
    if (this.gqlQueryConfig.length > 0) {
      const magicArgs: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
      const config: GqlComposeQueryDefinitionParams = { ...this.gqlQueryConfig[0] };
      Object.assign(config.gqlDefinition.filter?.value, { entityFts });
      return this.repository.load({
        params: {
          operationName: 'LoadEntityListSearch',
          definitions: [config],
          magicArgs,
        },
        queries: '',
      })
        .then((response) => EntityListHelper
          .mapData(response[this.widgetStoreName] as unknown as Data[]) as unknown as Data[])
        .catch(() => Promise.resolve([]));
    }
    return Promise.resolve([]);
  }

  @Action
  setDataConfigs(): void {
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    if (this.widget) {
      const {
        amount,
        type,
        filter,
        orderBy,
      } = JSON.parse(this.widget.payload || '{}');
      const first = {};
      if (amount && amount.length > 0) {
        Object.assign(first, { first: parseInt(amount, 10) });
      }
      let filterType = '';
      let orderByType = '';
      let operation = '';
      let fragmentName = '';
      switch (type) {
        case 'communityUser':
          filterType = GqlEntityFilterType.COMMUNITY_USER_FILTER;
          orderByType = GqlEntityOrderingType.COMMUNITY_USER_ORDERING;
          fragmentName = 'communityUserEntityListFragment';
          operation = 'communityUser';
          break;
        case 'exhibitor':
          filterType = GqlEntityFilterType.EXHIBITOR_FILTER;
          orderByType = GqlEntityOrderingType.EXHIBITOR_ORDERING;
          fragmentName = 'exhibitorEntityListFragment';
          operation = 'exhibitor';
          break;
        case 'session':
          filterType = GqlEntityFilterType.SESSION_FILTER;
          orderByType = GqlEntityOrderingType.SESSION_ORDERING;
          fragmentName = 'sessionEntityListFragment';
          operation = 'session';
          break;
        case 'product':
          filterType = GqlEntityFilterType.PRODUCT_FILTER;
          orderByType = GqlEntityOrderingType.PRODUCT_ORDERING;
          fragmentName = 'productEntityListFragment';
          operation = 'product';
          break;
        case 'largeProduct':
          filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
          orderByType = GqlEntityOrderingType.LARGE_PRODUCT_ORDERING;
          fragmentName = 'largeProductEntityListFragment';
          operation = 'largeProduct';
          break;
        case 'deal':
          filterType = GqlEntityFilterType.DEAL_FILTER;
          orderByType = GqlEntityOrderingType.DEAL_ORDERING;
          fragmentName = 'dealEntityListFragment';
          operation = 'deal';
          break;
        case 'speaker':
          filterType = GqlEntityFilterType.SPEAKER_FILTER;
          orderByType = GqlEntityOrderingType.SPEAKER_ORDERING;
          fragmentName = 'speakerEntityListFragment';
          operation = 'speaker';
          break;
        case 'subEdition':
          filterType = GqlEntityFilterType.SUB_EDITION_FILTER;
          orderByType = GqlEntityOrderingType.SUB_EDITION_ORDERING;
          fragmentName = 'subEditionEntityListFragment';
          operation = 'subEdition';
          break;
        case 'handout':
          filterType = GqlEntityFilterType.HANDOUT_FILTER;
          orderByType = GqlEntityOrderingType.HANDOUT_ORDERING;
          fragmentName = 'handoutEntityListFragment';
          operation = 'handout';
          break;
        case 'topic':
          filterType = GqlEntityFilterType.TOPIC_FILTER;
          orderByType = GqlEntityOrderingType.TOPIC_ORDERING;
          fragmentName = 'topicEntityListFragment';
          operation = 'topic';
          break;
        case 'channel':
          filterType = GqlEntityFilterType.CHANNEL_FILTER;
          orderByType = GqlEntityOrderingType.CHANNEL_ORDERING;
          fragmentName = 'channelEntityListFragment';
          operation = 'channel';
          break;
        case 'sponsor':
          filterType = GqlEntityFilterType.SPONSOR_FILTER;
          orderByType = GqlEntityOrderingType.SPONSOR_ORDERING;
          fragmentName = 'sponsorEntityListFragment';
          operation = 'sponsor';
          break;
        default:
          break;
      }
      let order = {};
      if (orderBy && orderBy.length > 0) {
        const orderBySpeakerRoleField = orderBy.split(',')
          .find((field: string) => field.startsWith('_speakerRoleDisplaySequence'));
        if (!orderBy.includes('default') && !orderBySpeakerRoleField) {
          order = {
            orderBy: {
              value: orderBy.split(','),
              type: orderByType,
            },
          };
        }
      }
      const fullFilter = {
        schemaCode: '%community%',
      };
      const tempFilter = JSON.parse(filter || '{}');
      if (Object.keys(tempFilter)
        .includes('filter')) {
        Object.assign(fullFilter, tempFilter.filter);
      } else {
        Object.assign(fullFilter, tempFilter);
      }
      configs.push({
        gqlDefinition: buildQueryDefinition({
          cacheable: !this.context.rootGetters.authUser
            || (!deepSearch(fullFilter, this.context.rootGetters.authUser.uid)
              && !deepSearch(fullFilter, '%authUser%')),
          filter: {
            value: fullFilter,
            type: filterType,
          },
          ...order,
          ...first,
        }),
        operation,
        fragmentName,
        alias: this.widgetStoreName,
      });
    }
    this.context.commit('setGqlQueryConfig', configs);
    this.context.commit(
      'WidgetDispatcherStore/setGqlQueryConfigs',
      {
        key: this.widgetStoreName,
        values: configs,
      },
      { root: true },
    );
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    this.data = EntityListHelper.mapData(data.values) as unknown as Data[];
  }
}
