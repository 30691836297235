





































import Component from 'vue-class-component';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import MeetingRequestWrapper from '@/components/wrappers/MeetingRequestWrapper';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    AvatarSoloWidget,
    StandardModal,
  },
})
export default class CompanyRequestModeratorListModal extends MeetingRequestWrapper {
  @Prop({ required: true })
  private modalId!: string;
}
