import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import SalesPackageLimitRepository from '@/repositories/SalesPackageLimitRepository';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import SlotStrategyEnum from '@/utils/enums/SlotStrategyEnum';
import { BasicTypes } from '@/utils/types/BasicTypes';

@Module({ namespaced: true })
export default class SalesPackageLimitStore extends LoadableStore<LoadableState> {
  private readonly repository = new SalesPackageLimitRepository();

  @Action
  filterSlots(payload: {exhibitorUid: string; code: string; type?: string | null}):
    Promise<{ type: string; limit: number; code: string }[]> {
    this.context.commit('load', true);
    let params: Record<string, BasicTypes> = { code: payload.code };
    if (payload.type) {
      params = { code: payload.code, type: payload.type };
    }
    return this.repository.filter({
      definition: buildQueryDefinition(
        {
          filter: {
            value: { uid: payload.exhibitorUid },
            type: GqlEntityFilterType.EXHIBITOR_FILTER,
          },
        },
      ),
      params,
    }).then((response) => {
      if (!response || response.length === 0) {
        return [];
      }

      // Sum of Purchased and Default Packages (GenericSlot)
      if (response[0].strategy === SlotStrategyEnum.GENERIC_SLOT) {
        return [{
          type: SlotStrategyEnum.GENERIC_SLOT as string,
          code: response[0].code || '',
          limit: response.map((s) => s.quantity || 0).reduce((a, b) => a + b),
        }];
      }

      // Sum of Purchased and Default Packages by types(HandoutSlot, CategorySlot)
      if ([SlotStrategyEnum.HANDOUT_SLOT,
        SlotStrategyEnum.CATEGORY_SLOT,
        SlotStrategyEnum.FIELD_SLOT,
        SlotStrategyEnum.EXTRA_PROPERTY_SLOT,
        SlotStrategyEnum.BOOLEAN_SLOT,
      ].includes(response[0].strategy as SlotStrategyEnum)) {
        const limits: { type: string; code: string; limit: number }[] = [];
        response.forEach((s) => {
          limits.push({
            type: s.type || '',
            code: s.code || '',
            limit: s.quantity || 0,
          });
        });
        return limits;
      }

      // Max value from Purchased or Default Packages (DescriptionLength)
      if (response[0].strategy === SlotStrategyEnum.DESCRIPTION_LENGTH) {
        return [{
          type: SlotStrategyEnum.DESCRIPTION_LENGTH as string,
          code: response[0].code || '',
          limit: Math.max(...response.map((s) => s.quantity || 0)),
        }];
      }

      // Zero or 1 for Purchased or Default Packages for component without quantity (FeaturedExhibitor, LogoSlot, GenericFeatureSlot, BannerSlot)
      if ([SlotStrategyEnum.FEATURED_EXHIBITOR,
        SlotStrategyEnum.LOGO_SLOT,
        SlotStrategyEnum.BANNER_SLOT,
        SlotStrategyEnum.GENERIC_FEATURE_SLOT,
        SlotStrategyEnum.VIDEO_PRESENTATION_SLOT,
      ].includes(response[0].strategy as SlotStrategyEnum)) {
        return [{
          type: response[0].strategy || '',
          code: response[0].code || '',
          limit: response.map((s) => s.quantity || 0).reduce((a, b) => a + b),
        }];
      }

      return [];
    })
      .finally(() => {
        this.context.commit('load', false);
      });
  }
}
