







































import { Component, Prop, Vue } from 'vue-property-decorator';
import IllustrationType from '@/utils/enums/IllustrationType';

@Component({
  name: 'IllustrationComponent',
})
export default class IllustrationComponent extends Vue {
  @Prop({ required: true })
  private readonly illustrationType!: IllustrationType;

  @Prop({ required: false, default: '' })
  private readonly title!: string;

  @Prop({ required: false, default: '' })
  private readonly message!: string;

  @Prop({ required: false, default: '' })
  private readonly actionText!: string;

  @Prop({ required: false, default: 'lg' })
  private readonly size!: string;
}
