import ExhibitorContact from '@/models/graphql/ExhibitorContact';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import WriteInterface from '@/repositories/base/WriteInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable max-len */
export default class ExhibitorContactRepository extends GraphQlRepository<ExhibitorContact> implements FilterInterface<ExhibitorContact>, WriteInterface<ExhibitorContact> {
  filter(params: GqlPayloadParams): Promise<ExhibitorContact[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterExhibitorContact',
      operation: 'exhibitorContact',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'exhibitorContactBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ exhibitorContact: Array<ExhibitorContact> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ exhibitorContact: Array<ExhibitorContact> }>) => response.data.exhibitorContact));
  }

  create(params: GqlPayloadParams): Promise<ExhibitorContact | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'ExhibitorContactCreate',
      operation: 'ExhibitorContactCreateForExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorContactBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorContactCreateForExhibitor: ExhibitorContact }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorContactCreateForExhibitor: ExhibitorContact }>) => response.data?.ExhibitorContactCreateForExhibitor));
  }

  delete(params: GqlPayloadParams): Promise<ExhibitorContact | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'ExhibitorContactDelete',
      operation: 'ExhibitorContactDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorContactBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorContactDelete: ExhibitorContact }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorContactDelete: ExhibitorContact }>) => response.data?.ExhibitorContactDelete));
  }

  update(params: GqlPayloadParams): Promise<ExhibitorContact | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'ExhibitorContactUpdate',
      operation: 'ExhibitorContactUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorContactBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorContactUpdate: ExhibitorContact }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorContactUpdate: ExhibitorContact }>) => response.data?.ExhibitorContactUpdate));
  }
}
