





























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  data(): object {
    return {
      email: '',
    };
  },
  components: { FontAwesomeComponent, AvatarSoloWidget },
})
export default class MemberInvitePermissions extends Vue {
  @Getter
  private community!: Community;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: false, default: null })
  private readonly user!: CommunityUser;

  @Prop({ required: false, default: '' })
  private readonly exhibitorId!: string;

  @Prop({ required: false, default: false })
  private readonly saveLoading!: boolean;

  private FileResourceHelper = FileResourceHelper;

  private selectedRole = 'standard';

  private get isMobile(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'sm' || this.currentBootstrapBreakpoint === 'xs');
  }

  private onRoleChange(role: string): void {
    if (!this.saveLoading) {
      this.selectedRole = role;
      this.$emit('role-change', role);
    }
  }
}
