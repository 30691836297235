














































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import useTestDataAttribute from '@/utils/TestDataAttribute';

@Component({
  methods: { useTestDataAttribute },
  components: {
    ButtonIconComponent, RecommendationBadgeComponent, FontAwesomeComponent,
  },
  inheritAttrs: false,
})
export default class AvatarSoloWidget extends Vue {
  @Prop({ required: false, default: false })
  readonly showEditButton!: boolean;

  @Prop({ required: false, default: '136' })
  private size!: string;

  @Prop({ required: false, default: false })
  private square!: boolean;

  @Prop({ required: false, default: null })
  private src!: string | null;

  @Prop({ required: false, default: null })
  private defaultIcon!: string;

  @Prop({ required: false, default: 'icon-32' })
  private iconContainerClasses!: string;

  @Prop({ required: false, default: 'icon-32 text-neutral-n-6-label' })
  private iconClasses!: string;

  @Prop({ required: false, default: '' })
  private iconMainContainerClasses!: string;

  @Prop({ required: false, default: undefined })
  private lastname!: string | null;

  @Prop({ required: false, default: undefined })
  private firstname!: string | null;

  @Prop({ required: false, default: false })
  private isAvailable!: boolean;

  @Prop({ required: false, default: null })
  private tooltipText!: string;

  @Prop({ required: false, default: false })
  private withBorders!: boolean;

  @Prop({ required: false, default: null })
  private borderColorClass!: string;

  @Prop({ required: false, default: null })
  private borderColor!: string;

  @Prop({ required: false, default: false })
  private recommendedForYou!: boolean;

  @Prop({ required: false, default: false })
  private opaque!: boolean;

  @Prop({ required: false, default: false })
  private isOnline!: boolean;

  @Prop({ required: false, default: false })
  private cover!: boolean;

  @Prop({ required: false, default: 'avatar' })
  private dataCy: string | undefined;

  private source = this.src;

  @Watch('src')
  private updateSource(): void {
    this.source = this.src;
  }
}
