


















































































































































































import { Component, Prop } from 'vue-property-decorator';
import EntityType from '@/utils/enums/EntityType';
import { format } from 'date-fns-tz';
import { Getter, namespace, State } from 'vuex-class';
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import MeetingParticipantState from '@/utils/enums/MeetingParticipantState';
import ButtonComponent from '@/components/ButtonComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import Event from '@/utils/types/Event';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import ToastActionParams from '@/utils/types/ToastActionParams';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import { runMathJax } from '@/utils/helpers/LatexHelper';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';

const agendaStore = namespace('AgendaStore');
const communityUserAgendaEntryStore = namespace('CommunityUserAgendaEntryStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    ButtonComponent,
    AvatarSoloWidget,
    FontAwesomeComponent,
    ButtonIconComponent,
  },
})
export default class ListItem extends BreakpointWrapper {
  @communityUserAgendaEntryStore.Action
  removeFromAgenda!: (
    payload: CommunityUserAgendaEntryFilter
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  protected FeatureKeys = FeatureKeys;

  @Prop({ required: false, default: {} })
  private readonly event!: Event;

  @Prop({ required: false, default: 'lg' })
  private readonly size!: string;

  @Getter
  private authUser!: CommunityUser;

  @Getter
  private community!: Community;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzAbbreviation!: string;

  @agendaStore.Mutation
  private setDayBeingViewed!: (date: string) => void;

  @agendaStore.Action
  private updateMeetingParticipantState!: (payload: {
    event: Event;
    meetingParticipantUid: string;
    state: string;
  }) => Promise<void>;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Mutation
  private setEventViewed!: (e: Event) => void;

  @agendaStore.Mutation
  private setEventEdited!: (e: Event) => void;

  @agendaStore.Getter
  private fetchViewMode!: ViewMode;

  @agendaStore.Action
  private updateMeeting!: (payload: {
    meeting: Partial<Event>;
  }) => Promise<void>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private ViewMode = ViewMode;

  private isHovered = false;

  private FileResourceHelper = FileResourceHelper;

  private MeetingParticipantState = MeetingParticipantState;

  private currentMeetingParticipantUid = '';

  private sessionDayTitle = '';

  private EntityType = EntityType;

  private get type(): string {
    return this.event.entityType;
  }

  private get isInvited(): boolean {
    return this.participantState === MeetingParticipantState.INVITED;
  }

  private get isAccepted(): boolean {
    return this.participantState === MeetingParticipantState.ACCEPTED;
  }

  private get isDeclined(): boolean {
    return this.participantState === MeetingParticipantState.DECLINED;
  }

  private get participantsList(): MeetingParticipant[] {
    if (this.event.participants
    && this.event.participants.length > 0) {
      const p = this.event.participants as MeetingParticipant[];
      return [
        {
          uid: this.event.creator ? this.event.creator.uid : '',
          state: MeetingParticipantState.ACCEPTED,
          user: this.event.creator,
        } as MeetingParticipant,
        ...p,
      ];
    }
    return [];
  }

  private get fullFormattedDate(): string {
    if (this.event.tzStartTime && this.event.tzEndTime) {
      this.currentDayTitle();
      const zone = `(${this.selectedTzAbbreviation})`;
      const hoursDiff = differenceInHours(
        this.event.tzEndTime,
        this.event.tzStartTime,
      );
      const hoursDiffValue = hoursDiff > 0 && hoursDiff < 24 ? `— (${hoursDiff}h)` : '';
      if (this.breakpoint.value === 'lg' || this.breakpoint.value === 'xl') {
        if (this.daysDifference() >= 1) {
          // eslint-disable-next-line max-len
          return `${format(this.event.tzStartTime, this.$t('app.date.defaultDateFormat') as string, { locale: this.dateLocale })}
          ${this.$t('app.date.dateTimeSeparator')}
          ${format(this.event.tzStartTime, this.$t('app.date.defaultTimeFormat') as string,
    { locale: this.dateLocale })}
          ${this.$t('app.date.dateTimeSeparator')}
       ${format(new Date(this.event.tzEndTime), this.$t('app.date.defaultDateFormat') as string,
    { locale: this.dateLocale })}
       ${format(new Date(this.event.tzEndTime), this.$t('app.date.defaultTimeFormat') as string,
    { locale: this.dateLocale })} ${zone}`;
        }
        return `${format(this.event.tzStartTime, this.$t('app.date.defaultDateFormat') as string,
          { locale: this.dateLocale })}
        ${this.$t('app.date.dateTimeSeparator')}
        ${format(this.event.tzStartTime, this.$t('app.date.defaultTimeFormat') as string,
    { locale: this.dateLocale })}
        ${this.$t('app.date.dateTimeSeparator')}
       ${format(
    new Date(this.event.tzEndTime),
          this.$t('app.date.defaultTimeFormat') as string,
          { locale: this.dateLocale },
  )}
       ${zone} ${hoursDiffValue}`;
      }
      if (this.daysDifference() >= 1) {
        return `${format(
          this.event.tzStartTime,
          this.$t('app.date.defaultDateFormat') as string,
          { locale: this.dateLocale },
        )}
        ${this.$t('app.date.dateTimeSeparator')}
        ${format(
    this.event.tzStartTime,
          this.$t('app.date.defaultTimeFormat') as string,
          { locale: this.dateLocale },
  )} ${zone}`;
      }
      return `${format(this.event.tzStartTime, this.$t('app.date.defaultDateFormat') as string,
        { locale: this.dateLocale })}`;
    }
    return '';
  }

  private get fullFormattedTime(): string {
    if (this.event.tzStartTime && this.event.tzEndTime) {
      const zone = `(${this.selectedTzAbbreviation})`;
      if (this.daysDifference() >= 1) {
        return `${format(new Date(this.event.tzEndTime), this.$t('app.date.defaultDateFormat') as string,
          { locale: this.dateLocale })}
        ${this.$t('app.date.dateTimeSeparator')}
        ${format(new Date(this.event.tzEndTime), this.$t('app.date.defaultTimeFormat') as string,
    { locale: this.dateLocale })}
        ${zone}`;
      }
      const hoursDiff = differenceInHours(
        new Date(this.event.tzEndTime),
        this.event.tzStartTime,
      );
      const minutesDiff = differenceInMinutes(
        new Date(this.event.tzEndTime),
        this.event.tzStartTime,
      ) - (hoursDiff * 60);
      const minutesDiffValue = minutesDiff ? `${minutesDiff}` : '';
      const hoursDiffValue = hoursDiff > 0 && hoursDiff < 24 ? `${hoursDiff}` : '';
      let timeLeft = '';
      if (hoursDiffValue !== '') {
        if (minutesDiffValue !== '') {
          timeLeft = `${this.$t('app.date.dateTimeSeparator')} (${hoursDiffValue}:${minutesDiffValue}h)`;
        } else {
          timeLeft = `${this.$t('app.date.dateTimeSeparator')} (${hoursDiffValue}h)`;
        }
      } else if (minutesDiffValue !== '') {
        timeLeft = `${this.$t('app.date.dateTimeSeparator')} (${minutesDiffValue}min)`;
      }
      return `${format(this.event.tzStartTime, this.$t('app.date.defaultTimeFormat') as string,
        { locale: this.dateLocale })}
      ${this.$t('app.date.dateTimeSeparator')}
       ${format(
    new Date(this.event.tzEndTime),
        this.$t('app.date.defaultTimeFormat') as string,
        { locale: this.dateLocale },
  )}
       ${zone} ${timeLeft}`;
    }
    return '';
  }

  private get startTime(): string {
    if (this.event.tzStartTime) {
      return `${format(this.event.tzStartTime, this.$t('app.date.defaultTimeFormat') as string,
        { locale: this.dateLocale })}`;
    }
    return '';
  }

  private get isCreator(): boolean {
    if (this.event.creator) {
      return this.event.creator.uid === this.authUser.uid;
    }
    return false;
  }

  private get participantState(): MeetingParticipantState | null | undefined {
    if (!this.isCreator && this.event.participants) {
      const participant = (this.event.participants as Array<MeetingParticipant>)
        .find((meetingParticipant: MeetingParticipant) => {
          if (meetingParticipant && meetingParticipant.user) {
            return meetingParticipant.user.uid === this.authUser.uid;
          }
          return false;
        });
      if (participant) {
        this.currentMeetingParticipantUid = participant.uid;
        return participant.state as MeetingParticipantState;
      }
    }
    this.currentMeetingParticipantUid = '';
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  private updated(): void {
    runMathJax();
  }

  private daysDifference(): number {
    if (this.event.tzStartTime && this.event.tzEndTime) {
      return differenceInDays(
        this.event.tzEndTime,
        this.event.tzStartTime,
      );
    }
    return 0;
  }

  private currentDayTitle(): void {
    if (this.daysDifference() >= 1 && this.event.tzStartTime) {
      const currentDay = differenceInDays(
        DateTimeHelper.getCurrentDateTime(),
        this.event.tzStartTime,
      ) + 1;
      const day = this.$t('toolbox.agenda.day');
      const of = this.$t('toolbox.agenda.of');
      this.sessionDayTitle = `(${day} ${currentDay} ${of} ${this.daysDifference()})`;
    }
  }

  private onRemoveFromAgendaClick(): void {
    if ([EntityType.SESSION, EntityType.SCHEDULE_EVENT].includes(this.event.entityType)) {
      this.removeFromAgenda({ event: this.event, uid: this.event.isInAgenda as string });
    }
    if (this.event.entityType === EntityType.MEETING) {
      this.updateMeeting({
        meeting: {
          ...this.event,
          startTime: this.event.startTime.replace('Z', ''),
          endTime: this.event.endTime.replace('Z', ''),
          isCancelled: true,
        },
      });
    }
    this.$logger.logMatomoStats(
      this.authUser,
        this.community.code as string,
        this.event.entityType,
        StatLoggerActions.REMOVE_FROM_AGENDA,
        'removeAppointment',
        -1,
        this.event.entityId,
        StatLoggerCategories.REMOVE,
        this.$i18n.locale,
    );
    this.addNewAction({
      message: `${this.$t('toolbox.agenda.create-edit.removed-from-agenda')}`,
      delay: 3500,
    });
  }

  private onUpdateParticipantState(state: MeetingParticipantState): void {
    if (!this.isCreator && this.participantState !== state) {
      this.updateMeetingParticipantState({
        event: this.event,
        meetingParticipantUid: this.currentMeetingParticipantUid,
        state,
      });
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        this.event.entityType,
        state === MeetingParticipantState.ACCEPTED
          ? StatLoggerActions.ACCEPT_TO_AGENDA
          : StatLoggerActions.REJECT_FROM_AGENDA,
        state === MeetingParticipantState.ACCEPTED
          ? 'acceptAppointment'
          : 'rejectAppointment',
        -1,
        this.event.entityId,
        state === MeetingParticipantState.ACCEPTED
          ? StatLoggerCategories.ACCEPT
          : StatLoggerCategories.REJECT,
        this.$i18n.locale,
      );
    }
  }

  private onEventClick(): void {
    this.setViewMode(ViewMode.DETAIL);
    this.setEventViewed(this.event);
  }

  private onEditClick(): void {
    this.setViewMode(ViewMode.EDIT);
    this.setDayBeingViewed(AgendaStoreHelper.formatDictionaryKey(this.event.tzStartTime));
    this.setEventEdited(this.event);
  }
}
