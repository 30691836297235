import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import GraphqlQueryHelper from '@/utils/helpers/GraphqlQueryHelper';
import { deepSearch } from '@/utils/ObjectHelpers';
import RootState from '@/store/states/RootState';
import { Data } from '@/utils/types/WidgetData';
import ProfileRoleRelation from '@/utils/enums/ProfileRoleRelation';

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class PillListWidgetStore extends WidgetBaseStore {
  public highlightPills: Array<Data> = [];

  @Action
  async setDataConfigs(): Promise<void> {
    if (this.widget) {
      const {
        filter,
        amount,
        orderBy,
        type,
        highlightUserProfile,
        highlightUserInterests,
      } = JSON.parse(this.widget.payload || '{}');
      let variables = JSON.parse(filter || '{}');
      if (variables && !variables.filter) {
        Object.assign(variables, { filter: {} });
      }
      let order = null;
      if (orderBy && orderBy.length > 0 && orderBy !== 'default') {
        order = JSON.stringify(orderBy.split(','))
          .replaceAll('"', '');
      }
      if (amount && amount > 0) {
        Object.assign(variables, { first: parseInt(amount, 10) });
      }
      variables = JSON.stringify(variables)
        .replace(/"([^"]+)":/g, '$1:')
        .trim()
        .slice(1, -1);
      let pillsQuery = `${type}%parameters% { uid name }`
        .replaceAll('%parameters%',
          (variables && variables.length > 0) || (order && order.length > 0)
            ? `(${order && order.length > 0 ? `orderBy: [${orderBy}]` : ''} ${variables})`
            : '');
      if (!pillsQuery.includes('_cachable')) {
        pillsQuery = GraphqlQueryHelper.appendArgumentToQuery(
          pillsQuery,
          '_cachable',
          'BooleanValue',
          !deepSearch(GraphqlQueryHelper.extractFilterFromQuery(pillsQuery), '%authUser%'),
        );
      }
      pillsQuery = `${this.widgetStoreName}_pills: ${pillsQuery}`;

      if (type === 'profileRole' && (highlightUserInterests || (highlightUserProfile && highlightUserProfile !== 'NONE'))) {
        const filterValue = highlightUserInterests ? ProfileRoleRelation.I_WANT : highlightUserProfile;
        pillsQuery += ` ${this.widgetStoreName}_interest: communityUserProfileRole(
                                                     _cachable: false
                                                     filter: {
                                                       relation: "${filterValue}"
                                                       user: { uid: %authUser% }
                                                     }
                                                     ) {
                                                     uid
                                                         schemaCode
                                                         relation
                                                         profileRole {
                                                           uid
                                                            name
                                                         }
                                                     }`;
      }

      this.context.commit(
        'WidgetDispatcherStore/setGqlQuery',
        {
          key: this.widgetStoreName,
          value: pillsQuery,
        },
        { root: true },
      );
      this.context.commit(
        'setGqlQuery',
        pillsQuery,
      );
    }
  }

  @Mutation
  setData({
    values,
    key,
  }: {
    values: [];
    key: string;
    rootState?: RootState;
  }): void {
    if (key === `${this.widgetStoreName}_pills`) {
      this.data = values;
    } else {
      this.highlightPills = values;
    }
  }

  @Mutation
  setGqlQuery(query: string): void {
    this.query += query;
  }

  @Mutation
  mapper(): void {
    if (this.widget) {
      this.mapping = {
        ...this.payload,
        pills: this.data,
        highlightPills: (this.highlightPills || []).map((i) => i.profileRole),
      };
    }
  }
}
