import { ApolloQueryResult } from '@apollo/client';
import Dashboard from '@/models/Dashboard';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildComposeQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlComposeQueryPayloadParams from '@/utils/types/gql/GqlComposeQueryPayloadParams';

export default class DashboardRepository extends GraphQlRepository<Dashboard> {
  filter(params: GqlComposeQueryPayloadParams): Promise<Record<string, Array<{ _count: number }>>> {
    return buildComposeQueryGql({
      operationName: params.operationName || 'LoadDashboardStats',
      definitions: params.definitions,
      authUser: params.authUser,
    }).then((query) => this.query<Record<string, Array<{ _count: number }>>, {}>(query, {}, false)
      .then((response: ApolloQueryResult<Record<string, Array<{ _count: number }>>>) => response.data));
  }
}
