import { ApolloQueryResult, FetchResult } from '@apollo/client';
import Exhibitor from '@/models/graphql/Exhibitor';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import {
  buildComposeMutationGql,
  buildMutationGql,
  buildQueryGql,
} from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GetterInterface from '@/repositories/base/GetterInterface';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';

/* eslint-disable max-len */
export default class ExhibitorRepository extends GraphQlRepository<Exhibitor>
  implements GetterInterface<Exhibitor>, UpdateInterface<Exhibitor> {
  update(params: GqlPayloadParams): Promise<Exhibitor | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdateExhibitor',
      operation: 'ExhibitorUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorUpdate: Exhibitor }>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorUpdate: Exhibitor }>) => response.data?.ExhibitorUpdate));
  }

  updateByLocale(params: GqlPayloadParams, locale: string): Promise<Exhibitor | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdateExhibitorByLocale',
      operation: 'ExhibitorUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorUpdate: Exhibitor }>(mutation, {
      ...params.definition.variables,
      _locale: locale,
    })
      .then((response: FetchResult<{ ExhibitorUpdate: Exhibitor }>) => response.data?.ExhibitorUpdate));
  }

  get(params: GqlPayloadParams): Promise<Exhibitor | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetExhibitor',
      operation: 'exhibitor',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'exhibitorBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ exhibitor: Array<Exhibitor> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ exhibitor: Array<Exhibitor> }>) => response.data.exhibitor[0]));
  }

  filter(params: GqlPayloadParams): Promise<Exhibitor[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterExhibitor',
      operation: 'exhibitor',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'exhibitorBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ exhibitor: Array<Exhibitor> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ exhibitor: Array<Exhibitor> }>) => response.data.exhibitor));
  }

  addCategoriesToExhibitor(params: GqlComposeMutationPayloadParams): Promise<Record<string, Exhibitor>[]> {
    return buildComposeMutationGql({
      operationName: 'AddCategoriesToExhibitor',
      definitions: params.definitions,
      authUser: params.authUser,
    }).then((mutations) => this.mutate<Record<string, Exhibitor>[], {}>(mutations))
      .then((response: FetchResult<Record<string, Exhibitor>[]>) => response.data as Record<string, Exhibitor>[]);
  }

  removeCategoriesFromExhibitor(params: GqlComposeMutationPayloadParams): Promise<Record<string, Exhibitor>[]> {
    return buildComposeMutationGql({
      operationName: 'RemoveCategoriesFromExhibitor',
      definitions: params.definitions,
      authUser: params.authUser,
    }).then((mutations) => this.mutate<Record<string, Exhibitor>[], {}>(mutations))
      .then((response: FetchResult<Record<string, Exhibitor>[]>) => response.data as Record<string, Exhibitor>[]);
  }

  setExhibitorCountry(params: GqlPayloadParams): Promise<Exhibitor | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'ExhibitorSetCountry',
      operation: 'ExhibitorSetCountry',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorSetCountry: Exhibitor }>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorSetCountry: Exhibitor }>) => response.data?.ExhibitorSetCountry));
  }

  setExhibitorAddress(params: GqlPayloadParams): Promise<Exhibitor | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'SetExhibitorAddress',
      operation: 'ExhibitorAddAddresse',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorAddAddresse: Exhibitor }>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorAddAddresse: Exhibitor }>) => response.data?.ExhibitorAddAddresse));
  }

  fetchWithPackageStock(params: GqlPayloadParams): Promise<Exhibitor> {
    let filter = '[]';
    if (params.params) {
      if (params.params.packageId) {
        filter = `["${params.params.packageId}"]`;
      }
    }

    return buildQueryGql({
      operationName: params.operationName || 'FilterSlots',
      operation: 'exhibitor',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'exhibitorWithPackageStockFragment',
      magicArgs: { filter: `(salesPackages: ${filter})` },
    })
      .then((query) => this.query<{ exhibitor: Array<Exhibitor> }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{ exhibitor: Array<Exhibitor> }>) => response.data.exhibitor[0]));
  }
}
