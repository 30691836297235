/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/camelcase */
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import FeedItemWrapper from '@/models/graphql/FeedItemWrapper';
import EntityType from '@/utils/enums/EntityType';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlQueryDefinition, { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import gql from 'graphql-tag';
import { print } from 'graphql/language/printer';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import UpdateInterface from '@/repositories/base/UpdateInterface';

/* eslint-disable max-len */
export default class FeedItemWrapperRepository extends GraphQlRepository<FeedItemWrapper>
  implements FilterInterface<FeedItemWrapper>, UpdateInterface<FeedItemWrapper> {
  filter(params: GqlPayloadParams): Promise<FeedItemWrapper[]> {
    switch (params.operationName) {
      case 'feedsOfEntity':
        return buildQueryGql({
          operationName: 'GetFeedsOfEntity',
          operation: 'getEntityFeed',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'feedItemWrapperEntityFragment',
          authUser: params.authUser,
        }).then((query) => {
          let q = print(query);
          const filter = (params.definition as GqlQueryDefinition).filter?.value;
          if (filter) {
            const {
              initiatorUid,
              skip,
              limit,
              bookmarksOnly,
              myUid,
            } = filter as { initiatorUid: string; skip: number; limit: number; bookmarksOnly: boolean; myUid: string};
            q = q.replace('($filter: _FeedItemWrapperFilter)', '')
              .replace('filter: $filter', `initiatorUid: "${initiatorUid}", skip: ${skip}, limit: ${limit}, bookmarksOnly: ${bookmarksOnly}, myUid: "${myUid}"`);
          }
          return this.query<{ getEntityFeed: Array<FeedItemWrapper> }, {}>(gql(q), params.definition.variables, false);
        }).then((response: ApolloQueryResult<{ getEntityFeed: Array<FeedItemWrapper> }>) => response.data.getEntityFeed);

      case 'feedsOfTopic':
        return buildQueryGql({
          operationName: 'GetFeedsOfTopic',
          operation: 'topic',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'feedItemWrapperTopicFragment',
          authUser: params.authUser,
        }).then((query) => {
          let q = print(query);
          if (params.params) {
            q = q.replaceAll('"%offset%"', String(params.params.offset))
              .replaceAll('"%first%"', String(params.params.first))
              .replaceAll('"%bookmarksOnly%"', String(params.params.bookmarksOnly))
              .replaceAll('%myUid%', String(params.params.myUid));
          }
          return this.query<{ topic: Array<{ feed: FeedItemWrapper[] }> }, {}>(gql(q), params.definition.variables, false);
        }).then((response: ApolloQueryResult<{ topic: Array<{ feed: FeedItemWrapper[] }> }>) => response.data.topic[0]?.feed || []);

      case 'feedOfChannel':
        return buildQueryGql({
          operationName: 'GetFeedsOfChannel',
          operation: 'channel',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'feedItemWrapperChannelFragment',
          authUser: params.authUser,
        }).then((query) => {
          let q = print(query);
          if (params.params) {
            q = q.replaceAll('"%offset%"', String(params.params.offset))
              .replaceAll('"%first%"', String(params.params.first))
              .replaceAll('"%bookmarksOnly%"', String(params.params.bookmarksOnly))
              .replaceAll('%myUid%', String(params.params.myUid));
          }
          return this.query<{ channel: Array<{ feed: FeedItemWrapper[] }>}, {}>(gql(q), params.definition.variables, false);
        }).then((response: ApolloQueryResult<{ channel: Array<{ feed: FeedItemWrapper[] }> }>) => response.data.channel[0]?.feed || []);

      default:
      case 'getWallFeeds':
        return buildQueryGql({
          operationName: 'GetWallFeeds',
          operation: 'communityUser',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'feedItemWrapperWallFragment',
          authUser: params.authUser,
        }).then((query) => {
          let q = print(query);
          if (params.params) {
            q = q.replaceAll('"%offset%"', String(params.params.skip))
              .replaceAll('"%first%"', String(params.params.limit))
              .replaceAll('"%bookmarksOnly%"', String(params.params.bookmarksOnly));
          }
          return this.query<{ communityUser: Array<{ _realTimeGlobalFeed: Array<FeedItemWrapper> }> }, {}>(gql(q), params.definition.variables, false);
        })
          .then((response: ApolloQueryResult<{ communityUser: Array<{ _realTimeGlobalFeed: Array<FeedItemWrapper> }> }>) => response.data.communityUser[0]._realTimeGlobalFeed)
          .then(async (feedItemWrappers) => {
            let allFeedItemWrappers = feedItemWrappers;
            const editionFeedItemsIds = allFeedItemWrappers.filter((item) => {
              const feedItemWrapper = item as unknown as FeedItemWrapper;
              return feedItemWrapper.initiator?.__typename === EntityType.EDITION;
            }).map((item) => (item as unknown as FeedItemWrapper).uid);

            if (editionFeedItemsIds.length > 0) {
              const editionFeedItems = await this.allWallFeedsWithEditionInitiator(editionFeedItemsIds);
              const dictionaryEdition = Object.assign({}, ...editionFeedItems.map((item) => ({ [item.uid]: item })));
              allFeedItemWrappers = allFeedItemWrappers.map((x) => {
                let feedItemWrapper = x as unknown as FeedItemWrapper;
                if ((feedItemWrapper.uid in dictionaryEdition)) {
                  feedItemWrapper = dictionaryEdition[feedItemWrapper.uid];
                }
                return feedItemWrapper;
              }) as unknown as FeedItemWrapper [];
            }
            return allFeedItemWrappers;
          });
    }
  }

  latestFeedItem(params: GqlPayloadParams): Promise<FeedItemWrapper | undefined> {
    switch (params.operationName) {
      case 'feedsOfEntity':
        return buildQueryGql({
          operationName: 'GetLatestFeedOfEntity',
          operation: 'getEntityFeed',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'latestFeedItemWrapperEntityFragment',
          authUser: params.authUser,
        }).then((query) => {
          let q = print(query);
          const filter = (params.definition as GqlQueryDefinition).filter?.value;
          if (filter) {
            const {
              initiatorUid,
              myUid,
            } = filter as { initiatorUid: string; skip: number; limit: number; bookmarksOnly: boolean; myUid: string};
            q = q.replace('($filter: _FeedItemWrapperFilter)', '')
              .replace('filter: $filter', `initiatorUid: "${initiatorUid}", myUid: "${myUid}", skip: 0, limit: 1`);
          }
          return this.query<{ getEntityFeed: Array<FeedItemWrapper> }, {}>(gql(q), params.definition.variables, false);
        }).then((response: ApolloQueryResult<{ getEntityFeed: Array<FeedItemWrapper> }>) => response.data.getEntityFeed[0]);

      case 'feedsOfTopic':
        return buildQueryGql({
          operationName: 'GetLatestFeedsOfTopic',
          operation: 'topic',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'latestFeedItemWrapperTopicFragment',
          authUser: params.authUser,
        }).then((query) => this.query<{ topic: Array<{ feed: FeedItemWrapper[] }> }, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{ topic: Array<{ feed: FeedItemWrapper[] }> }>) => response.data.topic[0]?.feed[0] || undefined);

      case 'feedOfChannel':
        return buildQueryGql({
          operationName: 'GetLatestFeedsOfChannel',
          operation: 'channel',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'latestFeedItemWrapperChannelFragment',
          authUser: params.authUser,
        }).then((query) => this.query<{ channel: Array<{ feed: FeedItemWrapper[] }>}, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{ channel: Array<{ feed: FeedItemWrapper[] }> }>) => response.data.channel[0]?.feed[0] || undefined);

      default:
      case 'getWallFeeds':
        return buildQueryGql({
          operationName: 'GetLatestWallFeeds',
          operation: 'communityUser',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'latestFeedItemWrapperWallFragment',
          authUser: params.authUser,
        }).then((query) => this.query<{ communityUser: Array<{ _realTimeGlobalFeed: Array<FeedItemWrapper> }> }, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{ communityUser: Array<{ _realTimeGlobalFeed: Array<FeedItemWrapper> }> }>) => response.data.communityUser[0]._realTimeGlobalFeed[0]);
    }
  }

  update(params: GqlPayloadParams):
    Promise<FeedItemWrapper | undefined> {
    switch (params.operationName) {
      case 'hidePost':
        return buildMutationGql({
          operationName: 'hidePost',
          operation: 'FeedItemWrapperAddHiddenUser',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'feedItemWrapperUidFragment',
          authUser: params.authUser,
        })
          .then((mutation) => this.mutate<{
            FeedItemWrapperAddHiddenUser: FeedItemWrapper;
          }, {}>(mutation, params.definition.variables))
          .then((response: FetchResult<{
            FeedItemWrapperAddHiddenUser: FeedItemWrapper;
          }>) => response.data?.FeedItemWrapperAddHiddenUser);

      case 'showPost':
        return buildMutationGql({
          operationName: 'showPost',
          operation: 'FeedItemWrapperRemoveHiddenUser',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'feedItemWrapperUidFragment',
          authUser: params.authUser,
        })
          .then((mutation) => this.mutate<{
            FeedItemWrapperRemoveHiddenUser: FeedItemWrapper;
          }, {}>(mutation, params.definition.variables))
          .then((response: FetchResult<{
            FeedItemWrapperRemoveHiddenUser: FeedItemWrapper;
          }>) => response.data?.FeedItemWrapperRemoveHiddenUser);

      default:
        return buildMutationGql({
          operationName: params.operationName || 'FeedItemWrapperUpdate',
          operation: 'FeedItemWrapperUpdate',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: params.fragmentName || 'feedItemWrapperFullFragment',
          authUser: params.authUser,
        })
          .then((mutation) => this.mutate<{
            FeedItemWrapperUpdate: FeedItemWrapper;
          }>(mutation, params.definition.variables))
          .then((response: FetchResult<{
            FeedItemWrapperUpdate: FeedItemWrapper;
          }>): FeedItemWrapper | undefined => response.data?.FeedItemWrapperUpdate);
    }
  }

  get(params: GqlPayloadParams): Promise<FeedItemWrapper | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetFeedItemWrapper',
      operation: 'feedItemWrapper',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'feedItemWrapperEntityFragment',
    }).then((query) => this.query<{ feedItemWrapper: FeedItemWrapper[] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ feedItemWrapper: FeedItemWrapper[] }>) => response.data?.feedItemWrapper[0]));
  }

  allWallFeedsWithEditionInitiator(uids: string[]): Promise<FeedItemWrapper[]> {
    return buildQueryGql({
      operationName: 'getEditionWallFeeds',
      operation: 'feedItemWrapper',
      gqlDefinition: buildQueryDefinition(
        {
          filter: {
            value: {
              uid_in: uids,
            },
            type: GqlEntityFilterType.FEED_ITEM_WRAPPER_FILTER,
          },
        },
      ),
      fragmentName: 'feedItemWrapperEditionFragment',
    }).then((query) => this.query<{ feedItemWrapper: Array<FeedItemWrapper> }, {}>(query, {}, false))
      .then((response: ApolloQueryResult<{ feedItemWrapper: Array<FeedItemWrapper> }>) => response.data.feedItemWrapper);
  }
}
