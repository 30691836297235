import GqlFieldType from '@/graphql/_Tools/GqlFieldType';
import GqlFieldInterface from '@/graphql/_Tools/type/GqlFieldInterface';

export default class GqlOffsetType extends GqlFieldType<number> {
  get isValid(): boolean {
    return this.value !== null && this.value !== undefined && this.value >= 0;
  }

  static init<TYPE = number>(
    seed: GqlFieldInterface<TYPE> | null | undefined,
  ): GqlFieldType<TYPE> | null {
    if (!seed) {
      return null;
    }

    const result = new GqlOffsetType(seed as unknown as GqlFieldInterface<number>, 'offset');
    return result.isValid ? result as unknown as GqlFieldType<TYPE> : null;
  }
}
