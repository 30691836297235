import { Action, Module } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
/* eslint-disable @typescript-eslint/camelcase */

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class EntityInfoWidgetStore extends WidgetBaseStore {
  @Action
  setDataConfigs(): void {
    if (this.widget) {
      const {
        dataType,
        type,
        entityCode,
        schemaCode,
      } = JSON.parse(this.widget.payload || '{}');
      const filter = {};
      let filterType = '';
      let operation = '';
      let fragmentName = '';
      switch (type) {
        case 'deal':
          if (dataType === 'details') {
            filterType = GqlEntityFilterType.DEAL_FILTER;
            fragmentName = 'dealEntityInfoFragment';
            operation = 'deal';
            Object.assign(filter, { uid: entityCode });
          }
          if (dataType === 'product') {
            filterType = GqlEntityFilterType.PRODUCT_FILTER;
            fragmentName = 'productEntityInfoLinksFragment';
            operation = 'product';
            Object.assign(filter, { deals: { uid: entityCode } });
          }
          if (dataType === 'largeProduct') {
            filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
            fragmentName = 'largeProductEntityInfoLinksFragment';
            operation = 'largeProduct';
            Object.assign(filter, { deals: { uid: entityCode } });
          }
          break;
        case 'session':
          filterType = GqlEntityFilterType.SESSION_FILTER;
          fragmentName = 'sessionEntityInfoFragment';
          operation = 'session';
          Object.assign(filter, { uid: entityCode });
          break;
        case 'largeProduct':
          filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
          if (dataType === 'details') {
            filterType = GqlEntityFilterType.PRODUCT_FILTER;
            fragmentName = 'largeProductEntityInfoFragment';
            operation = 'largeProduct';
            Object.assign(filter, { uid: entityCode });
          }
          if (dataType === 'deal') {
            filterType = GqlEntityFilterType.DEAL_FILTER;
            fragmentName = 'dealEntityInfoLinksFragment';
            operation = 'deal';
            Object.assign(filter, { largeProduct: { uid: entityCode } });
          }
          if (dataType === 'price') {
            fragmentName = 'largeProductEntityInfoPriceFragment';
            operation = 'largeProduct';
            Object.assign(filter, { uid: entityCode });
          }
          if (dataType === 'location' && schemaCode) {
            this.context.commit(
              'WidgetDispatcherStore/setMagicArgs',
              { editionSchemaCode: schemaCode },
              { root: true },
            );
            fragmentName = 'largeProductEntityInfoLocationFragment';
            operation = 'largeProduct';
            Object.assign(filter, { uid: entityCode });
          }
          if (dataType === 'exhibitor') {
            fragmentName = 'largeProductEntityInfoExhibitorFragment';
            operation = 'largeProduct';
            Object.assign(filter, { uid: entityCode });
          }
          break;
        case 'product':
          if (dataType === 'details') {
            filterType = GqlEntityFilterType.PRODUCT_FILTER;
            fragmentName = 'productEntityInfoFragment';
            operation = 'product';
            Object.assign(filter, { uid: entityCode });
          }
          if (dataType === 'deal') {
            filterType = GqlEntityFilterType.DEAL_FILTER;
            fragmentName = 'dealEntityInfoLinksFragment';
            operation = 'deal';
            Object.assign(filter, { product: { uid: entityCode } });
          }
          break;
        case 'communityUser':
          filterType = GqlEntityFilterType.COMMUNITY_USER_URL_FILTER;
          fragmentName = 'communityUserUrlBaseFragment';
          operation = 'communityUserUrl';
          Object.assign(filter, { user: { uid: entityCode } });
          break;
        case 'topics':
          filterType = GqlEntityFilterType.TOPIC_FILTER;
          fragmentName = 'topicBaseFragment';
          operation = 'topic';
          Object.assign(filter, { uid: entityCode });
          break;
        default:
          break;
      }
      const configs = [{
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: filter,
            type: filterType,
          },
        }),
        operation,
        fragmentName,
        alias: this.widgetStoreName,
      }];

      this.context.commit('setGqlQueryConfig', configs);
      this.context.commit(
        'WidgetDispatcherStore/setGqlQueryConfigs',
        {
          key: this.widgetStoreName,
          values: configs,
        },
        { root: true },
      );
    }
  }
}
