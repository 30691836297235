














































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueContextLoading from '@/components/VueContextLoading';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { State } from 'vuex-class';
import PickerDateTimeComponent from '@/components/calendar/PickerDateTimeComponent.vue';

@Component({
  components: { PickerDateTimeComponent },
  data(): object {
    return {
      dateValue: null,
    };
  },
})
export default class DateRadioFieldComponent extends VueContextLoading {
  @Prop({ default: '' })
  readonly label!: string;

  @Prop({ default: false, required: false })
  readonly firstChoiceNow!: boolean;

  @Prop({ default: null, required: false })
  readonly defaultValue!: string;

  @State
  private dateLocale!: Locale;

  private selected: string | null = null;

  mounted(): void {
    // eslint-disable-next-line no-nested-ternary
    this.selected = this.firstChoiceNow === true ? 'now' : this.firstChoiceNow === false ? 'never' : 'specific';
  }

  @Watch('defaultValue', { deep: true, immediate: true })
  updateInput(): void {
    if (this.defaultValue) {
      this.selected = 'specific';
      this.$data.dateValue = DateTimeHelper.toLocal(new Date(this.defaultValue));
    } else {
      this.selected = this.firstChoiceNow ? 'now' : 'never';
      this.$data.dateValue = null;
    }
  }

  private selectedDate(data: { date: Date; timestamp: number; text: string }): void {
    this.$data.dateValue = DateTimeHelper.toLocal(data.date);
    this.$emit('change', data.date);
  }

  private onRadioChange(radioValue: string): void {
    if (radioValue === 'now') {
      this.$emit('change', DateTimeHelper.toUTC(new Date()));
    } else if (radioValue === 'never') {
      this.$emit('change', null);
    }
  }
}
