export enum ToolbarMenuActions {
  TOOLBAR_DASHBOARD = 'toggleDashboardModal',
  TOOLBAR_AGENDA = 'toggleCalendarModal',
  TOOLBAR_NOTES = 'toggleNotesModal',
  TOOLBAR_TASK = 'toggleTasksModal',
  TOOLBAR_PROJECT = 'toggleProjectsModal',
  TOOLBAR_CONNECTION = 'toggleConnectionModal',
  TOOLBAR_CHAT = 'toggleChatModal',
  TOOLBAR_NETWORK = 'toggleNetworkModal',
  TOOLBAR_TAGS = 'toggleTagsModal'
}
