import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import AuthProviderRepository from '@/repositories/AuthProviderRepository';
import AuthProvider from '@/models/graphql/AuthProvider';

interface AuthProviderState extends LoadableState {
  authProviders: [];
}

@Module({ namespaced: true })
export default class AuthProviderStore extends LoadableStore<AuthProviderState> {
  allPromisesLoading = false;

  private readonly authProviderRepository = new AuthProviderRepository();

  get getAllPromisesLoading(): boolean {
    return this.allPromisesLoading;
  }

  protected get repository(): AuthProviderRepository {
    return this.authProviderRepository;
  }

  @Action
  loadAuthProviders(): Promise<AuthProvider[]> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: { isExternal: true },
          type: GqlEntityFilterType.AUTH_PROVIDER_FILTER,
        },
      }),
      fragmentName: 'authProviderBaseFragment',
    }).then((response) => response)
      .catch(() => [])
      .finally(() => this.context.commit('load', false));
  }
}
