import { ApolloQueryResult } from '@apollo/client';
import LoginProviderConfig from '@/models/graphql/LoginProviderConfig';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GetterInterface from '@/repositories/base/GetterInterface';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable max-len */
export default class LoginProviderConfigRepository extends GraphQlRepository<LoginProviderConfig>
  implements GetterInterface<LoginProviderConfig> {
  get(params: GqlPayloadParams): Promise<LoginProviderConfig | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetLoginProviderConfig',
      operation: 'loginProviderConfig',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'loginProviderConfigBaseFragment',
    }).then((query) => this.query<{ loginProviderConfig: LoginProviderConfig[] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ loginProviderConfig: LoginProviderConfig[] }>) => response.data?.loginProviderConfig[0]));
  }
}
