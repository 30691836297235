import { render, staticRenderFns } from "./AgendaFilterItem.vue?vue&type=template&id=40d7722a&scoped=true&"
import script from "./AgendaFilterItem.vue?vue&type=script&lang=ts&"
export * from "./AgendaFilterItem.vue?vue&type=script&lang=ts&"
import style0 from "../../../../assets/styles/toolbox/agenda/ToolboxAgendaContainer.scss?vue&type=style&index=0&id=40d7722a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d7722a",
  null
  
)

export default component.exports