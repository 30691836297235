import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Module, Mutation } from 'vuex-module-decorators';
import { Data } from '@/utils/types/WidgetData';
import { deepGet } from '@/utils/ObjectHelpers';
import FileResource from '@/models/graphql/FileResource';

@Module({ namespaced: true, stateFactory: true })
export default class DetailPageHeaderWidgetStore extends WidgetBaseStore {
  private dataValues: {
    logo?: string | FileResource;
    banners?: string | FileResource | Array<string> | Array<FileResource>;
    type?: string;
    entityType?: string;
  } = {};

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    const payload: Data = JSON.parse(this.widget?.payload || '{}');
    if (data.values.length > 0 && (payload.logo || payload.banner)) {
      data.values.forEach((dataValue) => {
        if (payload.logo) {
          const logoValue = deepGet(dataValue, String(payload.logo));
          if (logoValue) {
            this.dataValues.logo = logoValue;
          }
        }
        if (payload.banner) {
          const bannerValue = deepGet(dataValue, String(payload.banner));
          if (bannerValue) {
            this.dataValues.banners = bannerValue;
          }
        }
        // eslint-disable-next-line no-underscore-dangle
        this.dataValues.type = dataValue.__typename ? String(dataValue.__typename) : '';
        this.dataValues.entityType = this.dataValues.type;
      });
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      ...this.dataValues,
    };
  }
}
