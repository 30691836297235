/* eslint-disable import/no-cycle */
import BaseModel from '@/models/BaseModel';
import Community from '@/models/graphql/Community';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import EntityTypeModel from '@/models/graphql/EntityTypeModel';
import UiOnboarding from '@/models/graphql/UiOnboarding';

/* eslint-disable no-param-reassign,no-unused-expressions */
export default class UiPage extends BaseModel {
  id?: number;

  community?: Community;

  code?: string;

  name?: string;

  published?: boolean;

  payload?: string;

  widgets?: Array<UiPageWidget>;

  paramName?: string;

  entityType?: EntityTypeModel;

  path?: string

  meta?: string

  guest?: boolean

  defaultTab?: boolean

  production?: boolean

  active?: boolean

  header?: boolean

  footer?: boolean

  toolbar?: boolean

  showCta?: boolean

  showGdpr?: boolean

  chatPanel?: boolean

  version?: string

  createdBy?: string

  updatedBy?: string

  createdTime?: string;

  updatedTime?: string;

  existenceGraphql?: string;

  permissionGraphql?: string;

  childPages: Array<UiPage> = [];

  onboardings: UiOnboarding[] = [];

  parentPage?: UiPage;

  cssClasses?: string;

  metaTitle?: string;

  metaDescription?: string;

  static hydrate(item: object): UiPage {
    const result = new UiPage();
    Object.assign(result, item);
    result.widgets = UiPage.mappingWidgets(result.widgets || []);

    if (result.parentPage) {
      result.paramName = result.parentPage.paramName;
      result.entityType = result.parentPage.entityType;
    }

    return result;
  }

  public static mappingWidgets(widgets: Array<UiPageWidget>): Array<UiPageWidget> {
    return UiPage.nest(widgets.filter((w) => w.type !== 'destination_dependency')
      .map((w) => UiPageWidget.hydrate(w)));
  }

  private static nest(items: UiPageWidget[], uid?: string): UiPageWidget[] {
    return items.filter((item) => item.parentWidget?.uid === uid)
      .map((item) => ({ ...item, subWidgets: this.nest(items, item.uid) }))
      // eslint-disable-next-line no-nested-ternary,max-len
      .sort((a, b) => (a.displaySequence > b.displaySequence ? 1 : a.displaySequence < b.displaySequence ? -1 : 0));
  }
}
