var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"agenda-list-item",on:{"click":function($event){$event.stopPropagation();_vm.type !== 'new-meeting' ? _vm.onEventClick() : ''}}},[_c('div',{directives:[{name:"b-hover",rawName:"v-b-hover",value:(function (value) {_vm.isHovered = value;}),expression:"(value) => {isHovered = value;}"}],class:[
      'd-flex justify-content-between rounded position-relative w-100 h-100 align-items-start p-3',
      { 'session': _vm.EntityType.SESSION},
      {'meeting-accepted': _vm.EntityType.MEETING && (_vm.isAccepted || _vm.isCreator)},
      {'meeting-declined': _vm.EntityType.MEETING && _vm.isDeclined},
      {'meeting-invited': _vm.EntityType.MEETING && _vm.isInvited},
      {'new-meeting bg-white-white-100': _vm.type === 'new-meeting'} ]},[_c('div',{class:['d-flex align-items-start',
               {'mr-3': (_vm.size === 'lg' || _vm.size === 'md')},
               {'mr-2': _vm.size === 'sm'} ]},[_c('div',{class:['text-left d-flex align-items-start',
                 {'start-time-label-lg mr-3': _vm.size === 'lg'},
                 {'start-time-label-md mr-3': _vm.size === 'md' && _vm.fetchViewMode !== _vm.ViewMode.SEARCH},
                 {'start-time-label-sm mr-2': _vm.size === 'sm'}
        ]},[_c('label',{class:['medium text-left text-neutral-n-8-dark-ink m-0',
                   {'label-1 lh-20': (_vm.size === 'lg' || _vm.size === 'md')},
                   {'label-1': _vm.size === 'sm'},
                   {'strikethrough': _vm.isDeclined}
          ]},[_vm._v(" "+_vm._s(_vm.startTime)+" ")])]),_c('div',{staticClass:"d-flex flex-column"},[_c('label',{class:['medium text-left m-0 detail',
                   {'label-1 lh-20': (_vm.size === 'lg' || _vm.size === 'md')},
                   {'label-1': _vm.size === 'sm'},
                   {'strikethrough': _vm.isDeclined},
                   {'pending': _vm.isInvited},
                   {'text-neutral-n-5-placeholder': _vm.type === 'new-meeting'}
          ]},[_vm._v(" "+_vm._s(_vm.event.title)+" "+_vm._s(_vm.sessionDayTitle)+" ")]),_c('label',{class:['label label-3 text-left mt-2 mb-0 detail',
                   {'strikethrough': _vm.isDeclined},
                   {'pending': _vm.isInvited},
                   {'text-neutral-n-5-placeholder': _vm.type === 'new-meeting'}
          ]},[_vm._v(" "+_vm._s(_vm.fullFormattedDate)+" ")]),(!_vm.isDesktop)?_c('label',{class:['label label-3 text-left mb-0 detail',
                   {'strikethrough': _vm.isDeclined},
                   {'pending': _vm.isInvited},
                   {'text-neutral-n-5-placeholder': _vm.type === 'new-meeting'}
          ]},[_vm._v(" "+_vm._s(_vm.fullFormattedTime)+" ")]):_vm._e(),_c('label',{class:['label label-3 text-left mb-0 detail',
                   {'pending': _vm.isInvited},
                   {'text-neutral-n-5-placeholder': _vm.type === 'new-meeting'}
          ]},[_vm._v(" "+_vm._s(_vm.event.location)+" ")]),(_vm.type === _vm.EntityType.SESSION)?_c('label',{staticClass:"label label-3 medium text-left text-neutral-n-8-dark-ink mt-2 mb-0"},[_vm._v(" "+_vm._s(_vm.$tc('toolbox.agenda.view.interests', 0, {nbInterests: _vm.event.bookmarkCount}))+" ")]):_vm._e(),(_vm.type === _vm.EntityType.MEETING)?_c('b-avatar-group',{staticClass:"mt-2"},_vm._l((_vm.participantsList.slice(0, 5)),function(meetingParticipant){return _c('avatar-solo-widget',{key:meetingParticipant.uid,staticClass:"participant-avatar",attrs:{"firstname":meetingParticipant.user ? meetingParticipant.user.firstName : '',"lastname":meetingParticipant.user ? meetingParticipant.user.lastName : '',"src":meetingParticipant.user && meetingParticipant.user.pictureFileResource
              ? _vm.FileResourceHelper.getFullPath(meetingParticipant.user.pictureFileResource, 'w40')
              : undefined,"with-borders":true,"size":"16"}})}),1):_vm._e(),(_vm.type === _vm.EntityType.MEETING && !_vm.isCreator)?_c('div',{staticClass:"d-flex align-items-center mt-4"},[_c('button-component',{staticClass:"mr-1",attrs:{"text":("" + (_vm.isInvited || _vm.isDeclined
              ? _vm.$t('toolbox.agenda.meeting-request-button.accept')
              : _vm.isAccepted ? _vm.$t('toolbox.agenda.meeting-request-button.accepted'): '')),"variant":_vm.isInvited || _vm.isDeclined
              ? 'tertiary' : _vm.isAccepted ? 'primary': '',"size":"sm"},on:{"on-click":function($event){return _vm.onUpdateParticipantState(_vm.MeetingParticipantState.ACCEPTED)}}}),_c('button-component',{staticClass:"mr-1",attrs:{"text":("" + (_vm.isDeclined
              ? _vm.$t('toolbox.agenda.meeting-request-button.declined')
              : _vm.$t('toolbox.agenda.meeting-request-button.decline'))),"variant":_vm.isDeclined
              ? 'red'
              : 'tertiary',"size":"sm"},on:{"on-click":function($event){return _vm.onUpdateParticipantState(_vm.MeetingParticipantState.DECLINED)}}})],1):_vm._e()],1)]),(_vm.type !== 'new-meeting')?_c('div',{staticClass:"d-flex align-items-center column-gap-2 position-absolute buttons-icon"},[((_vm.isHovered || _vm.size === 'sm' || _vm.size === 'md') && _vm.isCreator)?_c('button-icon-component',{attrs:{"rounded":true,"size":"sm","variant":"yellow"},on:{"on-click":_vm.onEditClick},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-16","icon":"far fa-pen","icon-classes":"icon-12 text-neutral-n-8-dark-ink"}})]},proxy:true}],null,false,1024236144)}):_vm._e(),((_vm.isHovered || _vm.size === 'sm' || _vm.size === 'md')
          && _vm.type !== 'new-meeting'
          && !_vm.participantState)?_c('button-icon-component',{attrs:{"rounded":true,"variant":_vm.type === _vm.EntityType.SESSION
          || _vm.type === _vm.EntityType.SCHEDULE_EVENT
          ? 'alt-2'
          : _vm.EntityType.MEETING
            && (!_vm.participantState|| _vm.isAccepted)
            ? 'yellow' : 'tertiary',"size":"sm"},on:{"on-click":_vm.onRemoveFromAgendaClick},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":_vm.type === _vm.EntityType.SESSION
              || _vm.type === _vm.EntityType.SCHEDULE_EVENT
              ? 'far fa-calendar-xmark'
              : _vm.type === _vm.EntityType.MEETING ? 'far fa-trash-can' : '',"container-classes":"icon-16","icon-classes":"icon-12 text-neutral-n-8-dark-ink"}})]},proxy:true}],null,false,634092584)}):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }