import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { AppSocialMediaIcons } from '@/utils/enums/AppSocialMediaIcons';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';

@Module({ namespaced: true, stateFactory: true })
export default class SpeakerSocialLinksWidgetStore extends WidgetBaseStore {
  @Action
  setDataConfigs(): void {
    if (this.widget) {
      const { speakerCode } = JSON.parse(this.widget.payload || '{}');
      const configs = [{
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: { uid: speakerCode },
            type: GqlEntityFilterType.SPEAKER_FILTER,
          },
        }),
        operation: 'speaker',
        fragmentName: 'speakerSocialLinksFragment',
        alias: this.widgetStoreName,
      }];

      this.context.commit('setGqlQueryConfig', configs);
      this.context.commit(
        'WidgetDispatcherStore/setGqlQueryConfigs',
        { key: this.widgetStoreName, values: configs },
        { root: true },
      );
    }
  }

  @Mutation
  mapper(): void {
    const sites: Array<{ url: string; icon: string }> = [];
    if (this.data && this.data.length > 0 && this.data[0]) {
      if (this.data[0].facebookUrl) {
        sites.push({
          icon: (AppSocialMediaIcons as Record<string, string>)['facebook' || ''],
          url: this.data[0].facebookUrl.toString(),
        });
      }
      if (this.data[0].instagramUrl) {
        sites.push({
          icon: (AppSocialMediaIcons as Record<string, string>)['instagram' || ''],
          url: this.data[0].instagramUrl.toString(),
        });
      }
      if (this.data[0].twitterUrl) {
        sites.push({
          icon: (AppSocialMediaIcons as Record<string, string>)['twitter' || ''],
          url: this.data[0].twitterUrl.toString(),
        });
      }
      if (this.data[0].pinterestUrl) {
        sites.push({
          icon: (AppSocialMediaIcons as Record<string, string>)['pinterest' || ''],
          url: this.data[0].pinterestUrl.toString(),
        });
      }
      if (this.data[0].homepageUrl) {
        sites.push({
          icon: (AppSocialMediaIcons as Record<string, string>)['website' || ''],
          url: this.data[0].homepageUrl.toString(),
        });
      }
    }
    this.mapping = {
      ...this.payload,
      sites,
    };
  }
}
