

























































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import NotesListItemComponent from '@/components/toolbox/notes/NotesListItemComponent.vue';
import CommunityUserNote from '@/models/graphql/CommunityUserNote';
import CommunityUser from '@/models/graphql/CommunityUser';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import NoteEditorComponent from '@/components/toolbox/notes/NoteEditorComponent.vue';
import UiPage from '@/models/graphql/UiPage';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ToolboxQueryParams from '@/utils/types/ToolboxQueryParams';
import PillWidget from '@/components/pill/PillWidget.vue';
import Variant from '@/utils/enums/Variant';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { StateChanger } from 'vue-infinite-loading';
import CommunityUserLink from '@/models/graphql/CommunityUserLink';
import GUUID from '@/utils/GUUID';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import EntityType from '@/utils/enums/EntityType';
import Exhibitor from '@/models/graphql/Exhibitor';
import Product from '@/models/graphql/Product';
import LargeProduct from '@/models/graphql/LargeProduct';
import Session from '@/models/graphql/Session';
import Deal from '@/models/graphql/Deal';
import Article from '@/models/graphql/Article';
import Speaker from '@/models/graphql/Speaker';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import Community from '@/models/graphql/Community';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import { runMathJax } from '@/utils/helpers/LatexHelper';

const communityUserNoteStore = namespace('CommunityUserNoteStore');

@Component({
  name: 'ToolboxNotesContainer',
  methods: { useTestDataAttribute },
  components: {
    FontAwesomeComponent,
    PillWidget,
    LoadingSpinnerComponent,
    IllustrationComponent,
    InputSearchComponent,
    NotesListItemComponent,
    ButtonComponent,
    ButtonIconComponent,
    NoteEditorComponent,
  },
})
export default class ToolboxNotesContainer extends BreakpointWrapper {
  @Getter
  protected currentPage!: UiPage;

  @Getter
  protected readonly community!: Community;

  @Getter
  private authUser!: CommunityUser;

  @State
  private dateLocale!: Locale;

  @Prop({ required: false, default: null })
  private readonly payload!: ToolboxQueryParams;

  @communityUserNoteStore.Action
  private loadNotes!: (payload: {filter: object; offset: number}) => Promise<number>;

  @communityUserNoteStore.Action
  private loadNoteSearchCount!: (filter: object) => Promise<number | undefined>;

  @communityUserNoteStore.Action
  private loadNoteLinkedTarget!: (payload: { targetType: string; targetUid: string }) => Promise<void>;

  @communityUserNoteStore.Action
  private createNote!: (payload: { content: string; contentPlain: string; targetUid?: string }) => Promise<void>;

  @communityUserNoteStore.Action
  private updateNote!: (payload: { uid: string; content: string; contentPlain: string }) => Promise<void>

  @communityUserNoteStore.Action
  private deleteNote!: (uid: string) => Promise<CommunityUserNote | undefined>;

  @communityUserNoteStore.Action
  private removeNoteLinkTarget!: (payload: { uid: string; linkTargetUid: string }) => Promise<CommunityUserNote | undefined>;

  @communityUserNoteStore.Mutation
  private setNotes!: (payload: {data: CommunityUserNote[]; reset: boolean}) => void

  @communityUserNoteStore.Mutation
  private setSelectedNote!: (uid: string | null) => void;

  @communityUserNoteStore.Mutation
  private setTempNoteUid!: (uid: string | null) => void;

  @communityUserNoteStore.Mutation
  private addTempNote!: (tempNote: CommunityUserNote) => void;

  @communityUserNoteStore.Mutation
  private setNoteLinkedEntity!: (entity: {__typename: string} & object | null) => void;

  @communityUserNoteStore.Mutation
  private incrementNoteLinkedEntityCount!: () => void;

  @communityUserNoteStore.Mutation
  private decrementNoteLinkedEntityCount!: () => void;

  @communityUserNoteStore.State
  private notes!: CommunityUserNote[];

  @communityUserNoteStore.State
  private noteLinkedEntity!: {__typename: string} & object | null;

  @communityUserNoteStore.State
  private selectedNoteUid!: string | null;

  @communityUserNoteStore.State
  private tempNoteUid!: string | null;

  @communityUserNoteStore.State
  private noteLinkedEntityCount!: number;

  private isLoading = false;

  private isEditMobileMode = false;

  private noteInfiniteLoadingIdentifier = 0;

  private noteSearchCount = 0;

  private searchQuery = '';

  private offset = 0;

  private noteFilters: {
    user?: { uid: string };
    links?: [];
    entityFts?: string;
  } = {};

  private IllustrationType = IllustrationType;

  private variantEnum = Variant;

  private filterTypes = {
    ALL: 'all',
    ENTITY: 'entity',
  };

  private selectedFilter = this.filterTypes.ALL;

  private editorConfigs = {
    readOnly: false,
    theme: 'snow',
    placeholder: this.$t('toolbox.notes.notes-container.note-editor-placeholder'),
    modules: {
      toolbar: [
        { size: ['caption', false, 'medium', 'large', 'huge'] },
        'bold', 'italic', 'underline',
        { list: 'ordered' }, { list: 'bullet' },
        'link', 'image',
        { list: 'check' },
      ],
    },
  };

  private get selectedNote(): CommunityUserNote | undefined {
    if (this.selectedNoteUid && this.notes.length > 0) {
      return this.notes.find((note) => note.uid === this.selectedNoteUid);
    }
    return undefined;
  }

  private get entityPageUid(): string {
    if (this.currentPage.paramName && this.entityType) {
      return this.$route.params[this.currentPage.paramName] || '';
    }

    return '';
  }

  private get filterLabel(): string {
    return this.entityPageUid && this.entityType ? this.noteTargetInfo.name : 'Entity';
  }

  private get entityType(): string | null {
    if (this.currentPage.entityType?.code) {
      const isSupportedEntityType = ['communityUser',
        'product',
        'largeProduct',
        'deal',
        'article',
        'exhibitor',
        'session',
        'speaker'].includes(this.currentPage.entityType.code);
      return isSupportedEntityType ? this.currentPage.entityType.code : null;
    }
    return null;
  }

  private get noteTargetInfo(): { name: string; image: string } {
    let name = '';
    let image = '';
    if (this.noteLinkedEntity) {
      // eslint-disable-next-line no-underscore-dangle
      switch (this.noteLinkedEntity.__typename) {
        case EntityType.EXHIBITOR: {
          const entity = Exhibitor.hydrate(this.noteLinkedEntity);
          name = entity.name as string;
          image = entity.mainPicture;
        }
          break;
        case EntityType.PRODUCT:
          {
            const entity = Product.hydrate(this.noteLinkedEntity);
            name = entity.name as string;
            image = entity.mainPicture;
          }
          break;
        case EntityType.LARGE_PRODUCT:
          {
            const entity = LargeProduct.hydrate(this.noteLinkedEntity);
            name = entity.name as string;
            image = entity.mainPicture;
          }
          break;
        case EntityType.SESSION:
          {
            const entity = Session.hydrate(this.noteLinkedEntity);
            name = entity.name as string;
            image = entity.mainPicture;
          }
          break;
        case EntityType.DEAL:
          {
            const entity = Deal.hydrate(this.noteLinkedEntity);
            name = entity.name as string;
            image = entity.mainPicture;
          }
          break;
        case EntityType.USER:
          {
            const entity = CommunityUser.hydrate(this.noteLinkedEntity);
            name = entity.name as string;
            image = entity.mainPicture;
          }
          break;
        case EntityType.ARTICLE:
          {
            const entity = Article.hydrate(this.noteLinkedEntity);
            name = entity.name as string;
            image = entity.mainPicture;
          }
          break;
        case EntityType.SPEAKER:
          {
            const entity = Speaker.hydrate(this.noteLinkedEntity);
            name = entity.name as string;
            image = entity.mainPicture;
          }
          break;
        default:
          break;
      }
    }
    return { name, image };
  }

  // eslint-disable-next-line class-methods-use-this
  updated(): void {
    runMathJax();
  }

  @Watch('isLoading')
  private createPlaceholderNoteFormAddNoteActionButton(): void {
    if (!this.isLoading && this.payload && this.payload.data) {
      this.createPlaceholderNote();
      this.$root.$emit('clear-toolbox-payload-data');
    }
  }

  private createNoteFilters(): void {
    const entityUid = this.entityPageUid;
    const entityType = this.entityType ?? undefined;
    this.setTempNoteUid(null);
    const targetFilter = {};
    if (entityUid && entityType) {
      Object.assign(targetFilter, { links: { target: { uid: entityUid } } });
    } else {
      Object.assign(targetFilter, {});
    }
    const entityFtsFilter = {};
    if (this.searchQuery.length > 2) {
      Object.assign(entityFtsFilter, { entityFts: this.searchQuery });
    } else {
      Object.assign(entityFtsFilter, {});
    }
    this.noteFilters = {
      user: {
        uid: this.authUser.uid,
      },
      ...targetFilter,
      ...entityFtsFilter,
    };
  }

  private clickBackMobileEditMode(): void {
    if (this.selectedNote && this.selectedNote.isTheSame) {
      this.removeTempNote(this.selectedNote);
      this.isEditMobileMode = false;
    } else {
      this.saveNote();
    }
  }

  private openNote(uid: string): void {
    if (this.selectedNote && this.selectedNote.isTheSame) {
      this.removeTempNote(this.selectedNote);
    }
    if (!this.isMobile) {
      this.saveNote();
    }
    this.setSelectedNote(uid);
    this.isEditMobileMode = this.isMobile;
  }

  private removeNoteLink(payload: { note: CommunityUserNote; linkTargetUid: string }): void {
    if (payload.note && payload.note.links.length > 0) {
      let oldLink: CommunityUserLink | null = null;
      const linkIndex = payload.note.links.findIndex((l) => l && l.target && l.target.uid === payload.linkTargetUid);
      if (linkIndex > -1) {
        oldLink = payload.note.links[linkIndex];
        payload.note.links.splice(linkIndex, 1);
        const noteIndex = this.notes.findIndex((note) => note.uid === payload.note.uid);
        if (noteIndex > -1
          && this.noteLinkedEntity
          && Object.keys(this.noteLinkedEntity).length > 1) {
          this.notes.splice(noteIndex, 1);
          this.decrementNoteLinkedEntityCount();
          if (this.notes.length > 0) {
            this.setSelectedNote(this.notes[0].uid);
          } else {
            this.setSelectedNote(null);
          }
        }
      }
      if (payload.note.uid === this.tempNoteUid) {
        return;
      }
      this.removeNoteLinkTarget({
        uid: payload.note.uid,
        linkTargetUid: payload.linkTargetUid,
      }).catch(() => {
        if (oldLink) {
          payload.note.links.push(oldLink);
        }
      });
    }
  }

  private removeTempNote(note: CommunityUserNote): boolean {
    if (note.uid === this.tempNoteUid) {
      const tempNoteIndex = this.notes.findIndex((n) => n.uid === this.tempNoteUid);
      if (tempNoteIndex > -1) {
        this.notes.splice(tempNoteIndex, 1);
        this.setTempNoteUid(null);
        if (this.notes.length > 0) {
          this.setSelectedNote(this.notes[0].uid);
        } else {
          this.setSelectedNote(null);
        }
        return true;
      }
    }
    return false;
  }

  private removeNote(note: CommunityUserNote | null): void {
    if (note) {
      if (this.removeTempNote(note)) {
        return;
      }
      let oldNote: CommunityUserNote | null = null;
      const noteIndex = this.notes.findIndex((n) => n.uid === note.uid);
      if (noteIndex > -1) {
        oldNote = this.notes[noteIndex];
        this.notes.splice(noteIndex, 1);
        if (this.noteLinkedEntity && this.entityPageUid) {
          this.decrementNoteLinkedEntityCount();
        }
        if (this.notes.length === 0) {
          this.setSelectedNote(null);
        } else if (this.notes[noteIndex]) {
          this.setSelectedNote(this.notes[noteIndex].uid);
        } else {
          this.setSelectedNote(this.notes[0].uid);
        }
      }
      this.deleteNote(note.uid)
        .catch(() => {
          if (oldNote && noteIndex > -1) {
            this.notes.splice(noteIndex, 0, oldNote);
            this.incrementNoteLinkedEntityCount();
          }
        });
    }
  }

  private createPlaceholderNote(): void {
    if (!this.tempNoteUid) {
      const createdTime = DateTimeHelper.toISO8601UTC();
      const tempUid = GUUID.uuidv4();
      const links = { links: [] };
      if (this.noteLinkedEntity) {
        Object.assign(links, {
          links: [{
            uid: GUUID.uuidv4(),
            // eslint-disable-next-line no-underscore-dangle
            targetType: this.noteLinkedEntity.__typename,
            target: this.noteLinkedEntity,
          }],
        });
      }
      this.addTempNote(CommunityUserNote.hydrate({
        uid: tempUid,
        content: '',
        contentPlain: `${this.$t('toolbox.notes.notes-container.empty-note')}`,
        createdTime,
        updatedTime: createdTime,
        ...links,
      }));
      if (this.isMobile) {
        this.openNote(tempUid);
      }
      this.setSelectedNote(tempUid);
    }
  }

  private syncLocaleNoteChanges(payload: { html: string; text: string }): void {
    if (this.selectedNote && payload) {
      this.selectedNote.updatedTime = DateTimeHelper.toISO8601UTC();
      this.selectedNote.content = payload.html;
      this.selectedNote.contentPlain = payload.text;
    }
  }

  private saveNote(): void {
    if (this.selectedNote
      && this.selectedNote.contentPlain
      && this.selectedNote.contentPlain.length > 0
      && !this.selectedNote.isTheSame) {
      if (this.tempNoteUid) {
        const payload = {
          content: this.selectedNote.content || '',
          contentPlain: this.selectedNote.contentPlain,
        };
        if (this.noteLinkedEntity && this.selectedNote.links.length > 0) {
          Object.assign(payload, { targetUid: this.entityPageUid });
          this.incrementNoteLinkedEntityCount();
        }
        let entityId = -1;
        if (this.noteLinkedEntity !== null) {
          entityId = (this.noteLinkedEntity as unknown as { id: number }).id ?? -1;
        }
        this.createNote(payload).then(() => {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            this.entityType || '',
            StatLoggerActions.ADD,
            '',
            entityId,
            this.entityPageUid,
            StatLoggerCategories.NOTE,
            this.$i18n.locale,
          );
        });
      } else {
        this.updateNote({
          uid: this.selectedNote.uid,
          content: this.selectedNote.content || '',
          contentPlain: this.selectedNote.contentPlain,
        });
      }
    }
    this.isEditMobileMode = false;
  }

  private onSearch(payload: { query: string }): void {
    this.isLoading = true;
    this.searchQuery = payload.query;
    this.createNoteFilters();
    if (payload.query.length > 2) {
      this.loadNoteSearchCount(this.noteFilters)
        .then((count) => {
          if (count) {
            this.noteSearchCount = count;
          } else {
            this.noteSearchCount = 0;
          }
        });
    } else {
      this.searchQuery = '';
      this.noteSearchCount = 0;
    }
    this.resetNoteInfiniteLoading();
  }

  private onFilterClick(filterType: string): void {
    this.selectedFilter = filterType;
    this.createNoteFilters();
    if (this.searchQuery.length > 2) {
      this.loadNoteSearchCount(this.noteFilters)
        .then((count) => {
          if (count) {
            this.noteSearchCount = count;
          } else {
            this.noteSearchCount = 0;
          }
        });
    } else {
      this.searchQuery = '';
      this.noteSearchCount = 0;
    }
    if (filterType === this.filterTypes.ALL
      && 'links' in this.noteFilters
      && this.noteFilters.links) {
      delete this.noteFilters.links;
    }
    this.resetNoteInfiniteLoading();
  }

  private resetNoteInfiniteLoading(): void {
    this.offset = 0;
    this.setNotes({ data: [], reset: true });
    this.noteInfiniteLoadingIdentifier += 1;
  }

  @Watch('currentPage', { immediate: true })
  private currentPageChanged(): void {
    this.selectedFilter = this.entityPageUid
    && this.entityType
      ? this.filterTypes.ENTITY
      : this.filterTypes.ALL;
    if (this.entityType && this.entityPageUid) {
      this.loadNoteLinkedTarget({
        targetUid: this.entityPageUid,
        targetType: this.entityType,
      });
    }
    this.createNoteFilters();
    this.resetNoteInfiniteLoading();
  }

  private infiniteHandler(state: StateChanger): void {
    this.isLoading = true;
    this.loadNotes({
      filter: this.noteFilters,
      offset: this.offset,
    }).then((nbNotes) => {
      if (nbNotes === 10) {
        state.loaded();
        this.offset += 10;
      } else {
        state.complete();
        this.offset = 0;
      }
    }).catch(() => state.complete())
      .finally(() => {
        this.isLoading = false;
      });
  }
}
