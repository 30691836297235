enum GqlEntityOrderingType {
  ACTION_RULE_GROUP_ORDERING = '_ActionRuleGroupOrdering',
  ACTION_RULE_ORDERING = '_ActionRuleOrdering',
  AD_IMAGE_ORDERING = '_AdImageOrdering',
  AD_ORDERING = '_AdOrdering',
  ADDRESS_ORDERING = '_AddressOrdering',
  APP_TRANSLATION_ORDERING = '_AppTranslationOrdering',
  ARTICLE_ORDERING = '_ArticleOrdering',
  BOOTH_ORDERING = '_BoothOrdering',
  BUSINESS_CARD_ORDERING = '_BusinessCardOrdering',
  CATEGORIZABLE_ORDERING = '_CategorizableOrdering',
  CATEGORY_DOMAIN_CONTEXT_ORDERING = '_CategoryDomainContextOrdering',
  CATEGORY_ORDERING = '_CategoryOrdering',
  CHANGE_LOG_ORDERING = '_ChangeLogOrdering',
  CHANNEL_ORDERING = '_ChannelOrdering',
  COMMENT_ORDERING = '_CommentOrdering',
  COMMUNITY_CONTENT_ORDERING = '_CommunityContentOrdering',
  COMMUNITY_FEATURE_ORDERING = '_CommunityFeatureOrdering',
  COMMUNITY_ORDERING = '_CommunityOrdering',
  COMMUNITY_USER_AGENDA_ENTRY_ORDERING = '_CommunityUserAgendaEntryOrdering',
  COMMUNITY_USER_BOOKMARK_ORDERING = '_CommunityUserBookmarkOrdering',
  COMMUNITY_USER_CATEGORY_ORDERING = '_CommunityUserCategoryOrdering',
  COMMUNITY_USER_CONNECTION_ORDERING = '_CommunityUserConnectionOrdering',
  COMMUNITY_USER_FOLLOW_ORDERING = '_CommunityUserFollowOrdering',
  COMMUNITY_USER_INTEREST_ORDERING = '_CommunityUserInterestOrdering',
  COMMUNITY_USER_LINK_ORDERING = '_CommunityUserLinkOrdering',
  COMMUNITY_USER_NOTE_ORDERING = '_CommunityUserNoteOrdering',
  COMMUNITY_USER_ORDERING = '_CommunityUserOrdering',
  COMMUNITY_USER_PREFERENCES_ORDERING = '_CommunityUserPreferencesOrdering',
  COMMUNITY_USER_PROFILE_ROLE_ORDERING = '_CommunityUserProfileRoleOrdering',
  COMMUNITY_USER_PROJECT_ORDERING = '_CommunityUserProjectOrdering',
  COMMUNITY_USER_PUSH_NOTIFICATION_TOKEN_ORDERING = '_CommunityUserPushNotificationTokenOrdering',
  COMMUNITY_USER_SHARE_ORDERING = '_CommunityUserShareOrdering',
  COMMUNITY_USER_TAG_ORDERING = '_CommunityUserTagOrdering',
  COMMUNITY_USER_TASK_ORDERING = '_CommunityUserTaskOrdering',
  COMMUNITY_USER_URL_ORDERING = '_CommunityUserUrlOrdering',
  COMMUNITY_USER_VISITED_ORDERING = '_CommunityUserVisitedOrdering',
  COMPANY_USER_ROLE_ORDERING = '_CompanyUserRoleOrdering',
  CONTAINER_EDITION_ORDERING = '_ContainerEditionOrdering',
  CONTAINER_ORDERING = '_ContainerOrdering',
  COUNTRY_ORDERING = '_CountryOrdering',
  DATA_TRANSLATION_ORDERING = '_DataTranslationOrdering',
  DEAL_ORDERING = '_DealOrdering',
  DELETABLE_CONTENT_ORDERING = '_DeletableContentOrdering',
  EDITION_ADDRESS_MAPPING_ORDERING = '_EditionAddressMappingOrdering',
  EDITION_BUSINESS_CARD_MAPPING_ORDERING = '_EditionBusinessCardMappingOrdering',
  EDITION_BUSINESS_CARD_ORDERING = '_EditionBusinessCardOrdering',
  EDITION_CATEGORY_MAPPING_ORDERING = '_EditionCategoryMappingOrdering',
  EDITION_CONTENT_ORDERING = '_EditionContentOrdering',
  EDITION_COUNTRY_MAPPING_ORDERING = '_EditionCountryMappingOrdering',
  EDITION_DATA_TRANSLATION_MAPPING_ORDERING = '_EditionDataTranslationMappingOrdering',
  EDITION_DEAL_MAPPING_ORDERING = '_EditionDealMappingOrdering',
  EDITION_DEMOGRAPHIC_ANSWER_ORDERING = '_EditionDemographicAnswerOrdering',
  EDITION_DEMOGRAPHIC_QUESTION_ORDERING = '_EditionDemographicQuestionOrdering',
  EDITION_EXHIBITOR_CONTACT_MAPPING_ORDERING = '_EditionExhibitorContactMappingOrdering',
  EDITION_EXHIBITOR_MAPPING_ORDERING = '_EditionExhibitorMappingOrdering',
  EDITION_EXHIBITOR_URL_MAPPING_ORDERING = '_EditionExhibitorUrlMappingOrdering',
  EDITION_EXTRA_PROPERTY_CONFIGURATION_MAPPING_ORDERING = '_EditionExtraPropertyConfigurationMappingOrdering',
  EDITION_EXTRA_PROPERTY_MAPPING_ORDERING = '_EditionExtraPropertyMappingOrdering',
  EDITION_EXTRA_PROPERTY_SECTION_MAPPING_ORDERING = '_EditionExtraPropertySectionMappingOrdering',
  EDITION_FEATURE_ORDERING = '_EditionFeatureOrdering',
  EDITION_FILE_RESOURCE_MAPPING_ORDERING = '_EditionFileResourceMappingOrdering',
  EDITION_HANDOUT_MAPPING_ORDERING = '_EditionHandoutMappingOrdering',
  EDITION_LARGE_PRODUCT_IMAGE_MAPPING_ORDERING = '_EditionLargeProductImageMappingOrdering',
  EDITION_LARGE_PRODUCT_MAPPING_ORDERING = '_EditionLargeProductMappingOrdering',
  EDITION_ORDERING = '_EditionOrdering',
  EDITION_PRODUCT_IMAGE_MAPPING_ORDERING = '_EditionProductImageMappingOrdering',
  EDITION_PRODUCT_MAPPING_ORDERING = '_EditionProductMappingOrdering',
  EDITION_PROFILE_ROLE_MAPPING_ORDERING = '_EditionProfileRoleMappingOrdering',
  EDITION_PROFILE_ROLE_ORDERING = '_EditionProfileRoleOrdering',
  EDITION_REGISTRATION_CATEGORY_MAPPING_ORDERING = '_EditionRegistrationCategoryMappingOrdering',
  EDITION_REGISTRATION_CATEGORY_ORDERING = '_EditionRegistrationCategoryOrdering',
  EDITION_REGISTRATION_MAPPING_ORDERING = '_EditionRegistrationMappingOrdering',
  EDITION_REGISTRATION_ORDERING = '_EditionRegistrationOrdering',
  EDITION_SESSION_MAPPING_ORDERING = '_EditionSessionMappingOrdering',
  EDITION_SPEAKER_MAPPING_ORDERING = '_EditionSpeakerMappingOrdering',
  EDITION_SPEAKER_ROLE_MAPPING_ORDERING = '_EditionSpeakerRoleMappingOrdering',
  EDITION_SUB_EDITION_MAPPING_ORDERING = '_EditionSubEditionMappingOrdering',
  EDITION_SURVEY_MAPPING_ORDERING = '_EditionSurveyMappingOrdering',
  EDITION_SURVEY_ORDERING = '_EditionSurveyOrdering',
  EDITION_SURVEY_QUESTION_CHOICE_MAPPING_ORDERING = '_EditionSurveyQuestionChoiceMappingOrdering',
  EDITION_SURVEY_QUESTION_CHOICE_ORDERING = '_EditionSurveyQuestionChoiceOrdering',
  EDITION_SURVEY_QUESTION_MAPPING_ORDERING = '_EditionSurveyQuestionMappingOrdering',
  EDITION_SURVEY_QUESTION_ORDERING = '_EditionSurveyQuestionOrdering',
  EDITION_SURVEY_USER_ANSWER_MAPPING_ORDERING = '_EditionSurveyUserAnswerMappingOrdering',
  EDITION_SURVEY_USER_ANSWER_ORDERING = '_EditionSurveyUserAnswerOrdering',
  EDITION_USER_INTEREST_MAPPING_ORDERING = '_EditionUserInterestMappingOrdering',
  EDITION_USER_INTEREST_ORDERING = '_EditionUserInterestOrdering',
  EDITION_USER_MAPPING_ORDERING = '_EditionUserMappingOrdering',
  EDITION_USER_ORDERING = '_EditionUserOrdering',
  EDITION_USER_PROFILE_ROLE_MAPPING_ORDERING = '_EditionUserProfileRoleMappingOrdering',
  EDITION_USER_PROFILE_ROLE_ORDERING = '_EditionUserProfileRoleOrdering',
  EMAIL_LOG_ORDERING = '_EmailLogOrdering',
  EMAIL_SCHEMA_ORDERING = '_EmailSchemaOrdering',
  EMAIL_UNSUBSCRIBE_ORDERING = '_EmailUnsubscribeOrdering',
  EMAIL_WIDGET_ORDERING = '_EmailWidgetOrdering',
  ENTITY_TYPE_ORDERING = '_EntityTypeOrdering',
  EXHIBIT_HALL_ORDERING = '_ExhibitHallOrdering',
  EXHIBITOR_CONTACT_ORDERING = '_ExhibitorContactOrdering',
  EXHIBITOR_ORDERING = '_ExhibitorOrdering',
  EXHIBITOR_URL_ORDERING = '_ExhibitorUrlOrdering',
  EXHIBITOR_TAG_ORDERING = '_ExhibitorTagOrdering',
  EXTRA_PROPERTY_CONFIGURATION_ORDERING = '_ExtraPropertyConfigurationOrdering',
  EXTRA_PROPERTY_ORDERING = '_ExtraPropertyOrdering',
  EXTRA_PROPERTY_SECTION_ORDERING = '_ExtraPropertySectionOrdering',
  FEATURE_ORDERING = '_FeatureOrdering',
  FEED_ITEM_ORDERING = '_FeedItemOrdering',
  FEED_ITEM_WRAPPER_ORDERING = '_FeedItemWrapperOrdering',
  FEED_POST_LINK_ORDERING = '_FeedPostLinkOrdering',
  FEED_POST_MEDIA_ORDERING = '_FeedPostMediaOrdering',
  FEED_POST_ORDERING = '_FeedPostOrdering',
  FEED_POST_REPORT_ORDERING = '_FeedPostReportOrdering',
  FILE_RESOURCE_ORDERING = '_FileResourceOrdering',
  GEOZONE_ORDERING = '_GeozoneOrdering',
  HANDOUT_ORDERING = '_HandoutOrdering',
  INITIATOR_ORDERING = '_InitiatorOrdering',
  KEY_VALUE_ORDERING = '_KeyValueOrdering',
  LARGE_PRODUCT_IMAGE_ORDERING = '_LargeProductImageOrdering',
  LARGE_PRODUCT_ORDERING = '_LargeProductOrdering',
  LINKABLE_ORDERING = '_LinkableOrdering',
  LOCALE_ORDERING = '_LocaleOrdering',
  MASTER_COMMUNITY_ORDERING = '_MasterCommunityOrdering',
  MASTER_CONTENT_ORDERING = '_MasterContentOrdering',
  MASTER_DOMAIN_ORDERING = '_MasterDomainOrdering',
  MASTER_EDITION_ORDERING = '_MasterEditionOrdering',
  MEETING_ORDERING = '_MeetingOrdering',
  MEETING_PARTICIPANT_ORDERING = '_MeetingParticipantOrdering',
  MEETING_REQUEST_ORDERING = '_MeetingRequestOrdering',
  MESSAGE_GROUP_GUEST_TOKEN_ORDERING = '_MessageGroupGuestTokenOrdering',
  MESSAGE_GROUP_ORDERING = '_MessageGroupOrdering',
  MESSAGE_GROUP_STATE_ORDERING = '_MessageGroupStateOrdering',
  MESSAGE_ORDERING = '_MessageOrdering',
  MOBILE_SHOWROOM_CONFIG_ORDERING = '_MobileShowroomConfigOrdering',
  NODE_ORDERING = '_NodeOrdering',
  NOTIFICATION_ORDERING = '_NotificationOrdering',
  ORDER_ORDERING = '_OrderOrdering',
  ORGANIZER_ORDERING = '_OrganizerOrdering',
  POLL_ANSWER_ORDERING = '_PollAnswerOrdering',
  POLL_ORDERING = '_PollOrdering',
  POLL_USER_ANSWER_ORDERING = '_PollUserAnswerOrdering',
  PRODUCT_IMAGE_ORDERING = '_ProductImageOrdering',
  PRODUCT_ORDERING = '_ProductOrdering',
  PROFILE_ROLE_ORDERING = '_ProfileRoleOrdering',
  PROMO_SLOT_ORDERING = '_PromoSlotOrdering',
  RECOMMENDED_ORDERING = '_RecommendedOrdering',
  ROLE_ORDERING = '_RoleOrdering',
  SEARCH_RESULT_ORDERING = '_SearchResultOrdering',
  SALES_PACKAGE_ORDERING = '_SalesPackageOrdering',
  SECURITY_PRINCIPAL_ORDERING = '_SecurityPrincipalOrdering',
  SESSION_ORDERING = '_SessionOrdering',
  SPEAKER_ORDERING = '_SpeakerOrdering',
  SPEAKER_ROLE_ORDERING = '_SpeakerRoleOrdering',
  SPONSOR_ORDERING = '_SponsorOrdering',
  SPONSOR_ROLE_ORDERING = '_SponsorRoleOrdering',
  SUB_EDITION_ORDERING = '_SubEditionOrdering',
  SUB_EDITION_USER_ROLE_ORDERING = '_SubEditionUserRoleOrdering',
  SURVEY_ORDERING = '_SurveyOrdering',
  SURVEY_QUESTION_CHOICE_ORDERING = '_SurveyQuestionChoiceOrdering',
  SURVEY_QUESTION_ORDERING = '_SurveyQuestionOrdering',
  SURVEY_USER_ANSWER_ORDERING = '_SurveyUserAnswerOrdering',
  TOPIC_ORDERING = '_TopicOrdering',
  UI_MENU_ITEM_ORDERING = '_UiMenuItemOrdering',
  UI_MENU_ORDERING = '_UiMenuOrdering',
  UI_ONBOARDING_ORDERING = '_UiOnboardingOrdering',
  UI_ONBOARDING_WIDGET_ORDERING = '_UiOnboardingWidgetOrdering',
  UI_PAGE_ORDERING = '_UiPageOrdering',
  UI_PAGE_WIDGET_ORDERING = '_UiPageWidgetOrdering',
  VENUE_ORDERING = '_VenueOrdering',
  EXHIBITOR_VIDEO_ORDERING = '_ExhibitorVideoOrdering',
  SCHEDULE_EVENT_ORDERING = '_ScheduleEventOrdering'
}

export default GqlEntityOrderingType;
