import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import Survey from '@/models/graphql/Survey';
import SurveyRepository from '@/repositories/SurveyRepository';
import LoadableStore from '@/store/LoadableStore';
import { SurveyFilter } from '@/graphql/_Filters/SurveyFilter';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { deepSearch } from '@/utils/ObjectHelpers';

interface SurveyState extends LoadableState {
  surveys: Survey[];
  stateSurvey: Survey;
}

@Module({ namespaced: true })
export default class SurveyStore extends LoadableStore<SurveyState> {
  surveys: Survey[] = [] as Survey[];

  stateSurvey: Survey = {} as Survey;

  private readonly surveyRepository = new SurveyRepository();

  get survey(): Survey | null {
    return this.stateSurvey;
  }

  get fetchSurveys(): Survey[] {
    return this.surveys;
  }

  protected get repository(): SurveyRepository {
    return this.surveyRepository;
  }

  @Action
  get(payload: { filter: SurveyFilter; fragmentName?: string; loneQuery?: boolean }): Promise<Survey | undefined> {
    this.context.commit('load', true);
    const { authUser } = this.context.rootGetters;
    return this.repository.get({
      definition: buildQueryDefinition({
        cacheable: !authUser
          || (!deepSearch(payload.filter, authUser.uid)
        && !deepSearch(payload.filter, '%authUser%')),
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.SURVEY_FILTER,
        },
      }),
      fragmentName: payload.fragmentName || 'surveyBaseFragment',
    }).then((response) => {
      if (!payload.loneQuery) {
        this.context.commit('setElement', response);
      }
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  filter(payload: { filter: SurveyFilter; fragmentName?: string }): Promise<Survey[]> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.SURVEY_FILTER,
        },
      }),
      fragmentName: payload.fragmentName || 'surveyBaseFragment',
    }).then((response) => {
      this.context.commit('setElements', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  getWithQuestions(payload: { filter: SurveyFilter }): Promise<Survey | undefined> {
    this.context.commit('load', true);
    const { authUser } = this.context.rootGetters;
    return this.repository.get({
      definition: buildQueryDefinition({
        cacheable: !!authUser
          || (!deepSearch(payload.filter, authUser.uid)
            && !deepSearch(payload.filter, '%authUser%')),
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.SURVEY_FILTER,
        },
      }),
      fragmentName: 'surveyWithQuestionsFragment',
    }).then((response) => {
      this.context.commit('setElement', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Mutation
  setElements(list: Survey[]): void {
    this.surveys = list;
  }

  @Mutation
  setElement(survey: Survey): void {
    this.stateSurvey = survey;
  }
}
