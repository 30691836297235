
















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import Notification from '@/models/graphql/Notification';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
  format,
  fromUnixTime,
} from 'date-fns';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import ButtonComponent from '@/components/ButtonComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import { RawLocation } from 'vue-router';
import Comment from '@/models/graphql/Comment';

@Component({
  components: {
    FontAwesomeComponent,
    ButtonComponent,
    ButtonIconComponent,
    AvatarSoloWidget,
  },
})
export default class NotificationCommentPostComponent extends Vue {
  @Getter
  private readonly community!: Community;

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @State
  private dateLocale!: Locale;

  @Prop({ required: true })
  private notification!: Notification;

  private get fullName(): string {
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated) {
        return hydrated.fullName;
      }
    }
    return '';
  }

  private get firstName(): string {
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated && hydrated.firstName) {
        return hydrated.firstName;
      }
    }
    return '';
  }

  private get lastName(): string {
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated && hydrated.lastName) {
        return hydrated.lastName;
      }
    }
    return '';
  }

  private get image(): string | null {
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated) {
        return hydrated.profilePicture;
      }
    }
    return null;
  }

  private get navigateTo(): RawLocation {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.POST_COMMENT) {
      const comment = this.notification.triggered as Comment;
      if (comment?.post?._feedItem?.wrapper) {
        return {
          name: 'post-comment-detail',
          query: { commentId: comment.uid },
          // eslint-disable-next-line no-underscore-dangle
          params: { postId: comment.post._feedItem.wrapper.uid },
        };
      }
    }
    return '';
  }

  private get content(): string {
    return (this.notification.triggered as Comment).textContent || '';
  }

  private get dateReceived(): string {
    const today = DateTimeHelper.getCurrentDateTime();
    const createdTimeDate = fromUnixTime(this.notification.createdTimestamp as number);
    if (differenceInSeconds(today, createdTimeDate) < 60) {
      return `${this.$t('my-notification-list-component.time-ago.now')}`;
    }

    const diffInMinutes = differenceInMinutes(today, createdTimeDate);
    if (diffInMinutes < 60) {
      return `${this.$t('my-notification-list-component.time-ago.minutes', {
        number: diffInMinutes,
      })}`;
    }

    const diffInDays = differenceInDays(today, createdTimeDate);
    if (diffInDays === 1) {
      return ` ${this.$t('my-notification-list-component.time-ago.yesterday')}`;
    }
    if (diffInDays < 1) {
      return `${this.$t('my-notification-list-component.time-ago.hours', {
        number: differenceInHours(today, createdTimeDate),
      })}`;
    }
    if (diffInDays < 7) {
      return `${this.$t('my-notification-list-component.time-ago.days', { number: diffInDays })}`;
    }

    if (differenceInYears(today, createdTimeDate) === 0) {
      return format(createdTimeDate, `${this.$t('app.date.monthDayShort')}`, {
        locale: this.dateLocale,
      });
    }
    return format(createdTimeDate, `${this.$t('app.date.defaultDateFormat')}`, {
      locale: this.dateLocale,
    });
  }

  private closeNotificationModal(): void {
    this.$emit('on-close-notification');
  }

  private onInteractedWith(close = true): void {
    if (close) {
      this.closeNotificationModal();
    }
    if (!this.notification.interactedWith) {
      this.$emit('on-interacted-with', this.notification.uid);
    }
  }

  private onDelete(): void {
    this.$emit('on-delete', this.notification.uid);
  }
}
