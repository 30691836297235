import { render, staticRenderFns } from "./MicroPollWidget.vue?vue&type=template&id=0e990550&scoped=true&"
import script from "./MicroPollWidget.vue?vue&type=script&lang=ts&"
export * from "./MicroPollWidget.vue?vue&type=script&lang=ts&"
import style0 from "../assets/styles/MicroPollWidget.scss?vue&type=style&index=0&id=0e990550&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e990550",
  null
  
)

export default component.exports