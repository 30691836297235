import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import RootState from '@/store/states/RootState';
import { Data } from '@/utils/types/WidgetData';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import CommunityUserProfileRole from '@/models/graphql/CommunityUserProfileRole';

@Module({ namespaced: true, stateFactory: true })
export default class MyProfileRolesWidgetStore extends WidgetBaseStore {
  private profileRoles: CommunityUserProfileRole[] = [];

  @Action
  setDataConfigs(): void {
    if (this.payload) {
      const { type } = this.payload;
      super.setDataConfigs([{
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: {
              relation: type,
              user: { uid: '%authUser%' },
            },
            type: GqlEntityFilterType.COMMUNITY_USER_PROFILE_ROLE_FILTER,
          },
        }),
        fragmentName: 'communityUserProfileRoleBaseFragment',
        operation: 'communityUserProfileRole',
        alias: this.widgetStoreName,
      }, {
        gqlDefinition: buildQueryDefinition({
          filter: {},
          orderBy: {
            value: ['name_asc'],
            type: GqlEntityOrderingType.PROFILE_ROLE_ORDERING,
          },
        }),
        fragmentName: 'profileRoleFullFragment',
        operation: 'profileRole',
        alias: `${this.widgetStoreName}_profileRoles`,
      }]);
    }
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string; rootState?: RootState}): void {
    if (data.key.split('_').length > 1) {
      this.profileRoles = data.values as unknown as CommunityUserProfileRole[];
    } else if (data.values
        && data.values.length > 0
        && data.rootState
        && data.rootState.authUser
        && data.rootState.authUser.profileRoles
    ) {
      (data.values as unknown as CommunityUserProfileRole[]).forEach((role) => {
        if (data.rootState
          && data.rootState.authUser
          && data.rootState.authUser.profileRoles
            .filter((myRole) => myRole.uid === role.uid).length === 0) {
          data.rootState.authUser.profileRoles.push(role);
        }
      });
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      profileRoles: this.profileRoles,
    };
  }
}
