import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import LargeProductImageRepository from '@/repositories/LargeProductImageRepository';
import LargeProductImage from '@/models/graphql/LargeProductImage';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class LargeProductImageStore extends LoadableStore<LoadableState> {
  private readonly largeProductImageRepository = new LargeProductImageRepository();

  protected get repository(): LargeProductImageRepository {
    return this.largeProductImageRepository;
  }

  @Action
  createLargeProductImagesForLargeProduct(payload: {
    largeProductUid: string;
    images: Array<Record<string, string>>;
  }): Promise<void> {
    const images = payload.images.filter((img) => !!img);
    if (images.length > 0) {
      const largeProductImageDefinitions = images
        .map((image, index) => ({
          operation: 'LargeProductImageCreateForLargeProduct',
          gqlDefinition: buildMutationDefinition([
            {
              fieldName: 'largeProduct_LargeProductUid',
              type: GqlEntityInputType.ID,
              value: payload.largeProductUid,
            },
            {
              fieldName: 'entity',
              type: GqlEntityInputType.LARGE_PRODUCT_IMAGE_INPUT,
              value: {
                fullFileResourceBase64: image.base64Path,
                fullFileResourceFilename: image.fileName,
              },
            },
          ]),
          fragmentName: 'largeProductImageBaseFragment',
          alias: `add${index}`,
        }));
      return this.repository.createLargeProductImagesForLargeProduct({
        definitions: largeProductImageDefinitions,
      }).then(() => Promise.resolve());
    }
    return Promise.resolve();
  }

  @Action
  deleteLargeProductImagesForLargeProduct(images: Array<LargeProductImage>): Promise<void> {
    const imgs = images.filter((img) => !!img);
    if (imgs.length > 0) {
      const largeProductImageDefinitions = images
        .map((largeProductImage, index) => ({
          operation: 'LargeProductImageDelete',
          gqlDefinition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: GqlEntityInputType.ID,
              value: largeProductImage.uid,
            },
          ]),
          fragmentName: 'largeProductImageBaseFragment',
          alias: `delete${index}`,
        }));
      return this.repository.deleteLargeProductImagesForLargeProduct({
        definitions: largeProductImageDefinitions,
      }).then((deletedLargeProductImages) => Promise.all(Object.values(deletedLargeProductImages)
        .map((largeProductImage) => {
          const promises = [];
          if (largeProductImage.fullFileResource) {
            promises.push(this.context.dispatch(
              'FileResourceStore/deleteFileResource',
              largeProductImage.fullFileResource.uid,
              { root: true },
            ));
          }
          if (largeProductImage.thumbFileResource) {
            promises.push(this.context.dispatch(
              'FileResourceStore/deleteFileResource',
              largeProductImage.thumbFileResource.uid,
              { root: true },
            ));
          }
          return promises;
        }))).then(() => Promise.resolve());
    }
    return Promise.resolve();
  }
}
