import { ApolloQueryResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import SalesPackage from '@/models/graphql/SalesPackage';
import ReadInterface from '@/repositories/base/ReadInterface';

/* eslint-disable max-len */
export default class SalesPackageRepository extends GraphQlRepository<SalesPackage>
  implements ReadInterface<SalesPackage> {
  filter(params: GqlPayloadParams): Promise<SalesPackage[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterSalesPackage',
      operation: 'salesPackage',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'salesPackageBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ salesPackage: Array<SalesPackage> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ salesPackage: Array<SalesPackage> }>) => response.data.salesPackage));
  }

  get(params: GqlPayloadParams): Promise<SalesPackage> {
    return buildQueryGql({
      operationName: params.operationName || 'GetSalesPackage',
      operation: 'salesPackage',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'salesPackageBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ salesPackage: Array<SalesPackage> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ salesPackage: Array<SalesPackage> }>) => response.data.salesPackage[0]));
  }
}
