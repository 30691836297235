import { render, staticRenderFns } from "./DetailView.vue?vue&type=template&id=c3906782&scoped=true&"
import script from "./DetailView.vue?vue&type=script&lang=ts&"
export * from "./DetailView.vue?vue&type=script&lang=ts&"
import style0 from "../../../assets/styles/toolbox/agenda/ToolboxAgendaContainerDetailView.scss?vue&type=style&index=0&id=c3906782&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3906782",
  null
  
)

export default component.exports