














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MessageBoxDateSeparatorComponent extends Vue {
  @Prop({ required: true })
  private date!: Date;

  @Prop({ default: false })
  private readonly darkMode!: boolean;

  private get formattedDate(): string {
    const today = new Date();
    if (this.date.getDate() === today.getDate()
      && this.date.getMonth() === today.getMonth()
      && this.date.getFullYear() === today.getFullYear()) {
      return `${this.$t('chat.content.messages.today')}`;
    }
    return this.date.toLocaleString(this.$i18n.locale.split('_')[0], {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }
}
