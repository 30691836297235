/* eslint-disable import/no-cycle */
import MessageGroup from '@/models/graphql/MessageGroup';
import MessageType from '@/utils/enums/chat/MessageType';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import BaseModel from '@/models/BaseModel';
import CommunityUser from '@/models/graphql/CommunityUser';
import Reaction from '@/models/graphql/Reaction';
import Product from '@/models/graphql/Product';
import LargeProduct from '@/models/graphql/LargeProduct';
import Deal from '@/models/graphql/Deal';

export default class Message extends BaseModel {
  schemaCode?: string;

  tempId?: string;

  type?: MessageType;

  content?: string;

  senttime?: string;

  senttimestamp?: number;

  user?: CommunityUser;

  group?: MessageGroup;

  toUserRead?: boolean;

  toUserView?: boolean;

  childMessages: Message[] = [];

  parentMessage?: Message;

  userDeleted?: boolean;

  ogMetaData: Record<string, Record<string, string>> = {};

  reactions: Array<Reaction> = [];

  target: Deal | Product | LargeProduct | null = null;

  nbReactions = 0;

  myReactions: Array<Reaction> = [];

  get messageType(): MessageType {
    return this.type || MessageType.REGULAR;
  }

  get date(): Date {
    return this.senttime ? DateTimeHelper.toLocal(new Date(this.senttime as string)) : new Date();
  }

  static hydrate(item: object): Message {
    const result = new Message();
    Object.assign(result, item);

    if (result.user) {
      result.user = CommunityUser.hydrate(result.user);
    }

    return result;
  }
}
