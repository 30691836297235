import { Action, Module, Mutation } from 'vuex-module-decorators';
import Order from '@/models/graphql/Order';
import LoadableStore from '@/store/LoadableStore';
import LocalStorageCartRepository from '@/repositories/LocalStorageCartRepository';
import Cart from '@/models/LocalStorage/Cart';
import PackageInformation from '@/models/LocalStorage/PackageInformation';

@Module({ namespaced: true })
export default class LocalStorageCartStore extends LoadableStore<Cart> {
  private readonly localStorageCartRepository = new LocalStorageCartRepository();

  currentCartUid = '';

  currentCart: Cart = {} as Cart;

  protected get repository(): LocalStorageCartRepository {
    return this.localStorageCartRepository;
  }

  get numberOfItemInCart(): number {
    return (this.currentCart && this.currentCart?.packagesInformation) ? this.currentCart?.packagesInformation.length : 0;
  }

  get currentCartData(): Cart {
    return this.currentCart;
  }

  @Mutation
  setElement(cart: Cart): void {
    this.currentCart = cart;
  }

  @Mutation
  setCurrentUid(uid: string): void {
    this.currentCartUid = uid;
  }

  @Mutation
  addToCartMutation(newElement: PackageInformation): void {
    this.currentCart.packagesInformation.push(newElement);
  }

  @Mutation
  increaseCartMutation(payload: {packageToUpdate: string; value: number }): void {
    this.currentCart.packagesInformation.find(
      // eslint-disable-next-line array-callback-return
      (e: PackageInformation) => {
        if (e.uid === payload.packageToUpdate) {
          e.quantity = payload.value;
        }
      },
    );
  }

  @Mutation
  delete(packageUid: string): void {
    this.currentCart.packagesInformation = this.currentCart.packagesInformation.filter(
      (e: PackageInformation) => e.uid !== packageUid,
    );
  }

  @Mutation
  reset(): void {
    this.currentCart.packagesInformation = [];
  }

  @Action
  addToCart(payload: {packageUid: string; quantity?: number}): void {
    this.context.commit('load', true);
    const cartElement = this.currentCart.packagesInformation.find((e: PackageInformation) => e.uid === payload.packageUid);
    const newElement: PackageInformation = {
      uid: payload.packageUid,
      quantity: payload.quantity || 1,
    };
    if (!cartElement) {
      this.context.commit('addToCartMutation', newElement);
    }
    this.context.dispatch('saveCart');
    this.context.commit('load', false);
  }

  @Action
  increaseCart(payload: {packageUid: string; value: number }): void {
    this.context.commit('load', true);
    this.context.commit('increaseCartMutation', {
      packageToUpdate: payload.packageUid,
      value: payload.value,
    });
    this.context.dispatch('saveCart');
    this.context.commit('load', false);
  }

  @Action
  deletePackages(packageUid: string): void {
    this.context.commit('load', true);
    this.context.commit('delete', packageUid);
    this.context.dispatch('saveCart');
    this.context.commit('load', false);
  }

  @Action
  resetCart(cartUid: string): void {
    if (!this.currentCart) {
      this.context.dispatch('setCart', cartUid);
    }
    this.context.commit('load', true);
    this.context.commit('reset');
    this.context.dispatch('saveCart');
    this.context.commit('load', false);
  }

  @Action
  setCartWithData(payload: {cart: Order; cartUid: string}): string {
    if (!this.currentCart) {
      this.context.dispatch('setCart', payload.cartUid);
    }
    this.context.dispatch('resetCart', payload.cart.exhibitor?.uid || '');
    if (payload.cart.orderPackages) {
      payload.cart.orderPackages.forEach((orderPackage) => {
        if (payload.cart.exhibitor && orderPackage.salesPackage) {
          this.context.dispatch('addToCart', {
            packageUid: orderPackage.salesPackage.uid,
            quantity: orderPackage.quantity,
          });
        }
      });
    }
    return (payload.cartUid) ? payload.cartUid : '';
  }

  @Action
  setCart(exhibitorUid: string): void {
    this.context.commit('load', true);
    this.context.commit('setCurrentUid', exhibitorUid);
    const cart = this.repository.setCart(exhibitorUid);
    this.context.commit('setElement', cart);
    this.context.commit('load', false);
  }

  @Action
  saveCart(): void{
    this.repository.saveCart({
      cartUid: this.currentCartUid,
      cart: this.currentCart,
    });
  }
}
