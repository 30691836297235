import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import PollUserAnswer from '@/models/graphql/PollUserAnswer';
import PollUserAnswerRepository from '@/repositories/PollUserAnswerRepository';

interface PollUserAnswerState extends LoadableState {
  pollUserAnswers: PollUserAnswer[];
  statePollUserAnswer: PollUserAnswer;
}

@Module({ namespaced: true })
export default class PollUserAnswerStore extends LoadableStore<PollUserAnswerState> {
  pollUserAnswers: PollUserAnswer[] = [] as PollUserAnswer[];

  statePollUserAnswer: PollUserAnswer = {} as PollUserAnswer;

  private readonly pollUserAnswerRepository = new PollUserAnswerRepository();

  get pollUserAnswer(): PollUserAnswer | null {
    return this.statePollUserAnswer;
  }

  get fetchPollUserAnswers(): PollUserAnswer[] {
    return this.pollUserAnswers;
  }

  protected get repository(): PollUserAnswerRepository {
    return this.pollUserAnswerRepository;
  }

  @Action
  create(payload: {userUid: string; pollAnswerUid: string}): Promise<PollUserAnswer | undefined> {
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'pollAnswer_PollAnswerUid',
          type: 'ID',
          value: payload.pollAnswerUid,
        },
        {
          fieldName: 'user_CommunityUserUid',
          type: 'ID',
          value: payload.userUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.POLL_USER_ANSWER_INPUT,
          value: {},
        },
      ]),
    });
  }

  @Action
  delete(payload: {pollAnswerUid: string}): Promise<PollUserAnswer | undefined> {
    return this.repository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: payload.pollAnswerUid,
        },
      ]),
    });
  }

  @Mutation
  setElements(list: PollUserAnswer[]): void {
    this.pollUserAnswers = list;
  }

  @Mutation
  setElement(pollUserAnswer: PollUserAnswer): void {
    this.statePollUserAnswer = pollUserAnswer;
  }
}
