var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'search-input d-flex align-items-center justify-content-center',
    _vm.color,
    _vm.size,
    { 'w-100': _vm.isFullWidth },
    { 'p-3': _vm.size === 'lg' },
    { 'px-3 py-2': _vm.size === 'md' },
    { 'px-2 py-1': _vm.size === 'sm' },
    { 'bg-white-white-100': _vm.color === 'white' },
    { 'bg-neutral-n-2-background': _vm.color === 'gray' },
    { 'border-rounded': _vm.rounded },
    { 'rounded-sm': !_vm.rounded && (_vm.size === 'md' || _vm.size === 'sm') },
    { 'rounded': !_vm.rounded && _vm.size === 'lg' },
    { 'dark-border': _vm.darkBorder }
  ],on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.clear.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch.apply(null, arguments)}]}},[(!_vm.hideMagnifyingGlass)?_c('div',[_c('font-awesome-component',{attrs:{"container-classes":_vm.size === 'sm' ? 'icon-16' : 'icon-24',"icon":"fa-regular fa-magnifying-glass","icon-classes":"text-neutral-n-5-placeholder"}})],1):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"search",class:[
        'search-field text-neutral-n-8-dark-ink w-100 border-0 bg-transparent py-0',
        _vm.size === 'sm' ? 'label label-3' : 'paragraph'
      ],attrs:{"autofocus":_vm.autofocus,"placeholder":_vm.placeholder,"type":"text"},domProps:{"value":(_vm.query)},on:{"click":function($event){return _vm.$emit('on-click')},"input":[function($event){if($event.target.composing){ return; }_vm.query=$event.target.value},_vm.onSearch],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('search-query-enter-key', { query: _vm.query })}}}),(_vm.query)?_c('div',{staticClass:"cursor-pointer"},[_c('font-awesome-component',{attrs:{"container-classes":_vm.size === 'sm' ? 'icon-16' : 'icon-24',"stop-propagation":true,"icon":"fa-solid fa-circle-x","icon-classes":"text-neutral-n-5-placeholder"},on:{"on-click":_vm.clear}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }