enum Size {
  NANO = 'xxs',
  MICRO = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  XLARGE = 'xlg'
}

export default Size;
