import { ApolloQueryResult, FetchResult } from '@apollo/client';
import CommunityUserFollow from '@/models/graphql/CommunityUserFollow';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import createInterface from '@/repositories/base/CreateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';
import GetterInterface from '@/repositories/base/GetterInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';

/* eslint-disable max-len */
export default class CommunityUserFollowRepository extends GraphQlRepository<CommunityUserFollow>
  implements GetterInterface<CommunityUserFollow>,
    createInterface<CommunityUserFollow>,
    DeleteInterface<CommunityUserFollow> {
  get(params: GqlPayloadParams): Promise<CommunityUserFollow | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetCommunityUserFollow',
      operation: 'communityUserFollow',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'communityUserFollowBaseFragment',
    }).then((query) => this.query<{ communityUserFollow: Array<CommunityUserFollow> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ communityUserFollow: Array<CommunityUserFollow> }>) => response.data.communityUserFollow[0]));
  }

  create(params: GqlPayloadParams): Promise<CommunityUserFollow | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateCommunityUserFollow',
      operation: 'CommunityUserFollowCreateForUserAndLinkTarget',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserFollowBaseFragment',
      alias: 'CommunityUserFollowCreate',
    }).then((mutation) => this.mutate<{ CommunityUserFollowCreate: CommunityUserFollow }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserFollowCreate: CommunityUserFollow }>) => response.data?.CommunityUserFollowCreate));
  }

  delete(params: GqlPayloadParams): Promise<CommunityUserFollow | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeleteCommunityUserFollow',
      operation: 'CommunityUserFollowDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserFollowBaseFragment',
    }).then((mutation) => this.mutate<{ CommunityUserFollowDelete: CommunityUserFollow }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserFollowDelete: CommunityUserFollow }>) => response.data?.CommunityUserFollowDelete));
  }
}
