import GDPR from '@/utils/constants/Gdpr';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import GdprCookieInterface from '@/models/GdprCookieInterface';

export default class ClientStorage {
  static getItem(key: string, defaultResult: string | null = null): string | null {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(key);
    }

    return defaultResult;
  }

  static setItem(key: string, value: string): void {
    const exceptionKey = ['device-id', AUTH_TOKEN, GDPR, 'locale'];
    if (typeof localStorage !== 'undefined') {
      const gdprSettings: GdprCookieInterface = JSON.parse(localStorage.getItem(GDPR) as string);
      if ((gdprSettings && gdprSettings.necessary) || exceptionKey.includes(key)) {
        localStorage.setItem(key, value);
      }
    }
  }

  static removeItem(key: string): void {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(key);
    }
  }
}
