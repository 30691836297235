import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import CommunityUserAgendaEntryRepository from '@/repositories/CommunityUserAgendaEntryRepository';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';

@Module({ namespaced: true })
/* eslint-disable max-len */
export default class CommunityUserAgendaEntryStore extends LoadableStore<LoadableState> {
  private readonly communityUserAgendaEntryRepository = new CommunityUserAgendaEntryRepository();

  protected get repository(): CommunityUserAgendaEntryRepository {
    return this.communityUserAgendaEntryRepository;
  }

  @Action
  addToAgenda(payload: CommunityUserAgendaEntryFilter): Promise<CommunityUserAgendaEntry | undefined> {
    this.context.commit('load', true);
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'user_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: payload.userId,
        },
        {
          fieldName: 'linkTarget_LinkableUid',
          type: GqlEntityInputType.ID,
          value: payload.linkedUserId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMUNITY_USER_AGENDA_ENTRY_INPUT,
          value: { targetType: payload.entityType },
        },
      ]),
    }).then((response) => {
      if (response && payload.event) {
        this.context.dispatch(
          'AgendaStore/addToAgenda',
          {
            ...payload.event,
            isInAgenda: response.uid,
          },
          { root: true },
        );
      }
      return response;
    }).finally(() => this.context.commit('load', false));
  }

  @Action
  removeFromAgenda(payload: CommunityUserAgendaEntryFilter): Promise<CommunityUserAgendaEntry | undefined> {
    this.context.commit('load', true);
    return this.repository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.uid,
        },
      ]),
    }).then((response) => {
      if (payload.event) {
        this.context.dispatch(
          'AgendaStore/removeFromAgenda',
          payload.event,
          { root: true },
        );
      }
      return response;
    }).finally(() => this.context.commit('load', false));
  }
}
