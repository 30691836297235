import { FetchResult } from '@apollo/client';
import CommunityUser from '@/models/graphql/CommunityUser';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable max-len */
export default class CommunityUserAddressRepository extends GraphQlRepository<CommunityUser> {
  update(params: GqlPayloadParams): Promise<CommunityUser | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'SetCommunityUserAddress',
      operation: 'CommunityUserSetAddres',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserBaseFragment',
    }).then((mutation) => this.mutate<{ CommunityUserSetAddres: CommunityUser }>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserSetAddres: CommunityUser }>) => response.data?.CommunityUserSetAddres));
  }
}
