import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import WizardValidationRuleType from '@/utils/types/WizardValidationRuleType';

@Module({ namespaced: true, stateFactory: true })
export default class WizardWidgetStore extends WidgetBaseStore {
  private step = 1;

  private validatorFn: WizardValidationRuleType[] = [];

  private stepParamKey = 'step';

  get currentPos(): number {
    return this.step;
  }

  get currentStep(): UiPageWidget | undefined {
    return this.widget?.subWidgets[this.currentPos - 1];
  }

  @Mutation
  mapper(): void {
    let steps = [];
    if (this.widget && this.widget.subWidgets) {
      steps = this.widget.subWidgets.map((sub) => ({
        uid: sub.uid,
        ...JSON.parse(sub.payload || '{}'),
      }));
    }
    this.mapping = {
      ...this.payload,
      steps,
    };

    this.stepParamKey = (this.mapping as { stepKeyParam?: string }).stepKeyParam || 'step';
  }

  @Mutation
  private setStep(position: number): void {
    const params = new URLSearchParams(window.location.search);
    params.set(this.stepParamKey, String(position));
    const url = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
    window.history.pushState(params.toString(), '', url);

    const stepsCount = this.widget ? this.widget.subWidgets.length : 0;
    const result = position > stepsCount ? stepsCount : position;
    this.step = position <= 0 ? 1 : result;
  }

  @Action
  gotoStep(position: number | string): void {
    let pos = position || 1;
    if (typeof position === 'string') {
      pos = this
        .widget?.subWidgets
        .findIndex(
          (element) => JSON.parse(String(element?.payload))?.navigationCode === position
        ) || 0;
      pos += 1;
    }
    this
      .context
      .dispatch(
        'validStep',
        { from: this.currentPos, to: pos, steps: this.widget?.subWidgets },
      ).then((valid) => {
        if (!valid) {
          return;
        }
        this.context.commit('setStep', pos);
      });
  }

  @Action
  nextStep(): void {
    const position = this.currentPos + 1;
    this.context.dispatch('gotoStep', position);
  }

  @Action
  prevStep(): void {
    const position = this.currentPos - 1;
    this.context.dispatch('gotoStep', position);
  }

  @Mutation
  addValidator(validator: WizardValidationRuleType): void {
    this.validatorFn.push(validator);
  }

  @Action
  validStep(nav: { from?: number; to: number}): Promise<boolean> {
    const steps = this.widget?.subWidgets;
    return Promise.resolve(this.validatorFn.every((fn) => fn(nav.from, nav.to, steps)));
  }
}
