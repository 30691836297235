import BaseModel from '@/models/BaseModel';
import 'regenerator-runtime';
import GraphQlClients from '@/utils/enums/GraphQlClients';
import { InMemoryCache } from '@apollo/client';
import ChatTypingEvent from '@/utils/types/chat/ChatTypingEvent';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import ChatApolloClient from '@/plugins/apollo/ChatApolloClient';
import SubscriptionParams from '@/utils/types/SubscriptionParams';
import SubscriptionBaseRepository from '@/repositories/SubscriptionBaseRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildSubscriptionGql } from '@/graphql/_Tools/GqlGeneric';
import GqlSubscriptionDefinition from '@/graphql/_Tools/GqlSubscriptionDefinition';

/* eslint-disable max-len */
export default abstract class ChatBaseRepository<Model extends BaseModel>
  extends SubscriptionBaseRepository<Model> {
  private guestToken: string | null = null;

  private notGuestToken = false;

  constructor() {
    super();
    if (!this.apolloProvider.clients.has(GraphQlClients.CHAT_CLIENT)) {
      this.apolloProvider.clients.set(GraphQlClients.CHAT_CLIENT, ChatApolloClient.init(new InMemoryCache()));
    }
    this.use(GraphQlClients.CHAT_CLIENT);
  }

  get authToken(): string {
    return this.clientName === GraphQlClients.CHAT_CLIENT
    && !this.notGuestToken
    && this.guestToken
      ? this.guestToken
      : super.authToken;
  }

  eventSubscribe(payload: SubscriptionParams): Promise<{ unsubscribe: () => void }> {
    const params = payload.params as GqlPayloadParams;
    return buildSubscriptionGql({
      operationName: params.operationName || 'EventsSubscription',
      operation: 'events',
      gqlDefinition: params.definition as GqlSubscriptionDefinition,
      fragmentName: 'subscriptionChatEventsFragment',
    }).then((subscription) => this.use(GraphQlClients.CHAT_CLIENT)
      .subscribe<{ events: SubscriptionEvent }>(subscription, params.definition.variables)
      .subscribe({
        next: (value) => payload.callback(value.data?.events || null),
      }));
  }

  eventTypingSubscribe(payload: SubscriptionParams): Promise<{ unsubscribe: () => void }> {
    const params = payload.params as GqlPayloadParams;
    return buildSubscriptionGql({
      operationName: params.operationName || 'EventsTypingSubscription',
      operation: 'eventsTyping',
      gqlDefinition: params.definition as GqlSubscriptionDefinition,
      fragmentName: 'subscriptionChatEventsTypingFragment',
    }).then((subscription) => this.use(GraphQlClients.CHAT_CLIENT)
      .subscribe<{ eventsTyping: ChatTypingEvent }>(subscription, params.definition.variables)
      .subscribe({
        next: (value) => payload.callback(value.data?.eventsTyping || null),
      }));
  }
}
