












































































































import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import InputText from '@/components/InputText.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import UserSelectorModal from '@/components/modals/UserSelectorModal.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import CompanyRequestModeratorListModal
  from '@/components/modals/meeting-request/CompanyRequestModeratorListModal.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: {
    ButtonComponent,
    UserSelectorModal,
    FontAwesomeComponent,
    AvatarSoloWidget,
    InputText,
    CompanyRequestModeratorListModal,
  },
})
export default class UserSelectorField extends BreakpointWrapper {
  @Getter
  authUser!: CommunityUser;

  @Prop({ required: false, default: 'user-selector-modal' })
  private readonly modalId!: string;

  @Prop({ required: false, default: null })
  private readonly selectedModerator!: CommunityUser;

@Prop({ required: false, default: true })
  private readonly showCreator!: boolean;

  @Prop({ required: false, default: null })
  private readonly meetingRequest!: MeetingRequest;

   @Prop({ required: false, default: () => [] })
  private readonly selectedUsers!: CommunityUser[];

  @Prop({ required: false, default: () => [] })
  private readonly excludeUsers!: string[];

  private FileResourceHelper = FileResourceHelper;

  private selectedUserList: CommunityUser[] = [];

  private creator: CommunityUser | null = null;

  private modalOpened = false;

  private mounted(): void {
    this.selectedUserList = this.selectedUsers;
    if (this.selectedModerator) {
      this.creator = this.selectedModerator;
    } else {
      this.creator = this.authUser;
    }
  }

  private onInputFocus(): void {
    if (!this.modalOpened) {
      this.modalOpened = true;
      this.$bvModal.show(this.modalId);
    } else {
      (this.$refs.userSelectorInput as unknown as InputText).removeFocus();
      this.modalOpened = false;
    }
  }

  private onModalConfirm(selectedUserList: CommunityUser[]): void {
    this.selectedUserList = selectedUserList;
    this.$emit('select-change', this.selectedUserList);
    this.$bvModal.hide(this.modalId);
  }

  private isCreator(user: CommunityUser): boolean {
    if (this.selectedModerator) {
      return user.uid === this.selectedModerator.uid;
    }
    return user.uid === this.authUser.uid;
  }

  private onRemoveItemClick(user: CommunityUser): void {
    const toDelete = this.selectedUserList
      .findIndex((selectedUser: CommunityUser) => user.uid === selectedUser.uid);
    if (toDelete !== -1) {
      this.selectedUserList.splice(toDelete, 1);
    }
    this.$emit('select-change', this.selectedUserList);
  }

  private defaultCompany(user: CommunityUser): string {
    const defaultUser = CommunityUser.hydrate(user);
    if (!defaultUser.defaultCompany && !defaultUser.employerName) {
      return '';
    }
    return defaultUser.defaultCompany
      ? this.$tc('actions.at', 0, { employer: defaultUser.defaultCompany.name })
      : this.$tc('actions.at', 0, { employer: defaultUser.employerName });
  }

  private onSelectCreator(user: CommunityUser): void {
    this.$bvModal.hide('moderator-list-modal-1');
    this.creator = user;
    this.$emit('on-creator-select', user);
  }
}
