enum SurveyStrategy {
  SESSION_SURVEY = 'sessionSurvey',
  SPEAKER_SURVEY = 'speakerSurvey',
  EXHIBITOR_SURVEY = 'exhibitorSurvey',
  PRODUCT_SURVEY = 'productSurvey',
  FEEDBACK_SURVEY = 'feedbackSurvey',
  MICROPOLL_SURVEY = 'micropoll'
}

export default SurveyStrategy;
