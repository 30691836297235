export enum ChatErrorType {
  INTERNET,
  SERVER,
  FETCH_CONVERSATIONS,
  CREATE_CONVERSATION,
  FETCH_MESSAGES,
  SEND_MESSAGE,
  ADD_PARTICIPANT,
  DELETE_PARTICIPANT
}

export enum ChatErrorView {
  CONVERSATIONS,
  MESSAGES,
  BOTH,
  TOAST
}

export type ChatRequestType = { action: string; payload: ChatRequestPayloadType } | null;
export type ChatRequestPayloadType = Record<string, string> | object | string | null;

interface ChatError {
  type: ChatErrorType;

  view: ChatErrorView;

  group: string | 'ALL';

  request: ChatRequestType;
}

export default ChatError;
