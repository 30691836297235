



































import { Component, Prop, Vue } from 'vue-property-decorator';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import ChoiceParams from '@/utils/types/ChoiceParams';

@Component({
  components: { CheckboxSwitchComponent },
})
export default class MultiSelectComponent extends Vue {
  @Prop({ required: true })
  private readonly choices!: ChoiceParams[];

  @Prop({ required: false, default: () => [] })
  private readonly selectedChoices!: ChoiceParams[];

  private textFieldFocused: ChoiceParams | null = null;

  private isSelected(choice: ChoiceParams): boolean {
    return !!this.selectedChoices.find((c) => c.value === choice.value);
  }

  private inputValue(choice: ChoiceParams): string {
    const selected = this.selectedChoices.find((c) => c.value === choice.value);
    if (selected) {
      return selected.text;
    }
    return '';
  }

  private onSelect(choice: ChoiceParams): void {
    const choices = this.selectedChoices;
    const foundIndex = choices.findIndex((c) => c.value === choice.value);
    if (foundIndex > -1) {
      if (!this.textFieldFocused || (this.textFieldFocused && this.textFieldFocused.value !== choice.value)) {
        choices.splice(foundIndex, 1);
      }
    } else {
      const finalChoice = choice;
      if (choice.isOther) {
        finalChoice.text = '';
      }
      choices.push(finalChoice);
    }
    this.$emit('on-select', choices);
  }

  private onTextAnswerChange(text: string, choice: ChoiceParams): void {
    const choices = this.selectedChoices;
    const selected = this.selectedChoices.find((c) => c.value === choice.value);
    if (selected) {
      selected.text = text;
    }
    this.$emit('on-select', choices);
  }

  private onTextBlur(): void {
    this.textFieldFocused = null;
  }

  private onFocusIn(choice: ChoiceParams): void {
    this.textFieldFocused = choice;
  }
}
