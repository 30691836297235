import { Action, Module } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
/* eslint-disable @typescript-eslint/camelcase */

@Module({ namespaced: true, stateFactory: true })
export default class EntityTagsWidgetStore extends WidgetBaseStore {
  @Action
  setDataConfigs(): void {
    if (this.widget) {
      const {
        entityCode, entityType,
      } = JSON.parse(this.widget.payload || '{}');
      let filterType = '';
      let operation = '';
      let fragmentName = '';
      switch (entityType) {
        case 'communityUser':
          filterType = GqlEntityFilterType.COMMUNITY_USER_FILTER;
          fragmentName = 'communityUserWithTagsFragment';
          operation = 'communityUser';
          break;
        case 'exhibitor':
          filterType = GqlEntityFilterType.EXHIBITOR_FILTER;
          fragmentName = 'exhibitorWithTagsFragment';
          operation = 'exhibitor';
          break;
        case 'session':
          filterType = GqlEntityFilterType.SESSION_FILTER;
          fragmentName = 'sessionWithTagsFragment';
          operation = 'session';
          break;
        case 'product':
          filterType = GqlEntityFilterType.PRODUCT_FILTER;
          fragmentName = 'productWithTagsFragment';
          operation = 'product';
          break;
        case 'largeProduct':
          filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
          fragmentName = 'largeProductWithTagsFragment';
          operation = 'largeProduct';
          break;
        case 'boatProduct':
          filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
          fragmentName = 'largeProductBoatWithTagsFragment';
          operation = 'largeProduct';
          break;
        case 'deal':
          filterType = GqlEntityFilterType.DEAL_FILTER;
          fragmentName = 'dealWithTagsFragment';
          operation = 'deal';
          break;
        case 'subEdition':
          filterType = GqlEntityFilterType.SUB_EDITION_FILTER;
          fragmentName = 'subEditionWithTagsFragment';
          operation = 'subEdition';
          break;
        case 'article':
          filterType = GqlEntityFilterType.ARTICLE_FILTER;
          fragmentName = 'articleWithTagsFragment';
          operation = 'article';
          break;
        default:
          break;
      }
      const configs = [{
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: { uid: entityCode },
            type: filterType,
          },
        }),
        operation,
        fragmentName,
        alias: this.widgetStoreName,
      }];

      this.context.commit('setGqlQueryConfig', configs);
      this.context.commit(
        'WidgetDispatcherStore/setGqlQueryConfigs',
        { key: this.widgetStoreName, values: configs },
        { root: true },
      );
    }
  }
}
