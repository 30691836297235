import { ApolloQueryResult } from '@apollo/client';
import CategoryDomainContext from '@/models/graphql/CategoryDomainContext';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import Category from '@/models/graphql/Category';

export default class CategoryDomainContextRepository extends GraphQlRepository<CategoryDomainContext>
  implements FilterInterface<CategoryDomainContext> {
  filter(params: GqlPayloadParams): Promise<CategoryDomainContext[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterCategoryDomainsByContexts',
      operation: 'categoryDomainContext',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'categoryDomainContextBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ categoryDomainContext: Array<Category> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ categoryDomainContext: Array<Category> }>) => response.data.categoryDomainContext));
  }
}
