enum FeedType {
  COMMUNITY_USER = 'CommunityUser',
  EXHIBITOR = 'Exhibitor',
  SUB_EDITION = 'SubEdition',
  CHANNEL = 'Channel',
  PROFILE = 'Profile',
  GLOBAL = 'Global',
  TOPIC = 'Topic',
}

export default FeedType;
