import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import BusinessCardRepository from '@/repositories/BusinessCardRepository';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import LoadableStore from '@/store/LoadableStore';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class BusinessCardStore extends LoadableStore<LoadableState> {
  private readonly businessCardRepository = new BusinessCardRepository();

  protected get repository(): BusinessCardRepository {
    return this.businessCardRepository;
  }

  @Action
  sendContactInformation(payload: {userUid: string; exhibitorUid: string}): Promise<void> {
    const { userUid, exhibitorUid } = payload;
    if (userUid && exhibitorUid) {
      this.context.commit('load', true);
      return this.repository.create({
        definition: buildMutationDefinition([
          {
            fieldName: 'user_CommunityUserUid',
            type: 'ID',
            value: payload.userUid,
          },
          {
            fieldName: 'exhibitor_ExhibitorUid',
            type: 'ID',
            value: payload.exhibitorUid,
          },
          {
            fieldName: 'entity',
            type: GqlEntityInputType.BUSINESS_CARD_INPUT,
            value: {},
          },
        ]),
      }).then(() => Promise.resolve());
    }
    return Promise.resolve();
  }
}
