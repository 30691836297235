import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import { Data } from '@/utils/types/WidgetData';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import EntityType from '@/utils/enums/EntityType';

@Module({ namespaced: true, stateFactory: true })
export default class DescriptionFormWidgetStore extends WidgetBaseStore {
  private dataValues = {};

  @Action
  setDataConfigs(): void {
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    const payload = JSON.parse(this.widget?.payload || '{}');
    const {
      type,
      entityCode,
    } = payload;

    if (type && entityCode) {
      let operation: string;
      let fragmentName: string;
      const variables = { filter: {} };
      let filterType: string;

      switch (type.toLowerCase()) {
        case EntityType.SESSION.toLowerCase():
          operation = 'session';
          fragmentName = 'sessionDescriptionFragment';
          Object.assign(variables.filter, {
            uid: entityCode,
          });
          filterType = GqlEntityFilterType.SESSION_FILTER;
          break;
        default:
          operation = '';
          fragmentName = '';
          filterType = '';
      }

      configs.push({
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: variables.filter,
            type: filterType,
          },
        }),
        operation,
        fragmentName,
        alias: this.widgetStoreName,
      });

      super.setDataConfigs(configs);
    }
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    if (data.values.length === 1 && data.values[0].description) {
      [this.dataValues] = data.values;
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      data: this.dataValues,
    };
  }
}
