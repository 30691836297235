























































































































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { format } from 'date-fns';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import { Getter, namespace, State } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import MessageType from '@/utils/enums/chat/MessageType';
import Message from '@/models/graphql/Message';
import ChatErrorList from '@/utils/types/chat/ChatErrorList';
import { ChatRequestType } from '@/utils/types/chat/ChatError';
import OgService from '@/services/OgService';
import OgMetadata from '@/models/feeds/OgMetaData';
import OgImage from '@/models/feeds/OgImage';
import OgLinkCard from '@/components/cards/OgLinkCard.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { DOMAIN } from '@/utils/constants/Regex';
import ReactionType from '@/utils/enums/chat/ReactionType';
import Reaction from '@/models/graphql/Reaction';
import GUUID from '@/utils/GUUID';
import EntityType from '@/utils/enums/EntityType';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import MessageGroupPermission from '@/utils/enums/chat/MessageGroupPermission';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import LargeProduct from '@/models/graphql/LargeProduct';
import Product from '@/models/graphql/Product';
import Deal from '@/models/graphql/Deal';
import useTestDataAttribute from '@/utils/TestDataAttribute';

const communityUserStore = namespace('CommunityUserStore');

@Component({
  methods: { useTestDataAttribute },
  components: {
    DropdownMenuItem,
    FontAwesomeComponent,
    OgLinkCard,
    PillWidget,
    AvatarSoloWidget,
    LoadingSpinnerComponent,
  },
})
export default class MessageBoxBubbleComponent extends Vue {
  @Prop({ required: true })
  private message!: Message;

  @Prop({ default: false })
  private readonly darkMode!: boolean;

  @Prop({ default: false })
  private readonly noPadding!: boolean;

  @Prop({ default: MessageGroupPermission.GUEST })
  private readonly messageGroupPermission!: MessageGroupPermission;

  @Prop({ default: true })
  private readonly showAction!: boolean;

  @Prop({ default: true })
  private readonly canLike!: boolean;

  @Prop({ default: false })
  private readonly isChatClosed!: boolean;

  @Prop({ default: false })
  private readonly youAreBanned!: boolean;

  @Prop({ default: false })
  private readonly canModerate!: boolean;

  @Prop({ default: null })
  private readonly targetType!: string | null;

  @Prop({ default: null })
  private readonly targetUid!: string | null;

  @Prop({ default: 'ChatStore' })
  private readonly storeContext!: string;

  @Prop({ default: () => [] })
  private readonly sessionModerators!: CommunityUser[];

  @Getter
  private readonly authUser!: CommunityUser;

  @State
  private dateLocale!: Locale;

  @communityUserStore.Action
  private getBanStatus!: (payload: { user: CommunityUser }) => Promise<CommunityUser[] | undefined>;

  @communityUserStore.Getter
  private getLoadingState!: boolean;

  private MessageType = MessageType;

  private MessageGroupPermission = MessageGroupPermission;

  private ogService = new OgService();

  private error = false;

  private ogLinksData: object[] = [];

  private isLoading = false;

  private urlRegex = new RegExp(DOMAIN, 'gmi');

  private isHovered = false;

  private banned = false;

  private openedMenu = false;

  get isSessionChat(): boolean {
    return !!this.targetUid && !!this.targetType && this.targetType === EntityType.SESSION;
  }

  get isSessionModerator(): boolean {
    return this.isSessionChat
        && this.sessionModerators.length > 0
        && this.sessionModerators.findIndex((m) => !!this.message.user && this.message.user.uid === m.uid) > -1;
  }

  get content(): string | null {
    if (this.message
        && (this.message.messageType === MessageType.REGULAR
            || this.message.messageType === MessageType.QNA
            || this.message.messageType === MessageType.LINK)
        && this.message.content) {
      if (this.urlRegex.test(this.message.content)) {
        const matches = this.message.content.match(this.urlRegex);
        if (matches && matches.length > 0) {
          let { content } = this.message;
          matches.forEach((link) => {
            const href = /^(((https?|ftps?):)?\/\/)/i.test(link) ? link : `//${link}`;
            content = content
              .replaceAll(link,
                `<a href="${href}"
                    style="line-break: anywhere;"
                     class="text-blue-b-3-primary-blue" target="_blank">${link}</a>`);
          });
          return content;
        }
        return this.message.content;
      }
      return this.message.content;
    }
    return null;
  }

  private get messageUserName(): string {
    return `${this.message
    && this.message.user
      ? this.message.user.firstName : ''}
          ${this.message
    && this.message.user
    && this.message.user.lastName
    ? `${this.message.user.lastName.trim()
      .charAt(0)
      .toUpperCase()}.`
    : ''}`;
  }

  private get parentMessageUserName(): string {
    return `${this.parentMessage
    && this.parentMessage.user
      ? this.parentMessage.user.firstName : ''}
          ${this.parentMessage
    && this.parentMessage.user
    && this.parentMessage.user.lastName
    ? `${this.parentMessage.user.lastName.trim()
      .charAt(0)
      .toUpperCase()}.`
    : ''}`;
  }

  private get chatErrors(): ChatErrorList | null {
    return this.$store.state.ChatDispatcherStore[`${this.storeContext}`].chatErrors;
  }

  private get isYou(): boolean {
    return this.authUser && this.authUser.uid === this.message.user?.uid;
  }

  private get parentMessage(): Message | null {
    if (this.message && this.message.parentMessage) {
      return Message.hydrate(this.message.parentMessage);
    }
    return null;
  }

  private get formatTime(): string {
    if (this.message && this.message.senttime) {
      const senttime = this.message.senttime.replace('Z', '');
      const date = this.message.senttime ? new Date(`${senttime}Z`) : new Date();
      return format(date, this.$t('app.date.defaultTimeFormat') as string,
        { locale: this.dateLocale });
    }
    return '';
  }

  private get allowBasicActions(): boolean {
    return !this.isSessionChat
        || (this.isSessionChat && [
          MessageGroupPermission.STANDARD,
          MessageGroupPermission.ADMINISTRATOR,
          MessageGroupPermission.GUEST,
        ].includes(this.messageGroupPermission));
  }

  loadOGLinks(links: string[]): void {
    this.isLoading = true;
    this.ogLinksData = links.map((l) => {
      if (Object.keys(this.message.ogMetaData)
        .includes(l)) {
        return this.message.ogMetaData[l];
      }
      return {
        link: l,
        title: '',
        description: '',
        image: '',
      };
    });
    const promises = links
      .filter((link) => this.message.ogMetaData && !Object.keys(this.message.ogMetaData)
        .includes(link))
      .map((link) => this.ogService.getUrlOgData(link));
    Promise.all(promises)
      .then((responses) => {
        responses.forEach((ogMetaData) => {
          if (ogMetaData.data.success) {
            const data: Record<string, string> = this.createLink(ogMetaData);
            const index = Object.values(this.message.ogMetaData)
              .findIndex((ogL) => ogL.link === data.link);
            if (index === -1) {
              this.ogLinksData.push(data);
            } else {
              this.ogLinksData.splice(index, 1, data);
            }
          }
        });
      })
      .catch((l) => {
        const link = l.config.url.split('url-meta/')[1];
        if (link) {
          Object.assign(this.message.ogMetaData, {
            [decodeURIComponent(link)]: {
              link: decodeURIComponent(link),
              title: '',
              description: '',
              image: '',
            },
          });
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private reloadLastRequest(request?: ChatRequestType): Promise<void> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/reloadLastRequest`, request);
  }

  private createReaction(model: {
    reaction: ReactionType;
    messageId: string;
    tempId: string;
  }): void {
    this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/createReaction`, model);
  }

  private addReaction(payload: {
    reaction: Reaction;
    authUser: string;
  }): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeContext}/addReaction`, payload);
  }

  private removeReaction(reactionId: string): void {
    this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/removeReaction`, reactionId);
  }

  private deleteReaction(payload: {
    reaction: Reaction;
    authUser: string;
  }): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeContext}/deleteReaction`, payload);
  }

  private deleteMessage(): void {
    this.$emit('on-delete-message', this.message.uid);
    this.message.userDeleted = true;
    this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/deleteMessage`, this.message.uid);
  }

  private banUser(user: string): void {
    this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/banUser`, user);
  }

  private unBanUser(user: string): void {
    this.$store.dispatch(`ChatDispatcherStore/${this.storeContext}/unBanUser`, user);
  }

  private onReplyToMessage(): void {
    if (this.allowBasicActions) {
      this.$emit('on-message-to-reply', this.message);
    }
  }

  @Watch('chatErrors.errors', {
    deep: true,
    immediate: true,
  })
  private watchForErrors(): void {
    if (this.message && this.chatErrors) {
      const errors = this.chatErrors.findSendMessageErrors(this.message.group?.uid || '');
      if (this.chatErrors.haveErrors && errors.length > 0) {
        const messageRequest = errors.find((err) => err.request
            && err.request.payload
            && (err.request.payload as Record<string, string>).message === this.message.content
            && (err.request.payload as Record<string, string>).groupId === this.message.group?.uid
            && (err.request.payload as Record<string, string>).tempId === this.message.uid);
        this.error = !!messageRequest;
      } else {
        this.error = false;
      }
    }
  }

  @Watch('openedMenu')
  private loadBanStatus(): void {
    if (this.openedMenu) {
      this.getBanStatus({ user: this.message.user as unknown as CommunityUser })
        .then((data) => {
          if (this.message && this.message.user && data) {
            this.message.user.banned = data[0]?.banned || false;
          }
        });
    }
  }

  @Watch('isHovered')
  private closeMenu(): void {
    if (!this.isHovered) {
      this.openedMenu = false;
    }
  }

  private handleHover(hovered: boolean): void {
    this.isHovered = hovered
        && this.message
        && (this.message.messageType === MessageType.REGULAR
            || this.message.messageType === MessageType.LINK
            || this.message.messageType === MessageType.QNA);
  }

  private retry(): void {
    if (this.chatErrors) {
      const errors = this.chatErrors.findSendMessageErrors(this.message.group?.uid || '');
      if (this.chatErrors.haveErrors && errors.length > 0) {
        const messageRequestError = errors.find((err) => err.request
            && err.request.payload
            && (err.request.payload as Record<string, string>).message === this.message.content
            && (err.request.payload as Record<string, string>).groupId === this.message.group?.uid
            && (err.request.payload as Record<string, string>).tempId === this.message.uid);
        if (messageRequestError) {
          this.reloadLastRequest(messageRequestError.request)
            .finally(() => {
              if (this.chatErrors) {
                this.chatErrors.removeErrors(messageRequestError);
              }
            });
        }
      }
    }
  }

  private created(): void {
    if (this.message && this.message.type) {
      if ((this.message.type === MessageType.REGULAR
              || this.message.type === MessageType.LINK)
          && this.message.content
          && this.urlRegex.test(this.message.content)) {
        const matches = this.message.content.match(this.urlRegex);
        if (matches && matches.length > 0) {
          if (this.message.type === MessageType.REGULAR) {
            this.loadOGLinks(matches);
          } else if (this.message.target) {
            let title = '';
            let imgPath = '/img/banner/empty.svg';
            // eslint-disable-next-line no-underscore-dangle
            switch (this.message.target.__typename) {
              case EntityType.LARGE_PRODUCT: {
                const largeProduct = LargeProduct.hydrate(this.message.target);
                title = `${largeProduct.name}
                ${largeProduct.exhibitor ? `${this.$t('chat.content.messages.by')} ${largeProduct.exhibitor.name}` : ''}`;
                if (largeProduct.images
                    && largeProduct.images.length > 0) {
                  imgPath = FileResourceHelper
                    .getFullPath(largeProduct.images[0].fullFileResource, 'w320', '/img/banner/empty.svg');
                }
              }
                break;
              case EntityType.PRODUCT: {
                const product = Product.hydrate(this.message.target);
                title = `${product.name}
               ${product.exhibitor ? `${this.$t('chat.content.messages.by')} ${product.exhibitor.name}` : ''}`;
                if (product.images
                    && product.images.length > 0) {
                  imgPath = FileResourceHelper
                    .getFullPath(product.images[0].fullFileResource, 'w320', '/img/banner/empty.svg');
                }
              }
                break;
              case EntityType.DEAL: {
                const deal = Deal.hydrate(this.message.target);
                title = `${deal.name}
                ${deal.exhibitor ? `${this.$t('chat.content.messages.by')} ${deal.exhibitor.name}` : ''}`;
                imgPath = FileResourceHelper
                  .getFullPath(deal.displayFileResource, 'w320', '/img/banner/empty.svg');
              }
                break;
              default:
                break;
            }
            this.ogLinksData.push({
              link: matches[0],
              title,
              description: '',
              image: imgPath,
            });
            Object.assign(this.message.ogMetaData, {
              [matches[0]]: {
                link: matches[0],
                title,
                description: '',
                image: imgPath,
              },
            });
          }
        }
      }
    }
    if (this.message.user) {
      this.banned = this.message.user.banned;
    }
  }

  private createLink(ogMetaData: OgMetadata): Record<string, string> {
    const newLink = {
      link: '',
      title: '',
      image: '',
      description: '',
    };
    if (ogMetaData.data.requestUrl) {
      newLink.link = ogMetaData.data.requestUrl;
    }
    if (ogMetaData.data.ogSiteName) {
      newLink.title = ogMetaData.data.ogSiteName;
    }
    if (ogMetaData.data.ogTitle) {
      newLink.title = ogMetaData.data.ogTitle;
    }
    if (ogMetaData.data.twitterImage) {
      if (Array.isArray(ogMetaData.data.twitterImage) && ogMetaData.data.twitterImage.length > 0) {
        newLink.image = ogMetaData.data.twitterImage[0].url;
      } else {
        const twitterImage = ogMetaData.data.twitterImage as OgImage;
        newLink.image = twitterImage.url;
      }
    } else if (ogMetaData.data.ogImage) {
      if (Array.isArray(ogMetaData.data.ogImage) && ogMetaData.data.ogImage.length > 0) {
        newLink.image = ogMetaData.data.ogImage[0].url;
      } else {
        const ogImage = ogMetaData.data.ogImage as OgImage;
        newLink.image = ogImage.url;
      }
    } else if (ogMetaData.data.favicon) {
      if (ogMetaData.data.favicon.startsWith('//')) {
        newLink.image = ogMetaData.data.favicon.replace('//', `${new URL(ogMetaData.data.requestUrl).origin}/`);
      } else if (ogMetaData.data.favicon.startsWith('/')) {
        newLink.image = ogMetaData.data.favicon.replace('/', `${new URL(ogMetaData.data.requestUrl).origin}/`);
      } else {
        newLink.image = ogMetaData.data.favicon;
      }
    }
    if (ogMetaData.data.ogDescription) {
      newLink.description = ogMetaData.data.ogDescription;
    }
    Object.assign(this.message.ogMetaData, { [newLink.link]: newLink });
    return newLink;
  }

  private toggleReaction(): void {
    if (this.allowBasicActions) {
      if (this.message.myReactions.length > 0) {
        const myReaction = this.message.myReactions[0];
        this.removeReaction(myReaction.uid);
        this.deleteReaction({
          reaction: myReaction,
          authUser: this.authUser.uid,
        });
      } else {
        const tempId = GUUID.uuidv4();
        this.createReaction({
          messageId: this.message.uid,
          reaction: ReactionType.LIKE,
          tempId,
        });
        this.addReaction({
          reaction: Reaction.hydrate({
            uid: tempId,
            tempReactionId: tempId,
            reaction: ReactionType.LIKE,
            user: { uid: this.authUser.uid },
            message: {
              uid: this.message.uid,
              group: this.message.group,
            },
          }),
          authUser: this.authUser.uid,
        });
      }
    }
  }

  private toggleBanUser(): void {
    if (this.isSessionChat
        && this.authUser
        && this.message.user
        && this.authUser.uid !== this.message.user.uid) {
      if (!this.message.user.banned) {
        this.message.user.banned = true;
        this.banned = true;
        this.banUser(this.message.user.uid);
      } else {
        this.message.user.banned = false;
        this.banned = false;
        this.unBanUser(this.message.user.uid);
      }
    }
  }
}
