import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CommunityUserNote from '@/models/graphql/CommunityUserNote';
import CommunityUserNoteRepository from '@/repositories/CommunityUserNoteRepository';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';

@Module({ namespaced: true })
/* eslint-disable @typescript-eslint/camelcase,max-len */
export default class CommunityUserNoteStore extends LoadableStore<LoadableState> {
  private notes: CommunityUserNote[] = [];

  private selectedNoteUid: string | null = null;

  private tempNoteUid: string | null = null;

  private noteLinkedEntityCount = 0;

  private noteLinkedEntity: { __typename: string } & object | null = null;

  private repository = new CommunityUserNoteRepository();

  @Action
  private loadNotes(payload: { filter: object; offset: number }): Promise<number> {
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.COMMUNITY_USER_NOTE_FILTER,
        },
        orderBy: {
          value: ['id_desc'],
          type: GqlEntityOrderingType.COMMUNITY_USER_NOTE_ORDERING,
        },
        first: 10,
        offset: payload.offset,
      }),
    })
      .then((response) => {
        this.context.commit('setNotes', {
          data: response,
          reset: payload.offset === 0,
        });
        return response.length;
      })
      .then((nbElement) => Promise.resolve(nbElement))
      .catch(() => {
        this.context.commit('setNotes', {
          data: [],
          reset: true,
        });
        return Promise.resolve(0);
      });
  }

  @Action
  private loadNoteSearchCount(filter: object): Promise<number | undefined> {
    return this.repository.count({
      operationName: 'GetNoteSearchCount',
      definition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.COMMUNITY_USER_NOTE_FILTER,
        },
      }),
    });
  }

  @Action
  private createNote(payload: {
    content: string;
    contentPlain: string;
    targetUid?: string;
  }): Promise<void> {
    const definitionParams = [
      {
        fieldName: 'userUid',
        type: 'ID!',
        value: this.context.rootState.authUser?.uid,
      },
      {
        fieldName: 'entity',
        type: 'CommunityUserNoteInput!',
        value: {
          title: '',
          content: payload.content,
          contentPlain: payload.contentPlain,
        },
      },
    ];
    if (payload.targetUid) {
      definitionParams.push({
        fieldName: 'targetUid',
        type: 'ID',
        value: payload.targetUid,
      });
    }
    return this.repository.create({
      definition: buildMutationDefinition(definitionParams),
    })
      .then((note) => {
        if (note) {
          this.context.commit('setSelectedNote', note.uid);
          this.context.commit('replaceTempNote', note);
        }
        return Promise.resolve();
      });
  }

  @Action
  private updateNote(payload: {
    uid: string;
    content: string;
    contentPlain: string;
  }): Promise<void> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: '_CommunityUserNoteInput',
        value: {
          uid: payload.uid,
          title: '',
          content: payload.content,
          contentPlain: payload.contentPlain,
        },
      }]),
    })
      .then(() => Promise.resolve());
  }

  @Action
  private deleteNote(uid: string): Promise<CommunityUserNote | undefined> {
    return this.repository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: uid,
        },
      ]),
    });
  }

  @Action
  private removeNoteLinkTarget(payload: {
    uid: string;
    linkTargetUid: string;
  }): Promise<CommunityUserNote | undefined> {
    return this.repository.removeLinkTarget({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: payload.uid,
        },
        {
          fieldName: 'linkTarget_LinkableUid',
          type: 'ID',
          value: payload.linkTargetUid,
        },
      ]),
    });
  }

  @Action
  private loadNoteLinkedEntityCount(entityUid: string): Promise<void> {
    return this.repository.count({
      operationName: 'GetNoteLinkedEntityCount',
      definition: buildQueryDefinition({
        filter: {
          value: {
            user: {
              uid: this.context.rootState.authUser?.uid,
            },
            links: {
              target: {
                uid: entityUid,
              },
            },
          },
          type: GqlEntityFilterType.COMMUNITY_USER_NOTE_FILTER,
        },
      }),
    })
      .then((response) => {
        this.context.commit('setLinkedEntityCount', response);
      });
  }

  @Action
  private loadNoteLinkedTarget(payload: {
    targetType: string;
    targetUid: string;
  }): Promise<void> {
    let filterType = '';
    let fragmentName = '';
    switch (payload.targetType) {
      case 'exhibitor':
        filterType = GqlEntityFilterType.EXHIBITOR_FILTER;
        fragmentName = 'exhibitorActionButtonAddnoteFragment';
        break;
      case 'product':
        filterType = GqlEntityFilterType.PRODUCT_FILTER;
        fragmentName = 'productActionButtonAddnoteFragment';
        break;
      case 'largeProduct':
        filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
        fragmentName = 'largeProductActionButtonAddnoteFragment';
        break;
      case 'session':
        filterType = GqlEntityFilterType.SESSION_FILTER;
        fragmentName = 'sessionActionButtonAddnoteFragment';
        break;
      case 'deal':
        filterType = GqlEntityFilterType.DEAL_FILTER;
        fragmentName = 'dealActionButtonAddnoteFragment';
        break;
      case 'communityUser':
        filterType = GqlEntityFilterType.COMMUNITY_USER_FILTER;
        fragmentName = 'communityUserActionButtonAddnoteFragment';
        break;
      case 'article':
        filterType = GqlEntityFilterType.ARTICLE_FILTER;
        fragmentName = 'articleActionButtonAddnoteFragment';
        break;
      case 'speaker':
        filterType = GqlEntityFilterType.SPEAKER_FILTER;
        fragmentName = 'speakerActionButtonAddnoteFragment';
        break;
      default: break;
    }
    return this.repository.get({
      operation: payload.targetType,
      params: {
        definition: buildQueryDefinition({
          filter: {
            value: {
              uid: payload.targetUid,
            },
            type: filterType,
          },
        }),
        fragmentName,
      },
    })
      .then((response) => {
        if (response) {
          this.context.commit('setNoteLinkedEntity', response);
        }
        return Promise.resolve();
      });
  }

  @Mutation
  private setNotes(payload: { data: CommunityUserNote[]; reset: boolean }): void {
    if (payload.reset) {
      this.notes = payload.data.map((note) => CommunityUserNote.hydrate(note));
    } else {
      this.notes.push(...payload.data.map((note) => CommunityUserNote.hydrate(note)));
    }
    if (this.notes.length > 0 && payload.reset) {
      this.selectedNoteUid = this.notes[0].uid;
    }
  }

  @Mutation
  private addTempNote(tempNote: CommunityUserNote): void {
    this.tempNoteUid = tempNote.uid;
    this.notes.unshift(tempNote);
  }

  @Mutation
  private replaceTempNote(note: CommunityUserNote): void {
    const tempNoteIndex = this.notes.findIndex((n) => n.uid === this.tempNoteUid);
    if (tempNoteIndex > -1) {
      this.notes.splice(tempNoteIndex, 1, CommunityUserNote.hydrate(note));
      this.tempNoteUid = null;
    }
  }

  @Mutation
  private setLinkedEntityCount(count: number): void {
    this.noteLinkedEntityCount = count;
  }

  @Mutation
  private setSelectedNote(uid: string | null): void {
    this.selectedNoteUid = uid;
  }

  @Mutation
  private setTempNoteUid(uid: string | null): void {
    this.tempNoteUid = uid;
  }

  @Mutation
  private incrementNoteLinkedEntityCount(): void {
    this.noteLinkedEntityCount += 1;
  }

  @Mutation
  private decrementNoteLinkedEntityCount(): void {
    this.noteLinkedEntityCount = this.noteLinkedEntityCount > 0 ? this.noteLinkedEntityCount - 1 : 0;
  }

  @Mutation
  private setNoteLinkedEntity(entity: {__typename: string} & object | null): void {
    this.noteLinkedEntity = entity;
  }

  @Mutation
  private resetNoteStates(): void {
    this.notes = [];
    this.selectedNoteUid = null;
    this.tempNoteUid = null;
    this.noteLinkedEntityCount = 0;
    this.noteLinkedEntity = null;
  }
}
