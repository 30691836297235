export default function useTestDataAttribute(data: string): Record<'data-cy', string> | undefined {
  const enabled = process.env.VUE_APP_USE_TEST_DATA_ATTRIBUTES
    && process.env.VUE_APP_USE_TEST_DATA_ATTRIBUTES.toLowerCase() === 'true';
  if (enabled) {
    return {
      'data-cy': data,
    };
  }
  return undefined;
}
