import { Action, Module, Mutation } from 'vuex-module-decorators';
import MessageGroupRepository from '@/repositories/chat/MessageGroupRepository';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import ChatTypingEvent from '@/utils/types/chat/ChatTypingEvent';
import DynamicStore from '@/store/dynamic/DynamicStore';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { buildSubscriptionDefinition } from '@/graphql/_Tools/GqlSubscriptionDefinition';

@Module({ namespaced: true })
export default class ChatDispatcherStore extends DynamicStore {
  eventPointer: { unsubscribe: () => void } | null = null;

  sessionEventPointer: { unsubscribe: () => void } | null = null;

  eventTypingPointer: { unsubscribe: () => void } | null = null;

  protected parentStoreName = 'ChatDispatcherStore';

  private messageGroupRepository = new MessageGroupRepository();

  protected get repository(): MessageGroupRepository {
    return this.messageGroupRepository;
  }

  @Action
  events(sessionId?: string): void {
    const callback = (event: SubscriptionEvent | null): void => {
      this.subModules.forEach((module) => {
        this.context.dispatch(`${module}/events`, { event, newSessionGroup: !!sessionId });
      });
    };

    const definitionParam = [
      {
        fieldName: 'token',
        type: GqlEntityInputType.STRING,
        value: this.repository.authToken,
      },
    ];
    if (sessionId) {
      definitionParam.push({
        fieldName: 'custom',
        type: GqlEntityInputType.STRING,
        value: `notification-new-session-group-${sessionId}`,
      });
    }

    this.repository.eventSubscribe({
      callback: callback.bind(this),
      params: {
        operationName: 'ChatEventsSubscription',
        definition: buildSubscriptionDefinition(definitionParam),
      },
    }).then((unsubscribe) => {
      const pointer = sessionId ? 'setSessionEventPointer' : 'setEventPointer';
      this.context.commit(pointer, unsubscribe);
    });
  }

  @Action
  onConnected(): void {
    const callback = (): void => {
      this.subModules.forEach((module) => {
        this.context.dispatch(`${module}/onConnected`);
      });
    };
    this.repository.onConnected(callback.bind(this));
  }

  @Action
  onReconnected(): void {
    const callback = (): void => {
      this.subModules.forEach((module) => {
        this.context.dispatch(`${module}/onReconnected`);
      });
    };
    this.repository.onReconnected(callback.bind(this));
  }

  @Action
  onDisconnected(): void {
    const callback = (): void => {
      this.subModules.forEach((module) => {
        this.context.dispatch(`${module}/onDisconnected`);
      });
    };
    this.repository.onDisconnected(callback.bind(this));
  }

  @Action
  eventsTyping(): void {
    const callback = (eventsTyping: ChatTypingEvent | null): void => {
      this.subModules.forEach((module) => {
        this.context.dispatch(`${module}/eventsTyping`, eventsTyping);
      });
    };

    this.repository.eventTypingSubscribe({
      callback: callback.bind(this),
      params: {
        operationName: 'ChatEventsTypingSubscription',
        definition: buildSubscriptionDefinition([
          {
            fieldName: 'token',
            type: GqlEntityInputType.STRING,
            value: this.repository.authToken,
          },
        ]),
      },
    }).then((unsubscribe) => {
      this.context.commit('setTypingEventPointer', unsubscribe);
    });
  }

  @Mutation
  setEventPointer(eventPointer: { unsubscribe: () => void }): void {
    this.eventPointer = eventPointer;
  }

  @Mutation
  setSessionEventPointer(eventPointer: { unsubscribe: () => void }): void {
    this.sessionEventPointer = eventPointer;
  }

  @Mutation
  setTypingEventPointer(eventPointer: { unsubscribe: () => void }): void {
    this.eventTypingPointer = eventPointer;
  }

  @Action
  private clearCache(): void {
    this.context.commit('unsubscribe');
    this.subModules.forEach((module) => {
      this.context.commit(`${module}/clearCache`);
    });
  }

  @Mutation
  private unsubscribe(): void {
    if (this.eventPointer) {
      this.eventPointer.unsubscribe();
    }
    if (this.eventTypingPointer) {
      this.eventTypingPointer.unsubscribe();
    }
    if (this.sessionEventPointer) {
      this.sessionEventPointer.unsubscribe();
    }
    this.eventPointer = null;
    this.eventTypingPointer = null;
    this.sessionEventPointer = null;
    this.messageGroupRepository = new MessageGroupRepository();
  }

  @Mutation
  private sessionUnsubscribe(): void {
    if (this.sessionEventPointer) {
      this.sessionEventPointer.unsubscribe();
    }
    this.sessionEventPointer = null;
  }
}
