enum ActionButtonType {
  MEET = 'createMeeting',
  COMPANY_INVITE = 'companyInvite',
  LINK = 'link',
  BOOKMARK = 'bookmark',
  FOLLOW = 'follow',
  AGENDA = 'addToAgenda',
  CONNECT = 'connect',
  MAP = 'viewOnMap',
  VISIT = 'visit',
  MESSAGE = 'message',
  BLOCK = 'block',
  URL = 'productUrl',
  LARGE_PRODUCT_URL = 'largeProductUrl',
  ADD_NOTE = 'addNote',
  SHARE = 'share',
  LINK_PROFILE_SPEAKER = 'linkUserSpeaker',
  GENERIC = 'generic',
  FEEDBACK = 'feedback',
  TAGS = 'entityTags',
  SHARE_CONTACT = 'shareContact',
}

export default ActionButtonType;
