






























































import { Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  methods: { useTestDataAttribute },
  components: {
    FontAwesomeComponent,
    AvatarSoloWidget,
    StandardModal,
    ButtonComponent,
    ButtonIconComponent,
  },
})
export default class SendConnectionRequestModal extends BreakpointWrapper {
  @Prop({ default: '' })
  type!: string;

  @Prop({ required: true, default: '' })
  name!: string;

  @Prop({ required: false, default: -1 })
  private userId!: number | string;

  private requestDescription = '';

  private get title(): string {
    switch (this.isSmallDevice) {
      case false:
        return `${this.$t('component.user-card.modal.issue-connection-request.title',
          { name: this.name })}`;
      default:
        return `${this.$t('component.user-card.modal.issue-connection-request.short_title')}`;
    }
  }

  mounted(): void {
    this.requestDescription = `${this.$t('component.user-card.modal.issue-connection-request.message',
      { name: `${this.name}` })}`;
  }

  private onConfirm(message: string): void {
    this.$bvModal.hide(`${this.type}-${this.userId}`);
    this.$emit('on-confirm', message);
  }

  private onCancel(): void {
    this.$bvModal.hide(`${this.type}-${this.userId}`);
    this.$emit('on-cancel');
  }
}
