





















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OgLinkCard extends Vue {
  @Prop({
    required: false,
    default: '',
  })
  protected readonly link!: string;

  @Prop({
    required: false,
    default: '',
  })
  protected readonly image!: string;

  @Prop({
    required: false,
    default: '',
  })
  protected readonly title!: string;

  @Prop({
    required: false,
    default: '',
  })
  protected readonly description!: string;

  @Prop({
    required: false,
    default: true,
  })
  protected readonly isLoading!: boolean;
}
