import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { Data } from '@/utils/types/WidgetData';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

const pageStateManagementStore = namespace('PageStateManagementStore');
const widgetDispatcherStore = namespace('WidgetDispatcherStore');

@Component({
  inheritAttrs: false,
})
export default class VueRegisterStoreWidget extends Vue {
  @Prop({ required: false, default: null })
  protected readonly widget!: UiPageWidget;

  @Prop({ required: false, default: false })
  protected readonly standAlone!: boolean;

  protected baseStoreName = 'GenericWidgetStore';

  @pageStateManagementStore.Mutation
  protected removeDependency!: (payload: { page: number; widget: string }) => void;

  @pageStateManagementStore.Mutation
  protected addDependencyError!: (payload: { page: number; widget: string }) => void;

  @pageStateManagementStore.State
  private pageReadyToDisplay!: number[];

  @widgetDispatcherStore.Action('registerModule')
  private registerWidgetModule!: (payload: {
    baseStoreName: string; name: string; store: object;
  }) => void;

  @widgetDispatcherStore.Action('unRegisterModule')
  private unRegisterWidgetModule!: (payload: {
    name: string; store: object;
  }) => void;

  get isReadyToDisplay(): boolean {
    return this.standAlone
      || !!(this.widget
        && this.widget.page
        && this.widget.page.id
        && this.pageReadyToDisplay.includes(this.widget.page.id));
  }

  protected get storeName(): string {
    return this.getStoreName(this.widget);
  }

  protected get widgetStorePath(): string {
    return this.getWidgetStorePath(this.widget);
  }

  protected get count(): number | null {
    if (!this.standAlone) {
      return this.$store.getters[`${this.widgetStorePath}/count`];
    }
    return null;
  }

  protected get payload(): Data | null {
    if (!this.standAlone) {
      return this.widget && this.widget.payload ? JSON.parse(this.widget.payload) : null;
    }
    return null;
  }

  protected get one(): Data | null {
    if (!this.standAlone) {
      return this.$store.getters[`${this.widgetStorePath}/one`];
    }
    return null;
  }

  protected get all(): Array<Data> {
    if (!this.standAlone) {
      return this.$store.getters[`${this.widgetStorePath}/all`];
    }
    return [];
  }

  created(): void {
    if (!this.standAlone && this.storeName) {
      this.registerWidgetModule({
        baseStoreName: this.baseStoreName,
        name: this.storeName,
        store: this.$store,
      });
      this.$store.commit(`${this.widgetStorePath}/setWidgetStoreName`, this.storeName);
      this.$store.commit(`${this.widgetStorePath}/setWidget`, this.widget);
    }
  }

  beforeDestroy(): void {
    if (!this.standAlone && this.storeName) {
      this.unRegisterWidgetModule({
        name: this.storeName,
        store: this.$store,
      });
    }
  }

  setDataConfig(
    configs?: Array<GqlComposeQueryDefinitionParams>,
    fail = false,
    failMessage = '',
  ): Promise<void> {
    if (fail) {
      this.launchFailProcess(failMessage);
    }
    if (!fail
      && !this.standAlone
      && this.storeName) {
      return this.$store.dispatch(`${this.widgetStorePath}/setDataConfigs`, configs)
        .then(() => {
          if (this.widget
            && this.widget.page
            && this.widget.page.id) {
            this.removeDependency({ page: this.widget.page.id, widget: this.storeName });
          }
        }).catch(() => {
          this.launchFailProcess();
        });
    }
    return Promise.resolve();
  }

  protected getStoreName(widget: UiPageWidget | undefined): string {
    // eslint-disable-next-line no-param-reassign
    widget = widget || this.widget;
    if (!this.standAlone && this.widget) {
      return WidgetHelper.storeName(widget.type, `${widget.id}${this.widget.page?.id}`);
    }
    return '';
  }

  protected getWidgetStorePath(widget: UiPageWidget | undefined): string {
    const storeName = this.getStoreName(widget);
    if (!this.standAlone && storeName) {
      return WidgetHelper
        .widgetStorePath('WidgetDispatcherStore', storeName);
    }
    return '';
  }

  private launchFailProcess(failMessage = ''): void {
    if (this.widget
      && this.widget.page
      && this.widget.page.id) {
      this.addDependencyError({ page: this.widget.page.id, widget: this.storeName });
      this.removeDependency({ page: this.widget.page.id, widget: this.storeName });
    }
    Sentry.withScope((scope) => {
      scope.setLevel(Severity.Error);
      Sentry.captureException(failMessage || `Config error was found for ${this.widget?.type} widget with id ${this.widget?.id}`);
    });
  }
}
