










































































import { Component, Prop } from 'vue-property-decorator';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import EntityType from '@/utils/enums/EntityType';
import PillWidget from '@/components/pill/PillWidget.vue';
import { namespace } from 'vuex-class';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { RawLocation } from 'vue-router';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import ListFilter from '@/utils/enums/agenda/ListFilter';

const chatStore = namespace('ChatDispatcherStore/ChatStore');
const agendaStore = namespace('AgendaStore');

@Component({
  components: { PillWidget, AvatarSoloWidget },
})
export default class ToolboxDashboardCardComponent extends BreakpointWrapper {
  @Prop({ required: true })
  private readonly type!: string;

  @Prop({ required: false, default: 0 })
  private readonly total!: number;

  @Prop({ required: false, default: null })
  private readonly upcoming!: {
    value: number;
    variant: string;
  };

  @Prop({ required: false, default: null })
  private readonly live!: {
    value: number;
    variant: string;
  };

  @Prop({ required: false, default: null })
  private readonly pending!: {
    value: number;
    variant: string;
  };

  @chatStore.Getter('unReadCount')
  private readonly unreadMessages!: number;

  @chatStore.Getter('count')
  private readonly messageTotal!: number;

  @chatStore.Action
  private goToFirstUnreadMessage!: () => void;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Mutation
  private setListFilter!: (string: ListFilter) => void;

  private get entityIcon(): string {
    switch (this.type) {
      case EntityType.SESSION:
        return 'far fa-presentation-screen';
      case EntityType.MEETING:
        return 'far fa-calendar-clock';
      case EntityType.CONNECTION:
        return 'far fa-users';
      case EntityType.MESSAGE_GROUP:
        return 'far fa-comment-dots';
      default:
        return '';
    }
  }

  private get entityName(): string {
    switch (this.type) {
      case EntityType.SESSION:
        return `${this.$t('toolbox.dashboard.session.name')}`;
      case EntityType.MEETING:
        return `${this.$t('toolbox.dashboard.meeting.name')}`;
      case EntityType.CONNECTION:
        return `${this.$t('toolbox.dashboard.connection.name')}`;
      case EntityType.MESSAGE_GROUP:
        return `${this.$t('toolbox.dashboard.message.name')}`;
      default:
        return '';
    }
  }

  private get entitySubtitle(): string {
    switch (this.type) {
      case EntityType.SESSION:
        return `${this.$t('toolbox.dashboard.session.subtitle')}`;
      case EntityType.MEETING:
        return `${this.$t('toolbox.dashboard.meeting.subtitle')}`;
      case EntityType.CONNECTION:
        return `${this.$t('toolbox.dashboard.connection.subtitle')}`;
      case EntityType.MESSAGE_GROUP:
        return `${this.$t('toolbox.dashboard.message.subtitle')}`;
      default:
        return '';
    }
  }

  private get totalHint(): string {
    switch (this.type) {
      case EntityType.SESSION:
        return `${this.$t('toolbox.dashboard.session.count-hint')}`;
      case EntityType.MEETING:
        return `${this.$t('toolbox.dashboard.meeting.count-hint')}`;
      case EntityType.CONNECTION:
        return `${this.$t('toolbox.dashboard.connection.count-hint')}`;
      case EntityType.MESSAGE_GROUP:
        return `${this.$t('toolbox.dashboard.message.count-hint')}`;
      default:
        return '';
    }
  }

  private handleClick(): void {
    switch (this.type) {
      case EntityType.SESSION:
        this.setViewMode(ViewMode.LIST);
        this.setListFilter(ListFilter.SESSIONS);
        this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
        break;
      case EntityType.MEETING:
        this.setViewMode(ViewMode.LIST);
        this.setListFilter(ListFilter.MEETINGS);
        this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
        break;
      case EntityType.CONNECTION:
        this.$bvModal.hide('toolbox-modal');
        this.$router.push({ name: 'my-profile-connections' });
        break;
      case EntityType.MESSAGE_GROUP:
        this.$eventsBus.emit('ontoolbox', {
          view: ToolbarMenuActions.TOOLBAR_CHAT,
        });
        break;
      default:
        break;
    }
  }

  private connectionPendingRequest(): void {
    if (this.type === EntityType.CONNECTION && this.pending.value > 0) {
      this.$bvModal.hide('toolbox-modal');
      const route = {
        name: 'my-profile-connections',
        query: { page: '1', toggles: { pending: true } },
      } as unknown as RawLocation;
      this.$router.push(route);
    }
  }
}
