import { ApolloQueryResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import SubEdition from '@/models/graphql/SubEdition';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GetterInterface from '@/repositories/base/GetterInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
export default class SubEditionRepository extends GraphQlRepository<SubEdition>
  implements GetterInterface<SubEdition> {
  get(params: GqlPayloadParams): Promise<SubEdition | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetSubEdition',
      operation: 'subEdition',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'subEditionBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ subEdition: SubEdition[] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ subEdition: SubEdition[] }>) => response.data.subEdition[0]));
  }
}
