enum StatLoggerCategories {
  FOLLOW = 'follow',
  VISIT = 'visit',
  BOOKMARK = 'bookmark',
  ADD_TO_AGENDA = 'addToAgenda',
  CONNECT = 'connect',
  MEET = 'meet',
  VIEW_ON_MAP = 'viewOnMap',
  LINK = 'link',
  MESSAGE = 'message',
  NOTE = 'note',
  SHARE = 'share',
  SPONSOR = 'sponsor',
  BIG_NUMBER = 'bigNumber',
  CAROUSEL = 'carousel',
  FEATURED_EXHIBITOR = 'featuredExhibitor',
  LOGO_LIST = 'logoList',
  EXHIBITOR_EVENT = 'exhibitorEvent',
  LIVE_SESSION_THEATER = 'liveSessionTheater',
  LIVE_SESSION = 'liveSession',
  EVENT_INFORMATION = 'eventInformation',
  TOOLBOX = 'toolbox',
  ACCOUNT = 'account',
  FINANCING_CALCULATOR = 'financingCalculator',
  GLOBAL = 'global',
  ENTITY = 'entity',
  EXHIBITOR_VIDEO = 'exhibitorVideo',
  BUSINESS_CARD = 'businessCard',
  HANDOUT = 'handout',
  SOCIAL_LINK =' socialLink',
  FEEDBACK = 'feedback',
  INFO = 'info',
  REJECT = 'reject',
  ACCEPT = 'accept',
  REMOVE = 'remove',
  ADD = 'add',
  HELP = 'help'
}

export default StatLoggerCategories;
