enum StatLoggerActions {
  SIGN_IN_SUCCESS = 'signInSuccess',
  SIGN_IN_FAILURE = 'signInFailure',
  VIEW = 'view',
  ADD = 'add',
  ADD_TO_AGENDA = 'addToAgenda',
  ACCEPT_TO_AGENDA = 'acceptToAgenda',
  REJECT_FROM_AGENDA = 'rejectFromAgenda',
  REMOVE_FROM_AGENDA = 'removeFromAgenda',
  CLICK = 'click',
  MAP = 'map',
  VISIT = 'visit',
  SEARCH = 'search',
  FILTER = 'filter',
  SEND = 'send',
}

export default StatLoggerActions;
