import { FetchResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildComposeMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';
import LargeProductImage from '@/models/graphql/LargeProductImage';

export default class LargeProductImageRepository extends GraphQlRepository<LargeProductImage> {
  createLargeProductImagesForLargeProduct(params: GqlComposeMutationPayloadParams):
    Promise<Record<string, LargeProductImage>[]> {
    return buildComposeMutationGql({
      operationName: 'CreateImagesForLargeProduct',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, LargeProductImage>[], {}>(mutations))
      .then((response: FetchResult<Record<string, LargeProductImage>[]>) => response.data as Record<string, LargeProductImage>[]);
  }

  deleteLargeProductImagesForLargeProduct(params: GqlComposeMutationPayloadParams):
    Promise<Record<string, LargeProductImage>> {
    return buildComposeMutationGql({
      operationName: 'DeleteImagesForLargeProduct',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, LargeProductImage>, {}>(mutations))
      .then((response: FetchResult<Record<string, LargeProductImage>>) => response.data as Record<string, LargeProductImage>);
  }
}
