/* eslint-disable max-len */
import { Action, Module, Mutation } from 'vuex-module-decorators';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import LoadableStore from '@/store/LoadableStore';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import LoadableState from '@/store/states/LoadableState';
import ExhibitorUrl from '@/models/graphql/ExhibitorUrl';
import ExhibitorUrlRepository from '@/repositories/ExhibitorUrlRepository';
import { ExhibitorUrlFilter } from '@/graphql/_Filters/ExhibitorUrlFilter';

interface ExhibitorUrlState extends LoadableState {
  exhibitorUrls: ExhibitorUrl[];
}

@Module({ namespaced: true })
export default class ExhibitorUrlStore extends LoadableStore<ExhibitorUrlState> {
  exhibitorUrls: ExhibitorUrl[] = [];

  private readonly exhibitorUrlRepository = new ExhibitorUrlRepository();

  get fetchExhibitorUrls(): ExhibitorUrl[] {
    return this.exhibitorUrls;
  }

  protected get repository(): ExhibitorUrlRepository {
    return this.exhibitorUrlRepository;
  }

  @Mutation
  setUrls(exhibitorUrls: ExhibitorUrl[]): void {
    this.exhibitorUrls = exhibitorUrls;
  }

  @Action
  filter(payload: {filter: ExhibitorUrlFilter}): void {
    this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.EXHIBITOR_URL_FILTER,
        },
      }),
    }).then((response) => {
      this.context.commit('setUrls', response);
    });
  }

  @Action
  createExhibitorUrl(payload: { exhibitorUrl: ExhibitorUrl; uid: string }): Promise<undefined | void> {
    return this.repository.create({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.EXHIBITOR_URL_INPUT,
        value: payload.exhibitorUrl,
      },
      {
        fieldName: 'exhibitor_ExhibitorUid',
        type: 'ID',
        value: payload.uid,
      },
      ]),
    }).then((url) => {
      if (url) {
        this.exhibitorUrls.push(url);
      }
    });
  }

  @Action
  deleteExhibitorUrl(uid: string): void {
    const oldExhibitorUrls = this.exhibitorUrls;
    this.repository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: uid,
        },
      ]),
    }).then(() => {
      const index = this.exhibitorUrls.findIndex((el: ExhibitorUrl): boolean => el.uid === uid);
      if (index !== -1) {
        this.exhibitorUrls.splice(index, 1);
      }
    }).catch(() => {
      this.context.commit('setUrls', oldExhibitorUrls);
    });
  }

  @Action({ rawError: true })
  updateExhibitorUrl(exhibitorUrl: ExhibitorUrl): void {
    this.context.commit('load', true);
    const oldExhibitorUrls = this.exhibitorUrls;
    this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.EXHIBITOR_URL_INPUT,
          value: exhibitorUrl,
        },
      ]),
    }).then(() => this.context.commit('load', false))
      .catch(() => this.context.commit('setUrls', oldExhibitorUrls));
  }
}
