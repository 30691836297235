
















































import { Component, Prop } from 'vue-property-decorator';
import VueContextLoading from '@/components/VueContextLoading';
import { format, isToday } from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import isEqual from 'date-fns/isEqual';
import { mixins } from 'vue-class-component';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { State } from 'vuex-class';

@Component
export default class ToolboxAgendaDateComponent extends mixins(BreakpointWrapper, VueContextLoading) {
  @Prop({ required: false, default: () => new Date() })
  private readonly date!: Date;

  @Prop({ required: false, default: false })
  private readonly showDesktopStyle!: boolean;

  @State
  private dateLocale!: Locale;

  private format = format;

  get isToday(): boolean {
    if (this.date) {
      return isEqual(new Date(format(DateTimeHelper.getCurrentDateTime(), this.$t('app.date.defaultDateFormat') as string)),
        new Date(format(this.date, this.$t('app.date.defaultDateFormat') as string)));
    }
    return false;
  }

  private get dateHeader(): { isToday: boolean; d: string; m: string; dw: string; sd: string } | null {
    return {
      isToday: isToday(this.date),
      d: format(this.date, this.$t('app.date.day') as string, { locale: this.dateLocale }),
      m: format(this.date, this.$t('app.date.month') as string, { locale: this.dateLocale }),
      dw: format(this.date, this.$t('app.date.dayOfWeek') as string, { locale: this.dateLocale }),
      sd: format(this.date, this.$t('app.date.dayOfWeekShort') as string, { locale: this.dateLocale }),
    };
  }
}
