/* eslint-disable max-len */
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GetterInterface from '@/repositories/base/GetterInterface';
import FeedState from '@/models/graphql/FeedState';

export default class FeedStateRepository extends GraphQlRepository<FeedState>
  implements GetterInterface<FeedState>, UpdateInterface<FeedState> {
  get(params: GqlPayloadParams): Promise<FeedState | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetFeedState',
      operation: 'feedState',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'feedStateBaseFragment',
    }).then((query) => this.query<{ feedState: FeedState }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ feedState: FeedState }>) => response.data?.feedState));
  }

  create(params: GqlPayloadParams): Promise<FeedState | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'FeedStateCreate',
      operation: 'FeedStateCreateForUserAndFeedEntity',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'feedStateBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ FeedStateCreateForUserAndFeedEntity: FeedState }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FeedStateCreateForUserAndFeedEntity: FeedState }>) => response.data?.FeedStateCreateForUserAndFeedEntity ?? undefined));
  }

  update(params: GqlPayloadParams): Promise<FeedState | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'FeedStateUpdate',
      operation: 'FeedStateUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'feedStateBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ FeedStateUpdate: FeedState }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ FeedStateUpdate: FeedState }>) => response.data?.FeedStateUpdate));
  }
}
