





























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RatingNumberComponent extends Vue {
  @Prop({ required: false, default: 0 })
  private readonly value!: number;

  @Prop({ required: false, default: 10 })
  private readonly maxRating!: number;

  @Prop({ required: false, default: 'rating-number.unlikely' })
  private readonly minLabelKey!: string;

  @Prop({ required: false, default: 'rating-number.likely' })
  private readonly maxLabelKey!: string;

  private get minLabel(): string {
    if (this.$te(this.minLabelKey)) {
      return this.$t(this.minLabelKey) as string;
    }
    return this.$t('rating-number.unlikely') as string;
  }

  private get maxLabel(): string {
    if (this.$te(this.maxLabelKey)) {
      return this.$t(this.maxLabelKey) as string;
    }
    return this.$t('rating-number.likely') as string;
  }
}
