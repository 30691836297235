import { FetchResult } from '@apollo/client';
import ProductImage from '@/models/graphql/ProductImage';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildComposeMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';

export default class ProductImageRepository extends GraphQlRepository<ProductImage> {
  createProductImagesForProduct(params: GqlComposeMutationPayloadParams):
    Promise<Record<string, ProductImage>[]> {
    return buildComposeMutationGql({
      operationName: 'CreateImagesForProduct',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, ProductImage>[], {}>(mutations))
      .then((response: FetchResult<Record<string, ProductImage>[]>) => response.data as Record<string, ProductImage>[]);
  }

  deleteProductImagesForProduct(params: GqlComposeMutationPayloadParams):
    Promise<Record<string, ProductImage>> {
    return buildComposeMutationGql({
      operationName: 'DeleteImagesForProduct',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, ProductImage>, {}>(mutations))
      .then((response: FetchResult<Record<string, ProductImage>>) => response.data as Record<string, ProductImage>);
  }
}
