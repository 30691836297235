enum MessageMenuItem {
  UNREAD,
  READ,
  VIEW,
  PROFILE,
  SESSION_PREVIEW,
  ADD,
  BLOCK,
  LEAVE,
  DELETE,
  ACCEPT,
  DECLINE,
  MEET,
  MUTE,
  UNBLOCK,
  SHARE_CONTACT
}

export default MessageMenuItem;
