enum SlotStrategyEnum {
  GENERIC_SLOT = 'GenericSlot',
  HANDOUT_SLOT = 'HandoutSlot',
  CATEGORY_SLOT = 'CategorySlot',
  DESCRIPTION_LENGTH = 'DescriptionLength',
  FEATURED_EXHIBITOR = 'FeaturedExhibitor',
  LOGO_SLOT = 'LogoSlot',
  GENERIC_FEATURE_SLOT = 'GenericFeatureSlot',
  VIDEO_PRESENTATION_SLOT = 'VideoPresentationSlot',
  BANNER_SLOT = 'BannerSlot',
  EXTRA_PROPERTY_SLOT = 'ExtraPropertySlot',
  FIELD_SLOT = 'FieldSlot',
  BOOLEAN_SLOT = 'BooleanSlot',
}

export default SlotStrategyEnum;
