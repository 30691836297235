enum FeedAction {
  USER_LOGO_UPDATED = 'USER_LOGO_UPDATED',
  USER_BANNER_UPDATED = 'USER_BANNER_UPDATED',
  USER_COMPANY_UPDATED = 'USER_COMPANY_UPDATED',
  USER_INTEREST_UPDATED = 'USER_INTEREST_UPDATED',
  USER_PROFILE_ROLES_UPDATED = 'USER_PROFILEROLES_UPDATED',
  USER_BIOGRAPHY_UPDATED = 'USER_BIOGRAPHY_UPDATED',
  USER_TITLE_UPDATED = 'USER_TITLE_UPDATED',
  USER_URLS_UPDATED = 'USER_URLS_UPDATED',
  USER_CONNECTIONS_UPDATED = 'USER_CONNECTIONS_UPDATED',
  USER_CONNECTIONS_UPDATED_TO = 'USER_CONNECTIONS_UPDATED_TO',
  USER_CONNECTIONS_UPDATED_FROM = 'USER_CONNECTIONS_UPDATED_FROM',
  USER_BOOKMARKS_UPDATED = 'USER_BOOKMARKS_UPDATED',
  USER_CREATED = 'USER_CREATED',

  EXHIBITOR_DESCRIPTION_UPDATED= 'EXHIBITOR_DESCRIPTION_UPDATED',
  EXHIBITOR_LOGO_UPDATED = 'EXHIBITOR_LOGO_UPDATED',
  EXHIBITOR_BANNER_UPDATED = 'EXHIBITOR_BANNER_UPDATED',
  EXHIBITOR_CATEGORIES_UPDATED = 'EXHIBITOR_CATEGORIES_UPDATED',
  EXHIBITOR_URLS_UPDATED = 'EXHIBITOR_URLS_UPDATED',
  EXHIBITOR_PRODUCTS_UPDATED = 'EXHIBITOR_PRODUCTS_UPDATED',
  EXHIBITOR_DEALS_UPDATED = 'EXHIBITOR_DEALS_UPDATED',
  EXHIBITOR_PRODUCT_UPDATED = 'EXHIBITOR_PRODUCT_UPDATED',
  EXHIBITOR_DEAL_UPDATED = 'EXHIBITOR_DEAL_UPDATED',
  EXHIBITOR_HANDOUTS_UPDATED = 'EXHIBITOR_HANDOUTS_UPDATED',
  EXHIBITOR_ADDRESSES_UPDATED = 'EXHIBITOR_ADDRESSES_UPDATED',
  EXHIBITOR_EVENT_PARTICIPATION = 'EXHIBITOR_EVENT_PARTICIPATION',
  EXHIBITOR_CONTACTS_UPDATED = 'EXHIBITOR_CONTACTS_UPDATED',
  EXHIBITOR_CREATED = 'EXHIBITOR_CREATED',

  SUBEDITION_LOGO_UPDATED = 'SUBEDITION_LOGO_UPDATED',
  SUBEDITION_BANNER_UPDATED = 'SUBEDITION_BANNER_UPDATED',
  SUBEDITION_DESCRIPTION_UPDATED= 'SUBEDITION_DESCRIPTION_UPDATED',
  SUBEDITION_EXHIBITORS_UPDATED= 'SUBEDITION_EXHIBITORS_UPDATED',
  SUBEDITION_SPEAKERS_UPDATED= 'SUBEDITION_SPEAKERS_UPDATED',
  SUBEDITION_SESSIONS_UPDATED= 'SUBEDITION_SESSIONS_UPDATED',

  CHANNEL_SESSIONS_UPDATED= 'CHANNEL_SESSIONS_UPDATED',
  CHANNEL_EXHIBITORS_UPDATED= 'CHANNEL_EXHIBITORS_UPDATED',
  CHANNEL_ARTICLES_UPDATED= 'CHANNEL_ARTICLES_UPDATED',
  CHANNEL_PRODUCTS_UPDATED= 'CHANNEL_PRODUCTS_UPDATED',
  CHANNEL_DEALS_UPDATED= 'CHANNEL_DEALS_UPDATED',

  FEEDPOST_CREATED= 'FEEDPOST_CREATED',
}

export default FeedAction;
