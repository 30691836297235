import { ApolloQueryResult, FetchResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import Order from '@/models/graphql/Order';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import ReadInterface from '@/repositories/base/ReadInterface';
import CountInterface from '@/repositories/base/CountInterface';
import CookieService from '@/services/CookieService';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import OrderPaymentCharge from '@/models/graphql/OrderPaymentCharge';
import CreateInterface from '@/repositories/base/CreateInterface';

export default class OrderRepository extends GraphQlRepository<Order>
  implements ReadInterface<Order>, CreateInterface<Order>, CountInterface {
  get(params: GqlPayloadParams): Promise<Order | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetOrder',
      operation: 'order',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'orderBaseFragment',
    }).then((query) => this.query<{ order: Array<Order> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ order: Array<Order> }>) => response.data.order[0]));
  }

  update(params: GqlPayloadParams): Promise<Order | undefined> {
    return buildMutationGql({
      operationName: 'OrderUpdate',
      operation: 'OrderUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'orderBaseFragment',
    }).then((mutation) => this.mutate<{ OrderUpdate: Order }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ OrderUpdate: Order }>) => response.data?.OrderUpdate));
  }

  filter(params: GqlPayloadParams): Promise<Order[]> {
    return buildQueryGql({
      operationName: params.operationName || 'GetOrders',
      operation: 'order',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'orderFullFragment',
    }).then((query) => this.query<{ order: Array<Order> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ order: Array<Order> }>) => response.data.order));
  }

  count(params: GqlPayloadParams): Promise<number | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetOrderCount',
      operation: 'order',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'orderCountFragment',
      authUser: params.authUser,
    }).then((query) => this.use().query<{ order: [{ count: number }] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ order: [{ count: number }] }>) => response.data.order[0].count));
  }

  // eslint-disable-next-line class-methods-use-this
  getPaymentIntentCharge(params: {
    orderUid: string;
  }): Promise<OrderPaymentCharge> {
    const uploadUrl: string = process.env.VUE_APP_ACCOUNT_MANAGEMENT_BASE_URL ?? '';
    const stripeIntentUrl = `${uploadUrl}stripe/payment-intent-charge?orderUid=${params.orderUid}`;
    const request = new XMLHttpRequest();
    request.open('GET', stripeIntentUrl);
    request.setRequestHeader('jwt-token', CookieService.getCookie(AUTH_TOKEN) as string);
    return new Promise((resolve, reject) => {
      request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
          const response = JSON.parse(request.responseText);
          if (response.charge) {
            resolve(JSON.parse(response.charge));
          } else {
            reject(new Error('Intent Charge not returned'));
          }
        } else {
          reject(request.statusText);
        }
      };
      request.onerror = () => {
        reject(new Error('Network error occurred'));
      };
      request.send();
    });
  }

  create(params: GqlPayloadParams): Promise<Order | undefined> {
    return buildMutationGql({
      operationName: 'CreateOrderAndPaymentIntent',
      operation: 'createOrderAndPaymentIntent',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'orderBaseFragment',
    }).then((mutation) => this.mutate<{ createOrderAndPaymentIntent: Order }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ createOrderAndPaymentIntent: Order }>) => response.data?.createOrderAndPaymentIntent));
  }
}
