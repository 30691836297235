



























































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class InputSearchComponent extends Vue {
  @Prop({
    required: false,
    default: '',
  })
  private readonly defaultValue!: string;

  @Prop({
    required: false,
    default: 'Search',
  })
  private readonly placeholder!: string;

  @Prop({
    required: false,
    default: 'md',
  })
  private readonly size!: string;

  @Prop({
    required: false,
    default: 'gray',
  })
  private readonly color!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly autofocus!: boolean;

  @Prop({
    required: false,
    default: 500,
  })
  private readonly debounceTime!: number;

  @Prop({
    required: false,
    default: false,
  })
  private readonly rounded!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly isFullWidth!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly darkBorder!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly hideMagnifyingGlass!: boolean;

  private query = this.defaultValue;

  @Prop({ default: false })
  private readonly isGlobal!: boolean;

  private timer: NodeJS.Timeout | null = null;

  created(): void {
    this.updateQuery();
    if (this.isGlobal) {
      if (this.$route.query.query) {
        this.query = this.$route.query.query.toString();
      }
    }
  }

  private clear(): void {
    this.query = '';
    (this.$refs.search as HTMLElement).focus();
    this.$emit('search-query', { query: '' });
    this.$emit('search-query-enter-key', { query: '' });
    this.$emit('on-clear');
  }

  private onSearch(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.$emit('search-query', { query: this.query });
    }, this.debounceTime);
  }

  @Watch('defaultValue')
  private updateQuery(): void {
    this.query = this.defaultValue;
  }
}
