








































import { Component, Prop } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';

@Component({
  components: { ButtonIconComponent },
})
export default class StandardModal extends VueBaseWidget {
  @Prop({ default: '' })
  modalId!: string;

  @Prop({ default: 'md' })
  size!: string;

  @Prop({ default: '' })
  bodyClass!: string;

  @Prop({ default: '' })
  modalClass!: string;

  @Prop({ default: false })
  modalContentScroll!: boolean;

  @Prop({ default: true })
  fixedHeight!: boolean;

  @Prop({ default: false })
  headerIsHidden!: boolean;

  @Prop({ default: false })
  footerIsHidden!: boolean;

  @Prop({ default: '' })
  headerClass!: string;

  @Prop({ default: '' })
  footerClass!: string;

  @Prop({ default: false })
  disableClickBackdrop!: boolean;

  @Prop({ default: false })
  fullScreenOnMobile!: boolean;

  @Prop({ default: false })
  fullScreenOnTablet!: boolean;

  @Prop({ default: null })
  visible!: boolean;

  private onConfirm(): void {
    this.$emit('on-confirm');
  }

  private onCancel(): void {
    this.$emit('on-cancel');
  }

  private onModalShow(): void {
    this.$emit('on-show-modal');
  }

  private onModalShown(): void {
    this.$emit('on-shown-modal');
  }

  private onModalHide(): void {
    this.$emit('on-hide-modal');
  }

  private get isFullScreen(): boolean {
    return (this.isMobile && (this.size === 'lg' || this.fullScreenOnMobile))
      || (this.isTablet && this.fullScreenOnTablet);
  }
}
