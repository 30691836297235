import { ApolloQueryResult, FetchResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import SessionRating from '@/models/graphql/SessionRating';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import CreateInterface from '@/repositories/base/CreateInterface';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GetterInterface from '@/repositories/base/GetterInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable max-len */
export default class SessionRatingRepository extends GraphQlRepository<SessionRating>
  implements CreateInterface<SessionRating>, UpdateInterface<SessionRating>, GetterInterface<SessionRating> {
  get(params: GqlPayloadParams): Promise<SessionRating | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetSessionRatingOnSession',
      operation: 'sessionRating',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'sessionRatingFullFragment',
    }).then((query) => this.query<{ sessionRating: SessionRating }, {}>(query, params.definition.variables)
      .then((response: ApolloQueryResult<{ sessionRating: SessionRating }>) => response.data?.sessionRating));
  }

  create(params: GqlPayloadParams): Promise<SessionRating | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'SessionRatingCreateForSessionAndUser',
      operation: 'SessionRatingCreateForSessionAndUser',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'sessionRatingFullFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ SessionRatingCreateForSessionAndUser: SessionRating }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ SessionRatingCreateForSessionAndUser: SessionRating }>) => response.data?.SessionRatingCreateForSessionAndUser));
  }

  update(params: GqlPayloadParams): Promise<SessionRating | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'SessionRatingUpdate',
      operation: 'SessionRatingUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'sessionRatingFullFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ SessionRatingUpdate: SessionRating }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ SessionRatingUpdate: SessionRating }>) => response.data?.SessionRatingUpdate));
  }
}
