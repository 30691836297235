import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoginProviderConfigRepository from '@/repositories/LoginProviderConfigRepository';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import LoginProviderConfig from '@/models/graphql/LoginProviderConfig';

@Module({ namespaced: true })
export default class LoginProviderConfigStore extends LoadableStore<LoadableState> {
  private readonly loginProviderConfigRepository = new LoginProviderConfigRepository();

  protected get repository(): LoginProviderConfigRepository {
    return this.loginProviderConfigRepository;
  }

  @Action
  loadLoginProviderConfig(
    { schemaCode, loginProviderId }: { schemaCode: string; loginProviderId: number },
  ): Promise<LoginProviderConfig | undefined> {
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          value: {
            schemaCode,
            id: loginProviderId,
          },
          type: GqlEntityFilterType.LOGIN_PROVIDER_CONFIG_FILTER,
        },
      }),
    });
  }
}
