import { Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import CommunityUser from '@/models/graphql/CommunityUser';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CompanyRoleEnum from '@/utils/enums/CompanyRoleEnum';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import SubEditionUserRole from '@/models/graphql/SubEditionUserRole';
import SessionUserRole from '@/models/graphql/SessionUserRole';

type AuthUserCompanyRoleState = LoadableState

@Module({ namespaced: true })
export default class PermissionManagerStore
  extends LoadableStore<AuthUserCompanyRoleState> {
  get authUser(): CommunityUser | null {
    return this.context.rootState.authUser;
  }

  get companyRoles(): Array<CompanyUserRole> | undefined {
    return this.authUser?.companyRoles;
  }

  public get canModerate(): boolean {
    let canModerateSessions = false;
    let canModerateSubEdition = false;
    if (this.authUser?.subEditionRoles) {
      canModerateSubEdition = !!this.authUser.subEditionRoles.find(
        (subEditionRole: SubEditionUserRole) => subEditionRole.canModerateChat,
      );
    }
    if (this.authUser?.sessionRoles) {
      canModerateSessions = !!this.authUser.sessionRoles.find(
        (sessionUserRole: SessionUserRole) => sessionUserRole?.canModerateChat,
      );
    }
    return canModerateSessions || canModerateSubEdition;
  }

  get canPostInCompany(): (companyUid: string) => boolean {
    return (companyUid: string) => {
      if (this.companyRoles) {
        return this.companyRoles.filter((companyUserRole: CompanyUserRole) => {
          if (companyUserRole && companyUserRole.company) {
            return companyUserRole.company.uid === companyUid
              && companyUserRole.state === CompanyUserRoleStatusType.ACCEPTED
              && (companyUserRole.canPost || companyUserRole.role.code === CompanyRoleEnum.OWNER);
          }
          return false;
        }).length > 0;
      }
      return false;
    };
  }

  get haveACompanyRole(): (companyUid?: string) => boolean {
    return (companyUid = '') => (
      !!(this.companyRoles
        && this.companyRoles.length > 0
        && this.companyRoles[0].company
        && (!companyUid ? this.companyRoles[0].company.uid : this.companyRoles[0]
          .company.uid === companyUid)
        && this.companyRoles[0].role
        && this.companyRoles[0].role.code
        && this.companyRoles[0].role.code !== CompanyRoleEnum.STANDARD)
    );
  }

  get canHandleCompanyMeeting(): (companyUid: string) => boolean {
    return (companyUid: string) => {
      if (this.companyRoles) {
        return this.companyRoles.filter((companyUserRole: CompanyUserRole) => {
          if (companyUserRole && companyUserRole.company) {
            return companyUserRole.company.uid === companyUid
              && companyUserRole.state === CompanyUserRoleStatusType.ACCEPTED
              && (companyUserRole.handleMeetings || companyUserRole.role.code === CompanyRoleEnum.OWNER);
          }
          return false;
        }).length > 0;
      }
      return false;
    };
  }

  get canManageCompanyTeam(): (companyUid: string) => boolean {
    return (companyUid: string) => {
      if (this.companyRoles) {
        return this.companyRoles.filter((companyUserRole: CompanyUserRole) => {
          if (companyUserRole && companyUserRole.company) {
            return companyUserRole.company.uid === companyUid
              && companyUserRole.state === CompanyUserRoleStatusType.ACCEPTED
              && (companyUserRole.manageCompanyUserRoles || companyUserRole.role.code === CompanyRoleEnum.OWNER);
          }
          return false;
        }).length > 0;
      }
      return false;
    };
  }

  get canManageCompanyContent(): (companyUid: string) => boolean {
    return (companyUid: string) => {
      if (this.companyRoles) {
        return this.companyRoles.filter((companyUserRole: CompanyUserRole) => {
          if (companyUserRole && companyUserRole.company) {
            return companyUserRole.company.uid === companyUid
              && companyUserRole.state === CompanyUserRoleStatusType.ACCEPTED
              && (companyUserRole.manageContent || companyUserRole.role.code === CompanyRoleEnum.OWNER);
          }
          return false;
        }).length > 0;
      }
      return false;
    };
  }

  get canManageSales(): (companyUid: string) => boolean {
    return (companyUid: string) => {
      if (this.companyRoles) {
        const hasRole = this.companyRoles
          .find((companyRole) => companyRole.company
            && companyRole.company.uid === companyUid
            && companyRole.state === CompanyUserRoleStatusType.ACCEPTED
            && (companyRole.manageSales || companyRole.role.code === CompanyRoleEnum.OWNER));
        return !!hasRole;
      }
      return false;
    };
  }

  get canPostInSubEdition(): (subEditionId: string) => boolean {
    return (subEditionId: string) => {
      if (this.authUser?.subEditionRoles) {
        return this.authUser.subEditionRoles.filter((subEditionUserRole: SubEditionUserRole) => {
          if (subEditionUserRole && subEditionUserRole.subEdition) {
            return subEditionUserRole.subEdition.uid === subEditionId && subEditionUserRole.canPost;
          }
          return false;
        }).length > 0;
      }
      return false;
    };
  }
}
