import BaseModel from '@/models/BaseModel';
import Community from '@/models/graphql/Community';
/* eslint-disable import/no-cycle */
import UiPage from '@/models/graphql/UiPage';
import UiOnboarding from '@/models/graphql/UiOnboarding';

export default class UiPageWidget extends BaseModel {
  id?: number;

  displaySequence!: number;

  community?: Community;

  dataKey: string[] = [];

  page?: UiPage;

  parentWidget?: UiPageWidget;

  subWidgets: Array<UiPageWidget> = [];

  type?: string;

  payload?: string;

  createdTime?: string;

  updatedTime?: string;

  active?: boolean;

  onboarding?: UiOnboarding;

  static hydrate(item: object): UiPageWidget {
    const result = new UiPageWidget();
    Object.assign(result, item);
    return result;
  }
}
