
















































































import CommunityUser from '@/models/graphql/CommunityUser';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import Event from '@/utils/types/Event';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import MeetingParticipantState from '@/utils/enums/MeetingParticipantState';
import { differenceInMinutes, format } from 'date-fns';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import Meeting from '@/models/graphql/Meeting';
import ToastActionParams from '@/utils/types/ToastActionParams';
import EntityType from '@/utils/enums/EntityType';
import { runMathJax } from '@/utils/helpers/LatexHelper';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';

const agendaStore = namespace('AgendaStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
  },
})
export default class DailyItem extends Vue {
  @Prop({ required: false, default: null })
  private event!: Event;

  @Prop({ required: false, default: 'lg' })
  private readonly size!: string;

  @agendaStore.Getter
  private fetchViewMode!: ViewMode;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Mutation
  private setEventViewed!: (e: Event) => void;

  @agendaStore.Mutation
  private setDayBeingViewed!: (date: string) => void;

  @agendaStore.Mutation
  private setEventEdited!: (e: Event) => void;

  @agendaStore.Action
  private updateMeeting!: (payload: {
    meeting: Partial<Meeting>;
    toAdd?: string[];
    toDelete?: string[];
  }) => Promise<void>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @State
  private dateLocale!: Locale;

  @State
  private authUser!: CommunityUser;

  @State
  private community!: Community;

  private MeetingParticipantState = MeetingParticipantState;

  private ViewMode = ViewMode;

  private EntityType = EntityType;

  private isHovered = false;

   private currentMeetingParticipantUid = '';

  private isSmall = false;

  private get startTime(): string | null {
    if (this.event && this.event.tzStartTime) {
      return `${format(this.event.tzStartTime, this.$t('app.date.defaultTimeFormat') as string,
        { locale: this.dateLocale })}`;
    }
    return null;
  }

  private get isCreateEdit(): boolean {
    return this.fetchViewMode === ViewMode.CREATE || this.fetchViewMode === ViewMode.EDIT;
  }

  private get isCreator(): boolean {
    if (this.event.creator) {
      return this.event.creator.uid === this.authUser.uid;
    }
    return false;
  }

  private get type(): string {
    return this.event ? this.event.entityType : '';
  }

  private get title(): string {
    return this.event.title;
  }

  private get participantState(): MeetingParticipantState | null | undefined {
    if (!this.isCreator && this.event.participants) {
      const participant = (this.event.participants as Array<MeetingParticipant>)
        .find((meetingParticipant: MeetingParticipant) => {
          if (meetingParticipant && meetingParticipant.user) {
            return meetingParticipant.user.uid === this.authUser.uid;
          }
          return false;
        });
      if (participant) {
        this.currentMeetingParticipantUid = participant.uid;
        return participant.state as MeetingParticipantState;
      }
    }
    this.currentMeetingParticipantUid = '';
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  private updated(): void {
    runMathJax();
  }

  private mounted(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  private onResize(): void {
    this.$nextTick(() => {
      const el = this.$refs['agenda-list-item'] as Element;
      let smallWidth = false;
      if (el) {
        const { width } = el.getBoundingClientRect();
        smallWidth = width < 140;
      }
      let smallHeight = false;
      if (this.event.tzStartTime && this.event.tzEndTime) {
        const difference = differenceInMinutes(this.event.tzEndTime, this.event.tzStartTime);
        smallHeight = difference <= 45;
      }
      this.isSmall = smallWidth || smallHeight;
    });
  }

  private onEventClick(): void {
    this.setViewMode(ViewMode.DETAIL);
    this.setEventViewed(this.event);
  }

  private onEditClick(): void {
    this.setViewMode(ViewMode.EDIT);
    this.setDayBeingViewed(AgendaStoreHelper.formatDictionaryKey(this.event.tzStartTime));
    this.setEventEdited(this.event);
  }

  private cancelMeeting(): void {
    if (this.event) {
      this.event.isCancelled = true;
      this.updateMeeting({ meeting: { uid: this.event.uid, isCancelled: true } })
        .catch(() => {
          this.event.isCancelled = false;
        });
      this.addNewAction({
        message: `${this.$t('toolbox.agenda.create-edit.removed-from-agenda')}`,
        delay: 3500,
      });
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        this.event.entityType,
        StatLoggerActions.REMOVE_FROM_AGENDA,
        'removeAppointment',
        -1,
        this.event.entityId,
        StatLoggerCategories.REMOVE,
        this.$i18n.locale,
      );
    }
  }
}
