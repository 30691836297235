




















































import { Component, Prop } from 'vue-property-decorator';
import { BLink, BSkeleton, BSkeletonWrapper } from 'bootstrap-vue';
import VueContextLoading from '@/components/VueContextLoading';
import Size from '@/utils/enums/Size';

@Component({
  components: {
    BSkeleton,
    BLink,
    BSkeletonWrapper,
  },
})
export default class BadgeComponent extends VueContextLoading {
  @Prop({ required: false, default: '' })
  private readonly text!: string;

  @Prop({ required: false, default: null })
  private readonly number!: string;

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean;

  @Prop({ required: true, default: '' })
  private readonly color!: string;

  @Prop({ required: true, default: '' })
  private readonly size!: string;

  @Prop({ default: false })
  private readonly marked!: boolean;

  @Prop({ default: false })
  private readonly icon!: boolean;

  private get badgeClass(): object {
    return {
      text: true,
      label: this.size === Size.SMALL,
      'micro-badge-icon': this.size === Size.MICRO && this.icon,
      'small-badge-icon': this.size === Size.SMALL && this.icon,
      'medium-badge-icon': this.size === Size.MEDIUM && this.icon,
      'large-badge-icon': this.size === Size.LARGE && this.icon,
      'micro-badge': this.size === Size.MICRO && !this.icon,
      'small-badge': this.size === Size.SMALL && !this.icon,
      'medium-badge': this.size === Size.MEDIUM && !this.icon,
      'large-badge': this.size === Size.LARGE && !this.icon,
    };
  }
}
