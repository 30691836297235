import { Action, Module } from 'vuex-module-decorators';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import LoadableState from '@/store/states/LoadableState';
import CommunityUserBookmarkRepository from '@/repositories/CommunityUserBookmarkRepository';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
/* eslint-disable max-len */
export default class CommunityUserBookmarkStore extends LoadableStore<LoadableState> {
  private readonly communityUserBookmarkRepository = new CommunityUserBookmarkRepository();

  protected get repository(): CommunityUserBookmarkRepository {
    return this.communityUserBookmarkRepository;
  }

  @Action
  bookmark(payload: CommunityUserBookmarkFilter): Promise<CommunityUserBookmark | undefined> {
    this.context.commit('load', true);
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'user_CommunityUserUid',
          type: 'ID',
          value: payload.userId,
        },
        {
          fieldName: 'linkTarget_LinkableUid',
          type: 'ID',
          value: payload.linkedUserId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMUNITY_USER_BOOKMARK_INPUT,
          value: { targetType: payload.entityType },
        },
      ]),
    }).then((response) => {
      if (response) {
        this.context.dispatch(
          'CommunityUserFollowStore/bookmarkAutoFollow',
          payload,
          { root: true },
        );
        this.context.dispatch(
          'FeedItemWrapperStore/updateFeedItemBookmarkState',
          { feedItemWrapperUid: payload?.linkedUserId, ...response },
          { root: true },
        );
      }
      return response;
    }).finally(() => {
      this.context.commit('load', false);
    });
  }

  @Action
  unBookmark(payload: CommunityUserBookmarkFilter): Promise<CommunityUserBookmark | undefined> {
    this.context.commit('load', true);
    return this.repository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: payload.uid,
        },
      ]),
    }).then((response) => response)
      .finally(() => {
        this.context.commit('load', false);
      });
  }
}
