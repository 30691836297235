








































import { Component } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ClientStorage from '@/utils/ClientStore';
import GdprSettingModal from '@/components/modals/GdprSettingModal.vue';
import GdprCookieInterface from '@/models/GdprCookieInterface';
import GDPR from '@/utils/constants/Gdpr';
import { Getter } from 'vuex-class';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations/dist/vue';
import Vue from 'vue';
import { Integrations } from '@sentry/tracing';
import useTestDataAttribute from '@/utils/TestDataAttribute';

@Component({
  methods: { useTestDataAttribute },
  components: {
    CheckboxSwitchComponent,
    ButtonComponent,
    GdprSettingModal,
  },
  data(): object {
    return {
      show: false,
    };
  },
})
export default class GdprFormComponent extends VueBaseWidget {
  @Getter
  private isLoading!: boolean;

  mounted(): void {
    const gdprSettings = ClientStorage.getItem(GDPR);
    if ((gdprSettings == null || typeof JSON.parse(gdprSettings) === 'boolean')) {
      this.$data.show = true;
    } else {
      this.setSentry();
    }
  }

  private closeForm(): void {
    this.$data.show = false;
  }

  private setStorage(state: boolean): void {
    const settings: GdprCookieInterface = {
      necessary: true,
      analytics: state,
      advertising: state,
      performance: state,
    };
    ClientStorage.setItem(GDPR, JSON.stringify(settings));
    this.setMatomo();
    this.setSentry();
    this.closeForm();
  }

  private onSave(): void {
    this.setMatomo();
    this.setSentry();
    this.closeForm();
  }

  private onCancel(): void {
    this.$data.show = true;
  }

  private showGdprModal(): void {
    this.closeForm();
    this.$bvModal.show('gdpr-setting-modal');
  }

  private setCommunityLog(): void {
    if (this.community && this.community.matomoIdSite) {
      this.$logger.log(['setSiteId', this.community.matomoIdSite]);
    }
    if (this.community && this.community.code) {
      this.$logger.log(['setCustomDimension', 1, this.community.code]);
    } else {
      this.$logger.log(['setCustomDimension', 1, null]);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private setMatomo(): void {
    window.putMatomoScript();
    this.setCommunityLog();
  }

  // eslint-disable-next-line class-methods-use-this
  private setSentry(): void {
    const gdprSettings: GdprCookieInterface = JSON.parse(ClientStorage.getItem(GDPR) as string);
    if (gdprSettings && gdprSettings.analytics) {
      Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.NODE_ENV,
        integrations: [
          new VueIntegration({
            Vue,
            tracing: true,
          }),
          new Integrations.BrowserTracing(),
        ],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
      });
    }
  }
}
