import { Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';

@Module({ namespaced: true, stateFactory: true })
export default class UiOnBoardingWidgetStore extends WidgetBaseStore {
  private totalSteps = 1;

  private currentStep = 1;

  private onboardingPositions: string[] = [];

  @Mutation
  nextStep(): void {
    if (this.currentStep < this.totalSteps) {
      this.currentStep += 1;
    } else {
      this.currentStep = 1;
    }
  }

  @Mutation
  prevStep(): void {
    if (this.currentStep > 1) {
      this.currentStep -= 1;
    } else {
      this.currentStep = 1;
    }
  }

  @Mutation
  setTotalSteps(steps: number): void {
    this.totalSteps = steps;
  }

  @Mutation
  setOnboardingPositions(onboarding: string): void {
    this.onboardingPositions.push(onboarding);
  }
}
