import { ApolloQueryResult, FetchResult } from '@apollo/client';
import Session from '@/models/graphql/Session';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import ReadInterface from '@/repositories/base/ReadInterface';

export default class SessionRepository extends GraphQlRepository<Session>
  implements ReadInterface<Session>, UpdateInterface<Session> {
  filter(params: GqlPayloadParams): Promise<Session[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterSession',
      operation: 'session',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'sessionBaseFragment',
      authUser: params.authUser,
      params: params.params,
    }).then((query) => this.query<{ session: Array<Session> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ session: Array<Session> }>) => response.data.session));
  }

  filterStartDate(params: GqlPayloadParams): Promise<string[]> {
    return buildQueryGql({
      operationName: params.operationName || 'LoadSessionsStartDate',
      operation: 'session',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'sessionStartDateFragment',
      authUser: params.authUser,
      magicArgs: params.magicArgs,
      params: params.params,
    }).then((query) => this.query<{ session: Array<Session> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ session: Array<Session> }>) => response.data.session
        .filter((s) => s.startDate)
        .map((s) => s.startDate as string)));
  }

  get(params: GqlPayloadParams): Promise<Session | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetSession',
      operation: 'session',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'sessionBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ session: Array<Session> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ session: Array<Session> }>) => response.data.session[0]));
  }

  update(params: GqlPayloadParams): Promise<Session | undefined> {
    return buildMutationGql({
      operationName: 'UpdateSession',
      operation: 'SessionUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'sessionBaseFragment',
    }).then((mutation) => this.mutate<{ SessionUpdate: Session }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ SessionUpdate: Session }>) => response.data?.SessionUpdate));
  }
}
