import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import FeedState from '@/models/graphql/FeedState';
import FeedStateRepository from '@/repositories/FeedStateRepository';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

interface CommunityUserState extends LoadableState {
    feedState: FeedState[];
    feedStateState: FeedState;
  }

  @Module({ namespaced: true })
export default class FeedStateStore extends LoadableStore<CommunityUserState> {
    feedStates: FeedState[] = [];

    currentFeedState: FeedState = {} as FeedState;

    private readonly feedStateRepository = new FeedStateRepository();

    get getCurrentFeedState(): FeedState {
      return this.currentFeedState;
    }

    @Action
    create(payload: {userUid: string; lastView: number; feedUid: string}): void {
      this.context.commit('load', true);
      const entity: Partial<FeedState> = {
        lastViewedFeed: payload.lastView,
      };
      this.feedStateRepository.create({
        definition: buildMutationDefinition([
          {
            fieldName: 'user_CommunityUserUid',
            type: 'ID',
            value: payload.userUid,
          },
          {
            fieldName: 'feedEntity_NodeUid',
            type: 'ID',
            value: payload.feedUid,
          },
          {
            fieldName: 'entity',
            type: GqlEntityInputType.FEED_STATE_INPUT,
            value: entity,
          },
        ]),
      }).then((response) => {
        this.context.dispatch('CommunityUserStore/setFeedStates', {
          authUser: this.context.rootState.authUser,
          feedState: response,
        }, { root: true });
      });
    }

    @Action
    update(payload: {lastView: number; feedStateUid: string}): void {
      const entity: Partial<FeedState> = {
        uid: payload.feedStateUid,
        lastViewedFeed: payload.lastView,
      };
      this.feedStateRepository.update({
        definition: buildMutationDefinition([
          {
            fieldName: 'entity',
            type: GqlEntityInputType.FEED_STATE_INPUT,
            value: entity,
          },
        ]),
      }).then((response) => {
        this.context.dispatch('CommunityUserStore/setFeedStates', {
          authUser: this.context.rootState.authUser,
          feedState: response,
        }, { root: true });
      });
    }
}
