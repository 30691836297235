import { Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';

@Module({ namespaced: true, stateFactory: true })
export default class SponsorListWidgetStore extends WidgetBaseStore {
  @Mutation
  mapper(): void {
    let data = [];
    if (this.widget && this.widget.subWidgets && this.widget.subWidgets.length > 0) {
      data = this.widget.subWidgets.map((sub) => ({
        uid: sub.uid,
        ...JSON.parse(sub.payload || '{}'),
      }));
    }
    this.mapping = {
      ...this.payload,
      data,
    };
  }
}
