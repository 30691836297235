import { Action, Module, Mutation } from 'vuex-module-decorators';
import { format, isAfter } from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import LoadableState from '@/store/states/LoadableState';
import Agenda from '@/models/graphql/Agenda';
import AgendaRepository from '@/repositories/AgendaRepository';
import Meeting from '@/models/graphql/Meeting';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import AgendaFilter from '@/graphql/_Filters/AgendaFilter';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import Event from '@/utils/types/Event';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import ListFilter from '@/utils/enums/agenda/ListFilter';
import MeetingParticipantState from '@/utils/enums/MeetingParticipantState';
import EntityType from '@/utils/enums/EntityType';
import CommunityUser from '@/models/graphql/CommunityUser';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import ScheduleEvent from '@/models/graphql/ScheduleEvent';
import Session from '@/models/graphql/Session';

/* eslint-disable @typescript-eslint/camelcase */

interface AgendaState extends LoadableState {
  agenda: { [x: string]: Array<Event> };

  searchResult: { [x: string]: Array<Event> };

  listResult: { [x: string]: Array<Event> };

  newEventDays: string[];

  dayBeingViewed: string;

  viewMode: string;

  eventBeingEdited: Event;

  event: Event;

  listFilter: string;

  pendingFilterCount: number;

  meetingFilterCount: number;

  sessionFilterCount: number;

  meetingSaveLoading: boolean;
}

@Module({ namespaced: true })
/* eslint-disable max-len */
export default class AgendaStore extends LoadableStore<AgendaState> {
  private readonly agendaRepository = new AgendaRepository();

  // view
  private newEventDays: string[] = [];

  private dayBeingViewed = format(DateTimeHelper.getCurrentDateTime(), 'yyyy-MM-dd');

  private viewMode = ViewMode.DAILY_VIEW;

  private prevMode = ViewMode.DAILY_VIEW;

  // load
  private loadingAgenda = false;

  private agenda: { [x: string]: Array<Event> } = {};

  private searchResult: { [x: string]: Array<Event> } = {};

  private listResult: { [x: string]: Array<Event> } = {};

  // edit
  private eventBeingEdited: Event | {} = {};

  // detail
  private event: Event | {} = {};

  // updating agenda
  private meetingSaveLoading = false;

  // filter
  private listFilter = '';

  private pendingFilterCount = 0;

  private meetingFilterCount = 0;

  private sessionFilterCount = 0;

  private createStartTimeUpdated = 0;

  protected get repository(): AgendaRepository {
    return this.agendaRepository;
  }

  private get fetchEventsDays(): string[] {
    return this.newEventDays;
  }

  private get fetchDayBeingViewed(): string {
    return this.dayBeingViewed;
  }

  private get fetchViewMode(): string {
    return this.viewMode;
  }

  private get fetchPrevViewMode(): string {
    return this.prevMode;
  }

  private get fetchListResult(): { [x: string]: Array<Event> } {
    return this.listResult;
  }

  private get fetchNewAgendaData(): { [x: string]: Array<Event> } {
    return this.agenda;
  }

  private get fetchSearchResult(): { [x: string]: Array<Event> } {
    return this.searchResult;
  }

  private get fetchLoadingAgenda(): boolean {
    return this.loadingAgenda;
  }

  private get fetchEventEdit(): Event | {} {
    return this.eventBeingEdited;
  }

  private get fetchEventDetail(): Event | {} {
    return this.event;
  }

  private get fetchMeetingSaveLoading(): boolean {
    return this.meetingSaveLoading;
  }

  private get fetchPendingFilterCount(): number {
    return this.pendingFilterCount;
  }

  private get fetchMeetingFilterCount(): number {
    return this.meetingFilterCount;
  }

  private get fetchSessionFilterCount(): number {
    return this.sessionFilterCount;
  }

  private get fetchListFilter(): string {
    return this.listFilter;
  }

  @Action
  loadAgendaData(payload: { filter: AgendaFilter }): Promise<void> {
    this.context.commit('load', true);
    return this.repository.loadComposedQueries<Agenda>({
      operationName: 'LoadAgendaData',
      definitions: [{
        operation: 'meeting',
        alias: 'meetings',
        fragmentName: 'meetingForAgendaFragment',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.MEETING_FILTER,
            value: {
              isCancelled: false,
              _inMeeting: this.context.rootGetters.authUser.uid,
              ...payload.filter.orMeeting,
            },
          },
        }),
      }, {
        operation: 'session',
        alias: 'sessions',
        fragmentName: 'sessionForAgendaFragment',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.SESSION_FILTER,
            value: {
              _inAgenda: this.context.rootGetters.authUser.uid,
              ...payload.filter.orSession,
            },
          },
        }),
      }, {
        operation: 'scheduleEvent',
        alias: 'scheduleEvents',
        fragmentName: 'scheduleEventFullFragment',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.SCHEDULE_EVENT_FILTER,
            value: {
              _inAgenda: this.context.rootGetters.authUser.uid,
              ...payload.filter.orSession,
            },
          },
        }),
      }],
      authUser: this.context.rootGetters.authUser.uid,
    }).then((response: Agenda): void => {
      const result = AgendaStoreHelper.newDictionary(response, this.agenda, this.context.rootState.selectedTzName);
      this.context.commit('setAgenda', result);
      this.context.commit('setListResult', {
        agenda: result,
        authUser: this.context.rootState.authUser,
      });
    }).finally(() => {
      this.context.commit('load', false);
    });
  }

  @Action
  openAgendaFromUrl(payload: { entityType: string; entityUid: string }): Promise<boolean | void> {
    this.context.commit('load', true);
    if (payload.entityType === EntityType.MEETING.toLowerCase()) {
      return this.repository.loadComposedQueries<{meeting: Meeting[]}>({
        operationName: 'LoadAgendaFromUrl',
        definitions: [{
          operation: 'meeting',
          fragmentName: 'meetingForAgendaFragment',
          gqlDefinition: buildQueryDefinition({
            filter: {
              type: GqlEntityFilterType.MEETING_FILTER,
              value: {
                isCancelled: false,
                _inMeeting: this.context.rootGetters.authUser.uid,
                uid: payload.entityUid,
              },
            },
          }),
        }],
        authUser: this.context.rootGetters.authUser.uid,
      }).then((response) => {
        if (response && response.meeting && response.meeting.length > 0) {
          this.context.commit('setViewMode', ViewMode.DETAIL);
          this.context.commit('setEventViewed', AgendaStoreHelper.convertMeetingToEvent(response.meeting[0], this.context.rootState.selectedTzName));
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      }).catch(() => Promise.resolve(false))
        .finally(() => {
          this.context.commit('load', false);
        });
    }
    if (payload.entityType === EntityType.SESSION.toLowerCase()) {
      this.repository.loadComposedQueries<{session: Session[]}>({
        operationName: 'LoadAgendaFromUrl',
        definitions: [{
          operation: 'session',
          fragmentName: 'sessionForAgendaFragment',
          gqlDefinition: buildQueryDefinition({
            filter: {
              type: GqlEntityFilterType.SESSION_FILTER,
              value: {
                _inAgenda: this.context.rootGetters.authUser.uid,
                uid: payload.entityUid,
              },
            },
          }),
        }],
        authUser: this.context.rootGetters.authUser.uid,
      }).then((response) => {
        if (response && response.session && response.session.length > 0) {
          this.context.commit('setViewMode', ViewMode.DETAIL);
          this.context.commit('setEventViewed', AgendaStoreHelper.convertSessionToEvent(
            response.session[0], this.context.rootState.selectedTzName,
          ));
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      }).catch(() => Promise.resolve(false))
        .finally(() => {
          this.context.commit('load', false);
        });
    }
    if (payload.entityType === EntityType.SCHEDULE_EVENT.toLowerCase()) {
      this.repository.loadComposedQueries<{ scheduleEvent: ScheduleEvent[] }>({
        operationName: 'LoadAgendaFromUrl',
        definitions: [{
          operation: 'scheduleEvent',
          fragmentName: 'scheduleEventFullFragment',
          gqlDefinition: buildQueryDefinition({
            filter: {
              type: GqlEntityFilterType.SCHEDULE_EVENT_FILTER,
              value: {
                _inAgenda: this.context.rootGetters.authUser.uid,
                uid: payload.entityUid,
              },
            },
          }),
        }],
        authUser: this.context.rootGetters.authUser.uid,
      }).then((response) => {
        if (response && response.scheduleEvent && response.scheduleEvent.length > 0) {
          this.context.commit('setViewMode', ViewMode.DETAIL);
          this.context.commit('setEventViewed', AgendaStoreHelper.convertScheduleOfEventsToEvent(
            response.scheduleEvent[0], this.context.rootState.selectedTzName,
          ));
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      }).catch(() => Promise.resolve(false))
        .finally(() => {
          this.context.commit('load', false);
        });
    }
    return Promise.resolve(false);
  }

  @Action
  searchInAgenda(searchQuery: string): Promise<void> {
    this.context.commit('load', true);
    return this.repository.loadComposedQueries<Agenda>({
      operationName: 'SearchInAgenda',
      definitions: [{
        operation: 'meeting',
        alias: 'meetings',
        fragmentName: 'meetingForAgendaFragment',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.MEETING_FILTER,
            value: {
              isCancelled: false,
              _inMeeting: this.context.rootGetters.authUser.uid,
              OR: [
                { subject_contains: searchQuery },
                { description_contains: searchQuery },
                { location_contains: searchQuery },
              ],
            },
          },
        }),
      }, {
        operation: 'session',
        alias: 'sessions',
        fragmentName: 'sessionForAgendaFragment',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.SESSION_FILTER,
            value: {
              _inAgenda: this.context.rootGetters.authUser.uid,
              OR: [
                { name_contains: searchQuery },
                { description_contains: searchQuery },
              ],
            },
          },
        }),
      }, {
        operation: 'scheduleEvent',
        alias: 'scheduleEvents',
        fragmentName: 'scheduleEventFullFragment',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.SCHEDULE_EVENT_FILTER,
            value: {
              _inAgenda: this.context.rootGetters.authUser.uid,
              OR: [
                { name_contains: searchQuery },
                { description_contains: searchQuery },
                { session: { name_contains: searchQuery } },
                { childSessions: { name_contains: searchQuery } },
              ],
            },
          },
        }),
      }],
      authUser: this.context.rootGetters.authUser.uid,
    }).then((response: Agenda): void => {
      const result = AgendaStoreHelper.newDictionary(response, {}, this.context.rootState.selectedTzName);
      this.context.commit('setSearchElements', result);
    }).finally(() => {
      this.context.commit('load', false);
    });
  }

  @Action
  addToAgenda(event: Event): void {
    const newAgenda = AgendaStoreHelper.addEventToAgenda(event, this.agenda);
    this.context.commit('setAgenda', newAgenda);
  }

  @Action
  removeFromAgenda(event: Event): void {
    const newAgenda = AgendaStoreHelper.removeEventFromAgenda(event, this.agenda);
    const result = AgendaStoreHelper.removeEventFromAgenda(event, this.listResult);
    this.context.commit('setAgenda', newAgenda);
    this.context.commit('setListResult', {
      agenda: result,
      authUser: this.context.rootState.authUser,
    });
  }

  @Action
  updateMeeting(payload: {
    meeting: Partial<Meeting>;
    toAdd?: string[];
    toDelete?: string[];
  }): Promise<void> {
    this.context.commit('setMeetingSaveLoading', true);

    const participantsDefinitions = (payload.toDelete || []).map((participantUid, index) => ({
      operation: 'MeetingParticipantDelete',
      fragmentName: 'meetingParticipantDeleteFragment',
      alias: `removeParticipant${index}`,
      gqlDefinition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: participantUid,
        },
      ]),
    }));
    participantsDefinitions.push(...(payload.toAdd || []).map((userUid, index) => ({
      operation: 'MeetingParticipantCreateForMeetingAndUser',
      fragmentName: 'meetingParticipantBaseFragment',
      alias: `addParticipant${index}`,
      gqlDefinition: buildMutationDefinition([
        {
          fieldName: 'meeting_MeetingUid',
          type: GqlEntityInputType.ID,
          value: payload.meeting.uid,
        },
        {
          fieldName: 'user_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: userUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.MEETING_PARTICIPANT_INPUT,
          value: {
            state: MeetingParticipantState.INVITED,
          },
        },
      ]),
    })));

    let participantsPromise: Promise<Record<string, MeetingParticipant>[]> | null = null;
    if (participantsDefinitions.length > 0) {
      participantsPromise = this.repository.toggleParticipants({
        definitions: participantsDefinitions,
        authUser: this.context.rootGetters.authUser.uid,
      });
    }

    return Promise.all([participantsPromise])
      .then(() => this.repository.update({
        definition: buildMutationDefinition([{
          fieldName: 'entity',
          type: GqlEntityInputType.MEETING_INPUT,
          value: {
            uid: payload.meeting.uid,
            subject: payload.meeting.subject,
            description: payload.meeting.description,
            location: payload.meeting.location,
            startTime: payload.meeting.startTime,
            endTime: payload.meeting.endTime,
            timeZoneName: payload.meeting.timeZoneName,
            isCancelled: payload.meeting.isCancelled,
          },
        }]),
      }).then((meeting) => {
        if (meeting) {
          let newAgenda = AgendaStoreHelper.removeEventFromAgenda(meeting, this.agenda);
          let newListResult = AgendaStoreHelper.removeEventFromAgenda(meeting, this.listResult);
          if (meeting.isCancelled) {
            this.context.commit('setMeetingCount', this.meetingFilterCount - 1);
          } else {
            const event = AgendaStoreHelper.convertMeetingToEvent(meeting, this.context.rootState.selectedTzName);
            newAgenda = AgendaStoreHelper.addEventToAgenda(event, this.agenda);
            newListResult = AgendaStoreHelper.addEventToAgenda(event, this.listResult);
            this.context.commit('setAgenda', newAgenda);
          }
          this.context.commit('setListResult', newListResult);
          this.context.commit('setAgenda', newAgenda);
        }
        this.context.commit('ChatDispatcherStore/ChatStore/updateMessageGroupTarget', payload.meeting, { root: true });
      })).finally(() => {
        this.context.commit('setMeetingSaveLoading', false);
      });
  }

  @Action
  createMeeting(payload: {
    meeting: Partial<Meeting>;
    userUid: string;
    meetingRequestUid: string;
  }): Promise<Meeting | null | void> {
    this.context.commit('setMeetingSaveLoading', true);
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'creatorUid',
          type: GqlEntityInputType.REQUIRED_ID,
          value: payload.userUid,
        },
        {
          fieldName: 'meetingRequestUid',
          type: GqlEntityInputType.ID,
          value: payload.meetingRequestUid,
        },
        {
          fieldName: 'participants',
          type: GqlEntityInputType.ID_ARRAY,
          value: payload.meeting.participants ? payload.meeting.participants
            .map((p) => (p.user && p.user.uid && p.user.uid !== payload.userUid ? p.user.uid : null))
            .filter((uid) => uid !== null) : [],
        },
        {
          fieldName: 'entity',
          type: ' MeetingInput!',
          value: {
            subject: payload.meeting.subject,
            description: payload.meeting.description,
            location: payload.meeting.location,
            url: payload.meeting.url,
            startTime: payload.meeting.startTime,
            endTime: payload.meeting.endTime,
            timeZoneName: payload.meeting.timeZoneName,
            isCancelled: false,
            readOnly: false,
          },
        },
      ]),
    }).then((response) => {
      if (response) {
        if (payload.userUid === this.context.rootGetters.authUser.uid) {
          this.context.commit('setMeetingCount', this.meetingFilterCount + 1);
        }
        if (response.meetingRequest) {
          if (response.meetingRequest.trash) {
            this.context.dispatch(
              'CompanyCalendarStore/archiveMeetingRequest',
              { uid: response.meetingRequest.uid, trash: false },
              { root: true },
            );
          }
          this.context.commit(
            'CompanyCalendarStore/addMeeting',
            { uid: response.meetingRequest.uid, meeting: response },
            { root: true },
          );
        }
        const event = AgendaStoreHelper.convertMeetingToEvent(response as Meeting, this.context.rootState.selectedTzName);
        const newAgenda = AgendaStoreHelper.addEventToAgenda(event, this.agenda);
        AgendaStoreHelper.addEventToAgenda(event, this.listResult);
        this.context.commit('setAgenda', newAgenda);
      }
    }).finally(() => {
      this.context.commit('setMeetingSaveLoading', false);
    });
  }

  @Action
  updateMeetingParticipantState(payload: { event: Event; meetingParticipantUid: string; state: string }): Promise<void> {
    return this.repository.updateMeetingParticipantState({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.MEETING_PARTICIPANT_INPUT,
          value: {
            uid: payload.meetingParticipantUid,
            state: payload.state,
          },
        },
      ]),
    }).then(() => {
      if (payload.event && payload.event.participants) {
        const p = (payload.event.participants as MeetingParticipant[])
          .filter((m: MeetingParticipant) => m.uid === payload.meetingParticipantUid)[0];
        if (p.state === MeetingParticipantState.INVITED) {
          this.context.commit('setPendingMeetingCount', this.pendingFilterCount - 1);
        }
        AgendaStoreHelper.updateMeetingParticipantState(
          this.agenda,
          payload.event,
          this.context.rootGetters.authUser,
            payload.state as MeetingParticipantState,
        );
        AgendaStoreHelper.updateMeetingParticipantState(
          this.searchResult,
          payload.event,
          this.context.rootGetters.authUser,
            payload.state as MeetingParticipantState,
        );
        AgendaStoreHelper.updateMeetingParticipantState(
          this.listResult,
          payload.event,
          this.context.rootGetters.authUser,
            payload.state as MeetingParticipantState,
        );
      }
    });
  }

  @Action
  loadAgendaCounts(now: number): void {
    this.context.commit('load', true);
    this.repository.loadComposedQueries<Record<string, Array<{count: number}>>>({
      operationName: 'LoadAgendaCounts',
      definitions: [{
        operation: 'meeting',
        fragmentName: 'meetingCountFragment',
        alias: 'upcomingMeetingCount',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.MEETING_FILTER,
            value: {
              isCancelled: false,
              _inMeeting: this.context.rootGetters.authUser.uid,
              startTimestamp_gte: now,
            },
          },
        }),
      }, {
        operation: 'meetingParticipant',
        fragmentName: 'meetingParticipantCountFragment',
        alias: 'upcomingPendingMeetingCount',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.MEETING_PARTICIPANT_FILTER,
            value: {
              state: MeetingParticipantState.INVITED,
              user: { uid: this.context.rootGetters.authUser.uid },
              meeting: {
                isCancelled: false,
                startTimestamp_gte: now,
              },
            },
          },
        }),
      }, {
        operation: 'session',
        fragmentName: 'sessionCountFragment',
        alias: 'upcomingSessionCount',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.SESSION_FILTER,
            value: {
              _inAgenda: this.context.rootGetters.authUser.uid,
              startTimestamp_gte: now,
            },
          },
        }),
      }],
    }).then((response) => {
      if (response && Object.keys(response).length > 0) {
        if ('upcomingMeetingCount' in response && response.upcomingMeetingCount && response.upcomingMeetingCount.length > 0) {
          this.context.commit('setMeetingCount', response.upcomingMeetingCount[0].count);
        }
        if ('upcomingSessionCount' in response && response.upcomingSessionCount && response.upcomingSessionCount.length > 0) {
          this.context.commit('setSessionCount', response.upcomingSessionCount[0].count);
        }
        if ('upcomingPendingMeetingCount' in response && response.upcomingPendingMeetingCount && response.upcomingPendingMeetingCount.length > 0) {
          this.context.commit('setPendingMeetingCount', response.upcomingPendingMeetingCount[0].count);
        }
      }
    }).finally(() => {
      this.context.commit('load', false);
    });
  }

  @Mutation
  setViewMode(mode: ViewMode): void {
    this.prevMode = this.viewMode;
    if (mode === this.prevMode) {
      this.viewMode = ViewMode.DAILY_VIEW;
    } else {
      this.viewMode = mode;
    }
  }

  @Mutation
  setDayBeingViewed(date: string): void {
    this.dayBeingViewed = date;
  }

  @Mutation
  setMeetingSaveLoading(meetingSaveLoading: boolean): void {
    this.meetingSaveLoading = meetingSaveLoading;
  }

  @Mutation
  setListResult({
    agenda,
    authUser,
  }: { agenda: { [x: string]: Array<Event> }; authUser: CommunityUser }): void {
    const result: { [x: string]: Array<Event> } = {};
    if (this.listFilter === ListFilter.PENDING) {
      Object.keys(agenda).forEach((key) => {
        result[key] = agenda[key]
          .filter((e: Event) => {
            const meetingParticipant = (e
              .participants as Array<MeetingParticipant>)
              ?.find((participant: MeetingParticipant) => authUser && participant.user?.uid === authUser.uid);
            const isInvited = meetingParticipant ? meetingParticipant.state === MeetingParticipantState.INVITED : false;
            return 'creator' in e
              && e && e.creator && authUser
              && e.creator.uid !== authUser.uid && isInvited
              && isAfter(e.tzStartTime, new Date());
          });
      });
    } else if (this.listFilter === ListFilter.MEETINGS) {
      Object.keys(agenda).forEach((key) => {
        result[key] = agenda[key]
          .filter((e: Event) => e.entityType === EntityType.MEETING
            && isAfter(e.tzStartTime, new Date()));
      });
    } else if (this.listFilter === ListFilter.SESSIONS) {
      Object.keys(agenda).forEach((agendaData) => {
        result[agendaData] = agenda[agendaData]
          .filter((e: Event) => e.entityType === EntityType.SESSION && isAfter(e.tzStartTime, new Date()));
      });
    }

    const agendaEmptyKeys = Object.keys(result).filter((
      k: string,
    ) => result && result[k] && result[k].length === 0);
    agendaEmptyKeys.forEach((k: string) => {
      if (result && result[k]) {
        delete result[k];
      }
    });

    this.listResult = Object.keys(result).sort().reduce(
      (obj: { [x: string]: Array<Event> }, k) => {
        if (result && result) {
          // eslint-disable-next-line no-nested-ternary
          obj[k] = result[k].sort((a, b) => (a.startTime > b.startTime ? 1 : a.startTime < b.startTime ? -1 : 0));
        }
        return obj;
      }, {},
    );
  }

  @Mutation
  setSearchElements(search: { [x: string]: Array<Event> }): void {
    this.searchResult = search;
  }

  @Mutation
  setAgenda(agenda: { [x: string]: Array<Event> }): void {
    this.agenda = {};
    this.agenda = agenda;
    this.newEventDays = Object.keys(agenda);
  }

  @Mutation
  resetAgenda(): void {
    this.viewMode = ViewMode.DAILY_VIEW;
    this.dayBeingViewed = AgendaStoreHelper.formatDictionaryKey(DateTimeHelper.getCurrentDateTime());
    this.eventBeingEdited = {};
    this.event = {};
  }

  @Mutation
  setEventEdited(e: Partial<Event>): void {
    if (Object.keys(e).length === 0) {
      this.eventBeingEdited = {};
    } else {
      this.eventBeingEdited = {
        ...this.eventBeingEdited,
        ...e,
      };
    }
  }

  @Mutation
  setEventViewed(e: Event): void {
    this.event = e;
  }

  @Mutation
  setListFilter(filter: ListFilter): void {
    this.listFilter = filter;
  }

  @Mutation
  setPendingMeetingCount(meetingCount: number): void {
    this.pendingFilterCount = meetingCount;
  }

  @Mutation
  setMeetingCount(meetingCount: number): void {
    this.meetingFilterCount = meetingCount;
  }

  @Mutation
  setSessionCount(sessionCount: number): void {
    this.sessionFilterCount = sessionCount;
  }

  @Mutation
  updateCreateStartTime(): void {
    this.createStartTimeUpdated += 1;
  }

  @Mutation
  initAgenda(): void {
    this.agenda = {};
  }
}
