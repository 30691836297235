import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Data } from '@/utils/types/WidgetData';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import EntityType from '@/utils/enums/EntityType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class ResourcesFormWidgetStore extends WidgetBaseStore {
  private dataValues: Data[] = [];

  @Action
  setDataConfigs(): void {
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    const variables = { filter: {} };

    const {
      entityCode,
      type,
    } = JSON.parse(this.widget?.payload || '{}');

    if (type.toLowerCase() === EntityType.SESSION.toLowerCase()) {
      const fragmentName = 'sessionHandoutsFileResourceFragment';
      const operation = 'session';
      variables.filter = { uid: entityCode };
      const filterType = GqlEntityFilterType.SESSION_FILTER;
      configs.push({
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: variables.filter,
            type: filterType,
          },
        }),
        fragmentName,
        operation,
        alias: this.widgetStoreName,
      });
      super.setDataConfigs(configs);
    }

    if (type.toLowerCase() === EntityType.SPEAKER.toLowerCase()
      && this.context.rootState.authUser?.speakers[0]?.uid) {
      const fragmentName = 'speakerHandoutsFragment';
      const operation = 'speaker';
      variables.filter = { uid: this.context.rootState.authUser.speakers[0].uid };
      const filterType = GqlEntityFilterType.SPEAKER_FILTER;
      configs.push({
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: variables.filter,
            type: filterType,
          },
        }),
        fragmentName,
        operation,
        alias: this.widgetStoreName,
      });
      super.setDataConfigs(configs);
    }
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    if (data.values.length) {
      this.dataValues = data.values;
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      data: this.dataValues,
    };
  }
}
