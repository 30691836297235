/* eslint-disable import/no-cycle, no-underscore-dangle */
import BaseModel from '@/models/BaseModel';
import Community from '@/models/graphql/Community';
import Edition from '@/models/graphql/Edition';
import Exhibitor from '@/models/graphql/Exhibitor';
import Product from '@/models/graphql/Product';
import FileResource from '@/models/graphql/FileResource';
import Category from '@/models/graphql/Category';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import LargeProduct from '@/models/graphql/LargeProduct';
import EntityTranslationParams from '@/utils/types/EntityTranslationParams';

class Deal extends BaseModel {
  id?: number;

  community?: Community;

  edition?: Edition;

  name?: string;

  title?: string;

  content?: string;

  deleted?: boolean;

  startTime?: Date;

  endTime?: Date;

  startTimestamp?: number;

  endTimestamp?: number;

  exhibitor?: Exhibitor;

  product?: Product;

  largeProduct?: LargeProduct;

  displayFileResource?: FileResource;

  categories?: Array<Category>;

  _isBookmarked?: string | null;

  _isFollowed?: string | null;

  _isShared?: string | null;

  _isInAgenda?: string | null;

  _myTags?: CommunityUserTag[];

  _translations: Array<{
    locale: string;
    values: Array<{key: string; value: string | null}>;
  }> = [];

  get mainPicture(): string {
    return FileResourceHelper.getFullPath(this.displayFileResource, 'w40');
  }

  get translation(): EntityTranslationParams[] {
    const result: EntityTranslationParams[] = [];
    if (this._translations.length > 0) {
      this._translations
        .forEach((trans) => {
          result.push(
            ...trans.values
              .map((val) => ({
                locale: trans.locale,
                value: val.value,
                field: val.key,
              })),
          );
        });
    }
    return result;
  }

  translationsByField(field: string): EntityTranslationParams[] {
    return this.translation.filter((trans) => trans.field === field);
  }

  isMissingTranslation(locales: string[], fields: string[]): boolean {
    return this.translation.length === 0 || this.missingTranslation(locales, fields).length > 0;
  }

  missingTranslation(locales: string[], fields: string[]): EntityTranslationParams[] {
    return this.translation
      .filter((trans) => fields.findIndex((f) => f === trans.field) > -1
        && locales.findIndex((l) => l === trans.locale) > -1
        && !trans.value);
  }

  static hydrate(item: object): Deal {
    const result = new Deal();
    Object.assign(result, item);

    return result;
  }
}

export default Deal;
