


















































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ListViewItemParams from '@/utils/types/ListViewItemParams';
import EntityType from '@/utils/enums/EntityType';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import MessageUserSuggestionComponent from '@/components/chat/MessageUserSuggestionComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import { Getter, namespace } from 'vuex-class';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import MessageMenuItem from '@/utils/enums/chat/MessageMenuItem';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import IllustrationType from '@/utils/enums/IllustrationType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';

const chatStore = namespace('ChatDispatcherStore/ChatStore');
const communityUserChatStore = namespace('CommunityUserChatStore');
const businessCardStore = namespace('BusinessCardStore');
const toastStore = namespace('ToastStore');

@Component({
  methods: { useTestDataAttribute },
  components: {
    IllustrationComponent,
    FontAwesomeComponent,
    ConfirmModal,
    ButtonComponent,
    MessageUserSuggestionComponent,
    ButtonIconComponent,
    InputSearchComponent,
  },
})
export default class MessageBoxOptionsModal extends BreakpointWrapper {
  @Getter
  protected readonly authUser!: CommunityUser;

  @Getter
  protected readonly community!: Community;

  @Prop({ required: false, default: null })
  private readonly actionType!: MessageMenuItem | null

  @Prop({ required: true })
  private readonly groupId!: string;

  @Prop({ required: false, default: () => [] })
  private readonly users!: CommunityUser[];

  private IllustrationType = IllustrationType;

  private MessageMenuItem = MessageMenuItem;

  @chatStore.Action
  private addParticipants!: (model: {
    usersList: Array<{ uid: string }>;
    groupId: string;
  }) => Promise<void>;

  @chatStore.Action
  private leaveConversation!: (model: {
    uid: string;
  }) => Promise<void>;

  @communityUserChatStore.Action
  private blockCommunityUsers!: (users: CommunityUser[]) => Promise<void>;

  @communityUserChatStore.Action
  private unBlockCommunityUsers!: (users: CommunityUser[]) => Promise<void>;

  @businessCardStore.Action
  private sendContactInformation!: (payload: {userUid: string; exhibitorUid: string}) => Promise<void>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private selectedUserIds: Array<{ uid: string }> = [];

  private querySearch = '';

  private members = this.users;

  private get mapMembers(): ListViewItemParams[] {
    return this.members.map((u) => ({
      entityId: u.uid,
      entityType: EntityType.USER,
      src: u.profilePicture ? u.profilePicture : '',
      title: `${u.firstName} ${u.lastName}`,
      firstName: `${u.firstName}`,
      lastName: `${u.lastName}`,
      subtitle: u.jobTitle,
      // eslint-disable-next-line no-underscore-dangle
      ourConnection: u._ourConnection,
    })) || [];
  }

  private get modalSize(): string {
    // eslint-disable-next-line no-nested-ternary
    return this.isMobile
      ? 'sm'
      : this.isTablet
        ? 'md'
        : 'lg';
  }

  mounted(): void {
    this.updateMembers();
  }

  init(): void {
    this.selectedUserIds = [];
    this.querySearch = '';
    this.members = this.users;
  }

  @Watch('users', { deep: true })
  private updateMembers(): void {
    this.members = this.users;
  }

  private isSelected(uid: string): boolean {
    return this.selectedUserIds.filter((u) => u.uid === uid).length > 0;
  }

  private toggleSelectedUserIds(uid: string): void {
    const findIndex = this.selectedUserIds.findIndex((u) => u.uid === uid);
    if (findIndex < 0) {
      this.selectedUserIds.push({ uid });
    } else {
      this.selectedUserIds.splice(findIndex, 1);
    }
  }

  private onSearch(payload: { query: string }): void {
    this.querySearch = payload.query;
    if (payload.query.length > 2) {
      this.members = this.users
        .filter((u) => u.firstName?.toLowerCase()?.includes(payload.query.toLowerCase())
          || u.lastName?.toLowerCase()?.includes(payload.query.toLowerCase())
          || u.fullName?.toLowerCase()?.includes(payload.query.toLowerCase()));
    } else {
      this.members = this.users;
    }
  }

  private addNewMembers(): void {
    this.addParticipants({
      usersList: this.selectedUserIds,
      groupId: this.groupId,
    });
    this.$emit('close-conversation-action-modal');
  }

  private removeMembers(): void {
    this.leaveConversation({
      uid: this.groupId,
    });
    this.$emit('close-conversation-action-modal');
  }

  private blockMembers(): void {
    this.blockCommunityUsers([CommunityUser.hydrate({ uid: this.users[0].uid as string })])
      .then(() => {
        this.addNewAction({
          type: ToastActionType.BLOCK_USER,
          item: this.users[0].fullName,
        });
        this.$emit('close-conversation-action-modal');
      });
  }

  private unblockMembers(): void {
    this.unBlockCommunityUsers([CommunityUser.hydrate({ uid: this.users[0].uid })])
      .then(() => {
        this.addNewAction({
          type: ToastActionType.UNBLOCK_USER,
          item: this.users[0].fullName,
        });
        this.$emit('close-conversation-action-modal');
      });
  }

  private shareContact(): void {
    this.sendContactInformation({
      userUid: this.users[0].uid,
      exhibitorUid: this.groupId,
    }).then(() => {
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        EntityType.EXHIBITOR,
        StatLoggerActions.SEND,
        'sendBusinessCard',
        -1,
        this.groupId,
        StatLoggerCategories.BUSINESS_CARD,
        this.$i18n.locale,
      );
      this.addNewAction({
        type: ToastActionType.SHARE_CONTACT,
      });
      this.$emit('close-conversation-action-modal');
    });
  }
}
