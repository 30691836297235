import { render, staticRenderFns } from "./RespondPopupComponent.vue?vue&type=template&id=21b5a701&scoped=true&"
import script from "./RespondPopupComponent.vue?vue&type=script&lang=ts&"
export * from "./RespondPopupComponent.vue?vue&type=script&lang=ts&"
import style0 from "../assets/styles/modal/RespondPopupComponent.scss?vue&type=style&index=0&id=21b5a701&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b5a701",
  null
  
)

export default component.exports