var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"base-modal",attrs:{"id":_vm.modalId,"body-class":[
    'body',
    {'auto-height': !_vm.fixedHeight},
    _vm.bodyClass ],"content-class":['content border-0 rounded', {'fixed-height': _vm.fixedHeight}],"footer-class":("footer " + _vm.footerClass),"header-class":("header " + _vm.headerClass),"hide-footer":_vm.footerIsHidden,"hide-header":_vm.headerIsHidden,"modal-class":[
    'standard-modal',
    _vm.size,
    _vm.modalClass,
    { 'modal-content-scroll': _vm.modalContentScroll },
    { 'full-screen': _vm.isFullScreen }],"no-close-on-backdrop":_vm.disableClickBackdrop,"no-fade":_vm.isMobile,"size":_vm.size,"visible":_vm.visible,"centered":"","static":""},on:{"hide":_vm.onModalHide,"show":_vm.onModalShow,"shown":_vm.onModalShown},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_vm._t("header")]},proxy:true},{key:"modal-footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},[_vm._t("body")],2)}
var staticRenderFns = []

export { render, staticRenderFns }