import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import ProductImage from '@/models/graphql/ProductImage';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import ProductImageRepository from '@/repositories/ProductImageRepository';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class ProductImageStore extends LoadableStore<LoadableState> {
  private readonly productImageRepository = new ProductImageRepository();

  protected get repository(): ProductImageRepository {
    return this.productImageRepository;
  }

  @Action
  createProductImagesForProduct(payload: {
    productUid: string;
    images: Array<Record<string, string>>;
  }): Promise<void> {
    if (payload.images.length > 0) {
      const productImageDefinitions = payload.images
        .map((image, index) => ({
          operation: 'ProductImageCreateForProduct',
          gqlDefinition: buildMutationDefinition([
            {
              fieldName: 'product_ProductUid',
              type: GqlEntityInputType.ID,
              value: payload.productUid,
            },
            {
              fieldName: 'entity',
              type: GqlEntityInputType.PRODUCT_IMAGE_INPUT,
              value: {
                fullFileResourceBase64: image.base64Path,
                fullFileResourceFilename: image.fileName,
              },
            },
          ]),
          fragmentName: 'productImageBaseFragment',
          alias: `add${index}`,
        }));
      return this.repository.createProductImagesForProduct({
        definitions: productImageDefinitions,
      }).then(() => Promise.resolve());
    }
    return Promise.resolve();
  }

  @Action
  deleteProductImagesForProduct(images: Array<ProductImage>): Promise<void> {
    if (images.length > 0) {
      const productImageDefinitions = images.map((productImage, index) => ({
        operation: 'ProductImageDelete',
        gqlDefinition: buildMutationDefinition([
          {
            fieldName: 'uid',
            type: GqlEntityInputType.ID,
            value: productImage.uid,
          },
        ]),
        fragmentName: 'productImageBaseFragment',
        alias: `delete${index}`,
      }));
      return this.repository.deleteProductImagesForProduct({
        definitions: productImageDefinitions,
      }).then((deletedProductImages) => Promise.all(Object.values(deletedProductImages)
        .map((productImage) => {
          const promises = [];
          if (productImage.fullFileResource) {
            promises.push(this.context.dispatch(
              'FileResourceStore/deleteFileResource',
              productImage.fullFileResource.uid,
              { root: true },
            ));
          }
          if (productImage.thumbFileResource) {
            promises.push(this.context.dispatch(
              'FileResourceStore/deleteFileResource',
              productImage.thumbFileResource.uid,
              { root: true },
            ));
          }
          return promises;
        }))).then(() => Promise.resolve());
    }
    return Promise.resolve();
  }
}
