

























































































import { Component, Prop } from 'vue-property-decorator';
import Decoration from '@/utils/enums/Decoration';
import Size from '@/utils/enums/Size';
import Variant from '@/utils/enums/Variant';
import VueContextLoading from '@/components/VueContextLoading';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';

@Component({
  components: { AvatarSoloWidget, FontAwesomeComponent },
})
export default class PillComponent extends VueContextLoading {
  @Prop({ required: false, default: '' })
  private readonly text!: string;

  @Prop({ required: false, default: 'lightblue' })
  private readonly variant!: Variant;

  @Prop({ required: false, default: '' })
  private readonly textVariant!: Variant;

  @Prop({ required: false, default: 'sm' })
  private readonly size!: Size;

  @Prop({ required: false, default: null })
  private readonly iconSize!: Size;

  @Prop({ required: false, default: 'none' })
  private readonly decoration!: Decoration;

  @Prop({ required: false, default: 0 })
  private readonly number!: number;

  @Prop({ required: false, default: '' })
  private readonly img!: string;

  @Prop({ required: false, default: '' })
  private readonly iconClass!: string;

  @Prop({ required: false, default: false })
  private readonly clearable!: boolean;

  @Prop({ required: false, default: false })
  private readonly fluid!: boolean;

  @Prop({ required: false, default: true })
  private readonly clickable!: boolean;

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean;

  disabledParam = this.disabled;

  @Prop({ required: false, default: false })
  private readonly nowrap!: boolean;

  private sizeEnum = Size;

  private DecorationEnum = Decoration;
}
