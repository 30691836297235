/* eslint-disable max-len */
import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import CommunityUserConnectionRepository from '@/repositories/CommunityUserConnectionRepository';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import ToastActionType from '@/utils/enums/ToastActionType';
import CommunityUser from '@/models/graphql/CommunityUser';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

interface CommunityUserConnectionState extends LoadableState {
  stateConnections: CommunityUserConnection[];
}

type ConnectionArgs = {
  userId: string;
  linkedUserId: string;
  message: string;
}

type MessageLookupArgs = {
  myUid: string;
  users: CommunityUser[];
  groupType: string;
}

@Module({ namespaced: true })
export default class CommunityUserConnectionStore extends LoadableStore<CommunityUserConnectionState> {
  stateConnections: CommunityUserConnection[] = [];

  private readonly communityUserConnectionRepository = new CommunityUserConnectionRepository();

  protected get repository(): CommunityUserConnectionRepository {
    return this.communityUserConnectionRepository;
  }

  @Mutation
  setElements(connections: CommunityUserConnection[]): void {
    this.stateConnections = connections;
  }

  @Action
  loadConnections(): Promise<CommunityUserConnection[]> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: {
            schemaCode: this.context.rootGetters.communityCode,
            deleted: false,
            linkState: ConnectionStatusType.ACCEPTED,
            user: {
              _isNotBlocked: this.context.rootGetters.authUser.uid,
            },
            connectedUser: {
              _isNotBlocked: this.context.rootGetters.authUser.uid,
            },
            OR: [
              {
                user: {
                  uid: this.context.rootGetters.authUser.uid,
                },
              },
              {
                connectedUser: {
                  uid: this.context.rootGetters.authUser.uid,
                },
              },
            ],
          },
          type: GqlEntityFilterType.COMMUNITY_USER_CONNECTION_FILTER,
        },
      }),
      authUser: this.context.rootGetters.authUser.uid,
    }).then((response) => {
      this.context.commit('setElements', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  loadConnectionsCount(): Promise<number | undefined> {
    return this.repository.count({
      definition: buildQueryDefinition({
        filter: {
          value: {
            schemaCode: this.context.rootGetters.communityCode,
            deleted: false,
            linkState: ConnectionStatusType.ACCEPTED,
            user: {
              _isNotBlocked: this.context.rootGetters.authUser.uid,
            },
            connectedUser: {
              _isNotBlocked: this.context.rootGetters.authUser.uid,
            },
            OR: [
              {
                user: {
                  uid: this.context.rootGetters.authUser.uid,
                },
              },
              {
                connectedUser: {
                  uid: this.context.rootGetters.authUser.uid,
                },
              },
            ],
          },
          type: GqlEntityFilterType.COMMUNITY_USER_CONNECTION_FILTER,
        },
      }),
      authUser: this.context.rootGetters.authUser.uid,
    }).then((response) => response);
  }

  @Action
  disconnect(payload: { uid: string; toastUserFullName: string }): Promise<CommunityUserConnection | undefined> {
    return this.repository.update({
      operationName: 'Disconnect',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_CONNECTION_INPUT,
        value: { uid: payload.uid, linkState: ConnectionStatusType.DISCONNECTED },
      }]),
    }).then((response) => {
      if (payload.toastUserFullName) {
        this.context.dispatch(
          'ToastStore/addNewAction',
          {
            type: ToastActionType.REMOVE_CONNECTION_REQUEST,
            item: payload.toastUserFullName,
            delay: 3500,
          },
          { root: true },
        );
      }
      return response;
    });
  }

  @Action
  acceptConnection(payload: {
    uid: string;
    toastUserFullName: string;
  }): Promise<CommunityUserConnection | undefined> {
    this.context.commit('load', true);
    return this.repository.update({
      operationName: 'AcceptConnection',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_CONNECTION_INPUT,
        value: { uid: payload.uid, linkState: ConnectionStatusType.ACCEPTED },
      }]),
    }).then((connection) => {
      this.context.commit(
        'NotificationStore/updateConnectionNotificationState',
        connection,
        { root: true },
      );
      if (payload.toastUserFullName) {
        this.context.dispatch(
          'ToastStore/addNewAction',
          {
            type: ToastActionType.ACCEPT_CONNECTION_REQUEST,
            item: payload.toastUserFullName,
            delay: 3500,
          }, { root: true },
        );
      }
      return connection;
    }).finally(() => this.context.commit('load', false));
  }

  @Action
  declineConnection(payload: {
    uid: string;
    toastUserFullName: string;
  }): Promise<CommunityUserConnection | undefined> {
    this.context.commit('load', true);
    return this.repository.update({
      operationName: 'DeclineConnection',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_CONNECTION_INPUT,
        value: { uid: payload.uid, linkState: ConnectionStatusType.DECLINED },
      }]),
    }).then((connection) => {
      this.context.commit(
        'NotificationStore/updateConnectionNotificationState',
        connection,
        { root: true },
      );
      if (payload.toastUserFullName) {
        this.context.dispatch(
          'ToastStore/addNewAction',
          {
            type: ToastActionType.DECLINE_CONNECTION_REQUEST,
            item: payload.toastUserFullName,
            delay: 3500,
          },
          { root: true },
        );
      }
      return connection;
    }).finally(() => this.context.commit('load', false));
  }

  @Action
  connect(connectionArgs: ConnectionArgs): Promise<CommunityUserConnection | undefined> {
    return this.repository.create({
      operationName: 'CreateConnection',
      definition: buildMutationDefinition([
        {
          fieldName: 'user_CommunityUserUid',

          type: 'ID',
          value: connectionArgs.userId,
        },
        {
          fieldName: 'connectedUser_CommunityUserUid',
          type: 'ID',
          value: connectionArgs.linkedUserId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMUNITY_USER_CONNECTION_INPUT,
          value: { message: connectionArgs.message, linkState: ConnectionStatusType.INVITED },
        }]),
    }).then((response) => {
      this.context.dispatch(
        'ToastStore/addNewAction',
        {
          type: ToastActionType.SEND_CONNECTION_REQUEST,
          delay: 3500,
        },
        { root: true },
      );
      return response;
    });
  }

  @Action
  fullyAcceptConnection(payload: {
    uid: string;
    messageLookupArgs: MessageLookupArgs;
    toastUserFullName: string;
  }): Promise<CommunityUserConnection | undefined> {
    this.context.commit('load', true);
    return this.repository.update({
      operationName: 'FullyAcceptConnection',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_CONNECTION_INPUT,
        value: { uid: payload.uid, linkState: ConnectionStatusType.ACCEPTED },
      }]),
    }).then((connection) => {
      if (payload.toastUserFullName) {
        this.context.dispatch(
          'ToastStore/addNewAction',
          {
            type: ToastActionType.ACCEPT_CONNECTION_REQUEST,
            item: payload.toastUserFullName,
            delay: 3500,
          },
          { root: true },
        );
      }
      this.context.commit(
        'NotificationStore/updateConnectionNotificationState',
        connection,
        { root: true },
      );
      this.context.dispatch(
        'ChatDispatcherStore/ChatStore/messageGroupLookup',
        payload.messageLookupArgs,
        { root: true },
      ).then((lookup) => {
        if (lookup) {
          this.context.dispatch(
            'ChatDispatcherStore/ChatStore/acceptConversation',
            { uid: lookup },
            { root: true },
          );
        }
      });
      return connection;
    }).finally(() => this.context.commit('load', false));
  }

  @Action
  fullyDeclineConnection(payload: {
    uid: string;
    messageLookupArgs: MessageLookupArgs;
    toastUserFullName: string;
  }): Promise<CommunityUserConnection | undefined> {
    this.context.commit('load', true);
    return this.repository.update({
      operationName: 'FullyDeclineConnection',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_CONNECTION_INPUT,
        value: { uid: payload.uid, linkState: ConnectionStatusType.DECLINED },
      }]),
    }).then((connection) => {
      if (payload.toastUserFullName) {
        this.context.dispatch(
          'ToastStore/addNewAction',
          {
            type: ToastActionType.DECLINE_CONNECTION_REQUEST,
            item: payload.toastUserFullName,
            delay: 3500,
          },
          { root: true },
        );
      }
      this.context.commit(
        'NotificationStore/updateConnectionNotificationState',
        connection,
        { root: true },
      );
      this.context.dispatch(
        'ChatDispatcherStore/ChatStore/messageGroupLookup',
        payload.messageLookupArgs,
        { root: true },
      ).then((lookup) => {
        if (lookup) {
          this.context.dispatch(
            'ChatDispatcherStore/ChatStore/declineConversation',
            { uid: lookup },
            { root: true },
          );
        }
      });
      return connection;
    }).finally(() => this.context.commit('load', false));
  }

  @Action
  fullyConnect(payload: {
    connectionArgs: ConnectionArgs;
    newConversationArgs: object;
  }): Promise<CommunityUserConnection | undefined> {
    this.context.commit('load', true);
    return this.repository.create({
      operationName: 'CreateConnection',
      definition: buildMutationDefinition([
        {
          fieldName: 'user_CommunityUserUid',
          type: 'ID',
          value: payload.connectionArgs.userId,
        },
        {
          fieldName: 'connectedUser_CommunityUserUid',
          type: 'ID',
          value: payload.connectionArgs.linkedUserId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMUNITY_USER_CONNECTION_INPUT,
          value: { message: payload.connectionArgs.message, linkState: ConnectionStatusType.INVITED },
        }]),
    }).then((connection) => {
      this.context.dispatch(
        'ToastStore/addNewAction',
        {
          type: ToastActionType.SEND_CONNECTION_REQUEST,
          delay: 3500,
        },
        { root: true },
      );
      this.context.dispatch(
        'ChatDispatcherStore/ChatStore/createConversation',
        payload.newConversationArgs,
        { root: true },
      );
      this.context.commit(
        'FeedItemWrapperStore/toggleConnection',
        {
          connection,
          linkedUserUid: payload.connectionArgs.linkedUserId,
        },
        { root: true },
      );
      return connection;
    }).finally(() => this.context.commit('load', false));
  }

  @Action
  fullyDisconnect(payload: {
    uid: string;
    messageLookupArgs: MessageLookupArgs;
    toastUserFullName: string;
  }): Promise<CommunityUserConnection | undefined> {
    this.context.commit('load', true);
    return this.repository.update({
      operationName: 'Disconnect',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_CONNECTION_INPUT,
        value: { uid: payload.uid, linkState: ConnectionStatusType.DISCONNECTED },
      }]),
    }).then((connection) => {
      if (payload.toastUserFullName) {
        this.context.dispatch(
          'ToastStore/addNewAction',
          {
            type: ToastActionType.REMOVE_CONNECTION_REQUEST,
            item: payload.toastUserFullName,
            delay: 3500,
          },
          { root: true },
        );
      }
      this.context.commit(
        'FeedItemWrapperStore/toggleConnection',
        {
          connection: { uid: null },
          linkedUserUid: payload.messageLookupArgs.users[1].uid,
        },
        { root: true },
      );
      this.context.dispatch(
        'ChatDispatcherStore/ChatStore/messageGroupLookup',
        payload.messageLookupArgs,
        { root: true },
      ).then((lookup) => {
        if (lookup) {
          this.context.dispatch(
            'ChatDispatcherStore/ChatStore/disconnectConversation',
            { uid: lookup },
            { root: true },
          );
        }
      });

      return connection;
    }).finally(() => this.context.commit('load', false));
  }
}
