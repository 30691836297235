









































































import Event from '@/utils/types/Event';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { format, getUnixTime } from 'date-fns';
import Component from 'vue-class-component';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import { namespace, State } from 'vuex-class';
import ToolboxAgendaDateComponent from '@/components/toolbox/agenda/ToolboxAgendaDateComponent.vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ListFilter from '@/utils/enums/agenda/ListFilter';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import ListItem from '@/components/toolbox/agenda/item/ListItem.vue';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';

const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    LoadingSpinnerComponent,
    IllustrationComponent,
    ToolboxAgendaDateComponent,
    PillComponent,
    ListItem,
  },
})
export default class ListView extends BreakpointWrapper {
  @agendaStore.Getter
  private isLoading!: boolean;

  @agendaStore.Getter
  private fetchListResult!: { [x: string]: Array<Event> };

  @agendaStore.Getter
  private fetchSearchResult!: { [x: string]: Array<Event> };

  @agendaStore.Getter
  private fetchListFilter!: ListFilter;

  @agendaStore.Getter
  private fetchViewMode!: ViewMode;

  @State
  private dateLocale!: Locale;

  private DateTimeHelper = DateTimeHelper;

  private IllustrationType = IllustrationType;

  private scrollToToday = false;

  private ViewMode = ViewMode;

  private getUnixTime = getUnixTime;

  private get isScrolled(): boolean {
    return this.scrollToToday;
  }

  private get isEmpty(): boolean {
    return Object.keys(this.list).length === 0;
  }

  private get nbResults(): number {
    return Object.values(this.fetchSearchResult).reduce((previousValue, currentValue) => previousValue + currentValue.length, 0);
  }

  private get list(): { [x: string]: Array<Event> } {
    return this.fetchViewMode === ViewMode.LIST ? this.fetchListResult : this.fetchSearchResult;
  }

  private checkIfTimeToScroll(): void {
    const el = this.$refs[`${format(
      DateTimeHelper.getCurrentDateTime(),
      this.$t('yyyy-MM-dd') as string, { locale: this.dateLocale },
    )}`] as Element[];
    if (el && el.length > 0) {
      this.scrollToToday = el[0].getBoundingClientRect().top - 90 < 0
        || el[0].getBoundingClientRect().top > ((45 * 16));
    } else {
      this.scrollToToday = false;
    }
  }

  private moveTodayToFirstPlace(): void {
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.$nextTick(() => {
          const todayTimestamp = getUnixTime(new Date(format(
            DateTimeHelper.getCurrentDateTime(),
            this.$t('yyyy-MM-dd') as string,
            { locale: this.dateLocale },
          )));
          const el = document.getElementById(`id-${todayTimestamp}`);
          if (el) {
            el.scrollIntoView({ block: 'start', inline: 'start', behavior: 'auto' });
          }
        });
      });
    });
  }
}
