import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import AccountRepository from '@/repositories/AccountRepository';

@Module({ namespaced: true })
export default class AccountStore extends LoadableStore<LoadableState> {
  private repository = new AccountRepository();

  @Action
  triggerWelcome(payload: {
    communityCode: string;
    userId: string;
  }): void {
    this.context.commit('load', true);
    const { communityCode, userId } = payload;
    this.repository.triggerWelcome(
      communityCode,
      userId,
    ).finally(() => this.context.commit('load', false));
  }

  @Action
  triggerRecovery(payload: {
    communityCode: string;
    email: string;
  }): void {
    this.context.commit('load', true);
    const { communityCode, email } = payload;
    this.repository.triggerRecovery(
      communityCode,
      email,
    ).finally(() => this.context.commit('load', false));
  }

  @Action
  checkActivatability(payload: {
    token: string;
    email: string | null;
    password: string | null;
  }): Promise<object> {
    const { email, password, token } = payload;

    return this.repository.checkActivatability(
      email,
      password,
      token,
    );
  }

  @Action
  activateAccount(token: string): Promise<object> {
    return this.repository.activateAccount(token);
  }
}
