var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('h4',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t('admin-panel.modal.communityuser.account-permissions.title'))+" ")]),(_vm.user.firstName || _vm.user.lastName)?_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-2"},[_c('avatar-solo-widget',{attrs:{"firstname":_vm.user.firstName,"lastname":_vm.user.lastName,"src":_vm.user.pictureFileResource ? _vm.FileResourceHelper
        .getFullPath(_vm.user.pictureFileResource, 'w24') : null,"with-borders":!_vm.user.pictureFileResource,"size":"24"}}),_c('h6',{staticClass:"font-weight-normal mb-0 ml-3"},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")])],1):_vm._e(),_c('div',{staticClass:"d-flex column-gap-6 mt-4",class:[{'flex-column row-gap-6': _vm.isMobile}]},[_c('div',{staticClass:"d-flex flex-column flex-1 align-items-center",class:[
        {'not-selected': _vm.selectedRole !== 'standard'},
        {'selected': _vm.selectedRole === 'standard'},
        {'role-box-lg': !_vm.isMobile},
        {'role-box-sm': _vm.isMobile} ],on:{"click":function($event){return _vm.onRoleChange('standard')}}},[_c('font-awesome-component',{staticClass:"mb-3",attrs:{"icon-classes":("icon-32 " + (_vm.selectedRole === 'standard' ? 'text-blue-b-3-primary-blue' : 'text-neutral-n-6-label')),"container-classes":"icon-32","icon":"fa-regular fa-circle-user"}}),_c('h6',{staticClass:"mb-2",class:[{'text-blue-b-3-primary-blue': _vm.selectedRole === 'standard'}]},[_vm._v(" "+_vm._s(_vm.$t('admin-panel.modal.communityuser.account-permissions.standard.title'))+" ")]),_c('p',{staticClass:"mb-0 medium",class:[
          {'text-neutral-n-8-dark-ink': _vm.selectedRole === 'standard'},
          {'text-neutral-n-6-label': _vm.selectedRole !== 'standard'}
        ]},[_vm._v(" "+_vm._s(_vm.$t('admin-panel.modal.communityuser.account-permissions.standard.description'))+" ")])],1),_c('div',{staticClass:"d-flex flex-column flex-1 align-items-center",class:[
        {'not-selected': _vm.selectedRole !== 'admin'},
        {'selected': _vm.selectedRole === 'admin'},
        {'role-box-lg': !_vm.isMobile},
        {'role-box-sm': _vm.isMobile} ],on:{"click":function($event){return _vm.onRoleChange('admin')}}},[_c('font-awesome-component',{staticClass:"mb-3",attrs:{"icon-classes":("icon-32 " + (_vm.selectedRole === 'admin' ? 'text-blue-b-3-primary-blue' : 'text-neutral-n-6-label')),"container-classes":"icon-32","icon":"fa-regular fa-user-gear"}}),_c('h6',{staticClass:"mb-2",class:[{'text-blue-b-3-primary-blue': _vm.selectedRole === 'admin'}]},[_vm._v(" "+_vm._s(_vm.$t('admin-panel.modal.communityuser.account-permissions.admin.title'))+" ")]),_c('p',{staticClass:"mb-0 medium",class:[
          {'text-neutral-n-8-dark-ink': _vm.selectedRole === 'admin'},
          {'text-neutral-n-6-label': _vm.selectedRole !== 'admin'}
        ]},[_vm._v(" "+_vm._s(_vm.$t('admin-panel.modal.communityuser.account-permissions.admin.description'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }