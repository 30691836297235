import { FetchResult } from '@apollo/client';
import CommunityUser from '@/models/graphql/CommunityUser';
import { buildComposeMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GraphQlClients from '@/utils/enums/GraphQlClients';
import ChatBaseRepository from '@/repositories/chat/ChatBaseRepository';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';

/* eslint-disable max-len */
export default class CommunityUserChatRepository extends ChatBaseRepository<CommunityUser> {
  blockCommunityUsers(params: GqlComposeMutationPayloadParams): Promise<Record<string, boolean>[]> {
    return buildComposeMutationGql({
      operationName: 'BlockCommunityUsers',
      definitions: params.definitions,
    }).then((mutations) => this.use(GraphQlClients.CHAT_CLIENT)
      .mutate<Record<string, boolean>[], {}>(mutations))
      .then((response: FetchResult<Record<string, boolean>[]>) => response.data as Record<string, boolean>[]);
  }

  unBlockCommunityUsers(params: GqlComposeMutationPayloadParams): Promise<Record<string, boolean>[]> {
    return buildComposeMutationGql({
      operationName: 'UnBlockCommunityUsers',
      definitions: params.definitions,
    }).then((mutations) => this.use(GraphQlClients.CHAT_CLIENT)
      .mutate<Record<string, boolean>[], {}>(mutations))
      .then((response: FetchResult<Record<string, boolean>[]>) => response.data as Record<string, boolean>[]);
  }
}
