import { ApolloClient } from '@apollo/client';

export default class GraphQlProvider<TCache> {
  readonly clients: Map<string, ApolloClient<TCache>> = new Map();

  readonly defaultClient: ApolloClient<TCache>;

  protected static provider: object | null = null

  protected constructor(options: GraphQlProviderOptions<TCache>) {
    this.defaultClient = options.defaultClient;

    if (options.clients) {
      this.clients = options.clients;
    }
  }

  static init<TCache>(options: GraphQlProviderOptions<TCache>): GraphQlProvider<TCache> {
    if (!GraphQlProvider.provider) {
      GraphQlProvider.provider = new GraphQlProvider(options);
    }
    return GraphQlProvider.provider as unknown as GraphQlProvider<TCache>;
  }
}

export interface GraphQlProviderOptions<TCache> {
  defaultClient: ApolloClient<TCache>;

  clients?: Map<string, ApolloClient<TCache>>;
}
