import { FetchResult } from '@apollo/client';
import CommunityUserUrl from '@/models/graphql/CommunityUserUrl';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import WriteInterface from '@/repositories/base/WriteInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable max-len */
export default class CommunityUserUrlRepository extends GraphQlRepository<CommunityUserUrl>
  implements WriteInterface<CommunityUserUrl> {
  create(params: GqlPayloadParams): Promise<CommunityUserUrl | undefined> {
    return buildMutationGql({
      operationName: 'CommunityUserUrlCreateForUser',
      operation: 'CommunityUserUrlCreateForUser',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserUrlBaseFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ CommunityUserUrlCreateForUser: CommunityUserUrl }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserUrlCreateForUser: CommunityUserUrl }>) => response.data?.CommunityUserUrlCreateForUser));
  }

  update(params: GqlPayloadParams): Promise<CommunityUserUrl | undefined> {
    return buildMutationGql({
      operationName: 'CommunityUserUrlUpdate',
      operation: 'CommunityUserUrlUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserUrlBaseFragment',
    }).then((mutation) => this.mutate<{ CommunityUserUrlUpdate: CommunityUserUrl }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserUrlUpdate: CommunityUserUrl }>) => response.data?.CommunityUserUrlUpdate));
  }

  delete(params: GqlPayloadParams): Promise<CommunityUserUrl | undefined> {
    return buildMutationGql({
      operationName: 'CommunityUserUrlDelete',
      operation: 'CommunityUserUrlDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserUrlBaseFragment',
    }).then((mutation) => this.mutate<{ CommunityUserUrlDelete: CommunityUserUrl }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserUrlDelete: CommunityUserUrl }>) => response.data?.CommunityUserUrlDelete));
  }
}
