import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { Data } from '@/utils/types/WidgetData';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import EntityType from '@/utils/enums/EntityType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class VideoEmbedWidgetStore extends WidgetBaseStore {
  private dataValues: Data | undefined;

  @Action
  setDataConfigs(): void {
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    let operation: string | null = null;
    let fragmentName: string | null = null;
    const variables = { filter: {} };
    let filterType: string | null = null;

    const {
      entityType,
      entityCode,
    } = JSON.parse(this.widget?.payload || '{}');

    const { useUrl } = JSON.parse(this.widget?.payload || '{}');
    if (entityType && entityCode && useUrl === 'false') {
      switch (entityType.toLowerCase()) {
        case EntityType.LARGE_PRODUCT.toLocaleLowerCase():
          fragmentName = 'largeProductVideoUrlFragment';
          operation = 'largeProduct';
          variables.filter = { uid: entityCode };
          filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
          break;
        case EntityType.PRODUCT.toLocaleLowerCase():
          fragmentName = 'productVideoUrlFragment';
          operation = 'product';
          variables.filter = { uid: entityCode };
          filterType = GqlEntityFilterType.PRODUCT_FILTER;
          break;
        default:
          fragmentName = null;
          operation = null;
          filterType = null;
      }
    }

    if (operation && fragmentName && useUrl === 'false') {
      configs.push({
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: variables.filter,
            type: filterType,
          },
        }),
        fragmentName,
        operation,
        alias: this.widgetStoreName,
      });
      super.setDataConfigs(configs);
    }
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    if (data.values.length === 1) {
      [this.dataValues] = data.values;
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      data: this.dataValues,
    };
  }
}
