









































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import MessageGroup from '@/models/graphql/MessageGroup';
import ChatErrorList from '@/utils/types/chat/ChatErrorList';
import GroupType from '@/utils/enums/chat/GroupType';
import CommunityUser from '@/models/graphql/CommunityUser';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import Message from '@/models/graphql/Message';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import EntityType from '@/utils/enums/EntityType';

@Component({
  methods: { useTestDataAttribute },
  components: {
    ButtonIconComponent,
    FontAwesomeComponent,
  },
})
export default class MessageBoxWriteComponent extends Vue {
  @Prop({ default: 'ChatStore' })
  private readonly storeContext!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly defaultValue!: string;

  @Prop({ default: false })
  private readonly isBlockedByCurrentUser!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly isItInTheaterMode!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly autofocus!: boolean;

  @Prop({ default: false })
  private readonly darkMode!: boolean;

  @Prop({ default: true })
  private readonly isForChat!: boolean;

  @Prop({ default: false })
  private readonly isChatClosed!: boolean;

  @Prop({ default: null })
  private readonly messageToReplyTo!: Message | null;

  @Prop({
    required: false,
    default: '',
  })
  private readonly placeholder!: string;

  @Prop({
    required: false,
    default: 'message-box',
  })
  private readonly dataCy!: string;

  @Getter
  private readonly authUser!: CommunityUser;

  private messageText = '';

  private onFocus = this.autofocus;

  private allowFirstLoadFocus = this.autofocus;

  private startTyping = false;

  private get selectedGroup(): MessageGroup {
    return this.$store.getters[`ChatDispatcherStore/${this.storeContext}/selectedGroup`];
  }

  private get chatErrors(): ChatErrorList {
    return this.$store.getters[`ChatDispatcherStore/${this.storeContext}/chatErrors`];
  }

  private get userMessageName(): string {
    if (this.messageToReplyTo
        && this.messageToReplyTo.user) {
      let name = '';
      if (this.messageToReplyTo.user.firstName) {
        name += this.messageToReplyTo.user.firstName;
      }
      if (this.messageToReplyTo.user.lastName) {
        name += ` ${this.messageToReplyTo.user.lastName.trim()
          .charAt(0)
          .toUpperCase()}.`;
      }
      return name;
    }
    return '';
  }

  private get disable(): boolean {
    return !!(this.chatErrors && this.chatErrors.haveErrors
            && this.chatErrors.findCreateConversationError(this.selectedGroup?.uid))
        || !!(this.selectedGroup
            && this.selectedGroup.groupType
            && [GroupType.DISCONNECTED, GroupType.RECONNECTION].includes(this.selectedGroup.groupType))
        || (this.selectedGroup
            && this.selectedGroup.users !== undefined
            && this.selectedGroup.groupType === GroupType.DIRECT
            && this.selectedGroup.users.length > 0
            && this.authUser.haveBlockedUser(this.selectedGroup.users[0].uid))
        || (this.selectedGroup
            && this.selectedGroup.users !== undefined
            && this.isBlockedByCurrentUser)
        || (this.authUser.banned && this.selectedGroup.target?.__typename === EntityType.SESSION);
  }

  mounted(): void {
    this.updateMessageText();
  }

  @Watch('messageToReplyTo')
  @Watch('defaultValue')
  private updateMessageText(): void {
    this.messageText = this.defaultValue;
    this.$nextTick(() => {
      if (this.authUser) {
        this.addAutoResize();
        if (this.$refs.sendMessage) {
          if (this.allowFirstLoadFocus) {
            (this.$refs.sendMessage as HTMLTextAreaElement).focus({ preventScroll: true });
          } else {
            this.allowFirstLoadFocus = true;
          }
          (this.$refs.sendMessage as HTMLTextAreaElement).dispatchEvent(new Event('input'));
        }
      }
    });
  }

  private signIn(): void {
    const query = this.isItInTheaterMode ? { openTheaterMode: 'true' } : {};
    this.$router.push({
      name: 'login',
      query,
    });
  }

  @Watch('onFocus')
  private typing(): void {
    if (!this.isForChat) {
      this.$emit('on-change', this.messageText);
    } else if (this.messageText.length > 0) {
      if (this.onFocus) {
        if (!this.startTyping) {
          this.startTyping = true;
          this.$emit('typing-start', this.messageText);
        }
      } else {
        this.startTyping = false;
        this.$emit('typing-end');
      }
    } else if (!this.onFocus) {
      this.startTyping = false;
      this.$emit('typing-end');
    }
  }

  private emit(): void {
    if (this.authUser) {
      const message = this.messageText;
      if ((message.trim().length > 0) || this.messageToReplyTo) {
        this.$emit('typing-end');
        this.$emit('send-message', message);
        this.messageText = '';
        this.startTyping = false;
      }
      (this.$refs.sendMessage as HTMLTextAreaElement).focus({ preventScroll: true });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private addAutoResize(): void {
    document.querySelectorAll<HTMLElement>('[data-autoresize]')
      .forEach((element) => {
        element.style.boxSizing = 'border-box';
        const offset = element.offsetHeight - element.clientHeight;
        element.addEventListener('input', (event) => {
          if (event && event.target) {
            (event.target as HTMLElement).style.height = 'auto';
            (event.target as HTMLElement).style.height = `${(event.target as HTMLElement).scrollHeight + offset}px`;
          }
        });
        element.removeAttribute('data-autoresize');
      });
  }
}
