import { ApolloQueryResult, FetchResult } from '@apollo/client';
import BaseModel from '@/models/BaseModel';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import {
  buildComposeMutationGql,
  buildMutationGql,
  buildQueryGql,
} from '@/graphql/_Tools/GqlGeneric';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import ExhibitorTag from '@/models/graphql/ExhibitorTag';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';

/* eslint-disable max-len */
export default class ExhibitorTagRepository extends GraphQlRepository<BaseModel> {
  filter(params: GqlPayloadParams): Promise<ExhibitorTag[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterExhibitorTag',
      operation: 'exhibitorTag',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'exhibitorTagBaseFragment',
      authUser: params.authUser,
    })
      .then((query) => this.query<{
        exhibitorTag: Array<ExhibitorTag>;
      }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{
          exhibitorTag: Array<ExhibitorTag>;
        }>) => response.data.exhibitorTag));
  }

  create(params: GqlPayloadParams): Promise<ExhibitorTag | null> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateTagForExhibitor',
      operation: 'ExhibitorTagCreateForExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorTagBaseFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        ExhibitorTagCreateForExhibitor: ExhibitorTag;
      }>(mutation, params.definition.variables))
      .then((response: FetchResult<{
        ExhibitorTagCreateForExhibitor: ExhibitorTag;
      }>) => (response.data ? response.data.ExhibitorTagCreateForExhibitor : null));
  }

  delete(params: GqlPayloadParams): Promise<ExhibitorTag | null> {
    return buildMutationGql({
      operationName: params.operationName || 'DeleteExhibitorTag',
      operation: 'ExhibitorTagDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorTagBaseFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        ExhibitorTagDelete: ExhibitorTag;
      }>(mutation, params.definition.variables))
      .then((response: FetchResult<{
        ExhibitorTagDelete: ExhibitorTag;
      }>) => (response.data ? response.data.ExhibitorTagDelete : null));
  }

  update(params: GqlPayloadParams): Promise<ExhibitorTag | null> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdateExhibitorTag',
      operation: 'ExhibitorTagUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorTagBaseFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        ExhibitorTagUpdate: ExhibitorTag;
      }>(mutation, params.definition.variables))
      .then((response: FetchResult<{
        ExhibitorTagUpdate: ExhibitorTag;
      }>) => (response.data ? response.data.ExhibitorTagUpdate : null));
  }

  addTargetLink(params: GqlPayloadParams): Promise<ExhibitorTag | null> {
    return buildMutationGql({
      operationName: params.operationName || 'AddTargetLinkForExhibitorTag',
      operation: 'ExhibitorTagAddLinkTarget',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorTagBaseFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        ExhibitorTagAddLinkTarget: ExhibitorTag;
      }>(mutation, params.definition.variables))
      .then((response: FetchResult<{
        ExhibitorTagAddLinkTarget: ExhibitorTag;
      }>) => (response.data ? response.data.ExhibitorTagAddLinkTarget : null));
  }

  removeTargetLink(params: GqlPayloadParams): Promise<ExhibitorTag | null> {
    return buildMutationGql({
      operationName: params.operationName || 'RemoveTargetLinkForExhibitorTag',
      operation: 'ExhibitorTagRemoveLinkTarget',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorTagBaseFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        ExhibitorTagRemoveLinkTarget: ExhibitorTag;
      }>(mutation, params.definition.variables))
      .then((response: FetchResult<{
        ExhibitorTagRemoveLinkTarget: ExhibitorTag;
      }>) => (response.data ? response.data.ExhibitorTagRemoveLinkTarget : null));
  }

  manageExhibitorTagsLinksOnBatch(params: GqlComposeMutationPayloadParams): Promise<Record<string, ExhibitorTag>[]> {
    return buildComposeMutationGql({
      operationName: params.operationName || 'RunOnBatch',
      definitions: params.definitions,
      authUser: params.authUser,
    })
      .then((mutations) => this.mutate<Record<string, ExhibitorTag>[], {}>(mutations))
      .then((response: FetchResult<Record<string, ExhibitorTag>[]>) => response.data as Record<string, ExhibitorTag>[]);
  }
}
