
























































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import InputText from '@/components/InputText.vue';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';

@Component({
  data(): object {
    return {
      firstName: '',
      lastName: '',
    };
  },
  components: { InputText, AvatarSoloWidget },
  mixins: [validationMixin],
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(1),
      },
      lastName: {
        required,
        minLength: minLength(1),
      },
    },
  },
})
export default class MemberInviteNoAccountFound extends Vue {
  @Getter
  private community!: Community;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: true, default: '' })
  private readonly email!: string;

  private firstTimeUpdateFirstName = false;

  private firstTimeUpdateLastName = false;

  private firstNameFieldIsDirty = false;

  private lastNameFieldIsDirty = false;

  private validateState(field: string): boolean {
    switch (field) {
      case 'firstName':
        if (this.firstTimeUpdateFirstName) {
          const $dirty = this.$v.form.firstName?.$dirty;
          const $error = this.$v.form.firstName?.$error;
          return $dirty
            ? !$error
            : true;
        }
        return true;
      case 'lastName':
        if (this.firstTimeUpdateLastName) {
          const $dirty = this.$v.form.lastName?.$dirty;
          const $error = this.$v.form.lastName?.$error;
          return $dirty
            ? !$error
            : true;
        }
        return true;
      default:
        return true;
    }
  }

  private onBlur(field: string): void {
    switch (field) {
      case 'firstName':
        this.firstTimeUpdateFirstName = true;
        if (this.$data.firstName !== '') {
          const $error = this.$v.form.firstName?.$error;
          const $dirty = this.$v.form.firstName?.$dirty;
          if ($error) {
            this.firstNameFieldIsDirty = !!$dirty;
          }
        } else {
          this.firstNameFieldIsDirty = true;
        }
        break;
      case 'lastName':
        this.firstTimeUpdateLastName = true;
        if (this.$data.lastName !== '') {
          const $error = this.$v.form.lastName?.$error;
          const $dirty = this.$v.form.lastName?.$dirty;
          if ($error) {
            this.lastNameFieldIsDirty = !!$dirty;
          }
        } else {
          this.lastNameFieldIsDirty = true;
        }
        break;
      default:
        break;
    }
  }

  @Watch('$data.firstName')
  @Watch('$data.lastName')
  private onFieldChange(): void {
    this.$emit('field-change', {
      firstName: this.$data.firstName,
      lastName: this.$data.lastName,
    });
  }
}
