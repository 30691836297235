export enum ToolbarMenuIcons {
  TOOLBAR_DASHBOARD = 'fa-chart-mixed',
  TOOLBAR_AGENDA = 'fa-calendar-clock',
  TOOLBAR_NOTES = 'fa-pen-swirl',
  TOOLBAR_TASK = 'icon-tasks',
  TOOLBAR_PROJECT = 'icon-folder',
  TOOLBAR_CONNECTION = 'icon-team',
  TOOLBAR_CHAT = 'fa-comment-dots',
  TOOLBAR_NETWORK = 'fa-users',
  TOOLBAR_TAGS = 'fa-tags',
}
