
































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import GUUID from '@/utils/GUUID';
import AddToCalendarOptions from '@/utils/enums/AddToCalendarOptions';
import {
  CalendarEvent, google, ics, office365, outlook, yahoo,
} from 'calendar-link';

@Component({
  components: { FontAwesomeComponent, ButtonComponent },
})
export default class ExportToCalendarComponent extends Vue {
  @Prop({ required: true })
  private readonly calendarData!: CalendarEvent;

  @Prop({ required: true })
  private readonly btnText!: string;

  @Prop({ required: true })
  private readonly btnVariant!: string;

  private addToCalendarOptions = AddToCalendarOptions;

  private toggleAddToCalendar = false;

  private target = GUUID.uuidv4();

  private exportTo(option: AddToCalendarOptions): string {
    switch (option) {
      case AddToCalendarOptions.APPLE:
        return ics(this.calendarData);
      case AddToCalendarOptions.GOOGLE:
        return google(this.calendarData);
      case AddToCalendarOptions.OUTLOOK:
        return outlook(this.calendarData);
      case AddToCalendarOptions.OFFICE365:
        return office365(this.calendarData);
      case AddToCalendarOptions.YAHOO:
        return yahoo(this.calendarData);
      default:
        return '';
    }
  }
}
