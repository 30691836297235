/* eslint-disable max-len */
import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CommunityUserProfileRole from '@/models/graphql/CommunityUserProfileRole';
import CommunityUserProfileRoleRepository from '@/repositories/CommunityUserProfileRoleRepository';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import LoadableStore from '@/store/LoadableStore';

@Module({ namespaced: true })
export default class CommunityUserProfileRoleStore extends LoadableStore<LoadableState> {
  private readonly communityUserProfileRoleRepository = new CommunityUserProfileRoleRepository();

  protected get repository(): CommunityUserProfileRoleRepository {
    return this.communityUserProfileRoleRepository;
  }

  @Action
  createUserProfileRole(communityUserProfileRole: CommunityUserProfileRole): Promise<CommunityUserProfileRole | undefined> {
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          type: GqlEntityInputType.ID,
          fieldName: 'user_CommunityUserUid',
          value: communityUserProfileRole.user?.uid,
        },
        {
          type: GqlEntityInputType.ID,
          fieldName: 'profileRole_ProfileRoleUid',
          value: communityUserProfileRole.profileRole?.uid,
        },
        {
          type: GqlEntityInputType.COMMUNITY_USER_PROFILE_ROLE_INPUT,
          fieldName: 'entity',
          value: { relation: communityUserProfileRole.relation },
        },
      ]),
    });
  }

  @Action
  deleteUserProfileRole(uid: string): Promise<CommunityUserProfileRole | undefined> {
    return this.repository.delete({
      definition: buildMutationDefinition([
        {
          type: GqlEntityInputType.ID,
          fieldName: 'uid',
          value: uid,
        },
      ]),
    });
  }
}
