



























import { Component, Prop, Vue } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class RatingStarComponent extends Vue {
  @Prop({ required: false, default: 0 })
  private readonly value!: number;

  private localValue = 0;

  mounted(): void {
    this.localValue = this.value;
  }
}
