// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getContext() {
  return require.context(
    '../../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );
}

export default getContext;
