/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import FeedItemWrapper from '@/models/graphql/FeedItemWrapper';
import FeedItemWrapperRepository from '@/repositories/feeds/FeedItemWrapperRepository';
import FeedType from '@/utils/enums/feed/FeedType';
import { differenceInCalendarDays, fromUnixTime } from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import CommunityUser from '@/models/graphql/CommunityUser';
import FeedItem from '@/models/graphql/FeedItem';
import EntityType from '@/utils/enums/EntityType';
import Exhibitor from '@/models/graphql/Exhibitor';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import FeedPost from '@/models/graphql/FeedPost';
import FeedAction from '@/utils/enums/feed/FeedAction';
import SubEdition from '@/models/graphql/SubEdition';
import FeedPostMedia from '@/models/graphql/FeedPostMedia';
import FeedPostLink from '@/models/graphql/FeedPostLink';
import FeedItemHelper from '@/utils/helpers/FeedItemHelper';
import Channel from '@/models/graphql/Channel';
import FeedPostReportInput from '@/models/graphql/FeedPostReportInput';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import Topic from '@/models/graphql/Topic';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import LoadableStore from '@/store/LoadableStore';
import FeedPostRepository from '@/repositories/FeedPostRepository';
import FeedPostReportRepository from '@/repositories/feeds/FeedPostReportRepository';
import FeedPostLinkRepository from '@/repositories/feeds/FeedPostLinkRepository';
import FeedPostMediaRepository from '@/repositories/feeds/FeedPostMediaRepository';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import TopicRepository from '@/repositories/TopicRepository';

@Module({ namespaced: true })
export default class FeedItemWrapperStore extends LoadableStore<LoadableState> {
  private currentCategoryIndex = 0;

  private categories: {
    name: string;
    condition: (item: FeedItemWrapper) => boolean;
    items: FeedItemWrapper[];
  }[] = [
    {
      name: '',
      condition: function condition(item: FeedItemWrapper) {
        return !!(item.pinnedInChannel || item.pinnedInEntity || item.pinnedInGlobal || item.pinnedInTopic);
      },
      items: [],
    },
    {
      name: 'today',
      condition: function condition(item: FeedItemWrapper) {
        return differenceInCalendarDays(DateTimeHelper.getCurrentDateTime(), fromUnixTime(item.updatedTimestamp as number)) === 0;
      },
      items: [],
    },
    {
      name: 'yesterday',
      condition: function condition(item: FeedItemWrapper) {
        return differenceInCalendarDays(DateTimeHelper.getCurrentDateTime(), fromUnixTime(item.updatedTimestamp as number)) < 2;
      },
      items: [],
    },
    {
      name: 'this-week',
      condition: function condition(item: FeedItemWrapper) {
        return differenceInCalendarDays(DateTimeHelper.getCurrentDateTime(), fromUnixTime(item.updatedTimestamp as number)) < 7;
      },
      items: [],
    },
    {
      name: 'earlier',
      condition: function condition(item: FeedItemWrapper) {
        return differenceInCalendarDays(DateTimeHelper.getCurrentDateTime(), fromUnixTime(item.updatedTimestamp as number)) >= 7;
      },
      items: [],
    },
  ]

  private topicsList: {
    uid: string;
    value: string;
    text: string;
  }[]=[];

  private feedInitiator: CommunityUser | Exhibitor | SubEdition | Channel | null = null;

  private page = 0;

  private itemsPerPage = 8;

  private entityUid = '';

  private lastFeedType = '';

  private topicPublicPosting = true;

  private readonly feedItemWrapperRepository = new FeedItemWrapperRepository();

  private readonly feedPostRepository = new FeedPostRepository();

  private readonly feedPostReportRepository = new FeedPostReportRepository();

  private readonly feedPostLinkRepository = new FeedPostLinkRepository();

  private readonly feedPostMediaRepository = new FeedPostMediaRepository();

  private readonly topicRepository = new TopicRepository();

  get fetchLastFeedType(): string {
    return this.lastFeedType;
  }

  get fetchFeedInitiator(): CommunityUser | Exhibitor | SubEdition | Channel | null {
    return this.feedInitiator;
  }

  get fetchCategories(): {
    name: string;
    condition: (item: FeedItemWrapper) => boolean;
    items: FeedItemWrapper[];
  }[] {
    let alreadyMarked = false;
    const currentFeedState = this.context.rootState?.authUser?.feedStates?.find((e) => e.feedEntity?.uid === this.entityUid);
    this.categories.forEach((element) => {
      element.items.forEach((subElement) => {
        if (!alreadyMarked && element.name.length > 0) {
          if (this.entityUid === this.context.rootState.authUser?.uid) {
            if (subElement.showUpToDate || (Number(subElement.createdTimestamp) <= Number(this.context.rootState?.authUser?.lastViewedFeeds))) {
              subElement.showUpToDate = true;
              alreadyMarked = true;
            }
          } else if (currentFeedState) {
            if (subElement.showUpToDate || (Number(subElement.createdTimestamp) <= Number(currentFeedState.lastViewedFeed))) {
              subElement.showUpToDate = true;
              alreadyMarked = true;
            }
          }
        } else {
          subElement.showUpToDate = false;
        }
      });
    });
    return this.categories;
  }

  get fetchFeedInitiatorRepostList(): {
    uid: string;
    value: string;
    text: string;
  }[] {
    return this.topicsList;
  }

  get pageNumber(): number {
    return this.page;
  }

  get entityUidFeed(): string {
    return this.entityUid;
  }

  get isTopicPublicPosting(): boolean {
    return this.topicPublicPosting;
  }

  protected get repository(): FeedItemWrapperRepository {
    return this.feedItemWrapperRepository;
  }

  @Mutation
  initialize(): void {
    this.currentCategoryIndex = 0;
    this.page = 0;
    this.categories.forEach((category) => {
      category.items = [];
    });
  }

  @Mutation
  setFeedType(feedType: string): void {
    this.lastFeedType = feedType;
  }

  @Mutation
  setFeedInitiator(initiator: CommunityUser | Exhibitor | SubEdition | Channel): void {
    this.feedInitiator = initiator;
  }

  @Mutation
  setPage(page: number): void {
    this.page = page;
  }

  @Mutation
  setEntityUid(entityUid: string): void {
    this.entityUid = entityUid;
  }

  @Mutation
  setIsTopicPosting(organizerOnlyPosting: boolean): void {
    this.topicPublicPosting = !organizerOnlyPosting;
  }

  @Mutation
  setTopicsListForAuthUser(payload: { topics: Topic[]; entityUid: string}): void {
    payload.topics.forEach((topic) => {
      if (payload.entityUid !== topic.uid) {
        this.topicsList.push({
          text: (topic.name) ? topic.name : '',
          uid: topic.uid,
          value: (topic.name) ? topic.name : '',
        });
      }
    });
  }

  @Action
  async createPost({
    initiator, authUser, entity, links, medias, topicUid, channelUid,
  }: {
    initiator: CommunityUser | Exhibitor | SubEdition | Channel;
    authUser: CommunityUser;
    entity: Partial<FeedPost>;
    links: Partial<FeedPostLink>[];
    medias: Partial<FeedPostMedia>[];
    topicUid?: string | null;
    channelUid?: string | null;
  }): Promise<{ wrapperUid: string; postUid: string } | null> {
    const wrapper: FeedItemWrapper = {
      uid: DateTimeHelper.currentTimestamp.toString(),
      initiator: undefined,
      aggregateKey: FeedAction.FEEDPOST_CREATED,
      _feedItems: [{
        uid: Date.now().toString(),
        action: FeedAction.FEEDPOST_CREATED,
        triggered: undefined,
      }],
      createdTimestamp: DateTimeHelper.currentTimestamp,
      updatedTimestamp: DateTimeHelper.currentTimestamp,
      showUpToDate: true,
    };
    let definitionVariables = [
      {
        fieldName: 'initiator_NodeUid',
        type: GqlEntityInputType.ID,
        value: initiator.uid,
      },
      {
        fieldName: 'authorUser_CommunityUserUid',
        type: GqlEntityInputType.ID,
        value: authUser.uid,
      },
      {
        fieldName: 'entity',
        type: GqlEntityInputType.FEED_POST_INPUT,
        value: entity,
      },
    ];
    definitionVariables = topicUid ? [
      ...definitionVariables,
      {
        fieldName: 'topic_TopicUid',
        type: GqlEntityInputType.ID,
        value: topicUid,
      }]
      : definitionVariables;
    definitionVariables = channelUid ? [
      ...definitionVariables,
      {
        fieldName: 'channel_ChannelUid',
        type: GqlEntityInputType.ID,
        value: channelUid,
      }]
      : definitionVariables;
    // eslint-disable-next-line no-nested-ternary
    const operationNameString = topicUid ? 'createPostForTopic' : channelUid ? 'createPostForChannel' : 'createPost';
    const post = await this.feedPostRepository.create({
      operationName: operationNameString,
      definition: buildMutationDefinition(definitionVariables),
    });
    if (post !== undefined) {
      if (medias && medias.length) {
        const fetchedMedias = Promise.all(medias.map((media) => this.feedPostMediaRepository.create({
          definition: buildMutationDefinition([
            {
              fieldName: 'post_FeedPostUid',
              type: GqlEntityInputType.ID,
              value: post?.uid,
            },
            {
              fieldName: 'entity',
              type: GqlEntityInputType.FEED_POST_MEDIA_INPUT,
              value: media,
            },
          ]),
        })));
        const res = await fetchedMedias;
        post.medias = res as FeedPostMedia[];
        wrapper.initiator = initiator;
        if (wrapper._feedItems) {
          wrapper.initiator = post.initiator;
          wrapper._feedItems[0].triggered = post;
        }
        this.context.commit('addPost', { wrapper, categoryName: 'today' });
        return { postUid: post.uid, wrapperUid: wrapper.uid };
      } if (links && links.length) {
        const fetchedLink = Promise.all(links.map((link) => this.feedPostLinkRepository.create({
          definition: buildMutationDefinition([
            {
              fieldName: 'post_FeedPostUid',
              type: GqlEntityInputType.ID,
              value: post?.uid,
            },
            {
              fieldName: 'entity',
              type: GqlEntityInputType.FEED_POST_LINK_INPUT,
              value: link,
            },
          ]),
        })));
        const res = await fetchedLink;
        post.links = res as FeedPostLink[];
        wrapper.initiator = initiator;
        if (wrapper._feedItems) {
          wrapper.initiator = post.initiator;
          wrapper._feedItems[0].triggered = post;
        }
        this.context.commit('addPost', { wrapper, categoryName: 'today' });
        return { postUid: post.uid, wrapperUid: wrapper.uid };
      }
      const feedItemWrapper = await this.feedItemWrapperRepository.get(
        {
          definition: buildQueryDefinition({
            filter: {
              type: GqlEntityFilterType.FEED_ITEM_WRAPPER_FILTER,
              value: {
                feedItems: {
                  triggered: {
                    uid: post.uid,
                  },
                },
              },
            },
          }),
        },
      );
      if (feedItemWrapper) {
        wrapper.uid = feedItemWrapper.uid;
      }
      wrapper.initiator = initiator;
      if (wrapper._feedItems) {
        wrapper.initiator = post.initiator;
        wrapper._feedItems[0].triggered = post;
      }

      this.context.commit('addPost', { wrapper, categoryName: 'today' });
      return { postUid: post.uid, wrapperUid: wrapper.uid };
    }
    return null;
  }

  @Action
  deleteMediaPost(uid: string): void {
    this.feedPostMediaRepository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: uid,
        },
      ]),
    });
  }

  @Action
  likePost(payload: Record<string, string>): Promise<FeedPost | undefined> {
    if ('uid' in payload && 'likeUser_CommunityUserUid' in payload) {
      // eslint-disable-next-line @typescript-eslint/camelcase,@typescript-eslint/naming-convention
      const { uid, likeUser_CommunityUserUid } = payload;
      return this.feedPostRepository.update({
        operationName: 'LikeFeedPost',
        definition: buildMutationDefinition([
          {
            fieldName: 'uid',
            type: GqlEntityInputType.ID,
            value: uid,
          },
          {
            fieldName: 'likeUser_CommunityUserUid',
            type: GqlEntityInputType.ID,
            // eslint-disable-next-line @typescript-eslint/camelcase
            value: likeUser_CommunityUserUid,
          },
        ]),
      });
    }
    return Promise.resolve(undefined);
  }

  @Action
  dislikePost(payload: Record<string, string>): Promise<FeedPost | undefined> {
    if ('uid' in payload && 'likeUser_CommunityUserUid' in payload) {
      // eslint-disable-next-line @typescript-eslint/camelcase,@typescript-eslint/naming-convention
      const { uid, likeUser_CommunityUserUid } = payload;
      return this.feedPostRepository.update({
        operationName: 'DislikeFeedPost',
        definition: buildMutationDefinition([
          {
            fieldName: 'uid',
            type: GqlEntityInputType.ID,
            value: uid,
          },
          {
            fieldName: 'likeUser_CommunityUserUid',
            type: GqlEntityInputType.ID,
            // eslint-disable-next-line @typescript-eslint/camelcase
            value: likeUser_CommunityUserUid,
          },
        ]),
      });
    }
    return Promise.resolve(undefined);
  }

  @Action
  attachMediaToPost({ feedPostUid, media }: { feedPostUid: string; media: FeedPostMedia }): void {
    this.feedPostMediaRepository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'post_FeedPostUid',
          type: GqlEntityInputType.ID,
          value: feedPostUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FEED_POST_MEDIA_INPUT,
          value: media,
        },
      ]),
    });
  }

  @Action
  attachLinkToPost({ feedPostUid, link }: { feedPostUid: string; link: FeedPostLink }): void {
    this.feedPostLinkRepository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'post_FeedPostUid',
          type: GqlEntityInputType.ID,
          value: feedPostUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FEED_POST_LINK_INPUT,
          value: link,
        },
      ]),
    });
  }

  @Action
  deleteLinkPost(uid: string): void {
    this.feedPostLinkRepository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: uid,
        },
      ]),
    });
  }

  @Action
  updateFeedItemWrapper({
    authUserId,
    attributes,
  }: { authUserId: string; attributes: Partial<FeedItemWrapper> }): Promise<FeedItemWrapper | undefined> {
    return this.repository.update({
      authUser: authUserId,
      definition: buildMutationDefinition([
        {
          type: GqlEntityInputType.FEED_ITEM_WRAPPER_INPUT,
          fieldName: 'entity',
          value: attributes,
        },
      ]),
    });
  }

  @Action
  updatePost({
    feedItemWrapper,
    entity,
    oldEntity,
    medias,
    links,
  }: { feedItemWrapper: FeedItemWrapper; entity: Partial<FeedPost>; medias: FeedPostMedia[]; links: FeedPostLink[]; oldEntity: FeedPost }): Promise<void> {
    return this.feedPostRepository.update({
      operationName: 'updatePost',
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FEED_POST_INPUT,
          value: entity,
        },
      ]),
    }).then((post) => {
      if (feedItemWrapper && feedItemWrapper._feedItems && feedItemWrapper._feedItems.length !== 0 && feedItemWrapper._feedItems[0].triggered && entity && post !== undefined) {
        const feedOperationRepository = new FeedItemHelper(this.feedItemWrapperRepository);
        return feedOperationRepository
          .updatePostResources({
            post, entity, medias, links, oldEntity,
          })
          .then((mediasAndLinks) => {
            if (!mediasAndLinks) {
              return;
            }
            post.medias = mediasAndLinks.medias;
            post.links = mediasAndLinks.links;
            if (feedItemWrapper && feedItemWrapper._feedItems && feedItemWrapper?._feedItems[0].triggered) {
              feedItemWrapper._feedItems[0].triggered = post;
              this.context.commit('replacePost', feedItemWrapper);
            }
          });
      }
      return Promise.resolve();
    });
  }

  @Mutation
  addPost({ wrapper, categoryName }: { wrapper: FeedItemWrapper; categoryName: string }): void {
    this.categories.every((category) => {
      if (category.name === categoryName) {
        category.items.unshift(wrapper);
        return false;
      }
      return true;
    });
  }

  @Mutation
  unpinPost(wrapper: FeedItemWrapper): void {
    this.categories[1].items.unshift(wrapper);
  }

  @Mutation
  pinPost(wrapper: FeedItemWrapper): void {
    this.categories[0].items.unshift(wrapper);
  }

  @Mutation
  setElement(feedItemWrapper: FeedItemWrapper[]): void {
    feedItemWrapper.forEach((wrapper: FeedItemWrapper) => {
      if (!this.categories[this.currentCategoryIndex].condition(wrapper)) {
        this.currentCategoryIndex += 1;
        while (!this.categories[this.currentCategoryIndex].condition(wrapper)) {
          this.currentCategoryIndex += 1;
        }
      }
      const map = new Map();
      if (wrapper._feedItems) {
        wrapper._feedItems.forEach((item) => {
          let uid;
          if (item.triggered?.__typename === EntityType.BOOKMARK) {
            uid = (item.triggered as CommunityUserBookmark).link?.target?.uid;
          } else if (item.triggered?.__typename === EntityType.CONNECTION) {
            if (item.action === FeedAction.USER_CONNECTIONS_UPDATED_FROM) {
              uid = (item.triggered as CommunityUserConnection).connectedUser?.uid;
            } else if (item.action === FeedAction.USER_CONNECTIONS_UPDATED_TO) {
              uid = (item.triggered as CommunityUserConnection).user?.uid;
            } else {
              uid = (item.triggered as CommunityUserConnection).connectedUser?.uid;
            }
          } else if (item.triggered) {
            uid = item.triggered.uid;
          }

          if (uid && !map.has(uid)) {
            map.set(uid, item);
          }
        });
      }
      wrapper._feedItems = Array.from(map.values());
      const canNotPush = !!this.categories[this.currentCategoryIndex].items.find((item) => item.uid === wrapper.uid);
      if (!canNotPush) {
        this.categories[this.currentCategoryIndex].items.push(wrapper);
      }
      this.currentCategoryIndex = 0;
      map.clear();
    });
  }

  @Action
  loadFeeds(payload: {
    uid: string;
    authUserId: string;
    type: string;
    first: number;
    bookmarks: boolean;
  }): Promise<number | null> {
    this.context.commit('load', true);
    this.context.commit('setFeedType', payload.type);
    if (payload.type === FeedType.GLOBAL) {
      this.context.commit('setIsTopicPosting', false);
      return this.feedItemWrapperRepository.filter({
        operationName: 'getWallFeeds',
        params: {
          skip: this.itemsPerPage * this.page,
          limit: this.itemsPerPage,
          bookmarksOnly: payload.bookmarks,
        },
        authUser: payload.uid,
        definition: buildQueryDefinition(
          {
            filter: {
              value: {
                uid: payload.uid,
              },
              type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
            },
          },
        ),
      }).then((response: FeedItemWrapper[] | undefined) => {
        if (this.entityUid !== payload.uid) {
          this.context.commit('setEntityUid', payload.uid);
        }
        if (response) {
          if (this.page === 0) {
            if (response.length !== 0) {
              this.context.commit('setPage', 1);
            }
          } else {
            this.context.commit('setPage', this.page + 1);
          }
          this.context.commit('setElement', response);
          this.context.commit('load', false);
          return response.length;
        }
        return 0;
      }).catch(() => new Promise((resolve) => resolve(null)));
    }

    let queryResult!: Promise<FeedItemWrapper[]>;

    if (payload.type === FeedType.TOPIC) {
      queryResult = this.feedItemWrapperRepository.filter({
        operationName: 'feedsOfTopic',
        params: {
          first: this.itemsPerPage,
          offset: this.itemsPerPage * this.page,
          bookmarksOnly: payload.bookmarks,
          myUid: payload.authUserId,
        },
        authUser: payload.authUserId,
        definition: buildQueryDefinition(
          {
            filter: {
              value: {
                uid: payload.uid,
              },
              type: GqlEntityFilterType.TOPIC_FILTER,
            },
          },
        ),
      });
      this.topicRepository.get(
        {
          definition: buildQueryDefinition({
            filter: {
              type: GqlEntityFilterType.TOPIC_FILTER,
              value: {
                uid: payload.uid,
              },
            },
          }),
        },
      ).then((topic: Topic | undefined) => {
        if (topic) { this.context.commit('setIsTopicPosting', topic.organizerOnlyPosting); }
      });
    } else if (payload.type === FeedType.CHANNEL) {
      queryResult = this.feedItemWrapperRepository.filter({
        operationName: 'feedOfChannel',
        params: {
          first: this.itemsPerPage,
          offset: this.itemsPerPage * this.page,
          bookmarksOnly: payload.bookmarks,
          myUid: payload.authUserId,
        },
        authUser: payload.authUserId,
        definition: buildQueryDefinition(
          {
            filter: {
              value: {
                uid: payload.uid,
              },
              type: GqlEntityFilterType.CHANNEL_FILTER,
            },
          },
        ),
      });
    } else {
      queryResult = this.feedItemWrapperRepository.filter({
        operationName: 'feedsOfEntity',
        authUser: payload.authUserId,
        definition: buildQueryDefinition({
          filter: {
            value: {
              skip: this.itemsPerPage * this.page,
              limit: this.itemsPerPage,
              initiatorUid: this.feedInitiator
                && this.feedInitiator.uid
                && payload.type === FeedType.SUB_EDITION
                ? this.feedInitiator.uid
                : payload.uid,
              bookmarksOnly: payload.bookmarks,
              myUid: payload.authUserId,
            },
            type: GqlEntityFilterType.FEED_ITEM_WRAPPER_FILTER,
          },
        }),
      });
      this.context.commit('setIsTopicPosting', false);
    }
    return queryResult.then((response: FeedItemWrapper[]) => {
      if (this.entityUid !== payload.uid) {
        this.context.commit('setEntityUid', payload.uid);
      }
      if (this.page === 0) {
        if (response.length !== 0) {
          this.context.commit('setPage', 1);
        }
      } else {
        this.context.commit('setPage', this.page + 1);
      }
      this.context.commit('setElement', response);
      this.context.commit('load', false);
      return response.length;
    }).catch(() => new Promise((resolve) => resolve(null)));
  }

  @Action
  loadLatestFeed(payload: {
    uid: string;
    type: string;
    authUserId: string;
  }): Promise<FeedItemWrapper | undefined> {
    if (payload.type === FeedType.GLOBAL) {
      return this.feedItemWrapperRepository.latestFeedItem({
        operationName: 'getWallFeeds',
        authUser: payload.uid,
        definition: buildQueryDefinition(
          {
            filter: {
              value: {
                uid: payload.uid,
              },
              type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
            },
          },
        ),
      });
    }

    if (payload.type === FeedType.TOPIC) {
      return this.feedItemWrapperRepository.latestFeedItem({
        operationName: 'feedsOfTopic',
        authUser: payload.authUserId,
        definition: buildQueryDefinition(
          {
            filter: {
              value: {
                uid: payload.uid,
              },
              type: GqlEntityFilterType.TOPIC_FILTER,
            },
          },
        ),
      });
    }

    if (payload.type === FeedType.CHANNEL) {
      return this.feedItemWrapperRepository.latestFeedItem({
        operationName: 'feedOfChannel',
        authUser: payload.authUserId,
        definition: buildQueryDefinition(
          {
            filter: {
              value: {
                uid: payload.uid,
              },
              type: GqlEntityFilterType.CHANNEL_FILTER,
            },
          },
        ),
      });
    }
    return this.feedItemWrapperRepository.latestFeedItem({
      operationName: 'feedsOfEntity',
      authUser: payload.authUserId,
      definition: buildQueryDefinition({
        filter: {
          value: {
            initiatorUid: this.feedInitiator
              && this.feedInitiator.uid
              && payload.type === FeedType.SUB_EDITION
              ? this.feedInitiator.uid
              : payload.uid,
            myUid: payload.authUserId,
          },
          type: GqlEntityFilterType.FEED_ITEM_WRAPPER_FILTER,
        },
      }),
    });
  }

  @Action
  disconnect(uid: string): void {
    this.context.dispatch('CommunityUserConnectionStore/disconnect', { uid }, { root: true })
      .then(() => {
        this.categories.forEach((category) => {
          category.items.forEach((item) => {
            if ((item.initiator as CommunityUser)._ourConnection?.uid === uid) {
              (item.initiator as CommunityUser)._ourConnection = null;
            }
          });
        });
      });
  }

  @Action
  reportPost({
    postFeedPostUid,
    entity,
    reporterCommunityUserUid,
  }: { postFeedPostUid: string; entity: Partial<FeedPostReportInput>; reporterCommunityUserUid: string }): Promise<FeedPostReportInput | undefined> {
    return this.feedPostReportRepository.create({
      operationName: 'reportPost',
      definition: buildMutationDefinition([
        {
          fieldName: 'post_FeedPostUid',
          type: GqlEntityInputType.ID,
          value: postFeedPostUid,
        },
        {
          fieldName: 'reportedByUser_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: reporterCommunityUserUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FEED_POST_REPORT_INPUT,
          value: entity,
        },
      ]),
    });
  }

  @Action
  repostPostToFeed({
    initiatorNodeUid,
    repostPostFeedPostUid,
    authorUserCommunityUserUid,
    entity,
  }: { initiatorNodeUid: string; repostPostFeedPostUid: string; authorUserCommunityUserUid: string; entity: FeedPost }): Promise<FeedPost | undefined> {
    return this.feedPostRepository.create({
      operationName: 'repostPostToFeed',
      definition: buildMutationDefinition([
        {
          fieldName: 'initiator_NodeUid',
          type: GqlEntityInputType.ID,
          value: initiatorNodeUid,
        },
        {
          fieldName: 'repostPost_FeedPostUid',
          type: GqlEntityInputType.ID,
          value: repostPostFeedPostUid,
        },
        {
          fieldName: 'authorUser_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: authorUserCommunityUserUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FEED_POST_INPUT,
          value: entity,
        },
      ]),
    });
  }

  @Action
  repostPostToTopic({
    initiatorNodeUid,
    repostPostFeedPostUid,
    authorUserCommunityUserUid,
    topicTopicUid,
    entity,
  }: { initiatorNodeUid: string; repostPostFeedPostUid: string; authorUserCommunityUserUid: string; topicTopicUid: string; entity: FeedPost }): Promise<FeedPost | undefined> {
    return this.feedPostRepository.create({
      operationName: 'repostPostToTopic',
      definition: buildMutationDefinition([
        {
          fieldName: 'initiator_NodeUid',
          type: GqlEntityInputType.ID,
          value: initiatorNodeUid,
        },
        {
          fieldName: 'repostPost_FeedPostUid',
          type: GqlEntityInputType.ID,
          value: repostPostFeedPostUid,
        },
        {
          fieldName: 'authorUser_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: authorUserCommunityUserUid,
        },
        {
          fieldName: 'topic_TopicUid',
          type: GqlEntityInputType.ID,
          value: topicTopicUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.FEED_POST_INPUT,
          value: entity,
        },
      ]),
    });
  }

  @Mutation
  toggleConnection(payload: { connection: CommunityUserConnection | null | undefined; linkedUserUid: string }): void {
    const { connection, linkedUserUid } = payload;
    this.categories.forEach((category) => {
      category.items.forEach((wrapper: FeedItemWrapper) => {
        if ((wrapper.initiator as CommunityUser).uid === linkedUserUid) {
          (wrapper.initiator as CommunityUser)._ourConnection = connection;
        }

        if (wrapper._feedItems && wrapper._feedItems.length > 0) {
          wrapper._feedItems.forEach((item: FeedItem) => {
            if (item.triggered
              && item.triggered.__typename === EntityType.CONNECTION
            ) {
              if (item.action === FeedAction.USER_CONNECTIONS_UPDATED_FROM
                && ((item.triggered as CommunityUserConnection).connectedUser as CommunityUser).uid === linkedUserUid) {
                ((item.triggered as CommunityUserConnection).connectedUser as CommunityUser)._ourConnection = connection;
              } else if (item.action === FeedAction.USER_CONNECTIONS_UPDATED_TO
                && ((item.triggered as CommunityUserConnection).user as CommunityUser).uid === linkedUserUid) {
                ((item.triggered as CommunityUserConnection).user as CommunityUser)._ourConnection = connection;
              }
            }
          });
        }
      });
    });
  }

  @Action
  deletePost({ wrapperUid, triggeredUid }: { wrapperUid: string; triggeredUid: string }): Promise<void> {
    return this.feedPostRepository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: triggeredUid,
        },
      ]),
    })
      .then(() => this.context.commit('removePost', wrapperUid));
  }

  @Action
  hidePost(payload: { userUid: string; wrapperUid: string }): void {
    this.repository.update({
      operationName: 'hidePost',
      definition: buildMutationDefinition([
        {
          fieldName: 'hiddenUser_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: payload.userUid,
        },
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.wrapperUid,
        },
      ]),
    })
      .then(() => this.context.commit('removePost', payload.wrapperUid));
  }

  @Action
  showPost(payload: { userUid: string; wrapperUid: string }): void {
    this.repository.update({
      operationName: 'showPost',
      definition: buildMutationDefinition([
        {
          fieldName: 'hiddenUser_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: payload.userUid,
        },
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.wrapperUid,
        },
      ]),
    });
  }

  @Mutation
  removePost(uid: string): void {
    this.categories.every((category) => {
      const index = category.items.findIndex((wrapper: FeedItemWrapper) => wrapper.uid === uid);
      if (index !== -1) {
        category.items.splice(index, 1);
        return false;
      }
      return true;
    });
  }

  @Mutation
  replacePost(updatedWrapper: FeedItemWrapper): void {
    this.categories.every((category) => {
      const index = category.items.findIndex((wrapper: FeedItemWrapper) => wrapper.uid === updatedWrapper.uid);
      if (index !== -1) {
        category.items[index] = updatedWrapper;
        return false;
      }
      return true;
    });
  }

  @Action
  updateFeedItemBookmarkState(payload: CommunityUserBookmarkFilter): void {
    this.categories.forEach((element) => {
      const searchFeedItem = element.items.find((subElement) => subElement?.uid === payload?.feedItemWrapperUid);
      if (searchFeedItem) {
        searchFeedItem._isBookmarked = payload?.uid;
      }
    });
  }

  @Action
  getTopicsList(payload: {
    userUid: string;
    entityUid: string;
    isOrganiser: boolean;
  }): void{
    if (this.topicsList.length !== 0) return;
    this.topicRepository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: {
            _isFollowedFilter: payload.userUid,
          },
          type: GqlEntityFilterType.TOPIC_FILTER,
        },
      }),
    }).then((response) => {
      const el = {
        topics: response.filter((topic) => topic.organizerOnlyPosting === false || payload.isOrganiser),
        entityUid: payload.entityUid,
      };
      this.context.commit('setTopicsListForAuthUser', el);
    });
  }
}
