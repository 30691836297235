import EmailResponse from '@/utils/types/sign-in/EmailResponse';
import LoginResponseParams from '@/utils/types/LoginResponseParams';
import RestRepository from '@/repositories/base/RestRepository';

export default class SignInRepository extends RestRepository {
  constructor() {
    super(
      `${process.env.VUE_APP_SERVICES_ACCOUNT_URL}`,
      { 'Content-type': 'application/x-www-form-urlencoded' },
    );
  }

  login(username: string, password: string, communityCode: string, token: string): Promise<LoginResponseParams> {
    const formData = new URLSearchParams();
    formData.append('username', username.toLowerCase());
    formData.append('password', password);
    formData.append('community_code', communityCode);
    formData.append('token', token);

    return this.post<LoginResponseParams>({
      path: 'oauth-provider/sherpa-ajax/do-login',
      data: formData.toString(),
    });
  }

  magicLink(communityCode: string, username: string, token: string): Promise<LoginResponseParams | null> {
    const formData = new URLSearchParams();
    formData.append('username', username.toLowerCase());
    formData.append('community_code', communityCode);
    formData.append('token', token);
    return this.post<LoginResponseParams | null>({
      path: 'oauth-provider/sherpa-ajax/do-send-login-email',
      data: formData.toString(),
    });
  }

  checkEmail(communityCode: string, username: string, token: string): Promise<EmailResponse> {
    const formData = new URLSearchParams();
    formData.append('username', username.toLowerCase());
    formData.append('community_code', communityCode);
    formData.append('token', token);
    return this.post<EmailResponse>({
      path: 'oauth-provider/sherpa-ajax/do-login-step',
      data: formData.toString(),
    });
  }

  activateAccount(communityCode: string, token: string): Promise<LoginResponseParams | null> {
    const formData = new URLSearchParams();
    formData.append('community_code', communityCode);
    formData.append('token', token);
    return this.post<EmailResponse>({
      path: 'oauth-provider/sherpa-ajax/do-activate-user',
      data: formData.toString(),
    });
  }

  resetPassword(communityCode: string, email: string): Promise<LoginResponseParams | null> {
    const formData = new URLSearchParams();
    formData.append('community', communityCode);
    formData.append('email', email);
    return this.get<LoginResponseParams | null>({
      url: `${process.env.VUE_APP_ACCOUNT_MANAGEMENT_BASE_URL}`,
      path: 'account/trigger-recovery',
      params: formData,
      headers: {},
    });
  }
}
