








































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import FilterItemComponent from '@/components/FilterItemComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import InputText from '@/components/InputText.vue';
import TextAreaComponent from '@/components/TextAreaComponent.vue';
import CategorySelectorComponent from '@/components/CategorySelectorComponent.vue';
import DateRadioFieldComponent from '@/components/DateRadioFieldComponent.vue';
import UploadAssetComponent from '@/components/UploadAssetComponent.vue';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import GdprCookieType from '@/utils/enums/GdprCookieType';
import ClientStorage from '@/utils/ClientStore';
import GdprCookieInterface from '@/models/GdprCookieInterface';
import GDPR from '@/utils/constants/Gdpr';

@Component({
  components: {
    CheckboxSwitchComponent,
    UploadAssetComponent,
    DateRadioFieldComponent,
    CategorySelectorComponent,
    TextAreaComponent,
    InputText,
    FontAwesomeComponent,
    StandardModal,
    PillWidget,
    FilterItemComponent,
    InputSearchComponent,
    ButtonIconComponent,
    ButtonComponent,
  },
})
export default class GdprSettingModal extends Vue {
  @Prop({ default: '' })
  modalId!: string;

  private toggle = false;

  private settings: GdprCookieInterface = {
    necessary: true,
    analytics: false,
    advertising: false,
    performance: false,
  };

  private gdprCookieType = GdprCookieType

  private saveSettings(): void {
    ClientStorage.setItem(GDPR, JSON.stringify(this.settings));
    this.$bvModal.hide(this.modalId);
    this.$emit('on-save');
  }

  private onToggle(type: GdprCookieType): void {
    this.toggle = true;
    if (this.settings[type]) {
      this.settings[type] = false;
    } else {
      this.settings[type] = true;
    }
  }

  private onRejectAll(): void {
    this.settings.analytics = false;
    this.settings.advertising = false;
    this.settings.performance = false;
    this.saveSettings();
  }

  private onAcceptAll(): void {
    this.settings.analytics = true;
    this.settings.advertising = true;
    this.settings.performance = true;
    this.saveSettings();
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
    this.$emit('on-cancel');
  }

  private onShow(): void {
    const gdprStorage = ClientStorage.getItem(GDPR);
    if (gdprStorage) {
      const gdprSettings: GdprCookieInterface = JSON.parse(gdprStorage);
      if (gdprSettings.necessary) {
        this.settings = gdprSettings;
      }
    }
  }
}
