import { Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';

@Module({ namespaced: true, stateFactory: true })
export default class WideCarouselWidgetStore extends WidgetBaseStore {
  @Mutation
  mapper(): void {
    let slides = [];
    if (this.widget && this.widget.subWidgets) {
      slides = this.widget.subWidgets.map((sub) => ({
        uid: sub.uid,
        ...JSON.parse(sub.payload || '{}'),
      }));
    }
    this.mapping = {
      ...this.payload,
      slides,
    };
  }
}
