import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import Poll from '@/models/graphql/Poll';
import PollRepository from '@/repositories/PollRepository';
import { PollFilter } from '@/graphql/_Filters/PollFilter';
import { BasicTypes } from '@/utils/types/BasicTypes';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';

interface PollState extends LoadableState {
  polls: Poll[];
  statePoll: Poll;
}

@Module({ namespaced: true })
export default class PollStore extends LoadableStore<PollState> {
  polls: Poll[] = [] as Poll[];

  statePoll: Poll = {} as Poll;

  private page = 0;

  private itemsPerPage = 10;

  private readonly pollRepository = new PollRepository();

  get poll(): Poll | null {
    return this.statePoll;
  }

  get fetchPolls(): Poll[] {
    return this.polls;
  }

  protected get repository(): PollRepository {
    return this.pollRepository;
  }

  @Action
  filter(payload: { filter: PollFilter }): Promise<Poll[]> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.POLL_FILTER,
        },
        orderBy: {
          value: ['endTimestamp_desc', 'id_desc'],
          type: GqlEntityOrderingType.POLL_ORDERING,
        },
      }),
      authUser: this.context.rootGetters.authUser?.uid || '',
    }).then((response) => {
      this.context.commit('setElements', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  get(payload: { filter: PollFilter; fragmentName?: string }): Promise<Poll | undefined> {
    const { filter, fragmentName = 'pollBaseFragment' } = payload;
    this.context.commit('load', true);
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.POLL_FILTER,
        },
      }),
      fragmentName,
    }).then((response) => {
      this.context.commit('setElement', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  loadPaginatedPolls(payload: Record<string, BasicTypes>): Promise<number | null> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter as object | null | undefined,
          type: GqlEntityFilterType.POLL_FILTER,
        },
        orderBy: {
          value: ['endTimestamp_desc', 'id_desc'],
          type: GqlEntityOrderingType.POLL_ORDERING,
        },
        first: this.itemsPerPage,
        offset: this.itemsPerPage * this.page,
      }),
      operationName: 'PaginatePolls',
      authUser: payload.authUser as string,
    }).then((response) => {
      if (this.page === 0) {
        if (response.length !== 0) {
          this.context.commit('setPage', 1);
        }
      } else {
        this.context.commit('setPage', this.page + 1);
      }
      this.context.commit('addElements', response);
      this.context.commit('load', false);
      return response.length;
    }).catch(() => new Promise((resolve) => resolve(null)));
  }

  @Action
  create(payload: {sessionUid: string; entity: Poll}): Promise<Poll | undefined> {
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'session_SessionUid',
          type: 'ID',
          value: payload.sessionUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.POLL_INPUT,
          value: payload.entity,
        },
      ]),
    });
  }

  @Action
  createWithPost(payload: {postUid: string; entity: Poll}): Promise<Poll | undefined> {
    return this.repository.createPollWithPost({
      definition: buildMutationDefinition([
        {
          fieldName: 'post_FeedPostUid',
          type: 'ID',
          value: payload.postUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.POLL_INPUT,
          value: payload.entity,
        },
      ]),
    }).then((response) => response);
  }

  @Action
  update(payload: Partial<Poll>): Promise<Poll | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.POLL_INPUT,
          value: payload,
        },
      ]),
    });
  }

  @Action
  delete(uid: string): Promise<Poll | undefined> {
    return this.repository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    });
  }

  @Action
  count(payload: { filter: PollFilter }): Promise<void | number | undefined> {
    this.context.commit('load', true);
    return this.repository.count({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.POLL_FILTER,
        },
      }),
    }).then((response) => {
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  resetPage(): void {
    this.context.commit('setPage', 0);
    this.context.commit('setElements', []);
  }

  @Mutation
  setElements(list: Poll[]): void {
    this.polls = list;
  }

  @Mutation
  setElement(poll: Poll): void {
    this.statePoll = poll;
  }

  @Mutation
  addElements(polls: Poll[]): void {
    this.polls = [...this.polls];
    polls.forEach((newPoll) => {
      const pollIndex = this.polls.findIndex((p) => p.uid === newPoll.uid);
      if (pollIndex > -1) {
        this.polls.splice(pollIndex, 1, newPoll);
      } else {
        this.polls.push(newPoll);
      }
    });
  }

  @Mutation
  setPage(page: number): void {
    this.page = page;
  }
}
