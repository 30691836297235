/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import BaseModel from '@/models/BaseModel';
import Community from '@/models/graphql/Community';
import FeedState from '@/models/graphql/FeedState';
import CommunityUserLink from '@/models/graphql/CommunityUserLink';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import CommunityUserShare from '@/models/graphql/CommunityUserShare';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import CommunityUserProject from '@/models/graphql/CommunityUserProject';
import CommunityUserTask from '@/models/graphql/CommunityUserTask';
import CommunityUserFollow from '@/models/graphql/CommunityUserFollow';
import CommunityUserNote from '@/models/graphql/CommunityUserNote';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CommunityUserInterest from '@/models/graphql/CommunityUserInterest';
import CommunityUserProfileRole from '@/models/graphql/CommunityUserProfileRole';
import CommunityUserUrl from '@/models/graphql/CommunityUserUrl';
import FileResource from '@/models/graphql/FileResource';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import Country from '@/models/graphql/Country';
import CompanyRoleEnum from '@/utils/enums/CompanyRoleEnum';
import ProfilePrivacyType from '@/utils/enums/ProfilePrivacyType';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import Exhibitor from '@/models/graphql/Exhibitor';
import SubEditionUserRole from '@/models/graphql/SubEditionUserRole';
import Address from '@/models/graphql/Address';
import CommunityUserPreferences from '@/models/graphql/CommunityUserPreferences';
import ColorHelper from '@/utils/helpers/ColorHelper';
import tinycolor from 'tinycolor2';
import Speaker from '@/models/graphql/Speaker';
import SessionUserRole from '@/models/graphql/SessionUserRole';
import Category from '@/models/graphql/Category';

export default class CommunityUser extends BaseModel {
  id?: number;

  community?: Community;

  firstName?: string;

  lastName?: string;

  prefix?: string;

  suffix?: string;

  name?: string;

  _breadcrumbName?: string;

  jobTitle?: string;

  employerName?: string;

  email?: string;

  bio?: string;

  address?: Address;

  goals?: string;

  passwordHash?: string;

  createdTime?: string;

  updatedTime?: string;

  deletedTime?: string;

  notes?: Array<CommunityUserNote>;

  interests: Array<CommunityUserInterest> = [];

  profileRoles: Array<CommunityUserProfileRole> = [];

  tasks?: Array<CommunityUserTask>;

  links?: Array<CommunityUserLink>;

  bookmarks?: Array<CommunityUserBookmark>;

  follows?: Array<CommunityUserFollow>;

  shares?: Array<CommunityUserShare>;

  connected?: Array<CommunityUserConnection>;

  connections?: Array<CommunityUserConnection>;

  _connections?: Array<CommunityUser>;

  myProjects?: Array<CommunityUserProject>;

  collaboratingOnProjects?: Array<CommunityUserProject>;

  linked?: Array<CommunityUserLink>;

  urls: Array<CommunityUserUrl> = [];

  pictureFileResource?: FileResource;

  bannerFileResource?: FileResource;

  tags?: CommunityUserTag[];

  _isBookmarked?: string | null;

  _isFollowed?: string | null;

  _isShared?: string | null;

  _isInAgenda?: string | null;

  _isConnected?: boolean;

  _connected?: Array<CommunityUserConnection>;

  _ourConnection?: CommunityUserConnection | null;

  _isRecommendedForMe?: boolean;

  _followerCount?: number;

  _connectionCount?: number;

  _sharedConnectionCount?: number;

  _needsActivation?: boolean;

  _needsPasswordCreated?: boolean;

  _needsEmailDisambiguated?: boolean;

  _needsNameCreated?: boolean | null;

  _myTags?: CommunityUserTag[];

  city?: string;

  state?: string;

  country?: Country;

  lastViewedFeeds?: number;

  feedStates: FeedState[] = [];

  companyRoles: Array<CompanyUserRole> = [];

  speakers: Array<Speaker> = [];

  sessionRoles: Array<SessionUserRole> = [];

  subEditionRoles: Array<SubEditionUserRole> = [];

  blockedUsers: CommunityUser[] = [];

  blockedByUsers: CommunityUser[] = [];

  preferences?: CommunityUserPreferences;

  isBlocked?: boolean;

  isNotBlocked?: boolean;

  profilePrivacyMode?: string;

  _actions?: { key: string; value: string }[];

  _authToken?: string;

  password?: string;

  banned = false;

  roleInCompany!: CompanyUserRole;

  videoPresentationS3Url?: string;

  _ourSharedInterests: Category[] = [];

  _companyUsersConnections: CommunityUser[] = [];

  _ourSharedInterestsCount = 0;

  _ourSharedInterestsCompany: Category[] = [];

  _ourSharedInterestsCompanyCount = 0;

  get profilePicture(): string {
    return FileResourceHelper.getFullPath(this.pictureFileResource, 'w256');
  }

  get mainPicture(): string {
    return this.profilePicture;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get nameAbbr(): string {
    const str = [];
    if (this.firstName) {
      str.push(this.firstName[0].toUpperCase());
    }
    if (this.lastName) {
      str.push(this.lastName[0].toUpperCase());
    }
    return str.join('');
  }

  get getFullPrefixedName(): string {
    let fullName = '';
    if (this.prefix) {
      fullName = `${this.prefix}`;
    }
    if (this.firstName) {
      const separator = fullName ? ' ' : '';
      fullName = `${fullName}${separator}${this.firstName}`;
    }
    if (this.lastName) {
      const separator = fullName ? ' ' : '';
      fullName = `${fullName}${separator}${this.lastName}`;
    }
    if (this.suffix) {
      const separator = fullName ? ', ' : '';
      fullName = `${fullName}${separator}${this.suffix}`;
    }
    return fullName.trim();
  }

  get defaultCompany(): Exhibitor | null {
    if (this.companyRoles && this.companyRoles.length > 0) {
      const defaultCompanyUserRoles = this.companyRoles.filter((role: CompanyUserRole) => role.defaultCompany);
      return defaultCompanyUserRoles.length > 0 && defaultCompanyUserRoles[0].company
        ? defaultCompanyUserRoles[0].company : null;
    }
    return null;
  }

  get generatedColors(): { color: string; bgColor: string; borderColor: string } {
    // eslint-disable-next-line new-cap
    const color = new tinycolor(ColorHelper.generatedColor(this.fullName));
    return {
      color: color.toHex8String(),
      bgColor: color.brighten(50)
        .toHex8String(),
      borderColor: color.darken(50)
        .toHex8String(),
    };
  }

  get isProfileVisible(): boolean {
    switch (this.profilePrivacyMode) {
      case null:
      case ProfilePrivacyType.PUBLIC:
        return true;
      case ProfilePrivacyType.CONNECTIONS:
        if (this._ourConnection) {
          return this._ourConnection.linkState === ConnectionStatusType.ACCEPTED;
        }
        return false;
      case ProfilePrivacyType.PRIVATE:
        return false;
      default:
        return true;
    }
  }

  get isAnOrganiser(): boolean {
    if (this.subEditionRoles.length > 0) {
      return !!this.subEditionRoles.find((subEditionRole) => (
        subEditionRole.canPost
        && subEditionRole.role
        && subEditionRole.role.code
        && (subEditionRole.role.code === CompanyRoleEnum.OWNER || subEditionRole.role.code === CompanyRoleEnum.ADMINISTRATOR)
      ));
    }
    return false;
  }

  static hydrate(item: object): CommunityUser {
    if (item instanceof CommunityUser) {
      return item;
    }
    const result = new CommunityUser();
    Object.assign(result, item);

    return result;
  }

  public haveBlockedUser(entityUid: string): boolean {
    return !!this.blockedUsers.find((item) => item.uid === entityUid);
  }

  public isBlockedByUser(entityUid: string): boolean {
    return !!this.blockedByUsers.find((item) => item.uid === entityUid);
  }
}

export type AuthenticatedUser = CommunityUser & { token: string };
