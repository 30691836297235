enum SignInStep {
  GUEST = 'guest',
  ENTER_EMAIL = 'enter-email',
  SIGN_UP = 'sign-up',
  ENTER_PASSWORD = 'enter-password',
  ENTER_INFORMATION = 'enter-information',
  LOGGED_IN = 'logged-in',
  NO_SIGN_UP= 'no-sign-up',
  EMAIL_SENT= 'email-sent',
  CHANGE_EMAIL= 'change-email',
  CREATE_PASSWORD = 'create-password',
  EMAIL_DISAMBIGUATED = 'email-disambiguated',
  PASSWORD_RESET = 'password-reset',
  INVALID_SIGN_IN = 'invalid-sign-in'
}

export default SignInStep;
