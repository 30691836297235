import { Action, Module, Mutation } from 'vuex-module-decorators';
import MessageGroup from '@/models/graphql/MessageGroup';
import MessageGroupRepository from '@/repositories/chat/MessageGroupRepository';
import CommunityUser from '@/models/graphql/CommunityUser';
import EventEnum from '@/utils/enums/chat/EventEnum';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import Message from '@/models/graphql/Message';
import MessageGroupState from '@/models/graphql/MessageGroupState';
import {
  addSeconds, fromUnixTime, getUnixTime, parseISO,
} from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import MessageBoxConversationSummary from '@/utils/types/chat/MessageBoxConversationSummary';
import MessageBoxStatus from '@/utils/enums/chat/MessageBoxActions';
import MessageBoxReadStatus from '@/utils/enums/chat/MessageBoxReadStatus';
import MessageRepository from '@/repositories/chat/MessageRepository';
import { MessageFilter } from '@/graphql/_Filters/MessageFilter';
import TypingEventEnum from '@/utils/enums/chat/TypingEventEnum';
import ChatTypingEvent from '@/utils/types/chat/ChatTypingEvent';
import ChatErrorList from '@/utils/types/chat/ChatErrorList';
import ChatError, {
  ChatErrorType,
  ChatErrorView,
  ChatRequestPayloadType,
  ChatRequestType,
} from '@/utils/types/chat/ChatError';
import ToastActionType from '@/utils/enums/ToastActionType';
import MessageType from '@/utils/enums/chat/MessageType';
import GroupType from '@/utils/enums/chat/GroupType';
import EntityType from '@/utils/enums/EntityType';
import Meeting from '@/models/graphql/Meeting';
import Reaction from '@/models/graphql/Reaction';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import {
  CHAT_GROUPS,
  CHAT_MESSAGES,
  CHAT_MESSAGES_TO_KEEP_IN_VIEW,
} from '@/utils/constants/PaginationOffsets';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import CommunityUserRepository from '@/repositories/CommunityUserRepository';
import MessageGroupStateRepository from '@/repositories/chat/MessageGroupStateRepository';

/* eslint-disable @typescript-eslint/camelcase */
const checkUnreadState = (conv: MessageGroup): boolean => {
  if (conv && conv.myState && conv.lastMessageNotYours) {
    // eslint-disable-next-line no-underscore-dangle
    const isSessionChat = conv && !!conv.target && conv.target.__typename === EntityType.SESSION;
    if (isSessionChat) {
      return false;
    }
    const { lastReadTimestamp } = conv.myState;
    const sentTimestamp = getUnixTime(DateTimeHelper
      .toLocal(parseISO(conv.lastMessageNotYours.senttime as string)));
    if (sentTimestamp && lastReadTimestamp) {
      return sentTimestamp > lastReadTimestamp;
    }
  }
  return false;
};

const sort = (conv: MessageGroup[]): MessageGroup[] => conv.sort((a, b) => {
  if (a.messages && b.messages) {
    const aLastMessage = a.messages[a.messages?.length - 1] as unknown as Message;
    const bLastMessage = b.messages[b.messages?.length - 1] as unknown as Message;
    if (aLastMessage && aLastMessage.senttime && bLastMessage && bLastMessage.senttime) {
      const aSentTime = getUnixTime(DateTimeHelper.toLocal(parseISO(aLastMessage.senttime as string)));
      const bSentTime = getUnixTime(DateTimeHelper.toLocal(parseISO(bLastMessage.senttime as string)));

      if (aSentTime < bSentTime) {
        return 1;
      }
      if (aSentTime > bSentTime) {
        return -1;
      }
      return 0;
    }
    if (!aLastMessage || !aLastMessage.senttime) {
      return 1;
    }
    if (!bLastMessage || !bLastMessage.senttime) {
      return -1;
    }
  }
  return 0;
});

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class ChatStore extends LoadableStore<MessageGroup> {
  conversations: MessageGroup[] = [];

  groupsOffset = 0;

  loadMoreGroups = true;

  validateGroupFn: ((group: MessageGroup) => boolean) | null = null;

  groupsSearchQuery = '';

  status = MessageBoxStatus.CONVERSATIONS;

  readStatus = MessageBoxReadStatus.READ;

  lastRequestPayload: ChatRequestPayloadType = null;

  tempGroupId: string | null = null;

  tempTargetId: string | null = null;

  tempTargetType: string | null = null;

  tempTargetData: object | undefined | null = null;

  closed = true;

  playNewMessageSound = false;

  chatErrors = new ChatErrorList();

  selectedUsersForNewGroup: CommunityUser[] = [];

  serverDisconnectTimeOut: NodeJS.Timeout | null = null;

  needToOpenChatReceivingMessageResponse = false;

  private messageRepository: MessageRepository = new MessageRepository();

  private messageGroupRepository: MessageGroupRepository = new MessageGroupRepository();

  private messageGroupStateRepository: MessageGroupStateRepository = new MessageGroupStateRepository();

  private communityUserRepository: CommunityUserRepository = new CommunityUserRepository();

  get selectedView(): MessageBoxStatus {
    return this.status;
  }

  get getTempGroupId(): string | null {
    return this.tempGroupId;
  }

  get fullChatErrors(): ChatErrorList {
    return this.chatErrors;
  }

  get isClosed(): boolean {
    return this.closed;
  }

  get count(): number {
    return this.conversations.length;
  }

  get fetchPossibleGroups(): (usersId: string[]) => MessageGroup | undefined {
    return ((usersId: string[]) => this.conversations.find((conv) => {
      const ids: string[] = [];
      if (conv.target === null
        && conv.users && conv.users.length > 0) {
        ids.push(...conv.users.map((u) => u.uid) as string[]);
      }
      return ids.length === usersId.length
        && conv.inactiveUsers?.length === 0
        && ids.length > 0
        && ids.every((u) => usersId.includes(u));
    }));
  }

  get summary(): MessageBoxConversationSummary {
    return new MessageBoxConversationSummary(
      [
        // eslint-disable-next-line max-len
        ...this.selectedGroup?.users?.filter((user) => user.uid !== this.context.rootState.authUser?.uid) || [],
      ],
    );
  }

  get unReadCount(): number {
    return this.conversationsList.filter((conv) => conv.unreadState
      // eslint-disable-next-line no-underscore-dangle
      && !(conv.target && conv.target.__typename === EntityType.SESSION)
      && !conv.isMuted).length;
  }

  get selectedGroup(): MessageGroup | undefined {
    return this.conversations.find((conv) => conv.selected);
  }

  get conversationsList(): MessageGroup[] {
    return sort(this.conversations
      .filter((conv) => !(conv.groupType === GroupType.CONNECTION
        // eslint-disable-next-line no-underscore-dangle
        && conv.users?.find((u) => u._ourConnection
          // eslint-disable-next-line no-underscore-dangle
          && u._ourConnection.linkState === 'INVITED'
          // eslint-disable-next-line no-underscore-dangle
          && u._ourConnection.connectedUser
          // eslint-disable-next-line no-underscore-dangle
          && u._ourConnection.connectedUser.uid !== this.context.rootState.authUser?.uid
          && u.uid !== this.context.rootState.authUser?.uid)))
      .map((conv) => Object.assign(conv, {
        unreadState: conv.messagesInView
          ? false
          : checkUnreadState(conv),
      })))
      .filter((conv) => (this.validateGroupFn !== null && this.validateGroupFn(conv)) || this.validateGroupFn === null);
  }

  protected get repository(): MessageGroupRepository {
    return this.messageGroupRepository;
  }

  @Action
  goToFirstUnreadMessage(): void {
    if (this.conversations.length > 0 && this.unReadCount > 0) {
      const group = this.conversationsList.find((conv) => conv.unreadState);
      if (group && group.uid) {
        this.context.commit('setSelectedGroup', group.uid);
        this.context.commit('changeStatus', MessageBoxStatus.MESSAGES);
      }
    }
  }

  @Action
  toggleCompanyChatModerator(payload: {
    user: string;
    company: string;
    toAdd: boolean;
  }): Promise<void> {
    const authUser = this.context.rootState.authUser ? this.context.rootState.authUser.uid : '';
    return this.repository.filter({
      operationName: 'FindMessageGroupsForCompany',
      fragmentName: 'messageGroupBaseFragment',
      definition: buildQueryDefinition({
        filter: {
          value: {
            type: GroupType.GROUP,
            target: {
              uid: payload.company,
            },
          },
          type: GqlEntityFilterType.MESSAGE_GROUP_FILTER,
        },
      }),
      authUser,
    })
      .then((groups) => {
        if (groups.length > 0) {
          groups.forEach((group) => {
            if (payload.toAdd) {
              this.repository.setActiveUser({
                definition: buildMutationDefinition([
                  {
                    fieldName: 'userUid',
                    type: GqlEntityInputType.REQUIRED_ID,
                    value: payload.user,
                  },
                  {
                    fieldName: 'messageGroupUid',
                    type: GqlEntityInputType.REQUIRED_ID,
                    value: group.uid,
                  },
                ]),
                authUser,
              });
            } else {
              this.repository.setInactiveUser({
                definition: buildMutationDefinition([
                  {
                    fieldName: 'userUid',
                    type: GqlEntityInputType.REQUIRED_ID,
                    value: payload.user,
                  },
                  {
                    fieldName: 'messageGroupUid',
                    type: GqlEntityInputType.REQUIRED_ID,
                    value: group.uid,
                  },
                ]),
                authUser,
              });
            }
          });
        }
        return Promise.resolve();
      });
  }

  @Action
  lookupForMessageGroupWithTarget(targetId: string): Promise<MessageGroup | undefined> {
    return this.repository.get({
      operationName: 'FindMessageGroupForTarget',
      definition: buildQueryDefinition({
        filter: {
          value: {
            target: {
              uid: targetId,
            },
            // eslint-disable-next-line @typescript-eslint/camelcase
            type_not: MessageType.QNA,
          },
          type: GqlEntityFilterType.MESSAGE_GROUP_FILTER,
        },
      }),
      authUser: this.context.rootState.authUser ? this.context.rootState.authUser.uid : '',
    })
      .then((conversation) => {
        if (conversation) {
          this.context.commit('setConversationWithTarget', conversation);
          return Promise.resolve(conversation);
        }
        return Promise.resolve(undefined);
      });
  }

  @Action
  paginatedGroups(filter: object): Promise<void> {
    return this.repository.filter({
      operationName: 'LoadChatGroupsByPage',
      definition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.MESSAGE_GROUP_FILTER,
        },
        first: CHAT_GROUPS,
        offset: this.groupsOffset,
        orderBy: {
          value: ['_lastChatMessageSentTimestamp_desc'],
          type: GqlEntityOrderingType.MESSAGE_GROUP_ORDERING,
        },
      }),
      authUser: this.context.rootState.authUser ? this.context.rootState.authUser.uid : '',
    })
      .then((groups: Array<MessageGroup>) => {
        this.context.commit('setElements', {
          groups,
          reset: this.groupsOffset === 0,
          authUser: this.context.rootState.authUser?.uid || '',
        });
        this.context.commit('setGroupsOffset', this.groupsOffset + CHAT_GROUPS);
        this.context.commit('setLoadMoreGroups', groups.length === CHAT_GROUPS);
      })
      .catch((err) => {
        this.chatErrors.addErrors({
          type: ChatErrorType.FETCH_CONVERSATIONS,
          view: ChatErrorView.CONVERSATIONS,
          group: 'ALL',
          request: {
            action: 'paginatedGroups',
            payload: filter,
          },
        });
        throw err;
      });
  }

  @Mutation
  setGroupsOffset(value: number): void {
    this.groupsOffset = value;
  }

  @Action
  changeChatState(payload: { uid: string; active: boolean }): Promise<boolean | MessageGroup> {
    return this.repository.update({
      operationName: payload.active ? 'OpenChat' : 'CloseChat',
      definition: buildMutationDefinition([
        {
          fieldName: 'group',
          type: 'BaseInput!',
          value: {
            uid: payload.uid,
          },
        },
      ]),
    })
      .then((messageGroup) => !!messageGroup);
  }

  @Action
  lastMessageByGroup(): void {
    const authUserUid = this.context.rootState.authUser?.uid as string;
    this.repository.filter({
      operationName: 'LastMessageByGroup',
      definition: buildQueryDefinition({
        filter: {
          value: {
            users: {
              uid: authUserUid,
            },
            messages: {
              user: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                uid_not: authUserUid,
              },
            },
          },
          type: GqlEntityFilterType.MESSAGE_GROUP_FILTER,
        },
      }),
      authUser: this.context.rootState.authUser ? this.context.rootState.authUser.uid : '',
    })
      .then((response: Array<Partial<MessageGroup>>): void => {
        this.context.commit('setLastMessageByGroup', {
          response,
          authUser: this.context.rootState.authUser?.uid as string,
        });
      });
  }

  @Action
  createConversation(model: {
    users: CommunityUser[];
    message: string | null;
    tempId: string;
    messageTempId: string;
    targetId: string | null;
    targetType: string | null;
    messageType: MessageType | null;
    targetMessageId: string | null;
    active?: boolean | null;
  }): Promise<boolean> {
    const {
      users,
      message,
      targetId,
      targetType,
      tempId,
      messageTempId,
      messageType,
      targetMessageId,
      active,
    } = model;
    users.map((u) => u.uid);
    this.context.commit('setLastRequestPayload', model);
    this.context.commit('initTempGroupId');
    return this.repository.create({
      operationName: 'createConversation',
      definition: buildMutationDefinition([
        {
          fieldName: 'users',
          type: '[BaseInput!]!',
          value: (targetType === EntityType.SESSION && targetId ? [] : users).map((item) => ({ uid: item.uid })),
        },
        {
          fieldName: 'message',
          type: 'String',
          value: message,
        },
        {
          fieldName: 'targetId',
          type: GqlEntityInputType.ID,
          value: targetId,
        },
        {
          fieldName: 'targetType',
          type: 'String',
          value: targetType,
        },
        {
          fieldName: 'tempId',
          type: GqlEntityInputType.REQUIRED_ID,
          value: tempId,
        },
        {
          fieldName: 'messageTempId',
          type: GqlEntityInputType.REQUIRED_ID,
          value: messageTempId,
        },
        {
          fieldName: 'targetMessageId',
          type: GqlEntityInputType.ID,
          value: targetMessageId,
        },
        {
          fieldName: 'messageType',
          type: 'MessageTypeEnum',
          value: messageType,
        },
        {
          fieldName: 'active',
          type: 'Boolean',
          value: active === undefined ? null : active,
        },
      ]),
    })
      .then((messageGroup) => !!messageGroup);
  }

  @Action
  allMessages(filter: MessageFilter): Promise<void> {
    return this.messageRepository
      .filter({
        operationName: 'MessagesAll',
        definition: buildQueryDefinition({
          filter: {
            value: filter.searchQuery ? {
              ...filter,
              _fts: filter.searchQuery,
            } : filter,
            type: GqlEntityFilterType.MESSAGE_FILTER,
          },
        }),
      })
      .then((response: Array<Partial<Message>>) => {
        this.context.commit('addMessages', {
          uid: this.selectedGroup?.uid,
          messages: response,
          replace: true,
        });
      })
      .catch((err) => {
        this.chatErrors.addErrors({
          type: ChatErrorType.FETCH_MESSAGES,
          view: ChatErrorView.MESSAGES,
          group: this.selectedGroup?.uid as string,
          request: {
            action: 'allMessages',
            payload: filter,
          },
        });
        throw err;
      });
  }

  @Action
  paginatedMessages(payload: { filter: MessageFilter; offset: number }): Promise<number> {
    return this.messageRepository
      .filter({
        operationName: 'PaginatedMessages',
        definition: buildQueryDefinition({
          filter: {
            value: payload.filter,
            type: GqlEntityFilterType.MESSAGE_FILTER,
          },
          orderBy: {
            value: ['senttimestamp_desc'],
            type: GqlEntityOrderingType.MESSAGE_ORDERING,
          },
          first: CHAT_MESSAGES,
          offset: payload.offset,
        }),
        authUser: this.context.rootState.authUser?.uid as string,
      })
      .then((response: Array<Partial<Message>>) => {
        this.context.commit('addMessages', {
          uid: this.selectedGroup?.uid,
          messages: response.reverse(),
          replace: payload.offset === 0,
          reverse: true,
        });
        return Promise.resolve(response.length);
      })
      .catch((err) => {
        this.chatErrors.addErrors({
          type: ChatErrorType.FETCH_MESSAGES,
          view: ChatErrorView.MESSAGES,
          group: this.selectedGroup?.uid as string,
          request: {
            action: 'paginatedMessages',
            payload,
          },
        });
        throw err;
      });
  }

  @Action
  countMessagesByGroup(filter: MessageFilter): Promise<number> {
    return this.messageRepository
      .count({
        definition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: filter,
            type: GqlEntityFilterType.MESSAGE_FILTER,
          },
        }),
      })
      .then((countResponse) => (countResponse ? Promise.resolve(countResponse) : Promise.resolve(0)));
  }

  @Action
  loadUnreadMessageCount(filter: object): Promise<number> {
    return this.messageGroupRepository
      .count({
        operationName: 'GetUnreadMessageCount',
        definition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: filter,
            type: GqlEntityFilterType.MESSAGE_GROUP_FILTER,
          },
        }),
      })
      .then((countResponse) => (countResponse ? Promise.resolve(countResponse) : Promise.resolve(0)));
  }

  @Action
  sendMessage(model: {
    groupId: string;
    message: string;
    tempId: string;
    parentMessageId: string | null;
    targetMessageId: string | null;
    type: MessageType | null;
  }): void {
    const {
      groupId,
      message,
      tempId,
      parentMessageId,
      type,
      targetMessageId,
    } = model;
    this.context.commit('setLastRequestPayload', model);
    this.context.commit('initTempGroupId');
    this.messageRepository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'content',
          type: GqlEntityInputType.STRING,
          value: message,
        },
        {
          fieldName: 'groupId',
          type: GqlEntityInputType.REQUIRED_ID,
          value: groupId,
        },
        {
          fieldName: 'tempId',
          type: 'String',
          value: tempId,
        },
        {
          fieldName: 'parentMessageId',
          type: GqlEntityInputType.ID,
          value: parentMessageId,
        },
        {
          fieldName: 'targetMessageId',
          type: GqlEntityInputType.ID,
          value: targetMessageId,
        },
        {
          fieldName: 'messageType',
          type: 'MessageTypeEnum',
          value: type,
        },
      ]),
    })
      .then((messageResult) => !!messageResult);
  }

  @Action
  createReaction(model: {
    reaction: string;
    messageId: string;
    tempId: string;
  }): void {
    const {
      reaction,
      messageId,
      tempId,
    } = model;
    this.context.commit('setLastRequestPayload', model);
    this.messageRepository.create({
      operationName: 'CreateMessageReaction',
      definition: buildMutationDefinition([
        {
          fieldName: 'reaction',
          type: 'MessageReactionEnum',
          value: reaction,
        },
        {
          fieldName: 'messageId',
          type: GqlEntityInputType.REQUIRED_ID,
          value: messageId,
        },
        {
          fieldName: 'tempId',
          type: GqlEntityInputType.REQUIRED_ID,
          value: tempId,
        },
      ]),
    })
      .then((message) => !!message);
  }

  @Action
  deleteMessage(message: string): void {
    this.context.commit('setLastRequestPayload', message);
    this.messageRepository.delete({
      operationName: 'DeleteMessage',
      definition: buildMutationDefinition([
        {
          fieldName: 'message',
          type: 'BaseInput!',
          value: { uid: message },
        },
      ]),
    });
  }

  @Action
  banUser(user: string): void {
    this.context.commit('setLastRequestPayload', user);
    this.messageRepository.update({
      operationName: 'BanUser',
      definition: buildMutationDefinition([
        {
          fieldName: 'user',
          type: 'BaseInput!',
          value: { uid: user },
        },
      ]),
    });
  }

  @Action
  unBanUser(user: string): void {
    this.context.commit('setLastRequestPayload', user);
    this.messageRepository.update({
      operationName: 'UnBanUser',
      definition: buildMutationDefinition([
        {
          fieldName: 'user',
          type: 'BaseInput!',
          value: { uid: user },
        },
      ]),
    });
  }

  @Mutation
  addReaction(payload: { reaction: Reaction; authUser: string }): void {
    const group = this.conversations
      .find((conv) => payload.reaction.message.group && payload.reaction.message.group.uid === conv.uid);
    if (group) {
      const message = group.messages.find((m) => payload.reaction.message.uid === m.uid);
      if (message) {
        if (payload.reaction.user.uid === payload.authUser) {
          const reactionIndex = message.myReactions.findIndex((r) => payload.reaction.tempReactionId === r.uid);
          if (reactionIndex > -1) {
            message.myReactions.splice(reactionIndex, 1, payload.reaction);
          } else {
            message.myReactions.push(payload.reaction);
            message.nbReactions += 1;
          }
        } else {
          message.nbReactions += 1;
        }
      }
    }
  }

  @Mutation
  deleteReaction(payload: { reaction: Reaction; authUser: string }): void {
    const group = this.conversations
      .find((conv) => payload.reaction.message.group && payload.reaction.message.group.uid === conv.uid);
    if (group) {
      const message = group.messages.find((m) => payload.reaction.message.uid === m.uid);
      if (message) {
        if (payload.reaction.user.uid === payload.authUser) {
          const reactionIndex = message.myReactions.findIndex((r) => payload.reaction.uid === r.uid);
          if (reactionIndex > -1) {
            message.myReactions.splice(reactionIndex, 1);
            message.nbReactions -= 1;
          }
        } else {
          message.nbReactions -= 1;
        }
      }
    }
  }

  @Action
  removeReaction(reactionId: string): void {
    this.context.commit('setLastRequestPayload', reactionId);
    this.messageRepository.update({
      operationName: 'RemoveMessageReaction',
      definition: buildMutationDefinition([
        {
          fieldName: 'reactionId',
          type: GqlEntityInputType.REQUIRED_ID,
          value: reactionId,
        },
      ]),
    })
      .then((message) => !!message);
  }

  @Action
  addParticipants(model: {
    usersList: Array<{ uid: string }>;
    groupId: string;
  }): Promise<boolean> {
    const {
      usersList,
      groupId,
    } = model;
    this.context.commit('setLastRequestPayload', model);
    return this.repository.create({
      operationName: 'AddParticipants',
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.REQUIRED_ID,
          value: groupId,
        },
        {
          fieldName: 'users',
          type: '[BaseInput!]!',
          value: usersList,
        },
      ]),
    })
      .then((messageGroup) => !!messageGroup);
  }

  @Action
  leaveConversation(model: {
    uid: string;
  }): Promise<boolean> {
    const { uid } = model;
    this.context.commit('setLastRequestPayload', model);
    return this.repository.update({
      operationName: 'LeaveConversation',
      definition: buildMutationDefinition([
        {
          fieldName: 'group',
          type: 'BaseInput!',
          value: {
            uid,
          },
        },
      ]),
    })
      .then((messageGroup) => !!messageGroup);
  }

  @Action
  acceptConversation(model: {
    uid: string;
  }): Promise<boolean> {
    const { uid } = model;
    this.context.commit('setLastRequestPayload', model);
    return this.repository.update({
      operationName: 'AcceptConversation',
      definition: buildMutationDefinition([
        {
          fieldName: 'group',
          type: 'BaseInput!',
          value: {
            uid,
          },
        },
      ]),
    })
      .then((messageGroup) => !!messageGroup);
  }

  @Action
  declineConversation(model: {
    uid: string;
  }): Promise<boolean> {
    const { uid } = model;
    this.context.commit('setLastRequestPayload', model);
    return this.repository.update({
      operationName: 'DeclineConversation',
      definition: buildMutationDefinition([
        {
          fieldName: 'group',
          type: 'BaseInput!',
          value: {
            uid,
          },
        },
      ]),
    })
      .then((messageGroup) => !!messageGroup);
  }

  @Action
  disconnectConversation(model: {
    uid: string;
  }): Promise<boolean> {
    const { uid } = model;
    this.context.commit('setLastRequestPayload', model);
    return this.repository.update({
      operationName: 'DisconnectConversation',
      definition: buildMutationDefinition([
        {
          fieldName: 'group',
          type: 'BaseInput!',
          value: {
            uid,
          },
        },
      ]),
    })
      .then((messageGroup) => !!messageGroup);
  }

  @Action
  typing(model: {
    event: TypingEventEnum;
    groupId: string;
  }): Promise<boolean> {
    const {
      event,
      groupId,
    } = model;
    return this.repository.update({
      operationName: 'Typing',
      definition: buildMutationDefinition([
        {
          fieldName: 'groupId',
          type: GqlEntityInputType.REQUIRED_ID,
          value: groupId,
        },
        {
          fieldName: 'event',
          type: 'TypingEventEnum!',
          value: event,
        },
      ]),
    })
      .then((messageGroup) => !!messageGroup);
  }

  @Action
  updateState(model: {
    lastReadTimestamp: number;
    hideMessageBeforeTimestamp?: number;
    uid: string;
  }): void {
    const {
      lastReadTimestamp,
      uid,
      hideMessageBeforeTimestamp,
    } = model;
    this.messageRepository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'lastReadTimestamp',
          type: 'Int!',
          value: lastReadTimestamp,
        },
        {
          fieldName: 'hideMessageBeforeTimestamp',
          type: 'Int',
          value: hideMessageBeforeTimestamp,
        },
        {
          fieldName: 'uid',
          type: GqlEntityInputType.REQUIRED_ID,
          value: uid,
        },
      ]),
    });
  }

  @Action
  reloadLastRequest(request?: ChatRequestType): Promise<void> {
    if (request) {
      return this.context.dispatch(request.action, request.payload);
    }
    return Promise.resolve();
  }

  @Action
  messageGroupLookup(payload: {
    myUid: string;
    users: CommunityUser[];
    groupType: string[];
    targetUid?: string;
  }): Promise<string | null> {
    const {
      myUid,
      users,
      groupType,
      targetUid,
    } = payload;
    let operationName: string;
    let filter: object;
    if (targetUid && users.length === 0) {
      filter = {
        target: { uid: targetUid },
        type_in: Array.isArray(groupType) ? groupType : [groupType],
      };
      operationName = 'messageGroupLookupWithTarget';
    } else if (!targetUid) {
      filter = {
        myUid,
        userUids: users.map((u) => u.uid),
        groupType_in: Array.isArray(groupType) ? groupType : [groupType],
      };
      operationName = 'messageGroupLookupWithoutTarget';
    } else {
      filter = {
        myUid,
        userUids: users.map((u) => u.uid),
        targetUid,
        groupType_in: Array.isArray(groupType) ? groupType : [groupType],
      };
      operationName = 'MessageGroupLookup';
    }
    return this.repository.get({
      operationName,
      definition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.MESSAGE_GROUP_FILTER,
        },
      }),
      authUser: this.context.rootState.authUser ? this.context.rootState.authUser.uid : '',
    })
      .then((response) => {
        if (response && response.uid) {
          return response.uid;
        }
        return null;
      });
  }

  @Action
  openSelectedConversation(payload: {
    myUid: string;
    users: CommunityUser[];
    groupType: string[];
    group?: string | null;
    targetUid?: string | null;
    targetType?: string | null;
    targetData?: object;
  }): void {
    const filter = Object.create(payload);
    if (payload.targetUid) {
      filter.users = [];
    }
    const selectedGroupUid = this.context.getters.selectedGroup?.uid;
    const chatStatus = (this.context.state as unknown as { status: MessageBoxStatus }).status;
    const chatClosed = (this.context.state as unknown as { closed: boolean }).closed;
    let runLookup: Promise<string | null>;
    if (payload.group) {
      const selectedGroup = this.conversations.find((conversation) => conversation.uid === payload.group);
      if (selectedGroup) {
        this.context.commit('setSelectedGroup', payload.group);
        this.context.commit('changeStatus', MessageBoxStatus.MESSAGES);
        return;
      }
      runLookup = Promise.resolve(payload.group);
    } else {
      runLookup = this.context.dispatch('messageGroupLookup', filter);
    }
    runLookup.then((group) => {
      if (group) {
        this.repository.get({
          operationName: 'MessageGroup',
          definition: buildQueryDefinition({
            filter: {
              value: {
                uid: group,
              },
              type: GqlEntityFilterType.MESSAGE_GROUP_FILTER,
            },
            orderBy: {
              value: ['createdTime_desc'],
              type: GqlEntityOrderingType.MESSAGE_GROUP_ORDERING,
            },
          }),
          authUser: payload.myUid,
        })
          .then((messageGroup) => {
            if (messageGroup) {
              if (group !== selectedGroupUid) {
                this.context.dispatch('updateGroup', {
                  uid: group,
                  data: messageGroup,
                });
                if (!payload.group) {
                  this.context.commit('setGroupTargetNewGroup', {
                    uid: payload.targetUid,
                    type: payload.targetType,
                  });
                }
                this.context.commit('setSelectedGroup', group);
              }
              if (chatStatus !== MessageBoxStatus.MESSAGES) {
                this.context.commit('changeStatus', MessageBoxStatus.MESSAGES);
              }
              if (chatClosed && !payload.group) {
                this.context.commit('toggleChat', false);
              }
            }
          });
      } else if (!payload.group) {
        this.context.commit('setSelectedUsersForNewGroup', payload.users
          .filter((u) => u.uid !== this.context.rootState.authUser?.uid));
        this.context.commit('setGroupTargetNewGroup', {
          uid: payload.targetUid,
          type: payload.targetType,
          data: payload.targetData || {},
        });
        this.context.commit('setSelectedGroup', null);
        this.context.commit('changeStatus', MessageBoxStatus.NEW_CONVERSATION);
        this.context.commit('toggleChat', false);
      }
    });
  }

  @Action
  toggleMuteState(payload: { uid: string; muted: boolean }): Promise<string | null> {
    return this.messageGroupStateRepository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.MESSAGE_GROUP_STATE_INPUT,
          value: payload,
        },
      ]),
    })
      .then((response) => {
        if (response) {
          this.context.commit('updateToggleState', response);
          return response.muted ? response.uid : null;
        }
        return null;
      });
  }

  @Mutation
  updateToggleState(messageGroupState: MessageGroupState): void {
    const conversation = this.conversations.find((conv) => messageGroupState.group
      && conv.uid === messageGroupState.group.uid);
    if (conversation) {
      conversation.myState = messageGroupState;
      if (messageGroupState.muted === true) {
        conversation.isMuted = messageGroupState.uid;
      } else {
        conversation.isMuted = null;
      }
    }
  }

  @Mutation
  setCharError(error: ChatError): void {
    this.chatErrors.addErrors(error);
  }

  @Mutation
  setLastRequestPayload(payload: ChatRequestPayloadType): void {
    this.lastRequestPayload = payload;
  }

  @Mutation
  cleanupConversations(): void {
    this.conversations = this.conversations.filter((conv) => !(conv.tempId === conv.uid
      && this.tempGroupId === conv.uid && conv.messages.length === 0));
    this.tempGroupId = null;
  }

  @Mutation
  cleanupTempMeetingConversations(): void {
    this.conversations = this.conversations.filter((conv) => !(conv.tempId === conv.uid
      && this.tempGroupId === conv.uid && conv.target?.__typename === 'Meeting'));
    this.tempGroupId = null;
    this.selectedUsersForNewGroup = [];
    this.tempTargetId = null;
    this.tempTargetType = null;
    this.tempTargetData = null;
  }

  @Mutation
  setSelectedUsersForNewGroup(users: CommunityUser[]): void {
    this.selectedUsersForNewGroup = users;
  }

  @Mutation
  setGroupTargetNewGroup(payload: {
    uid: string | null;
    type: string | null;
    data?: object;
  }): void {
    this.tempTargetId = payload.uid;
    this.tempTargetType = payload.type;
    this.tempTargetData = payload.data;
  }

  @Mutation
  toggleChat(close?: boolean): void {
    if (close !== undefined) {
      this.closed = close;
    } else {
      this.closed = !this.closed;
    }
  }

  @Mutation
  updateChatState(group: MessageGroup): void {
    const conversation = this.conversations.find((conv) => conv.uid === group.uid);
    if (conversation) {
      conversation.active = group.active;
    }
  }

  @Mutation
  resetCurrentGroupMessages(groupUid: string): void {
    const group = this.conversations.find((conv) => conv.uid === groupUid);
    if (group) {
      group.messages = group.lastMessage ? [group.lastMessage] : [];
      group.messagesCount = 0;
      group.messagesOffset = 0;
    }
  }

  @Mutation
  initTempGroupId(): void {
    this.tempGroupId = null;
    this.selectedUsersForNewGroup = [];
    this.tempTargetId = null;
    this.tempTargetType = null;
    this.tempTargetData = null;
  }

  @Mutation
  setGroupMessageInView(payload: { uid: string; value: boolean }): void {
    this.conversations.map((conv) => Object.assign(conv,
      { messagesInView: conv.uid === payload.uid ? payload.value : false }));
  }

  @Mutation
  setNewConversation(group: MessageGroup): void {
    this.tempGroupId = group.uid;
    const index = this.conversations.findIndex((conv) => conv.uid === this.tempGroupId);
    if (index > -1) {
      this.conversations.splice(index, 1);
    }
    this.conversations.unshift(MessageGroup.hydrate({
      ...group,
      selected: true,
    }));
  }

  @Mutation
  setSelectedGroup(groupId: string): void {
    this.conversations.map((group) => Object.assign(group, {
      selected: group.uid === groupId,
      updated: this.chatErrors.findSendMessageErrors(group.uid).length > 0 ? true : group.updated,
    }));
  }

  @Mutation
  changeStatus(status: MessageBoxStatus): void {
    if (status !== MessageBoxStatus.MESSAGES) {
      this.conversations.map((group) => Object.assign(group, { selected: false }));
    }
    if (status !== MessageBoxStatus.NEW_CONVERSATION) {
      this.selectedUsersForNewGroup = [];
      this.tempTargetId = null;
      this.tempTargetType = null;
      this.tempTargetData = null;
    }
    if (status === MessageBoxStatus.CONVERSATIONS) {
      this.conversations
        .filter((conv) => conv.uid === conv.tempId && conv.messages.length === 0)
        .forEach((conv) => {
          const foundIndex = this.conversations.findIndex((g) => g.uid === conv.uid);
          if (foundIndex > -1) {
            this.conversations.splice(foundIndex, 1);
          }
        });
    }
    this.status = status;
  }

  @Action
  updateGroup(payload: { uid: string; data: Partial<MessageGroup> }): void {
    const group = this.conversations.find((conv) => conv.uid === payload.uid);
    if (group) {
      Object.assign(group, { ...group, ...payload.data });
    } else {
      this.conversations.push(MessageGroup.hydrate(payload.data));
    }
  }

  @Mutation
  applyDeletedMessages(message: Message): void {
    if (message && message.group) {
      const group = this.conversations
        .find((conv) => message.group && message.group.uid === conv.uid);
      if (group) {
        if (group.messages.length > 0) {
          const currentMessage = group.messages.find((m) => m.uid === message.uid);
          if (currentMessage) {
            currentMessage.userDeleted = message.userDeleted;
            group.messages
              .filter((m) => m.parentMessage && m.parentMessage.uid === message.uid)
              .forEach((m) => {
                if (m.parentMessage) {
                  m.parentMessage.userDeleted = message.userDeleted;
                }
              });
          }
        }
      }
    }
  }

  @Mutation
  updateUserBanState(user: CommunityUser): void {
    this.conversations
      // eslint-disable-next-line no-underscore-dangle
      .filter((conv) => conv.target && conv.target.__typename === EntityType.SESSION)
      .forEach((conv) => {
        if (conv.users && conv.users.length > 0) {
          const foundUser = conv.users.find((u) => u.uid === user.uid);
          if (foundUser) {
            foundUser.banned = user.banned;
          }
        }
      });
  }

  @Mutation
  addMessages(payload: {
    uid: string;
    messages: Message[];
    replace?: boolean;
    reverse?: boolean;
  }): void {
    const group = this.conversations.find((conv) => conv.uid === payload.uid);
    if (group) {
      if (group.messages && group.messages.length > 0) {
        if (payload.replace) {
          group.messages = payload.messages.map((m) => Message.hydrate(m));
        } else if (payload.reverse) {
          payload.messages.map((m) => Message.hydrate(m))
            .forEach((m) => {
              const found = group.messages.find((gm) => gm.uid === m.uid);
              if (!found) {
                group.messages.unshift(m);
              }
            });
        } else {
          payload.messages.map((m) => Message.hydrate(m))
            .forEach((m) => {
              const found = group.messages.find((gm) => gm.uid === m.uid);
              if (!found) {
                group.messages.push(m);
              }
            });
        }
      }
    }
  }

  @Mutation
  initMessagesForGroup(uid: string): void {
    const group = this.conversations.find((conv) => conv.uid === uid);
    if (group) {
      if (group.messages && group.messages.length > 0) {
        group.messages = [group.messages[group.messages.length - 1]];
      }
    }
  }

  @Mutation
  setNewState(state: MessageGroupState): void {
    const group = this.conversations.find((conv) => conv.uid === state.group?.uid);
    if (group) {
      Object.assign(group, { myState: state });
    }
  }

  @Action
  updateMessage(message: Message): void {
    const group = this.conversations.find((conv) => conv.uid === message.group?.uid);
    const authUser = this.context.rootState.authUser?.uid;
    if (group) {
      group.newMessageReceived += 1;
      if (!group.selected
        && [MessageType.LINK, MessageType.REGULAR].includes(message.type as MessageType)) {
        /**
         * If you are not messages view, means you are in conversations list
         * Replace the old message you had
         * Assign the message to the lastMessageNotYours to manage your unread state
         */
        group.messages = [message];
        if (message.user && message.user.uid !== authUser) {
          group.lastMessageNotYours = message;
          group.unreadState = true;
        }
        return;
      }
      if (group.messages.length === 0) {
        /**
         * First time you receive a message
         * Assign the message to the lastMessageNotYours to manage your unread state
         */
        group.messages = [message];
        if (message.type === MessageType.REGULAR) {
          group.lastMessageNotYours = message;
        }
        if (group.selected && group.messagesInView) {
          /**
           * If you are already in the conversation
           * Update your state to be after the senttime
           */
          if (group.myState
            && group.myState.uid
            // eslint-disable-next-line no-underscore-dangle
            && (!group.target || (group.target.__typename !== EntityType.SESSION))) {
            this.context.dispatch('updateState', {
              lastReadTimestamp: getUnixTime(addSeconds(new Date(), 1)),
              uid: group.myState.uid,
            });
          }
        } else if (message.user?.uid !== authUser
          && message.type === MessageType.REGULAR
          // eslint-disable-next-line no-underscore-dangle
          && !(group.target && group.target.__typename === EntityType.SESSION)) {
          /**
           * If you are not in the conversation
           * Send sound notification
           * Put the group to unread state
           */
          if (group.myState && !group.myState.muted) {
            this.context.commit('setPlaySound', true);
          }
          group.unreadState = true;
        }
      } else {
        const index = group.messages.findIndex((m) => m.uid === message.tempId || m.uid === message.uid);
        if (index > -1) {
          /**
           * If you are the sender, your message
           * Replaced with the full message from backend
           * Update your state to be after the senttime
           */
          group.messages.splice(index, 1, message);
          if (group.myState && group.myState.uid
            // eslint-disable-next-line no-underscore-dangle
            && (!group.target || (group.target.__typename !== EntityType.SESSION))) {
            this.context.dispatch('updateState', {
              lastReadTimestamp: getUnixTime(addSeconds(new Date(), 1)),
              uid: group.myState.uid,
            });
          }
        } else {
          /**
           * If you are the receiver, but not for the first time
           * Inject the full message from backend
           * Assign the message to the lastMessageNotYours to manage your unread state
           */
          group.messages = [...group.messages, message];
          if (message.type === MessageType.REGULAR) {
            group.lastMessageNotYours = message;
          }
          if (group.selected && group.messagesInView) {
            /**
             * If you are already in the conversation
             * Update your state to be after the senttime
             */
            if (group.myState && group.myState.uid
              // eslint-disable-next-line no-underscore-dangle
              && (!group.target || (group.target.__typename !== EntityType.SESSION))) {
              this.context.dispatch('updateState', {
                lastReadTimestamp: getUnixTime(addSeconds(new Date(), 1)),
                uid: group.myState.uid,
              });
            }
          } else if (message.user?.uid !== authUser
            && message.type === MessageType.REGULAR
            // eslint-disable-next-line no-underscore-dangle
            && !(group.target && group.target.__typename === EntityType.SESSION)) {
            /**
             * If you are not in the conversation
             * Send sound notification
             * Put the group to unread state
             */
            if (group.myState && !group.myState.muted) {
              this.context.commit('setPlaySound', true);
            }
            group.unreadState = true;
          }
        }
      }
      /**
       * Always keep some amount of messages
       * each time you receive one
       */
      if (group.messagesInView
        && group.messages.length > CHAT_MESSAGES_TO_KEEP_IN_VIEW) {
        group.messages.shift();
      }
    }
  }

  @Action
  updateTypingState(eventTyping: ChatTypingEvent): void {
    if (eventTyping.user.uid !== this.context.rootState.authUser?.uid) {
      const group = this.conversations.find((conv) => conv.uid === eventTyping.groupId);
      if (group) {
        const index = group.whoTyping.findIndex((u) => u.uid === eventTyping.user.uid);
        if (index > -1 && eventTyping.event === TypingEventEnum.END_TYPING) {
          group.whoTyping.splice(index, 1);
        }
        if (index === -1 && eventTyping.event === TypingEventEnum.START_TYPING) {
          group.whoTyping.push(CommunityUser.hydrate(eventTyping.user));
        }
      }
    }
  }

  @Action
  updateBlockState(data: { type: EventEnum; user: CommunityUser }): void {
    switch (data.type) {
      case EventEnum.UNBLOCKED_USER: {
        if (this.context.rootState.authUser
          && this.context.rootState.authUser.blockedByUsers) {
          this.context.rootState.authUser.blockedByUsers = this.context.rootState.authUser.blockedByUsers
            .filter((u) => u.uid !== data.user.uid);
        }
        break;
      }
      case EventEnum.UNBLOCK_USER: {
        if (this.context.rootState.authUser
          && this.context.rootState.authUser.blockedUsers) {
          this.context.rootState.authUser.blockedUsers = this.context.rootState.authUser.blockedUsers
            .filter((u) => u.uid !== data.user.uid);
        }
        break;
      }
      case EventEnum.BLOCK_USER: {
        if (this.context.rootState.authUser) {
          const findIndex = this.context.rootState.authUser.blockedByUsers
            .findIndex((u) => u.uid === data.user.uid);
          if (findIndex === -1) {
            this.context.rootState.authUser.blockedByUsers.push(data.user);
          } else {
            this.context.rootState.authUser.blockedByUsers.splice(findIndex, 1, data.user);
          }
        }
        break;
      }
      case EventEnum.BLOCKED_USER: {
        if (this.context.rootState.authUser) {
          const findIndex = this.context.rootState.authUser.blockedUsers
            .findIndex((u) => u.uid === data.user.uid);
          if (findIndex === -1) {
            this.context.rootState.authUser.blockedUsers.push(data.user);
          } else {
            this.context.rootState.authUser.blockedUsers.splice(findIndex, 1, data.user);
          }
        }
        break;
      }
      default:
        break;
    }
  }

  @Mutation
  setPlaySound(play: boolean): void {
    this.playNewMessageSound = play;
  }

  @Mutation
  setNeedToOpenChatReceivingMessageResponse(open: boolean): void {
    this.needToOpenChatReceivingMessageResponse = open;
  }

  @Action
  events(param: { event: SubscriptionEvent | null; newSessionGroup: boolean }): void {
    const {
      event,
      newSessionGroup,
    } = param;
    const groupEvents = [
      EventEnum.BLOCKED_USER,
      EventEnum.BLOCK_USER,
      EventEnum.UNBLOCKED_USER,
      EventEnum.UNBLOCK_USER,
      EventEnum.CREATE_CONVERSATION,
      EventEnum.ADD_PARTICIPANT,
      EventEnum.DELETE_PARTICIPANT,
      EventEnum.SEND_MESSAGE,
      EventEnum.UPDATE_CONVERSATION_STATE,
      EventEnum.DECLINE_CONVERSATION,
      EventEnum.ACCEPT_CONVERSATION,
      EventEnum.DISCONNECT_CONVERSATION,
      EventEnum.SEND_MESSAGE_REACTION,
      EventEnum.REMOVE_MESSAGE_REACTION,
      EventEnum.OPEN_CHAT,
      EventEnum.CLOSE_CHAT,
      EventEnum.DELETE_MESSAGE,
      EventEnum.BAN_USER,
      EventEnum.UNBAN_USER,
    ] as EventEnum[];
    if (event && groupEvents.includes(event.type as EventEnum) && event.success) {
      switch (event.type) {
        case EventEnum.UNBLOCK_USER:
        case EventEnum.UNBLOCKED_USER:
        case EventEnum.BLOCKED_USER:
        case EventEnum.BLOCK_USER: {
          const user = CommunityUser.hydrate(event.data);
          this.context.dispatch('updateBlockState', {
            type: event.type,
            user,
          });
          break;
        }
        case EventEnum.CREATE_CONVERSATION: {
          const newGroup = MessageGroup.hydrate({
            ...event.data,
          });
          // eslint-disable-next-line max-len
          const group = this.conversations.findIndex((conv) => conv.uid === newGroup.tempId
            || conv.uid === newGroup.uid || conv.target?.uid === newGroup.target?.uid);
          if (group > -1) {
            const messages = this.conversations[group].messages || [];
            this.context.dispatch('updateGroup', {
              uid: this.conversations[group].uid,
              data: {
                ...newGroup,
                messages,
                // eslint-disable-next-line max-len
                selected: newGroup.groupType && [GroupType.DIRECT, GroupType.GROUP].includes(newGroup.groupType) ? true : this.conversations[group].selected,
                updated: true,
                messagesInView: false,
              },
            });
          } else {
            this.context.dispatch('updateGroup', {
              uid: '',
              data: {
                ...newGroup,
                selected: false,
                messagesInView: false,
                unreadState: true,
              },
            });
          }
          let connectionsLookup = Promise.resolve([] as (CommunityUser | undefined)[]);
          if (newGroup.groupType
            && [GroupType.CONNECTION, GroupType.RECONNECTION].includes(newGroup.groupType)
            && newGroup.users) {
            const authUser = this.context.rootState.authUser?.uid || '';
            // eslint-disable-next-line max-len
            connectionsLookup = Promise.all((newGroup.users || []).map((u) => this.communityUserRepository.get({
              operationName: 'connectionLookup',
              definition: buildQueryDefinition({
                filter: {
                  value: {
                    uid: u.uid,
                  },
                  type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
                },
              }),
              authUser,
            })));
          }
          const conversation = this.conversations.find((conv) => conv.uid === newGroup.uid);
          connectionsLookup.then((results) => {
            if (conversation) {
              results.forEach((user) => {
                if (user) {
                  const index = (conversation.users || []).findIndex((u) => u.uid === user.uid);
                  if (index > -1 && conversation.users) {
                    // eslint-disable-next-line no-underscore-dangle
                    conversation.users[index]._ourConnection = user._ourConnection;
                  }
                }
              });
            }
          });
          break;
        }
        case EventEnum.SEND_MESSAGE: {
          const message = Message.hydrate({ ...event.data });
          const group = this.conversations
            .find((conv) => conv.uid === message.group?.uid);
          if (group) {
            this.context.dispatch('updateMessage', message);
            if (this.needToOpenChatReceivingMessageResponse) {
              this.context.commit('setSelectedGroup', group.uid);
              this.context.commit('changeStatus', MessageBoxStatus.MESSAGES);
              this.context.commit('toggleChat', false);
              this.context.commit('setNeedToOpenChatReceivingMessageResponse', false);
            }
          } else {
            this.messageGroupRepository.get({
              operationName: 'MessageGroup',
              definition: buildQueryDefinition({
                filter: {
                  value: { uid: message.group?.uid },
                  type: GqlEntityFilterType.MESSAGE_GROUP_FILTER,
                },
              }),
              authUser: this.context.rootState.authUser?.uid,
            })
              .then((messageGroup) => {
                if (messageGroup) {
                  this.context.dispatch('updateGroup', {
                    uid: messageGroup.uid,
                    data: {
                      ...messageGroup,
                      lastMessageNotYours: messageGroup.messages[0],
                    },
                  })
                    .then(() => {
                      if (this.needToOpenChatReceivingMessageResponse) {
                        this.context.commit('setSelectedGroup', messageGroup.uid);
                        this.context.commit('changeStatus', MessageBoxStatus.MESSAGES);
                        this.context.commit('toggleChat', false);
                        this.context.commit('setNeedToOpenChatReceivingMessageResponse', false);
                      }
                    });
                }
              });
          }
          break;
        }
        case EventEnum.UPDATE_CONVERSATION_STATE: {
          const state = event.data as unknown as MessageGroupState;
          this.context.commit('setNewState', state);
          break;
        }
        case EventEnum.ADD_PARTICIPANT: {
          const group = (event.data as unknown as MessageGroup);
          if (group.users?.length) {
            const authUserId = this.context.rootState.authUser?.uid;

            if (!group || !group?.users) {
              return;
            }

            const currentUserAdded = group.users
              .find((item) => item.uid === authUserId);
            const groupIndex = this.conversations
              .findIndex((item) => item.uid === group.uid);

            if (newSessionGroup && currentUserAdded) {
              this.context.commit('ChatDispatcherStore/sessionUnsubscribe', undefined, { root: true });
            }

            if (groupIndex < 0) {
              // eslint-disable-next-line prefer-destructuring
              group.lastMessageNotYours = group.messages ? group.messages[0] : undefined;
              group.unreadState = group.messagesInView ? false : checkUnreadState(group);
              this.conversations.unshift(MessageGroup.hydrate(group));
            } else {
              Object.assign(this.conversations[groupIndex],
                { groupType: group.groupType });
              this.conversations[groupIndex].users = group
                .users?.map((u) => CommunityUser.hydrate(u));
              this.conversations[groupIndex].inactiveUsers = group
                .inactiveUsers?.map((u) => CommunityUser.hydrate(u));
            }
          }
          break;
        }
        case EventEnum.DELETE_PARTICIPANT: {
          const group = (event.data as unknown as MessageGroup);
          if (group.inactiveUsers?.length) {
            const authUserId = this.context.rootState.authUser?.uid;
            const currentUserRemoved = group.inactiveUsers
              .find((item) => item.uid === authUserId);
            const groupIndex = this.conversations
              .findIndex((item) => item.uid === group.uid);
            if (currentUserRemoved && groupIndex >= 0) {
              this.conversations.splice(groupIndex, 1);
              this.context.commit('changeStatus', MessageBoxStatus.CONVERSATIONS);
            } else if (groupIndex >= 0) {
              this.conversations[groupIndex].users = group
                .users?.map((u) => CommunityUser.hydrate(u));
              this.conversations[groupIndex].inactiveUsers = group
                .inactiveUsers.map((u) => CommunityUser.hydrate(u));
            }
          }
          break;
        }
        case EventEnum.ACCEPT_CONVERSATION: {
          const newGroup = MessageGroup.hydrate({
            ...event.data,
          });
          if (newGroup && !newGroup.target) {
            const index = this.conversations.findIndex((conv) => conv.uid === newGroup.uid);
            if (index > -1) {
              const messages = this.conversations[index].messages || [];
              this.context.dispatch('updateGroup', {
                uid: this.conversations[index].uid,
                data: {
                  ...newGroup,
                  messages,
                  selected: this.conversations[index].selected,
                  updated: this.conversations[index].updated,
                  messagesInView: this.conversations[index].messagesInView,
                },
              });
            } else {
              this.conversations.unshift(newGroup);
              this.context.dispatch(
                'allMessages',
                { group: { uid: newGroup.uid } },
              );
            }
          }
          break;
        }
        case EventEnum.DECLINE_CONVERSATION: {
          const newGroup = MessageGroup.hydrate({
            ...event.data,
          });
          if (newGroup && !newGroup.target) {
            const index = this.conversations.findIndex((conv) => conv.uid === newGroup.uid);
            if (index > -1) {
              if (this.conversations[index].selected) {
                this.context.commit('changeStatus', MessageBoxStatus.CONVERSATIONS);
              }
              if (newGroup.groupType === GroupType.CONNECTION) {
                this.conversations.splice(index, 1);
              } else {
                this.conversations[index].groupType = newGroup.groupType;
              }
            }
          }
          break;
        }
        case EventEnum.DISCONNECT_CONVERSATION: {
          const newGroup = MessageGroup.hydrate({
            ...event.data,
          });
          if (newGroup && !newGroup.target) {
            const index = this.conversations.findIndex((conv) => conv.uid === newGroup.uid);
            if (index > -1) {
              const messages = this.conversations[index].messages || [];
              this.context.dispatch('updateGroup', {
                uid: this.conversations[index].uid,
                data: {
                  ...newGroup,
                  messages,
                  selected: this.conversations[index].selected,
                  updated: this.conversations[index].updated,
                  messagesInView: false,
                },
              });
            }
          }
          break;
        }
        case EventEnum.SEND_MESSAGE_REACTION: {
          const reaction = Reaction.hydrate(event.data);
          this.context.commit('addReaction', {
            reaction,
            authUser: this.context.rootState.authUser?.uid,
          });
          break;
        }
        case EventEnum.REMOVE_MESSAGE_REACTION: {
          const reaction = Reaction.hydrate(event.data);
          this.context.commit('deleteReaction', {
            reaction,
            authUser: this.context.rootState.authUser?.uid,
          });
          break;
        }
        case EventEnum.CLOSE_CHAT:
        case EventEnum.OPEN_CHAT: {
          const group = MessageGroup.hydrate(event.data);
          this.context.commit('updateChatState', group);
          break;
        }
        case EventEnum.DELETE_MESSAGE: {
          const message = Message.hydrate(event.data);
          this.context.commit('applyDeletedMessages', message);
          break;
        }
        case EventEnum.BAN_USER:
        case EventEnum.UNBAN_USER: {
          const user = CommunityUser.hydrate(event.data);
          this.context.commit('updateUserBanState', user);
          // the case below covers when the user is banned and is in a context
          // that the conversation is not created yet, like a session chat
          if (this.context.rootState.authUser?.uid === user.uid) {
            this.context.commit('updateBannedState', user.banned, { root: true });
          }
          break;
        }
        default:
          break;
      }
    } else if (event
      && !event.success
      // eslint-disable-next-line no-underscore-dangle
      && event.data.__typename === 'EventErrorOutput') {
      const payload = this.lastRequestPayload;
      switch (event.type) {
        case EventEnum.CREATE_CONVERSATION: {
          const error = {
            type: ChatErrorType.CREATE_CONVERSATION,
            view: ChatErrorView.MESSAGES,
            group: this.selectedGroup?.uid as string,
            request: {
              action: 'createConversation',
              payload,
            },
          };
          if (!this.chatErrors.findError(error)) {
            this.chatErrors.addErrors(error);
          }
          break;
        }
        case EventEnum.SEND_MESSAGE: {
          if (event.data.errorType === EventEnum.CHAT_CLOSED) {
            const requestPayload = payload as Record<string, string>;
            const closedMessageGroup = this.conversations.find((conv) => conv.uid === requestPayload.groupId);
            if (closedMessageGroup && closedMessageGroup.messages.length > 0) {
              closedMessageGroup.active = false;
              closedMessageGroup.messages.pop();
            }
          } else {
            const error = {
              type: ChatErrorType.SEND_MESSAGE,
              view: ChatErrorView.MESSAGES,
              group: this.selectedGroup?.uid as string,
              request: {
                action: 'sendMessage',
                payload,
              },
            };
            if (!this.chatErrors.findError(error)) {
              this.chatErrors.addErrors(error);
            }
          }
          break;
        }
        case EventEnum.ADD_PARTICIPANT: {
          if (payload && 'groupId' in (payload as Record<string, string>)) {
            this.context.dispatch('ToastStore/addNewAction', {
              type: ToastActionType.CHAT_ACTION_ERROR,
              delay: 3500,
              retryCallback: () => {
                this.context.dispatch('reloadLastRequest', {
                  action: 'addParticipants',
                  payload,
                });
              },
            }, { root: true });
          }
          break;
        }
        case EventEnum.DELETE_PARTICIPANT: {
          if (payload && 'uid' in (payload as Record<string, string>)) {
            this.context.dispatch('ToastStore/addNewAction', {
              type: ToastActionType.CHAT_ACTION_ERROR,
              delay: 3500,
              retryCallback: () => {
                this.context.dispatch('reloadLastRequest', {
                  action: 'leaveConversation',
                  payload,
                });
              },
            }, { root: true });
          }
          break;
        }
        default:
          break;
      }
    }
  }

  @Action
  onConnected(): void {
    this.chatErrors.removeErrors({
      view: ChatErrorView.BOTH,
      type: ChatErrorType.SERVER,
      group: 'ALL',
      request: null,
    });
  }

  @Action
  onReconnected(): void {
    if (this.serverDisconnectTimeOut) {
      clearTimeout(this.serverDisconnectTimeOut);
    }
    this.chatErrors.removeErrors({
      view: ChatErrorView.BOTH,
      type: ChatErrorType.SERVER,
      group: 'ALL',
      request: null,
    });
  }

  @Action
  onDisconnected(): void {
    const timeoutId = setTimeout(() => {
      this.chatErrors.addErrors({
        view: ChatErrorView.BOTH,
        type: ChatErrorType.SERVER,
        group: 'ALL',
        request: null,
      });
    }, 3000);
    this.context.commit('setServerDisconnectTimeOut', timeoutId);
  }

  @Action
  eventsTyping(eventsTyping: ChatTypingEvent | null): void {
    if (eventsTyping && eventsTyping.user && eventsTyping.groupId && eventsTyping.event) {
      this.context.dispatch('updateTypingState', eventsTyping);
    }
  }

  @Mutation
  setElements(payload: {
    groups: MessageGroup[];
    reset: boolean;
    authUser: string;
  }): void {
    if (payload.reset) {
      this.conversations = payload.groups.map((g) => MessageGroup.hydrate(g));
    } else {
      payload.groups.forEach((group) => {
        const existedGroup = this.conversations.find((conv) => conv.uid === group.uid);
        if (!existedGroup) {
          this.conversations.push(MessageGroup.hydrate(group));
        }
      });
    }
    this.conversations.forEach((group) => {
      if (group.messages.length > 0) {
        group.lastMessageNotYours = group.messages.find((m) => m.user?.uid !== payload.authUser);
      }
    });
  }

  @Mutation
  setConversationWithTarget(group: MessageGroup): void {
    // eslint-disable-next-line no-nested-ternary
    group.messages = group.messages.sort((d1, d2) => (d1.senttime
      && d2.senttime
      && new Date(d1.senttime).getTime() >= new Date(d2.senttime).getTime()
      ? 1
      : -1
    ));
    const foundIndex = this.conversations.findIndex((conv) => conv.uid === group.uid);
    if (foundIndex > -1) {
      const {
        selected,
        messages,
      } = this.conversations[foundIndex];
      Object.assign(group, {
        selected,
        messages,
      });
      this.conversations.splice(foundIndex,
        1,
        MessageGroup.hydrate(group));
    } else {
      this.conversations.unshift(MessageGroup.hydrate(group));
    }
  }

  @Mutation
  setServerDisconnectTimeOut(timeoutId: NodeJS.Timeout): void {
    this.serverDisconnectTimeOut = timeoutId;
  }

  @Mutation
  setLoadMoreGroups(val: boolean): void {
    this.loadMoreGroups = val;
  }

  @Mutation
  setGroupsSearchQuery(val: string): void {
    this.groupsSearchQuery = val;
  }

  @Mutation
  setValidateGroupFn(fnValidation: ((group: MessageGroup) => boolean) | null): void {
    this.validateGroupFn = fnValidation;
  }

  @Mutation
  setLastMessageByGroup(payload: { response: MessageGroup[]; authUser: string }): void {
    payload.response.forEach((item) => {
      const find = this.conversations.find((conv) => conv.uid === item.uid);
      if (find && item.messages.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        find.lastMessageNotYours = item.messages[0];
        find.unreadState = find.messagesInView ? false : checkUnreadState(find);
      }
    });
  }

  @Mutation
  updateMessageGroupTarget(meeting: Meeting): void {
    this.conversations.forEach((messageGroup: MessageGroup) => {
      if (meeting.startTimestamp && meeting.endTimestamp && messageGroup.target?.uid === meeting.uid) {
        const target = (messageGroup.target as Meeting);
        target.subject = meeting.subject;
        target.startTimestamp = getUnixTime(DateTimeHelper.toLocal(fromUnixTime(meeting.startTimestamp)));
        target.endTimestamp = getUnixTime(DateTimeHelper.toLocal(fromUnixTime(meeting.endTimestamp)));
        messageGroup.target = target;
      }
    });
  }

  @Mutation
  private clearCache(): void {
    this.conversations = [];
    this.groupsOffset = 0;
    this.loadMoreGroups = true;
    this.groupsSearchQuery = '';
    this.validateGroupFn = null;
    this.messageRepository = new MessageRepository();
    this.messageGroupRepository = new MessageGroupRepository();
    this.selectedUsersForNewGroup = [];
    this.tempTargetId = null;
    this.tempTargetType = null;
    this.tempTargetData = null;
    this.status = MessageBoxStatus.CONVERSATIONS;
    this.readStatus = MessageBoxReadStatus.READ;
    this.chatErrors = new ChatErrorList();
    this.playNewMessageSound = false;
    this.closed = true;
  }
}
