



































import { Component, Prop, Vue } from 'vue-property-decorator';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import EntityType from '@/utils/enums/EntityType';

@Component({
  components: { AvatarSoloWidget },
})
export default class SoloDoubleAvatarComponent extends Vue {
  @Prop({ required: true, default: () => [] })
  private readonly images!: string[];

  @Prop({ required: true, default: () => [] })
  private readonly names!: Array<{ firstName: string; lastName: string }>;

  @Prop({ required: false, default: EntityType.USER })
  private readonly type!: string;

  private EntityType = EntityType;
}
