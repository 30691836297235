










import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import Event from '@/utils/types/Event';
import MeetingDetail from '@/components/toolbox/agenda/detail/MeetingDetail.vue';
import SessionDetail from '@/components/toolbox/agenda/detail/SessionDetail.vue';
import EntityType from '@/utils/enums/EntityType';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    MeetingDetail,
    SessionDetail,
  },
})
/* eslint-disable max-len */
export default class DetailView extends BreakpointWrapper {
  @agendaStore.Getter
  private readonly fetchEventDetail!: Event;

  private EntityType = EntityType;
}
