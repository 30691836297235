import BaseModel from '@/models/BaseModel';
import 'regenerator-runtime';
import GraphQlClients from '@/utils/enums/GraphQlClients';
import SubscriptionApolloClient from '@/plugins/apollo/SubscriptionApolloClient';
import { NormalizedCacheObject } from '@apollo/client';
import SubscriptionParams from '@/utils/types/SubscriptionParams';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';

/* eslint-disable max-len */
export default abstract class SubscriptionBaseRepository<Model extends BaseModel>
  extends GraphQlRepository<Model> {
  // eslint-disable-next-line class-methods-use-this
  protected get headers(): object {
    const clients = [GraphQlClients.NOTIFICATION_CLIENT, GraphQlClients.CHAT_CLIENT];
    if (clients.includes(this.clientName as GraphQlClients)) {
      return {
        Authorization: `Bearer ${this.authToken}`,
      };
    }

    return super.headers;
  }

  abstract eventSubscribe(payload: SubscriptionParams): Promise<{ unsubscribe: () => void }>;

  onConnected(callback: () => void): void {
    (this.client as unknown as SubscriptionApolloClient<NormalizedCacheObject>).onConnected(callback);
  }

  onDisconnected(callback: () => void): void {
    (this.client as unknown as SubscriptionApolloClient<NormalizedCacheObject>).onDisconnected(callback);
  }

  onReconnected(callback: () => void): void {
    (this.client as unknown as SubscriptionApolloClient<NormalizedCacheObject>).onReconnected(callback);
  }
}
