import {
  addMinutes, format, fromUnixTime, Locale, subMinutes,
} from 'date-fns';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';

export default class DateTimeHelper {
  static readonly TIME_FORMAT_ISO_8601 = 'yyyy-MM-dd\'T\'HH:mm:ss';

  static get currentTimestamp(): number {
    return Math.round(Date.now() / 1000);
  }

  static getCurrentDateTime(): Date {
    return new Date();
  }

  static getTimeZoneAbbreviation(timeZoneName: string, lang = 'en'): string {
    return DateTimeHelper.getFormattedElement(timeZoneName, 'timeZoneName', 'short', lang);
  }

  static toISO8601(date: Date): string {
    return format(date, DateTimeHelper.TIME_FORMAT_ISO_8601);
  }

  static toISO8601UTC(date?: Date): string {
    const d = (date || new Date()).toISOString();
    return d.substr(0, d.lastIndexOf('.'));
  }

  static equalLocalizedDay(utcDate1: Date, utcDate2: Date): boolean {
    const date1 = format(utcDate1, 'dd-MM-yyyy');
    const date2 = format(utcDate2, 'dd-MM-yyyy');
    return date1 === date2;
  }

  static formatFromUnixTime(unix: number, stringFormat: string, locale: Locale): string {
    return format(fromUnixTime(unix), stringFormat, { locale });
  }

  static toUTC(date: Date, offset?: number | null): Date {
    if (offset) {
      return Math.sign(offset) === -1
        ? addMinutes(date, Math.abs(offset))
        : subMinutes(date, offset);
    }
    const currentOffset = date.getTimezoneOffset();
    return Math.sign(currentOffset) !== -1
      ? addMinutes(date, currentOffset)
      : subMinutes(date, Math.abs(currentOffset));
  }

  static toLocal(date: Date, offset?: number | null): Date {
    if (offset) {
      return Math.sign(offset) === -1
        ? subMinutes(date, Math.abs(offset))
        : addMinutes(date, offset);
    }
    const currentOffset = date.getTimezoneOffset();
    return Math.sign(currentOffset) === -1 ? addMinutes(date, currentOffset) : subMinutes(date, Math.abs(currentOffset));
  }

  static currentTimeZone(): string {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return timeZone;
  }

  static roundToNearest15 = (date = new Date()): Date => {
    const minutes = 15;
    const ms = 1000 * 60 * minutes;

    return new Date(Math.round(date.getTime() / ms) * ms);
  }

  static utcToZonedTimeDate(date: Date | number | string, timeZoneName: string): Date {
    return utcToZonedTime(
      date,
      timeZoneName,
    );
  }

  static zonedToUTCTimeDate(date: Date, timeZoneName: string): Date {
    return DateTimeHelper.toUTC(
      date,
      DateTimeHelper.timeZoneOffset(
        date,
        timeZoneName,
      ),
    );
  }

  static timeZoneOffset(date: Date, timeZoneName: string): number {
    return getTimezoneOffset(
      timeZoneName,
      date,
    ) / 60000;
  }

  private static getFormattedElement(
    timeZone: string,
    name: string,
    value: string,
    lang: string | null,
  ): string {
    const result = (new Intl.DateTimeFormat(lang || 'en', {
      [name]: value,
      timeZone,
    }).formatToParts().find((el) => el.type === name) || {}).value;

    return result || '';
  }
}
