import GqlFieldInterface from '@/graphql/_Tools/type/GqlFieldInterface';

export default class GqlFieldType<VALUE> implements GqlFieldInterface<VALUE> {
  value?: VALUE | null;

  type?: string | null;

  protected readonly fieldName!: string;

  protected constructor(seed: GqlFieldInterface<VALUE>, fieldName: string) {
    this.value = seed.value;
    this.type = seed.type;
    this.fieldName = fieldName;
  }

  get isValid(): boolean {
    return !!(
      this.value
      && ((typeof this.value === 'object' && Object.keys(this.value).length)
        || typeof this.value !== 'object')
      && this.type
    );
  }

  get variables(): object {
    return { [this.fieldName]: this.value };
  }

  get declaration(): string {
    return `$${this.fieldName}: ${this.type}`;
  }

  get parameter(): string {
    return `${this.fieldName}: $${this.fieldName}`;
  }

  static hydrate<TYPE>(seed: object, fieldName: string): GqlFieldType<TYPE> {
    return new GqlFieldType(seed, fieldName);
  }

  static init<TYPE>(
    seed: GqlFieldInterface<TYPE> | null | undefined,
    fieldName: string,
  ): GqlFieldType<TYPE> | null {
    if (!seed) {
      return null;
    }

    const result = new GqlFieldType(seed, fieldName);
    return result.isValid ? result : null;
  }
}
