import _Vue from 'vue';
import LoggerInterface from '@/utils/logger/LoggerInterface';
import CommunityUser from '@/models/graphql/CommunityUser';

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle */
export default class Logger implements LoggerInterface {
  // eslint-disable-next-line class-methods-use-this
  log(params: unknown[]): void {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
    if (window._paq) {
      window._paq.push(params);
    }
  }

  // eslint-disable-next-line max-params
  logMatomoStats(
    authUser: CommunityUser,
    communityCode: string,
    entityType: string,
    action: string,
    name: string,
    id: number,
    uid: string,
    category: string,
    locale: string,
    count?: number,
  ): void {
    this.log(['trackEvent', category, action, name, count, {
      ...this.serialiseAuthUserDimensions(authUser, communityCode, entityType),
      dimension2: locale,
      dimension6: id,
      dimension8: uid,
    }]);
  }

  // eslint-disable-next-line class-methods-use-this
  serialiseAuthUserDimensions(
    authUser: CommunityUser, communityCode: string, entityType: string,
  ): object {
    if (authUser) {
      return {
        dimension1: communityCode,
        dimension4: authUser.uid,
        dimension5: authUser && authUser.uid ? 'user' : 'guest',
        dimension7: communityCode,
        dimension9: entityType,
      };
    }
    return {
      dimension1: communityCode,
      dimension7: communityCode,
      dimension9: entityType,
    };
  }
}

export function logger(vue: typeof _Vue): void {
  vue.prototype.$logger = new Logger();
}
