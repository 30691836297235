










































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import Notification from '@/models/graphql/Notification';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import { RawLocation } from 'vue-router';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
  format,
  fromUnixTime,
} from 'date-fns';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import GroupType from '@/utils/enums/chat/GroupType';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';

const communityUserConnectionStore = namespace('CommunityUserConnectionStore');

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    AvatarSoloWidget,
  },
})
export default class NotificationListConnectionItemComponent extends Vue {
  @Getter
  private readonly community!: Community;

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @State
  private dateLocale!: Locale;

  @Prop({ required: true })
  private notification!: Notification;

  @communityUserConnectionStore.Action
  private fullyAcceptConnection!: (payload: {
    uid: string; messageLookupArgs: object; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private fullyDeclineConnection!: (payload: {
    uid: string; messageLookupArgs: object; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  private get isInvited(): boolean {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.CONNECTION) {
      const connection = this.notification.triggered as CommunityUserConnection;
      return !!(connection
              && connection.linkState
              && connection.linkState === ConnectionStatusType.INVITED)
          && this.featureByKey(FeatureKeys.COMMUNITY_CONNECTIONS_FEATURE)
          && this.featureByKey(FeatureKeys.COMMUNITY_CONNECTIONS_FEATURE).enabled;
    }
    return false;
  }

  private get isAccepted(): boolean {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.CONNECTION) {
      const connection = this.notification.triggered as CommunityUserConnection;
      return !!(connection
          && connection.linkState
          && connection.linkState === ConnectionStatusType.ACCEPTED);
    }
    return false;
  }

  private get isDeclined(): boolean {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.CONNECTION) {
      const connection = this.notification.triggered as CommunityUserConnection;
      return !!(connection
              && connection.linkState
              && connection.linkState === ConnectionStatusType.DECLINED)
          && this.featureByKey(FeatureKeys.COMMUNITY_CONNECTIONS_FEATURE)
          && this.featureByKey(FeatureKeys.COMMUNITY_CONNECTIONS_FEATURE).enabled;
    }
    return false;
  }

  private get isItForYou(): boolean {
    return !!(this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.CONNECTION
        && this.notification.notifiedUsers.findIndex((n) => n.uid === this.authUser.uid) > -1);
  }

  private get image(): string | null {
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated) {
        return hydrated.profilePicture;
      }
    }
    return null;
  }

  private get fullName(): string {
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated) {
        return hydrated.fullName;
      }
    }
    return '';
  }

  private get firstName(): string {
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated && hydrated.firstName) {
        return hydrated.firstName;
      }
    }
    return '';
  }

  private get lastName(): string {
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated && hydrated.lastName) {
        return hydrated.lastName;
      }
    }
    return '';
  }

  private get navigateTo(): RawLocation {
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated) {
        return {
          name: 'member-detail',
          params: { memberId: hydrated.uid },
        };
      }
    }
    return '';
  }

  private get content(): string {
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
      return this.isAccepted
        ? `${this.$t('my-notification-list-component.connection.request-accepted')}`
        : `${this.$t('my-notification-list-component.connection.request-invited')}`;
    }
    return '';
  }

  private get dateReceived(): string {
    const today = DateTimeHelper.getCurrentDateTime();
    const createdTimeDate = fromUnixTime(this.notification.createdTimestamp as number);
    if (differenceInSeconds(today, createdTimeDate) < 60) {
      return `${this.$t('my-notification-list-component.time-ago.now')}`;
    }

    const diffInMinutes = differenceInMinutes(today, createdTimeDate);
    if (diffInMinutes < 60) {
      return `${this.$t('my-notification-list-component.time-ago.minutes', {
        number: diffInMinutes,
      })}`;
    }

    const diffInDays = differenceInDays(today, createdTimeDate);
    if (diffInDays === 1) {
      return ` ${this.$t('my-notification-list-component.time-ago.yesterday')}`;
    }
    if (diffInDays < 1) {
      return `${this.$t('my-notification-list-component.time-ago.hours', {
        number: differenceInHours(today, createdTimeDate),
      })}`;
    }
    if (diffInDays < 7) {
      return `${this.$t('my-notification-list-component.time-ago.days', { number: diffInDays })}`;
    }

    if (differenceInYears(today, createdTimeDate) === 0) {
      return format(createdTimeDate, `${this.$t('app.date.monthDayShort')}`, {
        locale: this.dateLocale,
      });
    }
    return format(createdTimeDate, `${this.$t('app.date.defaultDateFormat')}`, {
      locale: this.dateLocale,
    });
  }

  private closeNotificationModal(): void {
    this.$emit('on-close-notification');
  }

  private onInteractedWith(close = true): void {
    if (close) {
      this.closeNotificationModal();
    }
    if (!this.notification.interactedWith) {
      this.$emit('on-interacted-with', this.notification.uid);
    }
  }

  private onDelete(): void {
    this.$emit('on-delete', this.notification.uid);
  }

  private onAccept(): void {
    this.onInteractedWith(false);
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.CONNECTION) {
      this.$set(this.notification.triggered, 'linkState', ConnectionStatusType.ACCEPTED);
      const initiator = CommunityUser.hydrate(this.notification.initiator);
      const auth = (({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }) => ({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }))(this.authUser) as CommunityUser;
      this.fullyAcceptConnection({
        uid: this.notification.triggered.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: initiator.uid,
            firstName: initiator.firstName,
            lastName: initiator.lastName,
            pictureFileResource: initiator.pictureFileResource,
          } as CommunityUser],
          groupType: [GroupType.CONNECTION, GroupType.RECONNECTION],
        },
        toastUserFullName: this.fullName,
      })
        .catch(() => {
          if (this.notification
                && this.notification.triggered
                // eslint-disable-next-line no-underscore-dangle
                && this.notification.triggered.__typename === EntityType.USER) {
            this.$set(this.notification.triggered, 'linkState', ConnectionStatusType.INVITED);
          }
        });
    }
  }

  private onDecline(): void {
    this.onInteractedWith(false);
    if (this.notification
        && this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.CONNECTION) {
      this.$set(this.notification.triggered, 'linkState', ConnectionStatusType.DECLINED);
      const initiator = CommunityUser.hydrate(this.notification.initiator);
      const auth = (({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }) => ({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }))(this.authUser) as CommunityUser;
      this.fullyDeclineConnection({
        uid: this.notification.triggered.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: initiator.uid,
            firstName: initiator.firstName,
            lastName: initiator.lastName,
            pictureFileResource: initiator.pictureFileResource,
          } as CommunityUser],
          groupType: [GroupType.CONNECTION, GroupType.RECONNECTION],
        },
        toastUserFullName: this.fullName,
      })
        .catch(() => {
          if (this.notification
                && this.notification.triggered
                // eslint-disable-next-line no-underscore-dangle
                && this.notification.triggered.__typename === EntityType.USER) {
            this.$set(this.notification.triggered, 'linkState', ConnectionStatusType.INVITED);
          }
        });
    }
  }
}
