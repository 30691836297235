import { render, staticRenderFns } from "./SignInPromptComponent.vue?vue&type=template&id=47a46faa&scoped=true&"
import script from "./SignInPromptComponent.vue?vue&type=script&lang=ts&"
export * from "./SignInPromptComponent.vue?vue&type=script&lang=ts&"
import style0 from "../assets/styles/SignInPromptModal.scss?vue&type=style&index=0&id=47a46faa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a46faa",
  null
  
)

export default component.exports