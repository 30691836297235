import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import RootState from '@/store/states/RootState';

interface EntitySearch {
  isLoading: boolean;
}

@Module({ namespaced: true })
export default class EntitySearchStore extends VuexModule<EntitySearch, RootState> {
  isLoading = false;

  empty = false;

  items: object[] = [];

  filter = {};

  @Mutation
  setLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  @Mutation
  setItems(items: object[]): void {
    this.items = items;
    this.empty = !items.length;
  }

  @Mutation
  setEmpty(empty: boolean): void {
    this.empty = empty;
  }

  @Mutation
  setQueryFilter(filter: object): void {
    this.filter = filter;
    this.empty = false;
    this.isLoading = false;
  }

  get isEmpty(): boolean {
    return this.empty;
  }
}
