import getContext from '@/plugins/locale/LocaleLoader';

interface Context {
  keys(): string[];
  (key: string): { };
}

class LocaleMessageRepository {
  readonly locales: Context;

  constructor() {
    this.locales = getContext();
  }

  all(): Promise<Record<string, object>> {
    const messages: Record<string, object> = { };

    this.locales.keys().forEach((key: string) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = this.locales(key);
      }
    });

    return new Promise<{ }>((resolve: (value: object) => void): void => resolve(messages));
  }

  async get(lang: string): Promise<object> {
    const all = await this.all();

    return new Promise<object>((resolve: (value: object) => void): void => resolve(all[lang]));
  }
}

export default LocaleMessageRepository;
