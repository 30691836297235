/* eslint-disable max-len,@typescript-eslint/camelcase  */
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import FeedPost from '@/models/graphql/FeedPost';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import WriteInterface from '@/repositories/base/WriteInterface';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GetterInterface from '@/repositories/base/GetterInterface';

export default class FeedPostRepository extends GraphQlRepository<FeedPost> implements GetterInterface<FeedPost>, WriteInterface<FeedPost> {
  get(params: GqlPayloadParams): Promise<FeedPost | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetFeedPosts',
      operation: 'feedPost',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'feedPostFullFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ feedPost: FeedPost[] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ feedPost: FeedPost[] }>) => response.data?.feedPost[0]));
  }

  create(params: GqlPayloadParams): Promise<FeedPost | undefined> {
    switch (params.operationName) {
      case 'createPost':
        return buildMutationGql({
          operationName: 'createPost',
          operation: 'FeedPostCreateForInitiatorAndAuthorUser',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'feedPostCreateFragment',
          alias: 'NewFeedPost',
        })
          .then((mutation) => this.mutate<{
              NewFeedPost: FeedPost;
            }, {}>(mutation, params.definition.variables))
          .then((response: FetchResult<{
            NewFeedPost: FeedPost;
          }>) => response.data?.NewFeedPost);

      case 'createPostForTopic':
        return buildMutationGql({
          operationName: 'createPostForTopic',
          operation: 'FeedPostCreateForInitiatorAndAuthorUserAndTopic',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'feedPostCreateFragment',
          alias: 'NewFeedPost',
        })
          .then((mutation) => this.mutate<{
            NewFeedPost: FeedPost;
          }, {}>(mutation, params.definition.variables))
          .then((response: FetchResult<{
            NewFeedPost: FeedPost;
          }>) => response.data?.NewFeedPost);

      case 'createPostForChannel':
        return buildMutationGql({
          operationName: 'createPostForChannel',
          operation: 'FeedPostCreateForInitiatorAndAuthorUserAndChannel',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'feedPostCreateFragment',
          alias: 'NewFeedPost',
        })
          .then((mutation) => this.mutate<{
                NewFeedPost: FeedPost;
              }, {}>(mutation, params.definition.variables))
          .then((response: FetchResult<{
                NewFeedPost: FeedPost;
              }>) => response.data?.NewFeedPost);

      case 'repostPostToFeed':
        return buildMutationGql({
          operationName: 'repostPostToFeed',
          operation: 'FeedPostCreateForInitiatorAndRepostPostAndAuthorUser',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'feedPostUidFragment',
          authUser: params.authUser,
        })
          .then((mutation) => this.mutate<{
            FeedPostRepostCreateForFeed: FeedPost;
          }, {}>(mutation, params.definition.variables))
          .then((response: FetchResult<{
            FeedPostRepostCreateForFeed: FeedPost;
          }>) => response.data?.FeedPostRepostCreateForFeed);

      case 'repostPostToTopic':
        return buildMutationGql({
          operationName: 'repostPostToTopic',
          operation: 'FeedPostCreateForInitiatorAndRepostPostAndAuthorUserAndTopic',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'feedPostUidFragment',
        })
          .then((mutation) => this.mutate<{
            FeedPostRepostCreateForFeedAndTopic: FeedPost;
          }, {}>(mutation, params.definition.variables))
          .then((response: FetchResult<{
            FeedPostRepostCreateForFeedAndTopic: FeedPost;
          }>) => response.data?.FeedPostRepostCreateForFeedAndTopic);

      default:
        return Promise.resolve(undefined);
    }
  }

  delete(params: GqlPayloadParams): Promise<FeedPost | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'deletePost',
      operation: 'FeedPostDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'feedPostUidFragment',
      authUser: params.authUser,
    }).then((mutation) => this.mutate<{ FeedItemWrapperRemoveFeedItem: FeedPost }, {}>(mutation, params.definition.variables))
      .then((response: FetchResult<{ FeedItemWrapperRemoveFeedItem: FeedPost }>) => response.data?.FeedItemWrapperRemoveFeedItem);
  }

  // eslint-disable-next-line class-methods-use-this
  update(params: GqlPayloadParams): Promise<FeedPost | undefined> {
    switch (params.operationName) {
      case 'updatePost':
        return buildMutationGql({
          operationName: 'updatePost',
          operation: 'FeedPostUpdate',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'feedPostUpdateFragment',
          authUser: params.authUser,
        }).then((mutation) => this.mutate<{ FeedPostUpdate: FeedPost }, {}>(mutation, params.definition.variables))
          .then((response: FetchResult<{ FeedPostUpdate: FeedPost }>) => response.data?.FeedPostUpdate);

      case 'LikeFeedPost':
        return buildMutationGql({
          operationName: 'LikeFeedPost',
          operation: 'FeedPostAddLikeUser',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'feedPostBaseFragment',
          authUser: params.authUser,
        }).then((mutation) => this.mutate<{ FeedPostAddLikeUser: FeedPost }, {}>(mutation, params.definition.variables))
          .then((response: FetchResult<{ FeedPostAddLikeUser: FeedPost }>) => response.data?.FeedPostAddLikeUser);

      case 'DislikeFeedPost':
        return buildMutationGql({
          operationName: 'DislikeFeedPost',
          operation: 'FeedPostRemoveLikeUser',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'feedPostBaseFragment',
          authUser: params.authUser,
        }).then((mutation) => this.mutate<{ FeedPostRemoveLikeUser: FeedPost }, {}>(mutation, params.definition.variables))
          .then((response: FetchResult<{ FeedPostRemoveLikeUser: FeedPost }>) => response.data?.FeedPostRemoveLikeUser);

      default:
        return Promise.resolve(undefined);
    }
  }
}
