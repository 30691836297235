import { DocumentNode } from 'graphql';
import 'regenerator-runtime';
import { Observable } from 'subscriptions-transport-ws';
import {
  ApolloClient,
  ApolloQueryResult,
  FetchResult,
  NormalizedCacheObject,
} from '@apollo/client';
import { createProvider } from '@/plugins/apollo/VueApollo';
import GraphQlProvider from '@/plugins/apollo/GraphQlProvider';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import BaseModel from '@/models/BaseModel';
import CookieService from '@/services/CookieService';
import ClientStorage from '@/utils/ClientStore';

/* eslint-disable max-len */
export default abstract class GraphQlRepository<Model extends BaseModel> {
  protected readonly apolloProvider: GraphQlProvider<NormalizedCacheObject>;

  protected clientName!: string;

  constructor() {
    this.apolloProvider = createProvider();
  }

  // eslint-disable-next-line class-methods-use-this
  get authToken(): string {
    return CookieService.getCookie(AUTH_TOKEN) || '';
  }

  // eslint-disable-next-line class-methods-use-this
  protected get headers(): object {
    return {
      'jwt-token': this.authToken,
    };
  }

  protected get client(): ApolloClient<NormalizedCacheObject> {
    return this.getClient(this.clientName);
  }

  /**
   * Empty string represents the default graphql client
   * @param clientName
   */
  use(clientName = ''): this {
    this.clientName = clientName;

    return this;
  }

  resetStore(): void {
    try {
      this.client.resetStore();
    } catch (e) {
      // Todo: Fix console once the log report is included in the project
      // eslint-disable-next-line no-console
      console.log('%cError on cache reset (login/logout)', 'color: orange;', e.message);
    }
  }

  protected getClient(clientName?: string): ApolloClient<NormalizedCacheObject> {
    if (clientName) {
      const client = this.apolloProvider.clients.get(this.clientName);

      if (!client) {
        throw new Error('Requested client not defined');
      }

      return client;
    }

    return this.apolloProvider.defaultClient;
  }

  protected query<ViewModel = Model, TVariables = object>(query: DocumentNode, parameterVariables?: TVariables, cache = true):
    Promise<ApolloQueryResult<ViewModel>> {
    /**
     WARNING: Without this line of code the _fts will not work.
     Because it is caching the old result and even when we change the _fts value, apollo will always
     return the cache result even if you set the fetch policy to no-cache or network-only.
     When you need to disable the cache set it to false value
     */
    if (!cache) {
      this.client.cache.reset();
    }
    const variables = {
      _locale: ClientStorage.getItem('locale') as string,
      ...parameterVariables,
    } as unknown as TVariables;
    return this.client.query<ViewModel, TVariables>({
      context: {
        headers: this.headers,
      },
      query,
      variables,
    });
  }

  protected mutate<ViewModel = Model, TVariables = object>(mutation: DocumentNode, parameterVariables?: TVariables):
    Promise<FetchResult<ViewModel, object, object>> {
    const variables = {
      _locale: ClientStorage.getItem('locale') as string,
      ...parameterVariables,
    } as unknown as TVariables;
    return this.client.mutate<ViewModel, TVariables>({
      context: {
        headers: this.headers,
      },
      mutation,
      variables,
    });
  }

  protected subscribe<ViewModel = Model, TVariables = object>(subscription: DocumentNode, parameterVariables?: TVariables):
    Observable<FetchResult<ViewModel, object, object>> {
    const variables = {
      _locale: ClientStorage.getItem('locale') as string,
      ...parameterVariables,
    } as unknown as TVariables;
    return this.client.subscribe<ViewModel, TVariables>({
      context: {
        headers: this.headers,
      },
      query: subscription,
      variables,
    });
  }
}
