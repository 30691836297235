var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'd-flex flex-column input-text-container',
    {'gap-2': _vm.label.length}
  ]},[_c('div',{staticClass:"d-flex justify-content-between"},[(_vm.label)?_c('p',{staticClass:"mb-0 text-left text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('span',{staticClass:"text-red-r-3-primary-red"},[_vm._v("*")]):_vm._e()]):_vm._e(),(_vm.translatable && _vm.localTabs.length > 0 && _vm.type !== 'password')?_c('segmented-controls-component',{staticClass:"ml-auto",attrs:{"elements":_vm.localTabs},on:{"on-click":_vm.switchTab}}):_vm._e(),(_vm.type === 'password' && !_vm.onInputShowHide)?_c('div',{staticClass:"ml-auto d-flex justify-content-center align-items-center show-button",on:{"click":function($event){return _vm.updateFieldVisibility()}}},[_c('font-awesome-component',{attrs:{"icon":_vm.fieldType === 'password'
          ? 'far fa-eye'
          : (_vm.fieldType === 'text' ? 'far fa-eye-slash' : ''),"container-classes":"icon-24","icon-classes":"text-neutral-n-6-label"}}),_c('p',{staticClass:"mb-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.fieldType === 'password' ? _vm.$t('input-text-password.show-button') : _vm.$t('input-text-password.hide-button'))+" ")])],1):_vm._e()],1),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.$emit('click-outside'); }),expression:"() => $emit('click-outside')"}],staticClass:"input-container h-100"},[_c('div',{staticClass:"position-relative"},[(_vm.onInputShowHide && _vm.type === 'password')?_c('div',{staticClass:"over-input-right-icon cursor-pointer user-select-none",on:{"click":function($event){return _vm.updateFieldVisibility()}}},[_c('font-awesome-component',{attrs:{"icon":_vm.fieldType === 'password'
            ? 'fa-solid fa-eye'
            : _vm.fieldType === 'text' ? 'fa-solid fa-eye-slash' : '',"container-classes":"squarify-icon-md","icon-classes":"text-neutral-n-6-label "}})],1):_vm._e(),_c('b-form-input',{ref:"inputField",class:[
          'input-text h-100',
          {'active': _vm.isActiveParam},
          {'hide-borders p-0': _vm.hideBorders},
          {'py-2 px-3': !_vm.hideBorders},
          {'rounded-sm': !_vm.rounded},
          {'rounded-input': _vm.rounded},
          {'py-3 py-3': _vm.size ==='large'} ],attrs:{"id":_vm.id,"aria-describedby":"input-live-help input-live-feedback","autocomplete":_vm.autoComplete,"disabled":_vm.disabled,"name":_vm.id,"placeholder":_vm.hintText,"state":_vm.hasErrors ? false : null,"type":_vm.fieldType},on:{"blur":function($event){_vm.$emit('blur', _vm.inputText.trim())},"focusin":_vm.focusIn,"focusout":_vm.focusOut,"update":_vm.onChange},model:{value:(_vm.inputText),callback:function ($$v) {_vm.inputText=$$v},expression:"inputText"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }