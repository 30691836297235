import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import CommunityUser from '@/models/graphql/CommunityUser';
import EmailResponse from '@/utils/types/sign-in/EmailResponse';
import Logger from '@/utils/logger/Logger';
import CookieService from '@/services/CookieService';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import jwtDecode from 'jwt-decode';
import JwtParams from '@/utils/types/JwtParams';
import SignInRepository from '@/repositories/SignInRepository';
import CommunityUserRepository from '@/repositories/CommunityUserRepository';
import RootState from '@/store/states/RootState';
import LoginResponseParams from '@/utils/types/LoginResponseParams';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import EmailType from '@/utils/enums/EmailType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import SignInStep from '@/utils/enums/SignInStep';

interface SignInState extends LoadableState {
  account: {
    validToken: false;
    expiredToken: false;
    activated: false;
    activatable: false;
  };
}

@Module({ namespaced: true })
/* eslint-disable max-len */
export default class SignInStore extends LoadableStore<SignInState> {
  private readonly communityUserRepository = new CommunityUserRepository();

  private readonly signInRepository = new SignInRepository();

  @Action
  triggerRecovery(payload: { email: string }): Promise<{ success: boolean } | null> {
    const {
      rootState,
      rootGetters,
    } = this.context;
    if (rootGetters.communityCode) {
      this.context.commit('load', true);
      const email = encodeURIComponent(payload.email);

      return this.signInRepository.resetPassword(rootGetters.communityCode, email)
        .then((response) => {
          if (response && response.success) {
            rootState.signInEmailState = EmailType.PASSWORD_RESET;
          }
          return response;
        })
        .catch(() => Promise.resolve({ success: false }));
    }
    return Promise.resolve({ success: false });
  }

  @Action
  changeUserEmail(payload: { token: string }): Promise<boolean> {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-whitespace-before-property
      this.context.dispatch('CommunityUserStore/changeUserEmail', { uploadToken: payload.token }, { root: true })
        .then((response: CommunityUser | undefined) => {
          if (response) {
            // eslint-disable-next-line no-underscore-dangle
            if (response._authToken) {
              // eslint-disable-next-line no-underscore-dangle
              CookieService.setCookie(AUTH_TOKEN, response._authToken);
              // eslint-disable-next-line no-underscore-dangle
              this.context.commit('AuthenticationStore/setAuthToken', response._authToken, { root: true });
            }
            this.context.dispatch('login')
              .then(() => {
                resolve(true);
              })
              .catch(() => {
                resolve(false);
              });
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  @Action
  login(credentials: {
    username: string;
    password: string;
  } | null = null): Promise<CommunityUser | null | undefined> {
    this.context.commit('load', true);
    const {
      rootState,
      rootGetters,
    } = this.context;

    if (credentials) {
      const {
        username,
        password,
      } = credentials;
      const token = CookieService.getCookie(AUTH_TOKEN);
      if (rootGetters.communityCode && token) {
        return this.signInRepository.login(username, password, rootGetters.communityCode, token)
          .then((response): Promise<CommunityUser | null> => {
            if (response && response.success) {
              this.context.commit('authenticate', {
                response,
                rootState,
                remember: false,
              });
              const authResponse = response as unknown as LoginResponseParams;
              return this.communityUserRepository.authUser({
                definition: buildQueryDefinition({
                  filter: {
                    value: {
                      uid: authResponse.userUid,
                    },
                    type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
                  },
                }),
              })
                .then((res) => {
                  if (res) {
                    this.context.dispatch('AuthenticationStore/setAuthStep', SignInStep.LOGGED_IN, { root: true });
                    rootState.authUser = CommunityUser.hydrate(res);
                    this.context.commit('AuthenticationStore/setAuthToken', response.communityToken, { root: true });
                    Logger.prototype.log(['trackEvent', 'communityUser', 'signIn',
                      `${rootState.authUser.firstName} ${rootState.authUser.lastName}`, 0, {
                        ...Logger.prototype
                          .serialiseAuthUserDimensions(
                            rootState.authUser,
                            rootState.community?.code ?? '',
                            'communityUser',
                          ),
                        dimension2: this.context.rootState.i18n?.locale || '',
                      }]);
                    return res;
                  }

                  return null;
                });
            }

            if (!response.success) {
              return Promise.resolve(null);
            }
            return Promise.reject();
          })
          .catch(() => {
            this.context.commit('unAuthenticate', rootState);
            this.context.commit(
              'AuthenticationStore/authFailure',
              'You have entered an invalid username or password',
              { root: true },
            );
            Logger.prototype.log([
              'trackEvent',
              'communityUser',
              'signInFailed',
              '',
              0,
              { dimension9: 'communityUser' },
            ]);
            return Promise.reject();
          });
      }
      return Promise.reject();
    }

    const token = CookieService.getCookie(AUTH_TOKEN) as string;
    const decodedToken = jwtDecode(token) as JwtParams;
    return this.communityUserRepository.authUser({
      definition: buildQueryDefinition({
        filter: {
          value: {
            uid: decodedToken.u,
          },
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
    })
      .then(((res) => {
        if (res) {
          rootState.authUser = CommunityUser.hydrate(res);
          this.context.commit('AuthenticationStore/setAuthToken', token, { root: true });
          return rootState.authUser;
        }
        return Promise.resolve(null);
      }));
  }

  @Action
  loginWithoutCredentials(): Promise<CommunityUser | null | undefined> {
    this.context.commit('load', true);
    const { rootState } = this.context;
    const token = CookieService.getCookie(AUTH_TOKEN) as string;
    const decodedToken = jwtDecode(token) as JwtParams;
    return this.communityUserRepository.authUser({
      definition: buildQueryDefinition({
        filter: {
          value: {
            uid: decodedToken.u,
          },
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
    })
      .then(((res) => {
        if (res) {
          rootState.authUser = CommunityUser.hydrate(res);
          this.context.commit('AuthenticationStore/setAuthToken', token, { root: true });
          return rootState.authUser;
        }
        return Promise.resolve(null);
      }));
  }

  @Action
  magicLink(payload: { username: string }): Promise<{ success: boolean } | null> {
    const {
      rootState,
      rootGetters,
    } = this.context;
    const token = CookieService.getCookie(AUTH_TOKEN);
    if (rootGetters.communityCode && token) {
      return this.signInRepository.magicLink(rootGetters.communityCode, payload.username, token)
        .then((response) => {
          rootState.signInEmailState = EmailType.EMAIL_LINK;
          return response;
        });
    }
    return Promise.resolve({ success: false });
  }

  @Action
  createPassword(payload: {
    password: string;
    uid?: string;
    email?: string;
  }): Promise<boolean | null> {
    return this.context.dispatch('CommunityUserStore/updatePassword', payload, { root: true })
      .then((response) => {
        if (response) {
          if (payload.email) {
            return this.context.dispatch('login', {
              username: payload.email,
              password: payload.password,
            })
              .then(() => true)
              .catch(() => false);
          }
          if (this.context.rootState.authUser) {
            // eslint-disable-next-line no-underscore-dangle
            this.context.rootState.authUser._needsPasswordCreated = false;
          }
          return true;
        }
        return false;
      })
      .catch(() => false);
  }

  @Action
  checkEmail(payload: { email: string }): Promise<EmailResponse> {
    this.context.commit('load', true);
    const token = CookieService.getCookie(AUTH_TOKEN);
    const {
      rootState,
      rootGetters,
    } = this.context;
    if (rootGetters.communityCode && token) {
      return this.signInRepository
        .checkEmail(rootGetters.communityCode, payload.email, token)
        .then((response) => {
          if (
            response.success
            && response.canProceedWithAccountCreation !== undefined
            && response.canProceedWithPasswordLogin !== undefined
            && response.canProceedWithMagicLinkViaEmail !== undefined
          ) {
            rootState.authEmail = payload.email;
            rootState.signInCanProceed = {
              accountCreation: response.canProceedWithAccountCreation,
              passwordLogin: response.canProceedWithPasswordLogin,
              magicLink: response.canProceedWithMagicLinkViaEmail,
            };
            return response;
          }
          return Promise.reject();
        })
        .catch(() => Promise.reject());
    }
    return Promise.reject();
  }

  @Action
  sendTriggerChangeEmail(payload: { email: string }): Promise<boolean | null> {
    const {
      rootState,
      rootGetters,
    } = this.context;
    if (rootGetters.authUser.uid && payload.email) {
      payload.email = payload.email.toLowerCase();
      return this.context.dispatch(
        'CommunityUserStore/count',
        { filter: payload },
        { root: true },
      )
        .then((count) => {
          if (count === 0) {
            return this.context.dispatch(
              'CommunityUserStore/updateUserProfile',
              {
                email: payload.email,
              },
              { root: true },
            )
              .then((communityUser) => {
                if (communityUser && rootState.authUser) {
                  rootState.authUser.email = communityUser.email;
                  return true;
                }
                return null;
              })
              .catch(() => null);
          }
          return Promise.resolve(false);
        })
        .catch(() => Promise.resolve(null));
    }
    return Promise.resolve(null);
  }

  @Action
  activateAccount(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const { rootGetters } = this.context;
      const token = CookieService.getCookie(AUTH_TOKEN);
      if (rootGetters.communityCode && token) {
        this.signInRepository.activateAccount(rootGetters.communityCode, token)
          .then((response) => {
            if (response && response.success) {
              this.context.dispatch('loginWithoutCredentials')
                .then(() => {
                  resolve(true);
                })
                .catch(() => {
                  resolve(false);
                });
            } else {
              resolve(false);
            }
          });
      } else {
        resolve(false);
      }
    });
  }

  @Action
  createAccount(model: CommunityUser): Promise<CommunityUser | undefined> {
    const { rootState } = this.context;
    return this.communityUserRepository.create({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_INPUT,
        value: model,
      }]),
      fragmentName: 'communityUserCreateFragment',
    })
      .then((response) => {
        // eslint-disable-next-line no-underscore-dangle
        if (response?._authToken) {
          // eslint-disable-next-line no-underscore-dangle
          CookieService.setCookie(AUTH_TOKEN, response._authToken);
          rootState.authUser = response;
        }
        rootState.signInEmailState = EmailType.WELCOME_EMAIL;
        return response;
      });
  }

  @Mutation
  afterLogin(payload: { rootState: RootState; token: string; communityUser: CommunityUser }): void {
    payload.rootState.authUser = CommunityUser.hydrate(payload.communityUser);
    this.context.commit('AuthenticationStore/setAuthToken', payload.token, { root: true });
  }

  @Mutation
  // eslint-disable-next-line class-methods-use-this
  authenticate(params: {
    response: LoginResponseParams;
    rootState: RootState;
    remember: boolean;
  }): void {
    const { response } = params;
    if (response.communityToken) {
      if (params.remember) {
        CookieService.setCookie(AUTH_TOKEN, response.communityToken, 365);
      } else {
        CookieService.setCookie(AUTH_TOKEN, response.communityToken);
      }
    }
  }

  @Mutation
  unAuthenticate(rootState: RootState): void {
    // eslint-disable-next-line no-param-reassign
    rootState.authUser = null;
    CookieService.deleteCookie(AUTH_TOKEN);
    if (this.communityUserRepository) {
      this.communityUserRepository.resetStore();
    }
  }
}
