import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import EntityType from '@/utils/enums/EntityType';
import Category from '@/models/graphql/Category';
import LargeProduct from '@/models/graphql/LargeProduct';
import LargeProductRepository from '@/repositories/LargeProductRepository';
import ExtraPropertyRepository from '@/repositories/ExtraPropertyRepository';
import BaseModel from '@/models/BaseModel';
import FileResourceParams from '@/utils/types/FileResourceParams';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import { BasicTypes } from '@/utils/types/BasicTypes';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { LargeProductFilter } from '@/graphql/_Filters/LargeProductFilter';

/* eslint-disable @typescript-eslint/camelcase */
interface LargeProductState extends LoadableState {
  paginatedLargeProducts: LargeProduct[];
  allPromisesLoading: boolean;
}

@Module({ namespaced: true })
export default class LargeProductStore extends LoadableStore<LargeProductState> {
  paginatedLargeProducts: LargeProduct[] = [];

  allPromisesLoading = false;

  private page = 0;

  private itemsPerPage = 10;

  private readonly largeProductRepository = new LargeProductRepository();

  private readonly extraPropertyRepository = new ExtraPropertyRepository();

  get getAllPromisesLoading(): boolean {
    return this.allPromisesLoading;
  }

  get getPaginatedLargeProducts(): LargeProduct[] {
    return this.paginatedLargeProducts;
  }

  protected get repository(): LargeProductRepository {
    return this.largeProductRepository;
  }

  @Action
  editLargeProduct(payload: {
    exhibitorId: string;
    largeProduct: {
      uid: string;
      name: string;
      url: string;
      videoUrl: string;
      dimensions: string;
      manufacturer: string;
      model: string;
      serie: string;
      serialNumber: string;
      price: number;
      description: string;
      publicationTime: string;
      endPublicationTime: string;
      oldCategories: [];
      newCategories: [];
      newDealLink: string;
      oldDealLink: string;
    };
  }): Promise<void | LargeProduct | undefined> {
    this.context.commit('setAllPromisesLoading', true);
    const finalLargeProduct = { ...payload.largeProduct };
    if ('oldCategories' in finalLargeProduct) {
      delete finalLargeProduct.oldCategories;
    }
    if ('newCategories' in finalLargeProduct) {
      delete finalLargeProduct.newCategories;
    }
    if ('newDealLink' in finalLargeProduct) {
      delete finalLargeProduct.newDealLink;
    }
    if ('oldDealLink' in finalLargeProduct) {
      delete finalLargeProduct.oldDealLink;
    }
    if (payload.largeProduct.uid) {
      return this.repository
        .update({
          definition: buildMutationDefinition([
            {
              fieldName: 'entity',
              type: GqlEntityInputType.LARGE_PRODUCT_INPUT,
              value: finalLargeProduct,
            },
          ]),
        });
    }
    return this.repository.createLargeProductForExhibitor({
      definition: buildMutationDefinition([
        {
          fieldName: 'exhibitor_ExhibitorUid',
          type: 'ID',
          value: payload.exhibitorId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.LARGE_PRODUCT_INPUT,
          value: finalLargeProduct,
        },
      ]),
    });
  }

  @Action
  updateLargeProductLinkedEntities(payload: {
    largeProduct: {
      uid: string;
      oldCategories: [];
      newCategories: [];
      boothToSet: [];
      boothToDelete: [];
      imagesToDelete: [];
      imagesToAdd: [];
      handoutsToDelete: [];
      handoutsToAdd: [];
      propertiesToDelete: [];
      propertiesToAdd: [];
      propertiesToUpdate: [];
      translations: Record<string, string>;
    };
    communityCode: string;
  }): Promise<void> {
    let translationsToUpdatePromise: Promise<LargeProduct | undefined>[] = [];
    if (Object.keys(payload.largeProduct.translations).length > 0) {
      translationsToUpdatePromise = Object.keys(payload.largeProduct.translations).map((key) => {
        const infos = key.split('-');
        return this.repository.updateByLocale({
          definition: buildMutationDefinition([
            {
              fieldName: 'entity',
              type: GqlEntityInputType.LARGE_PRODUCT_INPUT,
              value: {
                uid: payload.largeProduct.uid,
                [infos[0]]: payload.largeProduct.translations[key],
              },
            },
          ]),
        }, infos[1]);
      });
    }

    const categoriesToDelete: string[] = [];
    if (payload.largeProduct.oldCategories) {
      payload.largeProduct.oldCategories.forEach((category: Category) => {
        const toDelete = payload.largeProduct.newCategories
          .find((newCategory: Category) => newCategory.uid
            === category.uid);
        if (toDelete === undefined) {
          const alreadyInArray = categoriesToDelete.includes(category.uid);
          if (!alreadyInArray) {
            categoriesToDelete.push(category.uid);
          }
        }
      });
    }
    const categoriesToAdd: string[] = [];
    if (payload.largeProduct.newCategories) {
      payload.largeProduct.newCategories.forEach((category: Category) => {
        const toAdd = payload.largeProduct.oldCategories
          .find((newCategory: Category) => newCategory.uid
            === category.uid);
        if (toAdd === undefined) {
          const alreadyInArray = categoriesToAdd.includes(category.uid);
          if (!alreadyInArray) {
            categoriesToAdd.push(category.uid);
          }
        }
      });
    }

    let categoriesToAddPromise: Promise<Record<string, LargeProduct>[]> | null = null;
    if (categoriesToAdd.length > 0) {
      categoriesToAddPromise = this.repository.addCategoriesToLargeProduct({
        definitions: categoriesToAdd.map((category, index) => ({
          operation: 'LargeProductAddCategory',
          gqlDefinition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: 'ID',
              value: payload.largeProduct.uid,
            },
            {
              fieldName: 'category_CategoryUid',
              type: 'ID',
              value: category,
            },
          ]),
          fragmentName: 'largeProductBaseFragment',
          alias: `add${index}`,
        })),
      });
    }

    let categoriesToDeletePromise: Promise<Record<string, LargeProduct>[]> | null = null;
    if (categoriesToDelete.length > 0) {
      categoriesToDeletePromise = this.repository.removeCategoriesFromLargeProduct({
        definitions: categoriesToDelete.map((category, index) => ({
          operation: 'LargeProductRemoveCategory',
          gqlDefinition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: 'ID',
              value: payload.largeProduct.uid,
            },
            {
              fieldName: 'category_CategoryUid',
              type: 'ID',
              value: category,
            },
          ]),
          fragmentName: 'largeProductBaseFragment',
          alias: `delete${index}`,
        })),
      });
    }

    const imagesToAddPromise = this.context.dispatch(
      'LargeProductImageStore/createLargeProductImagesForLargeProduct',
      { largeProductUid: payload.largeProduct.uid, images: payload.largeProduct.imagesToAdd },
      { root: true },
    );

    const imagesToDeletePromise = this.context.dispatch(
      'LargeProductImageStore/deleteLargeProductImagesForLargeProduct',
      payload.largeProduct.imagesToDelete,
      { root: true },
    );

    const handoutsToAddPromise = this.context.dispatch(
      'HandoutStore/createHandoutsForEntity', {
        entityType: EntityType.LARGE_PRODUCT,
        entityId: payload.largeProduct.uid,
        handouts: payload.largeProduct.handoutsToAdd.map((h: FileResourceParams) => ({
          name: h.name,
          type: EntityType.LARGE_PRODUCT,
          schemaCode: payload.communityCode,
          handoutFileResourceBase64: h.base64Path,
          handoutFileResourceFilename: h.fileName,
        })),
      },
      { root: true },
    );

    const handoutsToDeletePromise = this.context.dispatch(
      'HandoutStore/deleteHandouts',
      payload.largeProduct.handoutsToDelete,
      { root: true },
    );

    const propertiesToAddPromise = this.context.dispatch(
      'ExtraPropertyStore/createExtraPropertiesForConfigurationsAndLargeProduct',
      {
        largeProductUid: payload.largeProduct.uid,
        extraProperties: payload.largeProduct.propertiesToAdd,
      },
      { root: true },
    );

    const propertiesToUpdatePromise = this.context.dispatch(
      'ExtraPropertyStore/updateExtraProperties',
      payload.largeProduct.propertiesToUpdate,
      { root: true },
    );

    const propertiesToDeletePromise = this.context.dispatch(
      'ExtraPropertyStore/deleteExtraProperties',
      payload.largeProduct.propertiesToDelete.map((ext: Record<string, string>) => ext.propertyUid),
      { root: true },
    );

    const allPromises = [
      imagesToDeletePromise,
      imagesToAddPromise,
      handoutsToAddPromise,
      handoutsToDeletePromise,
      propertiesToAddPromise,
      propertiesToUpdatePromise,
      propertiesToDeletePromise,
      ...translationsToUpdatePromise,
    ];

    const boothsToSet: string[] = [];
    if (payload.largeProduct.boothToSet && payload.largeProduct.boothToSet.length > 0) {
      boothsToSet.push((payload.largeProduct.boothToSet as BaseModel[])[0].uid);
    }

    const boothsToRemove: string[] = [];
    if (payload.largeProduct.boothToDelete && payload.largeProduct.boothToDelete.length > 0) {
      boothsToRemove.push((payload.largeProduct.boothToDelete as BaseModel[])[0].uid);
    }

    if (boothsToSet.length > 0) {
      allPromises.push(this.repository.setLargeProductBooth({
        definition: buildMutationDefinition([
          {
            fieldName: 'largeProductUid',
            type: 'ID!',
            value: payload.largeProduct.uid,
          },
          {
            fieldName: 'boothUid',
            type: 'ID!',
            value: boothsToSet[0],
          },
        ]),
      }));
    }

    if (boothsToRemove.length > 0) {
      allPromises.push(this.repository.unsetLargeProductBooth({
        definition: buildMutationDefinition([
          {
            fieldName: 'largeProductUid',
            type: 'ID!',
            value: payload.largeProduct.uid,
          },
          {
            fieldName: 'boothUid',
            type: 'ID!',
            value: boothsToRemove[0],
          },
        ]),
      }));
    }

    if (categoriesToAddPromise) {
      allPromises.push(categoriesToAddPromise);
    }
    if (categoriesToDeletePromise) {
      allPromises.push(categoriesToDeletePromise);
    }

    return Promise.all(allPromises).then(() => {
      this.context.commit('setAllPromisesLoading', false);
      return Promise.resolve();
    });
  }

  @Action
  loadPaginatedLargeProducts(payload: Record<string, BasicTypes>): Promise<number | null> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter as object | null | undefined,
          type: GqlEntityFilterType.LARGE_PRODUCT_FILTER,
        },
        first: this.itemsPerPage,
        offset: this.itemsPerPage * this.page,
      }),
      operationName: 'PaginateLargeProducts',
      authUser: payload.authUser as string,
    }).then((response) => {
      if (this.page === 0) {
        if (response.length !== 0) {
          this.context.commit('setPage', 1);
        }
      } else {
        this.context.commit('setPage', this.page + 1);
      }
      this.context.commit('addElements', response);
      this.context.commit('load', false);
      return response.length;
    }).catch(() => new Promise((resolve) => resolve(null)));
  }

  @Action
  loadMapLargeProduct(payload: {
    geozoneId: number; edition: string; authUser: string;
  }): Promise<LargeProduct | undefined> {
    return this.repository.get({
      operationName: 'GetMapLargeProduct',
      definition: buildQueryDefinition({
        filter: {
          value: {
            editionMappings_some: {
              editionLargeProduct_some: {
                schemaCode: payload.edition,
                booths_some: {
                  geozone_some: {
                    id: payload.geozoneId,
                  },
                },
              },
            },
          },
          type: GqlEntityFilterType.LARGE_PRODUCT_FILTER,
        },
      }),
      fragmentName: 'largeProductCardFragment',
      authUser: payload.authUser,
    });
  }

  @Action
  loadLargeProducts(filter: LargeProductFilter): Promise<LargeProduct[]> {
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.LARGE_PRODUCT_FILTER,
        },
      }),
    });
  }

  @Action
  resetPage(): void {
    this.context.commit('setPage', 0);
    this.context.commit('setPaginatedLargeProducts', []);
  }

  @Action
  copyLargeProduct(params: {
    largeProductUids: string[];
    exhibitorUid: string;
    communityCode: string;
  }): Promise<void> {
    this.context.commit('setAllPromisesLoading', true);
    const allPromises = params.largeProductUids
      .map((uid: string) => this.repository
        .copyLargeProduct({
          largeProductUid: uid,
          exhibitorUid: params.exhibitorUid,
          communityCode: params.communityCode,
        }));

    return Promise.all(allPromises).then(() => {
      this.context.commit('setAllPromisesLoading', false);
      return Promise.resolve();
    });
  }

  @Mutation
  setPage(page: number): void {
    this.page = page;
  }

  @Mutation
  addElements(largeProducts: LargeProduct[]): void {
    this.paginatedLargeProducts = [...this.paginatedLargeProducts, ...largeProducts];
  }

  @Mutation
  setPaginatedLargeProducts(list: LargeProduct[]): void {
    this.paginatedLargeProducts = list;
  }

  @Mutation
  setAllPromisesLoading(allPromisesLoading: boolean): void {
    this.allPromisesLoading = allPromisesLoading;
  }

  @Action
  publishLargeProduct(payload: { uid: string; publicationTime: string }): Promise<LargeProduct | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.LARGE_PRODUCT_INPUT,
        value: { ...payload, endPublicationTime: null },
      }]),
    });
  }

  @Action
  unpublishLargeProduct(payload: { uid: string; endPublicationTime: string }): Promise<LargeProduct | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.LARGE_PRODUCT_INPUT,
        value: payload,
      }]),
    });
  }

  @Action
  deleteLargeProduct(uid: string): Promise<boolean> {
    return this.repository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    }).then((response) => !!response);
  }
}
