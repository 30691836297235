import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { Data } from '@/utils/types/WidgetData';
import SessionRatingRepository from '@/repositories/SessionRatingRepository';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import SessionRating from '@/models/graphql/SessionRating';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { SessionRatingFilter } from '@/graphql/_Filters/SessionRatingFilter';
import SessionRatingEnum from '@/utils/enums/SessionRatingEnum';

interface ActiveSessionState extends LoadableState {
  activeSession: Data[];
}

@Module({ namespaced: true })
export default class SessionRatingStore extends LoadableStore<ActiveSessionState> {
  rating = SessionRatingEnum.NONE;

  private readonly repository = new SessionRatingRepository();

  get reaction(): SessionRatingEnum {
    return this.rating;
  }

  @Mutation
  updateReactionState(rating: SessionRatingEnum): void {
    this.rating = rating;
  }

  @Action
  getReaction(payload: { filter: SessionRatingFilter }): Promise<SessionRating | undefined> {
    this.context.commit('load', true);
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.SESSION_RATING_FILTER,
        },
      }),
      fragmentName: 'sessionRatingFullFragment',
    }).then((response) => {
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  createReaction({
    rating, sessionId, userCommunityUid,
  }: { rating: number; sessionId: string; userCommunityUid: string }): void {
    const temp = this.rating;
    this.context.commit('updateReactionState', rating);
    this.repository.create({
      authUser: this.context.rootGetters.authUser.uid,
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.SESSION_RATING_INPUT,
        value: {
          rating,
        },
      },
      {
        fieldName: 'session_SessionUid',
        type: GqlEntityInputType.ID,
        value: sessionId,
      },
      {
        fieldName: 'user_CommunityUserUid',
        type: GqlEntityInputType.ID,
        value: userCommunityUid,
      },
      ]),
    }).catch(() => this.context.commit('updateReactionState', temp));
  }

  @Action
  updateReaction({
    rating, uid, id, schemaCode,
  }: { rating: number; uid: string; id: number; schemaCode: string }): void {
    const temp = this.rating;
    this.context.commit('updateReactionState', rating);
    this.repository.update({
      authUser: this.context.rootGetters.authUser.uid,
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.SESSION_RATING_INPUT,
        value: {
          rating, uid, id, schemaCode,
        },
      },
      ]),
    }).catch(() => this.context.commit('updateReactionState', temp));
  }
}
