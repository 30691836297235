

















import Vue from 'vue';
import Component from 'vue-class-component';
import BadgeComponent from '@/components/BadgeComponent.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    BadgeComponent,
  },
})
export default class AgendaFilterItem extends Vue {
  @Prop({ required: true })
  private title!: string;

  @Prop({ required: true })
  private count!: number;

  @Prop({ required: true })
  private isSelected!: boolean;
}
