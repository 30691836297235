// eslint-disable-next-line unicorn/filename-case
import RestRepository from '@/repositories/base/RestRepository';

async function downloadAgendaPDFHandler(
  params: {
    timezone: string;
    token: string;
    uid: string;
    locale: string;
    fileName: string;
  },
): Promise<boolean> {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.classList.add('d-none');

  const restRepository = new RestRepository(
    `${process.env.VUE_APP_ACCOUNT_MANAGEMENT_BASE_URL}`,
  );
  const formData = new URLSearchParams();
  formData.append('timezone', params.timezone);
  formData.append('token', params.token);
  formData.append('uid', params.uid);
  formData.append('locale', params.locale);

  return restRepository.post<Response>({
    path: 'account/agendaPDF',
    data: formData,
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(response);
    a.href = url;
    a.download = params.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    return true;
  }).then((status) => {
    a.remove();
    return status;
  }).catch(() => Promise.resolve(false));
}

export default downloadAgendaPDFHandler;
