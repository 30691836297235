/* eslint-disable max-len */
import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import CommunityUserTagRepository from '@/repositories/CommunityUserTagRepository';
import { CommunityUserTagFilter } from '@/graphql/_Filters/CommunityUserTagFilter';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { TAG_LIST } from '@/utils/constants/PaginationOffsets';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';

interface CommunityUserTagState extends LoadableState {
  stateTag: CommunityUserTag;
  stateTags: CommunityUserTag[];
  stateUserEntityTags: CommunityUserTag[];
}

@Module({ namespaced: true })
export default class CommunityUserTagStore extends LoadableStore<CommunityUserTagState> {
  stateTag: CommunityUserTag = {} as CommunityUserTag;

  stateTags: CommunityUserTag[] = [];

  stateTagCount = 0;

  stateUserEntityTags: CommunityUserTag[] = [];

  private readonly communityUserTagRepository = new CommunityUserTagRepository();

  get fetchCommunityUserTag(): CommunityUserTag {
    return this.stateTag;
  }

  get fetchCommunityUserTags(): CommunityUserTag[] {
    return this.stateTags;
  }

  get fetchUserEntityTags(): CommunityUserTag[] {
    return this.stateUserEntityTags;
  }

  protected get repository(): CommunityUserTagRepository {
    return this.communityUserTagRepository;
  }

  @Action
  filter(payload: { filter: CommunityUserTagFilter }): Promise<CommunityUserTag[]> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.COMMUNITY_USER_TAG_FILTER,
        },
        orderBy: {
          value: ['name_asc'],
          type: GqlEntityOrderingType.COMMUNITY_USER_TAG_ORDERING,
        },
      }),
    })
      .then((response) => {
        this.context.commit('setElements', response);
        this.context.commit('load', false);
        return response;
      });
  }

  @Action
  paginated(payload: {
    filter: CommunityUserTagFilter;
    offset: number;
  }): Promise<CommunityUserTag[]> {
    return this.repository.filter({
      operationName: 'LoadPaginatedTags',
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.COMMUNITY_USER_TAG_FILTER,
        },
        orderBy: {
          value: ['name_asc_ignorecase'],
          type: GqlEntityOrderingType.COMMUNITY_USER_TAG_ORDERING,
        },
        first: TAG_LIST,
        offset: payload.offset,
        cacheable: false,
      }),
      fragmentName: 'communityUserTagToolboxFragment',
    })
      .then((response) => response);
  }

  @Action
  checkTagNameExist(payload: { authUser: string; name: string }): Promise<boolean> {
    return this.repository.filter({
      operationName: 'checkTagNameExist',
      fragmentName: 'communityUserTagDuplicatedNameFragment',
      definition: buildQueryDefinition({
        filter: {
          value: {
            name: payload.name,
            user: {
              uid: payload.authUser,
            },
          },
          type: GqlEntityFilterType.COMMUNITY_USER_TAG_FILTER,
        },
      }),
    })
      .then((response) => response.length > 0);
  }

  @Action
  filterUserEntityTags(payload: { filter: CommunityUserTagFilter }): Promise<CommunityUserTag[]> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.COMMUNITY_USER_TAG_FILTER,
        },
      }),
    })
      .then((response) => {
        this.context.commit('setUserEntityTags', response);
        this.context.commit('load', false);
        return response;
      })
      .finally(() => {
        this.context.commit('load', false);
      });
  }

  @Action
  loadTagLinkedEntityCount(entityUid: string): Promise<void> {
    return this.repository.count({
      operationName: 'GetTagLinkedEntityCount',
      definition: buildQueryDefinition({
        filter: {
          value: {
            user: {
              uid: this.context.rootState.authUser?.uid,
            },
            links: {
              target: {
                uid: entityUid,
              },
            },
          },
          type: GqlEntityFilterType.COMMUNITY_USER_TAG_FILTER,
        },
      }),
    })
      .then((response) => {
        this.context.commit('setTagCount', response || 0);
      });
  }

  @Action
  createForUser(payload: {
    userUid: string;
    entity: CommunityUserTag;
  }): Promise<CommunityUserTag | undefined> {
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'user_CommunityUserUid',
          type: 'ID',
          value: payload.userUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMUNITY_USER_TAG_INPUT,
          value: payload.entity,
        },
      ]),
    });
  }

  @Action
  updateCommunityUserTag(payload: Partial<CommunityUserTag>): Promise<CommunityUserTag | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMUNITY_USER_TAG_INPUT,
          value: payload,
        },
      ]),
    });
  }

  @Action
  deleteCommunityUserTag(uid: string): Promise<boolean> {
    return this.repository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    })
      .then((response) => !!response);
  }

  @Action
  removeFromEntity(payload: {
    tagId: string;
    entityId: string;
  }): Promise<CommunityUserTag | undefined> {
    return this.repository.removeFromEntity({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: payload.tagId,
        },
        {
          fieldName: 'linkTarget_LinkableUid',
          type: 'ID',
          value: payload.entityId,
        },
      ]),
    });
  }

  @Action
  setTagsForEntity(payload: {
    tagsToRemove: string[];
    tagsToAdd: string[];
    entityUid: string;
    myUid: string;
  }): Promise<boolean | undefined> {
    return this.repository.setTagsForEntity({
      definition: buildMutationDefinition([
        {
          fieldName: 'tagsToRemove',
          type: '[ID]',
          value: payload.tagsToRemove,
        },
        {
          fieldName: 'tagsToAdd',
          type: '[ID]',
          value: payload.tagsToAdd,
        },
        {
          fieldName: 'entityUid',
          type: 'ID!',
          value: payload.entityUid,
        },
        {
          fieldName: 'myUid',
          type: 'ID!',
          value: payload.myUid,
        },
      ]),
    });
  }

  @Mutation
  setElements(tags: CommunityUserTag[]): void {
    this.stateTags = tags;
  }

  @Mutation
  setElement(tag: CommunityUserTag): void {
    this.stateTag = tag;
  }

  @Mutation
  setUserEntityTags(tags: CommunityUserTag[]): void {
    this.stateUserEntityTags = tags;
  }

  @Mutation
  setTagCount(count: number): void {
    this.stateTagCount = count;
  }
}
