/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApolloQueryResult } from '@apollo/client';
import Role from '@/models/graphql/Role';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import ReadInterface from '@/repositories/base/ReadInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable max-len */
export default class RoleRepository extends GraphQlRepository<Role>
  implements ReadInterface<Role> {
  filter(params: GqlPayloadParams): Promise<Role[]> {
    return buildQueryGql({
      operationName: params.operationName || 'getroleByCode',
      operation: 'role',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'roleFullFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ role: Array<Role> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ role: Array<Role> }>) => response.data.role));
  }

  get(params: GqlPayloadParams): Promise<Role | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'getroleByCode',
      operation: 'role',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'roleFullFragment',
    }).then((query) => this.query<{ role: Role[] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ role: Role[] }>) => response.data.role[0]));
  }
}
