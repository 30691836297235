var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification-box",class:[
    {'notification-box-lg': !_vm.isMobile},
    {'notification-box-sm': _vm.isMobile},
    {'border-1 border-neutral-n-4-line': _vm.notificationBoxList.length > 0
      && (!_vm.isMobile || (_vm.isMobile && _vm.notificationBoxList.includes(_vm.NotificationBoxType.MICROPOLL)))}
  ]},[_c('div',{staticClass:"position-relative"},[(_vm.showMicropoll
        && _vm.fetchedMicropollNotifications
        && _vm.fetchedMicropollNotifications.length > 0
        && _vm.notificationBoxList[_vm.step - 1] === _vm.NotificationBoxType.MICROPOLL)?_c('div',{staticClass:"w-100"},[_c('micro-poll-widget',{key:_vm.currentMicropollNotifyIndex,attrs:{"survey":_vm.currentMicropoll},on:{"on-already-answered":_vm.nextMicropoll,"on-submit":_vm.onMicropollInteract,"on-close":_vm.onMicropollInteract}})],1):_vm._e(),(!_vm.isMobile)?[(_vm.survey && !_vm.isRemovedFromList(_vm.NotificationBoxType.GLOBAL_SURVEY))?[_c('survey-global-notification',{directives:[{name:"show",rawName:"v-show",value:(_vm.notificationBoxList[_vm.step - 1] === _vm.NotificationBoxType.GLOBAL_SURVEY),expression:"notificationBoxList[step - 1] === NotificationBoxType.GLOBAL_SURVEY"}],staticClass:"survey-notification-content",attrs:{"survey":_vm.survey}})]:_vm._e(),(_vm.entityType
          && _vm.entityUid
          && _vm.entityType.toLocaleLowerCase() === _vm.entityTypeEnum.SESSION.toLocaleLowerCase()
          && !_vm.isRemovedFromList(_vm.NotificationBoxType.SESSION_SURVEY))?[_c('survey-entity-notification',{directives:[{name:"show",rawName:"v-show",value:(_vm.notificationBoxList[_vm.step - 1] === _vm.NotificationBoxType.SESSION_SURVEY),expression:"notificationBoxList[step - 1] === NotificationBoxType.SESSION_SURVEY"}],staticClass:"survey-notification-content",attrs:{"entity-type":_vm.entityType || '',"entity-uid":_vm.entityUid || ''},on:{"display-survey-notification":_vm.displaySurveyEntityNotification,"hide-survey-notification":function($event){return _vm.removeFromList(_vm.NotificationBoxType.SESSION_SURVEY)}}})]:_vm._e(),(_vm.entityType
          && _vm.entityUid
          && _vm.entityType.toLocaleLowerCase() === _vm.entityTypeEnum.USER.toLocaleLowerCase()
          && !_vm.isRemovedFromList(_vm.NotificationBoxType.USER_INVITE))?[_c('connection-request-notification',{directives:[{name:"show",rawName:"v-show",value:(_vm.notificationBoxList[_vm.step- 1] === _vm.NotificationBoxType.USER_INVITE),expression:"notificationBoxList[step- 1] === NotificationBoxType.USER_INVITE"}],staticClass:"survey-notification-content",attrs:{"entity-type":_vm.entityType,"entity-uid":_vm.entityUid},on:{"display-connection-notification":_vm.displayConnectionNotification,"hide-connection-notification":_vm.hideConnectionNotification}})]:_vm._e(),(_vm.isFeatureActivated(_vm.FeatureKeys.COMMUNITY_ENABLE_COMPANY_INVITATION)
          && !_vm.isRemovedFromList(_vm.NotificationBoxType.COMPANY_INVITE))?[_c('company-invitation-notification',{directives:[{name:"show",rawName:"v-show",value:(_vm.notificationBoxList[_vm.step - 1] === _vm.NotificationBoxType.COMPANY_INVITE),expression:"notificationBoxList[step - 1] === NotificationBoxType.COMPANY_INVITE"}],staticClass:"survey-notification-content",attrs:{"entity-type":_vm.entityType || '',"entity-uid":_vm.entityUid || ''},on:{"display-company-invite-notification":_vm.displayCompanyInviteNotification,"hide-company-invite-notification":_vm.hideCompanyInviteNotification}})]:_vm._e()]:_vm._e(),(_vm.notificationBoxList.length > 1)?_c('div',{staticClass:"notification-box-controls bg-neutral-n-2-background"},[_c('div',{staticClass:"px-4 py-2 d-flex align-items-center justify-content-center"},[_c('button-icon-component',{staticClass:"border-0",attrs:{"shape":"round","size":"sm","variant":"alt-4"},on:{"on-click":function($event){return _vm.previousStepClick()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-chevron-left"}})]},proxy:true}],null,false,1317564604)}),_c('span',{staticClass:"m-0 label label-card medium text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s((_vm.step + " " + (_vm.$t('survey.feedback.controls-pagination')) + " " + (_vm.notificationBoxList.length)))+" ")]),_c('button-icon-component',{staticClass:"border-0",attrs:{"shape":"round","size":"sm","variant":"alt-4"},on:{"on-click":function($event){return _vm.nextStepClick()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-chevron-right"}})]},proxy:true}],null,false,3332382503)})],1)]):_vm._e(),(_vm.notificationBoxList.length > 0
        && (!_vm.isMobile || (_vm.isMobile && _vm.notificationBoxList.includes(_vm.NotificationBoxType.MICROPOLL))))?_c('button-icon-component',{staticClass:"position-absolute remove-notification-button",attrs:{"shape":"round","size":"sm","variant":"tertiary"},on:{"on-click":function($event){return _vm.onRemoveStepClick()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-40","icon":"fa-regular fa-xmark"}})]},proxy:true}],null,false,2870529070)}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }