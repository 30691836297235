import { FetchResult } from '@apollo/client';
import CommunityUserVisited from '@/models/graphql/CommunityUserVisited';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import createInterface from '@/repositories/base/CreateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';

/* eslint-disable max-len */
export default class CommunityUserVisitedRepository extends GraphQlRepository<CommunityUserVisited>
  implements createInterface<CommunityUserVisited>, DeleteInterface<CommunityUserVisited> {
  create(params: GqlPayloadParams): Promise<CommunityUserVisited | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateCommunityUserVisited',
      operation: 'CommunityUserVisitedCreateForUserAndLinkTarget',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserVisitedBaseFragment',
      alias: 'CommunityUserVisitedCreate',
    }).then((mutation) => this.mutate<{ CommunityUserVisitedCreate: CommunityUserVisited }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserVisitedCreate: CommunityUserVisited }>) => response.data?.CommunityUserVisitedCreate));
  }

  delete(params: GqlPayloadParams): Promise<CommunityUserVisited | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeleteCommunityUserVisited',
      operation: 'CommunityUserVisitedDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserVisitedBaseFragment',
    }).then((mutation) => this.mutate<{ CommunityUserVisitedDelete: CommunityUserVisited }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserVisitedDelete: CommunityUserVisited }>) => response.data?.CommunityUserVisitedDelete));
  }
}
