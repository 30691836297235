

































import { Component, Prop, Vue } from 'vue-property-decorator';
import CommunityUser from '@/models/graphql/CommunityUser';

@Component
export default class MessageBoxTypingComponent extends Vue {
  @Prop({ required: true })
  private readonly users!: CommunityUser[];

  @Prop({ default: false })
  private readonly darkMode!: boolean;

  private get firstname(): string {
    if (this.users.length > 0) {
      return this.users[0].firstName as string;
    }
    return '';
  }

  private mounted(): void {
    this.$emit('loaded');
  }

  private destroyed(): void {
    this.$emit('fix-scroll');
  }
}
