import LoadableState from '@/store/states/LoadableState';
import Meeting from '@/models/graphql/Meeting';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import CommunityUser from '@/models/graphql/CommunityUser';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import CompanyCalendarRepository from '@/repositories/CompanyCalendarRepository';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import LoadableStore from '@/store/LoadableStore';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import { MeetingRequestsInboxView } from '@/utils/enums/MeetingRequestsInboxView';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import { MeetingRequestFilter } from '@/graphql/_Filters/MeetingRequestFilter';

interface CompanyCalendarState extends LoadableState {
  meetings: Meeting[];

  requests: MeetingRequest[];

  reps: CompanyUserRole[];

  selectedMeetingRequest: MeetingRequest | null;

  isDetailView: boolean;

  companyReps: CompanyUserRole[];

  selectedFilter: MeetingRequestsInboxView;
}

@Module({ namespaced: true })
export default class CompanyCalendarStore extends LoadableStore<CompanyCalendarState> {
  meetings: Meeting[] = [];

  meetingRequests: MeetingRequest[] = [];

  selectedMeetingRequest: MeetingRequest | null = null;

  isDetailView = false;

  reps: CompanyUserRole[] = [];

  selectedFilter: MeetingRequestsInboxView = MeetingRequestsInboxView.INCOMING;

  companyCalendarRepository = new CompanyCalendarRepository();

  get moderators(): Array<CommunityUser> {
    return this.reps.map((rep) => CommunityUser.hydrate(rep.user || {}));
  }

  get companyReps(): CompanyUserRole[] {
    return this.reps;
  }

  protected get repository(): CompanyCalendarRepository {
    return this.companyCalendarRepository;
  }

  @Action
  loadMeetingRequests(filter: MeetingRequestFilter): Promise<void> {
    this.context.commit('load', true);
    return this.companyCalendarRepository.loadMeetingRequest({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.MEETING_REQUEST_FILTER,
          value: filter,
        },
        orderBy: {
          type: GqlEntityOrderingType.MEETING_REQUEST_ORDERING,
          value: ['readState_asc', 'createdTimestamp_desc'],
        },
      }),
      authUser: this.context.rootState.authUser?.uid || '',
      magicArgs: {
        companyUid: filter.exhibitor?.uid || '',
      },
    })
      .then((response) => {
        const usersUid = Array.from(
          new Set(response
            .filter((mr) => !!mr.user)
            .map((mr) => mr.user?.uid)),
        );
        if (usersUid.length > 0) {
          return this.repository.loadComposedQueries<Record<string, CommunityUser[]>>({
            operationName: 'LoadActionRulesForMeetingRequests',
            definitions: usersUid.map((uid) => ({
              operation: 'communityUser',
              alias: `u_${uid?.replaceAll('-', '_')}`,
              fragmentName: 'communityUserActionRuleForMeetingRequestFragment',
              gqlDefinition: buildQueryDefinition({
                filter: {
                  type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
                  value: {
                    uid,
                  },
                },
              }),
            })),
          })
            .then((actionRules) => {
              response.forEach((mr) => {
                if (mr.user) {
                  // eslint-disable-next-line no-underscore-dangle
                  mr.user._actions = actionRules[`u_${mr.user.uid.replaceAll('-', '_')}`][0]._actions;
                }
              });
              return response;
            });
        }
        return Promise.resolve(response);
      })
      .then((response) => {
        this.context.commit('setMeetingRequests', response);
        this.context.commit('load', false);
      });
  }

  @Action
  loadMeetingRequestDetail(uid: string): Promise<void> {
    this.context.commit('load', true);
    return this.companyCalendarRepository.loadMeetingRequest({
      operationName: 'LoadMeetingRequestDetail',
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.MEETING_REQUEST_FILTER,
          value: { uid },
        },
      }),
      authUser: this.context.rootState.authUser?.uid || '',
    })
      .then((response) => {
        if (response.length > 0 && response[0].user) {
          return this.repository.loadComposedQueries<Record<string, CommunityUser[]>>({
            operationName: 'LoadActionRulesForMeetingRequestDetail',
            definitions: [{
              operation: 'communityUser',
              alias: `u_${response[0].user.uid?.replaceAll('-', '_')}`,
              fragmentName: 'communityUserActionRuleForMeetingRequestFragment',
              gqlDefinition: buildQueryDefinition({
                filter: {
                  type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
                  value: {
                    uid: response[0].user.uid,
                  },
                },
              }),
            }],
          })
            .then((actionRules) => {
              response.forEach((mr) => {
                if (mr.user) {
                  // eslint-disable-next-line no-underscore-dangle
                  mr.user._actions = actionRules[`u_${mr.user.uid.replaceAll('-', '_')}`][0]._actions;
                }
              });
              return response;
            });
        }
        return Promise.resolve(response);
      })
      .then((response) => {
        if (response.length > 0) {
          this.context.commit('setSelectedMeetingRequest', response[0]);
          this.context.commit('setIsDetailView', true);
        }
      })
      .finally(() => {
        this.context.commit('load', false);
      });
  }

  @Action
  loadCompanyReps(filter: object): Promise<void> {
    this.context.commit('load', true);
    return this.companyCalendarRepository.loadCompanyReps({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.COMPANY_USER_ROLE_FILTER,
          value: filter,
        },
      }),
    })
      .then((response) => {
        this.context.commit('setCompanyReps', response);
        this.context.commit('load', false);
      });
  }

  @Action
  loadMeetings(filter: object): Promise<Meeting[]> {
    this.context.commit('load', true);
    return this.companyCalendarRepository.loadMeetings({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.MEETING_FILTER,
          value: filter,
        },
        orderBy: {
          type: GqlEntityOrderingType.MEETING_ORDERING,
          value: ['startTimestamp_asc'],
        },
      }),
    })
      .then((response) => {
        this.context.commit('setMeetings', response);
        this.context.commit('load', false);
        return response;
      });
  }

  @Action
  loadUnreadMeetingRequestCount(filter: object): Promise<number | undefined> {
    return this.companyCalendarRepository.count({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.MEETING_REQUEST_FILTER,
          value: filter,
        },
      }),
    });
  }

  @Action
  meetingRequestSetMeeting({
    uid,
    meeting,
  }: { uid: string; meeting: Meeting }): Promise<void> {
    return this.context.dispatch('MeetingRequestStore/meetingRequestSetMeeting', {
      uid,
      meeting,
    }, { root: true })
      .then(() => {
        this.context.commit('addMeeting', {
          uid,
          meeting,
        });
      });
  }

  @Action
  addMeetingRequestComment({
    uid,
  }: { uid: string }): void {
    this.context.commit('addCommentToMeetingRequest', uid);
  }

  @Mutation
  addCommentToMeetingRequest(uid: string): void {
    const meetingRequestIndex = this.meetingRequests.findIndex((m: MeetingRequest) => m.uid === uid);
    if (meetingRequestIndex >= 0) {
      // eslint-disable-next-line no-underscore-dangle
      this.meetingRequests[meetingRequestIndex]._commentCount += 1;
    }
  }

  @Mutation
  setMeetingRequests(data: MeetingRequest[]): void {
    this.meetingRequests = data;
  }

  @Mutation
  setMeetings(data: Meeting[]): void {
    this.meetings = data;
  }

  @Action
  deleteMeetingRequest(uid: string): void {
    this.context.dispatch('MeetingRequestStore/deleteMeetingRequest', uid, { root: true })
      .then(() => {
        this.context.commit('removeMeetingRequest', uid);
      });
  }

  @Action
  archiveMeetingRequest(payload: { uid: string; trash: boolean }): void {
    this.context.dispatch('MeetingRequestStore/updateMeetingRequest', {
      entity: payload,
    }, { root: true })
      .then(() => {
        this.context.commit('removeMeetingRequest', payload.uid);
      });
  }

  @Action
  markMeetingRequestAsRead(uid: string): Promise<void> {
    return this.context.dispatch(
      'MeetingRequestStore/updateMeetingRequest',
      {
        entity: {
          uid,
          readState: true,
        },
      },
      { root: true },
    )
      .then((updatedMeetingRequest: MeetingRequest | undefined) => {
        if (updatedMeetingRequest) {
          this.context.commit('updateMeetingRequest', updatedMeetingRequest);
        }
        return Promise.resolve();
      });
  }

  @Mutation
  updateMeetingRequest(newMR: MeetingRequest): void {
    this.meetingRequests = this.meetingRequests.map((mr) => {
      if (mr.uid === newMR.uid) return newMR;
      return mr;
    });
  }

  @Mutation
  removeMeetingRequest(uid: string): void {
    this.meetingRequests = this.meetingRequests.filter((req: MeetingRequest) => req.uid !== uid);
  }

  @Mutation
  addMeetingRequest(meetingRequest: MeetingRequest): void {
    this.meetingRequests.unshift(meetingRequest);
  }

  @Mutation
  addMeetingFromNotification(meeting: Meeting): void {
    const meetingIndex = this.meetings.findIndex((m) => m.uid === meeting.uid);
    if (meetingIndex === -1) {
      this.meetings.push(meeting);
    }
  }

  @Mutation
  addMeeting({
    uid,
    meeting,
  }: { uid: string; meeting: Meeting }): void {
    const meetingRequestIndex = this.meetingRequests.findIndex((m) => m.uid === uid);
    if (meetingRequestIndex > -1) {
      const newMeetingRequest = MeetingRequest.hydrate({
        ...this.meetingRequests[meetingRequestIndex],
        meeting,
        meetingRequestMeeting: meeting,
      });
      this.meetingRequests.splice(meetingRequestIndex, 1, newMeetingRequest);
    }
    if (this.selectedMeetingRequest
      && this.selectedMeetingRequest.uid === uid) {
      this.selectedMeetingRequest.meeting = meeting;
      this.selectedMeetingRequest.meetingRequestMeeting = meeting;
    }
  }

  @Mutation
  updateMeetingParticipant(meetingParticipant: MeetingParticipant): void {
    const meetingIndex = this.meetings
      .findIndex((m) => meetingParticipant.meeting && m.uid === meetingParticipant.meeting.uid);
    if (meetingIndex > -1) {
      const participant = (this.meetings[meetingIndex].participants || [])
        .find((p) => p.uid === meetingParticipant.uid);
      if (participant) {
        participant.state = meetingParticipant.state;
      }
      const meetingRequestIndex = this.meetingRequests
        .findIndex((mr) => mr.meeting && mr.meeting.uid === this.meetings[meetingIndex].uid);
      if (meetingRequestIndex > -1) {
        this.meetingRequests[meetingRequestIndex].meeting = this.meetings[meetingIndex];
        if (this.selectedFilter === MeetingRequestsInboxView.PENDING) {
          this.meetingRequests.splice(meetingRequestIndex, 1);
        }
      }
    }
  }

  @Mutation
  removeMeeting(uid: string): void {
    this.meetings = this.meetings.filter((meet: Meeting) => meet.uid !== uid);
  }

  @Mutation
  setCompanyReps(reps: CompanyUserRole[]): void {
    this.reps = reps;
  }

  @Mutation
  setSelectedFilter(filter: MeetingRequestsInboxView): void {
    this.selectedFilter = filter;
  }

  @Mutation
  setSelectedMeetingRequest(meetingRequest: MeetingRequest | null): void {
    this.selectedMeetingRequest = meetingRequest;
  }

  @Mutation
  setIsDetailView(isDetail: boolean): void {
    this.isDetailView = isDetail;
  }
}
