import { ApolloQueryResult } from '@apollo/client';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildComposeQueryGql, replaceMagicArgs } from '@/graphql/_Tools/GqlGeneric';
import BaseModel from '@/models/BaseModel';
import { BasicTypes } from '@/utils/types/BasicTypes';
import GqlComposeQueryPayloadParams from '@/utils/types/gql/GqlComposeQueryPayloadParams';
import { print } from 'graphql/language/printer';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { GQL_COUNT } from '@/utils/constants/Regex';

/* eslint-disable max-len */
export default class WidgetBaseRepository extends GraphQlRepository<BaseModel> {
  load(payload: {
    params: GqlComposeQueryPayloadParams;
    queries: string;
  }): Promise<Record<string, BasicTypes[]>> {
    let promise = Promise.resolve({} as DocumentNode);
    if (payload.params && payload.params.definitions.length > 0) {
      promise = buildComposeQueryGql({
        operationName: payload.params.operationName as string,
        definitions: payload.params.definitions,
        magicArgs: payload.params.magicArgs,
      });
    }
    return promise.then((query) => {
      let gqlQueryString = '';
      if (Object.keys(query).length > 0) {
        gqlQueryString = print(query);
        if (payload.queries) {
          const lastIndexOf = gqlQueryString.indexOf('{');
          gqlQueryString = [gqlQueryString.slice(0, lastIndexOf + 1),
            replaceMagicArgs(payload.queries, payload.params.magicArgs)
              .replaceAll(GQL_COUNT, '{ count: _count }'),
            gqlQueryString.slice(lastIndexOf + 1)].join('');
        }
      } else if (payload.queries) {
        const q = payload.queries.replaceAll(GQL_COUNT, '{ count: _count }');
        gqlQueryString = `query ${payload.params.operationName} { ${replaceMagicArgs(q, payload.params.magicArgs)} }`;
      }
      return this.query<Record<string, BasicTypes[]>, {}>(gql(gqlQueryString), {}, false);
    }).then((response: ApolloQueryResult<Record<string, BasicTypes[]>>) => response.data);
  }

  runQuery(payload: {query: string; filter: object}): Promise<Record<string, BasicTypes[]>> {
    return this.query<Record<string, BasicTypes[]>, {}>(gql(payload.query), payload.filter, false)
      .then((response: ApolloQueryResult<Record<string, BasicTypes[]>>) => response.data);
  }
}
