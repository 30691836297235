






























import { Component, Prop, Vue } from 'vue-property-decorator';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';

@Component({
  data(): object {
    return {};
  },
  components: { AvatarSoloWidget },
})
export default class MemberInviteAccountFound extends Vue {
  @Getter
  private community!: Community;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: false, default: null })
  private readonly user!: CommunityUser;

  @Prop({ required: false, default: '' })
  private readonly exhibitorId!: string;

  private FileResourceHelper = FileResourceHelper;
}
