import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import FeedPostMedia from '@/models/graphql/FeedPostMedia';
import Topic from '@/models/graphql/Topic';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { ApolloQueryResult } from '@apollo/client';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import FilterInterface from '@/repositories/base/FilterInterface';

export default class TopicRepository extends GraphQlRepository<FeedPostMedia> implements FilterInterface<Topic> {
  filter(params: GqlPayloadParams): Promise<Topic[]> {
    return buildQueryGql({
      operationName: 'topics',
      operation: 'topic',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'topicBaseFragment',
    }).then((query) => this.query<{ topic: Array<Topic> }, {}>(query, params.definition.variables, false))
      .then((response: ApolloQueryResult<{ topic: Array<Topic> }>) => response.data.topic);
  }

  get(params: GqlPayloadParams): Promise<Topic | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetTopicInformation',
      operation: 'topic',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'topicBaseFragment',
    }).then((query) => this.query<{ topic: Topic[] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ topic: Topic[] }>) => response.data?.topic[0]));
  }
}
