import { FetchResult } from '@apollo/client';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import createInterface from '@/repositories/base/CreateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';

/* eslint-disable max-len */
export default class CommunityUserBookmarkRepository extends GraphQlRepository<CommunityUserBookmark>
  implements createInterface<CommunityUserBookmark>, DeleteInterface<CommunityUserBookmark> {
  create(params: GqlPayloadParams): Promise<CommunityUserBookmark | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateCommunityUserBookmark',
      operation: 'CommunityUserBookmarkCreateForUserAndLinkTarget',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserBookmarkBaseFragment',
      alias: 'CommunityUserBookmarkCreate',
    }).then((mutation) => this.mutate<{ CommunityUserBookmarkCreate: CommunityUserBookmark }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserBookmarkCreate: CommunityUserBookmark }>) => response.data?.CommunityUserBookmarkCreate));
  }

  delete(params: GqlPayloadParams): Promise<CommunityUserBookmark | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeleteCommunityUserBookmark',
      operation: 'CommunityUserBookmarkDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserBookmarkBaseFragment',
    }).then((mutation) => this.mutate<{ CommunityUserBookmarkDelete: CommunityUserBookmark }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserBookmarkDelete: CommunityUserBookmark }>) => response.data?.CommunityUserBookmarkDelete));
  }
}
