





















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import Notification from '@/models/graphql/Notification';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
  format,
  fromUnixTime,
  getUnixTime,
} from 'date-fns';
import Session from '@/models/graphql/Session';
import SessionRatingEnum from '@/utils/enums/SessionRatingEnum';
import SessionRating from '@/models/graphql/SessionRating';
import ActionType from '@/utils/enums/ActionType';
import { SurveyFilter } from '@/graphql/_Filters/SurveyFilter';
import Survey from '@/models/graphql/Survey';
import { SurveyUserAnswerFilter } from '@/graphql/_Filters/SurveyUserAnswerFilter';
import SurveyUserAnswer from '@/models/graphql/SurveyUserAnswer';
import SurveyStrategy from '@/utils/enums/SurveyStrategy';
import { SessionRatingFilter } from '@/graphql/_Filters/SessionRatingFilter';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import { runMathJax } from '@/utils/helpers/LatexHelper';

/* eslint-disable @typescript-eslint/camelcase */

const sessionRatingStore = namespace('SessionRatingStore');
const surveyUserAnswerStore = namespace('SurveyUserAnswerStore');
const surveyStore = namespace('SurveyStore');

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
  },
})
export default class NotificationListSessionItemComponent extends Vue {
  @sessionRatingStore.Action
  createReaction!: (payload: { rating: number; sessionId: string; userCommunityUid: string }) => void;

  @sessionRatingStore.Action
  updateReaction!: (payload: { rating: number; uid?: string; id?: number; schemaCode?: string }) => void;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  protected FeatureKeys = FeatureKeys;

  @sessionRatingStore.Action
  private getReaction!: (payload: { filter: SessionRatingFilter }) => Promise<SessionRating[]>;

  @surveyStore.Action(ActionType.FILTER)
  private loadSessionSurveys!: (payload: { filter: SurveyFilter; fragmentName?: string }) => Promise<Survey[]>;

  @surveyUserAnswerStore.Action(ActionType.FILTER)
  private loadSurveyAnswers!: (payload: { filter: SurveyUserAnswerFilter }) => Promise<SurveyUserAnswer[]>;

  @Getter
  private readonly authUser!: CommunityUser;

  @State
  private dateLocale!: Locale;

  @Prop({ required: true })
  private notification!: Notification;

  private listIcons = [
    {
      key: 'love',
      icon: 'heart',
      ratingValue: SessionRatingEnum.LOVED,
    },
    {
      key: 'like',
      icon: 'thumbs-up',
      ratingValue: SessionRatingEnum.LIKE,
    },
    {
      key: 'dislike',
      icon: 'thumbs-down',
      ratingValue: SessionRatingEnum.DISLIKE,
    },
  ];

  private allowTakeSurvey = false;

  private ratingValue = SessionRatingEnum.NONE;

  private survey: Survey | undefined | null = null;

  private reaction: SessionRating | undefined | null = null;

  private get session(): Session | null {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.SESSION) {
      return Session.hydrate(this.notification.triggered);
    }
    return null;
  }

  private get title(): string {
    if (this.session && this.session.name) {
      return this.session.name;
    }
    return '';
  }

  private get dateReceived(): string {
    const today = DateTimeHelper.getCurrentDateTime();
    const createdTimeDate = fromUnixTime(this.notification.createdTimestamp as number);
    if (differenceInSeconds(today, createdTimeDate) < 60) {
      return `${this.$t('my-notification-list-component.time-ago.now')}`;
    }

    const diffInMinutes = differenceInMinutes(today, createdTimeDate);
    if (diffInMinutes < 60) {
      return `${this.$t('my-notification-list-component.time-ago.minutes', {
        number: diffInMinutes,
      })}`;
    }

    const diffInDays = differenceInDays(today, createdTimeDate);
    if (diffInDays === 1) {
      return ` ${this.$t('my-notification-list-component.time-ago.yesterday')}`;
    }
    if (diffInDays < 1) {
      return `${this.$t('my-notification-list-component.time-ago.hours', {
        number: differenceInHours(today, createdTimeDate),
      })}`;
    }
    if (diffInDays < 7) {
      return `${this.$t('my-notification-list-component.time-ago.days', { number: diffInDays })}`;
    }

    if (differenceInYears(today, createdTimeDate) === 0) {
      return format(createdTimeDate, `${this.$t('app.date.monthDayShort')}`, {
        locale: this.dateLocale,
      });
    }
    return format(createdTimeDate, `${this.$t('app.date.defaultDateFormat')}`, {
      locale: this.dateLocale,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  updated(): void {
    runMathJax();
  }

  private created(): void {
    if (this.session) {
      const now = getUnixTime(DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()));
      this.getReaction({
        filter: {
          session: { uid: this.session.uid },
          user: { uid: this.authUser.uid },
        },
      })
        .then((ratings) => {
          this.reaction = ratings && ratings.length > 0 ? ratings[0] : null;
          if (this.reaction && this.reaction.rating) {
            this.ratingValue = this.reaction.rating;
          }
        });
      if (this.session.surveyUrl) {
        this.allowTakeSurvey = true;
        return;
      }
      this.loadSessionSurveys({
        fragmentName: 'surveyWithSessionFragment',
        filter: {
          AND: [
            {
              OR: [
                {
                  startTimestamp_lte: now,
                  endTimestamp_gte: now,
                },
                {
                  startTime: null,
                  endTimestamp_gte: now,
                },
                {
                  startTimestamp_lte: now,
                  endTime: null,
                },
                {
                  startTime: null,
                  endTime: null,
                },
              ],
            },
            {
              OR: [
                { session: { uid: this.session.uid } },
                { strategy: SurveyStrategy.SESSION_SURVEY },
              ],
            },
          ],
        },
      })
        .then((surveys) => {
          if (surveys.length > 0) {
            this.survey = surveys.find((s) => s.session);
            if (!this.survey) {
              this.survey = surveys.find((s) => s.strategy && s.strategy === SurveyStrategy.SESSION_SURVEY);
            }
            if (this.survey && this.session) {
              this.loadSurveyAnswers({
                filter: {
                  survey: {
                    uid: this.survey.uid,
                  },
                  user: {
                    uid: this.authUser.uid,
                  },
                  session: {
                    uid: this.session.uid,
                  },
                },
              })
                .then((answers) => {
                  this.allowTakeSurvey = answers.length === 0;
                });
            }
          }
        });
    }
  }

  private onRate(newRating: number): void {
    if (this.session) {
      this.ratingValue = newRating;
      if (this.reaction) {
        this.updateReaction({
          rating: this.ratingValue,
          uid: this.reaction.uid,
          id: this.reaction.id,
          schemaCode: this.session.schemaCode,
        });
      } else {
        this.createReaction({
          rating: this.ratingValue,
          sessionId: this.session.uid,
          userCommunityUid: this.authUser.uid,
        });
      }
    }
  }

  private takeSurvey(): void {
    this.onInteractedWith(true);
    if (this.allowTakeSurvey && this.session) {
      if (this.session.surveyUrl) {
        window.open(this.session.surveyUrl, '_blank');
      } else if (this.survey) {
        this.$router.push({
          name: 'survey',
          params: {
            entityId: this.session.uid,
            entityType: EntityType.SURVEY,
            linkedEntityType: EntityType.SESSION,
            linkedEntityUid: this.session.uid,
          },
        })
          .catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
              throw error;
            }
          });
      }
    }
  }

  private closeNotificationModal(): void {
    this.$emit('on-close-notification');
  }

  private onInteractedWith(close = true): void {
    if (close) {
      this.closeNotificationModal();
    }
    if (!this.notification.interactedWith) {
      this.$emit('on-interacted-with', this.notification.uid);
    }
  }

  private onDelete(): void {
    this.$emit('on-delete', this.notification.uid);
  }

  private openDetail(): void {
    this.onInteractedWith(true);
    if (this.session) {
      this.$router.push({
        name: 'session-detail',
        params: { sessionId: this.session.uid },
      })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        });
    }
  }
}
