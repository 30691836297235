export enum AppSocialMediaIcons {
  facebook = 'fa-brands fa-facebook',
  instagram = 'fa-brands fa-instagram',
  linkedin = 'fa-brands fa-linkedin',
  pinterest = 'fa-brands fa-pinterest',
  twitter = 'fa-brands fa-x-twitter',
  youtube = 'fa-brands fa-youtube',
  snapchat = 'fa-brands fa-snapchat',
  tiktok = 'fa-brands fa-tiktok',
  website = 'far fa-arrow-up-right-from-square',
  android = '/img/icons/android-badge.svg',
  ios = '/img/icons/ios-badge.svg'
}
