













































































import { Component, Prop } from 'vue-property-decorator';
import Size from '@/utils/enums/Size';
import PickerCalendarComponent from '@/components/calendar/PickerCalendarComponent.vue';
import PickerCalendarType from '@/utils/enums/PickerCalendarType';
import { format, parseISO } from 'date-fns';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { State } from 'vuex-class';

@Component({
  components: { FontAwesomeComponent, PickerCalendarComponent },
})
export default class PickerDateComponent extends BreakpointWrapper {
  @Prop({ required: false, default: '' })
  private readonly label!: string;

  @Prop({ required: false, default: 'md' })
  private readonly size!: Size;

  @Prop({ required: false, validator: (d) => d.match(/^\d{4}-\d{2}-\d{2}$/) })
  private readonly date!: string;

  @Prop({ required: false, default: 'bottom' })
  private readonly position!: string;

  @Prop({ required: false, default: false })
  private readonly isActive!: boolean;

  @Prop({ required: false, default: false })
  private readonly hasErrors!: boolean;

  @Prop({ required: false, default: false })
  private readonly clearable!: boolean;

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean;

  @Prop({ required: false, default: false })
  private readonly showOnlyFutureDate!: boolean;

  @Prop({ required: false, default: PickerCalendarType.MULTI })
  private readonly type!: PickerCalendarType;

  @State
  private dateLocale!: Locale;

  private showCalendar = false;

  private get inputPickerDate(): string {
    if (this.date) {
      return format(parseISO(this.date), this.$t('app.date.defaultDateFormat') as string, { locale: this.dateLocale });
    }
    if (this.clearable) {
      return `${this.$t('toolbox.agenda.create-edit.input-empty-date')}`;
    }
    return '';
  }

  private toggleCalendar(): void {
    this.showCalendar = !this.showCalendar;
  }
}
