

































import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import CommunityUser from '@/models/graphql/CommunityUser';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import { Getter, namespace } from 'vuex-class';
import ActionType from '@/utils/enums/ActionType';
import { CommunityUsersFilter } from '@/graphql/_Filters/CommunityUsersFilter';
import EntityType from '@/utils/enums/EntityType';
import GroupType from '@/utils/enums/chat/GroupType';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import Community from '@/models/graphql/Community';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import Notification from '@/models/graphql/Notification';

const communityUserStore = namespace('CommunityUserStore');
const communityUserConnectionStore = namespace('CommunityUserConnectionStore');

@Component({
  components: { ButtonComponent },
})
/* eslint-disable no-underscore-dangle */
export default class ConnectionRequestNotification extends VueBaseNotify {
  @Getter
  protected readonly community!: Community;

  @Prop({
    required: true,
    default: '',
  })
  protected readonly entityUid!: string;

  @Prop({
    required: true,
    default: '',
  })
  protected readonly entityType!: string;

  @Getter
  private authUser!: CommunityUser;

  @communityUserStore.Action(ActionType.GET_ONE)
  private getConnection!: (payload: { filter: CommunityUsersFilter; fragmentName?: string })
      => Promise<void | CommunityUser | undefined>;

  @communityUserConnectionStore.Action
  private fullyAcceptConnection!: (payload: {
    uid: string; messageLookupArgs: object; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private fullyDeclineConnection!: (payload: {
    uid: string; messageLookupArgs: object; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  private userConnection: CommunityUser = {} as CommunityUser;

  private EntityTypeEnum = EntityType;

  protected get communityCode(): string {
    return this.community.code as string;
  }

  private get isInvited(): boolean {
    return !!(this.userConnection
        && this.userConnection._ourConnection
        && this.userConnection._ourConnection.connectedUser
        && this.userConnection._ourConnection.connectedUser.uid === this.authUser.uid
        && this.userConnection._ourConnection.linkState === ConnectionStatusType.INVITED);
  }

  private get isAccepted(): boolean {
    return !!(this.userConnection
        && this.userConnection._ourConnection
        && this.userConnection._ourConnection.linkState === ConnectionStatusType.ACCEPTED);
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.CONNECTION_REQUEST,
    ];
  }

  mounted(): void {
    this.initData();
  }

  notificationCallback(event: SubscriptionEvent): void {
    if (event.type === NotificationEventType.CONNECTION_REQUEST) {
      const notification = Notification.hydrate(event.data);
      if (notification
          && notification.initiator
          && notification.initiator.uid === this.entityUid
          && notification.notifiedUsers.findIndex((n) => n.uid === this.authUser.uid) > -1) {
        const connection = notification.triggered as unknown as CommunityUserConnection;
        if (connection
            && connection.linkState
            && [ConnectionStatusType.INVITED, ConnectionStatusType.ACCEPTED]
              .includes(connection.linkState)) {
          if (!this.isBlockedByOrIsBlockingUser()) {
            this.$set(this.userConnection, '_ourConnection', connection);
          }
        } else {
          this.$set(this.userConnection, '_ourConnection', null);
        }
      }
    }
  }

  @Watch('entityUid')
  private initData(): void {
    if (this.entityUid) {
      if (this.entityType.toLocaleLowerCase() === this.EntityTypeEnum.USER.toLocaleLowerCase()) {
        this.getConnection({
          filter: { uid: this.entityUid },
          fragmentName: 'communityUserActionButtonConnectFragment',
        })
          .then((response) => {
            if (response) {
              this.userConnection = response;
            }
          });
      }
    }
  }

  private onAccept(): void {
    if (this.isInvited && this.userConnection._ourConnection) {
      const connectedUser = CommunityUser.hydrate(this.userConnection._ourConnection.user);
      const auth = (({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }) => ({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }))(this.authUser) as CommunityUser;
      this.$set(this.userConnection._ourConnection, 'linkState', ConnectionStatusType.ACCEPTED);
      this.fullyAcceptConnection({
        uid: this.userConnection._ourConnection.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: connectedUser.uid,
            firstName: connectedUser.firstName,
            lastName: connectedUser.lastName,
            pictureFileResource: connectedUser.pictureFileResource,
          } as CommunityUser],
          groupType: [GroupType.CONNECTION, GroupType.RECONNECTION],
        },
        toastUserFullName: connectedUser.fullName,
      })
        .then(() => {
          this.$eventsBus.emit('render-action-button-group');
        })
        .catch(() => {
          if (this.userConnection._ourConnection) {
            this.$set(this.userConnection._ourConnection, 'linkState', ConnectionStatusType.INVITED);
          }
        });
    }
  }

  private onDecline(): void {
    if (this.isInvited && this.userConnection._ourConnection) {
      const connectedUser = CommunityUser.hydrate(this.userConnection._ourConnection.user);
      const auth = (({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }) => ({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }))(this.authUser) as CommunityUser;
      this.$set(this.userConnection._ourConnection, 'linkState', ConnectionStatusType.DECLINED);
      this.fullyDeclineConnection({
        uid: this.userConnection._ourConnection.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: connectedUser.uid,
            firstName: connectedUser.firstName,
            lastName: connectedUser.lastName,
            pictureFileResource: connectedUser.pictureFileResource,
          } as CommunityUser],
          groupType: [GroupType.CONNECTION, GroupType.RECONNECTION],
        },
        toastUserFullName: connectedUser.fullName,
      })
        .catch(() => {
          if (this.userConnection._ourConnection) {
            this.$set(this.userConnection._ourConnection, 'linkState', ConnectionStatusType.INVITED);
          }
        });
      this.$eventsBus.emit('render-action-button-group');
    }
  }

  @Watch('isInvited')
  private updateDisplayConnectionNotification(): void {
    if (this.isInvited) {
      this.$emit('display-connection-notification');
    } else {
      this.$emit('hide-connection-notification');
    }
  }

  private isBlockedByOrIsBlockingUser(): boolean {
    return this.authUser
        && (this.authUser.isBlockedByUser(this.userConnection.uid) || this.authUser.haveBlockedUser(this.userConnection.uid));
  }
}
