import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableStore from '@/store/LoadableStore';
import NotificationBox from '@/models/LocalStorage/NotificationBox';
import NotificationBoxConstant from '@/utils/constants/NotificationBox';
import NotificationBoxType from '@/utils/enums/notification/NotificationBoxType';
import ClientStorage from '@/utils/ClientStore';

@Module({ namespaced: true })
export default class LocalStorageNotificationBoxStore extends LoadableStore<NotificationBox> {
  ignoredNotifications: NotificationBox[] = [];

  notificationBoxConstant = NotificationBoxConstant;

  get fetchIgnoredNotifications(): NotificationBox[] {
    return this.ignoredNotifications;
  }

  @Action
  getIgnoredNotifications(): void {
    const jsonData = ClientStorage.getItem(this.notificationBoxConstant);
    const notificationStorage = jsonData ? JSON.parse(jsonData) as NotificationBox[] : [];
    this.context.commit('setIgnoredMutation', notificationStorage);
  }

  @Action
  addToIgnored(payload: {entityUid: string; notificationType?: NotificationBoxType}): void {
    const queueElement = this.ignoredNotifications.find((e: NotificationBox) => e.entityUid === payload.entityUid);
    if (!queueElement) {
      this.context.commit('addToIgnoredMutation', {
        entityUid: payload.entityUid,
        notificationType: payload.notificationType,
      } as NotificationBox);
    }
  }

  @Action
  removeFromIgnored(entityUid: string): void {
    const queueElement = this.ignoredNotifications.find((e: NotificationBox) => e.entityUid === entityUid);
    if (!queueElement) {
      this.context.commit('removeFromIgnoredMutation', entityUid);
    }
  }

  @Mutation
  addToIgnoredMutation(newElement: NotificationBox): void {
    const jsonData = ClientStorage.getItem(this.notificationBoxConstant);
    const notificationStorage = jsonData ? JSON.parse(jsonData) as NotificationBox[] : [];
    notificationStorage.push(newElement);
    ClientStorage.setItem(this.notificationBoxConstant, JSON.stringify(notificationStorage));
  }

  @Mutation
  removeFromIgnoredMutation(entityUid: string): void {
    this.ignoredNotifications = this.ignoredNotifications.filter(
      (e: NotificationBox) => e.entityUid !== entityUid,
    );
  }

  @Mutation
  setIgnoredMutation(elements: NotificationBox[]): void {
    this.ignoredNotifications = elements;
  }
}
