import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import Order from '@/models/graphql/Order';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { OrderFilter } from '@/graphql/_Filters/OrderFilter';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import PackageInformation from '@/models/LocalStorage/PackageInformation';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import OrderRepository from '@/repositories/OrderRepository';
import OrderPaymentCharge from '@/models/graphql/OrderPaymentCharge';

interface OrderState extends LoadableState {
  stateOrder: Order;
  orders: Order[];
}

@Module({ namespaced: true })
export default class OrderStore extends LoadableStore<OrderState> {
  stateOrder: Order = {} as Order;

  orders: Order[] = [] as Order[];

  private readonly orderRepository = new OrderRepository();

  private _loadingState = false;

  get loadingState(): boolean {
    // eslint-disable-next-line no-underscore-dangle
    return this._loadingState;
  }

  get order(): Order | null {
    return this.stateOrder;
  }

  get fetchOrders(): Order[] {
    return this.orders;
  }

  protected get repository(): OrderRepository {
    return this.orderRepository;
  }

  @Mutation
  setElement(order: Order): void {
    this.stateOrder = order;
  }

  @Action
  get(payload: { filter: OrderFilter }): Promise<Order | undefined> {
    this.context.commit('load', true);
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.ORDER_FILTER,
          value: payload.filter,
        },
      }),
      fragmentName: 'orderBaseFragment',
    }).then((response) => {
      this.context.commit('setElement', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  getWithCompanyUid(payload: { filter: OrderFilter; companyUid: string }): Promise<Order | undefined> {
    this.context.commit('load', true);
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.ORDER_FILTER,
          value: payload.filter,
        },
        magicArgs: { filter: `(exhibitorUid: "${payload.companyUid}")` },
      }),
      fragmentName: 'orderFullFragment',
      operationName: 'OrderWithCompanyUid',
    }).then((response) => {
      this.context.commit('setElement', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  filter(payload: { filter: OrderFilter; offset?: number; first?: number }): Promise<Order[]> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.ORDER_FILTER,
          value: payload.filter,
        },
        orderBy: {
          value: ['creationTime_desc'],
          type: GqlEntityOrderingType.ORDER_ORDERING,
        },
        first: payload.first,
        offset: payload.offset,
      }),
      fragmentName: 'orderBaseFragment',
    }).then((response) => {
      this.context.commit('setElements', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  count(payload: { filter: OrderFilter}): Promise<void | number | undefined> {
    this.context.commit('load', true);
    return this.repository.count({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.ORDER_FILTER,
        },
      }),
    }).then((response) => {
      this.context.commit('load', false);
      return response;
    });
  }

  @Mutation
  setElements(orders: Order[]): void {
    this.orders = orders;
  }

  @Action
  getPaymentIntentCharge(payload: { orderUid: string }): Promise<OrderPaymentCharge> {
    return this.repository.getPaymentIntentCharge({
      orderUid: payload.orderUid,
    }).then((response) => response);
  }

  @Action
  createOrderAndPaymentIntent(payload: {
    companyUid: string;
    salesPackages: Array<PackageInformation>;
    userUid: string;
    needsApproval: boolean;
  }): Promise<string> {
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'exhibitorUid',
          type: 'ID!',
          value: payload.companyUid,
        },
        {
          fieldName: 'userUid',
          type: 'ID!',
          value: payload.userUid,
        },
        {
          fieldName: 'salesPackages',
          type: GqlEntityInputType.STOCK_INPUT,
          value: payload.salesPackages,
        },
        {
          fieldName: 'needsApproval',
          type: GqlEntityInputType.BOOLEAN,
          value: payload.needsApproval,
        },
      ]),
    }).then((response) => (response?.uid ? response?.uid : ''));
  }

  @Action
  updateOrderStatus(payload: {
    uid: string;
    status: string;
  }): Promise<Order | undefined> {
    this.context.commit('load', true);
    return this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.ORDER_INPUT,
          value: payload,
        },
      ]),
    }).then((response: Order| undefined) => {
      this.context.commit('setElement', response);
      this.context.commit('load', false);
      return response;
    });
  }
}
