import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { SurveyQuestionFilter } from '@/graphql/_Filters/SurveyQuestionFilter';
import SurveyQuestion from '@/models/graphql/SurveyQuestion';
import SurveyQuestionRepository from '@/repositories/SurveyQuestionRepository';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';

interface SurveyQuestionState extends LoadableState {
  surveyQuestions: SurveyQuestion[];
  stateSurveyQuestion: SurveyQuestion;
}

@Module({ namespaced: true })
export default class SurveyQuestionStore extends LoadableStore<SurveyQuestionState> {
  surveyQuestions: SurveyQuestion[] = [] as SurveyQuestion[];

  stateSurveyQuestion: SurveyQuestion = {} as SurveyQuestion;

  private readonly surveyQuestionRepository = new SurveyQuestionRepository();

  get surveyQuestion(): SurveyQuestion | null {
    return this.stateSurveyQuestion;
  }

  get fetchSurveyQuestions(): SurveyQuestion[] {
    return this.surveyQuestions;
  }

  protected get repository(): SurveyQuestionRepository {
    return this.surveyQuestionRepository;
  }

  @Action
  loadSurveyQuestions(filter: SurveyQuestionFilter): Promise<SurveyQuestion[]> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.SURVEY_QUESTION_FILTER,
        },
        orderBy: {
          value: ['displayOrder_asc'],
          type: GqlEntityOrderingType.SURVEY_QUESTION_ORDERING,
        },
      }),
    }).then((response) => {
      this.context.commit('setElements', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Mutation
  setElements(list: SurveyQuestion[]): void {
    this.surveyQuestions = list;
  }

  @Mutation
  setElement(surveyQuestion: SurveyQuestion): void {
    this.stateSurveyQuestion = surveyQuestion;
  }
}
