

























import { Component, Prop } from 'vue-property-decorator';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import { Data } from '@/utils/types/WidgetData';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Component({
  components: { AvatarSoloWidget },
})
export default class PresenterComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: null })
  private readonly entityCode!: string;

  @Prop({ required: false, default: null })
  private readonly route!: string;

  @Prop({ required: false, default: null })
  private readonly type!: string;

  @Prop({ required: false, default: false })
  private readonly getInTouch!: boolean;

  @Prop({ required: false, default: () => [] })
  private data!: Data[];

  private FileResourceHelper = FileResourceHelper;

  private get navigateTo(): string | null {
    if (this.route && this.entity) {
      let r = this.route;
      const matches = this.route.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.$props[prop]) {
            r = r.replaceAll(m, this.$props[prop]);
          }
          if (this.entity && this.entity[prop]) {
            r = r.replaceAll(m, this.entity[prop] as string);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.entity.uid}`;
      }
      return `${r}/${this.entity.uid}`;
    }
    return null;
  }

  private get entity(): Data | null {
    if (this.data && this.data.length > 0) {
      return this.data[0];
    }
    return null;
  }

  created(): void {
    if (this.payload
      && 'entityCode' in this.payload
      && this.payload.entityCode
      && 'type' in this.payload
      && this.payload.type) {
      const filter = JSON.parse(`{ "${this.payload.type}s": { "uid": "${this.payload.entityCode}" } }`);
      this.setDataConfig([{
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.EXHIBITOR_FILTER,
            value: filter,
          },
        }),
        operation: 'exhibitor',
        fragmentName: 'exhibitorBaseFragment',
        alias: this.storeName,
      }]);
    } else if (this.widget && !this.standAlone) {
      this.setDataConfig(
        undefined,
        true,
      );
    }
  }
}
