import { render, staticRenderFns } from "./ToolboxNotesContainer.vue?vue&type=template&id=612f05dd&scoped=true&"
import script from "./ToolboxNotesContainer.vue?vue&type=script&lang=ts&"
export * from "./ToolboxNotesContainer.vue?vue&type=script&lang=ts&"
import style0 from "../../../assets/styles/toolbox/notes/ToolboxNotesContainer.scss?vue&type=style&index=0&id=612f05dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "612f05dd",
  null
  
)

export default component.exports