import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { Data } from '@/utils/types/WidgetData';
import WidgetBaseRepository from '@/repositories/widget/WidgetBaseRepository';

/* eslint-disable max-len */
@Module({ namespaced: true })
export default abstract class WidgetPaginatedStore extends LoadableStore<LoadableState> {
  private repository = new WidgetBaseRepository();

  private paginatedElements: Data[] = [];

  private count = 0;

  private totalCount = 0;

  get fetchPaginated(): Data[] {
    return this.paginatedElements;
  }

  get fetchCount(): number {
    return this.count;
  }

  get fetchTotalCount(): number {
    return this.totalCount;
  }

  @Action
  loadCount(payload: { query: string; variables: object }): Promise<void> {
    this.context.commit('load', true);
    return this.repository.runQuery({
      query: payload.query,
      filter: payload.variables,
    }).then((response) => {
      this.context.commit('setCount', {
        // eslint-disable-next-line no-underscore-dangle
        count: (Object.values(response)[0] as Record<string, number>[])[0]._count,
      });
      this.context.commit('load', false);
    });
  }

  @Action
  loadTotalCount(payload: { query: string; variables: object }): Promise<void> {
    this.context.commit('load', true);
    return this.repository.runQuery({
      query: payload.query,
      filter: payload.variables,
    }).then((response) => {
      this.context.commit('setTotalCount', {
        // eslint-disable-next-line no-underscore-dangle
        count: (Object.values(response)[0] as Record<string, number>[])[0]._count,
      });
      this.context.commit('load', false);
    });
  }

  @Action
  loadPaginated(payload: { query: string; variables: object }): Promise<void> {
    this.context.commit('load', true);
    return this.repository.runQuery({
      query: payload.query,
      filter: payload.variables,
    }).then((response) => {
      this.context.commit('setPaginatedElements', {
        list: Object.values(response)[0] as Data[],
      });
      this.context.commit('load', false);
    });
  }

  @Mutation
  setPaginatedElements(payload: { list: Data[] }): void {
    this.paginatedElements = payload.list;
  }

  @Mutation
  setCount(payload: { count: number }): void {
    this.count = payload.count;
  }

  @Mutation
  setTotalCount(payload: { count: number }): void {
    this.totalCount = payload.count;
  }
}
