import BaseModel from '@/models/BaseModel';
// eslint-disable-next-line import/no-cycle
import Community from '@/models/graphql/Community';
// eslint-disable-next-line import/no-cycle
import MessageGroup from '@/models/graphql/MessageGroup';

export default class MessageGroupGuestToken extends BaseModel {
  id?: number;

  community?: Community;

  schemaCode?: string;

  token?: string;

  group?: MessageGroup;

  static hydrate(item: object): MessageGroupGuestToken {
    const result = new MessageGroupGuestToken();
    Object.assign(result, item);
    return result;
  }
}
