import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import SurveyUserAnswer from '@/models/graphql/SurveyUserAnswer';
import { SurveyUserAnswerFilter } from '@/graphql/_Filters/SurveyUserAnswerFilter';
import SurveyUserAnswerRepository from '@/repositories/SurveyUserAnswerRepository';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import EntityType from '@/utils/enums/EntityType';
import { deepSearch } from '@/utils/ObjectHelpers';

interface SurveyUserAnswerState extends LoadableState {
  surveyUserAnswers: SurveyUserAnswer[];
  stateSurveyUserAnswer: SurveyUserAnswer;
}

@Module({ namespaced: true })
export default class SurveyUserAnswerStore extends LoadableStore<SurveyUserAnswerState> {
  surveyUserAnswers: SurveyUserAnswer[] = [] as SurveyUserAnswer[];

  stateSurveyUserAnswer: SurveyUserAnswer = {} as SurveyUserAnswer;

  private readonly surveyUserAnswerRepository = new SurveyUserAnswerRepository();

  get surveyUserAnswer(): SurveyUserAnswer | null {
    return this.stateSurveyUserAnswer;
  }

  get fetchSurveyUserAnswers(): SurveyUserAnswer[] {
    return this.surveyUserAnswers;
  }

  protected get repository(): SurveyUserAnswerRepository {
    return this.surveyUserAnswerRepository;
  }

  @Action
  get(payload: { filter: SurveyUserAnswerFilter; loneQuery?: boolean }): Promise<SurveyUserAnswer | undefined> {
    this.context.commit('load', true);
    const { authUser } = this.context.rootGetters;
    return this.repository.get({
      definition: buildQueryDefinition({
        cacheable: !authUser
          || (!deepSearch(payload.filter, authUser.uid)
            && !deepSearch(payload.filter, '%authUser%')),
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.SURVEY_USER_ANSWER_FILTER,
        },
      }),
      fragmentName: 'surveyUserAnswerWithSurveyFragment',
    }).then((response) => {
      if (!payload.loneQuery) {
        this.context.commit('setElement', response);
      }
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  filter(payload: { filter: SurveyUserAnswerFilter }): Promise<SurveyUserAnswer[]> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.SURVEY_USER_ANSWER_FILTER,
        },
      }),
      fragmentName: 'surveyUserAnswerWithSurveyFragment',
    }).then((response) => {
      this.context.commit('setElements', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  create(payload: {
    answer: SurveyUserAnswer;
    userId: string;
    surveyId: string;
    choiceId: string;
  }): Promise<SurveyUserAnswer | undefined> {
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.SURVEY_USER_ANSWER_INPUT,
          value: payload.answer,
        },
        {
          fieldName: 'user_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: payload.userId,
        },
        {
          fieldName: 'survey_SurveyUid',
          type: GqlEntityInputType.ID,
          value: payload.surveyId,
        },
        {
          fieldName: 'choice_SurveyQuestionChoiceUid',
          type: GqlEntityInputType.ID,
          value: payload.choiceId,
        },
      ]),
    });
  }

  @Action
  surveyUserAnswerSetEntity(payload: { uid: string; entityUid: string; entityType: string }):
    Promise<SurveyUserAnswer | undefined> {
    let fieldName = '';
    let operationName = '';
    switch (payload.entityType) {
      case EntityType.SURVEY:
        fieldName = 'survey_SurveyUid';
        operationName = 'SurveyUserAnswerSetSurvey';
        break;
      case EntityType.SURVEY_QUESTION_CHOICE:
        fieldName = 'choice_SurveyQuestionChoiceUid';
        operationName = 'SurveyUserAnswerSetChoice';
        break;
      case EntityType.USER:
        fieldName = 'user_CommunityUserUid';
        operationName = 'SurveyUserAnswerSetUser';
        break;
      case EntityType.SESSION:
        fieldName = 'session_SessionUid';
        operationName = 'SurveyUserAnswerSetSession';
        break;
      case EntityType.EXHIBITOR:
        fieldName = 'exhibitor_ExhibitorUid';
        operationName = 'SurveyUserAnswerSetExhibitor';
        break;
      case EntityType.SPEAKER:
        fieldName = 'speaker_SpeakerUid';
        operationName = 'SurveyUserAnswerSetSpeaker';
        break;
      case EntityType.PRODUCT:
        fieldName = 'product_ProductUid';
        operationName = 'SurveyUserAnswerSetProduct';
        break;
      default:
        break;
    }

    return this.repository.surveyUserAnswerSetEntity({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.uid,
        },
        {
          fieldName,
          type: GqlEntityInputType.ID,
          value: payload.entityUid,
        },
      ]),
    }, operationName);
  }

  @Mutation
  setElements(list: SurveyUserAnswer[]): void {
    this.surveyUserAnswers = list;
  }

  @Mutation
  setElement(surveyUserAnswer: SurveyUserAnswer): void {
    this.stateSurveyUserAnswer = surveyUserAnswer;
  }
}
