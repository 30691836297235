












































































































































import { Component, Prop } from 'vue-property-decorator';
import PickerCalendarComponent from '@/components/calendar/PickerCalendarComponent.vue';
import {
  format,
  getUnixTime,
  parse,
  setHours,
  setMinutes,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import InputNumberComponent from '@/components/InputNumberComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { State } from 'vuex-class';

@Component({
  components: {
    ButtonComponent,
    InputNumberComponent,
    FontAwesomeComponent,
    PickerCalendarComponent,
  },
})
export default class PickerDateTimeComponent extends BreakpointWrapper {
  @Prop({ default: new Date() })
  private readonly date!: Date;

  private showCalendar = false;

  private selectedDate = this.date || new Date();

  private isAfternoon = this.noon;

  private dateToBe = new Date();

  @State
  private dateLocale!: Locale;

  private get formattedDate(): string {
    return format(this.selectedDate, 'PPp');
  }

  private get selectedDateTimestamp(): string {
    return format(
      startOfDay(this.selectedDate),
      'yyyy-MM-dd',
      { locale: this.dateLocale },
    );
  }

  private get selectedMonthTimestamp(): number {
    return getUnixTime(startOfMonth(this.selectedDate));
  }

  private get noon(): boolean {
    return format(this.selectedDate, 'a') === 'PM';
  }

  private get minutes(): number {
    return parseInt(format(this.selectedDate, 'm'), 10);
  }

  private get hours(): number {
    return parseInt(format(this.selectedDate, 'h'), 10);
  }

  private onDateSelected(dates: string[]): void {
    if (dates && dates.length > 0) {
      this.dateToBe = parse(dates[0], 'yyyy-MM-dd', new Date());
    }
  }

  private onHourSelected(hours: number): void {
    this.dateToBe = setHours(this.dateToBe, hours);
  }

  private onMinuteSelected(minutes: number): void {
    this.dateToBe = setMinutes(this.dateToBe, minutes);
  }

  private onDayPeriodChanged(): void {
    this.isAfternoon = !this.isAfternoon;
  }

  private onSave(): void {
    this.showCalendar = false;
    const date = new Date(`${format(this.dateToBe, 'yyyy-MM-dd h:mm')} ${this.isAfternoon ? 'pm' : 'am'}`);
    this.selectedDate = date;
    this.$emit('on-date-selected', {
      date: DateTimeHelper.toUTC(date),
      timestamp: getUnixTime(date),
      text: format(DateTimeHelper.toUTC(date), DateTimeHelper.TIME_FORMAT_ISO_8601),
    });
  }

  private onClear(): void {
    this.selectedDate = new Date();
  }
}
