import { Action, Module, Mutation } from 'vuex-module-decorators';
import Session from '@/models/graphql/Session';
import LoadableStore from '@/store/LoadableStore';
import SessionRepository from '@/repositories/SessionRepository';
import {
  format, getDate, parse, startOfHour,
} from 'date-fns';
import SessionCalendarTab from '@/utils/types/session/calendar/SessionCalendarTab';
import { utcToZonedTime } from 'date-fns-tz';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import WidgetBaseRepository from '@/repositories/widget/WidgetBaseRepository';
import { BasicTypes } from '@/utils/types/BasicTypes';

interface SessionCalendar {
  header: SessionCalendarTab[];
}

@Module({ namespaced: true })
export default class SessionBrowserCalendarStore extends LoadableStore<SessionCalendar> {
  content: Record<string, Record<string, Session[]>> = {};

  selectedDate = '';

  private isEmpty = false;

  private header: SessionCalendarTab[] = [];

  private selectedKey: SessionCalendarTab | null = null;

  private readonly sessionRepository = new SessionRepository();

  private readonly widgetBaseRepository = new WidgetBaseRepository();

  get dateTabs(): Array<SessionCalendarTab> {
    return this.header;
  }

  protected get repository(): SessionRepository {
    return this.sessionRepository;
  }

  @Action
  loadSessionCalendarDates(payload: { filter: object; tz: string }): Promise<void> {
    this.context.commit('EntitySearchStore/setLoading', true, { root: true });
    this.context.commit(
      'WidgetDispatcherStore/setMagicArgs',
      { currentTimeZone: payload.tz },
      { root: true },
    );
    const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
    const config = {
      definition: buildQueryDefinition({
        filter: {
          value: {
            distinct: true,
            ...payload.filter,
          },
          type: GqlEntityFilterType.SESSION_FILTER,
        },
        orderBy: {
          value: ['startTime_asc'],
          type: GqlEntityOrderingType.SESSION_ORDERING,
        },
      }),
      magicArgs: args,
    };
    return this.repository.filterStartDate(config)
      .then((dates) => {
        this.context.commit('setHeader', {
          dates,
          tz: payload.tz,
          locale: this.context.rootState.dateLocale,
        });
        this.context.commit('EntitySearchStore/setLoading', false, { root: true });
        return Promise.resolve();
      })
      .then(() => this.context.dispatch('loadSessionsByDate', payload));
  }

  @Action
  loadSessionsByDate(payload: { filter: object; tz: string }): Promise<void> {
    this.context.commit('EntitySearchStore/setLoading', true, { root: true });
    if (this.selectedDate
      && (!Object.keys(this.content)
        .includes(this.selectedDate)
        || Object.keys(this.content[this.selectedDate]).length === 0)) {
      return this.repository.filter({
        definition: buildQueryDefinition({
          filter: {
            value: {
              ...payload.filter,
              _inStartDate: {
                dates: [this.selectedDate],
                timezone: payload.tz,
              },
            },
            type: GqlEntityFilterType.SESSION_FILTER,
          },
          orderBy: {
            value: ['startTime_asc'],
            type: GqlEntityOrderingType.SESSION_ORDERING,
          },
        }),
        operationName: 'LoadSessionsByDate',
        fragmentName: 'sessionCardFragment',
        authUser: this.context.rootState.authUser?.uid,
      })
        .then((sessions) => {
          this.context.commit('setSessionsCalendar', {
            sessions,
            tz: payload.tz,
            locale: this.context.rootState.dateLocale,
          });
          this.context.commit('EntitySearchStore/setLoading', false, { root: true });
        });
    }
    this.context.commit('EntitySearchStore/setLoading', false, { root: true });
    return Promise.resolve();
  }

  @Mutation
  setHeader(payload: { dates: string[]; tz: string; locale: Locale }): void {
    this.content = {};
    this.isEmpty = false;
    if (payload.dates.length === 0) {
      this.isEmpty = true;
      return;
    }
    this.header = payload.dates.map((d) => {
      const date = parse(d, 'yyyy-MM-dd', new Date());
      Object.assign(this.content, { [d]: {} });
      return {
        key: d,
        dayOfWeek: format(date, 'EEEE', { locale: payload.locale }),
        dayOfMonth: `${getDate(date)}`,
        selected: false,
      };
    });
    if (this.selectedDate) {
      const foundDate = this.header.find((h) => h.key === this.selectedDate);
      if (foundDate) {
        foundDate.selected = true;
        this.selectedKey = foundDate;
      } else if (this.header.length > 0) {
        this.selectedDate = this.header[0].key;
        this.header[0].selected = true;
        // eslint-disable-next-line prefer-destructuring
        this.selectedKey = this.header[0];
      }
    } else if (this.header.length > 0) {
      this.selectedDate = this.header[0].key;
      this.header[0].selected = true;
      // eslint-disable-next-line prefer-destructuring
      this.selectedKey = this.header[0];
    }
  }

  @Mutation
  setSessionsCalendar(payload: { sessions: Session[]; tz: string; locale: Locale }): void {
    this.isEmpty = false;
    if (payload.sessions.length === 0) {
      this.isEmpty = true;
      return;
    }
    payload.sessions.forEach((s) => {
      if (s.startTime) {
        const std = utcToZonedTime(`${s.startTime}Z`, payload.tz);
        const date = format(std, 'yyyy-MM-dd');
        const time = format(startOfHour(std), 'p', { locale: payload.locale });
        if (!(date in this.content && this.content[date])) {
          Object.assign(this.content, { [date]: {} });
        }
        if (!(time in this.content[date] && this.content[date][time])) {
          Object.assign(this.content[date], { [time]: [] });
        }
        if (!this.content[date][time].find((item) => item.uid === s.uid)) {
          this.content[date][time].push(s);
        }
      }
    });
  }

  @Mutation
  setSelectedKey(key: SessionCalendarTab): void {
    this.selectedKey = key;
    this.header.forEach((h) => {
      h.selected = h.key === key.key;
    });
  }

  @Mutation
  setSelectedDate(selected: string): void {
    this.selectedDate = selected;
    this.header.forEach((h) => {
      h.selected = h.key === selected;
    });
  }
}
