import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import PollAnswer from '@/models/graphql/PollAnswer';
import PollAnswerRepository from '@/repositories/PollAnswerRepository';

interface PollAnswerState extends LoadableState {
  pollAnswers: PollAnswer[];
  statePollAnswer: PollAnswer;
}

@Module({ namespaced: true })
export default class PollAnswerStore extends LoadableStore<PollAnswerState> {
  pollAnswers: PollAnswer[] = [] as PollAnswer[];

  statePollAnswer: PollAnswer = {} as PollAnswer;

  private readonly pollAnswerRepository = new PollAnswerRepository();

  get pollAnswer(): PollAnswer | null {
    return this.statePollAnswer;
  }

  get fetchPollAnswers(): PollAnswer[] {
    return this.pollAnswers;
  }

  protected get repository(): PollAnswerRepository {
    return this.pollAnswerRepository;
  }

  @Action
  create(payload: {pollId: string; entity: PollAnswer}): Promise<PollAnswer | undefined> {
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'poll_PollUid',
          type: 'ID',
          value: payload.pollId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.POLL_ANSWER_INPUT,
          value: payload.entity,
        },
      ]),
    });
  }

  @Action
  update(payload: Partial<PollAnswer>): Promise<PollAnswer | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.POLL_ANSWER_INPUT,
          value: payload,
        },
      ]),
    });
  }

  @Action
  delete(uid: string): Promise<PollAnswer | undefined> {
    return this.repository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    });
  }

  @Mutation
  setElements(list: PollAnswer[]): void {
    this.pollAnswers = list;
  }

  @Mutation
  setElement(pollAnswer: PollAnswer): void {
    this.statePollAnswer = pollAnswer;
  }
}
