import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import { ActionButtonWidgetHelper } from '@/utils/helpers/widgets/ActionButtonWidgetHelper';
import { Data } from '@/utils/types/WidgetData';

@Module({ namespaced: true, stateFactory: true })
export default class ActionButtonGroupWidgetStore extends WidgetBaseStore {
  private actionButtonsValues: { value: Data; key: string }[] = [];

  @Action
  setDataConfigs(): void {
    if (this.widget?.subWidgets?.length) {
      const {
        entityCode, type,
      } = JSON.parse(this.widget.payload || '{}');

      const configs = ActionButtonWidgetHelper.constructActionButtonsQueries(
        this.widget.subWidgets, type, entityCode, this.widgetStoreName, this.context.rootState.community?.code, this.context,
      );
      super.setDataConfigs(configs);
    }
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    if (data.values.length && this.widget) {
      this.actionButtonsValues = [...this.actionButtonsValues, { value: data.values[0], key: data.key }];
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      actionButtons: this.widget ? ActionButtonWidgetHelper.mapActionButton(this.widget, this.actionButtonsValues) : [],
    };
  }
}
