import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import MessageGroupRepository from '@/repositories/MessageGroupRepository';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import MessageGroup from '@/models/graphql/MessageGroup';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { MessageGroupFilter } from '@/graphql/_Filters/MessageGroupFilter';

interface MessageGroupState extends LoadableState {
  messageGroups: MessageGroup[];
  messageGroupState: MessageGroup[];
}

@Module({ namespaced: true })
export default class
MessageGroupStore extends LoadableStore<MessageGroupState> {
  messageGroups: MessageGroup[] = [];

  messageGroupState: MessageGroup = {} as MessageGroup;

  private readonly messageGroupRepository = new MessageGroupRepository();

  get messageGroup(): MessageGroup {
    return this.messageGroupState;
  }

  get fetchMessageGroups(): MessageGroup[] {
    return this.messageGroups;
  }

  protected get repository(): MessageGroupRepository {
    return this.messageGroupRepository;
  }

  @Action
  addUser(payload: {
    userUid: string;
    groupUid: string;
  }): Promise<boolean | undefined> {
    return this.repository.addUser({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: payload.groupUid,
        },
        {
          fieldName: 'user_CommunityUserUid',
          type: 'ID',
          value: payload.userUid,
        },
      ]),
      authUser: this.context.rootGetters.authUser.uid,
    });
  }

  @Action
  create(payload: { targetUid: string; messageGroup: MessageGroup }): Promise<MessageGroup | undefined> {
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'target_NodeUid',
          type: 'ID',
          value: payload.targetUid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.MESSAGE_GROUP_INPUT,
          value: payload.messageGroup,
        },
      ]),
      authUser: this.context.rootGetters.authUser.uid,
    });
  }

  @Action
  get(payload: { filter: MessageGroupFilter }): Promise<void | MessageGroup | undefined> {
    this.context.commit('load', true);
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.MESSAGE_GROUP_FILTER,
        },
      }),
      fragmentName: 'messageGroupBaseFragment',
      authUser: this.context.rootGetters.authUser?.uid || '',
    }).then((response) => {
      this.context.commit('setElement', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  updateMessageGroup(payload: MessageGroup): Promise<MessageGroup | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.MESSAGE_GROUP_INPUT,
        value: payload,
      }]),
      authUser: this.context.rootGetters.authUser.uid,
    });
  }

  @Mutation
  setElements(messageGroups: MessageGroup[]): void {
    this.messageGroups = messageGroups;
  }

  @Mutation
  setElement(messageGroup: MessageGroup): void {
    this.messageGroupState = messageGroup;
  }
}
