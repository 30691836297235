import { FetchResult } from '@apollo/client';
import CommunityUser from '@/models/graphql/CommunityUser';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable max-len */
export default class CommunityUserCountryRepository extends GraphQlRepository<CommunityUser> {
  update(params: GqlPayloadParams): Promise<CommunityUser | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommunityUserSetCountry',
      operation: 'CommunityUserSetCountry',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserBaseFragment',
    }).then((mutation) => this.use().mutate<{ CommunityUserSetCountry: CommunityUser }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserSetCountry: CommunityUser }>) => response.data?.CommunityUserSetCountry));
  }
}
