

























import { Component, Prop } from 'vue-property-decorator';
import VueContextLoading from '@/components/VueContextLoading';

@Component
export default class ButtonIconComponent extends VueContextLoading {
  @Prop({
    required: false,
    default: '',
  })
  private readonly variant!: string;

  @Prop({
    required: true,
    default: '',
  })
  private readonly size!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly disabled!: boolean;

  @Prop({
    required: false,
    default: 'box',
  })
  private readonly shape!: string;

  @Prop({ default: '' })
  private readonly text!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly backgroundSecondary!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly rounded!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private tooltipText!: string;

  private getSizeClass(): string {
    switch (this.size) {
      case 'xlg':
        return 'xlg-btn rounded';
      case 'lg':
        return 'lg-btn rounded';
      case 'md':
        return 'md-btn rounded-sm';
      default:
        return 'sm-btn rounded-sm';
    }
  }
}
