








































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import Notification from '@/models/graphql/Notification';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
  format,
  fromUnixTime,
} from 'date-fns';
import Meeting from '@/models/graphql/Meeting';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import Session from '@/models/graphql/Session';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import Event from '@/utils/types/Event';
import NotificationActionType from '@/utils/enums/notification/NotificationActionType';
import { runMathJax } from '@/utils/helpers/LatexHelper';

const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    AvatarSoloWidget,
  },
})
export default class NotificationListAgendaItemComponent extends Vue {
  @Getter
  private readonly community!: Community;

  @Getter
  private readonly authUser!: CommunityUser;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  @Prop({ required: true })
  private notification!: Notification;

  @agendaStore.Mutation
  private setEventViewed!: (e: Event) => void;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  private get isSession(): boolean {
    return !!this.notification.action?.startsWith('SESSION');
  }

  private get title(): string {
    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.SESSION) {
      const hydrated = Session.hydrate(this.notification.triggered);
      if (hydrated) {
        return hydrated.name || '';
      }
    }

    if (this.notification
        && this.notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.triggered.__typename === EntityType.MEETING) {
      const hydrated = this.notification.triggered as Meeting;
      if (hydrated) {
        return hydrated.subject || '';
      }
    }
    return '';
  }

  private get content(): string {
    switch (this.notification.action) {
      case NotificationActionType.SESSION_STARTING_NOW:
        return `${this.$t('my-notification-list-component.agenda.session-now')}`;
      case NotificationActionType.SESSION_STARTING_SOON:
        return `${this.$t('my-notification-list-component.agenda.session-soon')}`;
      case NotificationActionType.MEETING_STARTING_NOW:
        return `${this.$t('my-notification-list-component.agenda.meeting-now')}`;
      case NotificationActionType.MEETING_STARTING_SOON:
        return `${this.$t('my-notification-list-component.agenda.meeting-soon')}`;
      default:
        return '';
    }
  }

  private get dateReceived(): string {
    const today = DateTimeHelper.getCurrentDateTime();
    const createdTimeDate = fromUnixTime(this.notification.createdTimestamp as number);
    if (differenceInSeconds(today, createdTimeDate) < 60) {
      return `${this.$t('my-notification-list-component.time-ago.now')}`;
    }

    const diffInMinutes = differenceInMinutes(today, createdTimeDate);
    if (diffInMinutes < 60) {
      return `${this.$t('my-notification-list-component.time-ago.minutes', {
        number: diffInMinutes,
      })}`;
    }

    const diffInDays = differenceInDays(today, createdTimeDate);
    if (diffInDays === 1) {
      return ` ${this.$t('my-notification-list-component.time-ago.yesterday')}`;
    }
    if (diffInDays < 1) {
      return `${this.$t('my-notification-list-component.time-ago.hours', {
        number: differenceInHours(today, createdTimeDate),
      })}`;
    }
    if (diffInDays < 7) {
      return `${this.$t('my-notification-list-component.time-ago.days', { number: diffInDays })}`;
    }

    if (differenceInYears(today, createdTimeDate) === 0) {
      return format(createdTimeDate, `${this.$t('app.date.monthDayShort')}`, {
        locale: this.dateLocale,
      });
    }
    return format(createdTimeDate, `${this.$t('app.date.defaultDateFormat')}`, {
      locale: this.dateLocale,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  updated(): void {
    runMathJax();
  }

  private closeNotificationModal(): void {
    this.$emit('on-close-notification');
  }

  private onInteractedWith(close = true): void {
    if (close) {
      this.closeNotificationModal();
    }
    if (!this.notification.interactedWith) {
      this.$emit('on-interacted-with', this.notification.uid);
    }
  }

  private onDelete(): void {
    this.$emit('on-delete', this.notification.uid);
  }

  private openInToolbox(): void {
    this.onInteractedWith(true);
    if (this.notification
        && this.notification.triggered) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING) {
        this.setEventViewed(AgendaStoreHelper.convertMeetingToEvent(this.notification.triggered as Meeting, this.selectedTzName));
      }
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.SESSION) {
        this.setEventViewed(AgendaStoreHelper.convertSessionToEvent(this.notification.triggered as Session, this.selectedTzName));
      }
      this.setViewMode(ViewMode.DETAIL);
      this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
    }
  }
}
