enum LinkedEntityTypes {
    USER = 'CommunityUser',
    DEAL = 'Deal',
    PRODUCT = 'Product',
    LARGE_PRODUCT = 'LargeProduct',
    SESSION = 'Session',
    ARTICLE = 'Article',
    EXHIBITOR = 'Exhibitor',
    SPEAKER = 'Speaker',
  }

export default LinkedEntityTypes;
