import { FetchResult } from '@apollo/client';
import CommunityUserInterest from '@/models/graphql/CommunityUserInterest';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import { buildComposeMutationGql, buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import DeleteInterface from '@/repositories/base/DeleteInterface';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';

/* eslint-disable max-len */
export default class CommunityUserInterestRepository extends GraphQlRepository<CommunityUserInterest>
  implements DeleteInterface<CommunityUserInterest> {
  manageCommunityUserInterest(params: GqlComposeMutationPayloadParams): Promise<Record<string, CommunityUserInterest>[]> {
    return buildComposeMutationGql({
      operationName: 'ManageCommunityUserInterest',
      definitions: params.definitions,
      authUser: params.authUser,
    }).then((mutations) => this.mutate<Record<string, CommunityUserInterest>[], {}>(mutations))
      .then((response: FetchResult<Record<string, CommunityUserInterest>[]>) => response.data as Record<string, CommunityUserInterest>[]);
  }

  delete(params: GqlPayloadParams): Promise<CommunityUserInterest | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommunityUserInterestDelete',
      operation: 'CommunityUserInterestDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserInterestBaseFragment',
    }).then((mutation) => this.mutate<{ CommunityUserInterestDelete: CommunityUserInterest }>(mutation, params.definition.variables)
      .then((response: FetchResult<{ CommunityUserInterestDelete: CommunityUserInterest }>) => response.data?.CommunityUserInterestDelete));
  }
}
