import { FetchResult } from '@apollo/client';
import BusinessCard from '@/models/graphql/BusinessCard';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import CreateInterface from '@/repositories/base/CreateInterface';

/* eslint-disable max-len */
export default class BusinessCardRepository extends GraphQlRepository<BusinessCard>
  implements CreateInterface<BusinessCard> {
  create(params: GqlPayloadParams): Promise<BusinessCard | undefined> {
    return buildMutationGql({
      operationName: 'CreateForUserAndExhibitor',
      operation: 'BusinessCardCreateForUserAndExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'businessCardBaseFragment',
    }).then((mutation) => this.mutate<{ BusinessCardCreateForUserAndExhibitor: BusinessCard }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ BusinessCardCreateForUserAndExhibitor: BusinessCard }>) => response.data?.BusinessCardCreateForUserAndExhibitor));
  }
}
