


















import { Component, Prop, Vue } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class LoadingSpinnerComponent extends Vue {
  @Prop({
    required: false,
    default: null,
  })
  private readonly text!: string;
}
