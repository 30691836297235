import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import HandoutRepository from '@/repositories/HandoutRepository';
import Handout from '@/models/graphql/Handout';
import LoadableStore from '@/store/LoadableStore';
import GqlMutationDefinition, {
  buildMutationDefinition,
} from '@/graphql/_Tools/GqlMutationDefinition';
import EntityType from '@/utils/enums/EntityType';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class HandoutStore extends LoadableStore<LoadableState> {
  private readonly handoutRepository = new HandoutRepository();

  protected get repository(): HandoutRepository {
    return this.handoutRepository;
  }

  @Action
  createHandoutsForEntity(payload: {
    entityId: string;
    entityType: EntityType;
    handouts: Array<Partial<Handout>>;
  }): Promise<void> {
    if (payload.handouts.length > 0) {
      const handoutDefinitions = payload.handouts
        .map((handout, index) => {
          let operation = '';
          let fieldName = '';
          switch (payload.entityType) {
            case EntityType.EXHIBITOR:
              operation = 'HandoutCreateForExhibitor';
              fieldName = 'exhibitor_ExhibitorUid';
              break;
            case EntityType.PRODUCT:
              operation = 'HandoutCreateForProduct';
              fieldName = 'product_ProductUid';
              break;
            case EntityType.LARGE_PRODUCT:
              operation = 'HandoutCreateForLargeProduct';
              fieldName = 'largeProduct_LargeProductUid';
              break;
            case EntityType.SESSION:
              operation = 'HandoutCreateForSession';
              fieldName = 'session_SessionUid';
              break;
            case EntityType.SPEAKER:
              operation = 'HandoutCreateForSpeaker';
              fieldName = 'speaker_SpeakerUid';
              break;
            default:
              break;
          }
          return {
            operation,
            gqlDefinition: buildMutationDefinition([
              {
                fieldName,
                type: 'ID',
                value: payload.entityId,
              },
              {
                fieldName: 'entity',
                type: GqlEntityInputType.HANDOUT_INPUT,
                value: handout,
              },
            ]),
            fragmentName: 'handoutBaseFragment',
            alias: `add${index}`,
          };
        });
      return this.repository.createHandoutsForEntity({
        definitions: handoutDefinitions.filter((params) => !!params) as Array<{
          operation: string;
          gqlDefinition: GqlMutationDefinition;
          fragmentName: string;
          alias?: string;
        }>,
      }).then(() => Promise.resolve());
    }

    return Promise.resolve();
  }

  @Action
  deleteHandouts(handouts: Array<Handout>): Promise<void> {
    if (handouts.length > 0) {
      const handoutDefinitions = handouts.map((handout, index) => ({
        operation: 'HandoutDelete',
        gqlDefinition: buildMutationDefinition([
          {
            fieldName: 'uid',
            type: 'ID',
            value: handout.uid,
          },
        ]),
        fragmentName: 'handoutBaseFragment',
        alias: `delete${index}`,
      }));
      return this.repository.deleteHandouts({
        definitions: handoutDefinitions,
      }).then((deletedHandouts) => Promise.all(Object.values(deletedHandouts)
        .map((handout) => {
          const promises = [];
          if (handout.handoutFileResource) {
            promises.push(this.context.dispatch(
              'FileResourceStore/deleteFileResource',
              handout.handoutFileResource.uid,
              { root: true },
            ));
          }
          if (handout.thumbnailFileResource) {
            promises.push(this.context.dispatch(
              'FileResourceStore/deleteFileResource',
              handout.thumbnailFileResource.uid,
              { root: true },
            ));
          }
          return promises;
        }))).then(() => Promise.resolve());
    }
    return Promise.resolve();
  }

  @Action
  createOrUpdateHandoutForEntity(payload: {
    entityId: string;
    entityType: EntityType;
    handout: Partial<Handout>;
  }): Promise<Handout | undefined> {
    if (payload.handout.uid) {
      return this.repository
        .update({
          definition: buildMutationDefinition([{
            fieldName: 'entity',
            type: GqlEntityInputType.HANDOUT_INPUT,
            value: payload.handout,
          }]),
        });
    }

    switch (payload.entityType) {
      case EntityType.EXHIBITOR:
        return this.repository
          .createHandoutForExhibitor({
            definition: buildMutationDefinition([
              {
                fieldName: 'exhibitor_ExhibitorUid',
                type: 'ID',
                value: payload.entityId,
              },
              {
                fieldName: 'entity',
                type: GqlEntityInputType.HANDOUT_INPUT,
                value: payload.handout,
              },
            ]),
          });
      case EntityType.PRODUCT:
        return this.repository
          .createHandoutForProduct({
            definition: buildMutationDefinition([
              {
                fieldName: 'product_ProductUid',
                type: 'ID',
                value: payload.entityId,
              },
              {
                fieldName: 'entity',
                type: GqlEntityInputType.HANDOUT_INPUT,
                value: payload.handout,
              },
            ]),
          });
      case EntityType.LARGE_PRODUCT:
        return this.repository
          .createHandoutForLargeProduct({
            definition: buildMutationDefinition([
              {
                fieldName: 'largeProduct_LargeProductUid',
                type: 'ID',
                value: payload.entityId,
              },
              {
                fieldName: 'entity',
                type: GqlEntityInputType.HANDOUT_INPUT,
                value: payload.handout,
              },
            ]),
          });
      case EntityType.SESSION:
        return this.repository
          .createHandoutForSession({
            definition: buildMutationDefinition([
              {
                fieldName: 'session_SessionUid',
                type: 'ID',
                value: payload.entityId,
              },
              {
                fieldName: 'entity',
                type: GqlEntityInputType.HANDOUT_INPUT,
                value: payload.handout,
              },
            ]),
          });
      case EntityType.SPEAKER:
        return this.repository
          .createHandoutForSpeaker({
            definition: buildMutationDefinition([
              {
                fieldName: 'speaker_SpeakerUid',
                type: 'ID',
                value: payload.entityId,
              },
              {
                fieldName: 'entity',
                type: GqlEntityInputType.HANDOUT_INPUT,
                value: payload.handout,
              },
            ]),
          });
      default:
        return Promise.resolve(undefined);
    }
  }

  @Action
  deleteHandout(uid: string): Promise<boolean> {
    return this.repository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    }).then((response) => {
      if (response && response.handoutFileResource && response.handoutFileResource.uid) {
        this.context.dispatch(
          'FileResourceStore/deleteFileResource',
          response.handoutFileResource.uid,
          { root: true },
        );
      }
      if (response && response.thumbnailFileResource && response.thumbnailFileResource.uid) {
        this.context.dispatch(
          'FileResourceStore/deleteFileResource',
          response.thumbnailFileResource.uid,
          { root: true },
        );
      }
      return !!response;
    });
  }
}
