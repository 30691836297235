



























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueContextLoading from '@/components/VueContextLoading';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { BFormInput } from 'bootstrap-vue';
import SegmentedControlsComponent from '@/components/SegmentedControlsComponent.vue';
import SegmentedElementParams from '@/utils/types/SegmentedElementParams';
import { State } from 'vuex-class';
import LocaleModel from '@/models/LocaleModel';
import SizeDigit from '@/utils/enums/SizeDigit';
import EntityTranslationParams from '@/utils/types/EntityTranslationParams';
import ClientStorage from '@/utils/ClientStore';

@Component({
  components: { SegmentedControlsComponent, FontAwesomeComponent },
})
export default class InputText extends VueContextLoading {
  @Prop({ default: '' })
  readonly id!: string;

  @Prop({ default: '' })
  readonly label!: string;

  @Prop({ default: '' })
  readonly defaultValue!: string;

  @Prop({ default: '' })
  readonly hintText!: string;

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Prop({ default: false })
  readonly isActive!: boolean;

  @Prop({ default: false })
  readonly hasErrors!: boolean;

  @Prop({ default: false })
  readonly required!: boolean;

  @Prop({ default: '' })
  readonly type!: string;

  @Prop({ default: 'medium' })
  readonly size!: string;

  @Prop({ required: false, default: 'off' })
  readonly autoComplete!: string;

  @Prop({ required: false, default: false })
  readonly hideBorders!: boolean;

  @Prop({ default: false })
  readonly rounded!: boolean;

  @Prop({ default: false })
  readonly translatable!: boolean;

  @Prop({ default: false })
  readonly onInputShowHide!: boolean;

  @Prop({ default: () => [] })
  readonly translations!: EntityTranslationParams[];

  private inputText = '';

  private isActiveParam = this.isActive;

  private fieldType = 'text';

  @State
  private readonly locales!: LocaleModel[];

  private selectedLocale = ClientStorage.getItem('locale');

  private selectedTranslation: EntityTranslationParams | undefined = undefined;

  private localTabs: SegmentedElementParams[] = [];

  mounted(): void {
    this.inputText = this.defaultValue;
    if (this.type && this.type !== '') {
      this.fieldType = this.type;
    }
    this.buildTabConfig();
  }

  @Watch('defaultValue')
  @Watch('translations')
  @Watch('translatable')
  updateInput(): void {
    this.inputText = this.defaultValue;
    this.buildTabConfig();
  }

  focusIn(): void {
    this.isActiveParam = true;
    this.$emit('on-focus-in');
  }

  focusOut(): void {
    this.$emit('on-focus-out');
    this.isActiveParam = false;
  }

  public removeFocus(): void {
    this.isActiveParam = false;
    (this.$refs.inputField as unknown as BFormInput).blur();
  }

  updateFieldVisibility(): void {
    if (this.fieldType === 'password') {
      this.fieldType = 'text';
      return;
    }
    if (this.fieldType === 'text') {
      this.fieldType = 'password';
    }
  }

  private switchTab(tab: SegmentedElementParams): void {
    this.selectedLocale = tab.key;
    this.selectedTranslation = this.translations.find((t) => t.locale === tab.key);
    if (this.selectedTranslation) {
      this.inputText = this.selectedTranslation.value || '';
    }
    this.localTabs.forEach((v) => {
      v.activeState = tab.key === v.key;
    });
  }

  private buildTabConfig(): void {
    if (this.translatable && this.translations.length > 0) {
      this.localTabs = this.locales.map((l) => {
        const name = (l.locale as string).split('_')[0];
        const isTranslationMissing = this.translations.findIndex((t) => t.locale === l.locale && t.value) === -1;
        return {
          text: name.charAt(0).toUpperCase() + name.slice(1),
          key: l.locale as string,
          activeState: l.locale === this.selectedLocale,
          icon: isTranslationMissing ? 'far fa-warning' : '',
          iconColor: isTranslationMissing ? 'text-yellow-y-3-primary-yellow' : '',
          size: SizeDigit.S_24,
        };
      });
      this.selectedTranslation = this.translations.find((t) => t.locale === this.selectedLocale);
      if (this.selectedTranslation) {
        this.inputText = this.selectedTranslation.value || '';
      }
    }
  }

  private onChange(): void {
    this.$emit('on-change', this.inputText.trim());
    if (this.selectedTranslation) {
      this.selectedTranslation.value = this.inputText && this.inputText.length ? this.inputText : null;
      this.buildTabConfig();
      this.$emit('on-translation-update', this.selectedTranslation);
    } else {
      this.$emit('update:defaultValue', this.inputText);
    }
  }
}
