import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { deepSearch, mergeDeep } from '@/utils/ObjectHelpers';
/* eslint-disable @typescript-eslint/camelcase */
@Module({ namespaced: true, stateFactory: true })
export default class LogoListWidgetStore extends WidgetBaseStore {
  @Action
  setDataConfigs(): void {
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    if (this.widget) {
      const {
        filter, type,
      } = JSON.parse(this.widget.payload || '{}');
      const variables = { filter: {} };
      let filterType = '';
      let orderByType = '';
      let operation = '';
      let fragmentName = '';
      switch (type) {
        case 'Company':
          filterType = GqlEntityFilterType.EXHIBITOR_FILTER;
          orderByType = GqlEntityOrderingType.EXHIBITOR_ORDERING;
          fragmentName = 'exhibitorBaseFragment';
          operation = 'exhibitor';
          Object.assign(variables.filter, {
            ...{
              logoFileResource: {
                deleted: false,
              },
            },
          });
          break;
        case 'Product':
          filterType = GqlEntityFilterType.PRODUCT_FILTER;
          orderByType = GqlEntityOrderingType.PRODUCT_ORDERING;
          fragmentName = 'productBaseFragment';
          operation = 'product';
          Object.assign(variables.filter, {
            ...{
              images_some: {
                fullFileResource: {
                  deleted: false,
                },
              },
            },
          });
          break;
        case 'LargeProduct':
          filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
          orderByType = GqlEntityOrderingType.LARGE_PRODUCT_ORDERING;
          fragmentName = 'largeProductBaseFragment';
          operation = 'largeProduct';
          Object.assign(variables.filter, {
            ...{
              images_some: {
                fullFileResource: {
                  deleted: false,
                },
              },
            },
          });
          break;
        case 'Deal':
          filterType = GqlEntityFilterType.DEAL_FILTER;
          orderByType = GqlEntityOrderingType.DEAL_ORDERING;
          fragmentName = 'dealCardFragment';
          operation = 'deal';
          Object.assign(variables.filter, {
            ...{
              displayFileResource: {
                deleted: false,
              },
            },
          });
          break;
        default:
          break;
      }
      if (filter) {
        variables.filter = mergeDeep(variables.filter, JSON.parse(filter || '{}'));
      }
      configs.push({
        gqlDefinition: buildQueryDefinition({
          cacheable: !this.context.rootGetters.authUser
      || (!deepSearch(variables.filter, this.context.rootGetters.authUser.uid)
            && !deepSearch(variables.filter, '%authUser%')),
          filter: {
            value: variables.filter,
            type: filterType,
          },
          orderBy: {
            value: ['_random_asc'],
            type: orderByType,
          },
          first: 12,
        }),
        operation,
        fragmentName,
        alias: this.widgetStoreName,
      });
      this.context.commit('setGqlQueryConfig', configs);
      this.context.commit(
        'WidgetDispatcherStore/setGqlQueryConfigs',
        { key: this.widgetStoreName, values: configs },
        { root: true },
      );
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      data: this.data,
    };
  }
}
