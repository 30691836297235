import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import ExhibitorTag from '@/models/graphql/ExhibitorTag';
import ExhibitorTagRepository from '@/repositories/ExhibitorTagRepository';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';

interface ExhibitorTagState extends LoadableState {
  tags: Array<ExhibitorTag>;

  tag: ExhibitorTag;
}

@Module({ namespaced: true })
export default class ExhibitorTagStore extends LoadableStore<ExhibitorTagState> {
  tags: Array<ExhibitorTag> = [];

  tag: ExhibitorTag | null = null;

  private readonly repository = new ExhibitorTagRepository();

  @Action
  filter(payload: { filter: object; offset: number; first: number }): Promise<ExhibitorTag[]> {
    return this.repository.filter({
      operationName: 'LoadExhibitorTags',
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.EXHIBITOR_TAG_FILTER,
        },
        orderBy: {
          value: ['name_asc'],
          type: GqlEntityOrderingType.EXHIBITOR_TAG_ORDERING,
        },
        offset: payload.offset,
        first: payload.first,
      }),
    });
  }

  @Action
  create(payload: { name: string; exhibitor: string }): Promise<ExhibitorTag | null> {
    return this.repository.create({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.EXHIBITOR_TAG_INPUT,
        value: {
          name: payload.name,
        },
      },
      {
        fieldName: 'exhibitor_ExhibitorUid',
        type: 'ID',
        value: payload.exhibitor,
      },
      ]),
    });
  }

  @Action
  update(exhibitorTag: ExhibitorTag): Promise<ExhibitorTag | null> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.EXHIBITOR_TAG_INPUT,
        value: exhibitorTag,
      }]),
    });
  }

  @Action
  delete(uid: string): Promise<ExhibitorTag | null> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: GqlEntityInputType.ID,
        value: uid,
      }]),
    });
  }

  @Action
  addTargetLink(payload: { tagUid: string; targetUid: string }): Promise<ExhibitorTag | null> {
    return this.repository.addTargetLink({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: payload.tagUid,
        },
        {
          fieldName: 'linkTarget_LinkableUid',
          type: 'ID',
          value: payload.targetUid,
        },
      ]),
    });
  }

  @Action
  addTargetLinkOnBatch(payload: Array<{
    tagUid: string;
    targetUid: string;
  }>): Promise<Record<string, ExhibitorTag>[]> {
    const definitions = payload.map((conf, index) => ({
      operation: 'ExhibitorTagAddLinkTarget',
      gqlDefinition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: conf.tagUid,
        },
        {
          fieldName: 'linkTarget_LinkableUid',
          type: 'ID',
          value: conf.targetUid,
        },
      ]),
      fragmentName: 'exhibitorTagBaseFragment',
      alias: `add${index}`,
    }));
    return this.repository.manageExhibitorTagsLinksOnBatch({
      operationName: 'AddExhibitorTagLinkOnBatch',
      definitions,
    });
  }

  removeTargetLink(payload: { tagUid: string; targetUid: string }): Promise<ExhibitorTag | null> {
    return this.repository.removeTargetLink({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: payload.tagUid,
        },
        {
          fieldName: 'linkTarget_LinkableUid',
          type: 'ID',
          value: payload.targetUid,
        },
      ]),
    });
  }

  @Action
  removeTargetLinkOnBatch(payload: Array<{
    tagUid: string;
    targetUid: string;
  }>): Promise<Record<string, ExhibitorTag>[]> {
    const definitions = payload.map((conf, index) => ({
      operation: 'ExhibitorTagRemoveLinkTarget',
      gqlDefinition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: 'ID',
          value: conf.tagUid,
        },
        {
          fieldName: 'linkTarget_LinkableUid',
          type: 'ID',
          value: conf.targetUid,
        },
      ]),
      fragmentName: 'exhibitorTagBaseFragment',
      alias: `remove${index}`,
    }));
    return this.repository.manageExhibitorTagsLinksOnBatch({
      operationName: 'RemoveExhibitorTagLinkOnBatch',
      definitions,
    });
  }
}
