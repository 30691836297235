import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import Category from '@/models/graphql/Category';
import CategoryRepository from '@/repositories/CategoryRepository';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import { CategoryFilter } from '@/graphql/_Filters/CategoryFilter';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';

interface CategoryState extends LoadableState {
  cmsTableProductCategory: Category;

}

@Module({ namespaced: true })
export default class CategoryStore extends LoadableStore<CategoryState> {
  cmsTableProductCategory: Category | null = {} as Category;

  private readonly categoryRepository = new CategoryRepository();

  get fetchProductCategoryForCmsTable(): Category | null {
    return this.cmsTableProductCategory;
  }

  protected get repository(): CategoryRepository {
    return this.categoryRepository;
  }

  @Action
  loadCategories(filter: CategoryFilter): Promise<Category[]> {
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.CATEGORY_FILTER,
        },
        orderBy: {
          value: ['displaySequence_asc', 'name_asc'],
          type: GqlEntityOrderingType.CATEGORY_ORDERING,
        },
      }),
      fragmentName: 'categoryWithContextFragment',
    });
  }

  @Action
  loadProductCategoryForCmsTable(payload: { id: string; schemaCode: string }): Promise<void> {
    if (payload.id) {
      return this.repository.get({
        definition: buildQueryDefinition({
          filter: {
            value: payload,
            type: GqlEntityFilterType.CATEGORY_FILTER,
          },
        }),
        operationName: 'GetProductCategoryForCmsTable',
      }).then((response) => {
        if (response) {
          this.context.commit('setCmsTableProductCategory', response);
        }
        return Promise.resolve();
      });
    }
    this.context.commit('setCmsTableProductCategory', {});
    return Promise.resolve();
  }

  @Mutation
  setCmsTableProductCategory(category: Category): void {
    this.cmsTableProductCategory = category;
  }
}
