import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import CommunityUserChatRepository from '@/repositories/CommunityUserChatRepository';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import CommunityUser from '@/models/graphql/CommunityUser';

@Module({ namespaced: true })
export default class CommunityUserChatStore extends LoadableStore<LoadableState> {
    private readonly communityUserChatRepository = new CommunityUserChatRepository()

    private get repository(): CommunityUserChatRepository {
      return this.communityUserChatRepository;
    }

    @Action
    blockCommunityUsers(users: CommunityUser[]): Promise<void> {
      if (users.length > 0) {
        this.context.commit('load', true);
        return this.repository.blockCommunityUsers({
          definitions: users.map((u, index) => ({
            operation: 'blockUser',
            gqlDefinition: buildMutationDefinition([{
              fieldName: 'user',
              type: 'BaseInput!',
              value: {
                uid: u.uid,
              },
            }]),
            fragmentName: '',
            alias: `block${index}`,
          })),
        }).then(() => {
          this.context.commit('CommunityUserStore/setBlockedUsers', {
            users,
            rootState: this.context.rootState,
          }, { root: true });
          return Promise.resolve();
        }).finally(() => this.context.commit('load', false));
      }
      return Promise.resolve();
    }

    @Action
    unBlockCommunityUsers(users: CommunityUser[]): Promise<void> {
      if (users.length > 0) {
        this.context.commit('load', true);
        return this.repository.unBlockCommunityUsers({
          definitions: users.map((u, index) => ({
            operation: 'unblockUser',
            gqlDefinition: buildMutationDefinition([{
              fieldName: 'user',
              type: 'BaseInput!',
              value: {
                uid: u.uid,
              },
            }]),
            fragmentName: '',
            alias: `unBlock${index}`,
          })),
        }).then(() => {
          this.context.commit('CommunityUserStore/setUnBlockedUsers', {
            users,
            rootState: this.context.rootState,
          }, { root: true });
          return Promise.resolve();
        }).finally(() => this.context.commit('load', false));
      }
      return Promise.resolve();
    }
}
