import { Location } from 'vue-router';
import StringHelper from '@/utils/helpers/StringHelper';

export default class WidgetHelper {
  static storeName = (type = '', id = ''): string => `${StringHelper.snakeCaseToPascalCase(type)}${id}`;

  static widgetStorePath = (parent = '', child = ''): string => `${parent}/${child}`;

  static widgetStoreFullPath(parent = '', type = '', id = ''): string {
    return `${parent}/${WidgetHelper.storeName(type, id)}`;
  }

  static entityCode(entityCode: string, route: Location, defaultReturnValue: string | null = null): string {
    let r = entityCode;
    const matches = entityCode.match(/(%[a-zA-Z-_]+%)/gs);
    if (matches) {
      matches.forEach((m) => {
        const prop = m.replaceAll('%', '').trim();
        if (route && route.params && route.params[prop]) {
          r = r.replaceAll(m, route.params[prop]);
        } else if (route && route.query && route.query[prop]) {
          r = r.replaceAll(m, route.query[prop] as string);
        }
      });
      if (r !== entityCode) return r;
    }
    return defaultReturnValue || entityCode;
  }
}
