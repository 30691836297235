import { ApolloQueryResult, FetchResult } from '@apollo/client';
import CommunityUserPreferences from '@/models/graphql/CommunityUserPreferences';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import GetterInterface from '@/repositories/base/GetterInterface';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import CommunityUser from '@/models/graphql/CommunityUser';

/* eslint-disable max-len */
export default class CommunityUserPreferencesRepository extends GraphQlRepository<CommunityUserPreferences>
  implements GetterInterface<CommunityUserPreferences> {
  changePreferences(params: GqlPayloadParams): Promise<CommunityUserPreferences | undefined> {
    return buildMutationGql({
      operationName: 'ChangePreferences',
      operation: 'changeUserPreferences',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'communityUserPreferencesBaseFragment',
    }).then((mutation) => this.mutate<{ changeUserPreferences: CommunityUserPreferences }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ changeUserPreferences: CommunityUserPreferences }>) => response.data?.changeUserPreferences));
  }

  get(params: GqlPayloadParams): Promise<CommunityUserPreferences | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetCommunityUserPreferences',
      operation: 'communityUser',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'communityUserPreferencesFragment',
    }).then((query) => this.query<{ communityUser: CommunityUser[] }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ communityUser: CommunityUser[] }>) => {
        if (response
          && response.data
          && response.data.communityUser.length > 0
          && response.data.communityUser[0].preferences) {
          return response.data.communityUser[0].preferences;
        }
        return undefined;
      }));
  }
}
