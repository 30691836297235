import { render, staticRenderFns } from "./ToolboxTagsModal.vue?vue&type=template&id=187f8cc4&scoped=true&"
import script from "./ToolboxTagsModal.vue?vue&type=script&lang=ts&"
export * from "./ToolboxTagsModal.vue?vue&type=script&lang=ts&"
import style0 from "./ToolboxTagsModal.vue?vue&type=style&index=0&id=187f8cc4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187f8cc4",
  null
  
)

export default component.exports