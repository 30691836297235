import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import RootState from '@/store/states/RootState';
import { Data } from '@/utils/types/WidgetData';

@Module({ namespaced: true, stateFactory: true })
export default class MyProfileAboutFormStore extends WidgetBaseStore {
  @Action
  setDataConfigs(): void {
    super.setDataConfigs([{
      gqlDefinition: buildQueryDefinition({
        filter: {
          value: { uid: '%authUser%' },
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
      fragmentName: 'communityUserMyProfileAboutFormFragment',
      operation: 'communityUser',
      alias: this.widgetStoreName,
    }]);
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string; rootState?: RootState}): void {
    this.data = data.values;
    if (data.values
      && data.values.length > 0
      && Object.keys(data.values[0]).length > 0
      && data.rootState
      && data.rootState.authUser
      && data.values[0].uid === data.rootState.authUser.uid) {
      Object.assign(data.rootState.authUser, data.values[0]);
    }
  }
}
