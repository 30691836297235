






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import EntityType from '@/utils/enums/EntityType';
import CommunityUser from '@/models/graphql/CommunityUser';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

const chatStore = namespace('ChatDispatcherStore/ChatStore');

@Component({
  components: { FontAwesomeComponent, AvatarSoloWidget },
})
/* eslint-disable max-len */
export default class MessageUserSuggestionComponent extends Vue {
  @Getter
  private authUser!: CommunityUser;

  @chatStore.Mutation
  private toggleChat!: (close?: boolean) => void;

  @Prop({ required: true, default: '' })
  private readonly entityType!: EntityType;

  @Prop({ required: true, default: '' })
  private readonly entityId!: string | undefined;

  @Prop({ default: '' })
  private readonly src!: string | undefined | null;

  @Prop({ required: true, default: '' })
  private readonly title!: string | undefined;

  @Prop({ required: true, default: '' })
  private readonly lastName!: string | undefined;

  @Prop({ required: true, default: '' })
  private readonly firstName!: string | undefined;

  @Prop({ required: true, default: '' })
  private readonly subtitle!: string | undefined;

  @Prop({ required: false, default: false })
  private readonly isToggleItem!: boolean;

  @Prop({ default: undefined })
  private ourConnection!: CommunityUserConnection;

  @Prop({ required: false, default: false })
  private readonly selected!: boolean;

  private onClick(): void {
    if (!this.isToggleItem && this.entityId) {
      this.$router.push({
        name: 'member-detail', params: { memberId: this.entityId },
      }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }).finally(() => {
        this.toggleChat(true);
        this.$eventsBus.emit('close-conversation-option-modal');
        this.$eventsBus.emit('close-toolbox');
      });
    } else {
      this.$emit('on-select', this.entityId);
    }
  }
}
