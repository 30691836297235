var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isItForYou)?_c('div',{staticClass:"notification-item"},[_c('div',{staticClass:"d-flex p-3 column-gap-4"},[_c('div',[_c('avatar-solo-widget',{staticClass:"user-avatar border-0 bg-transparent",attrs:{"src":_vm.image,"with-borders":true,"default-icon":"fa-regular fa-buildings","icon-classes":"icon-16 text-neutral-n-6-label","size":"40"}})],1),_c('div',{staticClass:"d-flex flex-column text-left"},[_c('label',{staticClass:"label-2 text-neutral-n-8-dark-ink"},[_c('div',{staticClass:"d-inline-flex",on:{"click":_vm.onInteractedWith}},[(_vm.receiverHasBeenCancelledInvitation
              || _vm.receiverHasDeclinedInvitation
              || _vm.receiverHasAcceptedInvitation
              || _vm.receiverIsInvited
              || _vm.receiverIsDeclined)?_c('b-link',{staticClass:"m-0 text-decoration-none label label-2 medium text-neutral-n-8-dark-ink",attrs:{"to":_vm.navigateTo}},[_vm._v(" "+_vm._s(_vm.fullName)+" ")]):_vm._e()],1),_vm._v(" "+_vm._s(_vm.content)+" "),_c('div',{staticClass:"d-inline-flex",on:{"click":_vm.onInteractedWith}},[(!_vm.receiverHasBeenCancelledInvitation
              && !_vm.receiverHasDeclinedInvitation
              && !_vm.receiverHasAcceptedInvitation
              && (_vm.receiverIsAccepted || _vm.receiverIsCancelled))?_c('b-link',{staticClass:"m-0 text-decoration-none label label-2 medium text-neutral-n-8-dark-ink",attrs:{"to":_vm.navigateTo},on:{"click":_vm.onInteractedWith}},[_vm._v(" "+_vm._s(_vm.fullName)+" ")]):_vm._e()],1)]),_c('label',{staticClass:"m-0 label-2 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.dateReceived)+" ")]),(_vm.receiverIsInvited
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_ENABLE_COMPANY_INVITATION)
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_ENABLE_COMPANY_INVITATION).enabled)?_c('div',{staticClass:"notification-item-buttons d-flex column-gap-4 mt-2"},[_c('button',{class:['paragraph paragraph-2 font-weight-bold text-blue-b-3-primary-blue border-0',
                   'text-uppercase bg-transparent p-0'],on:{"click":_vm.onAccept}},[_vm._v(" "+_vm._s(_vm.$t('my-notification-list-component.company.accept'))+" ")]),_c('button',{class:['paragraph paragraph-2 font-weight-bold text-neutral-n-6-label border-0',
                   'text-uppercase bg-transparent p-0'],on:{"click":_vm.onDecline}},[_vm._v(" "+_vm._s(_vm.$t('my-notification-list-component.company.decline'))+" ")])]):(_vm.receiverIsDeclined)?_c('p',{staticClass:"m-0 pt-2 paragraph-2 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('my-notification-list-component.company.request-declined'))+" ")]):_vm._e()]),_c('div',{staticClass:"notification-item-icons ml-auto position-relative"},[(!_vm.notification.interactedWith)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-solid fa-scrubber","icon-classes":"icon-16 text-red-r-3-primary-red"}})],1):_vm._e(),_c('button-icon-component',{staticClass:"xmark-button position-absolute top-0 right-0",attrs:{"id":("remove-button-" + (_vm.notification.uid)),"boundry":"window","shape":"round","size":"md","variant":"tertiary"},on:{"on-click":_vm.onDelete},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-xmark","icon-classes":"neutral-n-8-dark-ink"}})]},proxy:true}],null,false,2243841175)}),_c('b-tooltip',{attrs:{"target":("remove-button-" + (_vm.notification.uid)),"boundary":"document","custom-class":"remove-button-tooltip","placement":"bottom","triggers":"hover"}},[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t('my-notification-list-component.tooltip-remove'))+" ")])])],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }