import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import { ApolloQueryResult } from '@apollo/client';
import Meeting from '@/models/graphql/Meeting';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';

export default class MeetingRepository extends GraphQlRepository<Meeting>
  implements FilterInterface<Meeting> {
  filter(params: GqlPayloadParams): Promise<Meeting[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterMeetings',
      operation: 'meeting',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'meetingFullFragment',
      authUser: params.authUser,
    }).then((query) => this.use().query<{ meeting: Array<Meeting> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ meeting: Array<Meeting> }>) => response.data.meeting));
  }
}
