import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CommunityUserFollow from '@/models/graphql/CommunityUserFollow';
import CommunityUserFollowRepository from '@/repositories/CommunityUserFollowRepository';
import { CommunityUserFollowFilter } from '@/graphql/_Filters/CommunityUserFollowFilter';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Module({ namespaced: true })
/* eslint-disable max-len */
export default class CommunityUserFollowStore extends LoadableStore<LoadableState> {
  private readonly communityUserFollowRepository = new CommunityUserFollowRepository();

  protected get repository(): CommunityUserFollowRepository {
    return this.communityUserFollowRepository;
  }

  @Action
  follow(payload: CommunityUserFollowFilter): Promise<CommunityUserFollow | undefined> {
    this.context.commit('load', true);
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'user_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: payload.userId,
        },
        {
          fieldName: 'linkTarget_LinkableUid',
          type: GqlEntityInputType.ID,
          value: payload.linkedUserId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMUNITY_USER_FOLLOW_INPUT,
          value: { targetType: payload.entityType },
        },
      ]),
    }).then((response) => response)
      .finally(() => {
        this.context.commit('load', false);
      });
  }

  @Action({ rawError: true })
  unFollow(payload: CommunityUserFollowFilter): Promise<CommunityUserFollow | undefined> {
    this.context.commit('load', true);
    return this.repository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.uid,
        },
      ]),
    }).then((response) => response)
      .finally(() => {
        this.context.commit('load', false);
      });
  }

  @Action({ rawError: true })
  unFollowEntity(payload: { userId: string; entityId: string }): Promise<CommunityUserFollow | undefined> {
    this.context.commit('load', true);
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.COMMUNITY_USER_FOLLOW_FILTER,
          value: {
            user: { uid: payload.userId },
            link: {
              target: {
                uid: payload.entityId,
              },
            },
          },
        },
      }),
    }).then((follow) => {
      if (follow && follow.uid) {
        return this.repository.delete({
          definition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: GqlEntityInputType.ID,
              value: follow.uid,
            },
          ]),
        });
      }
      return follow;
    })
      .finally(() => {
        this.context.commit('load', false);
      });
  }

  @Action
  bookmarkAutoFollow(payload: { userId: string; linkedUserId: string; entityType: string }): Promise<void> {
    return this.repository.get({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.COMMUNITY_USER_FOLLOW_FILTER,
          value: {
            user: { uid: payload.userId },
            link: {
              target: {
                uid: payload.linkedUserId,
              },
            },
          },
        },
      }),
    }).then(() => Promise.resolve());
  }
}
