import { ApolloQueryResult, FetchResult } from '@apollo/client';
import ExhibitorUrl from '@/models/graphql/ExhibitorUrl';
import FilterInterface from '@/repositories/base/FilterInterface';
import WriteInterface from '@/repositories/base/WriteInterface';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';

/* eslint-disable max-len */
export default class ExhibitorUrlRepository extends GraphQlRepository<ExhibitorUrl>
  implements FilterInterface<ExhibitorUrl>, WriteInterface<ExhibitorUrl> {
  filter(params: GqlPayloadParams): Promise<ExhibitorUrl[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterExhibitorUrls',
      operation: 'exhibitorUrl',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'exhibitorUrlBaseFragment',
    }).then((query) => this.query<{ exhibitorUrl: Array<ExhibitorUrl> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ exhibitorUrl: Array<ExhibitorUrl> }>) => response.data.exhibitorUrl));
  }

  create(params: GqlPayloadParams): Promise<ExhibitorUrl | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateExhibitorUrl',
      operation: 'ExhibitorUrlCreateForExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorUrlBaseFragment',
      alias: 'ExhibitorUrlCreate',
    }).then((mutation) => this.mutate<{ ExhibitorUrlCreate: ExhibitorUrl }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorUrlCreate: ExhibitorUrl }>) => response.data?.ExhibitorUrlCreate));
  }

  update(params: GqlPayloadParams): Promise<ExhibitorUrl | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'ExhibitorUrlUpdate',
      operation: 'ExhibitorUrlUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorUrlBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorUrlUpdate: ExhibitorUrl }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorUrlUpdate: ExhibitorUrl }>) => response.data?.ExhibitorUrlUpdate));
  }

  delete(params: GqlPayloadParams): Promise<ExhibitorUrl | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeleteExhibitorUrl',
      operation: 'ExhibitorUrlDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorUrlBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorUrlDelete: ExhibitorUrl }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorUrlDelete: ExhibitorUrl }>) => response.data?.ExhibitorUrlDelete));
  }
}
