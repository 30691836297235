





















































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueContextLoading from '@/components/VueContextLoading';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import SegmentedElementParams from '@/utils/types/SegmentedElementParams';
import SizeDigit from '@/utils/enums/SizeDigit';
import { State } from 'vuex-class';
import LocaleModel from '@/models/LocaleModel';
import SegmentedControlsComponent from '@/components/SegmentedControlsComponent.vue';
import EntityTranslationParams from '@/utils/types/EntityTranslationParams';
import ClientStorage from '@/utils/ClientStore';

@Component({
  components: { SegmentedControlsComponent, FontAwesomeComponent },
  data(): object {
    return {
      inputText: '',
    };
  },
})
export default class TextAreaComponent extends VueContextLoading {
  @Prop({ default: '' })
  readonly label!: string;

  @Prop({ default: '' })
  readonly subTitle!: string;

  @Prop({ default: '' })
  readonly defaultValue!: string;

  @Prop({ default: '' })
  readonly hintText!: string;

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Prop({ default: false })
  readonly isActive!: boolean;

  @Prop({ default: false })
  readonly hasErrors!: boolean;

  @Prop({ default: 4 })
  readonly rowsHeight!: number;

  @Prop({ default: false })
  readonly translatable!: boolean;

  @Prop({ default: () => [] })
  readonly translations!: EntityTranslationParams[];

  private isActiveParam = this.isActive;

  private isConfirmed = false;

  @State
  private readonly locales!: LocaleModel[];

  private selectedLocale = ClientStorage.getItem('locale');

  private localTabs: SegmentedElementParams[] = [];

  private selectedTranslation: EntityTranslationParams | undefined = undefined;

  mounted(): void {
    this.$data.inputText = this.defaultValue;
    this.buildTabConfig();
  }

  private switchTab(tab: SegmentedElementParams): void {
    this.selectedLocale = tab.key;
    this.selectedTranslation = this.translations.find((t) => t.locale === tab.key);
    if (this.selectedTranslation) {
      this.$data.inputText = this.selectedTranslation.value || '';
    }
    this.localTabs.forEach((v) => {
      v.activeState = tab.key === v.key;
    });
  }

  @Watch('defaultValue')
  @Watch('translations')
  @Watch('translatable')
  updateInput(): void {
    this.$data.inputText = this.defaultValue;
    this.buildTabConfig();
  }

  private buildTabConfig(): void {
    if (this.translatable && this.translations.length > 0) {
      this.localTabs = this.locales.map((l) => {
        const name = (l.locale as string).split('_')[0];
        const isTranslationMissing = this.translations.findIndex((t) => t.locale === l.locale && t.value) === -1;
        return {
          text: name.charAt(0).toUpperCase() + name.slice(1),
          key: l.locale as string,
          activeState: l.locale === this.selectedLocale,
          icon: isTranslationMissing ? 'far fa-warning' : '',
          iconColor: isTranslationMissing ? 'text-yellow-y-3-primary-yellow' : '',
          size: SizeDigit.S_24,
        };
      });
      this.selectedTranslation = this.translations.find((t) => t.locale === this.selectedLocale);
      if (this.selectedTranslation) {
        this.$data.inputText = this.selectedTranslation.value;
      }
    }
  }

  focusIn(): void {
    this.$emit('on-focus-in');
    this.isActiveParam = true;
  }

  onBlur(): void {
    this.$emit('blur');
    this.isActiveParam = false;
  }

  @Watch('isLoading')
  watchLoading(): void {
    this.setIsConfirmed();
  }

  @Watch('selfContext')
  watchSelfContext(): void {
    this.setIsConfirmed();
  }

  setIsConfirmed(): void {
    this.isConfirmed = this.selfContext !== '' && !this.isLoading;
  }

  @Watch('isConfirmed')
  watchIsConfirmed(): void {
    if (this.isConfirmed) {
      setTimeout(() => {
        this.isConfirmed = false;
      }, 5000);
    }
  }

  private onChange(): void {
    if (this.selectedTranslation) {
      this.selectedTranslation.value = this.$data.inputText && this.$data.inputText.length ? this.$data.inputText : null;
      this.buildTabConfig();
      this.$emit('on-translation-update', this.selectedTranslation);
    } else {
      this.$emit('update:defaultValue', this.$data.inputText);
    }
  }
}
