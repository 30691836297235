import { Inject, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class VueContextLoading extends Vue {
  @Inject({ from: 'context', default: '' })
  protected context!: string | string[];

  @Prop({ default: null })
  public selfContext!: string;

  protected getSelfContext(): string {
    return this.selfContext;
  }

  protected getContext(resource?: string, context?: string): string {
    let ctx = '';
    if (context) {
      ctx = context;
    } else {
      const selfContext = this.getSelfContext();
      ctx = selfContext != null ? selfContext : ((this.context as string) || '');
    }

    if (ctx === 'root') {
      ctx = '';
    }

    if (resource) {
      if (ctx) {
        ctx = `${ctx}/${resource}`;
      } else {
        ctx = resource;
      }
    }

    return ctx;
  }

  protected get isLoading(): boolean {
    const selfContext = this.getSelfContext();
    let context = selfContext != null ? selfContext : (this.context || '');

    if (context === 'root') {
      context = '';
    }

    if (Array.isArray(context)) {
      // eslint-disable-next-line max-len
      return context.map((e) => this.$store.getters[this.getContext('isLoading', e)]).reduce((prevValue, currentValue) => prevValue || currentValue);
    }
    return this.$store.getters[this.getContext('isLoading', context)];
  }
}
