

























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import MeetingParticipantState from '@/utils/enums/MeetingParticipantState';
import Meeting from '@/models/graphql/Meeting';
import { Getter, namespace, State } from 'vuex-class';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import MessageBoxDateSeparatorComponent
  from '@/components/chat/MessageBoxDateSeparatorComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { format, fromUnixTime } from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import EntityType from '@/utils/enums/EntityType';
import Community from '@/models/graphql/Community';

const agendaStore = namespace('AgendaStore');
@Component({
  components: { FontAwesomeComponent, MessageBoxDateSeparatorComponent, AvatarSoloWidget },
})
export default class MessageBoxMeetingBubbleComponent extends Vue {
  @Prop({ required: true })
  private readonly meeting!: Meeting;

  @Prop({ required: false, default: false })
  private readonly fromInsideMeeting!: boolean;

  @Prop({ default: false })
  private readonly darkMode!: boolean;

  @agendaStore.Action
  private updateMeetingParticipantState!: (payload: {
    meetingParticipantUid: string;
    state: string;
  }) => Promise<void>;

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private readonly community!: Community;

  @State
  private dateLocale!: Locale;

  private MeetingParticipantState = MeetingParticipantState;

  private get currentMeetingParticipantUid(): string | null {
    if (this.meeting.participants
      && this.meeting.participants.length > 0) {
      const state = this.meeting.participants
        .find((p) => p.user && p.user.uid === this.authUser.uid);
      if (state && state.uid) {
        return state.uid;
      }
    }

    return null;
  }

  private get isCreator(): boolean {
    if (this.meeting.creatorUser && this.meeting.creatorUser.uid) {
      return this.meeting.creatorUser.uid === this.authUser.uid;
    }
    return false;
  }

  private get myMeetingState(): MeetingParticipantState | null {
    if (this.meeting.participants
      && this.meeting.participants.length > 0) {
      const state = this.meeting.participants
        .find((p) => p.user && p.user.uid === this.authUser.uid);
      if (state && state.state) {
        return state.state;
      }
    }
    return null;
  }

  private get userMeetingInfo(): Record<string, string | Date | null> | null {
    const { creatorUser, participants } = this.meeting;
    let starttime = '';
    if (this.meeting.startTimestamp) {
      starttime += format(fromUnixTime(this.meeting.startTimestamp),
        `${this.$t('app.date.defaultDateFormat')}
        ${this.$t('app.date.dateTimeAtSeparator')}
        ${this.$t('app.date.defaultTimeFormat')}`,
        { locale: this.dateLocale });
    }
    if (creatorUser && creatorUser.uid === this.authUser.uid) {
      return {
        firstName: creatorUser.firstName || '',
        lastName: creatorUser.lastName || '',
        avatar: creatorUser.pictureFileResource
          ? FileResourceHelper.getFullPath(creatorUser.pictureFileResource, 'w40') : '',
        sentdate: new Date(this.meeting.createdTime as unknown as string),
        senttime: format(
          DateTimeHelper.toLocal(new Date(this.meeting.createdTime as unknown as string)),
          `${this.$t('app.date.defaultTimeFormat')}`,
          { locale: this.dateLocale },
        ),
        subject: this.meeting.subject || '',
        starttime,
        meeting: this.meeting.uid,
        state: null,
      };
    }
    if (participants && participants.length > 0) {
      const participant = participants.find((p) => p.user && p.user.uid === this.authUser.uid);
      if (participant && participant.user) {
        const { user } = participant;
        return {
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          avatar: user.pictureFileResource ? FileResourceHelper.getFullPath(user.pictureFileResource, 'w40') : '',
          sentdate: new Date(this.meeting.createdTime as unknown as string),
          senttime: format(
            DateTimeHelper.toLocal(new Date(this.meeting.createdTime as unknown as string)),
            `${this.$t('app.date.defaultTimeFormat')}`,
            { locale: this.dateLocale },
          ),
          subject: this.meeting.subject || '',
          starttime,
          meeting: this.meeting.uid,
          state: participant.state || '',
        };
      }
    }
    return null;
  }

  private onAccept(): void {
    if (!this.isCreator
      && this.currentMeetingParticipantUid
      && this.myMeetingState !== MeetingParticipantState.ACCEPTED) {
      const found = this.meeting
        .participants?.find((p) => p.uid === this.currentMeetingParticipantUid);
      const prevState = this.myMeetingState;
      if (found) {
        found.state = MeetingParticipantState.ACCEPTED;
      }
      this.updateMeetingParticipantState({
        meetingParticipantUid: this.currentMeetingParticipantUid,
        state: MeetingParticipantState.ACCEPTED,
      }).then(() => {
        this.$emit('on-accept-meeting-invite');
      }).catch(() => {
        if (found && prevState) {
          found.state = prevState;
        }
      });
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        EntityType.MEETING,
        StatLoggerActions.ACCEPT_TO_AGENDA,
        'acceptAppointment',
        -1,
        this.meeting.uid,
        StatLoggerCategories.ACCEPT,
        this.$i18n.locale,
      );
    }
  }

  private onDecline(): void {
    if (!this.isCreator
      && this.currentMeetingParticipantUid
      && this.myMeetingState !== MeetingParticipantState.DECLINED) {
      const found = this.meeting
        .participants?.find((p) => p.uid === this.currentMeetingParticipantUid);
      const prevState = this.myMeetingState;
      if (found) {
        found.state = MeetingParticipantState.DECLINED;
      }
      this.updateMeetingParticipantState({
        meetingParticipantUid: this.currentMeetingParticipantUid,
        state: MeetingParticipantState.DECLINED,
      }).then(() => {
        this.$emit('on-decline-meeting-invite');
      }).catch(() => {
        if (found && prevState) {
          found.state = prevState;
        }
      });
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        EntityType.MEETING,
        StatLoggerActions.REJECT_FROM_AGENDA,
        'rejectAppointment',
        -1,
        this.meeting.uid,
        StatLoggerCategories.REJECT,
        this.$i18n.locale,
      );
    }
  }
}
