 enum FeedPostType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  LINK = 'LINK',
  TEXT = 'TEXT',
  POLL = 'POLL',
  EMPTY = 'EMPTY',
  REPOST = 'REPOST',
}

export default FeedPostType;
