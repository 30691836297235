/* eslint-disable max-len */
import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CommunityUserUrl from '@/models/graphql/CommunityUserUrl';
import CommunityUserUrlRepository from '@/repositories/CommunityUserUrlRepository';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class CommunityUserUrlStore extends LoadableStore<LoadableState> {
  private readonly communityUserUrlRepository = new CommunityUserUrlRepository();

  @Action
  createUserUrl(userUrl: CommunityUserUrl): Promise<CommunityUserUrl | undefined> {
    this.context.commit('load', true);
    return this.communityUserUrlRepository.create({
      authUser: this.context.rootGetters.authUser.uid,
      definition: buildMutationDefinition([
        {
          fieldName: 'user_CommunityUserUid',
          type: 'ID',
          value: userUrl.user?.uid,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.COMMUNITY_USER_URL_INPUT,
          value: { name: userUrl.name, type: userUrl.type, url: userUrl.url },
        },
      ]),
    }).then((response) => response)
      .finally(() => {
        this.context.commit('load', false);
      });
  }

  @Action
  deleteUserUrl(uid: string): Promise<CommunityUserUrl | undefined> {
    this.context.commit('load', true);
    return this.communityUserUrlRepository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    }).then((response) => response)
      .finally(() => {
        this.context.commit('load', false);
      });
  }

  @Action({ rawError: true })
  updateUserUrl(url: CommunityUserUrl): Promise<CommunityUserUrl | undefined> {
    this.context.commit('load', true);
    return this.communityUserUrlRepository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_URL_INPUT,
        value: { uid: url.uid, url: url.url },
      },
      ]),
    }).then((response) => response)
      .finally(() => {
        this.context.commit('load', false);
      });
  }
}
