








































import { Component } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Vue from 'vue';
import Community from '@/models/graphql/Community';
import { Getter } from 'vuex-class';

@Component({
  components: {
    FontAwesomeComponent,
    ButtonComponent,
  },
})

export default class NotFound extends Vue {
  @Getter
  private readonly community!: Community;

  @Getter
  private readonly isUnifyExhibitorPortal!: boolean;

  private goHome(): void {
    if (this.isUnifyExhibitorPortal && this.$route.params.companyId) {
      this.$router.push({
        path: this.$route.params.companyId,
      });
      return;
    }
    this.$router.push({
      path: '/',
    });
  }
}
