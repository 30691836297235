import BaseService from '@/services/BaseService';

class CookieService extends BaseService {
  static setCookie(cname: string, cvalue: string, exdays?: number): void {
    let expires = '';
    if (exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      expires = `expires=${d.toUTCString()}`;
    }
    document.cookie = `${cname}=${cvalue};${expires};path=/;${process.env.NODE_ENV !== 'local' ? 'SameSite=None;Secure' : ''}`;
  }

  static getCookie(cname: string): string | null {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  static deleteCookie(cname: string): void {
    const expires = new Date(0).toUTCString();
    document.cookie = `${cname}=;expires=${expires};path=/`;
  }
}

export default CookieService;
