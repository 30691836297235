
















































import { Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ToolboxDashboardCardComponent
  from '@/components/toolbox/dashboard/ToolboxDashboardCardComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import Dashboard from '@/models/Dashboard';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import useTestDataAttribute from '@/utils/TestDataAttribute';

const dashboardStore = namespace('DashboardStore');

@Component({
  name: 'ToolboxDashboardContainer',
  methods: { useTestDataAttribute },
  components: {
    ToolboxDashboardCardComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
  },
})
export default class ToolboxDashboardContainer extends BreakpointWrapper {
  @Getter
  private authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature | undefined;

  @dashboardStore.State
  private readonly stats!: Dashboard | null;

  @dashboardStore.Action
  private loadDashboardStats!: (filter: { authUser: string; currentTime: number }) => Promise<void>;

  created(): void {
    this.loadDashboardStats({
      authUser: this.authUser.uid,
      currentTime: DateTimeHelper.currentTimestamp,
    });
  }

  private canShowDetail(key: string): boolean {
    let feature = null;
    switch (key) {
      case 'meetings':
        feature = this.featureByKey(FeatureKeys.AGENDA);
        return feature ? feature.enabled : false;
      case 'connections':
        feature = this.featureByKey(FeatureKeys.CONNECTIONS);
        return feature ? feature.enabled : false;
      case 'messages':
        feature = this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE);
        return feature ? feature.enabled : false;
      default:
        return true;
    }
  }
}
