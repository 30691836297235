import { Action, Module, Mutation } from 'vuex-module-decorators';
import DynamicStore from '@/store/dynamic/DynamicStore';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import WidgetBaseRepository from '@/repositories/widget/WidgetBaseRepository';
import { BasicTypes } from '@/utils/types/BasicTypes';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import UiPage from '@/models/graphql/UiPage';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import { AppTranslation } from '@/models/graphql/AppTranslation';
import { mergeDeep, unflatten } from '@/utils/ObjectHelpers';
import { GraphQLError } from 'graphql';

@Module({ namespaced: true })
export default class WidgetDispatcherStore extends DynamicStore {
  protected parentStoreName = 'WidgetDispatcherStore';

  private gqlQueryConfigs: Record<string, Array<GqlComposeQueryDefinitionParams>> = {};

  private gqlQueries: Record<string, string> = {};

  private magicArgs: Record<string, BasicTypes> = {};

  private widgetBaseRepository = new WidgetBaseRepository();

  get repository(): WidgetBaseRepository {
    return this.widgetBaseRepository;
  }

  get fetchMagicArgs(): Record<string, BasicTypes> {
    return this.magicArgs;
  }

  @Action
  loadPageData(pageId: number): Promise<void> {
    const definitions = Object.keys(this.gqlQueryConfigs)
      .filter((key) => key.includes(String(pageId)))
      .map((key) => this.gqlQueryConfigs[key])
      .reduce((acc, params) => [...acc, ...params], []);

    const queries = Object.keys(this.gqlQueries)
      .filter((key) => key.includes(String(pageId)))
      .map((key) => this.gqlQueries[key])
      .reduce((acc, query) => `${acc} ${query}`, '');

    this.context.commit(
      'PageStateManagementStore/removeReadyToLoadData',
      pageId,
      { root: true },
    );

    if (definitions.length > 0 || queries) {
      return this.repository.load({
        params: {
          operationName: `LoadPageDataFor${pageId}`,
          definitions,
          magicArgs: this.magicArgs,
        },
        queries,
      })
        .then((response) => {
          Object.keys(response)
            .forEach((k) => {
              const path = k.split('_');
              const childStore = path[0];
              this.context.commit(
                `${this.parentStoreName}/${childStore}/setData`,
                {
                  key: k,
                  values: response[k],
                  rootState: this.context.rootState,
                },
                { root: true },
              );
            });
          this.subModules.forEach((k) => {
            const path = k.split('_');
            const childStore = path[0];
            this.context.commit(
              `${this.parentStoreName}/${childStore}/mapper`,
              null,
              { root: true },
            );
          });
        })
        .then(() => {
          this.context.commit(
            'PageStateManagementStore/initLoadingStates',
            pageId,
            { root: true },
          );
          this.context.commit('initGqlQueryConfigs');
          return Promise.resolve();
        })
        .catch((error) => {
          if (error instanceof GraphQLError) {
            // eslint-disable-next-line no-console
            console.error('Parsing error:', error.message);
          } else {
            // eslint-disable-next-line no-console
            console.error('Unexpected error:', error);
          }
        });
    }
    return Promise.resolve()
      .then(() => {
        this.subModules.forEach((k) => {
          this.context.commit(
            `${this.parentStoreName}/${k}/mapper`,
            null,
            { root: true },
          );
        });
        this.context.commit(
          'PageStateManagementStore/initLoadingStates',
          pageId,
          { root: true },
        );
        this.context.commit('initGqlQueryConfigs');
        return Promise.resolve();
      });
  }

  @Action
  loadPageDependencies(page: { uid: string; parentUid?: string }): Promise<void> {
    const { pages } = this.context.rootState;
    let parentPageIndex = -1;
    let pageIndex = -1;
    let operationName = '';
    if (page.parentUid) {
      parentPageIndex = pages.findIndex((p) => p.uid === page.parentUid);
      if (parentPageIndex > -1) {
        pageIndex = pages[parentPageIndex].childPages.findIndex((p) => p.uid === page.uid);
        operationName = `LoadPageDependenciesFor${pages[parentPageIndex].childPages[pageIndex].id}`;
      }
    } else {
      pageIndex = pages.findIndex((p) => p.uid === page.uid);
      operationName = `LoadPageDependenciesFor${pages[pageIndex].id}`;
    }
    const localesIn = [this.context.rootState.i18n?.locale || ''];
    if (this.context.rootState.i18n?.locale !== this.context.rootState.fallbackLocale) {
      localesIn.push(this.context.rootState.fallbackLocale);
    }
    const definitions = [{
      operation: 'uiPageWidget',
      gqlDefinition: buildQueryDefinition({
        cacheable: true,
        filter: {
          value: { page: { uid: page.uid }, active: true },
          type: GqlEntityFilterType.UI_PAGE_WIDGET_FILTER,
        },
      }),
      fragmentName: 'uiPageWidgetBaseFragment',
      alias: 'widgets',
    },
    {
      operation: 'appTranslation',
      gqlDefinition: buildQueryDefinition({
        cacheable: true,
        filter: {
          value: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            locale_in: localesIn,
            uiPages: { uid: page.uid },
          },
          type: GqlEntityFilterType.APP_TRANSLATION_FILTER,
        },
      }),
      fragmentName: 'appTranslationBaseFragment',
      alias: 'translations',
    }];
    return this.repository.load({
      params: {
        operationName,
        definitions,
      },
      queries: '',
    })
      .then((resources) => {
        if ('widgets' in resources && resources.widgets) {
          const widgets = resources.widgets as UiPageWidget[];
          if (parentPageIndex > -1) {
            this.context.rootState.pages[parentPageIndex].childPages[pageIndex].widgets = UiPage.mappingWidgets(widgets);
          } else {
            this.context.rootState.pages[pageIndex].widgets = UiPage.mappingWidgets(widgets);
          }
        }
        if ('translations' in resources && resources.translations) {
          const translations = resources.translations as AppTranslation[];
          const json: Record<string, string> = {};
          translations
            .filter((t) => t.locale === this.context.rootState.fallbackLocale)
            .forEach((translation): void => {
              json[translation.lookup] = translation.content;
            });
          translations
            .filter((t) => t.locale !== this.context.rootState.fallbackLocale)
            .forEach((translation): void => {
              json[translation.lookup] = translation.content;
            });

          const uf = unflatten(json) || {};
          const current = this.context.rootState.i18n?.locale || '';
          const locales = this.context.rootState.i18n?.messages[current];
          this.context.commit('setMessages',
            {
              locale: this.context.rootState.i18n?.locale,
              messages: mergeDeep(locales, uf),
            },
            { root: true });
        }
      });
  }

  @Action
  runGqlQuery(payload: {
    query: string;
    operationName: string;
  }): Promise<Record<string, BasicTypes[]>> {
    return this.repository.load({
      params: {
        operationName: payload.operationName,
        definitions: [],
      },
      queries: payload.query,
    });
  }

  @Mutation
  setGqlQueryConfigs(payload: {
    key: string;
    values: Array<GqlComposeQueryDefinitionParams>;
  }): void {
    if (!(payload.key in this.gqlQueryConfigs)) {
      Object.assign(this.gqlQueryConfigs, { [payload.key]: [] });
    }
    this.gqlQueryConfigs[payload.key] = [...this.gqlQueryConfigs[payload.key], ...payload.values];
  }

  @Mutation
  setGqlQuery(payload: { key: string; value: string }): void {
    if (!(payload.key in this.gqlQueries)) {
      Object.assign(this.gqlQueries, { [payload.key]: '' });
    }
    this.gqlQueries[payload.key] = payload.value;
  }

  @Mutation
  setMagicArgs(args: Record<string, BasicTypes>): void {
    this.magicArgs = { ...this.magicArgs, ...args };
  }

  @Mutation
  initGqlQueryConfigs(): void {
    this.gqlQueryConfigs = {};
    this.gqlQueries = {};
  }
}
