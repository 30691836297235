





























































































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import MessageBoxActions from '@/utils/enums/chat/MessageBoxActions';
import MessageGroup from '@/models/graphql/MessageGroup';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';
import MessageMenuItem from '@/utils/enums/chat/MessageMenuItem';
import ChatErrorList from '@/utils/types/chat/ChatErrorList';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import { RawLocation } from 'vue-router';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ToastActionType from '@/utils/enums/ToastActionType';
import GroupType from '@/utils/enums/chat/GroupType';
import useTestDataAttribute from '@/utils/TestDataAttribute';

const chatStore = namespace('ChatDispatcherStore/ChatStore');
const communityUserConnectionStore = namespace('CommunityUserConnectionStore');
const communityUserStore = namespace('CommunityUserStore');
const toastStore = namespace('ToastStore');

@Component({
  methods: { useTestDataAttribute },
  components: { FontAwesomeComponent, ButtonIconComponent },
})
export default class MessageBoxMenuComponent extends Vue {
  @Prop({ required: true })
  private readonly group!: MessageGroup;

  @Prop({ required: true })
  private readonly menuConfig!: MessageMenuItem[];

  @Prop({ required: false, default: false })
  private readonly flipMenu!: boolean;

  @Prop({ required: false, default: '' })
  private readonly title!: string;

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private readonly community!: Community;

  @chatStore.State
  private chatErrors!: ChatErrorList;

  @chatStore.Mutation
  private toggleChat!: (close?: boolean) => void;

  @chatStore.Action
  private updateState!: (model: {
    lastReadTimestamp: number;
    hideMessageBeforeTimestamp?: number;
    uid: string;
  }) => void;

  @chatStore.Action
  private toggleMuteState!: (payload: { uid: string; muted: boolean }) => Promise<string | null>;

  @communityUserConnectionStore.Action
  private loadConnections!: () => Promise<CommunityUserConnection[]>;

  @communityUserConnectionStore.State
  private stateConnections!: CommunityUserConnection[];

  @communityUserConnectionStore.Action
  private acceptConnection!: (payload: {
    uid: string; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private declineConnection!: (payload: {
    uid: string; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserStore.Getter
  private fetchBlockedCommunityUserUids!: string[];

  @chatStore.Action
  private acceptConversation!: (model: { uid: string }) => Promise<boolean>;

  @chatStore.Action
  private declineConversation!: (model: { uid: string }) => Promise<boolean>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private readonly MessageBoxActions = MessageBoxActions;

  private MessageMenuItem = MessageMenuItem;

  private GroupType = GroupType;

  private showMenu = false;

  private actionType: MessageMenuItem | null = null;

  private isBlocked = false;

  private get selectedGroup(): MessageGroup {
    return this.group;
  }

  private get members(): CommunityUser[] {
    if (this.actionType !== MessageMenuItem.VIEW) {
      const connections = this.stateConnections.map((c) => {
        if (c.user.uid === this.authUser.uid) {
          return CommunityUser.hydrate(c.connectedUser as CommunityUser);
        }
        return CommunityUser.hydrate(c.user);
      }) || [];

      if (this.selectedGroup && this.selectedGroup.users) {
        return connections
          .filter(({ uid: id1 }) => !(this.selectedGroup?.users as CommunityUser[])
            .some(({ uid: id2 }) => id2 === id1))
          .filter((u) => u.uid !== this.authUser.uid);
      }
    } else {
      return this.groupUsers;
    }

    return [];
  }

  private get muted(): string | null {
    return this.selectedGroup.isMuted;
  }

  private get groupUsers(): CommunityUser[] {
    return (this.selectedGroup.users || []).filter((u) => u.uid !== this.authUser.uid);
  }

  created(): void {
    if (this.selectedGroup
      && this.selectedGroup.groupType === GroupType.DIRECT
      && this.groupUsers.length === 1) {
      this.isBlocked = this.authUser.haveBlockedUser(this.groupUsers[0].uid);
    }
  }

  mounted(): void {
    if (this.selectedGroup
      && this.selectedGroup.groupType === GroupType.DIRECT
      && this.groupUsers.length === 1) {
      this.isBlocked = this.authUser.haveBlockedUser(this.groupUsers[0].uid);
    }
  }

  private onToggleMenu(): void {
    this.showMenu = !this.showMenu;
    if (this.showMenu
      && this.stateConnections
      && this.stateConnections.length === 0) {
      this.loadConnections();
    }
  }

  @Watch('fetchBlockedCommunityUserUids', { deep: true })
  private setIsBlocked(): void {
    this.isBlocked = this.fetchBlockedCommunityUserUids
      .filter((userUid: string) => this.selectedGroup.groupType === GroupType.DIRECT
        && this.groupUsers.length === 1
        && this.groupUsers[0].uid === userUid).length > 0;
  }

  private openViewMembers(): void {
    this.actionType = MessageMenuItem.VIEW;
    this.showMenu = false;
    const users = this.members;
    this.$eventsBus.emit('open-conversation-option-modal', {
      users,
      groupId: this.selectedGroup.uid,
      actionType: this.actionType,
    });
  }

  private openAddMembers(): void {
    this.actionType = MessageMenuItem.ADD;
    this.showMenu = false;
    const users = this.members;
    this.$eventsBus.emit('open-conversation-option-modal', {
      users,
      groupId: this.selectedGroup.uid,
      actionType: this.actionType,
    });
  }

  private openProfileDetail(): void {
    let route: RawLocation | null = null;
    /* eslint-disable no-underscore-dangle */
    if (this.selectedGroup.target
      && this.selectedGroup.target.__typename) {
      if (this.selectedGroup.target.__typename === EntityType.SESSION) {
        route = { name: 'session-detail', params: { sessionId: this.selectedGroup.target.uid } };
      }

      if (this.selectedGroup.target.__typename === EntityType.EXHIBITOR) {
        route = { name: 'company-detail', params: { companyId: this.selectedGroup.target.uid } };
      }
    } else if (this.groupUsers.length > 0) {
      route = {
        name: 'member-detail',
        params: {
          memberId: this.groupUsers[0].uid,
        },
      };
    }
    if (route) {
      this.$router.push(route).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }).finally(() => {
        this.showMenu = false;
        this.toggleChat(true);
        this.$eventsBus.emit('close-conversation-option-modal');
        this.$eventsBus.emit('close-toolbox');
      });
    }
  }

  private markAsUnread(): void {
    this.showMenu = false;
    this.$emit('remove-hover-state');
    if (this.selectedGroup
      && !this.selectedGroup.selected
      && this.selectedGroup.messages
      && this.selectedGroup.messages.length > 0
      && this.selectedGroup.myState
      && this.selectedGroup.lastMessageNotYours) {
      const lastMessage = this.selectedGroup.lastMessageNotYours;
      if (lastMessage) {
        const beforeLastMessageTimestamp = Math.floor(lastMessage.date.getTime() / 1000) - 60;
        this.updateState({
          uid: this.selectedGroup.myState.uid,
          lastReadTimestamp: beforeLastMessageTimestamp,
        });
      }
    }
  }

  private blockUser(): void {
    this.showMenu = false;
    this.actionType = this.isBlocked ? MessageMenuItem.UNBLOCK : MessageMenuItem.BLOCK;
    if (this.groupUsers && this.groupUsers.length === 1) {
      this.$eventsBus.emit('open-conversation-option-modal', {
        users: this.groupUsers,
        groupId: this.selectedGroup.uid,
        actionType: this.actionType,
      });
    }
  }

  private leaveGroup(): void {
    this.showMenu = false;
    this.actionType = MessageMenuItem.LEAVE;
    this.$eventsBus.emit('open-conversation-option-modal', {
      users: [{ uid: this.authUser.uid }],
      groupId: this.selectedGroup.uid,
      actionType: this.actionType,
    });
  }

  private deleteConversation(): void {
    this.showMenu = false;
    this.actionType = MessageMenuItem.DELETE;
    this.$eventsBus.emit('open-conversation-option-modal', {
      users: [{ uid: this.authUser.uid }],
      groupId: this.selectedGroup.uid,
      actionType: this.actionType,
    });
  }

  private onAcceptConnectionRequest(): void {
    if (this.selectedGroup && this.selectedGroup.uid) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.groupUsers.length > 0 && this.groupUsers[0]._ourConnection && this.groupUsers[0]._ourConnection.uid) {
        this.acceptConnection({
          // eslint-disable-next-line no-underscore-dangle
          uid: this.groupUsers[0]._ourConnection.uid,
          toastUserFullName: this.groupUsers[0].fullName ?? '',
        }).then(() => {
          this.acceptConversation({ uid: this.selectedGroup.uid as string });
        });
      }
    }
  }

  private onDeclineConnectionRequest(): void {
    if (this.selectedGroup && this.selectedGroup.uid) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.groupUsers.length > 0 && this.groupUsers[0]._ourConnection && this.groupUsers[0]._ourConnection.uid) {
        this.declineConnection({
          // eslint-disable-next-line no-underscore-dangle
          uid: this.groupUsers[0]._ourConnection.uid,
          toastUserFullName: this.groupUsers[0].fullName ?? '',
        }).then(() => {
          this.declineConversation({ uid: this.selectedGroup?.uid as string });
        });
      }
    }
  }

  private createMeeting(): void {
    this.$emit('create-meeting');
  }

  private toggleMute(): void {
    if (this.selectedGroup && this.selectedGroup.myState) {
      if (this.selectedGroup.myState && this.selectedGroup.myState.uid) {
        this.toggleMuteState({ uid: this.selectedGroup.myState.uid, muted: !(this.muted) });
        this.addNewAction(
          {
            type: !(this.muted)
              ? ToastActionType.CONVERSATION_MUTED : ToastActionType.CONVERSATION_UNMUTE,
            message: `${this.title} ${!(this.muted)
              ? this.$t('chat.content.list-view.item.menu.toast-mute')
              : this.$t('chat.content.list-view.item.menu.toast-unmute')}`,
          },
        );
      }
    }
  }
}
