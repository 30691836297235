enum MessageType {
  REGULAR = 'REGULAR',
  USER_JOIN = 'USER_JOIN',
  USER_INVITED = 'USER_INVITED',
  USER_CONNECTED = 'USER_CONNECTED',
  USER_LEAVE = 'USER_LEAVE',
  QNA = 'QNA',
  LINK = 'LINK',
}

export default MessageType;
