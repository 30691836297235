var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'reco-badge-container',
    ("" + _vm.variant),
    'd-flex align-items-center justify-content-center',
    {'label-xlg': _vm.size === _vm.sizeEnum.XLARGE},
    {'label-lg': _vm.size === _vm.sizeEnum.LARGE && !_vm.icon},
    {'label-lg-icon': _vm.size === _vm.sizeEnum.LARGE && _vm.icon},
    {'label-md': _vm.size === _vm.sizeEnum.MEDIUM},
    {'label-sm': _vm.size === _vm.sizeEnum.SMALL} ]},[(_vm.icon)?_c('font-awesome-component',{staticClass:"icon",attrs:{"icon":_vm.icon.iconDef,"icon-classes":_vm.icon.iconClass,"container-classes":"icon-12"}}):_vm._e(),(_vm.label)?_c('p',{class:[
      'm-0 text-nowrap badge-label',
      {'label label-1 lh-large': _vm.size === _vm.sizeEnum.XLARGE},
      {'label label-3': _vm.size === _vm.sizeEnum.LARGE
        && !_vm.isMobile},
      {'label label-2': _vm.size === _vm.sizeEnum.LARGE
        && _vm.isMobile},
      {'label label-4': _vm.size === _vm.sizeEnum.MEDIUM},
      {'label label-5': _vm.size === _vm.sizeEnum.SMALL} ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }