import { FetchResult } from '@apollo/client';
import ExtraProperty from '@/models/graphql/ExtraProperty';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import { buildComposeMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';

/* eslint-disable max-len */
export default class ExtraPropertyRepository extends GraphQlRepository<ExtraProperty> {
  createExtraPropertiesForConfigsAndLargeProduct(params: GqlComposeMutationPayloadParams): Promise<Record<string, ExtraProperty>[]> {
    return buildComposeMutationGql({
      operationName: 'CreateExtraPropertiesForConfigsAndLargeProduct',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, ExtraProperty>[], {}>(mutations))
      .then((response: FetchResult<Record<string, ExtraProperty>[]>) => response.data as Record<string, ExtraProperty>[]);
  }

  updateExtraProperties(params: GqlComposeMutationPayloadParams): Promise<Record<string, ExtraProperty>[]> {
    return buildComposeMutationGql({
      operationName: 'UpdateExtraProperties',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, ExtraProperty>[], {}>(mutations))
      .then((response: FetchResult<Record<string, ExtraProperty>[]>) => response.data as Record<string, ExtraProperty>[]);
  }

  deleteExtraProperties(params: GqlComposeMutationPayloadParams): Promise<Record<string, ExtraProperty>[]> {
    return buildComposeMutationGql({
      operationName: 'DeleteExtraProperties',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, ExtraProperty>[], {}>(mutations))
      .then((response: FetchResult<Record<string, ExtraProperty>[]>) => response.data as Record<string, ExtraProperty>[]);
  }
}
