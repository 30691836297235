import { DataWithNull } from '@/utils/types/WidgetDataWithNull';
import EntityType from '@/utils/enums/EntityType';
import Exhibitor from '@/models/graphql/Exhibitor';
import Product from '@/models/graphql/Product';
import LargeProduct from '@/models/graphql/LargeProduct';
import Session from '@/models/graphql/Session';
import SubEdition from '@/models/graphql/SubEdition';
import Deal from '@/models/graphql/Deal';
import Speaker from '@/models/graphql/Speaker';
import CommunityUser from '@/models/graphql/CommunityUser';
import Handout from '@/models/graphql/Handout';
import SponsorRole from '@/models/graphql/SponsorRole';
import Topic from '@/models/graphql/Topic';
import Category from '@/models/graphql/Category';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FileResource from '@/models/graphql/FileResource';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import Sponsor from '@/models/graphql/Sponsor';
import LargeProductImage from '@/models/graphql/LargeProductImage';
import ProductImage from '@/models/graphql/ProductImage';
import Channel from '@/models/graphql/Channel';

/* eslint-disable no-underscore-dangle */
export default class EntityListHelper {
  static mapData = (data: DataWithNull[]): DataWithNull[] => {
    const list: DataWithNull[] = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        switch (item.__typename) {
          case EntityType.EXHIBITOR:
            list.push(EntityListHelper.exhibitorMapData(item as unknown as Exhibitor));
            break;
          case EntityType.PRODUCT:
            list.push(EntityListHelper.productMapData(item as unknown as Product));
            break;
          case EntityType.LARGE_PRODUCT:
            list.push(EntityListHelper.largeProductMapData(item as unknown as LargeProduct));
            break;
          case EntityType.SESSION:
            list.push(EntityListHelper.sessionsMapData(item as unknown as Session));
            break;
          case EntityType.SUB_EDITION:
            list.push(EntityListHelper.subEditionMapData(item as unknown as SubEdition));
            break;
          case EntityType.DEAL:
            list.push(EntityListHelper.dealMapData(item as unknown as Deal));
            break;
          case EntityType.SPEAKER:
            list.push(EntityListHelper.speakerMapData(item as unknown as Speaker));
            break;
          case EntityType.USER:
            list.push(EntityListHelper.communityUserMapData(item as unknown as CommunityUser));
            break;
          case EntityType.HANDOUT:
            list.push(EntityListHelper.handoutMapData(item as unknown as Handout));
            break;
          case EntityType.SPONSOR:
            list.push(EntityListHelper.sponsorMapData(item as unknown as SponsorRole));
            break;
          case EntityType.TOPIC:
            list.push(EntityListHelper.topicMapData(item as unknown as Topic));
            break;
          case EntityType.CHANNEL:
            list.push(EntityListHelper.channelMapData(item as unknown as Channel));
            break;
          default:
            break;
        }
      });
    }
    return list;
  };

  static exhibitorMapData(item: Exhibitor): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      categories: (item.categoriesInContext as Category[] || []).map((c) => c.name)
        .join(', ') as string,
      logo: item.logoFileResource ? FileResourceHelper.getFullPath(item.logoFileResource as FileResource, 'w40') : null,
      type: EntityType.EXHIBITOR,
      checkbox: false,
      bookmarked: item._isBookmarked as (string | null),
      followed: item._isFollowed as (string | null),
      visited: item._isVisited as (string | null),
      actions: item._actions || null,
    };
  }

  static productMapData(item: Product): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      companyId: item.exhibitor ? item.exhibitor.uid : null,
      company: item.exhibitor && item.exhibitor.name ? item.exhibitor.name as string : null,
      logo: item.images
      && (item.images as ProductImage[]).length > 0
      && (item.images as ProductImage[])[0].fullFileResource
        ? FileResourceHelper.getFullPath((item.images as ProductImage[])[0].fullFileResource, 'w40')
        : null,
      type: EntityType.PRODUCT,
      bookmarked: item._isBookmarked as (string | null),
    };
  }

  static largeProductMapData(item: LargeProduct): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      companyId: item.exhibitor ? item.exhibitor.uid : null,
      company: item.exhibitor && item.exhibitor.name ? item.exhibitor.name as string : null,
      logo: item.images
      && (item.images as LargeProductImage[]).length > 0
      && (item.images as LargeProductImage[])[0].fullFileResource
        ? FileResourceHelper.getFullPath((item.images as LargeProductImage[])[0].fullFileResource, 'w40')
        : null,
      type: EntityType.LARGE_PRODUCT,
      bookmarked: item._isBookmarked as (string | null),
    };
  }

  static sessionsMapData(item: Session): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      startTimestamp: item.startTimestamp as number,
      endTimestamp: item.endTimestamp as number,
      logo: item.imageFileResource ? FileResourceHelper.getFullPath(item.imageFileResource as FileResource, 'w40') : null,
      type: 'Session',
      bookmarked: item._isBookmarked as (string | null),
      agenda: item._isInAgenda as (string | null),
      actions: item._actions || null,
    };
  }

  static subEditionMapData(item: SubEdition): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      startTimestamp: item.startTimestamp as number,
      endTimestamp: item.endTimestamp as number,
      code: item.code as string,
      logo: item.logoFileResource ? FileResourceHelper.getFullPath(item.logoFileResource as FileResource, 'w40') : null,
      type: EntityType.SUB_EDITION,
      bookmarked: item._isBookmarked as (string | null),
      followed: item._isFollowed as (string | null),
      archived: item.archived as boolean,
    };
  }

  static dealMapData(item: Deal): DataWithNull {
    return {
      uid: item.uid as string,
      title: item.name as string,
      companyId: item.exhibitor ? item.exhibitor.uid : null,
      startTimestamp: item.startTimestamp as number,
      endTimestamp: item.endTimestamp as number,
      logo: item.displayFileResource ? FileResourceHelper.getFullPath(item.displayFileResource as FileResource, 'w40') : null,
      type: EntityType.DEAL,
      bookmarked: item._isBookmarked as (string | null),
    };
  }

  static speakerMapData(item: Speaker): DataWithNull {
    let fullName = '';
    if (item.prefix) {
      fullName = `${item.prefix} `;
    }
    if (item.firstName) {
      fullName = `${fullName}${item.firstName}`;
    }
    if (item.lastName) {
      fullName = `${fullName} ${item.lastName}`;
    }
    if (item.suffix) {
      fullName = `${fullName}, ${item.suffix}`;
    }
    return {
      uid: item.uid as string,
      title: fullName,
      jobTitle: `${item.jobTitle ? item.jobTitle : ''}`,
      employerName: `${item.employerName ? item.employerName : ''}`,
      firstName: `${item.firstName ? item.firstName : ''}`,
      lastName: `${item.lastName ? item.lastName : ''}`,
      prefix: `${item.prefix ? item.prefix : ''}`,
      suffix: `${item.suffix ? item.suffix : ''}`,
      logo: item.photoFileResource
        ? FileResourceHelper.getFullPath(item.photoFileResource as FileResource, 'w40') : null,
      type: EntityType.SPEAKER,
      displaySequence: item._displaySequence as (number | null),
      bookmarked: item._isBookmarked as (string | null),
    };
  }

  static communityUserMapData(item: CommunityUser): DataWithNull {
    let companyName = '';
    const companyRoles: CompanyUserRole[] = item.companyRoles as CompanyUserRole[];
    if (companyRoles && companyRoles.length > 0) {
      const selectedCompanyUserRole = companyRoles
        .find((companyRole: CompanyUserRole) => companyRole.defaultCompany);
      if (selectedCompanyUserRole
        && selectedCompanyUserRole.company
        && selectedCompanyUserRole.company.name) {
        companyName = (selectedCompanyUserRole as CompanyUserRole).company?.name as string;
      }
    }
    return {
      uid: item.uid as string,
      title: `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`,
      jobTitle: `${item.jobTitle ? item.jobTitle : ''}`,
      employerName: `${item.employerName ? item.employerName : ''}`,
      firstName: `${item.firstName ? item.firstName : ''}`,
      lastName: `${item.lastName ? item.lastName : ''}`,
      logo: item.pictureFileResource
        ? FileResourceHelper.getFullPath(item.pictureFileResource as FileResource, 'w40') : null,
      isProfileVisible: CommunityUser.hydrate(item).isProfileVisible,
      type: EntityType.USER,
      companyName,
      ourConnection: item._ourConnection ? item._ourConnection : null,
      actions: item._actions || null,
      followed: item._isFollowed as (string | null),
    };
  }

  static handoutMapData(item: Handout): DataWithNull {
    const fileResource = item.handoutFileResource as FileResource;
    return {
      uid: item.uid as string,
      title: `${item.name}`,
      fileUrl: `${item.fileUrl}`,
      type: EntityType.HANDOUT,
      fileResourcePath: fileResource && fileResource.path ? FileResourceHelper.getFullPath(fileResource) : null,
      relatedEntity: item.relatedEntity || null,
    };
  }

  static sponsorMapData(item: Sponsor): DataWithNull {
    const fileResource = item.logoFileResource as FileResource;
    return {
      uid: item.uid as string,
      title: `${item.name}`,
      fileUrl: `${item.url}`,
      sponsorSubTitle: `${item.description}`,
      companyId: item.exhibitor?.uid || null,
      type: EntityType.SPONSOR,
      logo: fileResource
        ? FileResourceHelper.getFullPath(fileResource as FileResource, 'w40') : null,
    };
  }

  static topicMapData(item: Topic): DataWithNull {
    return {
      uid: item.uid,
      autoFollow: item.autoFollow as boolean,
      title: `${item.name}`,
      topicSubTitle: `${item.description}`,
      type: EntityType.TOPIC,
      logo: item.thumbnailFileResource
        ? FileResourceHelper.getFullPath(item.thumbnailFileResource as FileResource, 'w40') : null,
      followed: item._isFollowed as (string | null),
    };
  }

  static channelMapData(item: Channel): DataWithNull {
    const fileResource = item.imageFileResource as FileResource;
    return {
      uid: item.uid,
      title: `${item.name}`,
      type: EntityType.CHANNEL,
      logo: fileResource
        ? FileResourceHelper.getFullPath(fileResource as FileResource, 'w72') : null,
      followed: item._isFollowed as (boolean | null),
      bookmarked: item._isBookmarked as (boolean | null),
    };
  }
}
