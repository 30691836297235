import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import Address from '@/models/graphql/Address';
import { AddressFilter } from '@/graphql/_Filters/AddressFilter';
import EntityType from '@/utils/enums/EntityType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import LoadableStore from '@/store/LoadableStore';
import AddressRepository from '@/repositories/AddressRepository';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

interface AddressState extends LoadableState {
  addresses: Address[];
}

@Module({ namespaced: true })
/* eslint-disable max-len */
export default class AddressStore extends LoadableStore<AddressState> {
  addresses: Address[] = [];

  private readonly addressRepository = new AddressRepository();

  get fetchAddresses(): Address[] {
    return this.addresses;
  }

  protected get repository(): AddressRepository {
    return this.addressRepository;
  }

  @Action({ rawError: true })
  updateAddress(payload: {
    address: Address; addressFields: {
      tag: string | null;
      address1: string | null;
      address2: string | null;
      city: string | null;
      state: string | null;
      postalCode: string | null;
    };
  }): Promise<Address | undefined> {
    this.context.commit('load', true);

    const newAddress: Address = {} as Address;

    newAddress.uid = payload.address.uid;

    if (payload.addressFields.tag) {
      newAddress.tag = payload.addressFields.tag;
    } else if (typeof payload.addressFields.tag === 'string') {
      newAddress.tag = '';
    }
    if (payload.addressFields.address1) {
      newAddress.address1 = payload.addressFields.address1;
    } else if (typeof payload.addressFields.address1 === 'string') {
      newAddress.address1 = '';
    }
    if (payload.addressFields.address2) {
      newAddress.address2 = payload.addressFields.address2;
    } else if (typeof payload.addressFields.address2 === 'string') {
      newAddress.address2 = '';
    }
    if (payload.addressFields.city) {
      newAddress.city = payload.addressFields.city;
    } else if (typeof payload.addressFields.city === 'string') {
      newAddress.city = '';
    }
    if (payload.addressFields.state) {
      newAddress.state = payload.addressFields.state;
    } else if (typeof payload.addressFields.state === 'string') {
      newAddress.state = '';
    }
    if (payload.addressFields.postalCode) {
      newAddress.postalCode = payload.addressFields.postalCode;
    } else if (typeof payload.addressFields.postalCode === 'string') {
      newAddress.postalCode = '';
    }

    return this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.ADDRESS_INPUT,
          value: newAddress,
        },
      ]),
    });
  }

  @Action
  createAddress(payload: { address: Partial<Address>; entityId: string; type: EntityType }): Promise<Address | undefined> {
    const { address } = payload;
    address.country = undefined;
    return this.repository.create({
      definition: buildMutationDefinition([
        {
          type: GqlEntityInputType.ADDRESS_INPUT,
          fieldName: 'entity',
          value: address,
        },
      ]),
    }).then((response) => {
      if (response && response.uid) {
        switch (payload.type) {
          case EntityType.EXHIBITOR:
            this.context.dispatch('ExhibitorStore/setExhibitorAddress', {
              uid: payload.entityId, addressId: response?.uid,
            }, { root: true });
            break;

          case EntityType.USER:
            this.context.dispatch('CommunityUserAddressStore/setCommunityAddress', {
              uid: payload.entityId, addressId: response?.uid,
            }, { root: true });
            break;

          default:
            return Promise.reject(new Error('Type not supported'));
        }
      }
      return Promise.resolve(response);
    });
  }

  @Action
  addressSetCountry(payload: { countryUid: string; addressUid: string }): Promise<Address | undefined> {
    return this.repository.addressSetCountry({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: payload.addressUid,
        },
        {
          fieldName: 'country_CountryUid',
          type: GqlEntityInputType.ID,
          value: payload.countryUid,
        },
      ]),
    });
  }

  @Action
  deleteAddress(id: string): Promise<Address | undefined> {
    return this.repository.delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          value: id,
        },
      ]),
    });
  }

  @Action
  loadCompanyAddresses(filter: AddressFilter): Promise<Address | undefined | void> {
    const schemaCode = { schemaCode: filter.communityCode };
    // eslint-disable-next-line @typescript-eslint/camelcase
    const baseFilters = filter.companyId ? { exhibitors_some: { uid: filter.companyId } } : {};
    const tagFilter = filter.tag ? { tag: filter.tag } : {};
    return this.repository.get({
      operationName: 'GetCompanyAddresses',
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.ADDRESS_FILTER,
          value: {
            ...schemaCode,
            ...tagFilter,
            ...baseFilters,
          },
        },
      }),
    }).then((response) => {
      this.context.commit('setElements', response ? [response] : []);
      return response;
    });
  }

  @Mutation
  setElements(addresses: Address[]): void {
    this.addresses = addresses;
  }
}
