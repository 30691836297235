import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import Session from '@/models/graphql/Session';
import SessionRepository from '@/repositories/SessionRepository';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { SessionFilter } from '@/graphql/_Filters/SessionFilter';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';

interface SessionState extends LoadableState {
  sessions: Session[];
  stateSession: Session;
  scheduleEventSessions: Session[];
}

@Module({ namespaced: true })
export default class SessionStore extends LoadableStore<SessionState> {
  sessions: Session[] = [] as Session[];

  stateSession: Session = {} as Session;

  scheduleEventSessions: Session[] = [] as Session[];

  private readonly sessionRepository = new SessionRepository();

  get session(): Session | null {
    return this.stateSession;
  }

  get fetchSessions(): Session[] {
    return this.sessions;
  }

  get fetchScheduleEventSessions(): Session[] {
    return this.scheduleEventSessions;
  }

  protected get repository(): SessionRepository {
    return this.sessionRepository;
  }

  @Action
  get(payload: { filter: SessionFilter }): Promise<Session | undefined> {
    this.context.commit('load', true);
    return this.repository.get({
      definition: buildQueryDefinition({
        cacheable: true,
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.SESSION_FILTER,
        },
      }),
      fragmentName: 'sessionBaseFragment',
    }).then((response) => {
      this.context.commit('setElement', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Action
  loadLiveSession(payload: { uid: string }): Promise<Session | undefined> {
    return this.repository.get({
      definition: buildQueryDefinition({
        cacheable: true,
        filter: {
          value: { uid: payload.uid },
          type: GqlEntityFilterType.SESSION_FILTER,
        },
      }),
      fragmentName: 'sessionForLiveVideoFragment',
      authUser: this.context.rootState.authUser?.uid,
    });
  }

  @Action
  updateSession(payload: Partial<Session>): Promise<Session | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.SESSION_INPUT,
          value: payload,
        },
      ]),
    });
  }

  /* eslint-disable @typescript-eslint/camelcase */
  @Action
  loadMapSession(payload: {
    geozoneId: number; edition: string; authUser: string;
  }): Promise<Session[] | undefined> {
    return this.repository.filter({
      operationName: 'GetMapSession',
      definition: buildQueryDefinition({
        filter: {
          value: {
            parentSession: null,
            editionMappings_some: {
              editionSession_some: {
                schemaCode: payload.edition,
                geozone_some: {
                  id: payload.geozoneId,
                },
              },
            },
          },
          type: GqlEntityFilterType.SESSION_FILTER,
        },
      }),
      fragmentName: 'sessionMapFragment',
      authUser: payload.authUser,
    });
  }

  /* eslint-disable @typescript-eslint/camelcase */
  @Action
  loadScheduleEventSubSession(payload: { filter: string; orderBy: string }): Promise<Session[] | undefined> {
    this.context.commit('load', true);
    const order = {
      orderBy: {
        value: payload.orderBy ? payload.orderBy.split(',') : ['startTimestamp_asc'],
        type: GqlEntityOrderingType.SESSION_ORDERING,
      },
    };
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: JSON.parse(payload.filter || '{}'),
          type: GqlEntityFilterType.SESSION_FILTER,
        },
        ...order,
      }),
      fragmentName: 'sessionForLiveVideoFragment',
      authUser: this.context.rootState.authUser?.uid,
    }).then((response) => {
      this.context.commit('setData', response);
      this.context.commit('load', false);
      return response;
    });
  }

  @Mutation
  setData(list: Session[]): void {
    this.scheduleEventSessions = list;
  }

  @Mutation
  setElement(session: Session): void {
    this.stateSession = session;
  }
}
