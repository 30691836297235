














































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import EntityType from '@/utils/enums/EntityType';
import SurveyEntityNotification from '@/components/notificationBox/SurveyEntityNotification.vue';
import { Getter, namespace } from 'vuex-class';
import ActionType from '@/utils/enums/ActionType';
import { SurveyUserAnswerFilter } from '@/graphql/_Filters/SurveyUserAnswerFilter';
import SurveyUserAnswer from '@/models/graphql/SurveyUserAnswer';
import { SurveyFilter } from '@/graphql/_Filters/SurveyFilter';
import Survey from '@/models/graphql/Survey';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import SurveyStrategy from '@/utils/enums/SurveyStrategy';
import CommunityUser from '@/models/graphql/CommunityUser';
import SurveyGlobalNotification from '@/components/notificationBox/SurveyGlobalNotification.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import ConnectionRequestNotification from '@/components/notificationBox/ConnectionRequestNotification.vue';
import CompanyInvitationNotification from '@/components/notificationBox/CompanyInvitationNotification.vue';
import NotificationBoxType from '@/utils/enums/notification/NotificationBoxType';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import NotificationBoxEntity from '@/models/LocalStorage/NotificationBox';
import Notification from '@/models/graphql/Notification';
import MicroPollWidget from '@/components/MicroPollWidget.vue';
import { mixins } from 'vue-class-component';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const surveyUserAnswerStore = namespace('SurveyUserAnswerStore');
const surveyStore = namespace('SurveyStore');
const localStorageNotificationBoxStore = namespace('LocalStorageNotificationBoxStore');
const notificationStore = namespace('NotificationStore');
const authenticationStore = namespace('AuthenticationStore');

@Component({
  components: {
    MicroPollWidget,
    CompanyInvitationNotification,
    ConnectionRequestNotification,
    FontAwesomeComponent,
    ButtonIconComponent,
    SurveyGlobalNotification,
    SurveyEntityNotification,
  },
})
/* eslint-disable no-underscore-dangle */
export default class NotificationBox extends mixins(VueBaseNotify, BreakpointWrapper) {
  @Prop({
    required: true,
    default: null,
  })
  protected readonly entityType!: string | null;

  @Prop({
    required: true,
    default: null,
  })
  protected readonly entityUid!: string | null;

  @Getter
  private authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @surveyUserAnswerStore.Action(ActionType.GET_ONE)
  private getAnswer!: (payload: { filter: SurveyUserAnswerFilter; loneQuery?: boolean }) => Promise<SurveyUserAnswer>;

  @surveyStore.Action(ActionType.GET_ONE)
  private getSurvey!: (payload: {
    filter: SurveyFilter;
    fragmentName?: string;
    loneQuery?: boolean;
  }) => Promise<Survey>;

  @localStorageNotificationBoxStore.Action
  private addToIgnored!: (payload: { entityUid: string; notificationType?: NotificationBoxType }) => void;

  @localStorageNotificationBoxStore.Action
  private getIgnoredNotifications!: () => void;

  @localStorageNotificationBoxStore.Getter
  private fetchIgnoredNotifications!: NotificationBoxEntity[];

  @notificationStore.Action('paginatedMicropollNotifications')
  private paginatedMicropollNotifications!: (filter?: {
    notifiedUId: string;
    offset?: number;
    startTimestamp_lte: number;
    endTimestamp_gte?: number;
  }) => Promise<number | null>;

  @notificationStore.Action
  private setInteractedUser!: (uid: string) => void;

  @notificationStore.Getter('fetchMicroPollNotifications')
  private fetchedMicropollNotifications!: Notification[];

  @notificationStore.Mutation
  private setMicropollNotifications!: (notifications: Notification[]) => void;

  @authenticationStore.Getter
  private isAuthenticated!: boolean;

  private entityTypeEnum = EntityType;

  private NotificationBoxType = NotificationBoxType;

  private hasGlobalSurvey = false;

  private globalSurveyAnswered = true;

  private step = 1;

  private nbStep = 0;

  private notificationBoxList: NotificationBoxType[] = [];

  private survey: Survey | null = null;

  private currentCompanyInvite: CompanyUserRole | null = null;

  private FeatureKeys = FeatureKeys;

  private currentMicropollNotifyIndex = -1;

  private get currentMicropoll(): Survey | undefined {
    return this.fetchedMicropollNotifications.length > 0 && this.currentMicropollNotifyIndex > -1
      ? this.fetchedMicropollNotifications[this.currentMicropollNotifyIndex].initiator as Survey : undefined;
  }

  private get showMicropoll(): boolean {
    const hideMicroPoll = (this.$route.meta && this.$route.meta.showMicroPoll === false);
    return !hideMicroPoll
        && this.currentMicropollNotifyIndex > -1
        && this.currentMicropollNotifyIndex < this.fetchedMicropollNotifications.length;
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.SURVEY,
      NotificationEventType.MICROPOLL,
    ];
    this.getIgnoredNotifications();
  }

  mounted(): void {
    if (!this.isMobile) {
      this.updateDisplaySurveyNotification();
    }
    this.paginatedMicropollNotifications({
      notifiedUId: this.authUser.uid,
      offset: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      startTimestamp_lte: DateTimeHelper.currentTimestamp,
      // eslint-disable-next-line @typescript-eslint/camelcase
      endTimestamp_gte: DateTimeHelper.currentTimestamp,
    })
      .then(() => {
        if (this.fetchedMicropollNotifications.length > 0) {
          this.notificationBoxList.unshift(NotificationBoxType.MICROPOLL);
          this.currentMicropollNotifyIndex = 0;
        }
      });
  }

  nextMicropoll(): void {
    this.currentMicropollNotifyIndex += 1;
    if (this.fetchedMicropollNotifications.length === (this.currentMicropollNotifyIndex)) {
      this.removeFromList(NotificationBoxType.MICROPOLL);
    }
  }

  onMicropollInteract(): void {
    this.setInteractedUser(this.fetchedMicropollNotifications[this.currentMicropollNotifyIndex].uid);
    this.nextMicropoll();
  }

  notificationCallback(event: SubscriptionEvent): void {
    if (event.type === NotificationEventType.SURVEY) {
      this.updateDisplaySurveyNotification();
    }
    const notification = Notification.hydrate(event.data);
    if (event && (event.type === NotificationEventType.MICROPOLL
        || (event.type === NotificationEventType.SURVEY && notification.action === 'MICROPOLL'))) {
      this.setMicropollNotifications([...this.fetchedMicropollNotifications, notification]);
      if (this.currentMicropollNotifyIndex < 0) {
        if (!this.notificationBoxList.includes(NotificationBoxType.MICROPOLL)) {
          this.notificationBoxList.unshift(NotificationBoxType.MICROPOLL);
        }
        this.nextMicropoll();
      }
    }
  }

  @Watch('entityUid')
  private onPageChange(): void {
    this.removeFromList(NotificationBoxType.SESSION_SURVEY);
    this.removeFromList(NotificationBoxType.USER_INVITE);
  }

  private updateDisplaySurveyNotification(): void {
    const now = DateTimeHelper.currentTimestamp;
    /* eslint-disable @typescript-eslint/camelcase */
    this.getSurvey({
      filter: {
        AND: [
          { OR: [{ startTimestamp_lte: now }, { startTimestamp: null }] },
          { OR: [{ endTimestamp_gte: now }, { endTimestamp: null }] },
        ],
        strategy: SurveyStrategy.FEEDBACK_SURVEY,
      },
      loneQuery: true,
    })
      .then((survey) => {
        if (survey) {
          this.survey = survey;
          this.hasGlobalSurvey = true;
          this.getAnswer({
            filter: {
              user: { uid: this.authUser ? this.authUser.uid : '' },
              survey: { uid: survey.uid },
            },
            loneQuery: true,
          })
            .then((response) => {
              if (response) {
                this.globalSurveyAnswered = response && survey.uid === response.survey?.uid;
                this.removeFromList(NotificationBoxType.GLOBAL_SURVEY);
              } else if (this.entityUid === survey.uid) {
                this.removeFromList(NotificationBoxType.GLOBAL_SURVEY);
              } else if (!this.isRemovedFromList(NotificationBoxType.GLOBAL_SURVEY)) {
                this.step = 1;
                this.addToList(NotificationBoxType.GLOBAL_SURVEY);
                this.globalSurveyAnswered = false;
              } else {
                this.removeFromList(NotificationBoxType.GLOBAL_SURVEY);
              }
            });
        } else {
          this.removeFromList(NotificationBoxType.GLOBAL_SURVEY);
          this.hasGlobalSurvey = false;
        }
      });
  }

  private addToList(type: NotificationBoxType): void {
    if (!this.notificationBoxList.includes(type)) {
      this.notificationBoxList.push(type);
    }
  }

  private removeFromList(type: NotificationBoxType): void {
    const elementIndex = this.notificationBoxList.findIndex((e) => e === type);
    if (elementIndex > -1) {
      this.notificationBoxList.splice(elementIndex, 1);
    }
  }

  private displaySurveyEntityNotification(): void {
    this.step = 1;
    this.addToList(NotificationBoxType.SESSION_SURVEY);
  }

  private displayCompanyInviteNotification(companyInvite: CompanyUserRole | null): void {
    this.currentCompanyInvite = companyInvite;
    if (!this.isRemovedFromList(NotificationBoxType.COMPANY_INVITE)) {
      this.step = 1;
      this.addToList(NotificationBoxType.COMPANY_INVITE);
    }
  }

  private displayConnectionNotification(): void {
    this.step = 1;
    this.addToList(NotificationBoxType.USER_INVITE);
  }

  private hideCompanyInviteNotification(): void {
    this.currentCompanyInvite = null;
    this.step = 1;
    this.removeFromList(NotificationBoxType.COMPANY_INVITE);
  }

  private hideConnectionNotification(): void {
    this.step = 1;
    this.removeFromList(NotificationBoxType.USER_INVITE);
  }

  private onRemoveStepClick(): void {
    let entityUid = '';
    const notificationType = this.notificationBoxList[this.step - 1];
    switch (this.notificationBoxList[this.step - 1]) {
      case NotificationBoxType.GLOBAL_SURVEY:
        entityUid = this.survey?.uid || '';
        this.removeFromList(NotificationBoxType.GLOBAL_SURVEY);
        break;
      case NotificationBoxType.COMPANY_INVITE:
        this.removeFromList(NotificationBoxType.COMPANY_INVITE);
        entityUid = this.currentCompanyInvite?.company?.uid || '';
        break;
      case NotificationBoxType.SESSION_SURVEY:
        this.removeFromList(NotificationBoxType.SESSION_SURVEY);
        entityUid = this.entityUid || '';
        break;
      case NotificationBoxType.USER_INVITE:
        this.removeFromList(NotificationBoxType.USER_INVITE);
        entityUid = this.entityUid || '';
        break;
      case NotificationBoxType.MICROPOLL:
        this.onMicropollInteract();
        if (this.fetchedMicropollNotifications
            && this.fetchedMicropollNotifications.length === this.currentMicropollNotifyIndex) {
          this.removeFromList(NotificationBoxType.MICROPOLL);
        }
        break;
      default:
        break;
    }
    if (notificationType !== NotificationBoxType.MICROPOLL) {
      this.step = this.step > 1 ? this.step - 1 : 1;
      this.addToIgnored({
        entityUid,
        notificationType,
      });
    }
  }

  private previousStepClick(): void {
    if (this.step > 1) {
      this.step -= 1;
    } else {
      this.step = this.notificationBoxList.length;
    }
  }

  private nextStepClick(): void {
    if (this.step >= this.notificationBoxList.length) {
      this.step = 1;
    } else {
      this.step += 1;
    }
  }

  private isFeatureActivated(code: FeatureKeys): boolean {
    return this.featureByKey(code) && this.featureByKey(code).enabled;
  }

  private isRemovedFromList(notificationType: NotificationBoxType): boolean {
    if (this.fetchIgnoredNotifications && this.fetchIgnoredNotifications.length) {
      let uid = '';
      if (notificationType === NotificationBoxType.GLOBAL_SURVEY && this.survey) {
        uid = this.survey.uid;
      } else if (notificationType === NotificationBoxType.COMPANY_INVITE
          && this.currentCompanyInvite
          && this.currentCompanyInvite.company) {
        uid = this.currentCompanyInvite.company.uid;
      }
      if (notificationType === NotificationBoxType.USER_INVITE
          || notificationType === NotificationBoxType.SESSION_SURVEY) {
        uid = this.entityUid || '';
      }
      const item = this.fetchIgnoredNotifications
        .find((notif: NotificationBoxEntity) => notif.entityUid === uid
              && notif.notificationType === notificationType);
      if (item) {
        return true;
      }
    }
    return false;
  }
}
