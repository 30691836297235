










































import { Component, Prop, Vue } from 'vue-property-decorator';
import SizeDigit from '@/utils/enums/SizeDigit';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class SegmentedElementComponent extends Vue {
  @Prop({ required: false, default: null })
  private readonly text!: string;

  @Prop({ required: false, default: null })
  private readonly number!: number;

  @Prop({ required: false, default: SizeDigit.S_40 })
  private readonly size!: SizeDigit;

  @Prop({ required: false, default: null })
  private readonly icon!: string;

  @Prop({ required: false, default: 'LEFT' })
  private readonly iconPosition!: string;

  @Prop({ required: false, default: null })
  private readonly iconColor!: string;

  @Prop({ required: false, default: false })
  private readonly activeState!: boolean;

  private SizeDigit = SizeDigit;
}
