import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { Data } from '@/utils/types/WidgetData';
import ProfileRoleRelation from '@/utils/enums/ProfileRoleRelation';
import DomainContextParams from '@/utils/types/DomainContextParams';
/* eslint-disable @typescript-eslint/camelcase */

@Module({ namespaced: true, stateFactory: true })
export default class MemberAboutWidgetStore extends WidgetBaseStore {
  private memberAboutData: Record<string, Array<Data>> = {};

  @Action
  setDataConfigs(): void {
    if (this.widget) {
      const {
        memberCode,
      } = JSON.parse(this.widget.payload || '{}');
      const configs = [{
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: {
              user: {
                uid: memberCode,
              },
              relation: ProfileRoleRelation.I_AM,
            },
            type: GqlEntityFilterType.COMMUNITY_USER_PROFILE_ROLE_FILTER,
          },
        }),
        operation: 'communityUserProfileRole',
        fragmentName: 'communityUserProfileRoleBaseFragment',
        alias: `${this.widgetStoreName}_roles`,
      },
      {
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: {
              user: {
                uid: memberCode,
              },
              relation: ProfileRoleRelation.I_WANT,
            },
            type: GqlEntityFilterType.COMMUNITY_USER_PROFILE_ROLE_FILTER,
          },
        }),
        operation: 'communityUserProfileRole',
        fragmentName: 'communityUserProfileRoleBaseFragment',
        alias: `${this.widgetStoreName}_lookingToMeets`,
      },
      {
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: {
              user: {
                uid: memberCode,
              },
              interest: {
                contexts: {
                  context: DomainContextParams.interest,
                },
              },
            },
            type: GqlEntityFilterType.COMMUNITY_USER_INTEREST_FILTER,
          },
        }),
        operation: 'communityUserInterest',
        fragmentName: 'communityUserInterestBaseFragment',
        alias: `${this.widgetStoreName}_interests`,
      },
      {
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: {
              user: {
                uid: memberCode,
              },
              interest: {
                contexts: {
                  context: DomainContextParams.sessionInterest,
                },
              },
            },
            type: GqlEntityFilterType.COMMUNITY_USER_INTEREST_FILTER,
          },
        }),
        operation: 'communityUserInterest',
        fragmentName: 'communityUserInterestBaseFragment',
        alias: `${this.widgetStoreName}_sessionInterests`,
      }];

      this.context.commit('setGqlQueryConfig', configs);
      this.context.commit(
        'WidgetDispatcherStore/setGqlQueryConfigs',
        { key: this.widgetStoreName, values: configs },
        { root: true },
      );
    }
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    const keys = data.key.split('_');
    Object.assign(this.memberAboutData, { [keys[1]]: data.values });
  }

  @Mutation
  mapper(): void {
    if (this.widget) {
      this.mapping = {
        ...this.payload,
        ...this.memberAboutData,
      };
    }
  }
}
