









































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { Getter, namespace } from 'vuex-class';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import InputText from '@/components/InputText.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import StandardModal from '@/components/modals/StandardModal.vue';

const communityUserTagStore = namespace('CommunityUserTagStore');

@Component({
  components: {
    StandardModal,
    InputText,
    FontAwesomeComponent,
    ButtonIconComponent,
    ButtonComponent,
  },
})
export default class CreateRenameTagModal extends Vue {
  @Prop({ default: '' })
  private modalId!: string;

  @Prop({ default: true })
  private isCreate!: boolean;

  @Prop({ default: '' })
  private tagUid!: string;

  @Prop({ default: '' })
  private tagName!: string;

  @Getter
  private authUser!: CommunityUser;

  @communityUserTagStore.Action
  private createForUser!: (payload: {
    userUid: string;
    entity: Partial<CommunityUserTag>;
  }) => Promise<void | CommunityUserTag>;

  @communityUserTagStore.Action
  private updateCommunityUserTag!: (payload: Partial<CommunityUserTag>) => Promise<CommunityUserTag | undefined>;

  @communityUserTagStore.Action
  private checkTagNameExist!: (payload: {
    authUser: string;
    name: string;
  }) => Promise<boolean>;

  private isLoading = false;

  private isDuplicated = false;

  private name = '';

  private onShown(): void {
    this.isLoading = false;
    this.isDuplicated = false;
    this.name = this.tagName;
    const tagNameInput = document.getElementById('tagName');
    if (tagNameInput) {
      tagNameInput.focus();
    }
  }

  private onCreateRenameTag(): void {
    this.name = this.name.trim();
    if (this.name.length > 0) {
      this.isLoading = true;
      this.isDuplicated = false;
      const name = this.name.trim();
      this.checkTagNameExist({
        name,
        authUser: this.authUser.uid,
      }).then((isDuplicated) => {
        this.isDuplicated = isDuplicated;
        if (!isDuplicated) {
          if (!this.isCreate && this.tagUid) {
            return this.updateCommunityUserTag({
              uid: this.tagUid,
              name,
            }).then(() => {
              this.$emit('tag-renamed', name);
              this.$bvModal.hide(this.modalId);
              return true;
            });
          }
          return this.createForUser({
            userUid: this.authUser.uid,
            entity: {
              name,
            },
          }).then((tag) => {
            this.$emit('tag-created', tag);
            this.$bvModal.hide(this.modalId);
            return true;
          });
        }
        return false;
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
}
