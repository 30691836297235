import { ApolloQueryResult, FetchResult } from '@apollo/client';
import Product from '@/models/graphql/Product';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import {
  buildComposeMutationGql,
  buildMutationGql,
  buildQueryGql,
} from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import DeleteInterface from '@/repositories/base/DeleteInterface';
import FilterInterface from '@/repositories/base/FilterInterface';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';

/* eslint-disable max-len */
export default class ProductRepository extends GraphQlRepository<Product>
  implements FilterInterface<Product>, UpdateInterface<Product>, DeleteInterface<Product> {
  createProductForExhibitor(params: GqlPayloadParams): Promise<Product | undefined> {
    return buildMutationGql({
      operationName: 'CreateProductForExhibitor',
      operation: 'ProductCreateForExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'productBaseFragment',
    }).then((mutation) => this.mutate<{ ProductCreateForExhibitor: Product }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ProductCreateForExhibitor: Product }>) => response.data?.ProductCreateForExhibitor));
  }

  update(params: GqlPayloadParams): Promise<Product | undefined> {
    return buildMutationGql({
      operationName: 'ProductUpdate',
      operation: 'ProductUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'productBaseFragment',
    }).then((mutation) => this.mutate<{ ProductUpdate: Product }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ProductUpdate: Product }>) => response.data?.ProductUpdate));
  }

  updateByLocale(params: GqlPayloadParams, locale: string): Promise<Product | undefined> {
    return buildMutationGql({
      operationName: 'ProductUpdateByLocale',
      operation: 'ProductUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'productBaseFragment',
    }).then((mutation) => this.mutate<{ ProductUpdate: Product }, {}>(mutation, {
      ...params.definition.variables,
      _locale: locale,
    }).then((response: FetchResult<{ ProductUpdate: Product }>) => response.data?.ProductUpdate));
  }

  delete(params: GqlPayloadParams): Promise<Product | undefined> {
    return buildMutationGql({
      operationName: 'DeleteProduct',
      operation: 'ProductDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'productBaseFragment',
    }).then((mutation) => this.mutate<{ ProductDelete: Product }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ProductDelete: Product }>) => response.data?.ProductDelete));
  }

  filter(params: GqlPayloadParams): Promise<Product[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterProducts',
      operation: 'product',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'productBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ product: Array<Product> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ product: Array<Product> }>) => response.data.product));
  }

  addCategoriesToProduct(params: GqlComposeMutationPayloadParams): Promise<Record<string, Product>[]> {
    return buildComposeMutationGql({
      operationName: 'AddCategoriesToProduct',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, Product>[], {}>(mutations))
      .then((response: FetchResult<Record<string, Product>[]>) => response.data as Record<string, Product>[]);
  }

  removeCategoriesFromProduct(params: GqlComposeMutationPayloadParams): Promise<Record<string, Product>[]> {
    return buildComposeMutationGql({
      operationName: 'removeCategoriesFromProduct',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, Product>[], {}>(mutations))
      .then((response: FetchResult<Record<string, Product>[]>) => response.data as Record<string, Product>[]);
  }
}
