import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import FeedPostReportInput from '@/models/graphql/FeedPostReportInput';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import { FetchResult } from '@apollo/client';
import CreateInterface from '@/repositories/base/CreateInterface';

export default class FeedPostReportRepository extends GraphQlRepository<FeedPostReportInput>
  implements CreateInterface<FeedPostReportInput> {
  create(params: GqlPayloadParams): Promise<FeedPostReportInput | undefined> {
    return buildMutationGql({
      operationName: 'ReportPost',
      operation: 'FeedPostReportCreateForPostAndReportedByUser',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'feedPostReportFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        FeedPostReportCreateForPostAndReportedByUser: FeedPostReportInput;
      }, {}>(mutation, params.definition.variables))
      .then((response: FetchResult<{
        FeedPostReportCreateForPostAndReportedByUser: FeedPostReportInput;
      }>) => response.data?.FeedPostReportCreateForPostAndReportedByUser);
  }
}
