var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.choices)?_c('div',{staticClass:"multi-select d-flex flex-column row-gap-2"},_vm._l((_vm.choices),function(choice,index){return _c('div',{key:index,class:[
      'choice px-3 border-1 border-neutral-n-4-line rounded-sm cursor-pointer',
      {'py-3': !choice.isOther},
      {'py-2': choice.isOther},
      { 'bg-blue-b-1-light-blue border-blue-b-1-light-blue': _vm.isSelected(choice) }
    ],on:{"click":function($event){return _vm.onSelect(choice)}}},[_c('checkbox-switch-component',{staticClass:"cursor-pointer",attrs:{"is-switcher":false,"text":_vm.isSelected(choice) && choice.isOther
        ? _vm.inputValue(choice)
        : (!choice.isOther ? choice.text : ''),"text-pos-start":false,"value":_vm.isSelected(choice),"editable":!!choice.isOther,"ellipsis":false},on:{"on-change":function($event){return _vm.onSelect(choice)},"on-text-change":function($event){return _vm.onTextAnswerChange($event, choice)},"on-text-blur":function($event){return _vm.onTextBlur(choice)},"focus-in":function($event){return _vm.onFocusIn(choice)}}})],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }