import BaseModel from '@/models/BaseModel';
import CommunityUser from '@/models/graphql/CommunityUser';
import Exhibitor from '@/models/graphql/Exhibitor';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import Meeting from '@/models/graphql/Meeting';
import Session from '@/models/graphql/Session';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import Message from '@/models/graphql/Message';
import Survey from '@/models/graphql/Survey';
import CustomNotification from '@/models/graphql/CustomNotification';

class Notification extends BaseModel {
  action?: string;

  interactedWith?: boolean;

  initiator?: Exhibitor | CommunityUser | Session | Meeting;

  triggered?:
    CompanyUserRole |
    CommunityUserConnection |
    MeetingParticipant |
    Meeting |
    Session |
    MeetingRequest |
    Message |
    CustomNotification |
    Survey;

  notifiedUsers: CommunityUser[] = [];

  deleted?: boolean;

  createdTime?: Date;

  createdTimestamp?: number;

  static hydrate(item: object): Notification {
    const result = new Notification();
    return Object.assign(result, item);
  }
}

export default Notification;
