import BaseModel from '@/models/BaseModel';
import CommunityUser from '@/models/graphql/CommunityUser';
// eslint-disable-next-line import/no-cycle
import Message from '@/models/graphql/Message';

export default class Reaction extends BaseModel {
  reaction!: string;

  tempReactionId!: string;

  user!: CommunityUser;

  message!: Message

  static hydrate(item: object): Reaction {
    const result = new Reaction();
    Object.assign(result, item);

    if (result.user) {
      result.user = CommunityUser.hydrate(result.user);
    }

    return result;
  }
}
