































































































































































import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import PillWidget from '@/components/pill/PillWidget.vue';
import MessageBoxMessages from '@/views/chat/MessageBoxMessages.vue';
import DynamicTextComponent from '@/components/DynamicTextComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ExportToCalendarComponent from '@/components/ExportToCalendarComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Component from 'vue-class-component';
import { CalendarEvent } from 'calendar-link';
import { Getter, namespace, State } from 'vuex-class';
import Event from '@/utils/types/Event';
import { format, utcToZonedTime } from 'date-fns-tz';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import PresenterComponent from '@/components/PresenterComponent.vue';
import SpeakerRole from '@/models/graphql/SpeakerRole';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ToastActionParams from '@/utils/types/ToastActionParams';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import CommunityUser from '@/models/graphql/CommunityUser';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import EntityType from '@/utils/enums/EntityType';
import { runMathJax } from '@/utils/helpers/LatexHelper';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';

const agendaStore = namespace('AgendaStore');
const communityUserAgendaEntryStore = namespace('CommunityUserAgendaEntryStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    PillWidget,
    DynamicTextComponent,
    MessageBoxMessages,
    FontAwesomeComponent,
    ExportToCalendarComponent,
    ButtonComponent,
    PresenterComponent,
    AvatarSoloWidget,
  },
})
export default class SessionDetail extends BreakpointWrapper {
  @communityUserAgendaEntryStore.Action
  removeFromAgenda!: (
    payload: CommunityUserAgendaEntryFilter
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @agendaStore.Getter
  private readonly fetchEventDetail!: Event;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @State
  private readonly dateLocale!: Locale;

  private FileResourceHelper = FileResourceHelper;

  private EntityType = EntityType;

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private readonly community!: Community;

  private get date(): string | { d: string; t: string } {
    const std = this.fetchEventDetail.tzStartTime;
    const edd = this.fetchEventDetail.tzEndTime;
    if (std && edd) {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      const zone = format(utcToZonedTime(new Date(), timeZone), 'zz', {
        timeZone,
        locale: this.dateLocale,
      }) || '';
      return {
        d: `${format(std, this.$t('app.date.defaultDateFormat').toString(), { locale: this.dateLocale })}`,
        t: `${format(
          std,
          this.$t('app.date.defaultTimeFormat').toString(),
          { locale: this.dateLocale },
        )} — ${format(
          edd,
          this.$t('app.date.defaultTimeFormat').toString(),
          { locale: this.dateLocale },
        )}${!this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE)
        || !this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE).enabled ? ` (${zone})` : ''}`,
      };
    }
    return '';
  }

  private get addToCalendarEvent(): CalendarEvent {
    const route = this.$router.resolve({
      name: 'session-detail',
      params: { sessionId: this.fetchEventDetail.uid },
    });
    return {
      title: this.fetchEventDetail.title,
      description: `\n ${window.location.origin}${route.href} \n ${this.fetchEventDetail.message}`,
      location: this.fetchEventDetail.location,
      start: this.fetchEventDetail?.startTime ? this.fetchEventDetail?.startTime : '',
      end: this.fetchEventDetail?.endTime ? this.fetchEventDetail?.endTime : '',
    };
  }

  private get userEntityList(): Array<SpeakerRole> {
    if (this.fetchEventDetail) {
      if ('participants' in this.fetchEventDetail
        && this.fetchEventDetail.participants
        && this.fetchEventDetail.participants.length > 0) {
        return this.fetchEventDetail.participants as Array<SpeakerRole>;
      }
    }
    return [];
  }

  private get presenter(): Record<string, string | boolean | Array<object>> | null {
    if (this.fetchEventDetail) {
      if ('exhibitor' in this.fetchEventDetail && this.fetchEventDetail.exhibitor) {
        return {
          standAlone: true,
          entityCode: this.fetchEventDetail.exhibitor.uid,
          type: 'session',
          route: '',
          data: [this.fetchEventDetail.exhibitor],
        };
      }
    }
    return null;
  }

  private subtitle(user: CommunityUser | undefined): string {
    if (user) {
      const temp = [];
      if (user.jobTitle) {
        temp.push(user.jobTitle);
      }
      if (user.employerName) {
        temp.push(user.employerName);
      }
      return temp.join(` ${this.$t('entity-list-item.at')} `).trim();
    }
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  private updated(): void {
    runMathJax();
  }

  private openSessionDetail(): void {
    this.$bvModal.hide('toolbox-modal');
    this.$router.push({
      name: 'session-detail',
      params: { sessionId: this.fetchEventDetail.entityId },
    });
  }

  private cancelMeeting(): void {
    if (this.fetchEventDetail && this.fetchEventDetail.isInAgenda) {
      // eslint-disable-next-line no-underscore-dangle
      this.removeFromAgenda({
        event: this.fetchEventDetail,
        uid: this.fetchEventDetail.isInAgenda,
      }).then(() => {
        this.setViewMode(ViewMode.DAILY_VIEW);
        this.addNewAction({
          message: `${this.$t('toolbox.agenda.create-edit.removed-from-agenda')}`,
          delay: 3500,
        });
      });
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        this.fetchEventDetail.entityType,
        StatLoggerActions.REMOVE_FROM_AGENDA,
        'removeAppointment',
        -1,
        this.fetchEventDetail.entityId,
        StatLoggerCategories.REMOVE,
        this.$i18n.locale,
      );
    }
  }
}
