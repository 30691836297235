import { VNode } from 'vue';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { DirectiveBinding } from 'vue/types/options';

const PROPERTY = '__app_toggle_modal__';

function isDisabled(el: EventTarget | null): boolean {
  const element = (el as HTMLElement);
  return (!(element instanceof Window)
    && (element.hasAttribute('disabled') || element.classList.contains('disabled')));
}

function eventOn(el: HTMLElement, eventName: string, handler: (event: Event) => void): void {
  if (el && el.addEventListener) {
    el.addEventListener(eventName, handler, true);
  }
}

function eventOff(el: HTMLElement | Window, eventName: string, fn: (event: Event) => void): void {
  if (el && el.removeEventListener) {
    el.removeEventListener(eventName, fn, true);
  }
}

function setRole(trigger: HTMLElement): void {
  // Ensure accessibility on non button elements
  if (trigger && trigger.tagName !== 'BUTTON') {
    // Only set a role if the trigger element doesn't have one
    if (!trigger.hasAttribute('role')) {
      trigger.setAttribute('role', 'button');
    }
    // Add a tabindex is not a button or link, and tabindex is not provided
    if (trigger.tagName !== 'A' && !trigger.hasAttribute('tabindex')) {
      trigger.setAttribute('tabindex', '0');
    }
  }
}

function getEventKey(event: Event): string {
  let { key } = event as KeyboardEvent;
  if (key !== undefined) {
    key = (event as KeyboardEvent).code;
  }
  if (key !== undefined) {
    key = `${(event as KeyboardEvent).keyCode}`;
  }
  return key;
}

interface ModalEventHandlerParams {
  vnode: VNode;

  type: string;

  key: string;

  target: string;

  currentTarget: EventTarget | null;

  el: HTMLElement;
}

function modalEventHandler(params: ModalEventHandlerParams): void {
  const {
    vnode,
    type,
    key,
    target,
    currentTarget,
    el,
  } = params;

  if (vnode.context) {
    if (type === 'click' || (type === 'keydown' && (key === '13' || key === '8'))) {
      vnode.context.$root.$emit('app::modal::toggle', target, currentTarget);
    }

    if (el.getAttribute('data-visible') === 'true') {
      if (type === 'resize') {
        vnode.context.$root.$emit('app::modal::resize', target, el);
      }
      if (type === 'scroll'
        && el.getAttribute('data-modal-direction') === 'top') {
        vnode.context.$root.$emit('app::modal::scroll', target, el);
      }
    }
  }
}

function addEventListeners(trigger: HTMLElement, handler: (event: Event) => void): void {
  eventOn(trigger, 'click', handler);
  window.addEventListener('resize', handler);
  window.addEventListener('scroll', handler);
  if (trigger.tagName !== 'BUTTON' && trigger.getAttribute('role') === 'button') {
    // If trigger isn't a button but has role button,
    // we also listen for `keydown.space` && `keydown.enter`
    eventOn(trigger, 'keydown', handler);
  }
}

export default {
  // When the bound element is inserted into the DOM...
  bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode): void {
    const target = Object.keys(binding.modifiers)[0];
    const trigger = el;
    if (target && trigger) {
      const handler = (event: Event): void => {
        // `currentTarget` is the element with the listener on it
        const { currentTarget } = event;
        if (!isDisabled(currentTarget)) {
          const { type } = event;
          const key = getEventKey(event);

          // Open modal only if trigger is not disabled
          modalEventHandler({
            vnode,
            type,
            key,
            target,
            currentTarget,
            el,
          });
        }
      };

      (trigger as unknown as Record<string, object>)[PROPERTY] = { handler, target, trigger };
      // If element is not a button, we add `role="button"` for accessibility
      setRole(trigger);
      // Listen for events
      addEventListeners(trigger, handler);
    }
  },

  unbind(el: HTMLElement): void {
    const oldProp = (el as unknown as Record<string, object>)[PROPERTY] || { };
    const { trigger, handler } = (oldProp as
      { handler: (event: Event) => void; target: string; trigger: HTMLElement });
    if (trigger && handler) {
      eventOff(trigger, 'click', handler);
      eventOff(trigger, 'keydown', handler);
      eventOff(el, 'click', handler);
      eventOff(el, 'keydown', handler);
      eventOff(window, 'resize', handler);
      eventOff(window, 'scroll', handler);
    }
    // eslint-disable-next-line no-param-reassign
    delete (el as unknown as Record<string, object>)[PROPERTY];
  },
};
