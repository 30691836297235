import { FetchResult } from '@apollo/client';
import WriteInterface from '@/repositories/base/WriteInterface';
import { buildMutationGql } from '@/graphql/_Tools/GqlGeneric';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import ExhibitorVideo from '@/models/graphql/ExhibitorVideo';

/* eslint-disable max-len */
export default class ExhibitorVideoRepository extends GraphQlRepository<ExhibitorVideo>
  implements WriteInterface<ExhibitorVideo> {
  create(params: GqlPayloadParams): Promise<ExhibitorVideo | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateExhibitorVideo',
      operation: 'ExhibitorVideoCreateForExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorVideoBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorVideoCreateForExhibitor: ExhibitorVideo }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorVideoCreateForExhibitor: ExhibitorVideo }>) => response.data?.ExhibitorVideoCreateForExhibitor));
  }

  update(params: GqlPayloadParams): Promise<ExhibitorVideo | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdateExhibitorVideo',
      operation: 'ExhibitorVideoUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorVideoBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorVideoUpdate: ExhibitorVideo }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorVideoUpdate: ExhibitorVideo }>) => response.data?.ExhibitorVideoUpdate));
  }

  delete(params: GqlPayloadParams): Promise<ExhibitorVideo | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'DeleteExhibitorVideo',
      operation: 'ExhibitorVideoDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'exhibitorVideoBaseFragment',
    }).then((mutation) => this.mutate<{ ExhibitorVideoDelete: ExhibitorVideo }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ ExhibitorVideoDelete: ExhibitorVideo }>) => response.data?.ExhibitorVideoDelete));
  }
}
