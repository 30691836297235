import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import Speaker from '@/models/graphql/Speaker';
import SpeakerRepository from '@/repositories/SpeakerRepository';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';

@Module({ namespaced: true })
export default class SpeakerStore extends LoadableStore<LoadableState> {
  private readonly speakerRepository = new SpeakerRepository();

  protected get repository(): SpeakerRepository {
    return this.speakerRepository;
  }

  @Action
  updateSpeaker(payload: Partial<Speaker>): Promise<Speaker | undefined> {
    this.context.commit('load', true);
    return this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.SPEAKER_INPUT,
          value: payload,
        },
      ]),
      fragmentName: 'speakerFullFragment',
    }).then((response) => response)
      .finally(() => {
        this.context.commit('load', false);
      });
  }
}
