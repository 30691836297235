import GqlVariablesInterface from '@/graphql/_Tools/type/GqlVariablesInterface';
import GqlExtraVariablesInterface from '@/graphql/_Tools/type/GqlExtraVariablesInterface';
import GqlFieldType from '@/graphql/_Tools/GqlFieldType';
import GqlOrderByType from '@/graphql/_Tools/GqlOrderByType';
import GqlOffsetType from '@/graphql/_Tools/GqlOffsetType';
import GqlDefinition from '@/graphql/_Tools/GqlDefinition';

export default class GqlQueryDefinition
  extends GqlDefinition<GqlVariablesInterface & GqlExtraVariablesInterface>
  implements GqlExtraVariablesInterface {
  static EXTRA_VARIABLES: Record<string, string> = {
    doCount: 'do-count',
    fts: '_fts',
    distinct: 'do-distinct',
    magicArgs: 'magicArgs',
    magicArguments: 'magicArguments',
    cacheable: '_cachable',
  };

  static EXTRA_VARIABLES_KEYS = Object.keys(GqlQueryDefinition.EXTRA_VARIABLES);

  filter?: GqlFieldType<object> | null;

  first?: GqlFieldType<number> | null;

  offset?: GqlOffsetType | null;

  orderBy?: GqlOrderByType | null;

  magicArguments?: Record<string, string>;

  /**
   * @deprecated
   * Use entityFts in filter instead
   */
  fts?: string | null;

  /**
   * @deprecated
   * No need, backend will detect a count query by search for _count in the query fields
   */
  doCount?: boolean | null;

  distinct?: boolean | null;

  cacheable = false;

  constructor(
    seed: GqlVariablesInterface & GqlExtraVariablesInterface,
  ) {
    super();
    this.filter = GqlFieldType.init<object>(seed.filter, 'filter');
    this.orderBy = GqlOrderByType.init(seed.orderBy);
    this.first = GqlFieldType.init<number>({ value: seed.first, type: 'Int' }, 'first');
    this.offset = GqlOffsetType.init<number>({ value: seed.offset, type: 'Int' });
    this.fts = seed.fts;
    this.doCount = seed.doCount;
    this.distinct = seed.distinct;
    this.magicArguments = seed.magicArgs;
    this.cacheable = seed.cacheable || false;
  }

  get keys(): string[] {
    const self = (this as unknown as Record<string, GqlFieldType<unknown>>);
    return Object.keys(this).filter((key) => {
      if (self[key] instanceof GqlFieldType && !self[key].isValid) {
        return false;
      }
      if (key === 'cacheable') {
        return true;
      }
      return (this as Record<string, unknown>)[key];
    });
  }

  get declaration(): string {
    const self = (this as unknown as Record<string, GqlFieldType<unknown>>);
    return this.keys
      .filter((k) => !GqlQueryDefinition.EXTRA_VARIABLES_KEYS.includes(k))
      .reduce((accumulation, item) => {
        if (self[item] instanceof GqlFieldType && self[item]) {
          return `${accumulation} ${self[item].declaration}`;
        }
        return '';
      }, '');
  }

  get parameter(): string {
    const self = (this as unknown as Record<string, GqlFieldType<unknown>>);
    return this.keys
      .filter((k) => !GqlQueryDefinition.EXTRA_VARIABLES_KEYS.includes(k))
      .reduce((accumulation, item) => {
        if (self[item] instanceof GqlFieldType && self[item]) {
          return `${accumulation} ${self[item].parameter}`;
        }
        return '';
      }, '');
  }

  get variables(): GqlVariablesInterface &
    GqlExtraVariablesInterface {
    const self = (this as unknown as Record<string, GqlFieldType<unknown>>);
    return this.keys
      .filter((key) => key !== 'magicArguments')
      .reduce((accumulation, item) => {
        if (self[item] instanceof GqlFieldType && self[item]) {
          return { ...accumulation, ...self[item].variables };
        }

        if (GqlQueryDefinition.EXTRA_VARIABLES_KEYS.includes(item)) {
          const index = GqlQueryDefinition.EXTRA_VARIABLES[item];
          return {
            ...accumulation,
            [index]: (this as Record<string, unknown>)[item],
          };
        }

        return {
          ...accumulation,
          [item]: (this as Record<string, unknown>)[item],
        };
      }, {});
  }

  get magicArgs(): Record<string, string> | undefined {
    return this.magicArguments;
  }
}

export function buildQueryDefinition(
  variables: GqlVariablesInterface & GqlExtraVariablesInterface,
): GqlQueryDefinition {
  return new GqlQueryDefinition(variables);
}

// Usage
buildQueryDefinition({
  cacheable: true,
  filter: {
    value: {},
    type: '_CommunityFilter',
  },
  first: 1,
  orderBy: {
    value: ['Date'],
    type: '_CommunityOrdering',
  },
});
