



























































import { Component, Prop, Watch } from 'vue-property-decorator';
import Event from '@/utils/types/Event';
import {
  addHours, format, isToday, startOfDay,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { namespace, State } from 'vuex-class';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import DailyItem from '@/components/toolbox/agenda/item/DailyItem.vue';
import ViewMode from '@/utils/enums/agenda/ViewMode';

const agendaStore = namespace('AgendaStore');

type EventParams = {
  id: string;
  data: Event | undefined;
  starttime: string;
  endtime: string;
  start: number;
  stop: number;
  topPos: number;
  height: number;
  numColumns: number;
  leftIndex: number;
  fullPosition: Record<string, number | string>;
};
@Component({
  components: { DailyItem },
})
export default class DailyView extends BreakpointWrapper {
  @agendaStore.Getter
  private fetchDayBeingViewed!: string;

  @agendaStore.Getter
  private fetchViewMode!: ViewMode;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Mutation
  private setEventEdited!: (e: Partial<Event>) => void;

  @agendaStore.Mutation
  private updateCreateStartTime!: () => void;

  @Prop({ required: false, default: () => [] })
  private dailyEvents!: Event[];

  @State
  private dateLocale!: Locale;

  private STARTTIME = 0;

  private ENDTIME = 24;

  private HEIGHTOFHOUR = 60;

  private dailyItemRender = 0;

  private eventsById: Record<string, EventParams> = {};

  private get dateHeader(): { isToday: boolean; d: string; m: string; dw: string } | null {
    this.scrollTo(this.events.length === 0 ? 420 : this.events[0].topPos);
    const date = this.fetchDayBeingViewed ? DateTimeHelper.toUTC(new Date(this.fetchDayBeingViewed))
      : DateTimeHelper.getCurrentDateTime();
    return {
      isToday: isToday(date),
      d: format(date, this.$t('app.date.day') as string, { locale: this.dateLocale }),
      m: format(date, this.$t('app.date.month') as string, { locale: this.dateLocale }),
      dw: format(date, this.$t('app.date.dayOfWeek') as string, { locale: this.dateLocale }),
    };
  }

  private get hoursOfdDay(): string[] {
    const d = startOfDay(new Date());
    const hours = [];
    for (let i = this.STARTTIME; i <= this.ENDTIME; i++) {
      hours.push(format(addHours(d, i), this.$t('app.date.defaultTimeFormat') as string, { locale: this.dateLocale }));
    }
    return hours;
  }

  private get events(): EventParams[] {
    if (this.dailyEvents && this.dailyEvents.length > 0) {
      return this.dailyEvents.map((item) => ({
        data: item,
        id: item.uid,
        starttime: format(item.tzStartTime, this.$t('app.date.defaultTimeFormat') as string, { locale: fr }),
        endtime: format(item.tzEndTime, this.$t('app.date.defaultTimeFormat') as string, { locale: fr }),
        start: 0,
        height: 0,
        stop: 0,
        numColumns: 1,
        topPos: 0,
        leftIndex: 0,
        fullPosition: {},
      }));
    }
    return [];
  }

  created(): void {
    this.renderDailyView();
  }

  mounted(): void {
    this.$nextTick(() => {
      this.manageEventCardUI();
      setTimeout(() => {
        this.scrollTo(this.events.length === 0 ? 420 : this.events[0].topPos);
      }, 100);
    });
    window.addEventListener('resize', this.manageEventCardUI);
  }

  @Watch('dailyEvents', { deep: true })
  renderDailyView(): void {
    let max: number;
    let m;
    let e;
    let ts;
    let event;
    let leftIndex;

    const MINUTESINDAY = (this.ENDTIME - this.STARTTIME) * 60;

    const timeslots: Record<number, string[]> = [];
    for (m = 0; m < MINUTESINDAY; m++) {
      Object.assign(timeslots, { [m]: [] });
    }

    this.displayEventsInView();

    const numEvents = this.events.length;
    for (e = 0; e < numEvents; e++) {
      event = this.events[e];
      for (m = event.start; m < event.stop; m++) {
        timeslots[m].push(event.id);
      }
    }

    for (m = 0; m < MINUTESINDAY; m++) {
      ts = timeslots[m];
      for (e = 0; e < ts.length; e++) {
        event = this.eventsById[ts[e]];
        max = ts.length;
        // eslint-disable-next-line no-loop-func
        ts.forEach((id) => {
          const evt = this.eventsById[id];
          max = (evt.numColumns > max) ? evt.numColumns : max;
        });

        if (event.numColumns <= max) {
          event.numColumns = max;
        }

        if (event.leftIndex === -1) {
          leftIndex = 0;
          while (!this.isFreeSpace(ts, leftIndex, event.id)) {
            leftIndex += 1;
          }
          event.leftIndex = leftIndex;
        }
      }
    }

    for (m = 0; m < MINUTESINDAY; m++) {
      ts = timeslots[m];
      for (e = 0; e < ts.length; e++) {
        event = this.eventsById[ts[e]];
        max = ts.length;
        // eslint-disable-next-line no-loop-func
        ts.forEach((id) => {
          const evt = this.eventsById[id];
          max = (evt.numColumns > max) ? evt.numColumns : max;
        });

        if (event.numColumns <= max) {
          event.numColumns = max;
        }
      }
    }
    this.layoutEvents();
    setTimeout(() => { this.dailyItemRender += 1; }, 100);
  }

  private scrollTo(y: number): void {
    if (this.$el) {
      const dailyView = this.$el.querySelector('.daily-view') as HTMLElement;
      if (dailyView) {
        dailyView.scrollTop = y;
      }
    }
  }

  private layoutEvents(): void {
    let numX;
    let xFactor;
    let left;
    this.events.forEach((event) => {
      numX = event.numColumns;
      xFactor = 1 / numX;
      left = (event.leftIndex * xFactor * 100);
      event.fullPosition = {
        top: `${Math.round(event.topPos)}px`,
        left: `calc(${left}% + ${event.leftIndex > 0 ? '4px' : 0})`,
        width: event.leftIndex > 0
          ? `calc(${Math.floor(100 * xFactor)}% - 4px)`
          : `${Math.floor(100 * xFactor)}%`,
        height: `${Math.round(event.height)}px`,
      };
    });
  }

  private displayEventsInView(): void {
    const numEvents = this.events.length;
    let event;
    let e;
    let pos;
    let stH;
    let stM;
    let etH;
    let etM;
    let height;

    for (e = 0; e < numEvents; e++) {
      event = this.events[e];
      event.leftIndex = -1;
      event.numColumns = 0;
      pos = event.starttime.indexOf(':');
      stH = parseInt(event.starttime.substr(0, pos), 10);
      stM = parseInt(event.starttime.substr(pos + 1), 10) / 60;

      event.start = ((stH - this.STARTTIME) * 60) + (stM * 60);
      event.topPos = ((stH - this.STARTTIME) * this.HEIGHTOFHOUR) + (stM * this.HEIGHTOFHOUR);

      pos = event.endtime.indexOf(':');
      etH = parseInt(event.endtime.substr(0, pos), 10);
      etM = parseInt(event.endtime.substr(pos + 1), 10) / 60;
      event.stop = ((etH - this.STARTTIME) * 60) + (etM * 60);

      height = (etH - stH) * this.HEIGHTOFHOUR;
      height -= stM * this.HEIGHTOFHOUR;
      height += etM * this.HEIGHTOFHOUR;
      event.height = height;
      this.eventsById[event.id] = event;
    }
  }

  private isFreeSpace(ts: string[], leftIndex: number, eventId: string): boolean {
    const tsLength = ts.length;
    let event;
    for (let i = 0; i < tsLength; ++i) {
      event = this.eventsById[ts[i]];
      if (event.leftIndex === leftIndex) {
        return event.id === eventId;
      }
    }
    return true;
  }

  private manageDailyViewClickUI(hour: number): void {
    const selectedDate = DateTimeHelper.roundToNearest15(this.fetchDayBeingViewed
      ? startOfDay(DateTimeHelper.toUTC(new Date(this.fetchDayBeingViewed)))
      : startOfDay(DateTimeHelper.getCurrentDateTime()));
    this.setViewMode(ViewMode.CREATE);
    this.setEventEdited({
      tzStartTime: addHours(selectedDate, hour),
    });
    this.updateCreateStartTime();
  }

  private manageEventCardUI = (): void => {
    const calData = document.querySelectorAll('.cal-data');
    if (calData.length > 0) {
      calData.forEach((el) => {
        const timeElement = el.querySelector('.cal-data-time-label');
        const titleElement = el.querySelector('.cal-data-title-label');
        if (timeElement && titleElement) {
          if (el.getBoundingClientRect().width < 56) {
            if (timeElement) {
              timeElement.classList.add('d-none');
            }
          } else if (timeElement) {
            timeElement.classList.remove('d-none');
          }
          if (el.getBoundingClientRect().width < 144) {
            if (titleElement) {
              titleElement.classList.add('d-none');
              if (timeElement) {
                timeElement.classList.add('max-w-100');
                timeElement.classList.add('text-center');
              }
            }
          } else if (titleElement) {
            titleElement.classList.remove('d-none');
            if (timeElement) {
              timeElement.classList.remove('max-w-100');
              timeElement.classList.remove('text-center');
            }
          }
        }
      });
    }
  }
}
