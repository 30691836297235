import { render, staticRenderFns } from "./MessageBoxContent.vue?vue&type=template&id=262e122a&scoped=true&"
import script from "./MessageBoxContent.vue?vue&type=script&lang=ts&"
export * from "./MessageBoxContent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/chat/MessageBoxContent.scss?vue&type=style&index=0&id=262e122a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "262e122a",
  null
  
)

export default component.exports