import FeedPost from '@/models/graphql/FeedPost';
import FeedPostLink from '@/models/graphql/FeedPostLink';
import FeedPostMedia from '@/models/graphql/FeedPostMedia';
import FeedItemWrapperRepository from '@/repositories/feeds/FeedItemWrapperRepository';
import FeedPostType from '@/utils/enums/feed/FeedPostType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import FeedPostLinkRepository from '@/repositories/feeds/FeedPostLinkRepository';
import FeedPostMediaRepository from '@/repositories/feeds/FeedPostMediaRepository';

type PostOperationResult = {
  medias: FeedPostMedia[];
  links: FeedPostLink[];
};

type UpdatePostResourcesArguments = {
  post: FeedPost;
  entity: Partial<FeedPost>;
  medias: FeedPostMedia[];
  links: FeedPostLink[];
  oldEntity: FeedPost;
}

export default class FeedItemHelper {
  feedItemWrapperRepository: FeedItemWrapperRepository;

  feedPostLinkRepository: FeedPostLinkRepository;

  feedPostMediaRepository: FeedPostMediaRepository;

  constructor(
    feedItemWrapperRepository: FeedItemWrapperRepository,
    feedPostLinkRepository?: FeedPostLinkRepository,
    feedPostMediaRepository?: FeedPostMediaRepository,
  ) {
    this.feedItemWrapperRepository = feedItemWrapperRepository;
    this.feedPostLinkRepository = feedPostLinkRepository || new FeedPostLinkRepository();
    this.feedPostMediaRepository = feedPostMediaRepository || new FeedPostMediaRepository();
  }

  updatePostResources({
    post,
    entity,
    medias,
    links,
    oldEntity,
  }: UpdatePostResourcesArguments): Promise<PostOperationResult | null> {
    const newPostMedias: FeedPostMedia[] = [];
    let result = Promise.resolve<PostOperationResult | null>(null);

    if (entity.type === FeedPostType.IMAGE || entity.type === FeedPostType.VIDEO) {
      const newMedias = medias.filter(
        (media) => media.uid === undefined && media.type !== undefined,
      );
      const newMediasWithUid = medias.filter(
        (media) => media.uid !== undefined && media.type !== undefined,
      );
      newMediasWithUid.forEach((feedMedia) => {
        if (feedMedia !== undefined && feedMedia.type === entity.type?.toLowerCase()) {
          newPostMedias.push(feedMedia);
        }
      });
      result = this.createNewMedia(newMedias, post.uid, newPostMedias);
      const newMediasUid = medias.map((media) => media.uid).filter((uid) => uid !== undefined);
      const toDeleteMediaUids = oldEntity.medias
        // eslint-disable-next-line arrow-body-style
        ?.filter((media) => {
          return (
            (!newMediasUid.includes(media.uid) && media.uid !== undefined)
            || media.type !== entity.type?.toLowerCase()
          );
        })
        .map((media) => media.uid);
      if (toDeleteMediaUids) {
        result = result.then(() => Promise.all(
          toDeleteMediaUids.map(
            (mediaUid) => this.feedPostMediaRepository.delete({
              definition: buildMutationDefinition([
                {
                  fieldName: 'uid',
                  type: GqlEntityInputType.ID,
                  value: mediaUid,
                },
              ]),
            }),
          ),
        ).then(() => ({
          medias: newPostMedias as FeedPostMedia[],
          links: [] as FeedPostLink[],
        })));
      }
      this.deleteLinks(oldEntity);
    } else if (entity.type === FeedPostType.LINK) {
      if (links && links.length > 0 && links[0].uid === undefined) {
        result = Promise.all(
          links.map(
            (link) => this.feedPostLinkRepository.create({
              operationName: 'FeedPostLinkCreateForPost',
              definition: buildMutationDefinition([
                {
                  fieldName: 'post_FeedPostUid',
                  type: GqlEntityInputType.ID,
                  value: post?.uid,
                },
                {
                  fieldName: 'entity',
                  type: GqlEntityInputType.FEED_POST_LINK_INPUT,
                  value: link,
                },
              ]),
            }),
          ),
        ).then((linksResponse) => {
          this.deleteLinks(oldEntity);
          return {
            medias: [] as FeedPostMedia[],
            links: linksResponse as FeedPostLink[],
          };
        });
      } else if ((!links || links.length === 0) && oldEntity.links?.length !== 0) {
        this.deleteLinks(oldEntity);
        result = Promise.resolve({
          medias: [] as FeedPostMedia[],
          links: oldEntity.links as FeedPostLink[],
        });
      } else if (links && links.length > 0 && links[0].uid !== undefined) {
        result = Promise.resolve({
          medias: [] as FeedPostMedia[],
          links: links as FeedPostLink[],
        });
      }
      this.deleteMedias(oldEntity);
    } else {
      this.deleteMedias(oldEntity);
      this.deleteLinks(oldEntity);
      result = Promise.resolve({
        medias: [] as FeedPostMedia[],
        links: [] as FeedPostLink[],
      });
    }

    return result;
  }

  private createNewMedia(
    newMedias: FeedPostMedia[],
    postUid: string,
    newPostMedias: FeedPostMedia[],
  ): Promise<PostOperationResult | null> {
    return Promise.all(
      newMedias.map((media) => this.feedPostMediaRepository.create({
        definition: buildMutationDefinition([
          {
            fieldName: 'post_FeedPostUid',
            type: GqlEntityInputType.ID,
            value: postUid,
          },
          {
            fieldName: 'entity',
            type: GqlEntityInputType.FEED_POST_MEDIA_INPUT,
            value: media,
          },
        ]),
      })),
    ).then((feedPostMedias) => {
      feedPostMedias.forEach((feedMedia) => {
        if (feedMedia !== undefined) {
          newPostMedias.push(feedMedia);
        }
      });

      return {
        medias: newPostMedias as FeedPostMedia[],
        links: [] as FeedPostLink[],
      };
    });
  }

  private deleteLinks(oldEntity: FeedPost): void {
    if (oldEntity.links && oldEntity.links.length > 0) {
      oldEntity.links.forEach(
        (oldLink) => this.feedPostLinkRepository.delete({
          definition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: GqlEntityInputType.ID,
              value: oldLink.uid,
            },
          ]),
        }),
      );
    }
  }

  private deleteMedias(oldEntity: FeedPost): void {
    if (oldEntity.medias && oldEntity.medias.length > 0) {
      oldEntity.medias.forEach(
        (oldMedia) => this.feedPostMediaRepository.delete({
          definition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: GqlEntityInputType.ID,
              value: oldMedia.uid,
            },
          ]),
        }),
      );
    }
  }
}
