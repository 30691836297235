

















































import { Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';

@Component({
  components: {
    StandardModal,
    ButtonComponent,
  },
})
export default class ConfirmModal extends VueBaseWidget {
  @Prop({ default: '' })
  type!: string;

  @Prop({ default: 'md' })
  size!: string;

  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  description!: string;

  @Prop({ default: '' })
  okText!: string;

  @Prop({ default: '' })
  cancelText!: string;

  @Prop({ default: 'primary' })
  okBtnVariant!: string;

  @Prop({ default: 'secondary' })
  cancelBtnVariant!: string;

  @Prop({ required: false, default: -1 })
  private userId!: number | string;

  @Prop({ required: false, default: false })
  private hideOkBtn!: boolean;

  private onConfirm(): void {
    this.$bvModal.hide(`${this.type}-${this.userId}`);
    this.$emit('on-confirm');
  }

  private onCancel(): void {
    this.$bvModal.hide(`${this.type}-${this.userId}`);
    this.$emit('on-cancel');
  }
}
