var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userMeetingInfo)?_c('div',{class:['w-100 d-flex flex-column',
           _vm.darkMode ? 'bg-neutral-n-8-dark-ink' : 'bg-white-white-100']},[(_vm.userMeetingInfo.sentdate)?_c('message-box-date-separator-component',{attrs:{"dark-mode":_vm.darkMode,"date":_vm.userMeetingInfo.sentdate}}):_vm._e(),_c('div',{staticClass:"d-flex gap-4 p-3 align-items-start"},[_c('avatar-solo-widget',{attrs:{"firstname":_vm.userMeetingInfo.firstName,"lastname":_vm.userMeetingInfo.lastName,"src":_vm.userMeetingInfo.avatar,"with-borders":!_vm.userMeetingInfo.avatar,"size":"40"}}),_c('div',{staticClass:"d-flex flex-column gap-2"},[_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('p',{class:['m-0 text-left font-weight-bold',
                   _vm.darkMode ? 'text-neutral-n-3-disable' : 'text-neutral-n-8-dark-ink']},[_vm._v(" "+_vm._s(_vm.userMeetingInfo.firstName)+" ")]),_c('label',{class:['m-0 label-4 medium',
                   _vm.darkMode ? 'text-neutral-n-5-placeholder' : 'text-neutral-n-6-label']},[_vm._v(" "+_vm._s(_vm.userMeetingInfo.senttime)+" ")])]),_c('div',{staticClass:"d-flex align-items-start gap-2"},[_c('font-awesome-component',{attrs:{"container-classes":"bg-yellow-y-1-light-yellow p-12 rounded-circle","icon":"far fa-calendar-clock","icon-classes":"icon-16 text-neutral-n-8-dark-ink"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('p',{class:['m-0 text-left font-weight-bold',
                     _vm.darkMode ? 'text-neutral-n-3-disable' : 'text-neutral-n-8-dark-ink']},[_vm._v(" "+_vm._s(_vm.userMeetingInfo.subject)+" ")]),_c('label',{class:['m-0 label-3 text-left',
                     _vm.darkMode ? 'text-neutral-n-5-placeholder' : 'text-neutral-n-6-label']},[_vm._v(" "+_vm._s(_vm.userMeetingInfo.starttime)+" ")]),(_vm.userMeetingInfo.state && !_vm.fromInsideMeeting)?_c('div',{staticClass:"d-flex gap-4"},[_c('p',{class:['m-0 paragraph-3 font-weight-bold text-uppercase cursor-pointer',
                       _vm.userMeetingInfo.state === _vm.MeetingParticipantState.ACCEPTED
                         ? 'text-blue-b-3-primary-blue'
                         : 'text-neutral-n-6-label'],on:{"click":_vm.onAccept}},[_vm._v(" "+_vm._s(_vm.$tc('toolbox.agenda.meeting.accept', _vm.userMeetingInfo.state === _vm.MeetingParticipantState.ACCEPTED ? 0 : 1))+" ")]),_c('p',{class:['m-0 paragraph-3 font-weight-bold text-uppercase cursor-pointer',
                       _vm.userMeetingInfo.state === _vm.MeetingParticipantState.DECLINED
                         ? 'text-red-r-3-primary-red'
                         : 'text-neutral-n-6-label'],on:{"click":_vm.onDecline}},[_vm._v(" "+_vm._s(_vm.$tc('toolbox.agenda.meeting.decline', _vm.userMeetingInfo.state === _vm.MeetingParticipantState.DECLINED ? 0 : 1))+" ")])]):_vm._e()])],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }