

























































import { Component, Prop, Watch } from 'vue-property-decorator';
import StandardModal from '@/components/modals/StandardModal.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { namespace } from 'vuex-class';
import MultiSelectComponent from '@/components/form/MultiSelectComponent.vue';
import SurveyQuestion from '@/models/graphql/SurveyQuestion';
import SurveyQuestionType from '@/utils/enums/SurveyQuestionType';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ChoiceParams from '@/utils/types/ChoiceParams';
import SurveyQuestionChoice from '@/models/graphql/SurveyQuestionChoice';
import SurveyUserAnswer from '@/models/graphql/SurveyUserAnswer';
import SingleSelectComponent from '@/components/form/SingleSelectComponent.vue';
import RatingStarComponent from '@/components/form/RatingStarComponent.vue';
import RatingNumberComponent from '@/components/form/RatingNumberComponent.vue';
import { mixins } from 'vue-class-component';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { SurveyQuestionFilter } from '@/graphql/_Filters/SurveyQuestionFilter';
import ActionType from '@/utils/enums/ActionType';
import { SurveyUserAnswerFilter } from '@/graphql/_Filters/SurveyUserAnswerFilter';
import Survey from '@/models/graphql/Survey';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

const surveyQuestionStore = namespace('SurveyQuestionStore');
const surveyUserAnswerStore = namespace('SurveyUserAnswerStore');

@Component({
  components: {
    FontAwesomeComponent,
    RatingNumberComponent,
    RatingStarComponent,
    SingleSelectComponent,
    MultiSelectComponent,
    ButtonComponent,
    ButtonIconComponent,
    StandardModal,
  },
})
export default class MicroPollWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget, BreakpointWrapper) {
  @Prop({ required: true, default: null })
  private survey!: Survey;

  @surveyQuestionStore.Getter
  private fetchSurveyQuestions!: SurveyQuestion[];

  @surveyQuestionStore.Action
  private loadSurveyQuestions!: (filter: SurveyQuestionFilter) => Promise<SurveyQuestion>;

  @surveyUserAnswerStore.Action(ActionType.CREATE)
  private createSurveyUserAnswer!: (payload: {
    answer: SurveyUserAnswer;
    userId: string;
    surveyId: string;
    choiceId: string;
  })
    => Promise<SurveyQuestionChoice | undefined>;

  @surveyUserAnswerStore.Action(ActionType.GET_ONE)
  private getAnswer!: (payload: { filter: SurveyUserAnswerFilter }) => Promise<SurveyUserAnswer>;

  private saveLoading = false;

  private entityCode = '';

  private SurveyQuestionTypes = SurveyQuestionType;

  private isSurveyAnswered = false;

  private isSurveyUnavailable = false;

  private isClosed = false;

  private questions: SurveyQuestion[] = [];

  private answers: {
    index: number;
    questionUid: string;
    answers: SurveyUserAnswer[];
  }[] = [];

  created(): void {
    this.setDataConfig();
    this.loadMicropoll();
  }

  private get fieldClass(): string {
    if (this.isDesktop) {
      return 'field-lg';
    }
    return this.isTablet ? 'field-md' : 'field-sm';
  }

  private loadMicropoll(): void {
    const { entityId } = this.$route.params;
    const payload = JSON.parse(this.widget?.payload || '{}');
    this.entityCode = payload.surveyId || this.survey?.uid || entityId;
    if (!this.entityCode) { return; }

    const now = DateTimeHelper.currentTimestamp;
    if (!this.survey) {
      return;
    }
    if ((this.survey.endTimestamp && this.survey.endTimestamp <= now)
      || (this.survey.startTimestamp && this.survey.startTimestamp >= now)) {
      this.isSurveyUnavailable = true;
    }
    this.getAnswer({
      filter: {
        user: { uid: this.authUser.uid },
        survey: { uid: this.entityCode },
      },
    }).then((response) => {
      if (response) {
        this.isSurveyAnswered = response && this.survey.uid === response.survey?.uid;
      } else {
        this.isSurveyAnswered = false;
      }
      if (!this.isSurveyAnswered) {
        this.loadQuestions();
      } else {
        this.$emit('on-already-answered', this.entityCode);
      }
    });
  }

  private loadQuestions(): void {
    if (this.entityCode) {
      this.loadSurveyQuestions({
        survey: { uid: this.entityCode },
        // eslint-disable-next-line @typescript-eslint/camelcase
        choices_not: null,
      });
    }
  }

  @Watch('fetchSurveyQuestions', { immediate: true })
  private setQuestions(): void {
    if (this.fetchSurveyQuestions && this.fetchSurveyQuestions.length) {
      this.questions = this.fetchSurveyQuestions;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getQuestionChoices(question: SurveyQuestion): ChoiceParams[] {
    return question.choices ? question.choices.map((choice: SurveyQuestionChoice) => ({
      value: choice.uid,
      text: choice.label,
      isOther: choice.isOther,
    } as ChoiceParams)) : [];
  }

  private onSingleSelectChange(choice: ChoiceParams | null): void {
    if (!choice || this.questions.length === 0) {
      return;
    }
    let isNew = false;
    if (this.answers.length >= 1) {
      if (this.answers[0].answers.length) {
        if (choice) {
          this.answers[0].answers[0].choice = {
            uid: choice?.value,
            label: choice?.text,
            isOther: choice?.isOther,
          } as SurveyQuestionChoice;
        } else {
          this.answers.splice(0, 1);
        }
      }
    } else {
      isNew = true;
    }
    if (isNew) {
      const answer = {
        choice: {
          uid: choice?.value,
          label: choice?.text,
          isOther: choice?.isOther,
        } as SurveyQuestionChoice,
      } as SurveyUserAnswer;
      this.answers.push({
        index: 0,
        questionUid: this.questions[0].uid,
        answers: [answer],
      });
    }
  }

  private onMultipleSelectChange(choices: ChoiceParams[]): void {
    if (this.questions.length === 0) {
      return;
    }

    let isNew = false;
    if (this.answers.length >= 1) {
      if (choices.length) {
        this.answers[0].answers = [];
        const finalAnswers: SurveyUserAnswer[] = [];
        choices.forEach((choice: ChoiceParams) => {
          const newAnswer = {
            choice: {
              uid: choice?.value,
              label: choice?.text,
              isOther: choice?.isOther,
            } as SurveyQuestionChoice,
          } as SurveyUserAnswer;

          finalAnswers.push(newAnswer);
        });
        this.answers[0].answers = finalAnswers;
      } else {
        this.answers.splice(0, 1);
      }
    } else {
      isNew = true;
    }

    if (isNew) {
      const finalAnswers: SurveyUserAnswer[] = [];
      choices.forEach((choice: ChoiceParams) => {
        const newAnswer = {
          choice: {
            uid: choice?.value,
            label: choice?.text,
            isOther: choice?.isOther,
          } as SurveyQuestionChoice,
        } as SurveyUserAnswer;

        finalAnswers.push(newAnswer);
      });
      this.answers.push({
        index: 0,
        questionUid: this.questions[0].uid,
        answers: finalAnswers,
      });
    }
  }

  private onRatingChange(rating: number): void {
    if (this.questions.length === 0) {
      return;
    }
    const question = this.questions[0];
    const choice = question.choices ? question.choices[0] : null;
    let isNew = false;
    if (this.answers.length >= 1) {
      if (this.answers[0].answers.length) {
        if (rating) {
          if (choice) {
            this.answers[0].answers[0].choice = {
              uid: choice.uid,
              label: rating.toString(),
              isOther: true,
            } as unknown as SurveyQuestionChoice;
          }
        } else {
          this.answers.splice(0, 1);
        }
      }
    } else {
      isNew = true;
    }

    if (isNew && rating && choice) {
      const newAnswer = {
        choice: {
          uid: choice.uid,
          label: rating.toString(),
          isOther: true,
        } as unknown as SurveyQuestionChoice,
      } as SurveyUserAnswer;
      this.answers.push({
        index: 0,
        questionUid: this.questions[0].uid,
        answers: [newAnswer],
      });
    }
  }

  private get selectedChoices(): ChoiceParams[] {
    return this.answers.length > 0 && this.answers[0].answers.length
      ? this.answers[0].answers.map((answer: SurveyUserAnswer) => ({
        value: answer.choice.uid,
        text: answer.choice.label,
        isOther: answer.choice.isOther,
      } as ChoiceParams))
      : [];
  }

  private onSubmit(): void {
    const answerCount = this.answers.reduce((previousValue, currentValue) => previousValue + currentValue.answers.length, 0);
    this.saveLoading = true;
    if (answerCount > 0) {
      this.answers.forEach((question: {
        index: number;
        questionUid: string;
        answers: SurveyUserAnswer[];
      }) => {
        let promises = [];
        promises = question.answers.map((answer: SurveyUserAnswer) => this.createSurveyUserAnswer({
          answer: {
            label: answer.choice.isOther ? answer.choice.label : '',
          } as SurveyUserAnswer,
          userId: this.authUser.uid,
          surveyId: this.entityCode,
          choiceId: answer.choice.uid,
        })
          .then(() => Promise.resolve(undefined)));
        Promise.all(promises).then((response) => {
          if (response) {
            this.saveLoading = false;
            this.isClosed = true;
          }
        });
      });
      this.$emit('on-submit', this.entityCode);
    }
  }

  private onClose(): void {
    this.isClosed = true;
    this.$emit('on-close', this.entityCode);
  }
}
