/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import BaseModel from '@/models/BaseModel';
import MobileShowroomConfig from '@/models/graphql/MobileShowroomConfig';
import Edition from '@/models/graphql/Edition';
import Booth from '@/models/graphql/Booth';
import Community from '@/models/graphql/Community';
import Category from '@/models/graphql/Category';
import Product from '@/models/graphql/Product';
import Deal from '@/models/graphql/Deal';
import Handout from '@/models/graphql/Handout';
import CommunityUserLink from '@/models/graphql/CommunityUserLink';
import EditionExhibitorMapping from '@/models/graphql/EditionExhibitorMapping';
import Article from '@/models/graphql/Article';
import Address from '@/models/graphql/Address';
import ExhibitorUrl from '@/models/graphql/ExhibitorUrl';
import ExhibitorContact from '@/models/graphql/ExhibitorContact';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import SubEdition from '@/models/graphql/SubEdition';
import FileResource from '@/models/graphql/FileResource';
import Country from '@/models/graphql/Country';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import Slot from '@/models/graphql/Slot';
import EntityTranslationParams from '@/utils/types/EntityTranslationParams';

class Exhibitor extends BaseModel {
  community?: Community;

  edition?: Edition;

  id?: number;

  name?: string;

  deleted?: boolean;

  email?: string;

  sortValue?: string;

  featured?: boolean;

  firstTime?: boolean;

  onboardingStatus?: string;

  description?: string;

  subEdition?: string;

  thumbnail?: string;

  logoFileResource?: FileResource;

  bannerFileResource?: FileResource;

  mobileShowroomConfig?: MobileShowroomConfig;

  parentExhibitor?: Exhibitor;

  childExhibitor?: Array<Exhibitor>;

  booths?: Array<Booth>;

  boothsOfEdition?: Array<Booth>;

  categories?: Array<Category>;

  categoriesInContext?: Array<Category>;

  products?: Array<Product>;

  deals?: Array<Deal>;

  urls?: Array<ExhibitorUrl>;

  articles?: Array<Article>;

  handouts?: Array<Handout>;

  addresses?: Array<Address>;

  country?: Country;

  contacts?: Array<ExhibitorContact>;

  /** Community-only fields: */
  editionMappings?: Array<EditionExhibitorMapping>;

  /** Edition-only fields: */
  communityMappings?: Array<EditionExhibitorMapping>;

  _chatReps?: Array<CompanyUserRole>;

  userRoles?: Array<CompanyUserRole>;

  linked?: Array<CommunityUserLink>;

  displayName?: string | null;

  _isBookmarked?: string | null;

  _isFollowed?: string | null;

  _isShared?: string | null;

  _isVisited?: string | null;

  _isInAgenda?: string | null;

  _isConnected?: boolean;

  _isRecommendedForMe?: boolean;

  _myTags?: CommunityUserTag[];

  _subEditions?: SubEdition[] = [];

  _followerCount?: number;

  _effectiveLimitNumProducts?: number;

  _effectiveLimitNumLargeProducts?: number;

  _effectiveLimitNumDeals?: number;

  _effectiveLimitNumHandouts?: number;

  _effectiveLimitNumLogos?: number;

  _effectiveLimitNumBanners?: number;

  _effectiveLimitNumCompanyRoles?: number;

  _effectiveLimitNumCategories?: number;

  _effectiveLimitNumDescription?: number;

  _effectiveLimitNumImagePerProduct?: number;

  _effectiveLimitNumHandoutPerProduct?: number;

  _effectiveLimitNumImagePerLargeProduct?: number;

  _effectiveLimitNumHandoutPerLargeProduct?: number;

  _effectiveLimitNumContactUs?: number;

  mainExhibitor?: boolean;

  _actions?: { key: string; value: string }[];

  slots: Array<Slot> = [];

  type?: Category[] = [];

  _stocks?: Array<{uid: string; quantity: number}>;

  videoPresentationS3Url?: string;

  _maxSalesPackageRank?: number

  _translations: Array<{
    locale: string;
    values: Array<{key: string; value: string | null}>;
  }> = [];

  repsAmount?: number;

  get translation(): EntityTranslationParams[] {
    const result: EntityTranslationParams[] = [];
    if (this._translations.length > 0) {
      this._translations
        .forEach((trans) => {
          result.push(
            ...trans.values
              .map((val) => ({
                locale: trans.locale,
                value: val.value,
                field: val.key,
              })),
          );
        });
    }
    return result;
  }

  get mainPicture(): string {
    return FileResourceHelper.getFullPath(this.logoFileResource, 'w40');
  }

  static hydrate(item: object): Exhibitor {
    const result = new Exhibitor();
    Object.assign(result, item);

    return result;
  }

  translationsByField(field: string): EntityTranslationParams[] {
    return this.translation.filter((trans) => trans.field === field);
  }
}

export default Exhibitor;
