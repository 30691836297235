import RestRepository from '@/repositories/base/RestRepository';
import AuthResponseParams from '@/utils/types/AuthResponseParams';

export default class MasterDomainRepository extends RestRepository {
  constructor() {
    super(
      `${process.env.VUE_APP_GRAPHQL_GUEST_TOKEN}`,
    );
  }

  loadCommunityConfig(domain: string): Promise<AuthResponseParams> {
    return this.post<AuthResponseParams>({
      path: `?domain=${domain}`,
    });
  }
}
