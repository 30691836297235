import { render, staticRenderFns } from "./MultiSelectComponent.vue?vue&type=template&id=bba0c8aa&scoped=true&"
import script from "./MultiSelectComponent.vue?vue&type=script&lang=ts&"
export * from "./MultiSelectComponent.vue?vue&type=script&lang=ts&"
import style0 from "./MultiSelectComponent.vue?vue&type=style&index=0&id=bba0c8aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bba0c8aa",
  null
  
)

export default component.exports