import { Action, Mutation } from 'vuex-module-decorators';
import LoadableStore from '@/store/LoadableStore';
import ComponentStoreParams from '@/utils/types/ComponentStoreParams';
import LoadableState from '@/store/states/LoadableState';
import BaseStoreMapper from '@/store/dynamic/BaseStoreMapper';

const baseStores: Record<string, object> = BaseStoreMapper;
export default abstract class DynamicStore extends LoadableStore<LoadableState> {
  protected parentStoreName = '';

  protected subModules: string[] = [];

  get modulePath(): (name: string) => string[] {
    return (name) => (this.parentStoreName.length > 0 ? [this.parentStoreName, name] : [name]);
  }

  @Action
  registerModule(payload: {
    baseStoreName: string;
    name: string;
    store: ComponentStoreParams;
  }): void {
    if (payload && payload.baseStoreName && payload.name && payload.store) {
      const { baseStoreName, name, store } = payload;
      if (!store.hasModule(this.modulePath(name))) {
        store.registerModule(this.modulePath(name), baseStores[baseStoreName]);
        this.context.commit('toggleSubModules', name);
      }
    }
  }

  @Action
  hasRegisteredModule(name: string): boolean {
    const result = this.subModules.filter((m) => m === name);
    return result.length > 0;
  }

  @Action
  unRegisterModule(payload: { name: string; store: ComponentStoreParams }): void {
    if (payload && payload.name && payload.store) {
      const { name, store } = payload;
      if (store.hasModule(this.modulePath(name))) {
        store.unregisterModule(this.modulePath(name));
        this.context.commit('toggleSubModules', name);
      }
    }
  }

  @Action
  initModules(store: ComponentStoreParams): void {
    this.subModules.forEach((name) => {
      if (store.hasModule(this.modulePath(name))) {
        store.unregisterModule(this.modulePath(name));
        this.context.commit('toggleSubModules', name);
      }
    });
  }

  @Mutation
  toggleSubModules(module: string): void {
    const index = this.subModules.indexOf(module);
    if (index < 0) {
      this.subModules.push(module);
    } else {
      this.subModules = this.subModules.filter((m) => m !== module);
    }
  }
}
