import { ApolloQueryResult } from '@apollo/client';
import { AppTranslation } from '@/models/graphql/AppTranslation';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import FilterInterface from '@/repositories/base/FilterInterface';

/* eslint-disable max-len */
export default class AppTranslationRepository extends GraphQlRepository<AppTranslation>
  implements FilterInterface<AppTranslation> {
  filter(params: GqlPayloadParams): Promise<AppTranslation[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterAppTranslation',
      operation: 'appTranslation',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'appTranslationBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ appTranslation: Array<AppTranslation> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ appTranslation: Array<AppTranslation> }>) => response.data.appTranslation));
  }
}
