import BaseService from '@/services/BaseService';
import ActionRule from '@/utils/enums/ActionRule';

class ActionRuleService extends BaseService {
  static isRuleValid(actions: {key: string; value: string}[], actionRule: ActionRule): boolean {
    if (actions) {
      const validAction = actions
        .find((action) => action.key === actionRule);
      return !!validAction && validAction.value === 'true';
    }
    return true;
  }
}

export default ActionRuleService;
