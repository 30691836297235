import BaseModel from '@/models/BaseModel';
// eslint-disable-next-line import/no-cycle
import Edition from '@/models/graphql/Edition';
// eslint-disable-next-line import/no-cycle
import Handout from '@/models/graphql/Handout';
// eslint-disable-next-line import/no-cycle
import Community from '@/models/graphql/Community';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import SpeakerRole from '@/models/graphql/SpeakerRole';
// eslint-disable-next-line import/no-cycle
import FileResource from '@/models/graphql/FileResource';
// eslint-disable-next-line import/no-cycle
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
// eslint-disable-next-line import/no-cycle
import CommunityUser from '@/models/graphql/CommunityUser';

class Speaker extends BaseModel {
  community?: Community;

  edition?: Edition;

  name?: string;

  firstName?: string;

  lastName?: string;

  type?: string;

  foreignId?: string;

  deleted?: boolean;

  prefix?: string;

  suffix?: string;

  jobTitle?: string;

  employerName?: string;

  bio?: string;

  credential?: string;

  homepageUrl?: string;

  email?: string;

  twitterUrl?: string;

  facebookUrl?: string;

  instagramUrl?: string;

  pinterestUrl?: string;

  subEdition?: string;

  _displaySequence?: number;

  photoFileResource?: FileResource;

  user?: CommunityUser;

  speakerRoles?: Array<SpeakerRole>;

  handouts: Array<Handout> = [];

  _isBookmarked?: string | null;

  _isFollowed?: string | null;

  _isShared?: string | null;

  _isInAgenda?: string | null;

  _isVisited?: string | null;

  _isRecommendedForMe?: boolean;

  get mainPicture(): string {
    return FileResourceHelper.getFullPath(this.photoFileResource, 'w40');
  }

  static hydrate(item: object): Speaker {
    const result = new Speaker();
    Object.assign(result, item);

    return result;
  }
}

export default Speaker;
