import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import OrderPackage from '@/models/graphql/OrderPackage';
import OrderPackageRepository from '@/repositories/OrderPackageRepository';
import { OrderPackageFilter } from '@/graphql/_Filters/OrderPackageFilter';

interface OrderPackageState extends LoadableState {
  allPromisesLoading: boolean;
}

@Module({ namespaced: true })
export default class OrderPackageStore extends LoadableStore<OrderPackageState> {
  allPromisesLoading = false;

  private orderPackages: OrderPackage[] = [] as OrderPackage[];

  private readonly orderPackageRepository = new OrderPackageRepository();

  get orderPackagesGetter(): OrderPackage[] {
    return this.orderPackages;
  }

  protected get repository(): OrderPackageRepository {
    return this.orderPackageRepository;
  }

  @Mutation
  setOrderPackages(elements: OrderPackage[]): void {
    this.orderPackages = elements;
  }

  @Action
  getForPageRestriction(payload: { filter: OrderPackageFilter }): Promise<OrderPackage[] | undefined> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.ORDER_PACKAGE_FILTER,
          value: payload.filter,
        },
      }),
      fragmentName: 'orderPackageBaseFragment',
    }).then((response: OrderPackage[]) => {
      this.context.commit('load', false);
      return response;
    });
  }
}
